import { useEffect, useState } from 'react';
// Core
import { useCurrentFrame,useVideoConfig,spring,Series,Audio, Img } from 'remotion';
import { Box, Container, Grid, Image, SimpleGrid, Stack, Table } from '@mantine/core';
// Styled Comps
import { useTheme } from 'styled-components'
import styled from 'styled-components';
// UTILS
import { H1, H3, P, S } from '../../../../utils/Text';


// Animations
import {SpringToFrom} from '../../../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../../../Animation/interpolate'
import { BorderBottom } from 'tabler-icons-react';

/*
<Grid.Col span={3}>
                        <RatioMeter DATA={DATA}/>
                    </Grid.Col>
*/

export const Section_WinLoseRatio=({DATA, DURATION})=>{
    const theme = useTheme()

    const frame = useCurrentFrame();
    return(
      <>
  
      <Grid 
            grow 
            gutter="xs"
            justify="space-around"
            sx={(theme) => ({
                position:'relative',
                width:'100%',
                height:'100%', 
                zIndex:100,
            })}
      >
      
        <Grid.Col span={6}>
            <Stack spacing="xs">
            <FrostedBlock 
            style={{ 
                background:theme.radialGradient,
                flexDirection:'column',
                transform: `translateY(${SpringToFrom(0,-1000,0,'Stiff')}px) translateY(${SpringToFrom((DURATION-15),0,-1000,'Springy100')}px)`,
            }}
            >
                    <H1
                        style={{
                            width: '100%',
                            padding: '3rem 0rem 0',
                            fontWeight: '900',
                            fontSize: '4rem',
                            color:theme.Copy[0], 
                            clipPath: `inset(0 ${SpringToFrom(15,50,0,'Springy100')}% 0 ${SpringToFrom(15,50,0,'Springy100')}%)`
                        }}
                    >{DATA.DATAOBJ.General.Team}</H1>
                     <H1
                        style={{
                            width: '100%',
                            padding: ' 0 0 3rem 0rem',
                            fontWeight: '400',
                            fontSize: '2.3rem',
                            color:theme.Copy[2],
                            letterSpacing:'-2px',
                            clipPath: `inset(0 ${SpringToFrom(18,50,0,'Springy100')}% 0 ${SpringToFrom(18,50,0,'Springy100')}%)`
                        }}
                    >{DATA.DATAOBJ.General.League} Winners</H1>
                </FrostedBlock>

                <FrostedBlock
                    style={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        padding:'2rem',
                        background:theme.radialGradient,
                        transform: `translateY(${SpringToFrom(0,1000,0,'Stiff')}px) translateY(${SpringToFrom((DURATION-15),0,1000,'Springy100')}px)`,
                    }}
                >
                    <Image 
                        src={DATA.DATAOBJ.General.LOGO} 
                        width={200}
                        style={{
                            marginBottom: '-100px',
                            position: 'relative',
                            zIndex: '100',
                            opacity:interpolateOpacityByFrame(frame,7,15, 0, 1),
                            transform: `scale( ${SpringToFrom(7,0,1,'Springy100')}) scale(${SpringToFrom((DURATION-15),1,0,'Springy100')})`,
                        }}
                    />

                    <Box
                    style={{
                        
                        transform: `translateY( ${SpringToFrom(7,100,0,'Springy100')}px) translateY(${SpringToFrom((DURATION-15),0,1000,'Springy100')}px)`,
                    }}>
                    <Image
                        src={DATA.DATAOBJ.General.HeroImage}
                        height='676px'
                        style={{
                            background: 'url(Whats_App_Image_2022_06_26_at_6_10_36_PM_2_897cca0738.jpg)',
                            clipPath: `inset(${SpringToFrom(7,50,0,'Springy100')}% 0 ${SpringToFrom(7,50,0,'Springy100')}% 0)`,
                        }}
                    />
                    </Box>
                    
        
                </FrostedBlock>
          </Stack>
        </Grid.Col>
        <Grid.Col span={5} >
            <FrostedBlock 
                style={{
                    background:theme.radialGradient,
                    justifyContent: 'center',
                    transform: `translateX(${SpringToFrom(0,1000,0,'Stiff')}px) translateX(${SpringToFrom((DURATION-15),0,1000,'Springy100')}px)`,
                }}
            >
                <RoadToTheTitle DATA={DATA}/>
            </FrostedBlock>
        </Grid.Col>
      </Grid>
      </>
    )
  }



  const RoadToTheTitle = ({DATA})=>{
    const frame = useCurrentFrame();
    const theme = useTheme()
    const FindVS=(game)=>{
        
        return game.HomeTeam === DATA.DATAOBJ.General.Team ? game.AwayTeam : game.HomeTeam
    }

    const Results=(RESULT)=>{
        //console.log(RESULT, DATA.DATAOBJ.General.Team, RESULT.indexOf(DATA.DATAOBJ.General.Team))
        return RESULT.indexOf(DATA.DATAOBJ.General.Team) != -1 ? theme.Asset.RESULT.WIN:theme.Asset.RESULT.LOST
    }
    return(
        <Box>
            <H1
                style={{ 
                    padding:'1em 0 2rem',
                    color:theme.Copy[0],
                    width:'100%',
                    clipPath: `inset(0 ${SpringToFrom(30,50,0,'Springy100')}% 0 ${SpringToFrom(30,50,0,'Springy100')}%)`}}
            >Road to the title</H1>
            

            <Box
            style={{
                opacity:interpolateOpacityByFrame(frame,7,15, 0, 1),
            }}>
                <Table striped style={{width:'100%'}}>
                {
                DATA.DATAOBJ.General.Season.map((game,i)=>{
                return(
                    <tr key={i} style={{    
                            
                            clipPath: `inset(0 ${SpringToFrom((45+(i*10)),50,0,'Springy100')}% 0 ${SpringToFrom((45+(i*10)),50,0,'Springy100')}%)`,
                            borderBottom: ` ${SpringToFrom((45+(i*10)),0,5,'Springy100')}px solid ${Results(game.ResultStatement)}`
                        }}>
                        <td><P style={{margin: '0 1em 0 .5em', textAlign:'left'}}>  VS {FindVS(game)} </P></td>
                        <td><P style={{margin: '.5em 0',textAlign:'left', color:Results(game.ResultStatement)}}> {game.ResultStatement}</P></td>
                    </tr>
                )
                })
            }
                </Table>
           
         </Box>
        </Box>

    )
  }


  const FrostedBlock = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
  `