import {  Image, SimpleGrid, Box, Container } from "@mantine/core";
import { useDispatch, useSelector } from 'react-redux';
// Theme
import { H3, P } from "../../../../Theme/type";
import {

  VideoWizardContainer,
} from "../../../../Theme/Structure/BodyContainer";
import { BTN_ICON_CALLBACK } from "../../../../Theme/Structure/Button/BTNS";
import { useState } from "react";
import { BLOCK_Section_SubHeading } from "../../../../Components/interface/BLOCK_Section_SubHeading";
import {updateTeamHeroImageforDivisionWinners} from "../../../../store/Reducer/VideoRendering";
export const DataCheck = ({ VIDEODATA, Name, setUpdate }) => {
  
  const RENDER = useSelector((state) => state.RENDER);
  const [image, setimage] = useState(RENDER.DATAOBJ.General.HeroImage);
  const dispatch = useDispatch();
  console.log(RENDER.DATAOBJ.General.GALLERY.length)
  const SelectedImage = (img) => {
    console.log(img)
    dispatch(updateTeamHeroImageforDivisionWinners({HERO:img}))
    
    //RENDER.DATAOBJ.General.HeroImage = img; 
    setimage(img); 
    setUpdate(true); 
  };
 
  if(RENDER.DATAOBJ.General.GALLERY.length === 0){
    return(<><BLOCK_Section_SubHeading LABEL="Add a Team Image to your video" Color={5} />
    <Container>
    <VideoWizardContainer mt={20} p={10}>
      <P align="center">There are currently no Team images associated with this team.</P>
      <P align="center">To add a new image for this team, Please refer to the Team Image Repository!</P>
    </VideoWizardContainer></Container></>)
    
  }
  return (
    <>
      <BLOCK_Section_SubHeading LABEL="Add a Team Image to your video" Color={5} />
      <Container>
      <VideoWizardContainer mb={0} p={0}>
      <P>Select a image from the gallery below</P>
      <SimpleGrid cols={3}>
        {RENDER.DATAOBJ.General.GALLERY.map((img, i) => {
          return (
            <Box
              key={i} 
              sx={(theme) => ({
                textAlign: "center",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                alignItems: "center",
                alignContent: "flex-start",
                padding: "0",
              })}
            >
              <VideoWizardContainer mb={0} p={0} key={i}>
                <Box
                  sx={(theme) => ({
                    backgroundColor:
                      image === img.attributes.url
                        ? theme.colors.brand[7]
                        : theme.colors.brand[0],
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "5px",
                    borderRadius:'5px'
                  })}
                >
                  <Image src={img.attributes.url} width={200} height={150} />
                  <BTN_ICON_CALLBACK
                    CALLBACK={() => {
                      SelectedImage(img.attributes.url);
                    }}
                    ICON="Click" 
                    TIPOBJ={{
                      LABEL: "Use this Team Image",
                      COLOR: "green",
                      POSITION: "top",
                    }}
                  />
                </Box>
              </VideoWizardContainer>
            </Box>
          );
        })}
       
      </SimpleGrid>
      </VideoWizardContainer>
      </Container>
      <P align="center">To add a new image for this team, Please refer to the Team Image Repository!</P>
    </>
  );
};
