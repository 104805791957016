import { useEffect } from "react";
import { EvaluatingData } from "../Layouts/Video_DataLoading";

export const EvaluateData = ({ STRAPIDATA, EVALUTEFUNC }) => {
  useEffect(() => {
    EVALUTEFUNC(STRAPIDATA);
  }, []);

  return <EvaluatingData />;
};

export const Evaluate2DataPoint = ({ STRAPIDATA,ADDITIONAL, EVALUTEFUNC }) => {
  console.log(STRAPIDATA,ADDITIONAL, EVALUTEFUNC)
  useEffect(() => {
    EVALUTEFUNC(STRAPIDATA,ADDITIONAL);
  }, []);

  return <EvaluatingData />; 
};
