import {useCurrentFrame,Img } from 'remotion';
import styled from 'styled-components';

import {SpringToFrom} from '../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../Animation/interpolate';

export const POTDPlayerImage=({IMG,Durations})=>{
    const frame = useCurrentFrame();

    return(
        <ImgContainer>
        
                <Img  
                    src={IMG}
                    style={{
                        width:'100%',
                        borderRadius:'10px',
                        opacity:interpolateOpacityByFrame(frame, Durations.TransitionAfter,(Durations.TransitionAfter+15), 0,1),
                        transform: `translateY(${SpringToFrom(Durations.TransitionAfter,360,0,'Springy100')}px)`
                        
                    }}
            /></ImgContainer>
            )
    }

    const ImgContainer = styled.div`
     
    text-align:center;
    width:90%;
    padding:1rem;
  
    
`