import { useCurrentFrame, Audio } from "remotion";
import { useTheme } from "styled-components";
import styled from "styled-components";
import { Series } from "remotion";
// Animations
import { SpringToFrom } from "../../../../../../Animation/RemotionSpring";
import { interpolateOpacityByFrame } from "../../../../../../Animation/interpolate";
import {
  FromMiddle,
  FromTopToBottom,
} from "../../../../../../Animation/ClipWipe";
import { Box, Group, Image, Stack } from "@mantine/core";
import { H1, P, H3 } from "../../../../../utils/Text";
import { TrimPlayerName } from "../../../../../../../../actions/UX";
import { AnimatedStrokeAroundImage } from "../../../../../GlobalComponents/AnimatedStrokeAroundImage";
import { filter } from "lodash";

export const ScoreCardWrapper = ({ DATA, LABEL, Durations }) => {
  const CreateTranslator = (Int) => {
    const Interval = Durations.Length;
    return `translateY(${SpringToFrom(
      Interval * Int,
      1360,
      0,
      "Springy100"
    )}px) translateY(${SpringToFrom(
      Interval * Int + Interval,
      0,
      -1360,
      "Springy100"
    )}px)`;
  };

  const CreatePairs = () => {
    let chunkSize = 2;
    const ARR = [];
    for (let i = 0; i < DATA.Fixtures[LABEL].length; i += chunkSize) {
      const chunk = DATA.Fixtures[LABEL].slice(i, i + chunkSize);
      ARR.push(chunk);
    }
    return ARR;
  };

  return (
    <Series>
      {CreatePairs().map((Fixture, i) => {
        return (
          <Series.Sequence
            key={i}
            durationInFrames={Durations.Length}
            layout="none"
          >
            <div
              key={i}
              style={{
                transform: `${CreateTranslator(i)}`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                alignContent: "flex-start",
                position: "absolute",
                top: "0",
                width: "90%",
              }}
            >
              {Fixture.map((GAME, i) => {
                return <ScoreCard GAME={GAME} key={i} Durations={Durations} />;
              })}
            </div>
            <Timer Durations={Durations} />
            <Audio
              src={`https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/TournamentsResultsItem_1.mp3`}
              playbackRate={1}
              startFrom={0} // if composition is 30fps, then it will start at 2s
              volume={1}
            />
          </Series.Sequence>
        );
      })}
    </Series>
  );
};

const TimerContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 10px;
`;

const Timer = ({ Durations }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();
  //console.log(((frame/Durations.Length)*100))
  return (
    <TimerContainer
      style={{
        width: `${(frame / Durations.Length) * 100}%`,
        backgroundColor: theme.Base[1],
      }}
    ></TimerContainer>
  );
};

//opacity:interpolateOpacityByFrame(frame, 45,60, 1,0),
//transform: `scale(${SpringToFrom(0,0,1,'Springy100')}) scale(${SpringToFrom(45,1,0)})`,

const GLASSBG = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 580px;
  margin: 80px 0 0 0;
  background: linear-gradient(
    145deg,
    rgba(0, 0, 0, 0.5) 10%,
    rgba(0, 0, 0, 0.2) 90%
  );
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const GLASSBGSMALL = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  background: linear-gradient(
    90deg,
    rgba(225, 225, 225, 0.4) 50%,
    rgba(225, 225, 225, 0.6) 50%
  );
  position: relative;
  border-radius: 0px;
`;

const ScoreCard = ({ GAME, Durations }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();
  return (
    <GLASSBG
      style={{
        opacity: interpolateOpacityByFrame(
          frame,
          Durations.Length - 7,
          Durations.Length,
          1,
          0
        ),
      }}
    >
      <TeamsAndResult GAME={GAME} Durations={Durations} />
      <GLASSBGSMALL
        style={{
          clipPath: FromMiddle(32),
        }}
      >
        <Performances GAME={GAME} Durations={Durations} />
        <MoMImage
          IMG={GAME.MOM.Image}
          Name={GAME.MOM.Name}
          Durations={Durations}
        />
      </GLASSBGSMALL>
    </GLASSBG>
  );
};
/*
    
*/

const TeamsAndResult = ({ GAME, Durations }) => {
  const theme = useTheme();
  return (
    <>
      <Group
        position="center"
        spacing={0}
        style={{
          width: "100%",
          padding: "2rem  1rem 0 ",
          position: "relative",
        }}
      >
        <AnimatedStrokeAroundImage
          COLOR={1}
          DIVIDE={4}
          Durations={Durations}
          STYLES={{
            width: "240px",
            height: "240px",
            top: "5px",
            left: "360px",
          }}
        />
        <Box style={{ width: "50%" }} spacing={0}>
          <Group position="apart">
            <LOGOS FromTO={[370, 0]} LOGO={GAME.homeTeamLogo} />
            <P
              style={{
                width: "300px",
                textAlign: "left",
                fontSize: "40px",
                fontWeight: "400",
                lineHeight: "45px",
                letterSpacing: "-3px",
                whiteSpace: 'nowrap',
                clipPath: FromMiddle(35),
                color: theme.Copy[1],
              }}
            >
              {TrimPlayerName(GAME.homeTeam, 15)} <br />
              {GAME.homeTeamResult}
            </P>
          </Group>
        </Box>

        <Box style={{ width: "50%" }} position="apart" spacing={0}>
        <Group position="apart">
            <P
              style={{
                width: "300px",
                textAlign: "right",
                fontSize: "40px",
                lineHeight: "45px",
                fontWeight: "400",
                whiteSpace: 'nowrap',
                letterSpacing: "-3px",
                clipPath: FromMiddle(35),
                color: theme.Copy[1],
              }}
            >
              {TrimPlayerName(GAME.awayTeam, 15)} <br /> {GAME.awayTeamResult}
            </P>

            <LOGOS FromTO={[-370, 0]} LOGO={GAME.awayTeamLogo} />
          </Group>
        </Box>
      </Group>
      <P
        style={{
          margin: "0",
          padding: "0",
          fontSize: "35px",
          lineHeight: "36px",
          fontWeight: "400",
          whiteSpace: 'nowrap',
          color: theme.Copy[1],
          clipPath: FromTopToBottom(45),
        }}
      >
        {GAME.resultStatement}
      </P>
    </>
  );
};

const LOGOS = ({ LOGO, FromTO }) => {
  return (
    <Image
      src={LOGO}
      width={150}
      height={150}
      radius={100}
      style={{
        transform: ` translateX(${SpringToFrom(
          30,
          FromTO[0],
          FromTO[1],
          "Springy100"
        )}px) scale(${SpringToFrom(7, 0, 1, "Springy100")})`,
      }}
    />
  );
};

const MoMImage = ({ IMG, Name, Durations }) => {
  const frame = useCurrentFrame();
  return (
    <Box
      style={{
        padding: ".5em",
        position: "absolute",
        bottom: "0px",
        width: "100%",
        opacity: interpolateOpacityByFrame(
          frame,
          Durations.RemoveScorcard - 7,
          Durations.RemoveScorcard,
          0,
          1
        ),
      }}
    >
      <Group position="apart" style={{ width: "100%" }}>
        <Image src={IMG} width={300} height={300} radius={5} />
        <H3
          style={{
            fontSize: "2.5rem",
            lineHeight: "2.5rem",
            width: "50%",
          }}
        >
          Man of the MAtch <br />
          {TrimPlayerName(Name)}
        </H3>
      </Group>
    </Box>
  );
};

//
const Performances = ({ GAME, Durations }) => {
  const frame = useCurrentFrame();

  return (
    <Group
      position="apart"
      spacing={0}
      grow
      style={{
        width: "100%",
        opacity: interpolateOpacityByFrame(
          frame,
          Durations.RemoveScorcard - 7,
          Durations.RemoveScorcard,
          1,
          0
        ),
      }}
    >
      <PerformanceStack
        NAME={GAME.homeTeam}
        SCORE={GAME.homeTeamResult}
        TEAM="homeTeam"
        GAME={GAME}
        SIDE="left"
      />

      <PerformanceStack
        NAME={GAME.awayTeam}
        SCORE={GAME.awayTeamResult}
        TEAM="awayTeam"
        GAME={GAME}
        SIDE="right"
      />
    </Group>
  );
};

const PerformanceStack = ({ TEAM, GAME, NAME, SCORE, SIDE }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();

  const FindPerformance = (Team) => {
    return filter(GAME.topBatting, (o) => {
      return o.attributes.team.data.attributes.Name === Team;
    }).slice(0, 2);
  };
  const FindBowlingPerformance = (Team) => {
    return filter(GAME.topBowling, (o) => {
      return o.attributes.team.data.attributes.Name === Team;
    }).slice(0, 2);
  };

  return (
    <Box
      justify="flex-start"
      spacing="xs"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        alignContent: "flex-end",
        justifyContent: "flex-start",
        height: "100%",
        width: "50%",
        padding: "1em 1em 0 ",
        minHeight: "320px",
        // backgroundColor: SIDE==='left' ?'#084B3A':'#34695C'
      }}
    >
      <H3
        style={{
          textAlign: SIDE,
          width: "100%",
          lineHeight: "35px",
          fontSize: "35px",
          letterSpacing: "1px",
          fontWeight: "400",
          color: theme.Copy[1],
          clipPath: FromTopToBottom(50),
          backgroundColor: theme.Base[1],
          padding: "0.5rem",
          borderRadius: "5px",
        }}
      >
        {TrimPlayerName(NAME)}
      </H3>
      {FindPerformance(GAME[TEAM]).map((player, i) => {
        return (
          <BattingPerformance player={player} SIDE={SIDE} INT={i} key={i} />
        );
      })}
      {FindBowlingPerformance(GAME[TEAM]).map((player, i) => {
        return (
          <BowlingPerformance player={player} SIDE={SIDE} INT={i} key={i} />
        );
      })}
    </Box>
  );
};

const BattingPerformance = ({ player, SIDE, INT }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();
  return (
    <P
      style={{
        letterSpacing: "0px",
        fontWeight: "600",
        padding: "0px",
        textAlign: SIDE,
        margin: ".3em 0 ",
        lineHeight: "35px",
        fontSize: "30px",
        width: "100%",
        color: theme.Copy[0],
        clipPath: FromTopToBottom(60 + 3 * INT),
      }}
    >
      {TrimPlayerName(player.attributes.player.data.attributes.Name)}{" "}
      {player.attributes.BATTING_Runs} ({player.attributes.BATTING_Balls})
    </P>
  );
};
const BowlingPerformance = ({ player, SIDE, INT }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();
  return (
    <P
      style={{
        letterSpacing: "0px",
        fontWeight: "600",
        padding: "0px",
        textAlign: SIDE,
        margin: ".1em 0 ",
        lineHeight: "32px",
        fontSize: "30px",
        color: theme.Copy[0],
        width: "100%",
        clipPath: FromTopToBottom(60 + 3 * INT),
      }}
    >
      {TrimPlayerName(player.attributes.player.data.attributes.Name)}{" "}
      {player.attributes.BOWLING_Wkts}/{player.attributes.BOWLING_Runs} (
      {player.attributes.BOWLING_Overs})
    </P>
  );
};

//
/*
MOM:
Image: "https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/uploads/medium_85464_122f52646e.jpeg"
Name: "Jason \"SLOGGER\" Hart"
[[Prototype]]: Object
awayTeam: "NCT 8"
awayTeamLogo: "https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/uploads/medium_16072_34ed956234.jpeg"
awayTeamResult: "137 / 7 (20.0)"
date: "2019-09-14"
fixtureID: 266436
homeTeam: "Swamp Donkeys 2.0"
homeTeamLogo: "https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/uploads/thumbnail_16236_016a578134.jpeg"
homeTeamResult: "137 / 2 (20.0)"
id: "17564"
region: {Name: 'Australia Champs 2019'}
resultStatement: "The game was a tie"
time: "10:00:00.000"
topBatting: Array(2)
0:
attributes:
BATTING_Balls: 39
BATTING_Runs: "63"
BATTING_SR: 161.54
player: {__typename: 'PlayerEntityResponse', data: {…}}
team: {__typename: 'TeamEntityResponse', data: {…}}
__typename: "PlayerBatting"
[[Prototype]]: Object
__typename: "PlayerBattingEntity"
[[Prototype]]: Object
1: {__typename: 'PlayerBattingEntity', attributes: {…}}
length: 2
[[Prototype]]: Array(0)
topBowling: (2) [{…}, {…}]
unixTime: 1568383200
*/
