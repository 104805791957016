import { createSlice } from "@reduxjs/toolkit";

export const RENDER = createSlice({
  name: "VIDEORENDERING",
  initialState: {
    DATAOBJ: {},
    ASSET: {},
    META: {
      Franchise: false,
      AdditionalInfo: false,
      NameVideo: false,
      VideoOutName: false,
    },
    INPUTDATA: {},
    PREVIEWCOMPONENT: false,
    THEME: {
      VideoAudio: false,
      VideoTheme: {
        Theme: {
          Base: [
            "#87D0F9",
            "#4CA8DC",
            "#b35723", 
            "#1D8240",
            "#49515A",
            "#F2F7F8",
          ],
          Copy: ["#343434", "#F2F7F8", "#b35723", "#99CA3C", "#1D8240"],
          BackgroundGradient:
            "linear-gradient(170deg, #FFFFFF 0.50%, #4CA8DC 85%)",
          radialGradient:
            "radial-gradient(97.01% 88.57% at 50% 8.49%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.3) 100%)",
          Transparent: "rgba(255, 255, 255, 0.4)",
          Asset: {
            GOTW: {
              TeamA: ["#49515A"],
              TeamB: ["#223843"],
              Fill: ["#B1E0FB", "#0c6293", "#4CA8DC", "#70afd3"],
            },
            RESULT: {
              WIN: "#418504",
              LOST: "#ff0000",
            },
          },
        },
      },
      VideoTemplate: "Glass",
    },
    SPONSORSHIP: false,
  },
  reducers: {
    addRender: (state, action) => {
      state.DATAOBJ = action.payload; 
    },
    IntAsset: (state, action) => {
      console.log(action.payload)
      state.ASSET = action.payload.addAsset;
      state.PREVIEWCOMPONENT = action.payload.Component;
      state.THEME.VideoTemplate = action.payload.Template;
      //state.ASSET = action.payload;
    },
    addAsset: (state, action) => {
      state.ASSET = action.payload;
    },
    addInputs: (state, action) => {
      state.INPUTDATA = action.payload;
    },
    addComponent: (state, action) => {
      state.PREVIEWCOMPONENT = action.payload;
    },
    addTemplate: (state, action) => {
      state.THEME.VideoTemplate = action.payload;
    },
    addTheme: (state, action) => {
      state.THEME.VideoTheme = action.payload;
    },
    addAudio: (state, action) => {
      state.THEME.VideoAudio = action.payload;
    },
    addFranchise: (state, action) => {
      state.META.Franchise = action.payload;
    },
    addVideoName: (state, action) => {
      state.META.NameVideo = action.payload;
    },
    addVideoOutName: (state, action) => {
      state.META.VideoOutName = action.payload;
    },
    addSponsor: (state, action) => {
      state.SPONSORSHIP = action.payload;
    },
    restoreOBJ: (state, action) => {
      console.log("restoreOBJ has been fired!!!")
      /* console.log(action)
      state.SPONSORSHIP = false;
      state.META.VideoOutName = false;
      state.META.NameVideo = false;
      state.THEME.VideoAudio = false;
      state.PREVIEWCOMPONENT = false;
      state.INPUTDATA = {};
      state.ASSET = {};
      state.DATAOBJ = {}; */
    },
    updatePOTDImage: (state, action) => {
      console.log(action);
      state.DATAOBJ.SHOWCASE.map((item,i)=>{
        if(i===action.payload.ID)
        item.IMAGE=action.payload.url
      })
    },
    updateMOMImage: (state, action) => {
      state.DATAOBJ.DATAOBJ.MoM.IMAGE=action.payload.url
    },
    updateTOTWImage: (state, action) => {
      console.log(action);
      Object.keys(state.DATAOBJ.DATAOBJ).map((KEY,i)=>{
        if(i === action.payload.ID)
        state.DATAOBJ.DATAOBJ[KEY].IMAGE=action.payload.url
      })
    },
    updateTop5Image: (state, action) => {
      console.log(action);
      state.DATAOBJ.DATAOBJ.CATEGORY.map((item,i)=>{
        if(i===action.payload.ID)
        item.IMAGE=action.payload.url
      })
    },
    updateDivisionWInnersImage: (state, action) => {
      console.log(action);
      state.DATAOBJ.TeamStats.PLAYERS.map((item,i)=>{
        if(i===action.payload.ID)
        item.IMAGE=action.payload.url
      })
    },
    updateTeamHeroImageforDivisionWinners: (state, action) => {
      console.log(action.payload.HERO);
      state.DATAOBJ.General.HeroImage=action.payload.HERO
     
    },
    updateMatchReviewImages: (state, action) => {
      
      console.log(action);
      console.log(state.DATAOBJ.DATAOBJ.Performances)
      state.DATAOBJ.DATAOBJ.Performances[action.payload.key][action.payload.ID].IMAGE=action.payload.url
    
    },
  },
});

export const {
  restoreOBJ,
  addRender,
  addAsset,
  IntAsset,
  addSponsor,
  addVideoOutName,
  addInputs,
  addComponent,
  addTheme,
  addTemplate,
  addAudio,
  addFranchise,
  addVideoName,
  updatePOTDImage,
  updateMOMImage,
  updateTOTWImage,
  updateTop5Image,
  updateDivisionWInnersImage,
  updateTeamHeroImageforDivisionWinners,
  updateMatchReviewImages
} = RENDER.actions;
export default RENDER.reducer;
