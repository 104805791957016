
import { useState } from "react" 
//import { useSelector } from 'react-redux'
import {ENV} from '../../Config/RunTimeConfig'
import { parseCookies } from 'nookies'
const qs = require('qs');

export const useFindPlayerName = (ctx)=>{

    const [PlayerName,setPlayerName] = useState([])

    //const UXSTATE = useSelector((state) => state.UXSTATE);
    const jwt = parseCookies(ctx).jwt
    

    const FindPlayerName  = async (_PARTIALNAME)=>{
      setPlayerName(false)
        const query = qs.stringify({
            filters: {
              $or: [
                {
                    Name:{ $containsi: _PARTIALNAME,},
                },
                {
                  player_battings:{ 
                    team:{ Name:{$containsi: _PARTIALNAME,}}},
                },
                {
                  player_bowlings:{ 
                    team:{ Name:{$containsi: _PARTIALNAME,}}},
                }
              ]
              },
              populate: ['image',
                'ImageGallery',
                'player_battings',
                'player_battings.team',
                'player_bowlings',
                'player_bowlings.team',
                'player_catches',
                'player_catches.team',
                'player_stumpings',
                'player_stumpings.team',
                'player_moms',
                'player_moms.team'],   
          }, {
            encodeValuesOnly: true,  
          });

        const res = await fetch(`${ENV.API_URL}/api/players?${query}`, { 
            headers: {
                Authorization: `Bearer ${jwt}`
            }
        }) 
        const loginResponse = await res.json();

      //console.log(loginResponse)
        setPlayerName(loginResponse.data)
    }
    return[PlayerName,FindPlayerName] 
}