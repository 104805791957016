import {Series } from "remotion";
import { FullWidthWrapper } from "../../../utils/Containers";

// Components
import {Overview} from './Sequences/Overview'
import {Batting} from './Sequences/Batting'
import {Bowling} from './Sequences/Bowling'
import {Fielding} from './Sequences/Fielding'
import {WrapUp} from './Sequences/Wrapup'
import {PlayerImage} from './Sequences/PlayerImage'

export const PlayerOfTheMatch = ({ DATA }) => { 
  console.log(DATA);

  /*
      Game Over view (T-A vs T-B, T-A Won)
      MoM = player
        POTM Sequence
        BAtting Stats
        Bowling STats
        Fielding STats
      
        Closing STtaement
        */

  return (
    <FullWidthWrapper>
      
      <PlayerImage DATA={DATA}/>
      <Series>
      <Series.Sequence
          durationInFrames={DATA.VideoSettings.SectionDuration[2]}
          layout="none"
        > 
          <Overview DATA={DATA}  />
        </Series.Sequence>
        <Series.Sequence
          durationInFrames={DATA.VideoSettings.SectionDuration[3]}
          layout="none"
        >
          <Batting DATA={DATA} />
        </Series.Sequence>
        <Series.Sequence
          durationInFrames={DATA.VideoSettings.SectionDuration[4]}
          layout="none"
        >
          <Bowling DATA={DATA} />
        </Series.Sequence>
        <Series.Sequence
          durationInFrames={DATA.VideoSettings.SectionDuration[5]}
          layout="none"
        >
          <Fielding DATA={DATA} />
        </Series.Sequence>
        <Series.Sequence
          durationInFrames={DATA.VideoSettings.SectionDuration[6]}
          layout="none"
        >
          <WrapUp DATA={DATA} />
        </Series.Sequence>
        
      </Series>
    </FullWidthWrapper>
  );
};