/* eslint-disable react/jsx-pascal-case */
// HOOKS

import { useGetGroupOfLeagues } from "../../../Hooks/Content/useLeagues";
// Actions
import { MergeTop5APICallBack } from "../../../actions/EvaluationUtils";

import { Container, List } from "@mantine/core";

import { ICONDB } from "../../../Theme/icons";
import { orderBy, meanBy, sumBy } from "lodash";
// UI Elements

import { useEffect, useState } from "react";
import { Space } from "@mantine/core";
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";
import { P } from "../../../Theme/type";
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";

export const SeasonBattingFunFacts = ({ SeasonID }) => {
  const [getLeagues, setgetLeagues] = useGetGroupOfLeagues();
  const [DATA, setDATA] = useState([]);
  const [topplayers, setTopPlayers] = useState(10);
  const averageOf = [
    "BATTING_Runs",
    "BATTING_Balls",
    "BATTING_fours",
    "BATTING_sixes",
  ];

  const ADD = (VAR, LENGTH = DATA.length) => {
    return sumBy(DATA.slice(0, LENGTH), (p) => p[VAR]);
  };
  const MEAN = (VAR, LENGTH = DATA.length) => {
    return meanBy(DATA.slice(0, LENGTH), (p) => p[VAR]).toFixed(2);
  };

  const FetchPlayerStats = (DATA) => {
    const ARR = [];
    DATA.attributes.watch_lists.data.map((WL, i) => {
      // console.log(WL);
      ARR.push(WL.id);
    });
    setgetLeagues(ARR);
  };

  useEffect(() => {
    if (SeasonID !== false) FetchPlayerStats(SeasonID);
  }, [SeasonID]);

  useEffect(() => {
    if (getLeagues) {
      let ITEM = orderBy(
        MergeTop5APICallBack(getLeagues, "BATTING_Runs"),
        ["BATTING_Runs"],
        ["desc"]
      );
      // console.log(ITEM);
      setDATA(ITEM);
    }
  }, [getLeagues]);
  if (DATA.length === 0) return false;
  return (
    <Container size={"lg"}>
      <Space h="lg" />

      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Fun Facts",
          ICON: "ChartDots",
          DESCRIPTION: "",
          TOOLBAR: false,
        }}
      />
      <VideoWizardContainer>
        <BLOCK_Section_SubHeading LABEL={`General`} Color={5} />
        <Space h="lg" />
        <List>
          <List.Item>
            {ADD("BATTING_INNINGS")} Individual Innings were counted this
            season, with an average of {MEAN("BATTING_INNINGS")} Individual
            Innings per player.
          </List.Item>
          <List.Item>
            {ADD("BATTING_DismissalCount")} dismissals were also counted this
            season, With an Average of {MEAN("BATTING_DismissalCount")}{" "}
            dismissals per player over the season.
          </List.Item>
          <List.Item>
            An average player has a{" "}
            {(
              (MEAN("BATTING_DismissalCount") / MEAN("BATTING_INNINGS")) *
              100
            ).toFixed(2)}
            % likelyhood of being dissmissed this season.
          </List.Item>

          <List.Item>
            {ADD("BATTING_Runs")} Runs have been scored off the bat this season.
            With a total runs average of {MEAN("BATTING_Runs")} per player over
            the season.
          </List.Item>
          <List.Item>
            The overall batting average per innings is {" "}
            {(ADD("BATTING_Runs") / ADD("BATTING_DismissalCount")).toFixed(2)}
          </List.Item>
          <List.Item>
            The overall batting strike rate for the season is{" "}
            {((ADD("BATTING_Runs") / ADD("BATTING_Balls")) * 100).toFixed(2)}
          </List.Item>
        </List>
        <Space h="lg" />
        <BLOCK_Section_SubHeading LABEL={`Boundaries`} Color={5} />
        <Space h="lg" />
        <List>
          <List.Item>
            {ADD("BATTING_sixes") * 6 + ADD("BATTING_fours") * 4} Runs or{" "}
            {(
              ((ADD("BATTING_sixes") * 6 + ADD("BATTING_fours") * 4) /
                ADD("BATTING_Runs")) *
              100
            ).toFixed(2)}
            % come from Boundaries
          </List.Item>
          <List.Item>
            {ADD("BATTING_sixes") * 6} Runs from Sixes. (
            {(((ADD("BATTING_sixes") * 6) / ADD("BATTING_Runs")) * 100).toFixed(
              2
            )}
            %)
          </List.Item>
          <List.Item>
            {ADD("BATTING_fours") * 4} Runs from Fours. (
            {(((ADD("BATTING_fours") * 4) / ADD("BATTING_Runs")) * 100).toFixed(
              2
            )}
            %)
          </List.Item>
        </List>

        <Space h="lg" />
        <BLOCK_Section_SubHeading
          LABEL={`Top ${topplayers} Players (by Runs)`}
          Color={5}
        />
        <Space h="lg" />
        <List>
          <List.Item>
            Of the top {topplayers} Players,{" "}
            {ADD("BATTING_INNINGS", topplayers)} Individual Innings have been
            counted, with an average of {MEAN("BATTING_INNINGS", topplayers)}{" "}
            Individual Innings per player. (
            {(
              (MEAN("BATTING_INNINGS", topplayers) / MEAN("BATTING_INNINGS")) *
              100
            ).toFixed(2)}
            % of the Season Average)
          </List.Item>
          <List.Item>
            {ADD("BATTING_DismissalCount", topplayers)} dismissals counted for
            the season, With an Average of{" "}
            {MEAN("BATTING_DismissalCount", topplayers)} dismissals per player
            over the season.
          </List.Item>
          <List.Item>
            A top {topplayers} player has a{" "}
            {(
              (MEAN("BATTING_DismissalCount", topplayers) /
                MEAN("BATTING_INNINGS", topplayers)) *
              100
            ).toFixed(2)}
            % likelyhood of being dissmissed this season. (
            {(
              (
                (MEAN("BATTING_DismissalCount", topplayers) /
                  MEAN("BATTING_INNINGS", topplayers)) *
                100
              ).toFixed(2) -
              (
                (MEAN("BATTING_DismissalCount") / MEAN("BATTING_INNINGS")) *
                100
              ).toFixed(2)
            ).toFixed(2)}
            % of the Season Average)
          </List.Item>
          <List.Item>
            The overall batting average per innings for the season is{" "}
            {(
              ADD("BATTING_Runs", topplayers) /
              ADD("BATTING_DismissalCount", topplayers)
            ).toFixed(2)}
            . (
            {(
              (ADD("BATTING_Runs", topplayers) /
                ADD("BATTING_DismissalCount", topplayers) /
                (ADD("BATTING_Runs") / ADD("BATTING_DismissalCount"))) *
              100
            ).toFixed(2)}
            % of the Season Average )
          </List.Item>
          <List.Item>
            The overall batting strike rate for the season is{" "}
            {(
              (ADD("BATTING_Runs", topplayers) /
                ADD("BATTING_Balls", topplayers)) *
              100
            ).toFixed(2)}
            . (
            {(
              ((
                (ADD("BATTING_Runs", topplayers) /
                  ADD("BATTING_Balls", topplayers)) *
                100
              ).toFixed(2) /
                ((ADD("BATTING_Runs") / ADD("BATTING_Balls")) * 100).toFixed(
                  2
                )) *
              100
            ).toFixed(2)}
            % of the Season Average )
          </List.Item>
          <List.Item>
            {ADD("BATTING_sixes", topplayers) * 6 +
              ADD("BATTING_fours", topplayers) * 4}{" "}
            Runs or{" "}
            {(
              ((ADD("BATTING_sixes", topplayers) * 6 +
                ADD("BATTING_fours", topplayers) * 4) /
                ADD("BATTING_Runs", topplayers)) *
              100
            ).toFixed(2)}
            % come from Boundaries
          </List.Item>
        </List>
      </VideoWizardContainer>
    </Container>
  );
};
