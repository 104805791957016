import { useState, useEffect } from "react";
// HOOKS

import { Group, Loader } from "@mantine/core";

import { UNIXTIME } from "../../actions/UX";
import { useSelector } from "react-redux";

import { P, S } from "../../Theme/type";


export const HeaderBarAccountStatus = () => {
  const AUTH = useSelector((state) => state.AUTH);

  if (AUTH.SelectedFranchise === false) return false;
  return <DataUpdatingChecker />;
};

const DataUpdatingChecker = () => {
  // HOOKS

  // STATE
  const [Updating, isUpdating] = useState(false);
  const UXSTATE = useSelector((state) => state.UXSTATE);
  //const AUTH = useSelector((state) => state.AUTH);

  // FUNC


  // USEEFFECT
 

  return (
    <Group
      justify="flex-end"
      spacing="xl"
      position="right"
      style={{ width: "100%" }}
    >
      {Updating ? (
        <>
          <P colorID={0}>Franchise Update in progress </P>
          <Loader color="teal" variant="bars" size="xs" />
        </>
      ) : (
        <S align="center" transform="uppercase" weight="200" colorID={0}>
          {UXSTATE?.SelectedFranchise?.attributes?.Name}'s LAST Data SYNC :{" "}
          {UNIXTIME(UXSTATE?.SelectedFranchise?.attributes?.LastUpdate)}
        </S>
      )}
    </Group>
  );
};
