import { useState, useEffect } from "react";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Drawer,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
} from "@mantine/core";
import { PageWrapper } from "./Containers";
export const PublicShell = (props) => {
  const [opened, setOpened] = useState(false);
  
  return (
    <AppShell padding={0}
    
    >
      <PageWrapper>{props.children}</PageWrapper>
    </AppShell>
  );
}; 
/* 
export const PublicShell = (props) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  useEffect(() => {
    console.log(opened);
  }, [opened]);


  const handleClick=()=>{
    console.log("clockewd")
  }

  return (
    <AppShell
      padding={0}
   
      header={
        <Header height={{ base: 50, md: 70 }} p="md">
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>

            <Text>Application header</Text>
          </div>
        </Header>
      }
    >
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        title="Register"
        padding="xl"
        size="xl"
      >
      
      </Drawer>
      <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
      <PageWrapper>{props.children}</PageWrapper>
    </AppShell>
  );
};
 */