
import { ThemeProvider } from 'styled-components'
//import {RemotionThemes} from '../../theme/themes'



// Import Design Templates for MATCHDAYRESULT.
// Add new deisng patterns below
// GLASS
import {GAMEOFTHEWEEKLITE_GLASS} from '../../theme/Templates/GLASS/index'
import {MOTD_LITE_ARROWS} from '../../theme/Templates/ARROWS/index'
import {MOTD_LITE_TOURNAMENT_GLASS} from '../../theme/Templates/TOURNAMENT_GLASS/index'
// END


export const Build_GAMEOFTHEWEEKLITE_4by5 = (props)=>{  
	const { RENDER } = props;
	const TEMPLATES={
		"Glass":<GAMEOFTHEWEEKLITE_GLASS  RENDER={RENDER}/>,
		"Arrows":<MOTD_LITE_ARROWS  RENDER={RENDER}/>,
		"TOURNAMENT_GLASS":<MOTD_LITE_TOURNAMENT_GLASS RENDER={RENDER}/> 
	}   
    return ( 
		<ThemeProvider theme={RENDER.THEME.VideoTheme.Theme}>
		{TEMPLATES[RENDER.THEME.VideoTemplate]}
	  </ThemeProvider> 
    );  
}