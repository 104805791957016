import {
  createStyles,
  Text,
  SimpleGrid,
  Container,
  Image,
} from "@mantine/core";
import {
  IconTruck,
  IconCertificate,
  IconCoin,
  TablerIcon,
} from "@tabler/icons";
import styled from 'styled-components';
import { H3 } from "../../Theme/type";
import { SectionWrapper } from "../Components/Containers";
const useStyles = createStyles((theme) => ({
  feature: {
    position: "relative",
    paddingTop: theme.spacing.xl,
    paddingLeft: theme.spacing.xl,
  },

  overlay: {
    position: "absolute",
    height: 100,
    width: 160,
    top: 0,
    left: 0,

    zIndex: 1,
  },

  content: {
    position: "relative",
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  icon: {
    color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
      .color,
  },

  title: {
    color: theme.colors.Public[0],
  },
}));

function Feature({ icon: Icon, title, description, className, ...others }) {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.feature, className)} {...others}>
      <div className={classes.overlay} />

      <div className={classes.content}>
        <Image src={"/LMSLOGODesaturated.png"} height={80} width={80} />
        <Text
          weight={400}
          size="xs"
          align="center"
          transform="uppercase"
          mb="xs"
          mt={5}
          className={classes.title}
        >
          {title}
        </Text>
      </div>
    </div>
  );
}

const mockdata = [
  {
    icon: IconTruck,
    title: "Sydney",

  },
  {
    icon: IconTruck,
    title: "Melbourne",

  },
  {
    icon: IconTruck,
    title: "Beaudesert",
   
  },
  {
    icon: IconTruck,
    title: "Central Coast",

  },
  {
    icon: IconCertificate,
    title: "Canberra",
 
  },
  {
    icon: IconCoin,
    title: "Tasmania",
    
  },
];

export function FeaturesAsymmetrical() {
  const items = mockdata.map((item) => <Feature {...item} key={item.title} />);

  return (
    <SectionWrapper BGCOLOR={1}>
      <Container mt={120} mb={30} size="lg">
        <H3
          colorID={4}
          transform='uppercase'
          align='center'
          weight='100'
          size={30}
        
        >Franchises using STATTO</H3>
        <SimpleGrid
          cols={6}
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          spacing={50}
        >
          {items}
        </SimpleGrid>
      </Container>
      <SVGContainer>
      <SVGICON />
      </SVGContainer>
    </SectionWrapper>
  );
}


const SVGICON = ()=>{
  return(
      <svg  viewBox="0 0 1440 67" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 6.5H76.6062V29H151.71V21.5H228.317V17H303.421V41H380.027V14H455.131V38H531.737V2H606.842V14H683.448V0.5H758.552V41H835.158V14H910.262V26H986.869H1061.97V9.5H1138.58V12.5H1213.68V26H1290.29V15.5H1365.39V26H1442V20V66.5H1365.39H1290.29H1213.68H1138.58H1061.97H986.869H910.262H835.158H758.552H683.448H606.842H531.737H455.131H380.027H303.421H228.317H151.71H76.6062H0V6.5Z" fill="#EEF2F3"/>
      </svg>
  )
}

const SVGContainer = styled.div`
    position: absolute;
    top: -1px;
    width: 100%;
    display: flex;
    z-index:0;
    transform: rotate(180deg);
 
`