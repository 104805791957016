import { useState } from "react";

// Components

import { SelectAnyPlayer } from "./Search";
import PlayerProfile from "./Profile";

import { BodyContainer } from "../../Theme/Structure/BodyContainer";

const EditPlayerProfile = () => {
  const [SectionID, setSectionID] = useState(0);
  const [SelectedPlayer, setSelectedPlayer] = useState(false);
  const SECTIONS = [
    {
      Component: (
        <SelectAnyPlayer
          setSectionID={setSectionID}
          setSelectedPlayer={setSelectedPlayer}
        />
      ),
      Label: "Search Player",
    },
    {
      Component: (
        <PlayerProfile
          SelectedPlayer={SelectedPlayer}
          setSelectedPlayer={setSelectedPlayer}
          setSectionID={setSectionID}
        />
      ),
      Label: "",
    },
  ];

  return (
    <>
      <BodyContainer>{SECTIONS[SectionID].Component}</BodyContainer>
    </>
  );
};
export default EditPlayerProfile;
//<Dropzone />
