import { AuthenticationForm } from "../Components/LOGINContainer";
import { IndexHeader } from "../Sections/INDEX_HEADER";
import { HeroImageRight } from "../Sections/INDEX_HERO";
import { FooterSimple } from "../Sections/INDEX_Footer";


export const PAGE_Login = () => {
  return (
    <>
   <IndexHeader INDEX={2} />
    <HeroImageRight />
    <AuthenticationForm />
    <FooterSimple />
    </>
  )
};