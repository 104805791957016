// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { ResponsiveBar } from '@nivo/bar'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const BarChart = ({DATA,Settings}) => {
  //console.log(DATA[0].length, Math.ceil(DATA[0].length/10))
      return(
    <ResponsiveBar
        data={DATA[0]} 
        keys={DATA[1]}
        indexBy={Settings.indexBy}
        margin={{ top: 10, right: 20, bottom: 100, left: 40 }}
        padding={0.3}
        minValue={0}
        groupMode="stacked"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={[ '#EC8E1F', '#4A819E', '#4FB575', '#82c5f0', '#1B6B96', '#4A5060','#F9716D']}  
        axisTop={null}
        axisRight={null} 
        yFormat=">-r"
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 18,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 45
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -10,
            format: e => Math.floor(e) === e && e
        }}
        label={function(e){return parseFloat(e.formattedValue).toFixed(0)}}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        legends={[
            {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 10,
                translateY: 90,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 120,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'square',
                symbolBorderColor: 'rgba(0, 0, 0, .5)', 
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        role="application"
        ariaLabel=""
       
    />
    )
}