import {gql,} from "@apollo/client";


export const QUERY = (APISETTINGS)=>{
    return gql`query GetResults{
      watchLists(filters:{ id:{in: [${APISETTINGS.IDS}]} }){
                data{
                  id
                  attributes{
                    Name
                  }
                }
              },
      fixtures(
        pagination: { start: 0, limit: -1 }
        filters: {
          
          and: [
            {  
              Date:{
                gte:"${APISETTINGS.Date[0]}"
              }
            },
            { 
              Date:{
                lte:"${APISETTINGS.Date[1]}" 
              }
            }
          ]
          hasResult: { eq: true }
          watch_list: { id: { in: [${APISETTINGS.IDS}] }  } 
        }
      ) {
        data {
          id
          attributes { 
            fixtureID
            UnixTime
            Time
            Date
            HomeTeamResult
            AwayTeamResult
            HomeTeam
            AwayTeam
            ResultStatement
            watch_list{
              data {
                attributes{
                  Name
                }
              }
            }
            player_moms {
              data{
                attributes{
                  player{data{attributes{
                    Name
                    image {
                      data {
                        id
                        attributes {
                          formats
                          url
                        }
                      }
                    }}}}
                  team{data{attributes{Name}}}
                }
              }
            }
            player_battings (pagination: { start: 0, limit: -1 })
              {
              data{
                attributes{
                  BATTING_Runs
                  BATTING_Balls
                  BATTING_SR
                  player{data{attributes{Name}}}
                  team{data{attributes{Name}}}
                }
              }
            }
            player_bowlings (pagination: { start: 0, limit: -1 }){
              data{
                attributes{
                  BOWLING_Overs
                  BOWLING_Runs
                  BOWLING_Wkts
                  player{data{attributes{Name}}}
                  team{data{attributes{Name}}}
                }
              }
            }
            teams (pagination: { start: 0, limit: -1 }){
              data {
                id
                attributes {
                  Name
                  Logo {
                    data {
                      id
                      attributes {
                        formats
                        url
                      }
                    }
                  }
                }
              }
            }
            
             
          }
        }
      }
    }  `
}