import { ThemeProvider } from 'styled-components'
//import {RemotionThemes} from '../../theme/themes'



// Import Design Templates for MATCHDAYRESULT.
// Add new deisng patterns below
// GLASS
import {GAMEOFTHEWEEK_GLASS} from '../../theme/Templates/GLASS/index'
import {MOTD_ARROWS} from '../../theme/Templates/ARROWS/index'
import {MOTD_TOURNAMENT_GLASS} from '../../theme/Templates/TOURNAMENT_GLASS/index'
// END


export const Build_GAMEOFTHEWEEK_4by5 = (props)=>{
	const { RENDER } = props;
	const TEMPLATES={
		"Glass":<GAMEOFTHEWEEK_GLASS  RENDER={RENDER}/>,
		"Arrows":<MOTD_ARROWS  RENDER={RENDER}/>,
		"TOURNAMENT_GLASS":<MOTD_TOURNAMENT_GLASS  RENDER={RENDER}/> 
	}    
    return (  
		<ThemeProvider theme={RENDER.THEME.VideoTheme.Theme}>
		{TEMPLATES[RENDER.THEME.VideoTemplate]}
	  </ThemeProvider> 
    );  
}