/* eslint-disable react/jsx-pascal-case */
import { Button, Space, Group, Container } from "@mantine/core";
import { useMediaQuery, useInterval } from "@mantine/hooks";

import { useSelector } from "react-redux";

import { useEffect, useState } from "react";

import moment from "moment";
// Hooks

import { useRunFranchiseChecker } from "../../../Hooks/Auth/useRunFranchiseChecker";
import { useGetFranchise } from "../../../Hooks/Content/useFranchises";

// Compoennts
import {
  ColoredContainer,
  VideoWizardContainer,
} from "../../../Theme/Structure/BodyContainer";
import { P } from "../../../Theme/type";
import { SimpleLoader } from "../../../Components/Global/IsLMSUpating";

import { orderBy } from "lodash";

// UI ELEMENTS
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";

// Components

import { UpdateProgress } from "./UpdateProgress";
import { Last5Updates } from "./Last5UpdatesList";

// UPdate compoennts

export const UpdateFranchiseHub = () => {
  return (
    <>
      <UpdateFranchiseCopy />
      <UpdateFranchiseButton />
      <Last5Updates />
    </>
  );
};

/* ************************ */
// Section COPY
/* ************************ */
const UpdateFranchiseCopy = () => {
  return (
    <BLOCK_Section_Title
      OBJ={{
        LABEL: "Update Franchise Data",
        ICON: "Refresh",
        DESCRIPTION:
          "Request an update from the server immediately! Whilst our system will periodically update your franchise's data from the LMS website, should you require an update immediately you can do so with the request button below. Update times can range from 2 min to 1 hour depending on the number of games and new players found",
        TOOLBAR: false,
      }}
    />
  );
};

/* ************************ */
// Section BTN
/* ************************ */
export const UpdateFranchiseButton = () => {
  const UXSTATE = useSelector((state) => state.UXSTATE);

  const [hasClicked, setHasClicked] = useState(false);
  // HOOKS
  const [FranchiseChecker, RunFranchiseChecker] = useRunFranchiseChecker();
  const [Franchise, FetchFranchise] = useGetFranchise();

  const Width = useMediaQuery("(min-width: 769px)");

  // VARS
  const Onehour = 2 * 60 * 1;
  let Now = Math.floor(Date.now() / 1000);

  // FUNC

  const interval = useInterval(() => {
    FetchFranchise();
    //console.log("Update here");
  }, 2000);

  const handleClick = () => {
    setHasClicked(true);
    interval.start();
    RunFranchiseChecker(UXSTATE.SelectedFranchise.id);
  };

  // USEEFFECTS
  useEffect(() => {
    if (Franchise !== false) {
      if (!Franchise.data.attributes.isUpdating) {
        interval.stop();
        setHasClicked(false);
      } else {
        if (!hasClicked) {
          setHasClicked(true);
          interval.start();
        }
      }
    }
  }, [Franchise]);
  useEffect(() => {}, [hasClicked]);

  useEffect(() => {}, [FranchiseChecker]);
  useEffect(() => {
    FetchFranchise();
  }, []);
  //e.SelectedFranchise
  if (UXSTATE?.SelectedFranchise === undefined) return false;
  return (
    <Container size="lg">
      <VideoWizardContainer>
        <Group position="apart">
          <P colorID={3}>
            Last update :{" "}
            {moment
              .unix(UXSTATE?.SelectedFranchise?.attributes.LastUpdate)
              .format("dddd DD MMM YYYY HH:mm:ss")}
          </P>
          {hasClicked ? (
            <P colorID={3}>Updating, Please Wait ...</P>
          ) : (
            <Button
              onClick={handleClick}
              fullWidth={!Width}
              disabled={
                parseInt(Now) -
                  parseInt(UXSTATE.SelectedFranchise.attributes.LastUpdate) >
                Onehour
                  ? false
                  : true
              }
            >
              {parseInt(Now) -
                parseInt(UXSTATE?.SelectedFranchise.attributes.LastUpdate) >
              Onehour
                ? "UPDATE GAME DATA"
                : "2 Min Cool down"}
            </Button>
          )}
        </Group>

        <UpdateProgress
          Franchise={
            !Franchise
              ? false
              : Franchise.data.attributes.franchise_update_requests.data
          }
          hasClicked={hasClicked}
        />
      </VideoWizardContainer>
    </Container>
  );
};

export const UpdateFranchiseButtonDashboard = () => {
  const UXSTATE = useSelector((state) => state.UXSTATE);
  const [hasClicked, setHasClicked] = useState(false);
  // HOOKS
  const [FranchiseChecker, RunFranchiseChecker] = useRunFranchiseChecker();
  const [Franchise, FetchFranchise] = useGetFranchise();

  const Width = useMediaQuery("(min-width: 769px)");

  // VARS
  const Onehour = 2 * 60 * 1;
  let Now = Math.floor(Date.now() / 1000);

  // FUNC

  const interval = useInterval(() => {
    FetchFranchise();
    //console.log("Update here");
  }, 2000);

  const handleClick = () => {
    setHasClicked(true);
    interval.start();
    RunFranchiseChecker(UXSTATE.SelectedFranchise.id);
  };

  // USEEFFECTS
  useEffect(() => {
    if (Franchise !== false) {
      if (!Franchise.data.attributes.isUpdating) {
        interval.stop();
        setHasClicked(false);
      } else {
        if (!hasClicked) {
          setHasClicked(true);
          interval.start();
        }
      }
    }
  }, [Franchise]);
  useEffect(() => {}, [hasClicked]);

  useEffect(() => {}, [FranchiseChecker]);
  useEffect(() => {
    FetchFranchise();
  }, []);
  return (
    <Container size="lg">
      <Group position="right">
        {hasClicked ? (
          <P colorID={3}>Updating, Please Wait ...</P>
        ) : (
          <Button
            onClick={handleClick}
            fullWidth={!Width}
            disabled={
              parseInt(Now) -
                parseInt(UXSTATE?.SelectedFranchise.attributes.LastUpdate) >
              Onehour
                ? false
                : true
            }
          >
            {parseInt(Now) -
              parseInt(UXSTATE?.SelectedFranchise.attributes.LastUpdate) >
            Onehour
              ? "UPDATE GAME DATA"
              : "2 Min Cool down"}
          </Button>
        )}
      </Group>

      <UpdateProgress
        Franchise={
          !Franchise
            ? false
            : Franchise.data.attributes.franchise_update_requests.data
        }
        hasClicked={hasClicked}
      />
    </Container>
  );
};

/* export const UpdateGameData = () => {
  const UXSTATE = useSelector((state) => state.UXSTATE);
  const [hasClicked, setHasClicked] = useState(false);
  // HOOKS
  const [FranchiseChecker, RunFranchiseChecker] = useRunFranchiseChecker();
  const [Franchise, FetchFranchise] = useGetFranchise();

  const Width = useMediaQuery("(min-width: 769px)");

  // VARS
  const Onehour = 2 * 60 * 1;
  let Now = Math.floor(Date.now() / 1000);

  // FUNC

  const interval = useInterval(() => {
    FetchFranchise();
    //console.log("Update here");
  }, 2000);

  const handleClick = () => {
    setHasClicked(true);
    interval.start();
    RunFranchiseChecker(UXSTATE.SelectedFranchise.id);
  };

  // USEEFFECTS
  useEffect(() => {
    if (Franchise !== false) {
      if (!Franchise.data.attributes.isUpdating) {
        interval.stop();
        setHasClicked(false);
      } else {
        if (!hasClicked) {
          setHasClicked(true);
          interval.start();
        }
      }
    }
  }, [Franchise]);
  useEffect(() => {}, [hasClicked]);

  useEffect(() => {}, [FranchiseChecker]);
  useEffect(() => {
    FetchFranchise();
  }, []);

  if (!UXSTATE.SelectedFranchise) return false;
  return (
    <>
      <Space h="xl" />
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Update Franchise Data",
          ICON: "Refresh",
          DESCRIPTION:
            "Request an update from the server immediately! Whilst our system will periodically update your franchise's data from the LMS website, should you require an update immediately you can do so with the request button below. Update times can range from 2 min to 1 hour depending on the number of games and new players found",
          TOOLBAR: false,
        }}
      />

      <ColoredContainer BGColor={3} Padding={10}>
        <Group position="apart">
          <P colorID={0}>
            Franchise Last update :{" "}
            {moment
              .unix(UXSTATE.SelectedFranchise.attributes.LastUpdate)
              .format("dddd DD MMM YYYY HH:mm:ss")}
          </P>
          {hasClicked ? (
            <P colorID={0}>Updating, Please Wait ...</P>
          ) : (
            <Button
              onClick={handleClick}
              fullWidth={!Width}
              disabled={
                parseInt(Now) -
                  parseInt(UXSTATE.SelectedFranchise.attributes.LastUpdate) >
                Onehour
                  ? false
                  : true
              }
            >
              {parseInt(Now) -
                parseInt(UXSTATE.SelectedFranchise.attributes.LastUpdate) >
              Onehour
                ? "UPDATE GAME DATA"
                : "2 Min Cool down"}
            </Button>
          )}
        </Group>
        <UpdateProgress
          Franchise={
            !Franchise
              ? false
              : Franchise.data.attributes.franchise_update_requests.data
          }
          hasClicked={hasClicked}
        />
      </ColoredContainer>
      <Space h="xl" />
      <Last5Updates />
    </>
  );
}; */
