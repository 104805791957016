/* eslint-disable react/jsx-pascal-case */
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";
import { BLOCK_ToolBar } from "../../../Components/interface/BLOCK_Toolbar";
import { BTN_CREATE } from "../../../Theme/Structure/Button/BTNS";

export const AssetToolBar = ({ setStart }) => {
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Build your Video",
          ICON: "Video",
          DESCRIPTION: "Fill in the steps below to build out your LMS Assets.",
          TOOLBAR: false,
        }}
      />

      <BLOCK_ToolBar
        TOOLS={[
          <BTN_CREATE
            LABEL="back"
            CALLBACK={() => {
              setStart(false);
            }}
          />,
        ]}
      />
    </>
  );
};
