

import { orderBy } from "lodash";
// CORE Components
import { SupImages } from "./SuplimentaryImages";
import {StatContainer} from './StatContainer';
import {COPY,EMPASISVALUE} from './Copy'
export const SectionBatting = ({ DATA, OBJ }) => {
  
    const IMAGES = orderBy(
      DATA.DATAOBJ.TeamStats.PLAYERS,
      ["BATTING_Runs"],
      ["desc"]
    ).slice(0, 5);
  
    return (
      <>
      <Sentence 
        DURATION={OBJ.DURATION}
        Name={DATA.DATAOBJ.General.Team}
        Runs={DATA.DATAOBJ.TeamStats.BATTING.RUNS}
        BALLS={DATA.DATAOBJ.TeamStats.BATTING.BALLS}
        Games={DATA.DATAOBJ.General.Played}
        Won={DATA.DATAOBJ.General.Won}
        Lost={DATA.DATAOBJ.General.Won / DATA.DATAOBJ.General.Played}
        Ratio={((DATA.DATAOBJ.General.Won / DATA.DATAOBJ.General.Played) * 100).toFixed(0)}
        PlayerNumbers={DATA.DATAOBJ.TeamStats.PLAYERS.length}
      />
      
        
      <StatContainer
        LABEL={`BATTING`}
        DURATION={OBJ.DURATION}
        TIMINGS={{
          SlideIn: 0,
          LabelPosition: 100,
          ClipIn: 15,
          SlideUp: 30,
        }}
      > 
        
        <SupImages
          DATA={IMAGES}
          DURATION={OBJ.DURATION}
          Limit={5}
          VAR={"BATTING_Runs"}
          Label="Top Run scorers"
        />
      </StatContainer>
      </>
    );
  };

  const Sentence = ({ Name, Runs,BALLS,DURATION }) => {
    return (
      <>
        <COPY DURATION={DURATION}>
        SCORING <EMPASISVALUE VALUE={Runs} /> runs in <EMPASISVALUE VALUE={BALLS} /> balls, {Name} SCORED AT A STRIKE RATE OF  
          <EMPASISVALUE VALUE={((Runs/BALLS)*100).toFixed(1)} /> RUNS per 100 balls or <EMPASISVALUE VALUE={((Runs/BALLS)*5).toFixed(1)} /> run(s) per over. 
        </COPY>
      </>
    );
  };