import {orderBy} from 'lodash'


export const FindIMGFORMAT = (FORMATS,PATH)=>{
      //console.log(FORMATS, PATH)
    if(FORMATS?.large)
      return FORMATS?.large 
    if(FORMATS?.medium)
        return FORMATS?.medium
    if(FORMATS?.small)
        return FORMATS?.small
    if(FORMATS?.thumbnail)
        return FORMATS?.thumbnail
        if(FORMATS === null||FORMATS === undefined)
        return {url:PATH ==='Team' ? DefaultTeamLogo():DefaultPlayerLogo()} 
   
}

export const hasTeamLogo = (LOGO)=>{
    //console.log(LOGO)
    return LOGO ? true : false
}

export const DefaultTeamLogo = ()=>{
    return 'https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/No_Image_Logo_997070e09b.png'
}
export const DefaultPlayerLogo = ()=>{
    return 'https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/player_silhouette_80a0f9e271.webp'
}
export const TrimPlayerName = (NAME, INT='15')=>{
   
    if(NAME === undefined)
    return ' '
    if(NAME.length > INT){
        const NAMESPLIT = NAME.trim().split(' ')
        const initials = NAMESPLIT[0].substring(0, 1).toUpperCase();
        //console.log(initials, NAMESPLIT[(NAMESPLIT.length-1)])
        return `${initials}. ${NAMESPLIT[(NAMESPLIT.length-1)]}`
    }
    return NAME.trim()
 
} 
export const TrimTeamName = (NAME, INT='15')=>{
   
    if(NAME === undefined)
    return ' '
    if(NAME.length > INT){
        //const NAMESPLIT = NAME.split(' ')
        const initials = NAME.substring(0, INT).toUpperCase();
        //console.log(initials, NAMESPLIT[(NAMESPLIT.length-1)])
        return `${initials}...`
    }
    return NAME
 
} 

    export const FindTopBatting = (BATTING)=>{
      //console.log(orderBy(BATTING.data,(obj) => parseInt(obj.attributes.BATTING_Runs, 10), ['desc']))
    return orderBy(BATTING.data,(obj) => parseInt(obj.attributes.BATTING_Runs, 10), ['desc'])
    }

    export const FindTopBowling = (BOWLING)=>{
        //console.log(orderBy(BOWLING.data,(obj) => parseInt(obj.attributes.BOWLING_Wkts, 10), ['desc']))
        return orderBy(BOWLING.data,(obj) => parseInt(obj.attributes.BOWLING_Wkts, 10), ['desc'])
        }