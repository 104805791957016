import { useRef,useEffect,useState } from 'react';
// Components

//import {Counter} from "./POTD_Counter"


import {FullWidthWrapper} from '../../../../utils/Containers'
import {SHOWCASE} from './SHOWCASE';

export const  POTDBATTING_BODY=({DATA,VideoSettings})=>{
  const [Dimensions, setDimensions] = useState([]);
  
  const ref = useRef(null);
  useEffect(() => { setDimensions([ref.current.clientHeight,ref.current.clientWidth]); }, []);
  
  return(
    <FullWidthWrapper ref={ref}> 
      <SHOWCASE DATA={DATA} Dimensions={Dimensions}/>
    </FullWidthWrapper>  
  ) 
}