import { Box, Group } from "@mantine/core";
import { P, S } from "../../Theme/type";
import { ICONDB } from "../../Theme/icons";

export const COPY_INFO = ({ COPY }) => {
  return (
    <Group position="left">
      <S colorID={7}>{ICONDB.InfoCircle.Component}</S>
      <S colorID={3} transform="uppercase">
        {" "}
        {COPY}
      </S>
    </Group>
  );
};

export const COPY_WARNING = ({ COPY }) => {
  return (
    <Group position="left">
      <S colorID={6}>{ICONDB.AlertTriangle.Component}</S>
      <S colorID={3} transform="uppercase">
        {COPY}
      </S>
    </Group>
  );
};
