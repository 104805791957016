import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import {
  DATAERROR,
  FetchingData,
  NoFixturesFound,
} from "../Layouts/Video_DataLoading";

export const QueryData = ({ QUERY, setRAWDATA, KEY="fixtures" }) => {
  const { loading, error, data } = useQuery(QUERY);
  //console.log(loading, data, error);

  useEffect(() => {
    if (!loading && error === undefined) setRAWDATA(data);
  }, [loading]);

  if (loading) return <FetchingData />;
  if (error !== undefined) return <DATAERROR ERROR={error} />;
  if (data[KEY].data.length === 0) return <NoFixturesFound />;
  return !loading && data !== undefined ? true : <NoFixturesFound />;
};
