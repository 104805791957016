import { useEffect, useState } from "react";
// Store
import { useDispatch, useSelector } from "react-redux";
import {
  addTheme,
  addTemplate,
  addAudio,
  addVideoOutName,
  addVideoName,
  addSponsor,
  addFranchise,
} from "../../store/Reducer/VideoRendering";
import { SelectTheme } from "../../Components/Videos/CreateVideoComponents/SelectTheme";
import { SelectASponsor } from "../../Components/Videos/CreateVideoComponents/SelectSponsor";
import { VIDEOPREVIEW } from "./Preview";

import { DropDownSelectVideoTemplate } from "../../Components/Videos/CreateVideoComponents/SelectTemplate";
import { Box, Container, Group, SimpleGrid } from "@mantine/core";
import { SelectAudio } from "../../Components/Videos/CreateVideoComponents/SelectAudio";
import { OrderContainer } from "./Order";
import {
  VideoTitleVersion2, 
  VideoOutPutNameVersion2, 
} from "../../actions/video";
import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";

import { ImageEditor } from "./EditImages";
import { useMediaQuery } from "@mantine/hooks";

export const RenderPreview = () => {
  const RENDER = useSelector((state) => state.RENDER);
  console.log("THIS IS THE RENDER STATE ON index INT")
  console.log(RENDER) 
  const [isConfirmOrder, setisConfirmOrder] = useState(false);
  if (Object.keys(RENDER.ASSET).length === 0) return <NoAssetStored />;
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Video Preview",
          ICON: "Download",
          DESCRIPTION: "Customise your video for your specific needs.", 
          TOOLBAR: false,
        }}
      />

      <Container size={"lg"}>
        <OrderContainer
          setisConfirmOrder={setisConfirmOrder}
          isConfirmOrder={isConfirmOrder}
        />
        <Group cols={2} spacing="xs" verticalspacing="xs" position="apart">
          <VIDEOPREVIEW /> 
          <CreateThemeCreator isConfirmOrder={isConfirmOrder} />
        </Group>
        <ImageEditor isConfirmOrder={isConfirmOrder} />
      </Container>
    </>
  );
};

const CreateThemeCreator = ({ isConfirmOrder }) => {
  const RENDER = useSelector((state) => state.RENDER);
  const AUTH = useSelector((state) => state.AUTH);
  const UXSTATE = useSelector((state) => state.UXSTATE);
  const largeScreen = useMediaQuery('(min-width: 900px)');
  // Reducers
  const dispatch = useDispatch();
  // FUNC STATE
  const [VideoTheme, setVideoTheme] = useState(false);
  const [Audio, setAudio] = useState(false);
  const [isSponsored, setIsSponsored] = useState(false);
  const [Layout, setLayout] = useState(false);

  useEffect(() => {
    if (VideoTheme !== false) dispatch(addTheme(VideoTheme.attributes));
  }, [VideoTheme]);

  useEffect(() => { 
    if (Layout !== false) dispatch(addTemplate(Layout.Name));
  }, [Layout]);

  useEffect(() => {
    //console.log(Audio);
    if (Audio !== false) dispatch(addAudio(Audio.attributes.URL));
  }, [Audio]);

  useEffect(() => {
    //console.log(isSponsored);
    dispatch(addSponsor(!isSponsored ? isSponsored : isSponsored.attributes));
  }, [isSponsored]);

  useEffect(() => {
    //console.log(RENDER, RENDER.ASSET.Asset.ComponentName);
  }, [RENDER]);

  useEffect(() => {
    if (RENDER.ASSET) {
      //console.log(UXSTATE.SelectedFranchise.attributes.Name)
      dispatch(addVideoOutName(VideoOutPutNameVersion2(RENDER, UXSTATE)));
      dispatch(addVideoName(VideoTitleVersion2(RENDER, UXSTATE))); 
      dispatch(addFranchise(UXSTATE.SelectedFranchise.attributes.Name));   
    
    }
  }, []);

  if (isConfirmOrder) return false;
  return (
    <Box
      py={15}
      px={15}
      sx={(theme) => ({
        height: "100%",
        justifyContent: "start",
        alignItems: "stretch",
        alignContent: "stretch",
        alignSelf: "stretch",
        backgroundColor:theme.colors.brand[0],
        borderRadius: "5px",
        width:largeScreen ? '50%' : '100%'
      })}
    >
      <DropDownSelectVideoTemplate
        setLayout={setLayout}
        Layout={Layout}
        AssetComponentName={RENDER.ASSET.Asset.ComponentName}
      />
      <SelectTheme 
        setTheme={setVideoTheme}
        Theme={false}
        Layout={RENDER.THEME.VideoTemplate}
      />
      <SelectAudio setAudio={setAudio} Audio={Audio} />
      <SelectASponsor setSponsor={setIsSponsored} Sponsor={isSponsored} />
    </Box>
  );
};

const NoAssetStored = () => {
  return <>NO ASSET STORED</>;
};

