import { useTheme } from 'styled-components';
import styled from 'styled-components';
import {SpringToFrom} from '../../Animation/RemotionSpring'
import { useCurrentFrame } from "remotion";
export const TripleDropTransition = ({Dimensions,Durations})=>{
  
    const theme = useTheme();
    const frame = useCurrentFrame()
    const StaggerStart=(INT)=>{ return (Durations.Close)-(Durations.Offset-INT)}

    //console.log("frame", Durations, frame)
    return(<>
    
    <OverLayContainer
            style={{
               
                transform: `translateY(${SpringToFrom(StaggerStart(0),(-Dimensions[0]-400),0)}px)`,
            }}>
                 <Overlay fill={theme.Base[2]}/>
            </OverLayContainer>
        <OverLayContainer
            style={{
            
                transform: `translateY(${SpringToFrom(StaggerStart(5),(-Dimensions[0]-400),0)}px)`,
            }}>
               
                <Overlay fill={theme.Base[0]}/>
            </OverLayContainer>

            <OverLayContainer
            style={{
               
                transform: `translateY(${SpringToFrom(StaggerStart(10),(-Dimensions[0]-400),0)}px)`,
            }}>
               
                 <Overlay fill={theme.Base[1]}/>
                  
            </OverLayContainer>
    </>)
}
const OverLayContainer = styled.div`
    width:100%;
    height:100%;
    position:absolute;
    z-index:10;
`

const Overlay = ({fill})=>{
    return(
        <svg viewBox="0 0 1920 3011" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 10.0001C0 4.4772 4.47715 0 10 0H1910C1915.52 0 1920 4.47715 1920 10V2547.95C1920 2551.71 1917.88 2555.16 1914.52 2556.87L1084.09 2977.54C996.804 3021.76 893.534 3021.1 806.815 2975.78L5.3679 2556.89C2.0683 2555.17 0 2551.76 0 2548.03V10.0001Z" fill={fill}/>
        </svg>
    )
}