import { useRef, useEffect } from "react";

import { Card, Avatar, Group, Space, Badge, Box, Center } from "@mantine/core";
import {
  CALLBACKIcon,
  ToLinkIcon,
} from "../../../Theme/Structure/Button/ActionIcons";
import { H3, P, S } from "../../../Theme/type";
import { useSelectVideoTheme } from "../../../Hooks/Content/useVideoTheme";
import { UISkeletonLines, UISkeletonCircle } from "../../Global/Skeleton";
import { TABLE_TD } from "../Wrapper/Table";
import { useMediaQuery } from "@mantine/hooks";

export const CARD_VIDEO_ASSET = ({ DATA, SetVideoModal, THEMEINT = 1 }) => {
  const [VideoTheme, FetchVideoTheme] = useSelectVideoTheme();
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  useEffect(() => {
    FetchVideoTheme();
  }, []);
  useEffect(() => {
    console.log(VideoTheme);
  }, [VideoTheme]);
  //console.log(DATA);
  const templates = DATA.attributes.templates.data.map((stat, i) => (
    <Badge key={i} color="teal">
      {stat.attributes.DisplayName}
    </Badge>
  ));

  if (VideoTheme === null) return <UISkeletonLines />;
  return (
    <Card
      withBorder
      p="xl"
      radius="md"
      sx={(theme) => ({
        "&:hover": {
          boxShadow: `${theme.shadows.md} !important`,
          transform: "scale(1.05)",
        },
      })}
    >
      <Card.Section
        sx={{
          background: VideoTheme[THEMEINT].attributes.Theme.BackgroundGradient,
          /*  backgroundImage: `url(${
            DATA.attributes.Cover?.data === null
              ? VideoTheme[0].attributes.Theme.BackgroundGradient
              : DATA.attributes.Cover?.data.attributes.url
          })`, */
          height: 100,
          backgroundPosition: "top center",
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        <Box
          sx={{
            background:
              VideoTheme[getRandomInt(VideoTheme.length)].attributes.Theme
                .Transparent,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Badge
            sx={(theme) => ({
              position: "absolute",
              top: "5px",
              right: "5px",
              color: theme.colors.brand[0],
            })}
            variant="gradient"
            gradient={{ from: "#666", to: "gray" }}
          >
            Orders : {DATA.attributes.orders.data.length}
          </Badge>
          <H3
            size={"1.25em"}
            colorID={3}
            lineheight={"0.9em"}
            transform="uppercase"
            weight={900}
            align="center"
            letterspacing="-1px"
          >
            {DATA.attributes.Name}
          </H3>
        </Box>
      </Card.Section>
      <Avatar
        src={
          DATA.attributes.HeaderImage.data[0].attributes.formats.thumbnail.url
        }
        size={60}
        radius={5}
        mx="auto"
        mt={-30}
      />
      <Space h="xs" />

      <Space h="md" />

      <Group mt={5} position="center" spacing={7}>
        {templates}
      </Group>
      <Space h="md" />
      <CTA SetVideoModal={SetVideoModal} DATA={DATA} />
    </Card>
  );
};

export const TABLE_VIDEO_ASSET = ({ Item, SetVideoModal }) => {
  const inputRef = useRef(null);
  console.log(Item);
  const Width = useMediaQuery("(min-width: 769px)");

  return (
    <tr>
      
      <TABLE_TD Padding={0}>
        <Center>
          <ToLinkIcon
            ICON={`SquarePlus`}
            TO={`/${Item.attributes.navigation.data.attributes.URI}/${Item.attributes.URI}`}
            TIPOBJ={{ LABEL: `Create`, COLOR: "teal" }}
          />
        </Center>
      </TABLE_TD>
      <TABLE_TD Padding={"0 20px"}>
        <P
          weight={900}
          size={"xl"}
          lineHeight={"0.9em"}
          margin={"0"}
          transform={"uppercase"}
          colorID={2}
        >
          {Item.attributes.Name}
        </P>
        <Box style={{ width:'300px'}}>
        <P
          weight={400}
          size={"sm"}
          lineHeight={"1.4em"}
          margin={"1px 0 "}
          transform={"uppercase"}
          colorID={4}
        >
          {Item.attributes.synopsis}
        </P>
        </Box>
        <P
          weight={400}
          margin={"0"}
          size={"sm"}
          transform={"normal"}
          colorID={5}
        >
          Total Orders :{Item.attributes.orders.data.length}
        </P>
      </TABLE_TD>
      {Width ? (
        <TABLE_TD Padding={0}>
          <Center>
            <CALLBACKIcon
              ref={inputRef}
              ICON={`PlayerPlay`}
              CALLBACK={() => {
                SetVideoModal(Item);
              }}
              TIPOBJ={{ LABEL: "Preview", COLOR: "orange" }}
            />
          </Center>
        </TABLE_TD>
      ) : (
        false
      )}

      
      <TABLE_TD Padding={0}>
        <Avatar
          src={
            Item.attributes.HeaderImage.data[0].attributes.formats.thumbnail.url
          }
          size={70}
          radius={5}
          mx={0}
          my={0}
          p={0}
        />
      </TABLE_TD>
    </tr>
  );
};

export const CTA = ({ SetVideoModal, DATA }) => {
  const inputRef = useRef(null);

  return (
    <Card.Section p={0}>
      <Group position="center" spacing={5}>
        <ToLinkIcon
          ICON={`SquarePlus`}
          TO={`/${DATA.attributes.navigation.data.attributes.URI}/${DATA.attributes.URI}`}
          TIPOBJ={{ LABEL: `Create`, COLOR: "teal" }}
        />
        <CALLBACKIcon
          ref={inputRef}
          ICON={`PlayerPlay`}
          CALLBACK={() => {
            SetVideoModal(DATA);
          }}
          TIPOBJ={{ LABEL: "Watch", COLOR: "orange" }}
        />
        <ToLinkIcon
          ICON={`Download`}
          TO={`/downloads`}
          TIPOBJ={{ LABEL: "Downloads", COLOR: "blue" }}
        />
      </Group>
    </Card.Section>
  );
};
