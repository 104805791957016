import { useEffect, useState } from "react";
import { orderBy } from "lodash";
import { useGetPlayersByLMSID } from "../../../../../Hooks/Content/usePlayer";

import {
  OrderPlayerRankings,
  CleanTop5DATA,
} from "../../../../../actions/EvaluationUtils";


import {findIndex,filter} from 'lodash'

const OffsetTime = 210;
const VideoOBJ = { 
  OpeningSceneDuration: 90,
  EndCreditsDuration: 120,
  SponsorTiming: 120,
  ItemOffset: OffsetTime,
  VideoBodyTiming: OffsetTime * 5,
  VideoDurationinFrames: 90 + 120 + OffsetTime * 5 + 120,
  RenderStill: [40, 190, 360, 560, 760, 950, 1160, 1300],
};
 
export const useBuildVideoData = () => {
  const [Player, GetPlayer] = useGetPlayersByLMSID();
  const VideoSettings = { ...VideoOBJ };
  const [VIDEODATA, setVIDEODATA] = useState({ VideoSettings });
  const PATH = "WORLDRANKINGBOWLING";
  const DefineBy='BOWLING_INNINGS'
  // HOOK TO FIND PLAYERS SELECTED
  const GETPLAYERS = (PLAYERS) => {
    const ARR = [];
    // Store Player IDs for Strapi API
    PLAYERS.map((item, i) => {
      ARR.push(item.LMSID);
    });
    // Find Strapi Players
    GetPlayer(PLAYERS, ARR);
  };

  useEffect(() => {
    // API CALLBACK
    if (Player !== false){
      const TOP5 = orderBy(
        Player,
        (obj) => parseInt(obj[PATH], 10)
        ,["asc"]
      )

        console.log("CLEAN OBJ")
        console.log(CleanTop5DATA(TOP5.slice(0,5), PATH))

      setVIDEODATA({
        ...VIDEODATA,
        DATAOBJ: { ...CleanTop5DATA(TOP5.slice(0,5), PATH) },
      });
    }
     
  }, [Player]);

  // Main Function
  const FindVIDEODATA = async (DATA) => {
    // Send QUERY DATA to MergeTop5APICallBack for sorting
    // MergeTop5APICallBack(DATA,VARIABLE TO GROUP AROUND)
   
    const PLAYERSHASH = [];
    const AvgGames =[]
    let INDEX;
    DATA.map((item, i) => {
      item.attributes.STATS.PLAYERS.map((player, i) => {
        console.log(player);
        INDEX = findIndex(PLAYERSHASH, (o) => {
          return o.id === player.id;
        });
        if (INDEX === -1){
          PLAYERSHASH.push({
            Name: player.Name,
            LMSID: player.LMSID,
            id: player.id,
            Variable: parseInt(player[PATH]),
            DefineBy: parseInt(player[DefineBy]), 
          });
          AvgGames.push(isNaN(parseInt(player[DefineBy])) ? 0 :parseInt(player[DefineBy]))
        }
          
      });
    });
    console.log(Math.floor(AvgGames.reduce((a, b) => a + b)/AvgGames.length))
    
    //const AverageGamesPlayed = Math.floor(AvgGames.reduce((a, b) => a + b)/AvgGames.length);
    const AverageGamesPlayed = 1;
    
    filter(PLAYERSHASH, (o)=>{ return o.DefineBy>=(AverageGamesPlayed)})
    console.log(filter(PLAYERSHASH, (o)=>{ return o.DefineBy>AverageGamesPlayed}))
    let ITEM = orderBy(
      filter(PLAYERSHASH, (o)=>{ return o.DefineBy>AverageGamesPlayed}),
      (obj) => parseInt(obj["Variable"], 10),
      ["asc"]
    );
    console.log(ITEM)
    GETPLAYERS(ITEM.slice(0, 5));
  };

  useEffect(() => {}, [VIDEODATA]);

  return [VIDEODATA, FindVIDEODATA];
};