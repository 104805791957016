import { useEffect, useState } from 'react';
// Core
import { useCurrentFrame,useVideoConfig,spring,Series,Audio, Img } from 'remotion';
import { Box, Center, Container, Grid, Image, SimpleGrid, Stack, Table } from '@mantine/core';
// Styled Comps
import { useTheme } from 'styled-components'
import styled from 'styled-components';
// UTILS
import { H1, H3, P, S } from '../../../../utils/Text';


// Animations
import {SpringToFrom} from '../../../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../../../Animation/interpolate'

/*
<Grid.Col span={3}>
                        <RatioMeter DATA={DATA}/>
                    </Grid.Col>
*/

export const Section_WinLoseRatio=({DATA,OBJ})=>{
    const theme = useTheme()

    const frame = useCurrentFrame();
    return(
        <FrostedBlock 
            style={{
                background:theme.radialGradient,
                justifyContent: 'center',
                width:'96%',
                margin:'0 2%', 
                borderRadius:'10px',
                height:'1350px',
                transform: `translateY(${SpringToFrom(0,1400,-240,'Springy100')}px) translateY(${SpringToFrom((OBJ.DURATION-15),0,1400,'Springy100')}px)`,
            }}
    >
        <RoadToTheTitle DATA={DATA}/>
    </FrostedBlock>
    )
  }



  const RoadToTheTitle = ({DATA})=>{
    const frame = useCurrentFrame();
    const theme = useTheme()
    const FindVS=(game)=>{
        
        return game.HomeTeam === DATA.DATAOBJ.General.Team ? game.AwayTeam : game.HomeTeam
    }

    const Results=(RES)=>{
        console.log(theme)
        return RES.indexOf(DATA.DATAOBJ.General.Team) != -1 ? theme.Asset.RESULT.WIN:theme.Asset.RESULT.LOST
    } 
    return(
        <Box>
            <H1
                style={{ 
                    padding:'0.4em 0 2rem',
                    color:theme.Copy[0],
                    width:'100%',
                    clipPath: `inset(0 ${SpringToFrom(30,50,0,'Springy100')}% 0 ${SpringToFrom(30,50,0,'Springy100')}%)`}}
            >Season results</H1>
            

            <Box
            style={{
                opacity:interpolateOpacityByFrame(frame,7,15, 0, 1),
            }}>
                <Table  style={{width:'100%'}}>
                <tbody>
                {
                DATA.DATAOBJ.General.Season.map((game,i)=>{
                return(
                    <tr key={i} style={{    
                            
                            clipPath: `inset(0 ${SpringToFrom((45+(i*10)),50,0,'Springy100')}% 0 ${SpringToFrom((45+(i*10)),50,0,'Springy100')}%)`,
                            borderBottom: ` ${SpringToFrom((45+(i*10)),0,2,'Springy100')}px solid ${Results(game.ResultStatement)}`
                        }}>
                        <td><P style={{margin: '0 1em 0 .5em',fontSize:'30px',  textAlign:'left'}}>  VS {FindVS(game)} </P></td>
                        <td><P style={{margin: '.5em 0',fontSize:'30px',textAlign:'left', color:Results(game.ResultStatement)}}> {game.ResultStatement}</P></td>
                    </tr>
                )
                })
            }
                </tbody>
                </Table>
         </Box>
        </Box>

    )
  }


  const FrostedBlock = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
  `