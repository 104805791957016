import { useEffect, useState } from "react"
import {useGetUpcomingFixtures,useGetPreviousFixtures} from '../../Hooks/Content/useFixtures'
import { Autocomplete } from '@mantine/core';

export const SelectAGame = ({CALLBACK, SelectedDataset})=>{
  
    const [WatchList, setWatchList] = useState(SelectedDataset.attributes.watch_lists.data)
    const [Fixtures, GetFixtures]  = useGetUpcomingFixtures()   
    const handleChange=(e)=>{ CALLBACK(e)}
      
  
    const CreateDATA=()=>{

      const ARR=[]
      Fixtures.map((ITEM,i)=>{
        ARR.push({ value: `${ITEM.attributes.HomeTeam} vs ${ITEM.attributes.AwayTeam} (${ITEM.attributes.Date})`,id: ITEM.id })
      })
      return ARR
    }
  
    useEffect(()=>{ GetFixtures(WatchList) },[])

    useEffect(()=>{  
  
      setWatchList(SelectedDataset.attributes.watch_lists.data)
      GetFixtures(WatchList)  
    },[SelectedDataset.id]);

    
    useEffect(()=>{ },[Fixtures])
  

      if(Fixtures === false)
      return(<>Loading</>)
      return (
          <>
          <Autocomplete
                  label="Type a team name to find your upcoming Game"
                  placeholder="Pick one"
                  onItemSubmit={handleChange} 
                  data={CreateDATA()} 
                  styles={(theme) => ({
                    input:{
                      '&, &:focus':{
                        borderColor: theme.colors.brand[5],
                        color: theme.colors.brand[5]
                      }
                    },
                    item: {
                      // applies styles to selected item
                      '&[data-selected], &:focus': {
                        '&, &:hover, &:focus': {
                          backgroundColor:theme.colors.brand[3],
                          color: theme.colors.brand[4],
                        },
                      },
            
                      // applies styles to hovered item (with mouse or keyboard)
                      '&[data-hovered], :focus': {
                        backgroundColor:theme.colors.brand[5],
                        color: theme.colors.brand[0],
                      },
                    },
                  })}
            />
            </>
         
        );
  }


  export const SelectACompletedGame = ({CALLBACK, SelectedDataset})=>{
  
    const [WatchList, setWatchList] = useState(SelectedDataset.attributes.watch_lists.data)
    const [Fixtures, GetFixtures]  = useGetPreviousFixtures()  
    const handleChange=(e)=>{ CALLBACK(e)}
      
  
    const CreateDATA=()=>{

      const ARR=[]
      Fixtures.map((ITEM,i)=>{
        ARR.push({ value: `${ITEM.attributes.HomeTeam} vs ${ITEM.attributes.AwayTeam} (${ITEM.attributes.Date})`,id: ITEM.id })
      })
      return ARR
    }
  
    useEffect(()=>{ GetFixtures(WatchList) },[])

    useEffect(()=>{  
  
      setWatchList(SelectedDataset.attributes.watch_lists.data)
      GetFixtures(WatchList)  
    },[SelectedDataset.id]);

    
    useEffect(()=>{ },[Fixtures])
  

      if(Fixtures === false)
      return(<>Loading</>)
      return (
          <>
          <Autocomplete
                  label="Type a team name to find your upcoming Game"
                  placeholder="Pick one"
                  onItemSubmit={handleChange} 
                  data={CreateDATA()} 
                  styles={(theme) => ({
                    input:{
                      '&, &:focus':{
                        borderColor: theme.colors.brand[5],
                        color: theme.colors.brand[5]
                      }
                    },
                    item: {
                      // applies styles to selected item
                      '&[data-selected], &:focus': {
                        '&, &:hover, &:focus': {
                          backgroundColor:theme.colors.brand[3],
                          color: theme.colors.brand[4],
                        },
                      },
            
                      // applies styles to hovered item (with mouse or keyboard)
                      '&[data-hovered], :focus': {
                        backgroundColor:theme.colors.brand[5],
                        color: theme.colors.brand[0],
                      },
                    },
                  })}
            />
            </>
         
        );
  }