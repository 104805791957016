import {Sequence} from 'remotion';
// Components

import {LeagueLabel} from '../../../Components/LeagueLabel'
import {UpcomingFixtureUL} from "./UpcomingFixtureUL"
import {FullWidthWrapper} from '../../../utils/Containers'
import {P_Bold} from '../../../utils/Text'
import { useTheme } from 'styled-components';

export const UPCOMINGFIXTURES_BODY=({DATA,VideoSettings,RenderType})=>{
	const theme = useTheme()
  return(
	<> 
		{
            Object.keys(DATA.Fixtures).map((Label,i)=>{							
                return(			
					<Sequence  
						from={VideoSettings.LeagueStartFrom[i]} 
						durationInFrames={(VideoSettings.LeagueDurations[i])}
						key={i}
					>
						<FullWidthWrapper>
							<LeagueLabel LABEL={Label}/>
							 
							<UpcomingFixtureUL 
								LeagueDurations={VideoSettings.LeagueDurations[i]} 
								VideoSettings={VideoSettings}
								LISTFIXTURES={DATA.Fixtures[Label]}
								RenderType={RenderType}
							/>
							<P_Bold style={{margin:0,color:theme.Copy[0]}}>Total Fixtures :  {DATA.Fixtures[Label].length}</P_Bold>
						</FullWidthWrapper>
					</Sequence>   
                )
            })
        }
	
	</>
  )
}