import { Group, Container, Badge } from "@mantine/core";
import styled from "styled-components";

import { SectionWrapper } from "../Components/Containers";
import { H2 } from "../Components/type";

const SVGContainer = styled.div`
  position: absolute;
  bottom: -1px;
  width: 100%;
  display: flex;
  z-index: 0;
`;

const VideoOptions = [
  "POTD BATTING",
  "POTD BOWLING",
  "PLAYER OF THE MATCH",
  "GAME OF THE WEEK LITE",
  "MATCH DAY RESULTS",
  "MATCH REVIEW",
  "TEAM OF THE WEEK",
  "THIS WEEK IN LMS",
  "UP COMING FIXTURES",
  "8 x Player Milestone options",
  "BATTING RANKINGS TOP 5",
  "BOWLING RANKINGS TOP 5",
  "TOP 5 4S",
  "TOP 5 6S",
  "TOP 5 CATCHES",
  "TOP 5 FASTEST FIFTIES",
  "TOP 5 RUNS",
  "TOP 5 WICKETS",
  "DIVISION WINNERS",
  "SEASON REVIEW"
];


export const IndexByLine001 = () => {
  return (
    <SectionWrapper BGCOLOR={1}>
      <Container
        size={"xl"}
       
        sx={(theme) => ({
          "@media (min-width: 1200px)": {
            padding: " 4em 0 7em",
          },
          "@media (max-width: 1200px) and (min-width: 800px)": {
            padding: " 3em 0 3em",
          },
          "@media (max-width: 800px) and (min-width: 755px)": {
            padding: " 3em 0 3em",
          },
          "@media (max-width: 755px) and (min-width: 576px)": {
            padding: " 3em 0 3em",
          },
          "@media (max-width: 576px)": {
            padding: " 3em 0 3em",
          },
        })}
      >
        <Group position="center">
          <H2 weight={100} colorID={0} align="left">
            over 25 video options avaliable
          </H2>
          <Group position="center">
            {VideoOptions.map((Name, i) => {
              return (
                <Badge
                  key={i}
                  variant="gradient" 
                  gradient={{ from: 'blue', to: 'green' }}
                  size="lg"
                  radius="xl"
                 
                >
                  {Name}
                </Badge>
              );
            })}
          </Group>
        </Group>
      </Container>
      <SVGContainer>
        <SVGICON />
      </SVGContainer>
    </SectionWrapper>
  );
};

const SVGICON = () => {
  return (
    <svg viewBox="0 0 1440 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 6.5H76.6062V29H151.71V21.5H228.317V17H303.421V41H380.027V14H455.131V38H531.737V2H606.842V14H683.448V0.5H758.552V41H835.158V14H910.262V26H986.869H1061.97V9.5H1138.58V12.5H1213.68V26H1290.29V15.5H1365.39V26H1442V20V66.5H1365.39H1290.29H1213.68H1138.58H1061.97H986.869H910.262H835.158H758.552H683.448H606.842H531.737H455.131H380.027H303.421H228.317H151.71H76.6062H0V6.5Z"
        fill="#EEF2F3"
      />
    </svg>
  );
};
