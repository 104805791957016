import { useSelector } from "react-redux";
import { Grid, Container } from "@mantine/core";
import {
  ColoredContainer,
  VideoWizardContainer,
} from "../../../Theme/Structure/BodyContainer";
import { H4_UPPERCASE, Header } from "../../../Theme/type";

import { UpdateFranchiseButtonDashboard } from "../../Franchise/SyncData/UpdateGameData";
import { SelectAFranchiseMini } from "../../Franchise/SyncData/SelectFranchise";

//<Leagues />

//import { UpdateGameData } from "./SyncData/UpdateGameData";
//import moment from 'moment';
export const DashHeader = () => {
  const UXSTATE = useSelector((state) => state.UXSTATE);
  if (!UXSTATE.SelectedFranchise) return <NoFranchiseSelected />;
  return (
    <Container
      size={"xl"}
      py={"xl"}
      sx={(theme) => ({
        //border: `1px solid ${theme.colors.brand[1]}`,
      })}
    >
      <VideoWizardContainer BGColor={3}>
        <Grid  align="flex-end">
          <Grid.Col xs={12} sm={12} md={12} lg={6} >
            <H4_UPPERCASE colorID={0}>Active Franchise</H4_UPPERCASE>
            <Header colorID={0}>
              {UXSTATE.SelectedFranchise.attributes.Name}
            </Header>
           
          </Grid.Col>
          <Grid.Col  xs={6}  sm={6} lg={3}>
            <SelectAFranchiseMini />
          </Grid.Col>
          <Grid.Col  xs={6} sm={6} lg={3}>
            <UpdateFranchiseButtonDashboard />
          </Grid.Col>
        </Grid>
      </VideoWizardContainer>
    </Container>
  );
};

const NoFranchiseSelected = () => {
  return (
    <Container
      size={"xl"}
      py={"xl"}
      sx={(theme) => ({
        //border: `1px solid ${theme.colors.brand[1]}`,
      })}
    >
      <VideoWizardContainer BGColor={3}>
        <Grid>
          <Grid.Col lg={8}>
            <H4_UPPERCASE colorID={6} weight={900} underline={true}>
              No Franchise Selected
            </H4_UPPERCASE>
          </Grid.Col>
          <Grid.Col lg={4}>
            <SelectAFranchiseMini />
          </Grid.Col>
        </Grid>
      </VideoWizardContainer>
    </Container>
  );
};
