import { useCurrentFrame } from "remotion";
import styled from "styled-components";
import { useTheme } from "styled-components";

import { H3 } from "../../../utils/Text";


import { LMSLOGO } from "../../../../Assets/SVG/svg";
import { Box } from "@mantine/core";
import { getContrastColor, getComplimentaryColor } from "../../../../../../actions/colors";
const Value = styled.h1`
  position: absolute;
  left: 440px;
  top: 50px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  margin: 0;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0;
`;

const HeaderMain = styled.div`
  position: absolute;
  width: 992px;
  height: 50px;
  left: 51px;
  top: 15px;
  background: gray;
  border-radius: 100px;
  z-index: 1000;
`;

const Timer = styled.div`
  position: absolute;
  width: 992px;
  height: 50px;
  left: 0px;
  top: 0px;
  border-radius: 100px;
  z-index: 900;
`;

const HeaderSub = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 567px;
  height: 83px;
  left: 19px;
  top: 15px;
  z-index: 1000;
  border: 2px solid #bb5d28;
  border-radius: 100px;
`;

export const Counter = ({
  _INT,
  _TOTAL,
  _Durations,
  _Title = "BOWLING HONOURS",
}) => {
  const theme = useTheme();
  const frame = useCurrentFrame();
  
  return (
    <>
      <Box
        style={{
          position: "absolute",
          left: "19px",
          top: "15px",
          zIndex: 1100,
          width: "80px",
          height: "80px",
        }}
      >
        <LMSLOGO />
      </Box>

      <HeaderSub
        style={{
          background: theme.Base[0],
          borderColor: theme.Base[1],
        }}
      />
      <HeaderMain
        style={{
          background: theme.BackgroundGradient,
        }}
      >
        <Timer
          style={{
            width: `${(frame / _Durations.Close) * 100}%`,
            background: theme.Base[2],
          }}
        />
        <H3
          style={{
            fontWeight: "600",
            textAlign: "right",
            fontSize: "30px",
            lineHeight: "50px",
            letterSpacing: "1px",
            paddingRight: "10px",
            color: getComplimentaryColor(theme.Base[2]),
            
            zIndex: "20000",
            position: "relative",
          }}
        >
          {_Title}
        </H3>
        <Value
          style={{
            zIndex: 999,
            fontSize: "25px",
            color: getComplimentaryColor(theme.Base[0]),
          }}
        >
          {_INT}-{_TOTAL}
        </Value>
      </HeaderMain>
    </>
  );
};
