import { combineReducers } from 'redux'
import UXSTATE from './UX'
import AUTH from './AUTH'
import RENDER from './VideoRendering'


export default combineReducers({
  UXSTATE: UXSTATE,
  AUTH:AUTH,
  RENDER:RENDER
})

//export default rootReducer 