/* eslint-disable react/jsx-pascal-case */
import { parseCookies, setCookie } from "nookies";
// HOOKS
import { useGetFixtureForComentaty } from "../../../Hooks/Content/useFixtures";

// UI Elements
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";
import { useEffect, useState } from "react";

// Compoennts
import { FindFixtureWrapper } from "./Components/Search/FindFixtureWrapper";
import { SelectedWrapper } from "./Components/Selected/SelectedWrapper";
export const CommentaryAssistant = () => {
  //const UXSTATE = useSelector((state) => state.UXSTATE);
  const [Fixture, GetFixture] = useGetFixtureForComentaty();
  const [confirm, setConfirm] = useState(false);

  const handleSetCookie = (FIXTURE) => {
    //console.log(FIXTURE);
    const cookies = parseCookies();
    const ARR =
      cookies.hasCommentaryHistory === undefined
        ? []
        : JSON.parse(cookies.hasCommentaryHistory);
 
    //console.log(ARR);
    ARR.push({
      id: FIXTURE.fixtureID,
      Home: FIXTURE.HomeTeam, 
      Away: FIXTURE.AwayTeam,
    });
    ARR.shift();
    setCookie(null, "hasCommentaryHistory", JSON.stringify(ARR.slice(0, 5)), {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    });
  };

  useEffect(() => {}, [Fixture]);
  useEffect(() => {
    if (confirm) {
      handleSetCookie(Fixture[0].attributes);
    }
  }, [confirm]);
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "LMS TV Commentary Assistant",
          ICON: "ChartDots",
          DESCRIPTION:
            "Providing LMS Team and Player statistics for use during live commentary sessions.",
          TOOLBAR: false,
        }}
      />

      {confirm ? (
        <SelectedWrapper Fixture={Fixture[0]} setConfirm={setConfirm} />
      ) : (
        <FindFixtureWrapper
          GetFixture={GetFixture}
          Fixture={Fixture}
          setConfirm={setConfirm}
        />
      )}
    </>
  );
};
