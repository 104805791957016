import {gql,} from "@apollo/client";

export const QUERY = (APISETTINGS)=>{
    return gql`query GameDetails {
      franchise(id:${APISETTINGS.FRANCHISEID}){
    	data{
        attributes{
          FranchiseStats
        }
      }
  }}`
}
  
