import { useTheme } from "styled-components";

// Animations
import { SpringToFrom } from "../../../../../../Animation/RemotionSpring";
import { EraseFromMiddle, FromMiddle } from "../../../../../../Animation/ClipWipe";
import {interpolateOpacityByFrame} from "../../../../../../Animation/interpolate";


import { Box, Center, Group, Image } from "@mantine/core";
import { P } from "../../../../../utils/Text";
import { TrimPlayerName } from "../../../../../../../../actions/UX";
import { useCurrentFrame } from "remotion";
import { getContrastColor } from "../../../../../../../../actions/colors";

export const TeamsAndScores = ({ GAME, Durations }) => {
  const theme = useTheme();
  const frame = useCurrentFrame();
  return (
    <Center>
      <Group
        position="center"
        spacing={10}
        style={{
          backgroundColor: theme.Base[1],
          height: "120px",
          position: "absolute",
          top: "50px",
          borderRadius: "100px",
          padding: "15px",
          width: `${SpringToFrom(30, 12, 100, "Springy100")}%`,
          transform: `scale(${SpringToFrom(7, 0, 1, "Springy100")})`,
          clipPath: EraseFromMiddle(262), 
          zIndex: 500,
        }}
      >
        <LOGOS FromTO={[0, -430]} LOGO={GAME.homeTeamLogo} />
        <LOGOS FromTO={[0, 430]} LOGO={GAME.awayTeamLogo} />
        <Box>
          <Display_P
            Settings={{
              Align: "left",
              Size: "40px",
            }}
          >
            {GAME.homeTeamResult}
          </Display_P>
          <Display_P
            Settings={{
              Align: "left",
              Size: "25px",
            }}
          >
            {TrimPlayerName(GAME.homeTeam, 20)}
          </Display_P>
        </Box>

        <Box>
          <Display_P
            Settings={{
              Align: "right",
              Size: "40px",
            }}
          >
            {GAME.awayTeamResult}
          </Display_P>
          <Display_P
            Settings={{
              Align: "right",
              Size: "25px",
            }}
          >
            {TrimPlayerName(GAME.awayTeam, 20)}
          </Display_P>
        </Box>
      </Group>
    </Center>
  );
};

const LOGOS = ({ LOGO, FromTO }) => {
  return (
    <Image
      src={LOGO}
      width={80}
      height={80}
      radius={100}
      style={{
        position: "absolute",
        top: "20px",
        transform: ` translateX(${SpringToFrom(
          30,
          FromTO[0],
          FromTO[1],
          "Springy100"
        )}px) scale(${SpringToFrom(
          7,
          0,
          1,
          "Springy100"
        )})  translateX(${SpringToFrom(
          250,
          FromTO[0],
          -FromTO[1],
          "Springy100"
        )}px)`,
      }}
    />
  );
};

const Display_P = (props) => {
  const { Settings } = props;
  const theme = useTheme();
  const frame = useCurrentFrame();
  return (
    <P
      style={{
        margin: 0,
        padding: 0,
        width: "350px",
        textAlign: Settings.Align,
        fontSize: Settings.Size,
        lineHeight: "40px",
        fontWeight: "400",
        whiteSpace: "nowrap",
        letterSpacing: "-1px",
        clipPath: FromMiddle(35),
        opacity:interpolateOpacityByFrame(frame, 245,250, 1,0),
        color: getContrastColor(theme.Base[1]),
      }}
    >
      {props.children}
    </P>
  );
};
