import { useEffect, useState } from "react";
import { Radio, Select } from "@mantine/core";
import { useAudio } from "../../Hooks/Content/useAudio";
import { find, findIndex } from "lodash";

export const SelectAudioOption = ({ DefaultValue, CALLBACK }) => {
  //{attributes:AUTH.AUTHUSER.theme}
  const [VideoAudio, FetchVideoTheme] = useAudio();
  const [value, setValue] = useState(DefaultValue);
  const handleChange = (e) => {
    console.log("e", e);
    const VALUE = find(VideoAudio, (o) => {
      return o.attributes.Title === e;
    });
    setValue(e);
    CALLBACK(VALUE);
  };

  useEffect(() => {
    FetchVideoTheme();
  }, []);
  useEffect(() => {
    console.log(VideoAudio);
  }, [VideoAudio]);

  const CreateDATA = () => {
    const ARR = [];
    VideoAudio.map((ITEM, i) => {
      ARR.push({ value: ITEM.attributes.Title, label: ITEM.attributes.Title });
    });
    return ARR;
  }; 

  if (VideoAudio === null) return <>Loading</>;
  return (
    <>
      <Select
        label=""
        placeholder="Select a Template"
        data={CreateDATA()}
        onChange={handleChange}
        styles={(theme) => ({
          input: {
            "&, &:focus": {
              borderColor: theme.colors.brand[5],
              color: theme.colors.brand[5],
            },
          },
          item: {
            // applies styles to selected item
            "&[data-selected], &:focus": {
              "&, &:hover, &:focus": {
                backgroundColor: theme.colors.brand[3],
                color: theme.colors.brand[4],
              },
            },

            // applies styles to hovered item (with mouse or keyboard)
            "&[data-hovered], :focus": {
              backgroundColor: theme.colors.brand[5],
              color: theme.colors.brand[0],
            },
          },
        })}
      />
    </>
  );
};

/*
<Radio.Group
                    orientation="vertical" 
                    label=""
                    description=""
                    spacing="sm"
                    size="lg"
                    
                    onChange={handleChange}
                    value={value}
                >
                { 
                    VideoAudio.map((ITEM,i)=>{
                        return(
                            <Radio color="green" value={ITEM.attributes.Title} label={ITEM.attributes.Title} key={i} />
                        )
                       
                      })
                }
                </Radio.Group>
*/
