import { Select } from '@mantine/core';

export const BattingPOTDSelectMinStrikeRate = ({CALLBACK,VALUE})=>{
  
    const ARR=[
        { value: 100, label: '100' },
        { value: 120, label: '120' },
        { value: 130, label: '130' },
        { value: 140, label: '140' },
        { value: 150, label: '150' },
        { value: 160, label: '160' },
        { value: 170, label: '170' },
        { value: 180, label: '180' },
        { value: 190, label: '190' },
        { value: 200, label: '200' },
        { value: 220, label: '220' },
        { value: 250, label: '250' },
      ]

    const handleChange=(e)=>{ CALLBACK(e)}
  
 
    return (
        <Select
          label=""
          placeholder={`Min Strike Rate :   ${VALUE}`}
          data={ARR} 
          onChange={handleChange}
          styles={(theme) => ({
            input:{
              '&, &:focus':{
                borderColor: theme.colors.brand[5],
                color: theme.colors.brand[5]
              }
            },
            item: {
              // applies styles to selected item
              '&[data-selected], &:focus': {
                '&, &:hover, &:focus': {
                  backgroundColor:theme.colors.brand[3],
                  color: theme.colors.brand[4],
                },
              },
    
              // applies styles to hovered item (with mouse or keyboard)
              '&[data-hovered], :focus': {
                backgroundColor:theme.colors.brand[5],
                color: theme.colors.brand[0],
              },
            },
          })}
        />
      );
}