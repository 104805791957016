/* eslint-disable react/jsx-pascal-case */
import { useState } from "react";

import { BTN_CREATE } from "../../../../../Theme/Structure/Button/BTNS";
// Components
import { GameMeta } from "./GameMeta";
import { TeamsOverview } from "./Overview";

import { TeamWrapper } from "./TeamWrapper";
import {LeagueLists} from './Components/LeagueList'
// FIND A FIXTURE
export const SelectedWrapper = ({ Fixture,setConfirm }) => {
  const [Path, setPath] = useState(0);
  const [teamID, setTeamID] = useState(false);


  const Components = [
    <LandingWrapper
      Fixture={Fixture}
      setPath={setPath}
      setTeamID={setTeamID}
      setConfirm={setConfirm}
    />,
    <LeagueLists Fixture={Fixture} setPath={setPath} setConfirm={setConfirm}/>,
    <TeamWrapper
      Fixture={Fixture}
      setPath={setPath}
      setTeamID={setTeamID}
      teamID={teamID}
      setConfirm={setConfirm} 
    />,
  ];

  return Components[Path];
};

const LandingWrapper = ({ Fixture, setPath, setTeamID, setConfirm }) => {
  return (
    <>
      <GameMeta Fixture={Fixture} setPath={setPath} setConfirm={setConfirm}/> 
      <TeamsOverview
        Fixture={Fixture}
        setPath={setPath}
        setTeamID={setTeamID}
      />
    </>
  );
};


