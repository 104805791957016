import { Container, Space } from "@mantine/core";
import { useEffect, useState } from "react";
// Actions
import { isFalseInObj } from "../../../../actions/UX";
// Reducers
import { useDispatch, useSelector } from "react-redux";
import {
  addRender,
  addAsset,
  addInputs,
  addComponent,
  addTemplate,
} from "../../../../store/Reducer/VideoRendering";
 
// fetch and Create
import { QUERY } from "./functions/Query";
import { useBuildVideoData } from "./functions/useBuildVideoData";

// Layout
import {
  BTN_ASIDE_LINK_INVERT,
  BTN_CREATE,
} from "../../../../Theme/Structure/Button/BTNS";
import { H3, P } from "../../../../Theme/type";

// V2 Components
// change this URL 
import { Build_TOP5BOWLINGRANKINGS } from "../../../../remotion/FE_Previews/TOPTENBOWLINGRANKINGS/Build"; 

import { QueryData } from "../../../../Components/Videos/CreateAssetV2/QueryData";
import { Evaluate2DataPoint } from "../../../../Components/Videos/CreateAssetV2/EvaluateData";

import { DataCollection_OnlyDataset } from "../../../../Components/Videos/CreateAssetV2/CollectionRequestData";
import { DataCheck } from "./Data";
// UTILS
import { pick } from "lodash";

export const FetchAssetRawData = ({
  setIsDataConfirmed,
  isDataConfirmed,
  Asset,
}) => {
  const [Results, FindResults] = useBuildVideoData();
  const AUTH = useSelector((state) => state.AUTH);
  const UXSTATE = useSelector((state) => state.UXSTATE);

  console.log(UXSTATE.SelectedFranchise.id);
  // REducers
  const dispatch = useDispatch();
  //UI states
  const [isFetching, setIsFetching] = useState(false);
  const [isEvaluating, setIsEvaluating] = useState(false);
  // UX Data
  const [INPUTDATA, setINPUTDATA] = useState([]);
  const [RAWDATA, setRAWDATA] = useState(false);
  const [EVDATA, setEVDATA] = useState(false);

  const FindWatchListIDs = () => {
    console.log(INPUTDATA.DATASET.watch_lists);
    let ARR = [];
    INPUTDATA.DATASET.watch_lists.data.map((Leagues, i) => {
      ARR.push(Leagues.id);
    });
    return ARR;
  };

  // useEFFECT
  useEffect(() => {
    dispatch(
      addAsset({
        Asset: pick(Asset, [
          "ComponentName",
          "Name",
          "Price",
          "video_components",
          "id",
          "media_editors",
        ]),
        AssetType: Asset.ComponentName,
      })
    );

    dispatch(addTemplate(Asset.templates.data[0].attributes.Name));
    dispatch(addComponent(Build_TOP5BOWLINGRANKINGS));
  }, []);
 
  useEffect(() => {
    console.log(INPUTDATA, isFalseInObj(INPUTDATA));
    dispatch(addInputs(INPUTDATA));
  }, [INPUTDATA]);

  useEffect(() => {
    console.log("RAWDATA", RAWDATA)
    if (RAWDATA !== false) setIsEvaluating(true);
  }, [RAWDATA]);

  useEffect(() => {
    //franchise

    if (Results.DATAOBJ !== undefined) {
      setEVDATA(Results);
      setIsDataConfirmed(true);
      dispatch(addRender(Results));
    }
    // Should i store this in a Reducer? or just pass it on to the preview????
  }, [Results]);

  if (EVDATA) return <Dataverified isDataConfirmed={isDataConfirmed} />;
  if (isEvaluating)
    return (
      <Evaluate2DataPoint
        STRAPIDATA={RAWDATA.watchLists.data}
        EVALUTEFUNC={FindResults}
        ADDITIONAL={INPUTDATA}
      />
    );
  if (isFetching)
    return (
      <QueryData
        QUERY={QUERY({ IDS: FindWatchListIDs() })}
        setRAWDATA={setRAWDATA}
        KEY="watchLists"
      />
    );
  return (
    <>
      <DataCollection_OnlyDataset setINPUTDATA={setINPUTDATA} />
      <Container size="lg" p={0}>
        <BTN_CREATE
          CALLBACK={() => {
            setIsFetching(true);
          }}
          LABEL={"Verify Game Data"}
          fullWidth={false}
          DISABLED={!isFalseInObj(INPUTDATA)}
        />
      </Container>
    </>
  );
};

const Dataverified = ({ isDataConfirmed }) => {
  return (
    <>
      <Container my={20}>
        <H3 transform="uppercase" colorID={4}>
          Results!
        </H3>
        <P>
          Review the results below, If you are happy with the results click the
          'PREVIEW VIDEO' button to review the video, make images changes and
          adjust the video theme and layout.
        </P>
        <Space h={20} />
        {isDataConfirmed ? (
          <BTN_ASIDE_LINK_INVERT TO="/preview-order" LABEL="Preview Video" />
        ) : (
          false
        )}
      </Container>
      <DataCheck />
    </>
  );
};
