import { useCurrentFrame } from "remotion";
import { Image } from "@mantine/core";
import { useTheme } from "styled-components";

import styled from "styled-components";

import moment from "moment";
// Animation
import { interpolateOpacityByFrame } from "../../../../../../Animation/interpolate";
import { SpringToFrom } from "../../../../../../Animation/RemotionSpring";
import {
  FromMiddle,
  FromBottomToTop,
} from "../../../../../../Animation/ClipWipe";
// Components
import { TopUnderlayForCopy } from "../../../../../GlobalComponents/BottomUnderlayForCopy";
import { H1, H2, H3, H4 } from "../../../../../utils/Text";

export const TheTeamsWrapper = ({ DATA, Duration }) => {
  const Durations = {
    Begin: 15,
    TransitionAfter: 240,
  };
  return (
    <SeriesContainer>
      <METADATA DATA={DATA} Duration={Duration} />
      <TEAMLOGOS DATA={DATA} Duration={Duration} />
      <TopUnderlayForCopy
        DATA={DATA}
        Durations={Durations}
        BOTTOM={`400px`}
        POSITIONSTARTFROMTOP={-100}
        POSITIONFROMTOP={-25}
      />
    </SeriesContainer>
  );
};

const METADATA = ({ DATA, Duration }) => {
  //console.log(DATA);

  const frame = useCurrentFrame();
  const theme = useTheme();

  const D = DATA.DATAOBJ;

  const ConvertData = (FromDate) => {
    const d = new Date(`${FromDate}`);
    return moment(d).format("DD MMMM");
  };

  const ConvertTime = (FromDate) => {
    const TIME = FromDate.split(":");
    return `${TIME[0]}:${TIME[1]}`;
  };

  return (
    <div style={{ marginTop: "350px" }}>
      <MetaItem>
        <H1
          style={{
            color: theme.Copy[1],
            width: "100%",
            clipPath: FromMiddle(20, "Springy100"),
          }}
        >
          Match of the DAY
        </H1>
      </MetaItem>
      <MetaItem>
        <H3
          style={{
            clipPath: FromMiddle(25, "Springy100"),
            fontSize: "4em",
            lineHeight: "1em",
            color: theme.Copy[1],
          }}
        >
          {D.ground}
        </H3>
      </MetaItem>

      <MetaItem>
        <H3
          style={{
            clipPath: FromMiddle(30, "Springy100"),
            fontSize: "4em",
            lineHeight: "1em",
            color: theme.Copy[1],
          }}
        >
          {ConvertData(D.Date)}
        </H3>
      </MetaItem>
      <MetaItem>
        <H3
          style={{
            clipPath: FromMiddle(35, "Springy100"),
            fontSize: "4em",
            lineHeight: "1em",
            color: theme.Copy[1],
          }}
        >
          {ConvertTime(D.Time)}
        </H3>
      </MetaItem>
    </div>
  );
};

const MetaItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  align-content: flex-end;
  margin-top: 0px;
`;

const TEAMLOGOS = ({ DATA, Duration }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();

  const D = DATA.DATAOBJ;

  return (
    <LogoContainer>
      <ParticipatingTeam
        POSITION={{ left: "0", bottom: "0" }}
        DEG={`270deg`}
        IMG={D.TeamA.LOGO}
        Name={D.TeamA.Name}
        ANIMATEIN={[0, Duration - 10]}
        POSITIONS={[-1000, 70]}
        transformOrigin="top left"
      />
      <H2
        style={{
          width: "100%",
          marginTop: "500px",
          fontWeight: 900,
          opacity: interpolateOpacityByFrame(frame, 0, 45, 0, 1),
          color: theme.Copy[1],
          fontSize: "6rem",
        }}
      >
        VS
      </H2>
      <ParticipatingTeam
        POSITION={{ right: "0", bottom: "0" }}
        DEG={`90deg`}
        IMG={D.TeamB.LOGO}
        Name={D.TeamB.Name}
        ANIMATEIN={[0, Duration - 10]}
        POSITIONS={[1000, -70]}
        transformOrigin="top right"
      />
    </LogoContainer>
  );
};

/** Containers */
const SeriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  flex-wrap: wrap;
  list-style: none;
`;
const LogoContainer = styled.div`
  width: 100%;
  height: 861px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-top: 200px;
  z-index: 1000;
  position: absolute;
  bottom: 0;
`;

const ParticipatingTeam = ({
  IMG,
  Name,
  ANIMATEIN,
  POSITIONS,
  POSITION,
  transformOrigin,
  DEG,
}) => {
  const theme = useTheme();
  const IMGSIZE = 450;
  return (
    <>
      <Image
        src={IMG}
        radius={IMGSIZE}
        width={IMGSIZE}
        height={IMGSIZE}
        style={{
          transform: `translateX(${SpringToFrom(
            ANIMATEIN[0],
            POSITIONS[0],
            POSITIONS[1],
            "fast"
          )}px) translateX(${SpringToFrom(
            ANIMATEIN[1],
            POSITIONS[1],
            POSITIONS[0],
            "Springy100"
          )}px)`,
        }}
      />
      <H1
        style={{
          color: theme.Base[4],
          transform: `rotate(${DEG})`,
          transformOrigin: transformOrigin,
          width: "70%",
          margin: "0",
          position: "absolute",

          clipPath: FromBottomToTop(7, "Springy100"),
          ...POSITION,
        }}
      >
        {Name}
      </H1>
    </>
  );
};
