
import { useState } from "react" 
import {ENV} from '../../Config/RunTimeConfig'
import {  useDispatch } from 'react-redux'
import { setCookie } from 'nookies'

import {isUserSet} from '../../store/Reducer/AUTH';


export const useAuthUserLocal = (ctx)=>{
    const [authUserLocal,setauthUserLocal] = useState(null)
    const dispatch = useDispatch()


    const FetchauthUserLocal = async (loginInfo)=>{
     
        
        const login = await fetch(`${ENV.API_URL}/api/auth/local`, { 
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginInfo)
        })
        const loginResponse = await login.json();

        //console.log(loginResponse)

        if(loginResponse.data === null){ 
            setauthUserLocal(loginResponse.error.message) }
        else{
            setCookie(null, 'AUTHID', loginResponse.user.id, {
                maxAge: 30 * 24 * 60 * 60,
                path: '/',
            })
            setCookie(null, 'jwt', loginResponse.jwt , {
                maxAge: 30 * 24 * 60 * 60,
                path: '/',
            })
            setauthUserLocal(loginResponse.user.id);
            // Send to Reducer
             //dispatch(setAuthUSer(loginResponse))
             dispatch(isUserSet(loginResponse.user.id))
        } 
    }
    return[authUserLocal ,FetchauthUserLocal]
}