import { IndexHeader } from "../Sections/INDEX_HEADER";
import { HeroImageRight } from "../Sections/INDEX_HERO";
import { FooterSimple } from "../Sections/INDEX_Footer";
import { Container } from "@mantine/core";
import { SectionWrapper } from "../Components/Containers";


export const PAGE_User = ()=>{
    return(
        <>
        <IndexHeader INDEX={1} />
        <HeroImageRight />
        <SectionWrapper BGCOLOR={0}>
      <Container size={"md"} py={90}>
        
      <h1>Statto Social User Agreement</h1>
<p>Welcome to Statto Social, the social media assistant for Lastmanstands (LMS) franchise owners. By using our website and services, you agree to be bound by the following terms and conditions.</p>

<h2>Data Collection and Use</h2>
<p>Statto Social collects data from the LMS website for the purpose of creating professional looking social media videos and images for your franchise. By signing up for Statto Social, you agree to allow us to use your LMS data for the duration of the seasons in which you wish to track. You also agree that we may store and use this data in accordance with our Privacy Policy.</p>

<h2>Player and Team Images</h2>
<p>Members can upload player and team photos to Statto Social to use in videos and images. By uploading images, you represent and warrant that you own the rights to these images or have obtained the necessary permissions to use them. Statto Social will not be held responsible for any copyright infringement claims related to images uploaded by members.</p>

<h2>Sponsored Videos</h2>
<p>Statto Social offers the option to turn any video into sponsored content with our "add a sponsor" feature. By using this feature, you represent and warrant that you have obtained the necessary permissions from the sponsor to feature their brand in the video.</p>

<h2>Disclaimer of Warranty</h2>
<p>Statto Social provides its website and services on an "as is" and "as available" basis. We do not make any representations or warranties of any kind, express or implied, as to the operation of our website or the information, content, materials, or products included on our website. To the full extent permissible by applicable law, we disclaim all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose.</p>

<h2>Limitation of Liability</h2>
<p>In no event shall Statto Social, or its directors, employees, agents, partners, or suppliers, be liable for any damages whatsoever, including, but not limited to, direct, indirect, special, incidental, or consequential damages, arising out of or in connection with the use of our website or services.</p>

<h2>Changes to the User Agreement</h2>
<p>We reserve the right to modify this user agreement at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on our website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.</p>

<h2>Contact Us</h2>
<p>If you have any questions about this user agreement, please contact us at </p>

</Container></SectionWrapper>
  
        <FooterSimple />
      </> 
    )
} 