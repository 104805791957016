import { useEffect, useState } from "react";
import { FindTeamLogoURI } from "../../../../../actions/video";
import { orderBy, filter, findIndex, remove, pull, inRange } from "lodash";

const VideoOBJ = {
  VideoDurationinFrames: null,
  SectionDuration: [90, 120, 180 * 5, 180 * 4, 180 * 4, 120],
  VideoBodyTiming: [180 * 4, 180 * 5, 180 * 4].reduce((a, b) => a + b),
  RenderStill: [60, 120, 300, 450, 630, 840, 1020, 1170, 1350,1500,1740,1890,2100,2250,2460], 
};

export const useCreateStats = () => {
  const VideoSettings = { ...VideoOBJ };
  const [Stats, setStats] = useState({ VideoSettings });

  const VideoDuration = () => {
    return Stats.VideoSettings.SectionDuration.reduce((a, b) => a + b);
  }; 

  function between(x, min, max) { 
    return x >= min && x <= max;
  }
  const FindSeasonAverages = (DATA) => {
    const ARR = {
      AVGBATTINGRUNS: [],
      AVGBOWLINGECO: [],
      AVGBOWLINGSTRIKERATE: [],
      AVGTOTALINNINGSOVERS: [],
      AVGTOTALINNINGSRUNS: [],
      AVGTOTALINNINGSWICKETS: [],
      AVGWICKETS: [],
    };
    DATA.map((GameDays, i) => {
      ARR.AVGBATTINGRUNS.push(GameDays.AVGBATTINGRUNS);
      ARR.AVGBOWLINGECO.push(GameDays.AVGBOWLINGECO);
      ARR.AVGBOWLINGSTRIKERATE.push(GameDays.AVGBOWLINGSTRIKERATE);
      ARR.AVGTOTALINNINGSOVERS.push(GameDays.AVGTOTALINNINGSOVERS);
      ARR.AVGTOTALINNINGSRUNS.push(GameDays.AVGTOTALINNINGSRUNS);
      ARR.AVGTOTALINNINGSWICKETS.push(GameDays.AVGTOTALINNINGSWICKETS);
      ARR.AVGWICKETS.push(GameDays.AVGWICKETS);
    });

    Object.keys(ARR).map((key, i) => {
      ARR[key] =
        ARR[key].filter((n) => n).reduce((a, b) => a + b, 0) /
        ARR[key].filter((n) => n).length;
    });

    return ARR;
  };

  const FindSumof = (DATA) => {
    const result = {}; //(1)
    const KeyLookup = [
      "Ducks",
      "FAS3",
      "FIXTURES",
      "FOURS",
      "OVERS",
      "Over50s",
      "RUNS",
      "SIXES",
      "WICKETS",
    ];
    DATA.forEach((basket) => {
      //(2)
      for (let [key, value] of Object.entries(basket)) {
        //(3)

        if (KeyLookup.indexOf(key) != -1) {
          if (result[key]) {
            //(4)
            result[key] += value; //(5)
          } else {
            //(6)
            result[key] = value;
          }
        }
      }
    });
    return result;
  };

  const SumOfDismissals = (DATA) => {
    const ARR = [];
    const result = {};
    DATA.map((DIS, I) => {
      ARR.push(DIS.Dismissal);
    });

    ARR.forEach((basket) => {
      //(2)
      for (let [key, value] of Object.entries(basket)) {
        if (result[key]) {
          //(4)
          result[key] += value; //(5)
        } else {
          //(6)
          result[key] = value;
        }
      }
    });
    return result;
  };

  const FindWeeklyData = (DATA, DATES) => {
    console.log(DATES);
    const ARR = [];
    DATA.OverTheSeason.map((GameDays, i) => {
      
      if (
        between(
          parseInt(GameDays.id),
          Math.floor(new Date(DATES[0]).getTime() / 1000),
          Math.floor(new Date(DATES[1]).getTime() / 1000)
        )
      )
        ARR.push(GameDays);
    });

    return {
      AVG: FindSeasonAverages(ARR),
      SUMof: FindSumof(ARR),
      Dismissals: SumOfDismissals(ARR),
    };
  };

  const FindStats = async (DATA, APISETTINGS) => {
    console.log(DATA, APISETTINGS.DATERANGE)
    const DATAOBJ = {
      DATERANGESTATS: FindWeeklyData(
        DATA.attributes.FranchiseStats,
        APISETTINGS.DATERANGE
      ),
      SEASONAVERAGES: FindSeasonAverages(
        DATA.attributes.FranchiseStats.OverTheSeason
      ),
    };

    Stats.VideoSettings.VideoDurationinFrames = VideoDuration();

    console.log(DATAOBJ);

    setStats({ ...Stats, DATAOBJ: { ...DATAOBJ } });
  };

  useEffect(() => {}, [Stats]);

  return [Stats, FindStats];
};
