import { Text } from "@mantine/core"

export const H1 = (props)=>{
    const {colorID=1, align='left',weight=500, underline=false,transform="uppercase" } = props
    return(
        <Text  transform={transform} weight={weight}  align={align} underline={underline}
                sx={(theme) => ({ color: theme.colors.Public[colorID], fontFamily:'Roboto', fontSize:'2.25em', letterSpacing:'-2.5px' })}
          >{props.children}</Text>
    )
}

export const H2 = (props)=>{
    const {colorID=1, align='left',weight=500, underline=false,transform="uppercase" } = props
    return(
        <Text  
            transform={transform} 
            weight={weight}  
            align={align} 
            underline={underline}
            sx={(theme) => ({ 
                '@media (min-width: 1200px)': {
                    fontSize:'2em', 
                   
                },
                '@media (max-width: 1200px) and (min-width: 800px)': {
                    fontSize:'2em',
                    padding:'0 .2em',
                    margin:'.5em 0'
                    
                },
                '@media (max-width: 800px) and (min-width: 755px)': {
                    fontSize:'2em',
                    padding:'0 .2em',
                    margin:'.5em 0'
                  
                },'@media (max-width: 755px) and (min-width: 576px)': {
                    fontSize:'2em',
                    textAlign:'center',
                    padding:'0 .2em',
                    margin:'.5em 0'
                  
                },'@media (max-width: 576px)': {
                    fontSize:'1.5em',
                    textAlign:'center',
                    padding:'0 .2em',
                    margin:'.5em 0'
                },
                color: theme.colors.Public[colorID], 
                fontFamily:'Roboto', 
                fontSize:'2em', 
                letterSpacing:'-.5px',
                lineHeight:'1.1em' 
            })}
          >{props.children}</Text>
    )
}

export const P = (props)=>{
    const {colorID=1, align='left',weight=500, underline=false,transform="uppercase" } = props
    return(
        <Text  transform={transform} weight={weight}  align={align} underline={underline}
                sx={(theme) => ({ color: theme.colors.Public[colorID], fontFamily:'Roboto', fontSize:'2em', letterSpacing:'-.5px',lineHeight:'1em' })}
          >{props.children}</Text>
    )
}


export const ExtraLarge = (props)=>{ 
    const {colorID=1, align='left',weight=500, underline=false,transform="uppercase" } = props
    return(
        <Text
            transform={transform} 
            weight={weight}  
            align={align} 
            underline={underline}
            sx={(theme) => ({ 
                '@media (min-width: 1200px)': {
                    fontSize:'3.5em', 
                   
                },
                '@media (max-width: 1200px) and (min-width: 800px)': {
                    fontSize:'3.5em', 
                    padding:'0 .2em'
                },
                '@media (max-width: 800px) and (min-width: 755px)': {
                    fontSize:'3.5em',
                    padding:'0 .2em' 
                  
                },'@media (max-width: 755px) and (min-width: 576px)': {
                    fontSize:'3em',
                    textAlign:'center',
                    padding:'0 .2em'
                  
                },'@media (max-width: 576px)': {
                    fontSize:'2.5em',
                    textAlign:'center',
                    padding:'0 .2em'
                },
                color: theme.colors.Public[colorID],
                fontFamily:'Roboto',
                letterSpacing:'-.5px',
                lineHeight:'1em' 
            })}
          >
            {props.children}</Text>
    )
}
/*
 '@media (min-width: 1200px)': {
                    padding:' 5em 0 8em',
                   
                },
                '@media (max-width: 1200px) and (min-width: 800px)': {
                    padding:' 5em 0',
                  
                },
                '@media (max-width: 800px) and (min-width: 755px)': {
                    padding:' 5em 0',
                  
                },'@media (max-width: 755px) and (min-width: 576px)': {
                    padding:' 5em 0',
                  
                },'@media (max-width: 576px)': {
                    padding:' 5em 0',
                }
<Text  transform={transform} weight={weight}  align={align} underline={underline}
                sx={(theme) => ({ color: theme.colors.brand[colorID], fontFamily:'Roboto', fontSize:'2.25em', letterSpacing:'-2.5px' })}
          >{props.children}</Text>
*/