import {useCurrentFrame, useVideoConfig,spring,} from 'remotion';

 const SpringConfig ={
    Default:{ 
        damping: 10,
        stiffness: 5,
        mass: 0.3,
   },
   fast:{ 
        damping: 10,
        stiffness: 55, 
        mass: 0.9,
    },
    Springy100:{  
          damping: 10,
         stiffness: 10, 
         mass: 0.3,
     },
     Stiff:{
         damping: 80,
         stiffness: 100,
         mass: 0.1,
     },
     VeryStiff:{
      damping: 1,
      stiffness: 100,
      mass: 1,
  },
    
   }  

   //StaggerSpringIn(fps,frame)
 export  const SpringIn = (fps,frame,effect='Springy100')=>{
    return  spring({
        fps,
        from: 0,
        to: 1,
        frame,
        config:SpringConfig[effect],
      })
} 


/*
Alter a box Height 
ContainerHeight(fps,frame,int,FROM,TO)
*/
export const SpringToFrom = (int,FROM,TO,effect='Default', Speed='normal')=>{
    const frame = useCurrentFrame();
    const { fps } = useVideoConfig();

  
    const SPEED={
        fast:(fps/5),
        normal:fps/2,
        slow:fps/1
    }

    return  spring({
        fps:SPEED[Speed],
        from: FROM,
        to: TO,
        frame:frame-int,
        config:SpringConfig[effect],
      })
}