import { Box } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
export const PreviewVideoRenderWrapper = (props) => {
  const largeScreen = useMediaQuery('(min-width: 900px)');
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        width:largeScreen ? 'auto' : '100%'
      }}
    >
      <Box
        style={{
          display: "inline-block",
          boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.25)",
          margin: "0",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
