/* eslint-disable react/jsx-pascal-case */
import { useSelector } from "react-redux";
// ACTIONS
import { RemoveWashouts } from "../../../actions/Stats";
import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";
import { H3, P } from "../../../Theme/type";
import {
  Box,
  Center,
  Container,
  Group,
  List,
  SimpleGrid,
  Space,
} from "@mantine/core";
import { BarChart } from "../../../Components/Charts/Bar";

import { ICONDB } from "../../../Theme/icons";
import { orderBy } from "lodash";
// UI Elements
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
//import { PageHeader } from "../../../Components/interface/ProductHeader";
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";

/*
Fixture by ground?
fixtures by umpire?
 total scores by ground?
 num players used?
 num new players.. first game?

*/

export const GeneralContainer = () => {
  const UXSTATE = useSelector((state) => state.UXSTATE);

  if (UXSTATE.SelectedFranchise.attributes?.FranchiseStats === undefined)
    return <>Loading Data</>;
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Season Statistics Overview",
          ICON: "ChartDots",
          DESCRIPTION: "A statistical overview of the active seasons.",
          TOOLBAR: false,
        }}
      />

      <BASESTATS STATS={UXSTATE.SelectedFranchise.attributes.FranchiseStats} />
    </>
  );
};

export const TournamentGeneralContainer = ({ FranchiseStats }) => {
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Season Statistics Overview",
          ICON: "ChartDots",
          DESCRIPTION: "A statistical overview of the active seasons.",
          TOOLBAR: false,
        }}
      />
      <BASESTATS
        STATS={FranchiseStats.franchise.data.attributes.FranchiseStats}
      />
    </>
  );
};

export const BASESTATS = ({ STATS }) => {
  //console.log(STATS);
  if (STATS.FIXTURES === false)
    return (
      <VideoWizardContainer>
        <BLOCK_Section_SubHeading
          LABEL="No Data present for the current active leagues "
          Color={6}
        />
        <Space h="xl" />
        <P>This could be due to:</P>
        <Space h="sm" />
        <List>
          <List.Item>
            The start of a new Season, and no game data has been collected.
          </List.Item>
          <List.Item>
            A new datasync is required to collect the lastest game data. Use the
            'Run Data Check' button in your franchise tab.
          </List.Item>
          <List.Item>
            No Active Leagues have been set. Check your active leagues in your
            franchise tab.
          </List.Item>
        </List>
      </VideoWizardContainer>
    );
  return (
    <>
      <Space h="xl" />
      <Container size="xl" p={0}>
        <SimpleGrid cols={1} breakpoints={[{ minWidth: "md", cols: 4 }]}>
          <BasicPod
            VALUE={STATS.FIXTURES}
            LABEL="Completed Fixtures"
            ICON="CalendarEvent"
          />
          <BasicPod VALUE={STATS.RUNS} LABEL="Runs Scored" ICON="Cricket" />
          <BasicPod
            VALUE={STATS.WICKETS}
            LABEL="Wickets Taken"
            ICON="BallTennis"
          />
          <BasicPod
            VALUE={STATS.OVERS.toFixed(1)}
            LABEL="Overs Bowled"
            ICON="Refresh"
          />
        </SimpleGrid>
      </Container>
      <Space h="xl" />
      <Container size="xl" p={0}>
        <VideoWizardContainer>
          <H3 align="center" colorID={5}>
            Average Season Score {(STATS.RUNS / STATS.FIXTURES / 2).toFixed(1)}{" "}
            / {(STATS.WICKETS / STATS.FIXTURES / 2).toFixed(1)} (
            {(STATS.OVERS / STATS.FIXTURES / 2).toFixed(1)})
          </H3>
        </VideoWizardContainer>
      </Container>
      <Space h="xl" />
      <TotalFixturesByMatchDay STATS={STATS} />
    </>
  );
};

const BasicPod = ({ VALUE, LABEL, ICON }) => {
  //console.log(VALUE,LABEL,ICON)
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.colors.brand[0],
        borderRadius: 3,
        borderColor: theme.colors.brand[0],
        borderWidth: 1,
        borderStyle: "solid",
        boxShadow: "5px 5px 10px rgba(148, 148, 148, 0.25)",
        textAlign: "center",
        width: "100%",
        padding: "1em 0.5em",
      })}
    >
      <Center>
        <H3 colorID={5} weight={900} transform="uppercase">
          {VALUE}
        </H3>
      </Center>
      <Center>
        <H3 colorID={4} weight={900} transform="uppercase">
          {ICONDB[ICON].Component}
        </H3>
      </Center>
      <Center>
        <H3 colorID={2} transform="uppercase">
          {LABEL}
        </H3>
      </Center>
    </Box>
  );
};
//{colorID=4,size="lg", align='left',weight=400, underline=false,transform="capitalize" }

const TotalFixturesByMatchDay = ({ STATS }) => {
  const RenderHistory = (TYPE) => {
    const ASSETS = TYPE;
    const REMOVEWASHOUTS = RemoveWashouts(STATS.OverTheSeason);
    return [orderBy(REMOVEWASHOUTS, ["id"], ["asc"]), ASSETS];
  };
  return (
    <Container size="xl" p={0}>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Fixture count by Matchday",
          ICON: "ChartDots",
          DESCRIPTION:
            "Total number of fixtures for a given matchday in all active franchise leagues",
          TOOLBAR: false,
        }}
      />

      <VideoWizardContainer>
        <div style={{ height: "300px" }}>
          <BarChart
            DATA={RenderHistory(["FIXTURES"])}
            Settings={{
              indexBy: "date",
            }}
          />
        </div>
      </VideoWizardContainer>
    </Container>
  );
};
