/* eslint-disable react/jsx-pascal-case */
import { useSelector } from "react-redux";

// ACTIONS
import { RemoveWashouts } from "../../../actions/Stats";

import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";
import { H3, H4_UPPERCASE, P } from "../../../Theme/type";
import { Box, Center, Container, Group, Space } from "@mantine/core";
import { BarChart } from "../../../Components/Charts/Bar";
import { LineChart } from "../../../Components/Charts/Line";

import { ICONDB } from "../../../Theme/icons";
import { orderBy, filter } from "lodash";

// UI Elements
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";

export const StatisticsBatting = () => {
  const UXSTATE = useSelector((state) => state.UXSTATE);

  //console.log(UXSTATE.SelectedFranchise)
  if (UXSTATE.SelectedFranchise.attributes?.FranchiseStats === undefined)
    return <>Loading Data</>;
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Batting Statistics Overview",
          ICON: "ChartDots",
          DESCRIPTION: "A statistical overview of the active seasons listed in the Manage Leagues page under the Franchise Tab.",
          TOOLBAR: false,
        }}
      />

      <Space h="xl" />
      <BarChartRunsByFixture
        STATS={UXSTATE.SelectedFranchise.attributes.FranchiseStats}
      />
      <LineChartBattingAverages
        STATS={UXSTATE.SelectedFranchise.attributes.FranchiseStats}
      />
    </>
  );
};

export const TournamentBattingContainer = ({ FranchiseStats }) => {
  return (
    <>
      <BLOCK_Section_SubHeading LABEL="BATTING" Color={5} />
      <BarChartRunsByFixture
        STATS={FranchiseStats.franchise.data.attributes.FranchiseStats}
      />
      <LineChartBattingAverages
        STATS={FranchiseStats.franchise.data.attributes.FranchiseStats}
      />
    </>
  );
};

/* ********************************************************* */
// BATTING

const BarChartRunsByFixture = ({ STATS }) => {
  const RenderHistory = (TYPE) => {
    const ASSETS = TYPE;

    const REMOVEWASHOUTS = RemoveWashouts(STATS.OverTheSeason);
    return [orderBy(REMOVEWASHOUTS, ["id"], ["asc"]), ASSETS];
  };

  return (
    <>
    <Container size="lg"  p={0}>
        <BLOCK_Section_Title
          OBJ={{
            LABEL: "Total Runs by Matchday",
            ICON: "ChartDots",
            DESCRIPTION:
            "Total SUM of the runs scored by Game days",
            TOOLBAR: false,
          }}
        />
      </Container>
     
      <Container size="lg"  p={0}>
        <VideoWizardContainer>
          <div style={{ height: "450px" }}>
            <BarChart
              DATA={RenderHistory(["RUNS"])}
              Settings={{
                indexBy: "date",
              }}
            />
          </div>
        </VideoWizardContainer>
      </Container>
    </>
  );
};

const LineChartBattingAverages = ({ STATS }) => {
  const CreateData = (VAR) => {
    const ARR = [];

    const REMOVEWASHOUTS = RemoveWashouts(STATS.OverTheSeason);

    orderBy(REMOVEWASHOUTS, ["id"], ["asc"]).map((item, i) => {
      ARR.push({ x: item.date, y: item[VAR] });
    });
    return ARR;
  };

  const CreateSeasonInningAVG = () => {
    //console.log(STATS)
    const ARR = [],
      FIX = [],
      AVGRUN = [];

    const REMOVEWASHOUTS = RemoveWashouts(STATS.OverTheSeason);
    orderBy(REMOVEWASHOUTS, ["id"], ["asc"]).map((item, i) => {
      FIX.push(1);
      AVGRUN.push(item.AVGTOTALINNINGSRUNS);
      ARR.push({
        x: item.date,
        y: AVGRUN.reduce((a, b) => a + b) / FIX.reduce((a, b) => a + b),
      });
    });
    return ARR;
  };
  const CreateSeasonBAttingAVG = () => {
    //console.log(STATS)
    const ARR = [],
      FIX = [],
      AVGRUN = [];
    orderBy(STATS.OverTheSeason, ["id"], ["asc"]).map((item, i) => {
      FIX.push(1);
      AVGRUN.push(item.AVGBATTINGRUNS);
      ARR.push({
        x: item.date,
        y: AVGRUN.reduce((a, b) => a + b) / FIX.reduce((a, b) => a + b),
      });
    });
    return ARR;
  };

  const RenderTeamLineData = () => {
    const OBJ = [
      {
        id: "Season",
        data: CreateSeasonInningAVG(),
      },
      {
        id: "Matchday",
        data: CreateData("AVGTOTALINNINGSRUNS"),
      },
    ];

    return OBJ;
  };
  const RenderIndividualLineData = () => {
    const OBJ = [
      {
        id: "Season ",
        data: CreateSeasonBAttingAVG(),
      },
      {
        id: "Matchday",
        data: CreateData("AVGBATTINGRUNS"),
      },
    ];

    console.log(OBJ)
    return OBJ;
  };
  return (
    <>
      <Container size="lg"  p={0}>
        <BLOCK_Section_Title
          OBJ={{
            LABEL: "Inning Scores",
            ICON: "ChartDots",
            DESCRIPTION:
              "Average Inning scores per matchday, overlayed with the Seasonal Average total.",
            TOOLBAR: false,
          }}
        />
      </Container>

      <Container size="lg"  p={0}>
        <VideoWizardContainer>
          <div style={{ height: "450px" }}>
            <LineChart DATA={RenderTeamLineData()} />
          </div>
        </VideoWizardContainer>
      </Container>
      <Container size="lg"  p={0}>
        <BLOCK_Section_Title
          OBJ={{
            LABEL: "Batting averages",
            ICON: "ChartDots",
            DESCRIPTION:
              "Players Batting average per matchday, overlayed with the Seasonal batting Average.",
            TOOLBAR: false,
          }}
        />
      </Container>
      <Container size="lg"  p={0}>
        <VideoWizardContainer>
          <div style={{ height: "450px" }}>
            <LineChart DATA={RenderIndividualLineData()} />
          </div>
        </VideoWizardContainer>
      </Container>
    </>
  );
};
