import { useEffect } from "react"
import { Select } from '@mantine/core';
import {useSelectVideoTemplate} from '../../Hooks/Content/useVideoTemplate'
import {find, findIndex} from 'lodash'

export const SelectVideoTemplates = ({AssetComponentName, CALLBACK})=>{
  
  const [VideoTemplate,FetchVideoTemplate] = useSelectVideoTemplate()  

    const handleChange=(e)=>{
      const THEME = find(VideoTemplate, (o)=>{return o.id === e})
        CALLBACK(THEME)
    }

  const CreateDATA=()=>{
    const ARR=[]
    VideoTemplate.map((ITEM,i)=>{
      let INDEX = findIndex(ITEM.attributes.assets.data, (o)=>{return o.attributes.ComponentName === AssetComponentName })
      
      if(INDEX !== -1)  
        ARR.push({ value: ITEM.id, label: ITEM.attributes.Name })
    })
    return ARR
  }

  useEffect(()=>{ FetchVideoTemplate() },[])
  useEffect(()=>{ },[VideoTemplate])
    
    if(VideoTemplate === null)
    return(<>Loading</>)
    return (
        <Select
          label=""
          placeholder="Select a Template"
          data={CreateDATA()} 
          onChange={handleChange} 
          styles={(theme) => ({
            input:{
              '&, &:focus':{
                borderColor: theme.colors.brand[5],
                color: theme.colors.brand[5]
              }
            },
            item: {
              // applies styles to selected item
              '&[data-selected], &:focus': {
                '&, &:hover, &:focus': {
                  backgroundColor:theme.colors.brand[3],
                  color: theme.colors.brand[4],
                },
              },
    
              // applies styles to hovered item (with mouse or keyboard)
              '&[data-hovered], :focus': {
                backgroundColor:theme.colors.brand[5],
                color: theme.colors.brand[0],
              },
            },
          })}
        />
      );
}