import { useTheme } from "styled-components";
import styled from "styled-components";

// Animations
import { SpringToFrom } from "../../../../../../Animation/RemotionSpring";

import {
  FromTopToBottom,
  EraseFromMiddle,
  FromLeftToRight,
} from "../../../../../../Animation/ClipWipe";
import { Group } from "@mantine/core";

import { P } from "../../../../../utils/Text";

import moment from "moment";
import { TeamNames } from "./Teams";
import { getContrastColor,getDominantColor } from "../../../../../../../../actions/colors";

// Styled Components
const WRAPPER = styled.div`
  position: relative;
  height: 235px;
  margin-bottom: 40px;
  width: 100%;
`;

export const FixtureCard = ({ GAME, Durations, LEN }) => {
  //console.log(GAME)
  const theme = useTheme();

  const DATE = moment(GAME.date).format("dddd DD MMMM");
  const TIME = GAME.time.split(":");

  return (
    <WRAPPER
      style={{
        clipPath: EraseFromMiddle(Durations.Length - 25, "Springy100"),
      }}
    >
      <GroupMeta Durations={Durations} DIR={60} Top={"0px"}>
        <P
          style={{
            margin: "-50px 0 0 30px ",
            fontWeight: 400,
            fontSize: "25px",
            color: getContrastColor(getDominantColor(theme.radialGradient)),
          }}
        >
          {DATE}
        </P>
        <P
          style={{
            margin: "-50px 30px 0 0 ",
            fontWeight: 400,
            fontSize: "25px",
            color: getContrastColor(getDominantColor(theme.radialGradient)),
          }}
        >{`Game Time : ${TIME[0]}:${TIME[1]}`}</P>
      </GroupMeta>

      <GroupMeta Durations={Durations} DIR={-40} Top={"100px"}>
        <P
          style={{
            margin: "70px 0 0 10px ",
            fontWeight: 400,
            fontSize: "25px",
            clipPath: FromTopToBottom(55),
            color: getContrastColor(getDominantColor(theme.radialGradient)),
          }}
        >
          <FormBalls form={GAME?.homeTeamForm} INT={0} TOTAL={10} />
        </P>
        <P
          style={{
            margin: "74px 10px 0 0px ",
            fontWeight: 400,
            fontSize: "25px",
            clipPath: FromTopToBottom(55),
            color: getContrastColor(getDominantColor(theme.radialGradient)),
          }}
        >
          <FormBalls form={GAME?.awayTeamForm} INT={0} TOTAL={10} />
        </P>
      </GroupMeta>

      <TeamNames GAME={GAME} Durations={Durations} LEN={LEN} />
    </WRAPPER>
  );
};

//
const GroupMeta = (props) => {
  const { Durations, DIR, Top } = props;
  const theme = useTheme();

  console.log(theme.radialGradient);
  console.log(getDominantColor(theme.radialGradient))
  const CreateTranslator = (Int, DIR) => {
    const Interval = Durations.Length;
    return `translateY(${SpringToFrom(
      50,
      DIR,
      0,
      "Springy100"
    )}px) translateY(${SpringToFrom(
      Interval * Int + Interval,
      0,
      -1360,
      "Springy100"
    )}px)`;
  };
  return (
    <Group
      position="apart"
      style={{
        clipPath: FromTopToBottom(20),
        transform: `${CreateTranslator(1, DIR)}`,
        margin: "0",
        boxSizing: "border-box",
        position: "absolute",
        width: "880px",
        height: "113px",
        left: "80px",
        top: Top,
        background: theme.radialGradient, 
        border: `2px solid ${theme.Base[0]}`,
        borderRadius: "10px",
      }}
    >
      {props.children}
      
    </Group>
  );
};

function FormBalls({ form }) {
  const formBalls = form
    .trim()
    .split(" ")
    .map((ball, index) => {
      let bgColor;
      if (ball === "W") {
        bgColor = "green";
      } else if (ball === "L") {
        bgColor = "red";
      } else if (ball === "D") {
        bgColor = "gray";
      }

      return (
        <div
          key={index}
          style={{
            clipPath: FromLeftToRight(60 + 20 * index, "Springy100"),
            width: "50px",
            height: "20px",
            borderRadius: "0px",
            backgroundColor: bgColor,
          }}
        />
      );
    });

  return (
    <Group position="center" spacing={2}>
      {formBalls}
    </Group>
  );
}
