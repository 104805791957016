import { Text,Table,Image} from '@mantine/core';
// Theme
import {  H3, P } from "../../../Theme/type";
import {VideoWizardContainer} from '../../../Theme/Structure/BodyContainer'
import { BLOCK_Section_SubHeading } from '../../interface/BLOCK_Section_SubHeading';
export const Top5DataCheck = ({VIDEODATA})=>{
    console.log(VIDEODATA.DATAOBJ.DATAOBJ.CATEGORY)
    return(
        <>

        <BLOCK_Section_SubHeading LABEL="Top 5 Data" Color={5} />
        <VideoWizardContainer >
           
                <Text size="lg"  weight={700}></Text>  
                    <Table> 
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>ID</th>
                                    <th>PLAYER</th>
                                    <th>Team</th>
                                    <th>Variable</th>
                                </tr> 
                            </thead>
                        <tbody>
              
                        {
                            VIDEODATA.DATAOBJ.DATAOBJ.CATEGORY.map((item,i)=>{
                                return(
                                    <tr  key={i}> 
                                        <td>
                                        <Image 
                                            src={item.IMAGE}
                                            height={50}
                                            width={50}
                                            radius={100}
                                            /></td>
                                        <td><P>{item.ID}</P></td>
                                        <td><P>{item.Name}</P></td>
                                        <td><P>{item.Team.Name}</P></td>
                                        <td><P>{item.VARIABLE}</P></td> 
                                    </tr> 
                                    )
                            })
                        }
                        </tbody>
                    </Table>
                </VideoWizardContainer>
        </>
    )
}