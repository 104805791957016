import { forwardRef } from 'react';
import {ActionIcon } from "@mantine/core"
import { Link } from "react-router-dom";
import {ICONDB} from '../../../Theme/icons'
import {ToolTipWrapper} from './BtnToolTipWrapper'

export const CALLBACKIcon =forwardRef(({CALLBACK, ICON, TIPOBJ=false},ref)=> {
    return (
      <ToolTipWrapper TIPOBJ={TIPOBJ}>
      <ActionIcon   
        size="lg" 
        radius="sm"  
        variant="light"
        ref={ref}
        onClick={CALLBACK}
          sx={(theme) => ({     
           borderColor:theme.colors.brand[1],
            background: theme.colors.gradients[3],
            color:theme.colors.brand[3],
              '&:hover': {
                //backgroundColor: theme.colors.brand[3],
                background: theme.colors.gradients[2],
                color:theme.colors.brand[2],
              },
            })}>
        {ICONDB[ICON].Component}
      </ActionIcon>
      </ToolTipWrapper>
    );
  })
 

export const ToLinkIcon =forwardRef(({TO, ICON,TIPOBJ=false},ref)=> {
    return (
      <ToolTipWrapper TIPOBJ={TIPOBJ}>
      <ActionIcon   
        size="lg" 
        radius="sm" 
        variant="light"
        component={Link}
        ref={ref}
        to={TO}
          sx={(theme) => ({     
            borderColor:theme.colors.brand[1],
            background: theme.colors.gradients[3],
            color:theme.colors.brand[3],
              '&:hover': {
                background: theme.colors.gradients[2],
                color:theme.colors.brand[2],
              },
            })}>
        {ICONDB[ICON].Component}
      </ActionIcon>
      </ToolTipWrapper>
    );
  })