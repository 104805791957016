import { useEffect } from "react"
import { Select } from '@mantine/core';
import {useSelectVideoTheme} from '../../Hooks/Content/useVideoTheme'
import {find} from 'lodash'
export const SelectVideoTheme = ({DATA, CALLBACK})=>{
  
  const [VideoTheme,FetchVideoTheme] = useSelectVideoTheme()  

    const handleChange=(e)=>{
      const THEME = find(VideoTheme, (o)=>{return o.id === e})
        CALLBACK(THEME)
    }

  const CreateDATA=()=>{
    const ARR=[]
    VideoTheme.map((ITEM,i)=>{
      ARR.push({ value: ITEM.id, label: ITEM.attributes.Name })
    })
    
    return ARR
  }

  useEffect(()=>{ FetchVideoTheme() },[])
  useEffect(()=>{ },[VideoTheme])
    
    if(VideoTheme === null)
    return(<>Loading</>)
    return (
        <Select
          label=""
          placeholder="Select a Theme"
          data={CreateDATA()} 
          onChange={handleChange} 
          styles={(theme) => ({
            input:{
              '&, &:focus':{
                borderColor: theme.colors.brand[5],
                color: theme.colors.brand[5]
              }
            },
            item: {
              // applies styles to selected item
              '&[data-selected], &:focus': {
                '&, &:hover, &:focus': {
                  backgroundColor:theme.colors.brand[3],
                  color: theme.colors.brand[4],
                },
              },
    
              // applies styles to hovered item (with mouse or keyboard)
              '&[data-hovered], :focus': {
                backgroundColor:theme.colors.brand[5],
                color: theme.colors.brand[0],
              },
            },
          })}
        />
      );
} 