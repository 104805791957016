/* eslint-disable react/jsx-pascal-case */
import { useEffect } from "react";

import { useGetPlayerMilestones } from "../../Hooks/user/useGetPlayerMilestones";
import { Container, SimpleGrid, Space } from "@mantine/core";
import { BTN_ICON_LINK } from "../../Theme/Structure/Button/BTNS";
import { ICONDB } from "../../Theme/icons";
import { UILargeComponent } from "../../Components/Global/Skeleton";
import { orderBy } from "lodash";
// UI ELEMENTS
import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";
import { BLOCK_Section_SubHeading } from "../../Components/interface/BLOCK_Section_SubHeading";
import {
  TABLE_WRAPPER,
  TABLE_THEAD_WRAPPER,
  TABLE_TD,
} from "../../Components/interface/Wrapper/Table";
import { VideoWizardContainer } from "../../Theme/Structure/BodyContainer";
import { COPY_INFO } from "../../Components/interface/BLOCK_Info";

import {MilestoneCard} from './Components/Card'

export const DisplayMilestones = () => {
  const [Milestones, FetchMilestones] = useGetPlayerMilestones();

  const OBJ = [
    {
      PATH: "Matches",
      LABEL: "Matches (Base 100)",
      VAR: "MATCHES",
    },
    {
      PATH: "RUNS",
      LABEL: "Runs (Base 1000)",
      VAR: "RUNS",
    },
    {
      PATH: "WICKETS",
      LABEL: "WICKETS (Base 1000)",
      VAR: "WICKETS",
    },
    {
      PATH: "FIFTIES",
      LABEL: "FIFTIES (Base 100)",
      VAR: "FIFTIES",
    },
    {
      PATH: "HUNDREDS",
      LABEL: "HUNDREDS (Base 10)",
      VAR: "HUNDREDS",
    },
    {
      PATH: "W3",
      LABEL: "3 Wicket Hauls (Base 100)",
      VAR: "W3",
    },
    {
      PATH: "W5",
      LABEL: "5 Wicket Hauls (Base 50)",
      VAR: "W5",
    },
  ];
  useEffect(() => {
    FetchMilestones();
  }, []);
  useEffect(() => {
    console.log(Milestones)
  }, [Milestones]);

  if (!Milestones) return <UILargeComponent />;
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{ 
          LABEL: "Approaching  MILESTONES",
          ICON: "MoodSmile",
          DESCRIPTION:
            "Upcoming career milestones. Assets can be created for all Milestones listed.",
          TOOLBAR: false,
        }}
      />

      <Container size={"lg"}>
        {OBJ.map((item, i) => {
          return (
            <AddMilestone
              key={i}
              DATA={Milestones.Approaching[item.PATH]}
              LABEL={item.LABEL}
              VAR={item.VAR}
            />
          );
        })}
      </Container>
    </>
  );
};

const AddMilestone = ({ DATA, LABEL, VAR }) => {
  if (DATA.length === 0)
    return (
      <div>
        
        <BLOCK_Section_SubHeading LABEL={`${LABEL}`} Color={5} />
        <VideoWizardContainer>
        <COPY_INFO COPY={`There are currently no players within the milestone range for career ${LABEL}`}/> 
        </VideoWizardContainer>
        <Space h="xl" />
      </div>
    );
  return (
    <div>
      <BLOCK_Section_SubHeading LABEL={`${LABEL}`} Color={5} />
      <VideoWizardContainer>
        <MilestoneFrame
          Results={orderBy(DATA, (obj) => parseInt(obj[VAR], 10), ["desc"])}
          VAR={VAR}
        />
      </VideoWizardContainer>
      <Space h="xl" />
    </div>
  );
};

const MilestoneFrame = ({ Results, VAR }) => {
  return (
    <>
      <SimpleGrid cols={1} breakpoints={[{ minWidth: "xs", cols: 2 },{ minWidth: "md", cols: 3 }]}>

      
     {Results.map((player, i) => {
            return <MilestoneCard player={player} VAR={VAR} key={i} />;
          })}
          </SimpleGrid>
     {/*  <TABLE_WRAPPER>
        <TABLE_THEAD_WRAPPER HEADERS={["ID", "Player", VAR, "Profile"]} />

        <tbody>
          {Results.map((player, i) => {
            return <MilestoneResults player={player} VAR={VAR} key={i} />;
          })}
        </tbody>
      </TABLE_WRAPPER> */}
    </>
  );
};

const MilestoneResults = ({ player, VAR }) => {
  return (
    
    <tr key={player.Name}>
      <TABLE_TD>{player.PlayerID}</TABLE_TD>
      <TABLE_TD>{player.Name}</TABLE_TD>
      <TABLE_TD>{player[VAR]}</TABLE_TD>
      <TABLE_TD>
        <BTN_ICON_LINK
          TO={`https://www.lastmanstands.com/cricket-player/t20&playerid=${player.PlayerID}`}
          ICON="ExternalLink"
          TIPOBJ={{
            LABEL: "Players Profile",
            COLOR: "gray",
            POSITION: "left",
          }}
        />
      </TABLE_TD>
    </tr>
  );
};


