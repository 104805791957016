import { Box, Group } from "@mantine/core";
import { H3 } from "../../Theme/type";
import { ColoredContainer } from "../../Theme/Structure/BodyContainer";
import { ICONDB } from "../../Theme/icons";

/*
    <BLOCK_Section_SubHeading LABEL='Active Season Overview' Color={5}/>
*/
export const BLOCK_Section_SubHeading = ({ LABEL, Color,BGColor=0 }) => {
  return (
    <ColoredContainer Padding={0} BGColor={BGColor}>
      <Group
        position="left"
        spacing={3}
        sx={(theme) => ({
          justifyContent: "flex-start",
          alignItems: "center",
          alignContent: "center",
          display: "flex",
        })}
      >
        <Box
          sx={(theme) => ({
            borderRadius: "5px",
            padding: "0px",
          })}
        >
          <H3 colorID={Color} size={'xl'} weight={400} transform={`uppercase`} underline={true}>{LABEL}</H3> 
        </Box>
      </Group>
    </ColoredContainer>
    
  );
};

export const BLOCK_Section_SubHeading_INVERT = ({ LABEL, Color }) => {
  return (
    <ColoredContainer Padding={0} BGColor={0}>
      <Group
        position="left" 
        noWrap={true}
        spacing={3}
        sx={(theme) => ({
          justifyContent: "flex-start",
          alignItems: "center",
          alignContent: "center",
          display: "flex",
        })}
      >
       
        <Box
          sx={(theme) => ({
            backgroundColor: theme.colors.brand[Color],
            width: "3px",
            borderRadius: "5px",
          })}
        >
          &nbsp;
        </Box>
        <Box
          sx={(theme) => ({
            borderRadius: "5px",
            padding: "0px",
          })}
        >
          <H3 colorID={Color} lineheight={'1em'}>{LABEL}</H3>
        </Box>
      </Group>
    </ColoredContainer>
  );
};