import { ThemeProvider } from 'styled-components'


// Import Design Templates for MATCHDAYRESULT.
// Add new deisng patterns below
// GLASS
import {MATCHDAYRESULTS_GLASS} from '../../theme/Templates/GLASS/index'
import {MATCHDAYRESULTS_ARROWS} from '../../theme/Templates/ARROWS/index'
import {MATCHDAYRESULTS_ROUNDED} from '../../theme/Templates/ROUNDED/index'
// END


export const Build_MATCHDAYRESULTS_4by5 = (props)=>{
	const {RENDER} = props

	const TEMPLATES={ 
		"Glass":<MATCHDAYRESULTS_GLASS    RENDER={RENDER}/>, 
		"Arrows":<MATCHDAYRESULTS_ARROWS   RENDER={RENDER}/>,
		"Rounded":<MATCHDAYRESULTS_ROUNDED   RENDER={RENDER}/>,
		
	}   
    return (  
		<ThemeProvider theme={RENDER.THEME.VideoTheme.Theme} >  
			{ 
				TEMPLATES[RENDER.THEME.VideoTemplate] 
			}
		</ThemeProvider> 
    );  
}