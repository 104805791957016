import { IndexHeader } from "../Sections/INDEX_HEADER";
import { HeroImageRight } from "../Sections/INDEX_HERO";
import { FooterSimple } from "../Sections/INDEX_Footer";
import { Container } from "@mantine/core";
import { SectionWrapper } from "../Components/Containers";


export const PAGE_Terms = ()=>{
    return(
        <>
        <IndexHeader INDEX={1} />
        <HeroImageRight />
        <SectionWrapper BGCOLOR={0}>
      <Container size={"md"} py={90}>
        
        <h1>Terms and Conditions</h1>

<h2>Introduction</h2>
<p>These terms and conditions govern your use of the Statto Social application and website ("Statto Social" or "the Service"). By accessing or using Statto Social, you agree to be bound by the terms and conditions set forth below. If you do not wish to be bound by these terms and conditions, you may not access or use Statto Social.</p>

<h2>Use of the Service</h2>
<p>In order to use Statto Social, you must agree to provide accurate and complete registration information and to comply with the terms and conditions of this Agreement. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password.</p>

<h2>Data Collection and Use</h2>
<p>Statto Social collects data from the Lastmanstands.com website for the duration of the season in which you are tracking. By using the Service, you agree to allow Statto Social to collect and use this data to create videos and images for your social media accounts. Members can also upload player and team photos to Statto Social to use in videos and images.</p>

<h2>Sponsored Content</h2>
<p>Statto Social allows users to create sponsored videos by adding a sponsor's logo or information to a video. By using this feature, you agree to comply with all applicable laws and regulations and to obtain any necessary permissions and approvals from the sponsor and any other relevant parties.</p>

<h2>Termination</h2>
<p>Statto Social reserves the right to terminate your use of the Service at any time and for any reason, with or without notice.</p>

<h2>Modification of Terms</h2>
<p>Statto Social reserves the right to modify these terms and conditions at any time and without notice, and such modifications shall be effective immediately upon posting on the Service. You agree to review these terms and conditions periodically to be aware of such modifications and your continued access or use of the Service shall be deemed your conclusive acceptance of the modified Agreement.</p>

</Container></SectionWrapper>
  
        <FooterSimple />
      </> 
    )
} 