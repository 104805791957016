import {
  interpolate,
  useCurrentFrame,
  useVideoConfig,
  spring,
  Img,
  Audio,
} from "remotion";
import { FullWidthWrapper } from "../utils/Containers";
import { H4 } from "../utils/Text";
import { interpolateOpacityByFrame } from "../../Animation/interpolate";
// Sponsorsed Components
import { SponsorLogoEND } from "./Sponsor/Logo";

import { VideoTypeLabel } from "./OpeningTitles/VideoTypeLabel";
import { DisplayVideoDate } from "./OpeningTitles/VideoDates";

import { BroughtToYouBy } from "./Sponsor/Broughttoyouby";
import { SponsorTagline } from "./Sponsor/SponsorTagline";
import { DisplaySponsorLogo } from "./Sponsor/SponsorLogo";
import { SponsorCTA } from "./Sponsor/SponsorCTA";
import { ImageRatio } from "../../../../actions/Images";

export const OpeningSponsorSequence = (props) => {
  const SPONSOR =
    props.DATA.OrderMetadata.TournamentDetails.sponsor?.data.attributes;
  const frame = useCurrentFrame();
  const styles = ImageRatio(
    props.DATA.OrderMetadata.TournamentDetails.LOGO.data.attributes
  ).SponsorTitle;
  return (
    <FullWidthWrapper
      style={{
        justifyContent: "space-around",
      }}
    >
      <div
        style={{
          marginTop: "5em",
        }}
      >
        <div
          style={{
            marginBottom: "2em",
            opacity: interpolateOpacityByFrame(frame, 7, 15, 0, 1),
            textAlign: "center",
          }}
        >
          <Img
            style={styles}
            src={
              props.DATA.OrderMetadata.TournamentDetails.LOGO.data.attributes
                .url
            }
          />
        </div>
        <VideoTypeLabel VideoLabel={props.VideoLabel} />
        <DisplayVideoDate VideoDate={props.DATA.OrderMetadata.FromDate} />
      </div>
      <div
        style={{
          width: "100%",
        }}
      >
        <BroughtToYouBy />
        <SponsorTagline TAGLINE={SPONSOR.Name} />
        <DisplaySponsorLogo SPONSOR={SPONSOR} />
        <SponsorCTA CTA={SPONSOR.Tagline} />
      </div>
      <Audio
        src={`https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/TournamentSponsor.mp3`}
        playbackRate={1}
        startFrom={0} // if composition is 30fps, then it will start at 2s
      />
    </FullWidthWrapper>
  );
};

//

export const ClosingSponsorSequence = (props) => {
  //console.log(props.DATA.OrderMetadata.Sponsor)
  const SPONSOR = props.DATA.OrderMetadata.Sponsor;

  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const FadeIn = (START, END) => {
    return interpolate(frame, [START, END], [0, 1], {
      extrapolateRight: "clamp",
    });
  };

  const StaggerSpring = (int) => {
    return spring({
      fps,
      from: 50,
      to: 0,
      frame: frame - int,
      config: {
        damping: 80,
        stiffness: 100,
        mass: 0.5,
      },
    });
  };
  return (
    <div
      style={{
        position: "absolute",
        top: "2em",
        left: "auto",
        right: "auto",
        width: "100%",
      }}
    >
      <div
        style={{
          opacity: FadeIn(0, 30),
          transform: `translateY(${StaggerSpring(0)}px)`,
        }}
      >
        <H4>Brought to you by</H4>
      </div>
      <div
        style={{
          opacity: FadeIn(7, 45),
          textAlign: "center",
          transform: `translateY(${StaggerSpring(7)}px)`,
        }}
      >
        <SponsorLogoEND Sponsor={SPONSOR} />
      </div>
    </div>
  );
};

export const BodySponsorSequence = (props) => {
  //console.log(props.DATA.OrderMetadata.Sponsor)
  const SPONSOR = props.DATA.OrderMetadata.Sponsor;

  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const FadeIn = (START, END) => {
    return interpolate(frame, [START, END], [0, 1], {
      extrapolateRight: "clamp",
    });
  };

  const StaggerSpring = (int) => {
    return spring({
      fps,
      from: 50,
      to: 0,
      frame: frame - int,
      config: {
        damping: 80,
        stiffness: 100,
        mass: 0.5,
      },
    });
  };
  return (
    <div style={{ position: "absolute", bottom: ".5em", left: "27em" }}>
      <div
        style={{
          opacity: FadeIn(7, 45),
          transform: `translateY(${StaggerSpring(7)}px)`,
        }}
      >
        <Img
          style={{ height: "40px" }}
          src={`${SPONSOR.Logo.formats.thumbnail.url}`}
        />
      </div>
    </div>
  );
};
