import {gql,} from "@apollo/client";


export const QUERY = (APISETTINGS)=>{

    return gql`query GameDetails {
      fixture(id:${APISETTINGS.GameID}){
        data{
          id
          attributes{
            fixtureID
            HomeTeam
            AwayTeam
            Date
            Time
            ground{
              data{
                id
                attributes{
                  Name
                }
              }
            }
            umpire{
              data{ 
                id
                attributes{
                  Name
                }
              }
            }
            teams{
              data{
                id
                attributes{
                  Name
                  Games
                  Won
                  Lost
                  Win_Ratio
                  Form
                  City_Rank
                  National_Rank
                  World_Rank
                  Logo {
                          data {
                            id
                            attributes {
                              formats
                              url
                            }
                          }
                        }
                  player_battings(
                    pagination: { start: 0, limit: -1 }
                    filters:{fixture:{watch_list:{id:{in: [${APISETTINGS.IDS}]}}}}){
                    data{ 
                      id
                      attributes{ 
                        BATTING_Runs
                        BATTING_Balls
                        BATTING_sixes
                        player{
                          data{
                            id
                            attributes{
                              Name
                              image{data {
                                id
                                attributes {
                                  formats
                                  url
                                }
                              }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  player_bowlings(
                    pagination: { start: 0, limit: -1 }
                    filters:{fixture:{watch_list:{id:{in: [${APISETTINGS.IDS}]}}}}){
                    data{
                      id
                      attributes{
                        BOWLING_Overs
                        BOWLING_Runs
                        BOWLING_Wkts
                        BOWLING_Econ
                        player{
                          data{
                            id
                            attributes{
                              Name
                              image{data {
                                id
                                attributes {
                                  formats
                                  url
                                }
                              }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
          }`
}
  