import { orderBy } from "lodash";
// CORE Components
import { SupImages } from "./SuplimentaryImages";

import { StatContainer } from "./StatContainer";
import { COPY, EMPASISVALUE } from "./Copy";
export const SectionBoundaries = ({ DATA, OBJ }) => {
  const Sixes = orderBy(
    DATA.DATAOBJ.TeamStats.PLAYERS,
    ["BATTING_sixes"],
    ["desc"]
  ).slice(0, 5);
  return (
    <>
      <Sentence
        DURATION={OBJ.DURATION}
        Name={DATA.DATAOBJ.General.Team}
        Runs={DATA.DATAOBJ.TeamStats.BATTING.RUNS}
        BALLS={DATA.DATAOBJ.TeamStats.BATTING.BALLS}
        Games={DATA.DATAOBJ.General.Played}
        TotalBoundaires={
          DATA.DATAOBJ.TeamStats.BATTING.FOUR * 4 + DATA.DATAOBJ.TeamStats.BATTING.SIX * 6
        }
        Fours={DATA.DATAOBJ.TeamStats.BATTING.FOUR}
        Sixes={DATA.DATAOBJ.TeamStats.BATTING.SIX}
        PlayerNumbers={DATA.DATAOBJ.TeamStats.PLAYERS.length}
      />

      <StatContainer
        LABEL={`Boundaries`}
        DURATION={OBJ.DURATION}
        TIMINGS={{
          SlideIn: 0,
          LabelPosition: 100,
          ClipIn: 15,
          SlideUp: 30,
        }}
      >
    
        <SupImages
          DATA={Sixes}
          DURATION={OBJ.DURATION}
          Limit={5}
          VAR={"BATTING_sixes"}
          Label="Top 5  Most Sixes"
        />
      </StatContainer>
    </>
  );
};

const Sentence = ({ DURATION, Runs, Fours, Sixes, TotalBoundaires }) => {
  return (
    <>
      <COPY DURATION={DURATION}>
        <EMPASISVALUE VALUE={TotalBoundaires} /> RUNS OR <EMPASISVALUE
          VALUE={`${((TotalBoundaires / Runs) * 100).toFixed(2)}%`}
        />{" "}
        OF THEIR RUns CAME IN BOUNDARIES,
        <br />
        <EMPASISVALUE VALUE={Fours} /> Fours ({Fours*4}) &amp; <EMPASISVALUE VALUE={Sixes} /> Sixes ({Sixes*6})
      </COPY>
    </>
  );
};