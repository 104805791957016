import {
  Container,
  Title,
  Accordion,
  createStyles,
  Divider,
  Box,
} from "@mantine/core";
import { useEffect } from "react";
import { Player } from "video-react";
import { PreviewVideoRenderWrapper } from "../../Components/interface/Wrapper/PreviewVideoRenderWrapper";

import { UISkeletonLines } from "../../Components/Global/Skeleton";
import { PageHeader } from "../../Components/interface/ProductHeader";
import { useGetHowTos } from "../../Hooks/Content/useHowTos";
import { H3, P } from "../../Theme/type";
export const HOW_TO = () => {
  return (
    <>
      <PageHeader NavPosition="How To" />

      <FaqSimple />
    </>
  );
};

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    minHeight: 650,
  },

  title: {
    marginBottom: theme.spacing.xl * 1.5,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,
  },
}));

export function FaqSimple() {
  const [HowTos, GetHowTos] = useGetHowTos();
  const { classes } = useStyles();

  useEffect(() => {
    GetHowTos();
  }, []);
  useEffect(() => {}, [HowTos]);

  if (HowTos === null) return <UISkeletonLines />;
  return (
    <Container size="lg" className={classes.wrapper}>
      <H3 colorID={5}>How do i ...</H3>

      <Accordion variant="separated">
        {HowTos.map((Q, i) => {
          return (
            <Accordion.Item
              key={i}
              className={classes.item}
              value={`Question-${i}`}
            >
              <Accordion.Control>
                <H3 colorID={3} transform="uppercase">
                  ... {Q.attributes.Question}
                </H3>
              </Accordion.Control>
              <Accordion.Panel>
                <Divider pb={20} />
                <P>{Q.attributes.Answer}</P>
                <P></P>
                <HowToPlayer VIDEO={Q.attributes.VideoURL} />
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </Container>
  );
}

const HowToPlayer = ({ VIDEO }) => {
  useEffect(() => {
    console.log(VIDEO)
  }, [VIDEO]);
  if(VIDEO === null)
  return false
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Player>
        <source src={VIDEO} />
      </Player>
    </Box>
  );
};
