/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateTOTWImage } from "../../../store/Reducer/VideoRendering";
import { Container, Grid } from "@mantine/core";

// Components
import { SinglePlayerImagewithChangeOption } from "./Components/SinglePlayerImagewithChangeOptions";

// UTILS
import { findIndex } from "lodash";
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";

// Components
import { UploadNewPlayerImageContainer } from "./Components/UploadNewPlayerImageContainer";

/* ******************************************************************** 
// PLAYER IMAGE, UI DISPLAY WITH CHANGE OFD IMAGE OPTION

1. Image Gallery Containers
2. 

/* ******************************************************************** */
export const EditTOTWImages = ({ PlayerImages }) => {
  const [NewImage, SetNewImage] = useState(false);
  const [Player, SetPlayer] = useState(false);
  const [selectFromGallery, SetFromGallery] = useState(false);
  const dispatch = useDispatch();
  const ChangeImage = (id) => {
    SetPlayer(id);
    SetNewImage(true);
  };

  useEffect(() => {
    SetNewImage(false);
  }, [PlayerImages, selectFromGallery]);

  const UpdateDATAOBJ = (ImageFile) => {
    let THISID;
    Object.keys(PlayerImages).map((KEY, i) => {
      if (PlayerImages[KEY].ID === Player.ID) THISID = i;
    });

    //console.log("UPDATEINDEX", THISID);
    dispatch(updateTOTWImage({ ID: THISID, url: ImageFile.url }));
  };

  if (NewImage)
    return (
      <UploadNewPlayerImageContainer
        Player={Player}
        SetNewImage={SetNewImage}
        UpdateDATAOBJ={UpdateDATAOBJ}
      />
    );
  return (
    <>
      <Container size={"lg"} my={30}>
        <BLOCK_Section_SubHeading LABEL="Image Selector" Color={5} />
        <Grid columns={12} justify="flex-start" align="flex-start">
          {Object.keys(PlayerImages).map((KEY, i) => {
            return (
              <Grid.Col
                span={6}
                xs={6}
                sm={4}
                md={3}
                xl={3}
                justify="center"
                align="center"
                key={i}
              >
                <SinglePlayerImagewithChangeOption
                  IMAGE={PlayerImages[KEY].IMAGE}
                  NAME={PlayerImages[KEY].Name}
                  PLAYER={PlayerImages[KEY]}
                  SetFromGallery={SetFromGallery}
                  ChangeImage={ChangeImage}
                  DispatchFUNC={updateTOTWImage}
                  INDEX={i}
                />
              </Grid.Col>
            );
          })}
        </Grid>
      </Container>
    </>
  );
};
