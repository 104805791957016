import { CALLBACKIcon } from "../../../../Theme/Structure/Button/ActionIcons";
import { filter } from "lodash";
import { useRef, useState } from "react";
import { PreviewVideoModal } from "../../../../Components/Global/PlayVideoModal";
import {
  BTN_ASIDE_LINK_INVERT,
  BTN_ICON_CALLBACK,
} from "../../../../Theme/Structure/Button/BTNS";
import { Group, Loader } from "@mantine/core";

export const DownloadCTABTNS = (props) => {
  const [opened, setOpened] = useState(false);
  const [videoURL, setVideoURL] = useState(false);
  const {
    DOWNLOAD,
    SetVideoModal,
    FilterItems,
    setRefreshRender,
    RefreshRender,
  } = props;
  //RefreshRender

  return (
    <>
      <Group position="apart">
       
        {/* <PreviewBTN
          DOWNLOAD={DOWNLOAD}
          setOpened={setOpened}
          setVideoURL={setVideoURL}
        /> */}
         <ViewDownloadCTA {...props} />
      </Group>

      <PreviewVideoModal
        setOpened={setOpened}
        opened={opened}
        videoURL={videoURL}
      />
    </>
  );
};

const ViewDownloadCTA = (props) => {
  const {
    DOWNLOAD,
    SetVideoModal,
    FilterItems,
    setRefreshRender,
    RefreshRender,
  } = props;
  const CTA = (DOWNLOAD) => {
    const ARR = filter(DOWNLOAD.attributes.render_lists.data, (o) => {
      return o.attributes.hasBeenProcessed;
    });

    const VideoRenderCheck = () => {
      setRefreshRender(true);
    };

    return ARR.length === 0 ? (
      <BTN_ICON_CALLBACK
        CALLBACK={() => {
          VideoRenderCheck();
        }}
        ICON="Refresh"
        LABEL="Refresh"
        TIPOBJ={{
          LABEL: "Check for updates",
          COLOR: "gray",
          POSITION: "top",
        }}
      />
    ) : (
      <BTN_ASIDE_LINK_INVERT
        TO={`/downloadable/${DOWNLOAD.id}`}
        LABEL="View"
        TIPOBJ={{
          LABEL: "View Downloadables",
          COLOR: "gray",
          POSITION: "top",
        }}
      />
    );
  };
  return RefreshRender ? (
    <Loader color="teal" size="xs" variant="bars" />
  ) : (
    CTA(DOWNLOAD)
  );
};

/* Video preview BTN, opens Modal with rendered video */
const PreviewBTN = ({ DOWNLOAD, setOpened, setVideoURL }) => {
  const inputRef = useRef(null);

  const SetVideoModal = (Item) => {
    setOpened(true);
    setVideoURL(Item[0].attributes.DownloadURI);
  };

  const Preview = (DOWNLOAD) => {
    const ARR = filter(DOWNLOAD.attributes.render_lists.data, (o) => {
      return o.attributes.hasBeenProcessed && !o.attributes.hasRenderError;
    });
    return ARR.length === 0 ? (
      false
    ) : (
      <CALLBACKIcon
        ref={inputRef}
        TIPOBJ={{ LABEL: "Preview", COLOR: "orange" }}
        ICON={`PlayerPlay`}
        CALLBACK={() => {
          SetVideoModal(DOWNLOAD.attributes.render_lists.data);
        }}
      />
    );
  };

  return Preview(DOWNLOAD);
};
