import { useCurrentFrame} from 'remotion';
import { Image } from '@mantine/core';
import { useTheme } from 'styled-components'
import {CalendarEvent,Map2,Clock,Building,BuildingSkyscraper,World} from 'tabler-icons-react';
import styled from 'styled-components';

import moment from 'moment';
// Animation
import {interpolateOpacityByFrame} from '../../../../../../../Animation/interpolate'
import {SpringToFrom} from '../../../../../../../Animation/RemotionSpring'
import { FromMiddle,FromBottomToTop } from '../../../../../../../Animation/ClipWipe';
// Components

import { H1, H2,H3,H4 } from '../../../../../../utils/Text';

export const RankingWrapper = ({D,STYLE})=>{
    const frame = useCurrentFrame();
    const theme = useTheme();
    const Theme_Stats = theme.GOTW.Stats[STYLE]

    const OPACITY =(START, END)=>interpolateOpacityByFrame(frame, START,END, 0, 1)
    const TRANSLATEY =(START)=>`translateY(${SpringToFrom(START,100,10)}px)`
    const TRANSLATEX = (START)=>`translateX(${SpringToFrom(START,-100,0)}px)`

    return(
        <RankContainer>
        <div>
            <Building size={100} 
            style={{
                opacity:OPACITY(15,30),
                transform: TRANSLATEY(15),
                stroke: Theme_Stats.Icons,
                strokeWidth:'1px'

            }}/>
            <H1
            style={{
                opacity:OPACITY(15,30),
                transform: TRANSLATEX(15),
                color:Theme_Stats.IconColor
            }}
            >{D.City_Rank}</H1>
            <H4
             style={{
                opacity:OPACITY(15,30),
                transform: TRANSLATEX(15),
                color:Theme_Stats.IconColor,
                letterSpacing:'0px'
            }}>
                City</H4>
        </div>
        <div>
            <BuildingSkyscraper size={100} 
            style={{
                opacity:OPACITY(25,40),
                transform: TRANSLATEY(25),
                stroke: Theme_Stats.Icons,
                strokeWidth:'1px'
            }}
            />
            <H1
             style={{
                opacity:OPACITY(25,40),
                transform: TRANSLATEX(25),
                color:Theme_Stats.IconColor
            }}
            >{D.National_Rank}</H1>
            <H4
             style={{
                opacity:OPACITY(25,40),
                transform: TRANSLATEX(25),
                color:Theme_Stats.IconColor,
                letterSpacing:'0px'
            }}>National</H4>
        </div>
        <div>
            <World size={100} 
             style={{
                opacity:OPACITY(35,50),
                transform: TRANSLATEY(35),
                stroke: Theme_Stats.Icons,
                strokeWidth:'1px'
            }}
            />
            <H1 style={{
                opacity:OPACITY(35,50),
                transform: TRANSLATEX(35),
                color:Theme_Stats.IconColor
            }}>{D.World_Rank}</H1>
            <H4 style={{
                opacity:OPACITY(35,50),
                transform: TRANSLATEX(35),
                color:Theme_Stats.IconColor,
                letterSpacing:'0px'
            }}>World</H4>
        </div>
     </RankContainer>
    )
}

const RankContainer = styled.div`

    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;
    width: 100%;
    margin:0px 0 ;

`