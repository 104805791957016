import { useEffect, useState } from "react" 
import {ENV} from '../../Config/RunTimeConfig'
import { useDispatch } from 'react-redux'

import { parseCookies,setCookie } from 'nookies'

// FUNC : GET SINGLE
// FUNC : GET 
// FUNC : CREATE
// FUNC : DELETE 

// FUNC : UPDATE 
export const useUpdateMeVideoSettings = (ctx)=>{
    ///api/users/:id
    const [UpdateUser,setUpdateUser] = useState(null);
    const jwt = parseCookies(ctx).jwt
    const PutUpdateUser = async (_DATA,_ID)=>{
      //console.log(_DATA,_ID)
        const res = await fetch(`${ENV.API_URL}/api/user-video-settings/${_ID}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({
                data: {
                    Settings:_DATA
                  }
            })
        })
        const UpdateStatus = await res.json();
      //console.log(UpdateStatus)

        setUpdateUser(UpdateStatus.data)
    }
    return[UpdateUser,PutUpdateUser]
}
