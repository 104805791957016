// Global
import { Audio, interpolate, useCurrentFrame, Sequence } from "remotion";
import { ArrowsTopTail, DivisionWinnersTopAndTail } from "./Arrows_Top_Tail";
// Components
import { POTDBATTING_BODY } from "./Compositions/WEEKLY/POTD/BATTING/POTDBATTING_BODY";
import { POTDBOWLING_BODY } from "./Compositions/WEEKLY/POTD/BOWLING/POTDBOWLING_BODY";
import { TOP5_BODY } from "./Compositions/TOP5/Top5_BODY";
import { MATCHDAYRESULTS_BODY } from "./Compositions/WEEKLY/MATCHDAY/MATCHDAYRESULTS/MATCHDAYRESULTS_BODY";
import { MATCHDAYFIXTURES_BODY } from "./Compositions/WEEKLY/MATCHDAY/MATCHDATFIXTURES/MATCHDAYFIXTURES_BODY";
import { MOTD_BODY } from "./Compositions/WEEKLY/MATCHDAY/MOTD/GAMEOFTHEWEEK_BODY";
import { MOTD_BODY_LITE } from "./Compositions/WEEKLY/MATCHDAY/MOTDLITE/GAMEOFTHEWEEK_BODY";
import { POSTMATCHINTERVIEW_BODY } from "./Compositions/WEEKLY/INTERVIEWS/POSTMATCH/POSTMATCHINTERVIEW_BODY";
import { TOURNAMENTWINNERS_BODY } from "./Compositions/WEEKLY/WINNERS/TOURNAMENTWINNERS";
import { THISWEEKINLMS } from "./Compositions/WEEKLY/MATCHDAY/THISWEEKINLMS/THISWEEKINLMS_BODY";
import { MATCHREVIEW_BODY } from "./Compositions/WEEKLY/MATCHDAY/MATCHREVIEW/MATCHREVIEW_BODY";
// Add new Templates in here
// UPCOMING FIXTURES
export const UPCOMINGFIXTURES_ARROWS = ({ RENDER }) => {
  //console.log(DATA);
  const OpeningMeta = {
    Label: "Fixtures",
  };
  return (
    <>
      <ArrowsTopTail
        OpeningMeta={OpeningMeta}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER} 
      >
        <MATCHDAYFIXTURES_BODY 
          DATA={RENDER.DATAOBJ}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
        />
      </ArrowsTopTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
};

/*************************************  */
// Match Day Results Sequence
export const MATCHDAYRESULTS_ARROWS = ({ RENDER }) => {
  //console.log(DATA);
  const OpeningMeta = {
    Label: "RESULTS",
  };
  return (
    <>
      <ArrowsTopTail
        OpeningMeta={OpeningMeta}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      >
        <MATCHDAYRESULTS_BODY
          DATA={RENDER.DATAOBJ}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
        />
      </ArrowsTopTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
};

/*************************************  */
// Match Day Results Sequence
export const THISWEEKINLMS_ARROWS = ({ RENDER }) => {
  //console.log(DATA);
  const OpeningMeta = {
    Label: "THIS WEEK IN LMS",
  };
  return (
    <>
      <ArrowsTopTail
        OpeningMeta={OpeningMeta}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      >
        <THISWEEKINLMS
          DATA={RENDER.DATAOBJ}
          RENDER={RENDER}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
        />
      </ArrowsTopTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
};

/*************************************  */
// BATTING POTD Results Sequence
export const POTDBATTING_ARROWS = ({ RENDER }) => {
  //console.log(DATA)
  const OpeningMeta = {
    Label: "Plays of the Day Batting",
  };

  return (
    <>
      <ArrowsTopTail
        OpeningMeta={OpeningMeta}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      >
        <POTDBATTING_BODY
          DATA={RENDER.DATAOBJ}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
        />
      </ArrowsTopTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
};

/*************************************  */
// BOWLING POTD Results Sequence
export const POTDBOWLING_ARROWS = ({ RENDER }) => {
  //console.log(DATA);
  const OpeningMeta = {
    Label: "Plays of the Day Bowling",
  };
  return (
    <>
      <ArrowsTopTail
        OpeningMeta={OpeningMeta}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      >
        <POTDBOWLING_BODY
          DATA={RENDER.DATAOBJ}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
        />
      </ArrowsTopTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
};

/*************************************  */
// Game of the Week
export const MOTD_ARROWS = ({ RENDER }) => {
  //console.log(DATA);
  const OpeningMeta = {
    Label: "Featured Game",
  };
  return (
    <>
      <ArrowsTopTail
        VideoLabel="MOTD"
        OpeningMeta={OpeningMeta}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      >
        <MOTD_BODY
          DATA={RENDER.DATAOBJ}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
          RENDER={RENDER}
        />
      </ArrowsTopTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
};
// Game of the Week LITE version
// this version has not player stats
export const MOTD_LITE_ARROWS = ({ RENDER }) => {
  //console.log(DATA);
  const OpeningMeta = {
    Label: "Featured Game",
  };
  return (
    <>
      <ArrowsTopTail
        VideoLabel="MOTD"
        OpeningMeta={OpeningMeta}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      >
        <MOTD_BODY_LITE
          DATA={RENDER.DATAOBJ}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
          RENDER={RENDER}
        />
      </ArrowsTopTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
};



/*************************************  */
// Game of the Week
export const MATCHREVIEW_ARROWS = ({ RENDER }) => {
  //console.log(DATA);
  const OpeningMeta = {
    Label: "Match Day Review",
  };
  return (
    <>
      <ArrowsTopTail
        VideoLabel="MOTD"
        OpeningMeta={OpeningMeta}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      >
        <MATCHREVIEW_BODY
          DATA={RENDER.DATAOBJ}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
          RENDER={RENDER}
        /> 
      </ArrowsTopTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
};


/*************************************  */
// Post Match Interview
export const POSTMATCHINTERVIEW_ARROWS = ({ DATA }) => {
  const OpeningMeta = {
    Label: "Post Match Reaction",
  };
  return (
    <>
      <ArrowsTopTail
        VideoLabel={OpeningMeta.Label}
        OpeningMeta={OpeningMeta}
        DATA={DATA}
      >
        <POSTMATCHINTERVIEW_BODY DATA={DATA} />
      </ArrowsTopTail>
    </>
  );
};

/*************************************  */
// LEADER BOARDs

// Top 5's
export const ARROWS_TOP5_4by5 = ({ RENDER, VARIABLE='',PREFIX='' }) => {
  //console.log(DATA);

  const OpeningMeta = { Label: `Top 5 ${VARIABLE}` };

  return (
    <>
      <ArrowsTopTail
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
        OpeningMeta={OpeningMeta}
      >
        <TOP5_BODY
          DATA={RENDER.DATAOBJ}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
          VARIABLE={VARIABLE}
          PREFIX={PREFIX}
        />
      </ArrowsTopTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
};

/*************************************  */
// WINNERS

export const ARROWS_WINNERS = ({ RENDER }) => {
  console.log(RENDER);
  const MainTitle = "";
  const Subtitle = "";
  const OpeningMeta = {
    Label: "Winners",
  };
  return (
    <> 
      <DivisionWinnersTopAndTail
        VideoLabel={OpeningMeta.Label}
        OpeningMeta={OpeningMeta}
        DATA={RENDER}
      >
        <TOURNAMENTWINNERS_BODY
          DATA={RENDER}
          VideoSettings={RENDER.VideoSettings}
          Subtitle={Subtitle}
          MainTitle={MainTitle}
        />
      </DivisionWinnersTopAndTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
};

const DefaultAudio = ({ RENDER }) => {
  const frame = useCurrentFrame();
  const DefaultAudio = `https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/TournamentTitleMusic_v2.mp3`;
  const TotalFrames = RENDER.DATAOBJ.VideoSettings.VideoDurationinFrames;
  return (
    <Sequence from={90}>
       <Audio
        src={!RENDER.THEME.VideoAudio ? DefaultAudio : RENDER.THEME.VideoAudio}
        startFrom={0} // if composition is 30fps, then it will start at 2s
        endAt={TotalFrames} // if composition is 30fps, then it will end at 4s
        volume={interpolate(frame, [TotalFrames - 120, TotalFrames], [0.5, 0], {
          extrapolateLeft: "clamp",
        })}
      />
      <Audio
        src={`https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/audio/BackgroundCricketMatch.mp3`}
        startFrom={120} // if composition is 30fps, then it will start at 2s
        endAt={TotalFrames} // if composition is 30fps, then it will end at 4s
        volume={interpolate(frame, [TotalFrames - 120, TotalFrames], [0.8, 0], {
          extrapolateLeft: "clamp",
        })}
      /> 
    </Sequence>
  );
};
