// Actions
import { BowlingList } from "../../../../../../actions/Stats";

import { VideoWizardContainer } from "../../../../../../Theme/Structure/BodyContainer";
import { Container } from "@mantine/core";

import { BLOCK_Section_SubHeading } from "../../../../../../Components/interface/BLOCK_Section_SubHeading";
import { P } from "../../../../../../Theme/type";
// utils
import { SORTABLETABLE } from "./utils/SortableTable";

export const ListBowlers = ({ Fixtures, teamID, setSelectedPlayer }) => {
  const OBJ = {
    OrderBy: "BATTING_Runs",
    ITEMS: [
      { Name: "Name", PATH: "Name", ToFixed: false },
      { Name: "INN", PATH: "BOWLING_INNINGS", ToFixed: false },
      { Name: "Wickets", PATH: "BOWLING_Wkts", ToFixed: false },
      { Name: "Runs", PATH: "BOWLING_Runs", ToFixed: false },
      { Name: "Overs", PATH: "BOWLING_Overs", ToFixed: false },
      { Name: "AVG", PATH: "BOWLING_AVG", ToFixed: true },
      { Name: "ECO", PATH: "BOWLING_ECO", ToFixed: true },
      { Name: "SR", PATH: "BOWLING_SR", ToFixed: true },
    ],
  };

  return (
    <Container size={"lg"}>
      <BLOCK_Section_SubHeading LABEL="BOWLING" Color={5} />

      <P>
        Use the dots above the title to sortby this Category, Click twice to
        change order
      </P>
      <VideoWizardContainer>
        <SORTABLETABLE
          DATA={BowlingList(Fixtures, teamID)}
          CALLBACK={setSelectedPlayer}
          OBJ={OBJ}
        />
      </VideoWizardContainer>
    </Container>
  );
};
