
import { Series,Audio, Img } from 'remotion';

import {orderBy,filter} from 'lodash'

// Components
import {FullWidthWrapper} from '../../../utils/Containers'
import { Section_WinLoseRatio } from './Components/WinLoseRatio';
import { Section_TeamStats } from './Components/TeamStats'; 
import {Secton_TopPerformers} from './Components/TopPerformers'
import {CongratsMsg} from './Components/CongratulationsMsg'

 


export const DIVISIONWINNERS_BODY=({DATA})=>{
  console.log(DATA.DATAOBJ.DATAOBJ)
  const MostRuns = orderBy(DATA.DATAOBJ.TeamStats.PLAYERS, ['BATTING_Runs'], ['desc'])

  const MostFours = orderBy(DATA.DATAOBJ.TeamStats.PLAYERS, ['BATTING_fours'], ['desc'])
  const MostSixes = orderBy(DATA.DATAOBJ.TeamStats.PLAYERS, ['BATTING_sixes'], ['desc'])
  const MostWickets = orderBy(DATA.DATAOBJ.TeamStats.PLAYERS, ['BOWLING_Wkts'], ['desc'])
  const MostOvers = orderBy(DATA.DATAOBJ.TeamStats.PLAYERS, ['BOWLING_Overs'], ['desc'])
 
  const OrderSR = orderBy(filter(DATA.DATAOBJ.TeamStats.PLAYERS, function(o) { return !isNaN(o.BATTING_SR); }), ['BATTING_SR'], ['desc'])

  const BestAVG = orderBy(filter(DATA.DATAOBJ.TeamStats.PLAYERS, function(o) { return o.BOWLING_Overs > 5 && o.BOWLING_AVG !== 0; }), ['BOWLING_AVG'], ['asc'])

  const BESTECO = orderBy(filter(DATA.DATAOBJ.TeamStats.PLAYERS, function(o) { return o.BOWLING_Overs > 5 && o.BOWLING_ECO !== 0; }), ['BOWLING_ECO'], ['asc'])
  
  const BattingLabels=[
      { LABEL:'MOST RUNS', PATH:'BATTING_Runs'}, 
      { LABEL:'Strike Rate', PATH:'BATTING_SR'},
      { LABEL:'MOST FOURS', PATH:'BATTING_fours'}, 
      { LABEL:'MOST SIXES', PATH:'BATTING_sixes'},
    ]  
  

  const BowlingLabels=[
      { LABEL:'MOST Wickets', PATH:'BOWLING_Wkts'},
      { LABEL:'Most Overs', PATH:'BOWLING_Overs'},
      { LABEL:'Best AVG', PATH:'BOWLING_AVG'},
      { LABEL:'Best ECO', PATH:'BOWLING_ECO'},
    ]

  return(
	<>  
        <FullWidthWrapper> 
            <Series> 
                <Series.Sequence durationInFrames={240} layout="none">
                        <Section_WinLoseRatio DATA={DATA} DURATION={240}/>
                </Series.Sequence> 
                <Series.Sequence durationInFrames={240} layout="none">
                      <Section_TeamStats DATA={DATA} DURATION={240}/>
                </Series.Sequence>
                <Series.Sequence durationInFrames={210} layout="none">
                    <Secton_TopPerformers DISIPLINE={`The Batsmen`} DURATION={210} LABELS={BattingLabels} Performances={[MostRuns.slice(0,3),OrderSR.slice(0,3),MostFours.slice(0,3),MostSixes.slice(0,3)]} />
                </Series.Sequence>
                <Series.Sequence durationInFrames={210} layout="none">
                    <Secton_TopPerformers DISIPLINE={`The Bowlers`} DURATION={210} LABELS={BowlingLabels} Performances={[MostWickets.slice(0,3),MostOvers.slice(0,3), BestAVG.slice(0,3),BESTECO.slice(0,3)]} />
                </Series.Sequence>
                <Series.Sequence durationInFrames={180} layout="none">
                    <CongratsMsg DATA={DATA} DURATION={180} />
                </Series.Sequence>
            </Series>
        </FullWidthWrapper>
     
        <Audio 
            src={`https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/DivisionWinnerMainVersion1.mp3`}
            playbackRate={1}
            startFrom={0} // if composition is 30fps, then it will start at 2s
            volume={1}
      	/>
	</>
  )
}