import { useEffect } from "react";
import { useGetTournaments } from "../../Hooks/Content/useTournaments";
import { Container, Image, MediaQuery, Table } from "@mantine/core";
import {
  ColoredContainer,
  ContentContainerx3,
  VideoWizardContainer,
} from "../../Theme/Structure/BodyContainer";
import { BTN_ICON_CALLBACK } from "../../Theme/Structure/Button/BTNS";
import { H3 } from "../../Theme/type";
import { UISkeletonLines } from "../../Components/Global/Skeleton";
import { filter } from "lodash";
// UI ELEMENTS
import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";
import { TABLE_TD, TABLE_THEAD_WRAPPER, TABLE_WRAPPER } from "../../Components/interface/Wrapper/Table";

export const CurrentTournaments = ({ setID }) => {
  const [Tournaments, GetTournaments] = useGetTournaments();

  // Fetch Hook
  useEffect(() => {
    GetTournaments();
  }, []);
  // Test Hook
  useEffect(() => {
  //console.log(Tournaments);
  }, [Tournaments]);

  if (Tournaments === null) return <UISkeletonLines />;
  return (
    <>
      <ActiveTournaments
        DATA={filter(Tournaments, function (o) {
          return o.attributes.isTournamentApproved && o.attributes.isActive;
        })}
        setID={setID}
      />
      <PendingTournaments
        DATA={filter(Tournaments, function (o) {
          return !o.attributes.isTournamentApproved;
        })}
      />
      <ArchivedTournaments
        DATA={filter(Tournaments, function (o) {
          return o.attributes.isTournamentApproved && !o.attributes.isActive;
        })}
        setID={setID}
      />
    </>
  );
};

const ActiveTournaments = ({ DATA, setID }) => {
  const CreateActiveRows = () => {
    //console.log(DATA)
    return DATA.map((sp, i) => {
    //console.log(sp.attributes.LOGO.data.attributes.url);
      return (
        <tr key={i}>
          <TournamentLogo LOGO={sp.attributes.LOGO.data.attributes} />
          <TableName STR={`${sp.attributes.Name} ${sp.attributes.Year}`} />
          <TableLocation Location={sp.attributes.Location} />
          <TableTagline Tagline={sp.attributes.Tagline} />

          <TABLE_TD>
            <BTN_ICON_CALLBACK
              CALLBACK={() => {
                setID(sp.id);
              }}
              ICON={"Eye"}
              BG={1}
              COLOR={7}
              TIPOBJ={{
                LABEL: "View Tournament",
                COLOR: "gray",
                POSITION: "top",
              }}
            />
          </TABLE_TD>
        </tr>
      );
    });
  };
  return (
    <>
      <TableLabel
        label="Active Tournaments"
        Desciption="Current Active Tournaments"
        Icon="Tournament"
      />

      <TableBody BGCOLOR={0}>{CreateActiveRows()}</TableBody>
    </>
  );
};

const PendingTournaments = ({ DATA }) => {
  const CreatePendingRows = () => {
    //console.log(DATA)
    return DATA.map((sp, i) => {
    //console.log(sp);
      return (
        <tr key={i}>
          <TournamentLogo LOGO={sp?.attributes?.LOGO.data?.attributes} />
          <TableName STR={`${sp.attributes.Name} ${sp.attributes.Year}`} />
          <TableLocation Location={sp.attributes.Location} />
          <TableTagline Tagline={sp.attributes.Tagline} />
          <TABLE_TD></TABLE_TD>
        </tr>
      );
    });
  };
  return (
    <>
      <TableLabel
        label="Pending Tournaments"
        Desciption="Pending Tournaments"
        Icon="Tournament"
      />
      <TableBody BGCOLOR={1}>{CreatePendingRows()}</TableBody>
    </>
  );
};

const ArchivedTournaments = ({ DATA, setID }) => {
  const CreateArchivedRows = () => {
    return DATA.map((sp, i) => {
      return (
        <tr key={i}>
          <TournamentLogo LOGO={sp.attributes.LOGO.data.attributes} />
          <TableName STR={`${sp.attributes.Name} ${sp.attributes.Year}`} />
          <TableLocation Location={sp.attributes.Location} />
          <TableTagline Tagline={sp.attributes.Tagline} />

          <TABLE_TD>
            <BTN_ICON_CALLBACK
              CALLBACK={() => {
                setID(sp.id);
              }}
              ICON={"Eye"}
              BG={1}
              COLOR={7}
            />
          </TABLE_TD>
        </tr>
      );
    });
  };

  return (
    <>
      <TableLabel
        label="Archived Tournaments"
        Desciption="Archived Tournaments"
        Icon="Tournament"
      />
      <TableBody BGCOLOR={1}>{CreateArchivedRows()}</TableBody>
    </>
  );
};

/* ***************** */
// Table Components

const TableLabel = ({ label, Desciption, Icon }) => {
  /*
    <ColoredContainer Padding='xs'  BGColor={3}>
            <H3 colorID={1} >{label}</H3>
        </ColoredContainer>
    */
  return (
    <BLOCK_Section_Title
      OBJ={{
        LABEL: label,
        ICON: Icon,
        DESCRIPTION: Desciption,
        TOOLBAR: false,
      }}
    />
  );
};

const TournamentLogo = ({ LOGO }) => {
//console.log(LOGO);
  return (
    <MediaQuery smallerThan="lg" styles={{ display: "none" }}>
      <TABLE_TD>
        {LOGO === undefined ? (
          false
        ) : (
          <Image radius="md" width={80} height={80} src={LOGO.url} alt="Logo" />
        )}
      </TABLE_TD>
    </MediaQuery>
  );
};

const TableName = ({ STR }) => {
  return <TABLE_TD>{STR} </TABLE_TD>;
};
const TableLocation = ({ Location }) => {
  return <TABLE_TD HIDE={true}>{Location} </TABLE_TD>;
};

const TableTagline = ({ Tagline }) => {
  return (
    <MediaQuery smallerThan="lg" styles={{ display: "none" }}>
      <TABLE_TD HIDE={true}>{Tagline}</TABLE_TD>
    </MediaQuery>
  );
};

const TableBody = (props) => {
  return (
    <Container size="lg"  p={0}>
      <VideoWizardContainer>
        <TABLE_WRAPPER> 
        <TABLE_THEAD_WRAPPER
          DISPLAY={["None", "table-header-group"]}
          HEADERS={[" ",  "Name"]}
        />
          <TABLE_THEAD_WRAPPER
          DISPLAY={[ "table-header-group","None"]}
          HEADERS={[" ", "Name", "Location", "Tagline"]}
          />

       
          <tbody>{props.children}</tbody>
        </TABLE_WRAPPER>
      </VideoWizardContainer>
    </Container>
  );
};
