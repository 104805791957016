/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { restoreOBJ } from "../../../../store/Reducer/VideoRendering";
// HOOKS
import { useFetchAsset } from "../../../../Hooks/Assets/useFetchAsset";

// Layout
import { BodyContainer } from "../../../../Theme/Structure/BodyContainer";
import {
  Index_Header,
  IndexTitle,
} from "../../../../Components/Videos/Layouts/Video_Index";


// Components
import { UISkeletonLinesWithCopy } from "../../../../Components/Global/Skeleton";
import { FetchAssetRawData } from "./FetchData";
import { AssetToolBar } from "../../Components/AssetInputToolBar";

const POTD_BATTING = ({ AssetID }) => {
  const dispatch = useDispatch();

  // UI Controllers
  const [start, setStart] = useState(false);

  // Component Controllers
  const [FindAsset, FetchAsset] = useFetchAsset();
  const [showAsset, setShowAsset] = useState(false);

  const [isDataConfirmed, setIsDataConfirmed] = useState(false);
  // Fetch and Store ASSET TYPE
  useEffect(() => { 
    FetchAsset(AssetID);
  }, []);
  useEffect(() => {
    setShowAsset(FindAsset);
  }, [FindAsset]);

  useEffect(() => {
    dispatch(restoreOBJ());
  }, [start]);

  if (showAsset === false || showAsset === undefined)
    return <UISkeletonLinesWithCopy Copy={"Building Asset Information"} />;
  return (
    <BodyContainer>
      <IndexTitle showAsset={showAsset} setStart={setStart} start={start} />

      {start ? (
        <AssetInputs
          setStart={setStart}
          setIsDataConfirmed={setIsDataConfirmed}
          isDataConfirmed={isDataConfirmed}
          showAsset={showAsset}
        />
      ) : (
        <Index_Header setStart={setStart} start={start} showAsset={showAsset} />
      )}
    </BodyContainer>
  );
};

export default POTD_BATTING;

const AssetInputs = ({
  setStart,
  setIsDataConfirmed,
  isDataConfirmed,
  showAsset,
}) => {
  return (
    <>
      <AssetToolBar setStart={setStart} />
      <FetchAssetRawData
        setIsDataConfirmed={setIsDataConfirmed}
        isDataConfirmed={isDataConfirmed}
        Asset={showAsset}
      />
    </>
  );
};