import { useEffect, useState } from "react"
import {ENV} from '../../Config/RunTimeConfig'
// FUNC : CREATE 

export const useCreateFreeTrial=(ctx)=>{

    const [FreeTrial, setFreeTrial] = useState(false)
    const CreateFreeTrial = async(_DATA)=>{
        console.log(_DATA)
        await fetch(`${ENV.API_URL}/api/form-free-trials`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: _DATA
            })
        })
        setFreeTrial(true)
    }  

    return[FreeTrial,CreateFreeTrial]
} 