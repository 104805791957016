import { useEffect, useState } from "react";
import { findIndex, filter } from "lodash";

const VideoOBJ = {
  OpeningSceneDuration: 90,
  EndCreditsDuration: 120,
  SponsorTiming: 120,
  SectionTimings: [240, 240, 210, 210, 180],
  VideoBodyTiming: [240, 240, 210, 210, 180].reduce((a, b) => a + b, 0),
  VideoDurationinFrames: [90, 120, 120, 240, 240, 210, 210, 180].reduce(
    (a, b) => a + b,
    0
  ),
  RenderStill: [70, 300, 500, 750, 950, 1111],
};

export const useCreateDivisionWinners = () => {
  //console.log(SETTINGS) 
  const VideoSettings = { ...VideoOBJ };
  const [WINNERS, setWINNERS] = useState({ VideoSettings });

  //  UTILS //
  const isFifty = (VALUE) => {
    return VALUE >= 50 ? 1 : 0;
  };
  const isStrikeRate = (RUNS, BALLS) => {
    const VALUE = parseFloat(((RUNS / BALLS) * 100).toFixed(2));

    return IsFinite(VALUE) ? parseFloat(((RUNS / BALLS) * 100).toFixed(2)) : 0;
  };
  const autoReduce = (ARR) => {
    return ARR.reduce((a, b) => a + b, 0);
  };
  const IsUndefined = (VAR) => {
    return VAR === undefined || isNaN(VAR) ? 0 : VAR;
  };
  const IsFinite = (VALUE) => {
    return isFinite(VALUE);
  };
  //const IsNAN=(VALUE)=>{return isNaN(VALUE) }
  const IsNewPlayer = (Player, OBJ) => {
    const IsHere = findIndex(OBJ, (o) => {
      return o.PlayerID === Player.PlayerID;
    });
    return IsHere; // !== -1 ? IsHere:false
  };

  const FindWonGame = (GAMES, TEAM) => {
    const ARR = [];
    GAMES.map((game, i) => {
      if (game.attributes.ResultStatement.includes(TEAM)) ARR.push(1);
    });
    return ARR.reduce((a, b) => a + b, 0);
  };

  const BowlingECO = (OVER, RUNS) => {
    const ECO = RUNS / OVER;
    return isNaN(ECO) ? 0 : parseFloat(ECO.toFixed(2));
  };
  const BowlingAVG = (WKT, RUNS) => {
    const ECO = RUNS / WKT;
    return IsFinite(ECO) ? (isNaN(ECO) ? 0 : parseFloat(ECO.toFixed(2))) : 0;
  };

  const CleanGameData = (GAMES) => {
    const CleanGames = [];
    GAMES.map((Game, i) => {
      CleanGames.push({
        HomeTeam: Game.attributes.HomeTeam,
        HomeTeamResult: Game.attributes.HomeTeamResult,
        AwayTeam: Game.attributes.AwayTeam,
        AwayTeamResult: Game.attributes.AwayTeamResult,
        ResultStatement: Game.attributes.ResultStatement,
      });
    });
    return CleanGames;
  };

  /* ******************************************** */
  // CREATE OBJ PROPERTIES
  /* ******************************************** */

  /* PLAYER STATS ******************************************** */

  /* General  STATS ******************************************** */
  const CreateGeneral = (DATA) => {
    console.log(DATA.attributes)
    const GAMES = filter(DATA.attributes.fixtures.data, function (o) {
      //o.attributes.ResultStatement !== 'The game was a tie' &&
      return o.attributes.player_moms.data.length !== 0;
    });

    const OBJ = {
      Team: DATA.attributes.Name,
      LOGO: DATA.attributes.Logo.data.attributes.url,
      GALLERY: DATA.attributes.ImageGallery?.data,
      HeroImage: DATA.attributes.TeamPhoto?.data?.attributes?.url,
      ID: DATA.attributes.TeamID,
      League: DATA.attributes.watch_lists.data[0].attributes.Name,
      Played: GAMES.length,
      Season: CleanGameData(GAMES),
      Won: FindWonGame(GAMES, DATA.attributes.Name),
    };
    return OBJ;
  }; 

  const AddToRosterItem = (PLAYER) => {
    //console.log(PLAYER)
    return {
      Name: PLAYER.player.data.attributes.Name,
      PlayerID: PLAYER.player.data.attributes.PlayerID,
      ID: PLAYER.player.data.id,
      IMAGE: PLAYER.player.data.attributes.image.data?.attributes.url,
      BATTING_Balls: IsUndefined(parseInt(PLAYER.BATTING_Balls)),
      BATTING_Runs: IsUndefined(parseInt(PLAYER.BATTING_Runs)),
      ARR_RUNS: [IsUndefined(parseInt(PLAYER.BATTING_Runs))],
      BATTING_fours: IsUndefined(parseInt(PLAYER.BATTING_fours)),
      BATTING_sixes: IsUndefined(parseInt(PLAYER.BATTING_sixes)),
      BATTING_50: isFifty(IsUndefined(parseInt(PLAYER.BATTING_Runs))),
      BATTING_SR: isStrikeRate(
        IsUndefined(parseInt(PLAYER.BATTING_Runs)),
        IsUndefined(parseInt(PLAYER.BATTING_Balls))
      ),
      BOWLING_Wkts: IsUndefined(PLAYER.BOWLING_Wkts),
      BOWLING_Overs: IsUndefined(parseInt(PLAYER.BOWLING_Overs)),
      BOWLING_Runs: IsUndefined(PLAYER.BOWLING_Runs),
      BOWLING_Maidens: IsUndefined(PLAYER.BOWLING_Maidens),
      BOWLING_ECO: BowlingECO(
        IsUndefined(PLAYER.BOWLING_Overs),
        IsUndefined(PLAYER.BOWLING_Runs)
      ),
      BOWLING_AVG: BowlingAVG(
        IsUndefined(PLAYER.BOWLING_Wkts),
        IsUndefined(PLAYER.BOWLING_Runs)
      ),

      PLAYERS_Catches: IsUndefined(PLAYER.PLAYERS_Catches),
    };
  };

  const AddStatsRosterItem = (PLAYER, STATS) => {
    //console.log(' STATS.PLAYERS_Catches ',IsUndefined(STATS.PLAYERS_Catches), PLAYER.PLAYERS_Catches)

    PLAYER.BATTING_Balls =
      PLAYER.BATTING_Balls + IsUndefined(STATS.BATTING_Balls);
    PLAYER.BATTING_Runs =
      PLAYER.BATTING_Runs + IsUndefined(parseInt(STATS.BATTING_Runs));
    PLAYER.ARR_RUNS.push(IsUndefined(parseInt(STATS.BATTING_Runs)));
    PLAYER.BATTING_fours =
      PLAYER.BATTING_fours + IsUndefined(STATS.BATTING_fours);
    PLAYER.BATTING_sixes =
      PLAYER.BATTING_sixes + IsUndefined(STATS.BATTING_sixes);
    PLAYER.BATTING_50 =
      PLAYER.BATTING_50 + isFifty(IsUndefined(parseInt(STATS.BATTING_Runs)));
    PLAYER.BATTING_SR = isStrikeRate(
      IsUndefined(parseInt(PLAYER.BATTING_Runs)),
      IsUndefined(parseInt(PLAYER.BATTING_Balls))
    );
    PLAYER.BOWLING_Wkts = PLAYER.BOWLING_Wkts + IsUndefined(STATS.BOWLING_Wkts);
    PLAYER.BOWLING_Overs =
      PLAYER.BOWLING_Overs + IsUndefined(STATS.BOWLING_Overs);
    PLAYER.BOWLING_Runs = PLAYER.BOWLING_Runs + IsUndefined(STATS.BOWLING_Runs);
    PLAYER.BOWLING_Maidens =
      PLAYER.BOWLING_Maidens + IsUndefined(STATS.BOWLING_Maidens);
    PLAYER.BOWLING_ECO = BowlingECO(
      IsUndefined(PLAYER.BOWLING_Overs),
      IsUndefined(PLAYER.BOWLING_Runs)
    );
    PLAYER.BOWLING_AVG = BowlingAVG(
      IsUndefined(PLAYER.BOWLING_Wkts),
      IsUndefined(PLAYER.BOWLING_Runs)
    );
    PLAYER.PLAYERS_Catches =
      PLAYER.PLAYERS_Catches + IsUndefined(STATS.PLAYERS_Catches);

    //return true
    //console.log(PLAYER)
    //PLAYER.BATTING_Runs = parseInt(PLAYER.BATTING_Runs)+parseInt(STATS.BATTING_Runs)
  };

  /* TEAM STATS ******************************************** */

  const CreateBatting = (DATA, ROSTER) => {
    const BATTING = {
      RUNS: [],
      BALLS: [],
      FOUR: [],
      SIX: [],
    };
    DATA.map((Batting, i) => {
      BATTING.RUNS.push(parseInt(Batting.attributes.BATTING_Runs));
      BATTING.BALLS.push(parseInt(Batting.attributes.BATTING_Balls));
      BATTING.FOUR.push(parseInt(Batting.attributes.BATTING_fours));
      BATTING.SIX.push(parseInt(Batting.attributes.BATTING_sixes));

      let INDEX = IsNewPlayer(
        Batting.attributes.player.data.attributes,
        ROSTER
      );
      if (INDEX === -1) {
        ROSTER.push(AddToRosterItem(Batting.attributes));
      } else {
        AddStatsRosterItem(ROSTER[INDEX], Batting.attributes);
      }
    });
    return BATTING;
  };

  const CreateBowling = (DATA, ROSTER) => {
    const BOWLING = {
      WICKETS: [],
      OVERS: [],
      RUNS: [],
      MADIENS: [],
    };

    DATA.map((Bowling, i) => {
      BOWLING.WICKETS.push(parseInt(Bowling.attributes.BOWLING_Wkts));
      BOWLING.OVERS.push(parseInt(Bowling.attributes.BOWLING_Overs));
      BOWLING.RUNS.push(parseInt(Bowling.attributes.BOWLING_Runs));
      BOWLING.MADIENS.push(parseInt(Bowling.attributes.BOWLING_Maidens));

      let INDEX = IsNewPlayer(
        Bowling.attributes.player.data.attributes,
        ROSTER
      );
      if (INDEX === -1) {
        ROSTER.push(AddToRosterItem(Bowling.attributes));
      } else {
        AddStatsRosterItem(ROSTER[INDEX], Bowling.attributes);
      }
    });

    return BOWLING;
  };

  const CreateFielding = (DATA, ROSTER) => {
    const FIELDING = {
      CATCHES: [],
    };
    //console.log(DATA)
    DATA.map((Feilding, i) => {
      FIELDING.CATCHES.push(parseInt(Feilding.attributes.PLAYERS_Catches));

      let INDEX = IsNewPlayer(
        Feilding.attributes.player.data.attributes,
        ROSTER
      );
      if (INDEX === -1) {
        ROSTER.push(AddToRosterItem(Feilding.attributes));
      } else {
        AddStatsRosterItem(ROSTER[INDEX], Feilding.attributes);
      }
    });
    return FIELDING;
  };

  const CreateTeamStats = async (DATA) => {
    //console.log(DATA.attributes)

    const TEAMSTATS = {
      BATTING: {},
      BOWLING: {},
      FIELDING: {},
      PLAYERS: [],
    };

    // Create Disipline Stats, And Player Stats
    //console.log('start')
    //console.log('CreateBatting', TEAMSTATS.PLAYERS.length)
    TEAMSTATS.BATTING = await CreateBatting(
      DATA.attributes.player_battings.data,
      TEAMSTATS.PLAYERS
    );
    //console.log('CreateBowling', TEAMSTATS.PLAYERS.length)
    TEAMSTATS.BOWLING = await CreateBowling(
      DATA.attributes.player_bowlings.data,
      TEAMSTATS.PLAYERS
    );
    //console.log('CreateFielding', TEAMSTATS.PLAYERS.length)
    TEAMSTATS.FIELDING = await CreateFielding(
      DATA.attributes.player_catches.data,
      TEAMSTATS.PLAYERS
    );
    //console.log('END', TEAMSTATS.PLAYERS.length)

    TEAMSTATS.BATTING.RUNS = autoReduce(TEAMSTATS.BATTING.RUNS);
    TEAMSTATS.BATTING.BALLS = autoReduce(TEAMSTATS.BATTING.BALLS);
    TEAMSTATS.BATTING.FOUR = autoReduce(TEAMSTATS.BATTING.FOUR);
    TEAMSTATS.BATTING.SIX = autoReduce(TEAMSTATS.BATTING.SIX);

    TEAMSTATS.BOWLING.WICKETS = autoReduce(TEAMSTATS.BOWLING.WICKETS);
    TEAMSTATS.BOWLING.OVERS = autoReduce(TEAMSTATS.BOWLING.OVERS);
    TEAMSTATS.BOWLING.RUNS = autoReduce(TEAMSTATS.BOWLING.RUNS);
    TEAMSTATS.BOWLING.MADIENS = autoReduce(TEAMSTATS.BOWLING.MADIENS);

    TEAMSTATS.FIELDING.CATCHES = autoReduce(TEAMSTATS.FIELDING.CATCHES);

    return TEAMSTATS;
  };

  /* Main Function ******************************************** */
  const CreateWinners = async (DATA) => {
    /*
        -- Create 
            - General Stats
                - Name--
                - Division--
                - Games Played--
                - Games won--
                - Season by Date--

             - Teams Stats
                - Runs --
                - Wickets--
                - Catches--
                - Players Used --
                - Player roster --

             - Standout Players
              - Most runs --
              - Hs --
              - Best AVG
              - Most Wickets --
              - Best Eco --
              - Most Catches --
              - Most Games

       */

    const DATAOBJ = {
      General: await CreateGeneral(DATA),
      TeamStats: await CreateTeamStats(DATA),
    };

    setWINNERS({ ...WINNERS, ...DATAOBJ });
  };

  useEffect(() => {}, [WINNERS]);

  return [WINNERS, CreateWinners];
};
