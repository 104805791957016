import {Sequence} from 'remotion';

// Components
import {LeagueLabel} from '../../../Components/LeagueLabel'
import {FixtureResultsUL} from "./FixtureResultsUL"
import {FullWidthWrapper} from '../../../utils/Containers'


export const MATCHDAYRESULTS_BODY=({DATA,VideoSettings})=>{
  return(
	<> 
		{
            Object.keys(DATA.Fixtures).map((Label,i)=>{		
				console.log(Label)				
                return(			
						<Sequence  
							key={i}  
							from={VideoSettings.LeagueStartFrom[i]} 
							durationInFrames={(VideoSettings.LeagueDurations[i])} 
						> 
							<FullWidthWrapper> 
								<LeagueLabel LABEL={Label}/>  
								 
								<FixtureResultsUL 
									LeagueDurations={(VideoSettings.LeagueDurations[i])}
									VideoSettings={VideoSettings}
									LISTFIXTURES={DATA.Fixtures[Label]}
									
								/> 
							</FullWidthWrapper>
						</Sequence>   
                )
            })
        }
		
	</>
  )
}