/* eslint-disable react/jsx-pascal-case */
import { useEffect } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { useFindPlayerName } from "../../Hooks/user/useFindPlayerName";
import { Image, Input, Space, MediaQuery, Box } from "@mantine/core";

import { H3, P } from "../../Theme/type";
import { IsLoading } from "../../Components/Global/isLoadingDefault";

import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";
import { BTN_ICON_CALLBACK } from "../../Theme/Structure/Button/BTNS";
import { PageHeader } from "../../Components/interface/ProductHeader";
import {
  TABLE_WRAPPER,
  TABLE_THEAD_WRAPPER,
  TABLE_TD,
} from "../../Components/interface/Wrapper/Table";
export const SelectAnyPlayer = ({ setSectionID, setSelectedPlayer }) => {
  const [PlayerName, FindPlayerName] = useFindPlayerName();
  let timeout = null;
  const handleChange = (e) => {
    const value = e.target.value.trim(); // remove whitespace from the input value

    if (value.length > 4) { // check that the input value is greater than 3 characters

        // clear the previous timeout if it exists
        if (timeout) {
            clearTimeout(timeout);
        }

        // set a new timeout to call the FindTeamName function
        timeout = setTimeout(() => {
          FindPlayerName(value);
        }, 500);
    }
};


  useEffect(() => {}, [PlayerName]);

  return (
    <>
      <PageHeader NavPosition="Players" />
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Player Search",
          ICON: "Search",
          DESCRIPTION:
            "A player's name or the team they have played for can be used as a search term.",
          TOOLBAR: false,
        }}
      />
      <H3 align="right">Results {PlayerName.length}</H3>
      <Input
        onChange={handleChange}
        styles={(theme) => ({
          input: {
            "&, &:focus": {
              borderColor: theme.colors.brand[2],
              backgroundColor: theme.colors.brand[2],
              color: theme.colors.brand[0],
            },
          },
        })}
      />

      {PlayerName === false ? (
        <>
          <Space h="xl" />
          <IsLoading />
        </>
      ) : (
        <Results
          PlayerName={PlayerName}
          setSectionID={setSectionID}
          setSelectedPlayer={setSelectedPlayer}
        />
      )}
    </>
  );
};

const Results = ({ PlayerName, setSectionID, setSelectedPlayer }) => {
  const Width = useMediaQuery("(min-width: 769px)");

  const TeamCheck = (_PLAYER) => {
    //console.log(_PLAYER.attributes)
    const ARR = [];
    _PLAYER.attributes.player_battings.data.map((item, i) => {
      if (item.attributes.team?.data?.attributes.Name)
        ARR.push(item.attributes.team.data.attributes.Name);
    });
    _PLAYER.attributes.player_bowlings.data.map((item, i) => {
      if (item.attributes.team?.data?.attributes.Name)
        ARR.push(item.attributes.team.data.attributes.Name);
    });
    //console.log(_PLAYER.attributes.Name, ARR)
    return ARR[0];
  };
  //console.log(PlayerName)
  if (PlayerName === null) return false;
  if (PlayerName.length === 0)
    return (
      <>
        <Space h="md" />
        <P colorID={4}>
          There are currently {PlayerName.length} Results for the search term
          provided! Please check the spelling is correct.
        </P>
      </>
    );

  return (
    <>
      <Space h="md" />

      <TABLE_WRAPPER>
        <TABLE_THEAD_WRAPPER
          DISPLAY={["None", "table-header-group"]}
          HEADERS={["Player", "Name", " "]}
        />
        <TABLE_THEAD_WRAPPER
          DISPLAY={["table-header-group", "None"]}
          HEADERS={[
            " ",
            "Name",
            "ID",
            "Matches",
            "Runs",
            "Wickets",
            "Has Played For",
            " ",
          ]}
        />

        <tbody>
          {PlayerName.map((Player, i) => {
            //console.log(PlayerName)
            // MATCHES RUNS WICKETS
            return (
              <tr key={i}>
                <TABLE_TD>
                  <Image
                    src={
                      Player.attributes?.image?.data?.attributes?.url !==
                      undefined
                        ? `${Player.attributes?.image?.data?.attributes?.url}`
                        : false
                    }
                    radius={10}
                    width={50}
                    height={50}
                    alt={Player.attributes.Name}
                    placeholder
                  />
                </TABLE_TD>
                <TABLE_TD>{Player.attributes.Name}</TABLE_TD>
                <TABLE_TD HIDE={true}>{Player.attributes.PlayerID}</TABLE_TD>
                <TABLE_TD HIDE={true}>{Player.attributes.MATCHES}</TABLE_TD>
                <TABLE_TD HIDE={true}>{Player.attributes.RUNS}</TABLE_TD>
                <TABLE_TD HIDE={true}>{Player.attributes.WICKETS}</TABLE_TD>
                <TABLE_TD HIDE={true}>{TeamCheck(Player)}</TABLE_TD>

                <TABLE_TD>
                  <BTN_ICON_CALLBACK
                    ICON="Eye"
                    TIPOBJ={{
                      LABEL: `View ${Player.attributes.Name}'s Players Profile`,
                      COLOR: "gray",
                      POSITION: "top",
                    }}
                    CALLBACK={() => {
                      setSectionID(1);
                      setSelectedPlayer(Player);
                    }}
                  />
                </TABLE_TD>
              </tr>
            );
          })}
        </tbody>
      </TABLE_WRAPPER>
    </>
  );
};
