import { useState } from "react";
import {ENV} from '../../Config/RunTimeConfig'
import { parseCookies } from 'nookies'
import {findIndex} from 'lodash'
const qs = require('qs');





 

// FUNC : GET 
export const useGetPlayers = ()=>{
    const [Players,setPlayers] = useState(false) 
 
    const GetPlayers = async()=>{
        const query = qs.stringify({ 
            populate: {
              assets: { populate: "*" } 
            }, 
          }, {
            encodeValuesOnly: true,
          }); 

        const res = await fetch(`${ENV.API_URL}/api/players?${query}`)
        const Players = await res.json();
      //console.log(Players)
        setPlayers(Players.data)
    }
    return [Players, GetPlayers] 
}


// FUNC : GET SINGLE
export const useGetPlayerSingle = (ctx)=>{
  const [Player,setPlayer] = useState(false)  
  const jwt = parseCookies(ctx).jwt
  const GetPlayer = async(_ID)=>{
    const query = qs.stringify({
      populate: ['image',
      'ImageGallery',
      'player_battings',
      'player_battings.team',
      'player_battings.fixture',
      'player_bowlings',
      'player_bowlings.team',
      'player_bowlings.fixture',
      'player_catches',
      'player_catches.team',
      'player_stumpings',
      'player_stumpings.team',
      'player_moms',
      'player_moms.team'],   
    }, {
      encodeValuesOnly: true, 
    });

      const res = await fetch(`${ENV.API_URL}/api/players/${_ID}?${query}`, { 
        headers: {
            Authorization: `Bearer ${jwt}`
        }
    })

      const Players = await res.json();
    //console.log(Players)
      setPlayer(Players.data)
  }
  return [Player, GetPlayer] 
 // 
}


// FUNC : GROUP PLAYERS BY LMSID []
// THIS HOOK IS USED IN THE TOP TENS
export const useGetPlayersByLMSID = ()=>{
  const [Players,setPlayers] = useState(false) 

  const GetPlayers = async(_PLAYERS, _IDS)=>{

  //console.log(_IDS)

      const query = qs.stringify({ 
          populate:['image','player_battings','player_battings.team','player_battings.team.Logo','player_bowlings','player_bowlings.team','player_bowlings.team.Logo'], 
          filters: {
            PlayerID:{
              $in: _IDS,
            }
          },
        }, {
          encodeValuesOnly: true,
        }); 

      const res = await fetch(`${ENV.API_URL}/api/players?${query}`)
      const Players = await res.json();
     
     
      //console.log(Players.data, _PLAYERS)

      Players.data.map((item,i)=>{
          
          let INDEX = findIndex(_PLAYERS,(o)=>{ return o.LMSID ===item.attributes.PlayerID })
          //console.log(INDEX)
          let DEETS = item.attributes
          _PLAYERS[INDEX] = {..._PLAYERS[INDEX],...DEETS }
          //PlayerID
      })
      setPlayers(_PLAYERS)
    }
  return [Players, GetPlayers] 
}


// FUNC : CREATE
// FUNC : UPDATE 
// FUNC : DELETE 