
import {useCurrentFrame } from 'remotion';
import { useTheme } from 'styled-components';
import styled from 'styled-components';


import {SpringToFrom} from '../../../../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../../../../Animation/interpolate'

//import LMS from "../../../../../Assets/defaultLarge.png";
import {LargeTitleTranslateIn} from '../../../../../GlobalComponents/LargeTitleTranslateIn'
import {POTDPlayerImage} from '../../../../../GlobalComponents/POTD/POTDPlayerImage'
import {TripleDropTransition} from '../../../../../GlobalComponents/TripleDropTransition'
import {BottomUnderlayForCopy} from '../../../../../GlobalComponents/BottomUnderlayForCopy'
import {SmallCopyTranslateIn} from '../../../../../GlobalComponents/SmallCopyTranslateIn'
import {TeamLogoLarge} from '../../../../../GlobalComponents/POTD/TeamLogoLarge'
import {TeamLogoSmall} from '../../../../../GlobalComponents/POTD/MainLogo'
import {AudioBaseTrack} from '../../../../../GlobalComponents/Audio/BaseTrack'
import {AnimatedStrokeAroundImage} from '../../../../../GlobalComponents/AnimatedStrokeAroundImage'



 

export const AnimateIn = ({POTD,Dimensions, Durations,DATA})=>{
    return(
        <>  
            <Details POTD={POTD} Durations={Durations} DATA={DATA} />
            <TripleDropTransition Dimensions={Dimensions} Durations={Durations}/>
            <POTDPlayerImage IMG={POTD.IMAGE} Durations={Durations}/>
        </>
    )
}



const DetailsContainer = styled.div`
    width:100%;
    height:100%;
    position:absolute;
    display:flex;
    z-index:100;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: center;
    flex-direction:column;
`
    
const ColoredMask= styled.div`
    width:100%;
    height:100%;
    position:absolute;

`
    const ScoreContainer = styled.div` position:relative;
        padding:1rem 0   ;
        margin:.5rem 0  ;
        border-radius: 10px;
        width:100%
        `


const Details = ({POTD,Durations,DATA})=>{
    const frame = useCurrentFrame();
    const theme = useTheme();
    //console.log(POTD)

    const POTDSTRING = POTD.BATTING_Runs+'('+POTD.BATTING_Balls+')'
   


    return(
<>
    <ColoredMask 
        style={{
            backgroundColor:theme.Base[1],
            opacity:interpolateOpacityByFrame(frame,0,(0+5), 1, 0), 
        }}
    />
    <BottomUnderlayForCopy   Durations={Durations} DATA={DATA}/>
    <DetailsContainer>
        <TeamLogoLarge  Durations={Durations} LOGO={POTD.Logo} />
        <ScoreContainer
            style={{
                overflow: 'hidden',
                transform: `translateY(${SpringToFrom(7,-360,-370,'Springy100')}px) translateY(${SpringToFrom(Durations.TransitionAfter,0,400,'Springy100')}px)`,
            }}
        >
            <SmallCopyTranslateIn  fontWeight='400' STR={POTD.Name}  Duration={(Durations.Close-Durations.Offset)} />
            <TeamLogoSmall Logo={POTD.Logo} Durations={Durations} />
            <LargeTitleTranslateIn POTDSTRING={POTDSTRING} Durations={Durations}/>
            <SmallCopyTranslateIn 
                Size='sm'  
                STR={`SR : ${POTD.BATTING_SR}`}  
                Duration={(Durations.Close-Durations.Offset)}
            />
        </ScoreContainer>
    </DetailsContainer>
    <AnimatedStrokeAroundImage COLOR={1} DIVIDE={4} Durations={Durations} STYLES={{ width:'450px',height:'450px',top: '180px' }}/>
    <AudioBaseTrack />
        </>
    )
}




