/* eslint-disable react/jsx-pascal-case */
import { useState, useEffect } from "react";
import {
  useArchiveSponsor,
  useGetSponsors,
} from "../../../Hooks/Content/useSponsors";

import { Container, Image, MediaQuery, Space } from "@mantine/core";
import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";

import { BTN_ICON_CALLBACK } from "../../../Theme/Structure/Button/BTNS";
import { UISkeletonLines } from "../../../Components/Global/Skeleton";

// UI ELEMENTS
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
import { TABLE_WRAPPER } from "../../../Components/interface/Wrapper/Table";

export const CurrentSponsors = () => {
  const [Sponsor, ArchiveSponsor] = useArchiveSponsor();
  const [Sponsors, GetSponsors] = useGetSponsors();
  const [update, setUpdate] = useState(false);

  const handleArchiveSponsor = (_ID, _STATE) => {
    //console.log(_ID, _STATE);
    setUpdate(true);
    ArchiveSponsor(_ID, { isActive: _STATE });
  };

  useEffect(() => {
    GetSponsors();
  }, []);
  useEffect(() => {
    //console.log(Sponsors);
    setUpdate(false);
  }, [Sponsors]);

  useEffect(() => {
    if (Sponsor) {
      GetSponsors();
    }
  }, [Sponsor]);

  const CreateRows = (Active) => {
    return Sponsors.map((sp, i) => {
      if (sp.attributes.isActive === Active)
        return (
          <tr key={i}>
            <MediaQuery smallerThan="lg" styles={{ display: "none" }}>
              <td>
                {sp.attributes.Logo.data.attributes.url === undefined ? (
                  false
                ) : (
                  <Image
                    radius="md"
                    width={50}
                    height={50}
                    fit="contain"
                    src={sp.attributes.Logo.data.attributes.url}
                    alt="Player or Team image Missing"
                  />
                )}
              </td>
            </MediaQuery>

            <td>{sp.attributes.Label}</td>
            <td>{sp.attributes.Name}</td>
            <MediaQuery smallerThan="lg" styles={{ display: "none" }}>
              <td>{sp.attributes.Tagline}</td>
            </MediaQuery>
            <td>
              <BTN_ICON_CALLBACK
                CALLBACK={() => {
                  handleArchiveSponsor(sp.id, !Active);
                }}
                ICON={Active ? "CircleX" : "CirclePlus"}
                BG={1}
                COLOR={Active ? 6 : 7}
                TIPOBJ={{
                  LABEL: Active ? "Archive Sponsor" : "Reactivate Sponsor",
                  COLOR: Active ? "Red" : "Green",
                  POSITION: "top",
                }}
              />
            </td>
          </tr>
        );
    });
  };

  if (Sponsors === null) return <UISkeletonLines />;
  if (update) {
    return <UISkeletonLines />;
  }
  return (
    <>
      <BLOCK_Section_SubHeading LABEL="Active Sponsors" Color={7} />
      <Space h={20} />
      <Container size="lg"  p={0}>
        <VideoWizardContainer>
          <TABLE_WRAPPER>
            <thead>
              <tr>
                <MediaQuery smallerThan="lg" styles={{ display: "none" }}>
                  <th></th>
                </MediaQuery>
                <th>Label</th>
                <th>Sponsor</th>
                <MediaQuery smallerThan="lg" styles={{ display: "none" }}>
                  <th>Tagline</th>
                </MediaQuery>
                <th></th>
              </tr>
            </thead>
            <tbody>{CreateRows(true, null)}</tbody>
          </TABLE_WRAPPER>
        </VideoWizardContainer>
      </Container>

      <BLOCK_Section_SubHeading LABEL="Archived Sponsors" Color={6} />
      <Space h={20} />
      <Container size="lg"  p={0}>
        <VideoWizardContainer>
          <TABLE_WRAPPER>
            <thead>
              <tr>
                <MediaQuery smallerThan="lg" styles={{ display: "none" }}>
                  <th></th>
                </MediaQuery>
                <th>Label</th>
                <th>Sponsor</th>
                <MediaQuery smallerThan="lg" styles={{ display: "none" }}>
                  <th>Tagline</th>
                </MediaQuery>
                <th></th>
              </tr>
            </thead>
            <tbody>{CreateRows(false, null)}</tbody>
          </TABLE_WRAPPER>
        </VideoWizardContainer>
      </Container>
    </>
  );
};
