import {useCurrentFrame,Img } from 'remotion';
import { useTheme } from 'styled-components';
import styled from 'styled-components';
import {SpringToFrom} from '../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../Animation/interpolate';
import {TrimPlayerName} from '../../../../actions/UX'

import { H1 } from '../utils/Text';


export const SmallCopyTranslateIn=({STR,Duration,Size='md', fontWeight='100'})=>{
    const frame = useCurrentFrame();
    const theme = useTheme();

    const FS={
        xs:'2rem',
        sm:'3rem',
        md:'4rem',
        lg:'5rem',
    }

    return(
        <H1
            style={{
            color:theme.Base[2],
            width:'100%',
            textAlign:'center',
            fontSize:FS[Size],
            lineHeight:'auto',
            margin:'.5rem 0',
            fontWeight:fontWeight,
            //marginTop: `${SpringToFrom(7,-160,0)}px`,
           transform: `translateX(${SpringToFrom(15,150,0)}px) translateY(${SpringToFrom(Duration,0,400,'fast')}px)`,
          
        }}
        >
                {
                    TrimPlayerName(STR,'14').split('').map((D,i)=>{
                        return(
                                <span
                                    key={i}
                                    style={{
                                        margin:0,
                                        opacity:interpolateOpacityByFrame(frame, (15+((i+1))),(16+((i+1))), 0, 1),
                                    }}>
                                    {D}</span>
                            )
                    })
                }</H1>
    )
}