import { Text } from "@mantine/core";
import { forwardRef } from "react";

export const Header = (props) => {
  const {
    colorID = 4,
    align = "left",
    weight = 500,
    underline = false,
    transform = "uppercase",
    fontSize = "2.25em",
    lineHeight = "1em",
  } = props;
  return (
    <Text
      transform={transform}
      weight={weight}
      align={align}
      underline={underline}
      sx={(theme) => ({
        color: theme.colors.brand[colorID],
        fontFamily: "Roboto",
        fontSize: fontSize,
        letterSpacing: "-2.5px",
        lineHeight: lineHeight,
      })}
    >
      {props.children}
    </Text>
  );
};

export const ASSET_Header = (props) => {
  const { colorID = 4, lineClamp = false } = props;
  return (
    <Text
      lineClamp={lineClamp}
      size="md"
      weight={500}
      transform="uppercase"
      sx={(theme) => ({
        color: theme.colors.brand[colorID],
        width: "100%",
        fontFamily: "Roboto",
      })}
    >
      {props.children}
    </Text>
  );
};

export const H1 = (props) => {
  return (
    <Text
      size={30}
      weight={100}
      transform="uppercase"
      sx={(theme) => ({ color: theme.colors.brand[0], fontFamily: "Roboto" })}
    >
      {props.children}
    </Text>
  );
};

export const H2 = (props) => {
  return (
    <Text
      size="lg"
      weight={700}
      transform="capitalize"
      sx={(theme) => ({ color: theme.colors.brand[4], fontFamily: "Roboto" })}
    >
      {props.children}
    </Text>
  );
};

export const H3 = forwardRef((props, ref) => {
  const {
    colorID = 4,
    size = "lg",
    lineheight = "auto",
    align = "left",
    weight = 400,
    underline = false,
    transform = "capitalize",
    letterspacing = "auto",
  } = props;
  return (
    <Text
      ref={ref}
      size={size}
      transform={transform}
      weight={weight}
      align={align}
      underline={underline}
      sx={(theme) => ({
        color: theme.colors.brand[colorID],
        fontFamily: "Roboto",
        letterSpacing: letterspacing,
        lineHeight: lineheight,
      })}
    >
      {props.children}
    </Text>
  );
});

export const H4_UPPERCASE = (props) => {
  const {
    colorID = 4,
    lineClamp = false,
    align = "left",
    weight = 400,
    size = "md",
    underline = false,
  } = props;
  return (
    <Text
      lineClamp={lineClamp}
      size={size}
      weight={weight}
      align={align}
      underline={underline}
      transform="uppercase"
      sx={(theme) => ({
        color: theme.colors.brand[colorID],
        fontFamily: "Roboto",
      })}
    >
      {props.children}
    </Text>
  );
};

export const SectionLabel = (props) => {
  const {
    colorID = 4,
    lineClamp = false,
    align = "left",
    weight = 200,
    size = "md",
    underline = false,
  } = props;
  return (
    <Text
      lineClamp={lineClamp}
      size={size}
      weight={weight}
      align={align}
      underline={underline}
      transform="uppercase"
      sx={(theme) => ({
        letterSpacing: "-1px",
        color: theme.colors.brand[colorID],
        fontFamily: "Roboto",
      })}
    >
      {props.children}
    </Text>
  );
};

export const P = (props) => {
  const {
    colorID = 3,
    lineClamp = false,
    align = "left",
    size = "md",
    weight = 400,
    transform='normal',
    lineHeight='1.1em',
    margin='7px 0'
  } = props;
  return (
    <Text
      lineClamp={lineClamp}
      size={size}
      align={align}
      weight={weight}
      transform={transform}
      sx={(theme) => ({
        color: theme.colors.brand[colorID],
        fontFamily: "Roboto",
        lineHeight: lineHeight,
        margin:margin
      })}
    >
      {props.children}
    </Text>
  );
};
export const P_Bold = (props) => {
  const { colorID = 3, lineClamp = false } = props;
  return (
    <Text
      lineClamp={lineClamp}
      size="md"
      weight={800}
      sx={(theme) => ({
        color: theme.colors.brand[colorID],
        fontFamily: "Roboto",
      })}
    >
      {props.children}
    </Text>
  );
};

export const S = (props) => {
  const {
    colorID = 3,
    lineClamp = false,
    weight = 400,
    align = "left",
    underline = false,
    transform = "capitalize",
    letterspacing = "auto",
  } = props;
  return (
    <Text
      lineClamp={lineClamp}
      size="sm"
      weight={weight}
      transform={transform}
      align={align}
      underline={underline}
      sx={(theme) => ({
        lineHeight: "1em",
        color: theme.colors.brand[colorID],
        fontFamily: "Roboto",
        letterSpacing: letterspacing,
      })}
    >
      {props.children}
    </Text>
  );
};

export const P_WARNING = (props) => {
  return (
    <Text
      size="md"
      weight={400}
      sx={(theme) => ({ color: theme.colors.brand[6], fontFamily: "Roboto" })}
    >
      {props.children}
    </Text>
  );
};
