import { interpolate, useCurrentFrame } from "remotion";
import { useTheme } from "styled-components";
import { SeactionTitleContainer } from "../utils/Containers";
import { SectionTitleBar } from "../utils/Graphics";
import { H1 } from "../utils/Text";

export const LeagueLabel = (props) => {
  const { LABEL } = props;
  const frame = useCurrentFrame();
  const theme = useTheme()
  //const { fps } = useVideoConfig();
  const widthWhite = interpolate(frame, [0, 7], [0, 700], {
    extrapolateRight: "clamp",
  });
  const opacity = interpolate(frame, [7, 30], [0, 1], {
    extrapolateRight: "clamp",
  });
  return (
    <SeactionTitleContainer>
      <SectionTitleBar style={{ width: `${widthWhite}px` }} {...props} />
      <H1 style={{ opacity, color:theme.Copy[0], fontSize:'50px', lineHeight:'1em' }} {...props}>
        {LABEL}
      </H1>
    </SeactionTitleContainer>
  );
};

export const LargeLabel = (props) => {
  const { LABEL } = props;
  const frame = useCurrentFrame();
  //const { fps } = useVideoConfig();
  const widthWhite = interpolate(frame, [0, 7], [0, 700], {
    extrapolateRight: "clamp",
  });
  const opacity = interpolate(frame, [7, 30], [0, 1], {
    extrapolateRight: "clamp",
  });
  return (
    <SeactionTitleContainer>
      <SectionTitleBar style={{ width: `${widthWhite}px` }} {...props} />
      <H1
        style={{
          opacity,
          fontSize: "5em",
          fontWeight: "800",
          lineHeight: ".8em",
        }}
        {...props}
      >
        {LABEL}
      </H1>
    </SeactionTitleContainer>
  );
};
