import {interpolate, useCurrentFrame, useVideoConfig,spring } from 'remotion';
import {IntroContainer} from "../../utils/Containers"
import { OpeningH1} from "../../utils/Text"

import {SpringToFrom} from '../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../Animation/interpolate'

export const VideoTitle = (props)=>{

    const {TITLE} =props  
    const frame = useCurrentFrame();  

    return( 
	<IntroContainer>
		<OpeningH1 
            style={{
                        opacity:interpolateOpacityByFrame(frame, 0,30, 0,1),
                        transform: `translateY(${SpringToFrom(0,50,0,'Default')}px) translateY(${SpringToFrom(75,0,100,'fast')}px)`,
                    }} 
                    {... props}>
                        <div style={{ opacity:interpolateOpacityByFrame(frame, 75,85, 1,0), }}>
                        {TITLE}
                        </div>
                    
        </OpeningH1>
		
	</IntroContainer>
    )
}