import {gql,} from "@apollo/client";


export const QUERY = (APISETTINGS)=>{

    return gql`query GameDetails {
      fixture(id:${APISETTINGS.GameID}){
        data{
          id
          attributes{
            fixtureID
            ResultStatement
             player_moms{
              data{
                attributes{
                	team{
                    data{
                      id
                      attributes{
                        Name 
                        TeamID
                        Logo{
                          data{
                            attributes{
                              url
                            }
                          }
                        }
                      }
                    } 
                  }
                  player{
                    data{
                      id
                      attributes{
                        Name
                        MATCHES 
                        PlayerID
                        WORLDRANKINGBATTING
                        WORLDRANKINGBOWLING
                        HIGHSCORE
                        FIFTIES
                        RUNS
                        WICKETS
                        image{
                          data{
                            attributes{
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            } 
            player_bowlings (pagination: { start: 0, limit: -1 }){
               data{
                attributes{
                  BOWLING_Overs
                  BOWLING_Runs
                  BOWLING_Wkts
                  BOWLING_Maidens
                  BOWLING_Econ
                   player{
                    data{
                      id
                      attributes{
                        Name
                        PlayerID
                      }
                    }
                  }
                }
              }
            }
            player_catches (pagination: { start: 0, limit: -1 }){
               data{
                attributes{
                  PLAYERS_Catches
                  player{
                    data{
                      id
                      attributes{
                        Name
                        PlayerID
                      }
                    }
                  }
                }
              }
            }
           
            player_stumpings (pagination: { start: 0, limit: -1 }){
              data{
                attributes{
                  PLAYERS_Stumpings
                  player{
                    data{
                      id
                      attributes{
                        Name
                        PlayerID
                      }
                    }
                  }
                }
              }
            }
            player_battings (pagination: { start: 0, limit: -1 }){
              data{
                attributes{
                  BATTING_Runs
                  BATTING_Balls
                  BATTING_fours
                  BATTING_sixes
                  BATTING_SR
                  BATTING_HowOut
                  player{
                    data{
                      id
                      attributes{
                        Name
                        PlayerID
                      }
                    }
                  }
                }
              }
            } 
            HomeTeam
            AwayTeam
            Date
            Time
            ground{
              data{
                id 
                attributes{
                  Name
                }
              }
            }
            umpire{
              data{ 
                id
                attributes{
                  Name
                }
              }
            }
            teams{
              data{
                id
                attributes{
                  Name
                  Games
                  Won
                  Lost
                  Win_Ratio
                  Form
                  City_Rank
                  National_Rank
                  World_Rank
                  Logo {
                          data {
                            id
                            attributes {
                              formats
                              url
                            }
                          }
                        }
                 
                 
                }
              }
            }
          }
        }
      }
}`
}
  