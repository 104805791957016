import { useCurrentFrame, Series, Img } from "remotion";
import { Lottie } from "@remotion/lottie";

import animationBall from "./Components/Lottie/Ball.json";
import animationOUT from "./Components/Lottie/OUT.json";
import LMS from "../../../../../../Assets/defaultLarge.png";
import styled from "styled-components";
// Animation
import { EraseFromMiddle } from "../../../../../../Animation/ClipWipe";
import { interpolateOpacityByFrame } from "../../../../../../Animation/interpolate";
// Components

import { TOPTOBOTTOM } from "./Components/ToptoBottom";
import { COPY, EMPASISVALUE } from "./Components/Copy";
const LMSIMGSTYLES = {
  mixBlendMode: "luminosity",
  position: "absolute",
  bottom: "20%",
  left: "10%",
  width: "80%",
  opacity: 0.05,
};

export const SECTION_BOWLING = ({ DATA, Duration }) => {
  console.log(DATA);
  const SectionLength = 180;
  return (
    <SeriesContainer>
      <Series>
        <Series.Sequence durationInFrames={SectionLength} layout="none">
          <BOWLING_PANEL_1 DATA={DATA} />
        </Series.Sequence>
        <Series.Sequence durationInFrames={SectionLength} layout="none">
          <BOWLING_PANEL_2 DATA={DATA} />
        </Series.Sequence>
        <Series.Sequence durationInFrames={SectionLength} layout="none">
          <BOWLING_PANEL_3 DATA={DATA} />
        </Series.Sequence>
        <Series.Sequence durationInFrames={SectionLength} layout="none">
          <BOWLING_PANEL_4 DATA={DATA} />
        </Series.Sequence>
      </Series>
      <TOPTOBOTTOM
        POSITIONING={{
          TOP: [-79, -90],
          BOTTOM: [55, 47],
        }}
      />
      <Img src={LMS} style={LMSIMGSTYLES} />
    </SeriesContainer>
  );
};

const BOWLING_PANEL_1 = ({ DATA }) => {
  console.log(DATA);
  const SUMof = DATA.DATAOBJ.DATERANGESTATS.SUMof;
  const frame = useCurrentFrame();
  return (
    <SeriesContainer>
      <COPY DURATION={180}>
        <Lottie
          loop={true}
          style={{
            width: "300px",
            margin: "0px 350px",
            textAlign: "center",
            opacity: interpolateOpacityByFrame(frame, 15, 50, 0, 1),
            clipPath: EraseFromMiddle(180 - 15, "Springy100"),
          }}
          animationData={animationBall}
        />
        <EMPASISVALUE VALUE={SUMof.WICKETS} /> Wickets fell over{" "}
        <EMPASISVALUE VALUE={SUMof.OVERS.toFixed(0)} /> Overs, Resulting in a
        wicket every{" "}
        <EMPASISVALUE VALUE={((SUMof.OVERS * 5) / SUMof.WICKETS).toFixed()} />{" "}
        balls.
      </COPY>

      <TITLE
        style={{ opacity: interpolateOpacityByFrame(frame, 30, 50, 0, 1) }}
      >
        BOWLING
      </TITLE>
    </SeriesContainer>
  );
};
const BOWLING_PANEL_2 = ({ DATA }) => {
  console.log(DATA);
  const SUMof = DATA.DATAOBJ.DATERANGESTATS.SUMof;
  return (
    <SeriesContainer>
      <COPY DURATION={180}>
        <EMPASISVALUE VALUE={(SUMof.OVERS * 5).toFixed(0)} /> balls were bowled
        to{" "}
        <EMPASISVALUE
          VALUE={
            SUMof.WICKETS + DATA.DATAOBJ.DATERANGESTATS.Dismissals["Not Out"]
          }
        />{" "}
        individual innings.
      </COPY>
      <TITLE>BOWLING</TITLE>
    </SeriesContainer>
  );
};
const BOWLING_PANEL_3 = ({ DATA }) => {
  console.log(DATA);
  const SUMof = DATA.DATAOBJ.DATERANGESTATS.SUMof;
  const frame = useCurrentFrame();
  return (
    <SeriesContainer>
      <COPY DURATION={180}>
        <Lottie
          playbackRate={0.8}
          loop={true}
          style={{
            height: "600px",
            margin: "-200px 150px -110px",
            textAlign: "center",
            opacity: interpolateOpacityByFrame(frame, 15, 50, 0, 1),
            clipPath: EraseFromMiddle(180 - 15, "Springy100"),
          }}
          animationData={animationOUT}
        />
        This week,
        <br />
        there was a{" "}
        <EMPASISVALUE
          VALUE={(
            (DATA.DATAOBJ.DATERANGESTATS.Dismissals["Not Out"] /
              SUMof.WICKETS) *
            100
          ).toFixed(2)}
        />
        % chance you were Dismissed by one of these balls.
      </COPY>
      <TITLE>BOWLING</TITLE>
    </SeriesContainer>
  );
};

const BOWLING_PANEL_4 = ({ DATA }) => {
  const SUMof = DATA.DATAOBJ.DATERANGESTATS.Dismissals;
  console.log(DATA.DATAOBJ.DATERANGESTATS.Dismissals);
  const sortable = Object.fromEntries(
    Object.entries(SUMof).sort(([, b], [, a]) => a - b)
  );

  console.log(sortable);
  delete sortable["Not Out"];
  return (
    <SeriesContainer>
      <COPY DURATION={180}>
        Most COmmon Dismissals!
        <br />
        {Object.keys(sortable).map((value, i) => {
          return (
            <div key={i}>
              {value} <EMPASISVALUE VALUE={sortable[value]} />
              <br />
            </div>
          );
        })}
      </COPY>
      <TITLE>BOWLING</TITLE>
    </SeriesContainer>
  );
};

/** Containers */
const SeriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 3000;
`;
const TITLE = styled.h1`
  font-family: "Arial";
  color: ${(props) => props.theme.Copy[1]};
  font-weight: 900;
  font-size: 7em;
  line-height: 1em;
  text-align: center;
  letter-spacing: -3px;
  text-transform: uppercase;
  text-align: center;
  margin: 0;

  position: absolute;
  bottom: 0;
`;
