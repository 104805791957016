import {  Sequence, useCurrentFrame } from "remotion";

import {

  TrimTeamName,
} from "../../../../../../../actions/UX";

import { FullWidthWrapper } from "../../../../utils/Containers";
import { H2,  } from "../../../../utils/Text";

import styled, { useTheme } from "styled-components";
import { Group, Image, Table } from "@mantine/core";
import {
  FromBottomToTop,

  FromTopToBottom,
  EraseFromMiddle,
} from "../../../../../Animation/ClipWipe";
import { SpringToFrom } from "../../../../../Animation/RemotionSpring";
import { interpolateOpacityByFrame } from "../../../../../Animation/interpolate";

const Color = require("color");

const BorderBottom = 10;
function lightenHex(hex, percentage) {
  let newColor = Color(hex)
    .lighten(percentage / 10)
    .hex();
  return newColor;
}
function getContrastingColor(hex) {
  let colorHex = Color(hex);
  let contrastingColor = colorHex.isLight() ? "black" : "white";
  return contrastingColor;
}

const Intervals = (a, b, c) => {
  return c * (b - a);
};

export const LEAGUESTANDINGS_UL = ({
  LEAGUETABLE,
  VideoSettings,
  LeagueDurations,
}) => {
  const frame = useCurrentFrame();

  const theme = useTheme();

  const totalItems = LEAGUETABLE.LeagueTable.length;
  const TR_HEIGHT = (900/totalItems) //<= 10 ? 45 : 55;

  return (
    <FullWidthWrapper>
      <Sequence from={0} durationInFrames={LeagueDurations}>
        <Table
          style={{
            height: `${
              LEAGUETABLE.LeagueTable.length * (TR_HEIGHT + BorderBottom)
            }px`,
            margin: "0 10px",
          }}
        >
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>
                <H2>P</H2>
              </th>
              <th>
                <H2>W</H2>
              </th>
              <th>
                <H2>L</H2>
              </th>
              <th>
                <H2>D</H2>
              </th>
              <th>
                <H2>Pts</H2>
              </th>
            </tr>
          </thead>
          <tbody>
            {LEAGUETABLE.LeagueTable.map((TEAM, ii) => {
              return (
                <TR
                  key={ii}
                  style={{
                    background:
                      ii < 3
                        ? lightenHex(theme.Base[0], ii)
                        : theme.BackgroundGradient,
                    opacity: interpolateOpacityByFrame(
                      frame,
                      Intervals(ii, totalItems, 16),
                      Intervals(ii, totalItems, 20),
                      0,
                      1
                    ),
                    transform: `translateY(${SpringToFrom(
                      Intervals(ii, totalItems, 15),
                      TR_HEIGHT + BorderBottom,
                      0
                    )}px)`,
                    clipPath: EraseFromMiddle(
                      LeagueDurations - 15,
                      "Springy100"
                    ),
                  }}
                >
                  <td>
                    <Group>
                      <Image
                        src={TEAM.LOGO}
                        height={TR_HEIGHT}
                        width={TR_HEIGHT}
                        radius={TR_HEIGHT}
                        style={{
                          clipPath: FromBottomToTop(
                            Intervals(ii, totalItems, 20),
                            "Springy100"
                          ),
                        }}
                      />

                      <H2
                        style={{
                          textAlign: "left",
                          fontWeight: ii < 3 ? 600 : 400,
                          clipPath: FromBottomToTop(
                            Intervals(ii, totalItems, 20),
                            "Springy100"
                          ),
                          color:
                            ii < 3
                              ? getContrastingColor(
                                  lightenHex(theme.Base[0], ii)
                                )
                              : false,
                        }}
                      >
                        {TrimTeamName(TEAM.Team, 20)}
                      </H2>
                    </Group>
                  </td>
                  <td>
                    {
                      TEAM?.Form != undefined ? <FormBalls form={TEAM?.Form} INT={ii} TOTAL={totalItems}/>:false
                    }
                    
                  </td>
                  <td>
                    <TABLEITEMS VALUE={TEAM.P} INT={ii} TOTAL={totalItems} />
                  </td>
                  <td>
                    <TABLEITEMS VALUE={TEAM.W} INT={ii} TOTAL={totalItems} />
                  </td>
                  <td>
                    <TABLEITEMS VALUE={TEAM.L} INT={ii} TOTAL={totalItems} />
                  </td>
                  <td>
                    <TABLEITEMS VALUE={TEAM.D} INT={ii} TOTAL={totalItems} />
                  </td>
                  <td>
                    <TABLEITEMS VALUE={TEAM.Pts} INT={ii} TOTAL={totalItems} />
                  </td>
                </TR>
              );
            })}
            
          </tbody>
        </Table>
      </Sequence>
      {/*  <Timer Durations={LeagueDurations}/> */}
    </FullWidthWrapper>
  );
};

const TR = styled.tr`
  border-radius: 10px;
  border-bottom: ${BorderBottom}px solid transparent;
`;

const TABLEITEMS = ({ VALUE, INT, TOTAL }) => {
  const theme = useTheme();

  return (
    <H2
      style={{
        fontWeight: INT === 0 ? 900 : 400,
        clipPath: FromTopToBottom(Intervals(INT, TOTAL, 24), "Springy100"),
        color:
          INT < 3 ? getContrastingColor(lightenHex(theme.Base[0], INT)) : false,
      }}
    >
      {VALUE}
    </H2>
  );
};

function FormBalls({ form,INT, TOTAL }) {
  const formBalls = form
    .trim()
    .split(" ")
    .map((ball, index) => {
      let bgColor;
      if (ball === "W") {
        bgColor = "green";
      } else if (ball === "L") {
        bgColor = "red";
      } else if (ball === "D") {
        bgColor = "gray";
      }

      return (
        <div
          key={index}
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "20px",
            backgroundColor: bgColor,
            clipPath: FromBottomToTop(Intervals(INT, TOTAL, 24), "Springy100"),
          }}
        />
      );
    });

  return <>{formBalls}</>;
}
