/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateMOMImage } from "../../../store/Reducer/VideoRendering";
import { Container, Grid } from "@mantine/core";

// Components
import { SinglePlayerImagewithChangeOption } from "./Components/SinglePlayerImagewithChangeOptions";

// UTILS
import { findIndex } from "lodash";
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";

// Components
import { UploadNewPlayerImageContainer } from "./Components/UploadNewPlayerImageContainer";

/* ******************************************************************** 
// PLAYER IMAGE, UI DISPLAY WITH CHANGE OFD IMAGE OPTION

1. Image Gallery Containers
2. 

/* ******************************************************************** */
export const EditMilestoneImage = ({ PlayerImages }) => {
  //console.log(PlayerImages);
  const [NewImage, SetNewImage] = useState(false);
  const [Player, SetPlayer] = useState(false);
  const [selectFromGallery, SetFromGallery] = useState(false);
  const dispatch = useDispatch();
  const ChangeImage = (id) => {
    SetPlayer(id);
    SetNewImage(true);
  };

  useEffect(() => {
    SetNewImage(false);
  }, [PlayerImages, selectFromGallery]);

  const UpdateDATAOBJ = (ImageFile) => {
    /* const UPDATEINDEX = findIndex(MOMIMG, (o) => {
      return o.ID === Player.ID;
    }); */
    dispatch(updateMOMImage({ url: ImageFile.url })); 
  };

  if(NewImage)
    return (
      <UploadNewPlayerImageContainer
        Player={PlayerImages}
        SetNewImage={SetNewImage}
        UpdateDATAOBJ={UpdateDATAOBJ}
      />
    );
  return (
    <>
      <Container size={"lg"} my={30}>
        <BLOCK_Section_SubHeading LABEL="Image Selector" Color={5} />
        <Grid columns={12} justify="flex-start" align="flex-start">
          <Grid.Col
            span={6}
            xs={6}
            sm={4}
            md={3}
            xl={3}
            justify="center"
            align="center"
          >
            <SinglePlayerImagewithChangeOption
              IMAGE={PlayerImages.image?.data.attributes.url}
              NAME={PlayerImages.Name}
              PLAYER={PlayerImages}
              SetFromGallery={SetFromGallery}
              ChangeImage={ChangeImage}
              DispatchFUNC={updateMOMImage}
            />
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
};
