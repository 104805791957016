import { IndexHeader } from "../Sections/INDEX_HEADER";
import { HeroImageRight } from "../Sections/INDEX_HERO";
import { FooterSimple } from "../Sections/INDEX_Footer";
import { GiveitaGo } from "../Sections/GIVEAGO_Intro";
import { GiveItAPreview } from "../Sections/GIVEAGO_Preview";
import { useFetchFrontEndExamples } from "../../Hooks/Content/useFrontEndExamples";
import { useEffect, useState } from "react";
import { filter } from "lodash";
import {
  Box,
  Button,
  Container,
  Group,
  createStyles,
  SimpleGrid,
  Card,
  Text,
  ActionIcon,
  Tooltip,
} from "@mantine/core";
import { DropDownSelectVideoTemplate } from "../../Components/Videos/CreateVideoComponents/SelectTemplate";
import { SelectTheme } from "../../Components/Videos/CreateVideoComponents/SelectTheme";
import { SelectAudio } from "../../Components/Videos/CreateVideoComponents/SelectAudio";
import { H3 } from "../../Theme/type";
import { ICONDB } from "../../Theme/icons";
import { VideoWizardContainer } from "../../Theme/Structure/BodyContainer";
import {
  IconList,
  IconVideo,
  IconDatabase,
  IconArrowBackUp,
} from "@tabler/icons";
const Assets = [
  {
    Label: "Batting Honours",
    ASSET: "POTDBATTING",
    Icon: "Settings",
  },
  {
    Label: "Bowling Honours",
    ASSET: "POTDBOWLING",
    Icon: "Settings",
  },
  {
    Label: "Player of the Match",
    ASSET: "PLAYEROFTHEMATCH",
    Icon: "Settings",
  },
  {
    Label: "Game of the Week",
    ASSET: "GAMEOFTHEWEEKLITE",
    Icon: "Settings",
  },
  {
    Label: "MATCH REVIEW",
    ASSET: "MATCHREVIEW",
    Icon: "Settings",
  },
  {
    Label: "This Week in LMS",
    ASSET: "THISWEEKINLMS",
    Icon: "Settings",
  },
  {
    Label: "Player of the Match",
    ASSET: "PLAYEROFTHEMATCH",
    Icon: "Settings",
  },
  {
    Label: "TOP 5 6S",
    ASSET: "LEADERBOARDSIXES",
    Icon: "Settings",
  },
  {
    Label: "LMS STATUS",
    ASSET: "PLAYERMILESTONE",
    Icon: "Settings",
  },
  {
    Label: "Season Review",
    ASSET: "TEAMSEASONREVIEW",
    Icon: "Settings",
  },
];

export const PAGE_GiveItAGO = () => {
  const [FrontEndExample, FetchFrontEndExample] = useFetchFrontEndExamples();

  const [OBJ, setOBJ] = useState({
    SELECTED: false,
    DATA: false,
  });

  useEffect(() => {
    FetchFrontEndExample();
  }, []);

  useEffect(() => {}, [FrontEndExample, OBJ]);

  if (FrontEndExample === null) return <>Fetching data ...</>;
  return (
    <>
      <IndexHeader INDEX={1} />
      <HeroImageRight />

      <GiveitaGo />
      <GroupedProgess OBJ={OBJ} />
      <SelectAssetType setOBJ={setOBJ} OBJ={OBJ} />
      <SelectDataSet setOBJ={setOBJ} OBJ={OBJ} />
      <PreviewAndCustomizerWrapper
        RENDER={FrontEndExample[0].attributes}
        setOBJ={setOBJ}
        OBJ={OBJ}
      />

      <FooterSimple />
    </>
  );
};

/* ********************************************************* */
/* SelectAssetType */
/* ********************************************************* */
const useStyles = createStyles((theme) => ({
  card: {
    border: `1px solid ${theme.colors.brand[1]}`,
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.colors.brand[5],
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },
}));
const SelectAssetType = ({ setOBJ, OBJ }) => {
  const { classes } = useStyles();
  const handleClick = (asset) => {
    //console.log(asset)
    setOBJ({ ...OBJ, SELECTED: asset });
  };

  if (!OBJ.SELECTED)
    return (
      <Container size={"md"}>
        <VideoWizardContainer>
          <H3 colorID={4}>Let's make a video with the following asset:</H3>
          <SimpleGrid
            cols={3}
            spacing="sm"
            mt={7}
            breakpoints={[
              { maxWidth: "lg", cols: 2 },
              { maxWidth: "md", cols: 1 },
            ]}
          >
            {Assets.map((assets, i) => {
              return (
                <Card
                  key={assets.Label}
                  radius="md"
                  onClick={() => {
                    handleClick(assets);
                  }}
                  sx={(theme) => ({
                    position: "relative",
                    backgroundColor: theme.colors.brand[0],
                    border: `1px solid ${theme.colors.brand[1]}`,

                    padding: theme.spacing.xl,
                    borderRadius: theme.radius.sm,
                    cursor: "pointer",

                    "&:hover": {
                      boxShadow: `${theme.shadows.md} !important`,
                      transform: "scale(1.03)",
                      backgroundColor: theme.colors.brand[1],
                    },
                  })}
                >
                  <Text
                    size="lg"
                    weight={500}
                    className={classes.cardTitle}
                    mt="md"
                  >
                    {assets.Label}
                  </Text>

                  <Text
                    transform="uppercase"
                    weight={400}
                    colorID={3}
                    align="right"
                  >
                    {ICONDB[assets.Icon].Component}
                  </Text>
                  {}
                </Card>
              );
            })}
          </SimpleGrid>
        </VideoWizardContainer>
      </Container>
    );
};

/* END SelectAssetType ************************************** */
/* ********************************************************* */

/* ********************************************************* */
/* Select a Dataset */
/* ********************************************************* */
const SelectDataSet = ({ setOBJ, OBJ }) => {
  const [FrontEndExample, FetchFrontEndExample] = useFetchFrontEndExamples();
  const { classes } = useStyles();
  useEffect(() => {
    FetchFrontEndExample();
  }, []);

  useEffect(() => {}, [FrontEndExample, OBJ]);

  const handleClick = (asset) => {
    //console.log(asset)
    setOBJ({ ...OBJ, DATA: asset });
  };

  const CreateData = () => {
    return filter(FrontEndExample, (o) => {
      return o.attributes.ASSET.AssetType === OBJ.SELECTED.ASSET;
    });
  };
  const Reset = () => {
    setOBJ({
      SELECTED: false,
      DATA: false,
    });
  };

  if (FrontEndExample === null) return false;
  if (!OBJ.DATA && OBJ.SELECTED)
    return (
      <Container>
        <H3 colorID={4}>Select a Data set for the video</H3>
        <SimpleGrid
          cols={3}
          spacing="sm"
          mt={7}
          breakpoints={[
            { maxWidth: "lg", cols: 2 },
            { maxWidth: "md", cols: 1 },
          ]}
        >
          {CreateData().length !== 0
            ? CreateData().map((Data, i) => {
                return (
                  <Card
                    key={i}
                    radius="md"
                    onClick={() => {
                      handleClick(Data);
                    }}
                    sx={(theme) => ({
                      position: "relative",
                      backgroundColor: theme.colors.brand[0],
                      border: `1px solid ${theme.colors.brand[1]}`,

                      padding: theme.spacing.xl,
                      borderRadius: theme.radius.sm,
                      cursor: "pointer",

                      "&:hover": {
                        boxShadow: `${theme.shadows.md} !important`,
                        transform: "scale(1.03)",
                        backgroundColor: theme.colors.brand[1],
                      },
                    })}
                  >
                    <Text
                      size="lg"
                      weight={500}
                      className={classes.cardTitle}
                      mt="md"
                    >
                      {Data.attributes.Name}
                    </Text>
                    <Text className={classes.description} mt="md">
                      Category : {Data.attributes.Category}
                    </Text>

                    <Text
                      color="dimmed"
                      className={classes.description}
                      mt="md"
                    >
                      Data Information: {Data.attributes.AdditionalInformation}
                    </Text>
                    <Text
                      transform="uppercase"
                      weight={400}
                      colorID={3}
                      align="right"
                    >
                      {ICONDB["Download"].Component}
                    </Text>
                    {}
                  </Card>
                );
              })
            : 'Currently there are 0 Datasets for this Asset Type'}
        </SimpleGrid>
        <Tooltip label="Reset">
          <ActionIcon
            color="red"
            size="md"
            my={20}
            radius="xl"
            onClick={Reset}
            variant="filled"
          >
            <IconArrowBackUp size={24} />
          </ActionIcon>
        </Tooltip>
      </Container>
    );
};

/* END Data set ************************************** */
/* ********************************************************* */

const PreviewAndCustomizerWrapper = ({ setOBJ, OBJ }) => {
  const RENDER = OBJ.DATA.attributes;
  if (OBJ.DATA && OBJ.SELECTED)
    return (
      <Group cols={2} spacing="xs" verticalspacing="xs" position="center">
        <GiveItAPreview RENDER={RENDER} />
        <CreateThemeCreator RENDER={RENDER} setOBJ={setOBJ} />
      </Group>
    );
};

const CreateThemeCreator = ({ RENDER, setOBJ }) => {
  // FUNC STATE
  const [VideoTheme, setVideoTheme] = useState(false);
  const [Audio, setAudio] = useState(false);

  const [Layout, setLayout] = useState(false);

  useEffect(() => {
    if (Layout !== false) {
      RENDER.THEME.VideoTemplate = Layout.Name;
    }
  }, [Layout]);

  useEffect(() => {
    if (VideoTheme !== false) {
      RENDER.THEME.VideoTheme.Theme = VideoTheme.attributes.Theme;
    }
  }, [VideoTheme]);

  useEffect(() => {
    if (Audio !== false) {
      RENDER.THEME.VideoAudio = Audio.attributes.URL;
    }
  }, [Audio]);

  const Reset = () => {
    setOBJ({
      SELECTED: false,
      DATA: false,
    });
  };

  return (
    <Box
      py={15}
      px={25}
      sx={(theme) => ({
        height: "100%",
        justifyContent: "start",
        alignItems: "stretch",
        alignContent: "stretch",
        alignSelf: "stretch",
        borderRadius: "5px",
      })}
    >
      <DropDownSelectVideoTemplate
        setLayout={setLayout}
        Layout={Layout}
        AssetComponentName={RENDER.ASSET.Asset.ComponentName}
      />

      <SelectTheme
        setTheme={setVideoTheme}
        Theme={false}
        Layout={RENDER.THEME.VideoTemplate}
      />
      <SelectAudio setAudio={setAudio} Audio={Audio} />

      <Group position="apart">
        <Tooltip label="Reset">
          <ActionIcon
            color="red"
            size="md"
            my={20}
            radius="xl"
            onClick={Reset}
            variant="filled"
          >
            <IconArrowBackUp size={24} />
          </ActionIcon>
        </Tooltip>
        <Button
          variant="gradient"
          gradient={{ from: "blue", to: "green" }}
          size="md"
          component="a"
          href="/Signup"
          mt={0}
        >
          Render Video
        </Button>
      </Group>
    </Box>
  );
};
//<SelectASponsor setSponsor={setIsSponsored} Sponsor={isSponsored} />

const GroupedProgess = ({ OBJ }) => {
  return (
    <Container size={"sm"}>
      <Group position="center" mb={50}>
        <Tooltip label="Select an Asset">
          <ActionIcon color="teal" size="xl" radius="xl" variant="filled">
            <IconList size={34} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Select Dataset">
          <ActionIcon
            color="teal"
            size="xl"
            radius="xl"
            variant={OBJ.SELECTED ? "filled" : "transparent"}
          >
            <IconDatabase size={34} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Customise Video">
          <ActionIcon
            color="teal"
            size="xl"
            radius="xl"
            variant={OBJ.DATA ? "filled" : "transparent"}
          >
            <IconVideo size={34} />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Container>
  );
};