import { useState } from "react" 
import {ENV} from '../../Config/RunTimeConfig'
import { parseCookies } from 'nookies'
const qs = require('qs');

// FUNC : GET SINGLE
// FUNC : CREATE
// FUNC : DELETE 


// FUNC : GET 
export const useSelectVideoTheme= (ctx)=>{

    const [VideoTheme,setVideoTheme] = useState(null);
    const jwt = parseCookies(ctx).jwt
    
    const FetchVideoTheme = async ()=>{
        const query = qs.stringify({
           populate: '*',  
          }, {
            encodeValuesOnly: true,
          });

        const res = await fetch(`${ENV.API_URL}/api/themes?${query}`, { 
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                //Authorization: `Bearer ${jwt}`
            },
          
        })
        const Themes = await res.json();
      //console.log(Themes)

        setVideoTheme(Themes.data)
    }
    return [VideoTheme,FetchVideoTheme]
}


// FUNC : UPDATE 
export const useChangeDefaultTheme = (ctx)=>{

    const [ChangeTheme,setChangeTheme] = useState(null);
   
    const jwt = parseCookies(ctx).jwt
    const AUTHID = parseCookies(ctx).AUTHID
    
    const PutChangeTheme = async (_DATA)=>{
      //console.log(_DATA, AUTHID)
        const res = await fetch(`${ENV.API_URL}/api/users/${AUTHID}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(_DATA)
        })
        const GroupingStatus = await res.json();
      //console.log(GroupingStatus)

        setChangeTheme(GroupingStatus)
    }
    return[ChangeTheme,PutChangeTheme]
}