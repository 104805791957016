import { useTheme } from "styled-components";
import styled from "styled-components";
import { SpringToFrom } from "../../../../../../../Animation/RemotionSpring";

const BottomUnderlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: end;
`;

/*
translateY(${SpringToFrom(
          Durations.TransitionAfter,
          POSITIONFROMTOP,
          POSITIONSTARTFROMTOP,
          "Springy100",
          "fast"
        )}%)
*/
export const  BottomUnderlayForCopy = ({
  Durations,
  POSITIONSTARTFROMTOP = -100,
  POSITIONFROMTOP = 0,
}) => {
  const theme = useTheme();

  return (
    <BottomUnderlay
      style={{
      
        transform: `translateY(${SpringToFrom(
          Durations.Begin,
          POSITIONSTARTFROMTOP,
          POSITIONFROMTOP,
          "Springy100",
          "fast"
        )}%) `,
      }}
    >
      <svg viewBox="0 0 1920 1507" fill="none">
        <path
          d="M0 211.336C0 206.614 3.30401 202.535 7.92379 201.554L895.423 13.1863C936.388 4.4918 978.719 4.46991 1019.69 13.1221L1912.07 201.56C1916.69 202.537 1920 206.618 1920 211.345V1494.5C1920 1500.02 1915.52 1504.5 1910 1504.5H9.99996C4.47711 1504.5 0 1500.02 0 1494.5V211.336Z"
          fill={theme.Base[0]}
        />
        <path
          d="M0 221.336C0 216.613 3.30401 212.535 7.92379 211.554L895.423 23.1863C936.388 14.4918 978.719 14.4699 1019.69 23.1221L1912.07 211.56C1916.69 212.537 1920 216.618 1920 221.345V1497C1920 1502.52 1915.52 1507 1910 1507H9.99996C4.47711 1507 0 1502.52 0 1497V221.336Z"
          fill={theme.Base[1]}
        />
      </svg>
    </BottomUnderlay>
  );
};

const TopUnderlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: start;
`;
/*
translateY(${SpringToFrom(
          Durations.TransitionAfter,
          POSITIONFROMTOP,
          POSITIONSTARTFROMTOP,
          "Springy100",
          "fast"
        )}%)
*/
export const TopUnderlayForCopy = ({
  Durations,
  POSITIONSTARTFROMTOP = -100,
  POSITIONFROMTOP = 0,
}) => {
  const theme = useTheme();

  return (
    <TopUnderlay
      style={{
        transform: `translateY(${SpringToFrom(
          Durations.Begin,
          POSITIONSTARTFROMTOP,
          POSITIONFROMTOP,
          "Springy100",
          "fast"
        )}%) `,
      }}
    >
      <svg viewBox="0 0 1920 2400" fill="none">
        <path
          d="M0 2069.16C0 2073.45 2.73484 2077.26 6.79783 2078.63L861.73 2367.61C923.892 2388.62 991.228 2388.67 1053.42 2367.76L1913.19 2078.62C1917.26 2077.26 1920 2073.44 1920 2069.15V13.9813C1920 8.45849 1915.52 3.98138 1910 3.98138H9.99996C4.47711 3.98138 0 8.45854 0 13.9814V2069.16Z"
          fill={theme.Base[0]}
        />
        <path
          d="M0 2053.23C0 2057.52 2.73484 2061.33 6.79783 2062.71L861.73 2351.69C923.892 2372.7 991.228 2372.75 1053.42 2351.83L1913.19 2062.7C1917.26 2061.33 1920 2057.51 1920 2053.22V9.99972C1920 4.47688 1915.52 -0.000315428 1910 -0.000315428H9.99996C4.47711 -0.000315428 0 4.47684 0 9.99968V2053.23Z"
          fill={theme.Base[1]}
        />
      </svg>
    </TopUnderlay>
  );
};
