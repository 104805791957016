import { createSlice } from '@reduxjs/toolkit'

export const UXSTATE = createSlice({
  name: 'UXSTATE',
  initialState: {
    SelectedFranchise:false
  }, 
  reducers: {
    isSelectedFranchise: (state, action) => {
      console.log('isSelectedFranchise action.payload', action)
      state.SelectedFranchise = action.payload
    }
  }
}) 

export const { isSelectedFranchise } = UXSTATE.actions
export default UXSTATE.reducer 