import { Stack, Text, Loader, Container } from "@mantine/core";
import { useEffect, useState } from "react";
import { useAuthUser } from "../Hooks/Auth/useAuthUserMe";

import { H2 } from "../Theme/type";
import {
  ColoredContainer,
  VideoWizardContainer,
} from "../Theme/Structure/BodyContainer";

function ProcessAuth() {
  const [authUser, FetchauthUser] = useAuthUser();

  const [ERROR, setERROR] = useState(false);

  useEffect(() => {
    FetchauthUser();
  }, []);

  useEffect(() => {}, []);
  return (
    <Container
      style={{ height: "100vh", display: "flex", justifyContent: "center" }}
    >
      <Stack justify="space-around" align="center">
        <VideoWizardContainer>
          <Stack justify="space-around" align="center">
            {!ERROR ? true : <Text>{ERROR}</Text>}
            <H2>AUTHENTICATING USER</H2>
            <Loader color="teal" variant="bars" />
          </Stack>
        </VideoWizardContainer>
      </Stack>
    </Container>
  );
}

export default ProcessAuth;
