/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetStripedDownFixtures,
  useUpdateGetFixtures,
} from "../../../Hooks/Content/useFixtures";

import {
  ThemeIcon,
  Space,
  Group,
  Modal,
  Container,
  Image,
  Center,

} from "@mantine/core";

//THEME
import { H3, P } from "../../../Theme/type";

import {
  BorderedContainer,

} from "../../../Theme/Structure/BodyContainer";


// UTILS
import moment from "moment";
import { orderBy } from "lodash";
import {
  BTN_ICON_CALLBACK,
  BTN_ICON_LINK,
} from "../../../Theme/Structure/Button/BTNS";
// UI ELEMENTS

import {
  TABLE_WRAPPER,
  TABLE_THEAD_WRAPPER,
  TABLE_TD,
} from "../../../Components/interface/Wrapper/Table";
import {GPT3Prompt} from './GPT3Prompt'

export  const ScoreCardModalContainer = ({ GAME }) => {
  console.log(GAME);
  const FindTeamLogo = (TEAMS, TEAM) => {
    return TEAMS[0].attributes.Name === TEAM
      ? GAME.teams.data[0].attributes?.Logo.data?.attributes.url
      : GAME.teams.data[1].attributes?.Logo.data?.attributes.url;
  };

  if (GAME === false) return false;
  return (
    <>
      <BorderedContainer>
        <Group position="apart" pacing="lx" grow>
          <H3 align="right">
            {GAME.HomeTeam} <br /> {GAME.HomeTeamResult}
          </H3>{" "}
          <Image
            width={80}
            height={80}
            src={`${FindTeamLogo(GAME.teams.data, GAME.HomeTeam)}`}
          />
          <P>vs</P>
          <Image
            width={80}
            height={80}
            src={`${FindTeamLogo(GAME.teams.data, GAME.AwayTeam)}`}
          />{" "}
          <H3>
            {GAME.AwayTeam} <br /> {GAME.AwayTeamResult}{" "}
          </H3>
        </Group>
      </BorderedContainer>
      <Group position="center" spacing="xl">
        <H3>{GAME.ResultStatement}</H3>
      </Group>
      <BorderedContainer BGColor={0}>
        <Center>
          <H3 weight={900} underline={true} colorID={5}>
            {GAME.HomeTeam} Batting Innings
          </H3>{" "}
        </Center>

        <Container>
          <TABLE_WRAPPER>
            <TABLE_THEAD_WRAPPER
              HEADERS={["Name", "Runs", "Balls", "Howout", " "]}
            />

            <tbody>
              {GAME.player_battings.data.map((Batting, i) => {
                if (
                  Batting.attributes.team.data.attributes.Name === GAME.HomeTeam
                )
                  return (
                    <tr key={i}>
                      <TABLE_TD>
                        {Batting.attributes.player.data.attributes.Name}
                      </TABLE_TD>
                      <TABLE_TD>{Batting.attributes.BATTING_Runs}</TABLE_TD>
                      <TABLE_TD>{Batting.attributes.BATTING_Balls}</TABLE_TD>
                      <TABLE_TD>{Batting.attributes.BATTING_HowOut}</TABLE_TD>
                      <TABLE_TD>
                        <BTN_ICON_LINK
                          ICON="ExternalLink"
                          TO={`https://www.lastmanstands.com/cricket-player/t20?playerid=${Batting.attributes.player.data.attributes.PlayerID}`}
                          TIPOBJ={{
                            LABEL: "Players Profile",
                            COLOR: "gray",
                            POSITION: "top",
                          }}
                        />
                      </TABLE_TD>
                    </tr>
                  );
              })}
            </tbody>
          </TABLE_WRAPPER>
        </Container>
        <Space h="xl" />
        <Center>
          <H3 weight={100} underline={false} colorID={4}>
            {GAME.AwayTeam} Bowling Innings
          </H3>{" "}
        </Center>

        <Container>
          <TABLE_WRAPPER>
            <TABLE_THEAD_WRAPPER
              HEADERS={["Name", "Overs", "Wickets", "Runs", " "]}
            />

            <tbody>
              {GAME.player_bowlings.data.map((bowling, i) => {
                if (bowling.attributes.team.data === null) return false;
                if (
                  bowling.attributes.team.data.attributes.Name === GAME.AwayTeam
                )
                  return (
                    <tr key={i}>
                      <TABLE_TD>
                        {bowling.attributes.player.data.attributes.Name}
                      </TABLE_TD>
                      <TABLE_TD>{bowling.attributes.BOWLING_Overs}</TABLE_TD>
                      <TABLE_TD>{bowling.attributes.BOWLING_Wkts}</TABLE_TD>
                      <TABLE_TD>{bowling.attributes.BOWLING_Runs}</TABLE_TD>
                      <TABLE_TD>
                        <BTN_ICON_LINK
                          ICON="ExternalLink"
                          TIPOBJ={{
                            LABEL: "Players Profile",
                            COLOR: "gray",
                            POSITION: "top",
                          }}
                          TO={`https://www.lastmanstands.com/cricket-player/t20?playerid=${bowling.attributes.player.data.attributes.PlayerID}`}
                        />
                      </TABLE_TD>
                    </tr>
                  );
              })}
            </tbody>
          </TABLE_WRAPPER>
        </Container>
      </BorderedContainer>

      <BorderedContainer BGColor={0}>
        <Center>
          <H3 weight={900} underline={true} colorID={5}>
            {GAME.AwayTeam} Batting Innings
          </H3>
        </Center>
        <Container>
          <TABLE_WRAPPER>
            <TABLE_THEAD_WRAPPER
              HEADERS={["Name", "Runs", "Balls", "Howout", " "]}
            />

            <tbody>
              {GAME.player_battings.data.map((Batting, i) => {
                if (
                  Batting.attributes.team.data.attributes.Name === GAME.AwayTeam
                )
                  return (
                    <tr key={i}>
                      <TABLE_TD>
                        {Batting.attributes.player.data.attributes.Name}
                      </TABLE_TD>
                      <TABLE_TD>{Batting.attributes.BATTING_Runs}</TABLE_TD>
                      <TABLE_TD>{Batting.attributes.BATTING_Balls}</TABLE_TD>
                      <TABLE_TD>{Batting.attributes.BATTING_HowOut}</TABLE_TD>
                      <TABLE_TD>
                        <BTN_ICON_LINK
                          ICON="ExternalLink"
                          TIPOBJ={{
                            LABEL: "Players Profile",
                            COLOR: "gray",
                            POSITION: "top",
                          }}
                          TO={`https://www.lastmanstands.com/cricket-player/t20?playerid=${Batting.attributes.player.data.attributes.PlayerID}`}
                        />
                      </TABLE_TD>
                    </tr>
                  );
              })}
            </tbody>
          </TABLE_WRAPPER>
        </Container>
        <Space h="xl" />
        <Center>
          <H3 weight={100} underline={false} colorID={4}>
            {GAME.HomeTeam} Bowling Innings
          </H3>{" "}
        </Center>
        <Container>
          <TABLE_WRAPPER>
            <TABLE_THEAD_WRAPPER
              HEADERS={["Name", "Overs", "Wickets", "Runs", " "]}
            />

            <tbody>
              {GAME.player_bowlings.data.map((bowling, i) => {
                if (bowling.attributes.team.data === null) return false;
                if (
                  bowling.attributes.team.data.attributes.Name === GAME.HomeTeam
                )
                  return (
                    <tr key={i}>
                      <TABLE_TD>
                        {bowling.attributes.player.data.attributes.Name}
                      </TABLE_TD>
                      <TABLE_TD>{bowling.attributes.BOWLING_Overs}</TABLE_TD>
                      <TABLE_TD>{bowling.attributes.BOWLING_Wkts}</TABLE_TD>
                      <TABLE_TD>{bowling.attributes.BOWLING_Runs}</TABLE_TD>
                      <TABLE_TD>
                        <BTN_ICON_LINK
                          ICON="ExternalLink"
                          TIPOBJ={{
                            LABEL: "Players Profile",
                            COLOR: "gray",
                            POSITION: "top",
                          }}
                          TO={`https://www.lastmanstands.com/cricket-player/t20?playerid=${bowling.attributes.player.data.attributes.PlayerID}`}
                        />
                      </TABLE_TD>
                    </tr>
                  );
              })}
            </tbody>
          </TABLE_WRAPPER>
          <GPT3Prompt GAME={GAME}/>
         
        </Container>
      </BorderedContainer>
    </>
  );
};