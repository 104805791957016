import { Series } from 'remotion';

import {FullWidthWrapper} from '../../../../utils/Containers'
// SERIES SEQUENCES
import {TheTeamsWrapper} from './Series/TheTeams'
import {StatsWrapper} from './Series/Thestats'
import {ThePlayers} from './Series/ThePlayers'
import {ThePrediction} from './Series/ThePrediction'

export const MOTD_LITE_BODY=({DATA,VideoSettings,RenderType})=>{

  return(
	<>   
        <FullWidthWrapper>
            <Series> 
                
                <Series.Sequence durationInFrames={VideoSettings.SectionDuration[2]} layout="none">
                    <TheTeamsWrapper DATA={DATA} Duration={VideoSettings.SectionDuration[2]} />
                </Series.Sequence>
                <Series.Sequence durationInFrames={VideoSettings.SectionDuration[3]} layout="none">
                    <StatsWrapper DATA={DATA} Duration={VideoSettings.SectionDuration[3]}/>
                </Series.Sequence>
               
                <Series.Sequence durationInFrames={VideoSettings.SectionDuration[4]} layout="none">
                        <ThePrediction DATA={DATA} Duration={VideoSettings.SectionDuration[4]}/>
                </Series.Sequence>
            </Series>
        </FullWidthWrapper>
	</>
  )
}
/*
 <Series.Sequence durationInFrames={VideoSettings.SectionDuration[4]} layout="none">
                        <ThePlayers DATA={DATA} Duration={VideoSettings.SectionDuration[4]}/>
                </Series.Sequence>
*/