import {Img,interpolate, useCurrentFrame} from 'remotion';

import BG from "../../../Assets/JS-dotGrid-2022-04-02-21-47-49.png";
import LMS from "../../../Assets/defaultLarge.png";
import {BodyContainer} from '../../utils/Containers'

export const OuterContainer = (props)=>{
	
	const frame = useCurrentFrame();
	const top = interpolate(frame, [0, 1500], [0, -300 ], {extrapolateRight: "clamp",});
	const left = interpolate(frame, [0, 1500], [0, -300 ], {extrapolateRight: "clamp",});

	const IMGSTYLES={
		mixBlendMode: 'color-dodge',
			position: 'absolute', 
			top,
			left,
			opacity :0.3 ,
	}
	const LMSIMGSTYLES={
		mixBlendMode: 'luminosity',
			position: 'absolute',
			bottom:'-25%',
			left:'10%',
			width:'80%',
			opacity :0.05, 
	}
    return(
	<BodyContainer {... props}> 
		
		<Img src={LMS} style={LMSIMGSTYLES}/>
		{props.children}
	</BodyContainer>
    )
}