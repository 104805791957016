import {useCurrentFrame } from 'remotion';
import {IntroContainer} from "../../utils/Containers"
import {FranchiseH1 } from "../../utils/Text"

import {SpringToFrom} from '../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../Animation/interpolate'


export const DisplayFranchise = (props)=>{
    const {VideoLabel} = props
    const frame = useCurrentFrame();
    return(
	<IntroContainer
        style={{ 
                padding:0,
                width:'90%',
            }}
    >
		<FranchiseH1 
            style={{
                opacity:interpolateOpacityByFrame(frame, 0,30, 0,1),
                transform: `translateY(${SpringToFrom(0,-50,0,'Default')}px) translateY(${SpringToFrom(75,0,-100,'fast')}px)`
                 }} {... props} >
                    <div style={{ opacity:interpolateOpacityByFrame(frame, 75,85, 1,0), }}>
                        {VideoLabel}
                    </div>
                </FranchiseH1>	
	</IntroContainer>
    )
}
//<OpeningBottomBar style={{width:`${widthWhite}px`}} {... props}/>