import {interpolate, useCurrentFrame, useVideoConfig,spring } from 'remotion';
import {IntroContainer} from "../../utils/Containers"
import { TournamentNameH1} from "../../utils/Text"

import {SpringToFrom} from '../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../Animation/interpolate'

export const TournamentName = (props)=>{

    const {TournamentName} =props  
    const frame = useCurrentFrame();  

    return( 
	<IntroContainer>
		<TournamentNameH1 
            style={{
                        opacity:interpolateOpacityByFrame(frame, 15,30, 0,1),
                        transform: `translateY(${SpringToFrom(15,200,0,'Default')}px) translateY(${SpringToFrom(90,0,200,'fast')}px)`,
                    }} 
                    {... props}>
                        <div style={{ opacity:interpolateOpacityByFrame(frame, 90,100, 1,0), }}>
                        {TournamentName}
                        </div>
                    
        </TournamentNameH1>
		
	</IntroContainer>
    )
}