import { useCurrentFrame } from "remotion";
import { useTheme } from "styled-components";

// Animations

import { interpolateOpacityByFrame } from "../../../../../../Animation/interpolate";
import {
  FromLeftToRight,
  FromMiddle,
  FromTopToBottom,
} from "../../../../../../Animation/ClipWipe";
import { Box, Group } from "@mantine/core";
import { H1, P } from "../../../../../utils/Text";
import { TrimPlayerName } from "../../../../../../../../actions/UX";
import { filter } from "lodash";
import { getContrastColor } from "../../../../../../../../actions/colors";

export const Performances = ({ GAME, Durations }) => {
  const frame = useCurrentFrame();

  return (
    <Group
      position="apart"
      spacing={0}
      grow
      style={{
        width: "100%",
        opacity: interpolateOpacityByFrame(
          frame,
          Durations.RemoveScorcard - 7,
          Durations.RemoveScorcard,
          1,
          0
        ),
      }}
    >
      <PerformanceStack
        NAME={GAME.homeTeam}
        SCORE={GAME.homeTeamResult}
        TEAM="homeTeam"
        GAME={GAME}
        SIDE="left"
      />

      <PerformanceStack
        NAME={GAME.awayTeam}
        SCORE={GAME.awayTeamResult}
        TEAM="awayTeam"
        GAME={GAME}
        SIDE="right"
      />
    </Group>
  );
};

const PerformanceStack = ({ TEAM, GAME, NAME, SCORE, SIDE }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();

  const FindPerformance = (Team) => {
    return filter(GAME.topBatting, (o) => {
      return o.attributes.team.data.attributes.Name === Team;
    }).slice(0, 3);
  };
  const FindBowlingPerformance = (Team) => {
    return filter(GAME.topBowling, (o) => {
      return o.attributes.team.data.attributes.Name === Team;
    }).slice(0, 3);
  };

  return (
    <Box
      justify="flex-start"
      spacing="xs"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        alignContent: "flex-end",
        justifyContent: "flex-start",
        height: "100%",
        width: "50%",
        padding: "1em 1em 0 ",
        minHeight: "320px",
      }}
    >
      <P style={{
            margin: '0px 0px 0px',
            textAlign:'left',
            lineHeight: '20px',
            fontSize: '20px',
            fontWeight: '600',
            letterSpacing: '2px',
            width: '100%',
            backgroundColor: theme.Base[2],
            color:getContrastColor(theme.Base[2]),
          
            borderRadius:'10px',
            padding: '10px'
        }}>Batting</P>
      {FindPerformance(GAME[TEAM]).map((player, i) => {
        return (
          <BattingPerformance player={player} SIDE={SIDE} INT={i} key={i} />
        );
      })}
      <P
        style={{
            margin: '10px 0px 0px',
            textAlign:'left',
            lineHeight: '20px',
            fontSize: '20px',
            fontWeight: '600',
            letterSpacing: '2px',
            width: '100%',
            borderRadius:'10px',
            backgroundColor: getContrastColor(theme.Base[2]),
            color: theme.Base[2],
            padding: '10px'
        }}
      >
        Bowling
      </P>
      {FindBowlingPerformance(GAME[TEAM]).map((player, i) => {
        return (
          <BowlingPerformance player={player} SIDE={SIDE} INT={i} key={i} />
        );
      })}
    </Box>
  );
};

const BattingPerformance = ({ player, SIDE, INT }) => {
  return (
    <PerformanceP SIDE={SIDE} INT={INT}>
      {TrimPlayerName(player.attributes.player.data.attributes.Name)}{" "}
      {player.attributes.BATTING_Runs} ({player.attributes.BATTING_Balls})
    </PerformanceP>
  );
};
const BowlingPerformance = ({ player, SIDE, INT }) => {
  return (
    <PerformanceP SIDE={SIDE} INT={INT}>
      {TrimPlayerName(player.attributes.player.data.attributes.Name)}{" "}
      {player.attributes.BOWLING_Wkts}/{player.attributes.BOWLING_Runs} (
      {player.attributes.BOWLING_Overs})
    </PerformanceP>
  );
};

const PerformanceP = (props) => {
  const { SIDE, INT } = props;
  const theme = useTheme();
  return (
    <P
      style={{
        letterSpacing: "0px",
        fontWeight: "600",
        padding: "0px",
        textAlign: SIDE,
        margin: ".3em 0 ",
        lineHeight: "25px",
        fontSize: "25px",
        width: "100%",
        color: getContrastColor('#ffffff'),
        clipPath: FromLeftToRight(60 + 5 * INT),
      }}
    >
      {props.children}
    </P>
  );
};
