/* eslint-disable react/jsx-pascal-case */
import { useEffect } from "react";
import { BTN_CREATE } from "../../../../../Theme/Structure/Button/BTNS";
import { FORMATTTIME } from "../../../../../actions/UX";
import {
  Card,
  Image,
  Text,
  Badge,
  Group,
  Center,
  Avatar,
  createStyles,
  Container,
} from "@mantine/core";

import { BLOCK_Section_SubHeading } from "../../../../../Components/interface/BLOCK_Section_SubHeading";
import { VideoWizardContainer } from "../../../../../Theme/Structure/BodyContainer";
import { H3 } from "../../../../../Theme/type";
export const FixtureResults = ({ Fixture, setConfirm,GetFixture }) => {
  useEffect(() => {}, [Fixture]);
  if (Fixture === false) return <Container size={"xs"} p="md"><VideoWizardContainer><H3>Enter a Fixture ID or select a previously veiwed game.</H3></VideoWizardContainer></Container>;
  if (Fixture.length === 0) return  <Container size={"xs"} p="md"><BLOCK_Section_SubHeading LABEL='No Fixtures found for this Fixture ID' Color={6}/></Container>;
  return (
    <Container size={"xs"} p="md">
      {Fixture.map((Fix, i) => {
        return <ArticleCard key={i} Fix={Fix} setConfirm={setConfirm} />;
      })}

    <BTN_CREATE
          CALLBACK={() => {
            GetFixture(false);
          }}
          LABEL={"Clear Selected"}
        />
    </Container>
  );
};

const useStyles = createStyles((theme) => ({
  card: {
    position: "relative",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  rating: {
    position: "absolute",
    top: theme.spacing.xs,
    right: theme.spacing.xs + 2,
    pointerEvents: "none",
  },

  title: {
    display: "block",
    marginTop: theme.spacing.md,
    marginBottom: theme.spacing.xs / 2,
  },

  action: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
    }),
  },

  footer: {
    marginTop: theme.spacing.md,
  },
}));

export function ArticleCard({ Fix, setConfirm }) {
  const { classes, cx, theme } = useStyles();

//console.log(Fix);
  return (
    <Card withBorder radius="md" className={cx(classes.card)}>
      <Card.Section>
        <Image
          src={Fix.attributes.teams.data[0].attributes.Logo.data.attributes.url}
          height={200}
        />
      </Card.Section>
      <Card.Section>
        <Image
          src={Fix.attributes.teams.data[1].attributes.Logo.data.attributes.url}
          height={200}
        />
      </Card.Section>
      <Badge
        className={classes.rating}
        variant="gradient"
        gradient={{ from: "yellow", to: "red" }}
      >
        {FORMATTTIME(Fix.attributes.Date)}
      </Badge>

      <Text className={classes.title} weight={500} component="a">
        {Fix.attributes.HomeTeam} vs {Fix.attributes.AwayTeam}
      </Text>

      <Text size="sm" color="dimmed" lineClamp={4}>
        {Fix.attributes.watch_list.data.attributes.Name}
      </Text>

      <Group position="apart" className={classes.footer}>
        <Center>
          <Avatar src={null} size={24} radius="xl" mr="xs" />
          <Text size="sm" inline>
            {Fix.attributes.ground.data?.attributes.Name}
          </Text>
        </Center>

        <BTN_CREATE
          CALLBACK={() => {
            setConfirm(true);
          }}
          LABEL={"Select"}
        />
      </Group>
    </Card>
  );
}
