
import { Container, Loader, Space } from "@mantine/core";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { BLOCK_Section_SubHeading } from "../../../../Components/interface/BLOCK_Section_SubHeading";
import {
  TABLE_TD,
  TABLE_THEAD_WRAPPER,
  TABLE_WRAPPER,
} from "../../../../Components/interface/Wrapper/Table";

import { useAIReports } from "../../../../Hooks/Content/useAICreate";
import {
  BorderedContainer,
  VideoWizardContainer,
} from "../../../../Theme/Structure/BodyContainer";
import { BTN_CREATE } from "../../../../Theme/Structure/Button/BTNS";
import { H3, P } from "../../../../Theme/type";
import moment from "moment";
import { BLOCK_ToolBar } from "../../../../Components/interface/BLOCK_Toolbar";
export const UpComingFixtures = ({ Order }) => {
    //console.log(Order)
    const ConvertTime = (FromDate) => {
      const TIME = FromDate.split(":");
      return `${TIME[0]}:${TIME[1]}`;
    };
    const LoopOBJ = (OBJ) => {
      const LIST = OBJ.map((Game, i) => {
        return (
          <tr key={i}>
            <TABLE_TD>{moment(Game.date).format("DD MMMM")} </TABLE_TD>
            <TABLE_TD>{ConvertTime(Game.time)}</TABLE_TD>
            <TABLE_TD>{Game.homeTeam}</TABLE_TD>
            <TABLE_TD>vs</TABLE_TD>
            <TABLE_TD>{Game.awayTeam}</TABLE_TD>
            <TABLE_TD>{Game.ground}</TABLE_TD>
          </tr>
        );
      });
      return LIST;
    };
    return (
      <>
        <H3>Upcoming Fixtures</H3>
  
        <TABLE_WRAPPER>
          {Object.keys(Order.attributes.DATA.Fixtures).map((keys, i) => {
            return LoopOBJ(Order.attributes.DATA.Fixtures[keys]);
          })}
        </TABLE_WRAPPER>
      </>
    );
  };

  // AI WRITEUP
// AI WRITEUP
export const UPCOMINGFIXTURESWRITEUP = ({ Order }) => {
  console.log(Order);
  const [AICreate, FetchAICreate] = useAIReports();
  const [loading, setLoading] = useState(false);

  //useAIMatchResults

  const CreateWriteup = () => {
    console.log("CreateWriteup", Order);
    FetchAICreate(Order.id,`/api/ai-writeup/createWriteup`,'Fixtures','UPCOMINGFIXTURES');
    setLoading(true);
  };

  useEffect(() => {
    console.log(AICreate);
    if (AICreate?.response !== undefined) {
      setLoading(false);
    }
  }, [AICreate]);

  if (loading)
    return (
      <Container size={"xs"} py={20}>
        <Loader color="teal" variant="bars" />
      </Container>
    );
  return (
    <>
      <Container size={"lg"} py={0}>
        
        {AICreate?.response !== undefined ? (
          false
        ) : (
          <BLOCK_ToolBar 
          TOOLS={[<BTN_CREATE LABEL={`Create AI Write up`} CALLBACK={CreateWriteup} />]}
        />
        )}
      </Container>

      {AICreate !== null ? <WriteUp Response={AICreate.response} /> : false}
    </>
  );
};

const WriteUp = ({ Response }) => {
  return (
    <Container size={"lg"} py={0}>
      <VideoWizardContainer>
        <ReactMarkdown>{Response}</ReactMarkdown>
      </VideoWizardContainer>
      <BorderedContainer>
        <P colorID={4} size={"sm"} lineHeight={"1.4em"}>
          Please note that while this AI will generate a match report based on
          its knowledge of T20 Cricket, the report may not accurately reflect
          the events of the LMS match. The scorecard provided will be used, but
          the report may not accurately reflect retired or returning batsmen.
          Please review the report before posting to ensure its accuracy.
        </P>
      </BorderedContainer>
    </Container>
  );
};