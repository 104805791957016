/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import {
  useGetStripedDownFixtures,
  useUpdateGetFixtures,
  useSyncSingleGame,
} from "../../Hooks/Content/useFixtures";

import { Space, Group, Modal, Container, Progress } from "@mantine/core";

//THEME

// Components
import { IsLoading } from "../../Components/Global/isLoadingDefault";
import { VideoWizardContainer } from "../../Theme/Structure/BodyContainer";

// Filter Inputs
import { DataDateRangePicker } from "../../Components/settings/SelectaDate";
//import { SelectGetDataCompletedorFuture} from '../../Components/settings/SelectGetDataCompletedorFuture'

// UTILS
import moment from "moment";
import { orderBy } from "lodash";
import {
  BTN_ICON_CALLBACK,
  BTN_ICON_LINK,
} from "../../Theme/Structure/Button/BTNS";
// UI ELEMENTS
import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";
import { BLOCK_Section_SubHeading } from "../../Components/interface/BLOCK_Section_SubHeading";
import { BLOCK_ToolBar } from "../../Components/interface/BLOCK_Toolbar";
import {
  TABLE_WRAPPER,
  TABLE_THEAD_WRAPPER,
  TABLE_TD,
} from "../../Components/interface/Wrapper/Table";
import { ScoreCardModalContainer } from "./GameData/ScoreCardModalContainer";

export const FranchiseDataChecker = ({isSelected, hasGameData}) => {
  const UXSTATE = useSelector((state) => state.UXSTATE);

  //console.log(UXSTATE.SelectedFranchise.attributes.watch_lists.data)
  const [Fixtures, setFixtures] = useGetStripedDownFixtures();
  const [updateFixture, PutUpdateFixture] = useUpdateGetFixtures();
  //const [SyncFixture, GetSyncFixture] = useSyncSingleGame()
  const [resyncPage, setResyncPage] = useState(false);



  const [opened, setOpened] = useState(false);
  const [ModalDATA, setModalDATA] = useState(false);



  // FIlter State
  const [DateRange, setDateRange] = useState([
    moment(new Date()).format("YYYY-MM-DD"),
    moment(new Date()).format("YYYY-MM-DD"),
  ]);
  const [GameStatus, setGameStatus] = useState("Current");
  const [SelectDivision, setSelectDivision] = useState(null);
  //const UXSTATE = useSelector((state) => state.UXSTATE);

  useEffect(() => {
    setResyncPage(true);
  }, []);



  // onFilterChange
  const RefreshDOM = () => {
    setFixtures(SelectDivision, DateRange);
    setResyncPage(true);
  };
  


  useEffect(() => {
    if (updateFixture !== null) RefreshDOM();
  }, [updateFixture]);

  useEffect(() => {
    if (SelectDivision != null) RefreshDOM();
  }, [SelectDivision, DateRange]);

  useEffect(() => {
    if (Fixtures) setResyncPage(false);
  }, [Fixtures]);

  const ConvertData = (DateRange) => {
    const d = new Date(`${DateRange}`);
    return moment(d).format("D MMM");
  };

  const ModalState = (DATA, STATE) => {
    isSelected(true) 
    hasGameData(DATA)
  };

  const CreateFixtureRows = () => {
    //GameStatus
    return orderBy(Fixtures, ["attributes.Date"]).map((fixture, i) => {
      const f = fixture.attributes;
      console.log(f)
      return (
        <>
          <tr key={i}>
            <TABLE_TD>
              <BTN_ICON_CALLBACK
                CALLBACK={() => ModalState(f, true)}
                ICON={`Ballpen`} 
                TIPOBJ={{
                  LABEL: "Create Writeup",
                  COLOR: "gray",
                  POSITION: "top",
                }}
              />
            </TABLE_TD>
            <TABLE_TD HIDE={true}>{ConvertData(f.Date)}</TABLE_TD>
            <TABLE_TD>
              {f.HomeTeam}
              <br />
              {f.HomeTeamResult}{" "}
            </TABLE_TD>
            <TABLE_TD>
              {f.AwayTeam}
              <br />
              {f.AwayTeamResult}
            </TABLE_TD>
            <TABLE_TD HIDE={true}>{f.ResultStatement}</TABLE_TD>
           

          </tr>
          <tr>
            <td colSpan={7} style={{ padding: "0 0 16px 0 " }}>
              <Progress
                radius="xs"
                size={18}
                sections={[
                  {
                    value: 20,
                    color: `${f.hasMeta ? "teal" : "red"}`,
                    label: "Game",
                    tooltip: "Do we have the basic game information?",
                  },
                  {
                    value: 20,
                    color: `${f.hasRelational ? "teal" : "red"}`,
                    label: "Details",
                    tooltip:
                      "Do we have indepth information on this game? i.e. umpires grounds",
                  },
                  {
                    value: 20,
                    color: `${f.hasPlayerSync ? "teal" : "red"}`,
                    label: "Players",
                    tooltip:
                      "Has the player data for this game been collected?",
                  },
                  {
                    value: 20,
                    color: `${f.hasResult ? "teal" : "red"}`,
                    label: "Results",
                    tooltip: "Do we have a Result from LMS?",
                  },
                  {
                    value: 20,
                    color: `${
                      f.player_moms.data.length !== 0 ? "teal" : "red"
                    }`,
                    label: "MOM",
                    tooltip: "Does this game have a MoM Assigned?",
                  },
                ]}
              />
            </td>
          </tr>
        </>
      );
    });
  };

  if (
    UXSTATE.SelectedFranchise?.attributes?.watch_lists?.data.length ===
    undefined
  )
    return <IsLoading />;
  if (UXSTATE.SelectedFranchise?.attributes.watch_lists.data.length === 0)
    return <IsLoading />;
  if (!Fixtures)
    return (
      <FixtureDataFilters
        setSelectDivision={setSelectDivision}
        DateRange={DateRange}
        SelectDivision={SelectDivision}
        setDateRange={setDateRange}
        GameStatus={GameStatus}
        setGameStatus={setGameStatus}
      />
    );
  if (resyncPage) return <IsLoading />;
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "GAME REVIEWS",
          ICON: "ListNumbers",
          DESCRIPTION: `Create AI generated Match Writeups.`,
          TOOLBAR: false,
        }}
      />
      <BLOCK_Section_SubHeading
        LABEL={`${Fixtures.length} Fixtures Found for the following dates -  ${moment(DateRange[0]).format(
          "DD MMMM YYYY"
        )} to ${moment(DateRange[1]).format("DD MMMM YYYY")}`}
        Color={4}
      />

      <Space h="md" />
      <BLOCK_ToolBar
        TOOLS={[
          <FixtureDataFilters
            setSelectDivision={setSelectDivision}
            DateRange={DateRange}
            SelectDivision={SelectDivision}
            setDateRange={setDateRange}
            GameStatus={GameStatus}
            setGameStatus={setGameStatus}
          />,
        ]}
      />
      <Container size="lg" p={0}>
        <VideoWizardContainer>
          <TABLE_WRAPPER>
            <TABLE_THEAD_WRAPPER
              DISPLAY={["table-header-group", "None"]}
              HEADERS={[" ", "Date", "Home", "Away", "Results"]}
            />
            <TABLE_THEAD_WRAPPER
              DISPLAY={["None", "table-header-group"]}
              HEADERS={[" ", "Home", "Away"]}
            />
            <tbody>{CreateFixtureRows(Fixtures)}</tbody>
          </TABLE_WRAPPER>
        </VideoWizardContainer>
      </Container>
      <Modal
        transition="fade"
        transitionDuration={800}
        transitionTimingFunction="ease"
        opened={opened}
        onClose={() => setOpened(false)}
        size="calc(100vw - 87px)"
        overflow="inside"
        sx={(theme) => ({
          ".mantine-Modal-modal": {
            backgroundColor: theme.colors.brand[0],
          },
        })}
      >
        <ScoreCardModalContainer GAME={ModalDATA} />
      </Modal>
    </>
  );
};

const FixtureDataFilters = ({
  setSelectDivision,
  SelectDivision,
  setDateRange,
  DateRange,
  setGameStatus,
  GameStatus,
}) => {
  const UXSTATE = useSelector((state) => state.UXSTATE);

  const CreateFranchiseSelect = () => {
    let ARR = [];
    UXSTATE.SelectedFranchise.attributes.watch_lists.data.map((Season, i) => {
      //console.log(Season)
      if (Season.attributes.hasCompleted !== true) ARR.push(Season.id);
    });

    setSelectDivision(ARR);
  };
  useEffect(() => {
    if (SelectDivision === null || SelectDivision === false)
      CreateFranchiseSelect();
  }, [GameStatus]);

  return (
    <Group position="right" spacing="sm" grow>
      <DataDateRangePicker setDateRange={setDateRange} />
    </Group>
  );
};
