import { orderBy } from "lodash";
// CORE Components
import { SupImages } from "./SuplimentaryImages";
import { StatContainer } from "./StatContainer";

import { COPY, EMPASISVALUE } from "./Copy";

export const SectionBattingAverages = ({ DATA, OBJ }) => {
  const IMAGES = orderBy(
    DATA.DATAOBJ.TeamStats.PLAYERS,
    ["BATTING_SR"],
    ["desc"]
  ).slice(0, 5);

  return (
    <>
      <Sentence
        DURATION={OBJ.DURATION}
        Name={DATA.DATAOBJ.General.Team}
        Runs={DATA.DATAOBJ.TeamStats.BATTING.RUNS}
        TotalInnings={DATA.DATAOBJ.TeamStats.BATTING.WICKETS.length}
        WicketsLost={DATA.DATAOBJ.TeamStats.BATTING.WICKETS.reduce((a, b) => a + b, 0)}
      />

      <StatContainer
        LABEL={`BATTING`}
        DURATION={OBJ.DURATION}
        TIMINGS={{
          SlideIn: 0,
          LabelPosition: 100,
          ClipIn: 15,
          SlideUp: 30,
        }}
      >
        <SupImages
          DATA={IMAGES}
          DURATION={OBJ.DURATION}
          Limit={5}
          VAR={"BATTING_SR"}
          Label="Top 5 Strike Rate"
        />
      </StatContainer>
    </>
  );
};

const Sentence = ({ Name, Runs, TotalInnings, WicketsLost, DURATION }) => {
  return (
    <>
      <COPY DURATION={DURATION}>
        {Name} averaged <EMPASISVALUE VALUE={(Runs / WicketsLost).toFixed(2)} />{" "}
        RUNS PER WICKET ACROSS <EMPASISVALUE VALUE={TotalInnings} /> individual   INNINGS,
        WITH <EMPASISVALUE VALUE={WicketsLost} /> WICKETS LOST AND  <EMPASISVALUE VALUE={(TotalInnings-WicketsLost)} /> NOT OUTS.
       
      </COPY>
    </>
  );
};