import { useEffect, useState } from "react";
import { orderBy } from "lodash";
import { useGetPlayersByLMSID } from "../../../../../Hooks/Content/usePlayer";

import {
  MergeTop5APICallBack,
  CleanTop5DATA,
} from "../../../../../actions/EvaluationUtils";
const OffsetTime = 210;
const VideoOBJ = {
  OpeningSceneDuration: 90,
  EndCreditsDuration: 120,
  SponsorTiming: 120,
  ItemOffset: OffsetTime,
  VideoBodyTiming: OffsetTime * 5,
  VideoDurationinFrames: 90 + 120 + OffsetTime * 5 + 120,
  RenderStill: [40, 190, 360, 560, 760, 950, 1160, 1300],
};

export const useBuildVideoData = () => {
  const [Player, GetPlayer] = useGetPlayersByLMSID();
  const VideoSettings = { ...VideoOBJ };
  const [VIDEODATA, setVIDEODATA] = useState({ VideoSettings });
  let PATH = "BATTING_sixes";
  // HOOK TO FIND PLAYERS SELECTED
  const GETPLAYERS = (PLAYERS) => {
    const ARR = [];
    // Store Player IDs for Strapi API
    PLAYERS.map((item, i) => {
      ARR.push(item.LMSID);
    });
    // Find Strapi Players
    GetPlayer(PLAYERS, ARR);
  };

  useEffect(() => {
    // API CALLBACK
    if (Player !== false) {
      setVIDEODATA({
        ...VIDEODATA,
        DATAOBJ: { ...CleanTop5DATA(Player, PATH) },
      });
    }
  }, [Player]);

  // Main Function
  const FindVIDEODATA = async (DATA) => {
    // Send QUERY DATA to MergeTop5APICallBack for sorting
    // MergeTop5APICallBack(DATA,VARIABLE TO GROUP AROUND)

    let ITEM = orderBy(
      MergeTop5APICallBack(DATA, PATH),
      [PATH, "BATTING_Runs"],
      ["desc", "desc"]
    );
    GETPLAYERS(ITEM.slice(0, 5));
  };

  useEffect(() => {}, [VIDEODATA]);

  return [VIDEODATA, FindVIDEODATA];
};
