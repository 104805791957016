import { useEffect, useState } from "react";
import {ENV} from '../../Config/RunTimeConfig'
const qs = require('qs');

export const useRunFranchiseChecker = ()=>{
    const [FranchiseChecker,setFranchiseChecker] = useState(false)
 
    const RunFranchiseChecker = async(_ID)=>{
        const query = qs.stringify({ id:_ID});
 
        const res = await fetch(`${ENV.API_URL}/api/franchise/Datacheck/${_ID}?${query}`)
        const Players = await res.json();

      //console.log("DATACHECK RETUNRED VALUE ", Players)
        setFranchiseChecker(Players)
    }
    useEffect(()=>{},[FranchiseChecker])
    return [FranchiseChecker, RunFranchiseChecker]   
}