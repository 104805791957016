import { orderBy } from "lodash";

// CORE Components
import { SupImages } from "./SuplimentaryImages";
import { StatContainer } from "./StatContainer";
import { COPY, EMPASISVALUE } from "./Copy";
export const SectionFielding = ({ DATA, OBJ }) => {
  const IMAGES = orderBy(
    DATA.DATAOBJ.TeamStats.PLAYERS,
    ["PLAYERS_Catches"],
    ["desc"]
  ).slice(0, 5);
  return (
    <>
      <Sentence
        DURATION={OBJ.DURATION}
        Name={DATA.DATAOBJ.General.Team}
        CATCHES={DATA.DATAOBJ.TeamStats.FIELDING.CATCHES}
        BALLS={DATA.DATAOBJ.TeamStats.BATTING.BALLS}
        Games={DATA.DATAOBJ.General.Played}
      />
      <StatContainer
        LABEL={`FIELDING`}
        DURATION={OBJ.DURATION}
        TIMINGS={{
          SlideIn: 7,
          LabelPosition: 40,
          ClipIn: 15,
          SlideUp: 30,
        }}
      >
        <SupImages
          DATA={IMAGES}
          DURATION={OBJ.DURATION}
          Limit={5}
          VAR={"PLAYERS_Catches"}
          Label="Top 5 Catches Taken"
        />
      </StatContainer>
    </>
  );
};

const Sentence = ({ Name, CATCHES, BALLS, Games, DURATION }) => {
  return (
    <>
      <COPY DURATION={DURATION}>
        {Name} TOOK <EMPASISVALUE VALUE={CATCHES} /> CATCHES IN {Games} GAMES,
        OR <EMPASISVALUE VALUE={(CATCHES / Games).toFixed(2)} /> per game,
        WITH A CATCH TAKEN EVERY <EMPASISVALUE VALUE={(BALLS / CATCHES).toFixed(0)} /> BALLS BOWLED.
      </COPY>
    </>
  );
};