import { useState } from "react" 
import {ENV} from '../../Config/RunTimeConfig'

export const useFetchFrontEndExamples= (ctx)=>{

    const [FrontEndExample,setFrontEndExample] = useState(null);
   
    
    const FetchFrontEndExample = async ()=>{
      

        const res = await fetch(`${ENV.API_URL}/api/front-end-asset-examples`, { 
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
          
        })
        const Themes = await res.json();
      console.log(Themes)

      setFrontEndExample(Themes.data)
    }
    return [FrontEndExample,FetchFrontEndExample]
}