import { useEffect } from "react";
import { useGetLeagues } from "../../../Hooks/Content/useLeagues";

import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";

import { Container, ScrollArea, Space, Divider } from "@mantine/core";
import { inRange, orderBy } from "lodash";

import { UISkeletonLines } from "../../../Components/Global/Skeleton";
import moment from "moment";
import { BTN_ICON_LINK } from "../../../Theme/Structure/Button/BTNS";

// UI ELEMENTS
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";
import {
  TABLE_TD,
  TABLE_THEAD_WRAPPER,
  TABLE_WRAPPER,
} from "../../../Components/interface/Wrapper/Table";
import { H3 } from "../../../Theme/type";

export const UpcomingFixtures = () => {
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Upcoming Fixture list",
          ICON: "Calendar",
          DESCRIPTION:
            "Quick look at fixtures scheduled within the next 7 days.",
          TOOLBAR: false,
        }}
      />

      <Space h={10} />
      <Container size="xl"  p={0}>
        <VideoWizardContainer>
          <Fixtures />
        </VideoWizardContainer>
      </Container>
      <Space h={30} />
      <Divider my="lg" color="white" />
      <Space h={30} />
    </>
  );
};
const Fixtures = () => {
  const [getLeagues, FetchgetLeagues] = useGetLeagues();

  useEffect(() => {
    FetchgetLeagues();
  }, []);
  useEffect(() => {}, [getLeagues]);

  const FindUpcoming = () => {
    const ARR = [];
    let SevenDaysFromNow = Math.floor(
      (Date.now() + 7 * 24 * 60 * 60 * 1000) / 1000
    );
    let Today = Math.floor(Date.now() / 1000);
    getLeagues.map((LEG, i) => {
      if (LEG.attributes.hasCompleted !== true) {
        LEG.attributes.fixtures.data.map((fix, i) => {
          if (inRange(fix.attributes.UnixTime, Today, SevenDaysFromNow))
            ARR.push(fix);
        });
      }
    });
    return orderBy(ARR, (obj) => parseInt(obj.attributes.UnixTime), ["asc"]);
  };

  if (!getLeagues) return <UISkeletonLines />;
  if (FindUpcoming().length === 0)
    return (
      <>
        <Space h="xl" />
        <H3 colorID={5} align="center">
          {FindUpcoming().length} Upcoming fixtures listed for the next 7 days
        </H3>
      </>
    );
  return (
    <>
      <Space h="xl" />

      <ScrollArea style={{ height: 350 }}>
        <TABLE_WRAPPER>
          <TABLE_THEAD_WRAPPER
            HEADERS={["Date", "Home Team", " ", "Away Team", "LMS"]}
          />

          <tbody>
            {FindUpcoming().map((fix, i) => {
              return (
                <tr key={i}>
                  <TABLE_TD>
                    {moment(fix.attributes.Date).format("DD MMMM")}
                  </TABLE_TD>
                  <TABLE_TD>{fix.attributes.HomeTeam}</TABLE_TD>
                  <TABLE_TD>vs</TABLE_TD>
                  <TABLE_TD>{fix.attributes.AwayTeam}</TABLE_TD>
                  <TABLE_TD>
                    <BTN_ICON_LINK
                      TO={`https://www.lastmanstands.com/${fix.attributes.Link}`}
                      ICON="ExternalLink"
                      TIPOBJ={{
                        LABEL: "Go To Fixture",
                        COLOR: "gray",
                        POSITION: "left",
                      }}
                    />
                  </TABLE_TD>
                </tr>
              );
            })}
          </tbody>
        </TABLE_WRAPPER>
      </ScrollArea>
    </>
  );
};
