import { createStyles, Container, Group, Anchor, Image } from '@mantine/core';
/* import { MantineLogo } from '@mantine/ds'; */

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: 0,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.md,
    },
  },
}));



const links= [
      {
        "link": "/GiveItAGo",
        "label": "Give it a try"
      },
      {
        "link": "/Terms",
        "label": "Terms"
      },
      {
        "link": "/Users",
        "label": "user Agreement"
      }
    ]
  
export function FooterSimple() {
  const { classes } = useStyles();
  const items = links.map((link) => (
   /*  <Anchor
      color="dimmed"
      key={link.label}
      href={link.link}
      onClick={(event) => event.preventDefault()}
      size="sm"
    >
      {link.label}
    </Anchor> */
     <a
     key={link.label}
     href={link.link}
    
    
   >
     {link.label}
   </a>
  ));

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        
      <Image 
          src={'/StattoLogo.png'}
          height={50}
          width={50}
          fit='contain'
        />
        <Group className={classes.links}>{items}</Group>
      </Container>
    </div>
  );
}