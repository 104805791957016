import {
  Sequence,
  interpolate,
  Audio,
  useCurrentFrame,
  useVideoConfig,
  spring,
  Series,
} from "remotion";
import { useTheme } from "styled-components";
// Components
import { LargeLabel } from "../../../Components/LeagueLabel";

import { FrostedItem } from "../../../utils/Containers";

/* import {FixtureResultsUL} from "./FixtureResultsUL" */
import { FullWidthWrapper } from "../../../utils/Containers";
import styled from "styled-components";
import { H1, H3, H4, P } from "../../../utils/Text";
import { FindIMGFORMAT, TrimPlayerName } from "../../../../../../actions/UX";

const UL = styled.ul`
  display: flex;
  flex-direction: row;
  margin: 5% 0 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  flex-wrap: wrap;
  list-style: none;
`;
const LI = styled.li`
  height: 220px;
  width: 50%;
  position: relative;
`;
const Outer = styled.div`
  position: relative;
  width: 92%;
  margin: 0 2% 0 6%;
  height: 220px;
`;
const Inner = styled.div`
  width: 380px;
  margin-left: 220px;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 220px;
  align-content: center;
`;

const PlayerImage = styled.div`
  left: 0;
  width: 220px;
  height: 220px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 5%;
  position: absolute;
  z-index: 1000;
`;
const TeamImage = styled.div`
  width: 75px;
  height: 75px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  position: absolute;
  bottom: -25px;
  z-index: 5000;
  left: 70px;
`;

export const TEAMOFTHEWEEK_BODY = ({ DATA, VideoSettings, RenderType }) => {
  const theme = useTheme();
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const Labels = {
    Bat1: "Top Run Scorer",
    Bat2: "Top Run Scorer",
    Bat3: "Fastest 50",
    AR: "Best All rounder",
    Bowler1: "Most Wickets",
    Bowler2: "Most Wickets",
    Bowler3: "Best economy bowler", 
    Keeper: "Best Keeper/Fielder",
  };

  const ExpandContainer = (int) => {
    return spring({
      fps: fps / 2,
      from: 44,
      to: 100,
      frame: frame - int,
      config: {
        damping: 80,
        stiffness: 100,
        mass: 0.5,
      },
    });
  };

  const BorderRadius = (int) => {
    return spring({
      fps: fps / 2,
      from: 100,
      to: 2,
      frame: frame - int,
      config: {
        damping: 80,
        stiffness: 100,
        mass: 0.5,
      },
    });
  };
  const ScaleIn = (int) => {
    return spring({
      fps: fps / 2,
      from: 0,
      to: 1,
      frame: frame - int,
      config: {
        damping: 8,
        stiffness: 50,
        mass: 0.8,
      },
    });
  };
  const FadeIn = (START, END) => {
    return interpolate(frame, [START, END], [0, 1], {
      extrapolateRight: "clamp",
    });
  };
  const FadeOut = (START, END) => {
    return interpolate(frame, [START, END], [1, 0], {
      extrapolateRight: "clamp",
    });
  };

  const ConstTiming = (INT) => {
    const FPS = 75;
    return FPS + FPS * INT;
  };

  const RevealPause = 7;
  const revealHold = 45;

  return (
    <>
      <Audio
        src={`https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/TeamOfTheWeekSoundEffects.mp4`}
        playbackRate={1}
        startFrom={0} // if composition is 30fps, then it will start at 2s
        volume={0.5}
      />
      <FullWidthWrapper>
        <LargeLabel LABEL={"Team of the Week"} />
        <UL>
          {Object.keys(DATA.DATAOBJ).map((key, i) => {
            return (
              <LI key={i}>
                <Sequence
                  from={3 * i}
                  durationInFrames={VideoSettings.VideoBodyTiming}
                >
                  <Outer style={{ display: "flex", justifyContent: "center" }}>
                    <FrostedItem
                      style={{
                        background: theme.radialGradient,
                        opacity: FadeIn(3 * i, 30 + i),
                        transform: `scale(${ScaleIn(7)})`,
                        display: "flex",
                        alignItems: "center",
                        width: `${ExpandContainer(ConstTiming(i))}%`,
                        borderRadius: `${BorderRadius(ConstTiming(i))}%`,
                      }}
                    >
                      <Series>
                        <Series.Sequence durationInFrames={ConstTiming(i)}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              textAlign: "center",
                              alignContent: "center",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <H1
                              style={{
                                opacity: FadeOut(
                                  ConstTiming(i) - 15,
                                  ConstTiming(i)
                                ),
                                width: " 100%",
                                color: theme.Copy[0],
                              }}
                            >
                              ?
                            </H1>
                          </div>
                        </Series.Sequence>
                        <Series.Sequence
                          durationInFrames={VideoSettings.VideoBodyTiming}
                        >
                          <div>
                            <PlayerImage
                              style={{
                                opacity: FadeIn(
                                  ConstTiming(i) + RevealPause,
                                  ConstTiming(i) + revealHold
                                ),
                                backgroundImage: `url(${DATA.DATAOBJ[key].IMAGE})`,
                              }}
                            />
                            <TeamImage
                              style={{
                                opacity: FadeIn(
                                  ConstTiming(i) + RevealPause,
                                  ConstTiming(i) + revealHold
                                ),
                                backgroundImage:`url(${DATA.DATAOBJ[key]?.team?.data.attributes.Logo?.data.attributes.url})`,
                              }}
                            />
                            <Inner>
                              <H4
                                style={{
                                  opacity: FadeIn(
                                    ConstTiming(i) + RevealPause,
                                    ConstTiming(i) + revealHold
                                  ),
                                  position: "absolute",
                                  top: "-30px",
                                  left: 0,
                                  letterSpacing: 0,
                                  fontWeight: 200,
                                  width: " 100%",
                                  color: theme.Copy[0],
                                  textAlign: "left",
                                }}
                              >
                                {Labels[key]}
                              </H4>

                              <H1
                                style={{
                                  letterSpacing: "0px",
                                  color: theme.Copy[0],
                                  fontSize: "2em",
                                  opacity: FadeIn(
                                    ConstTiming(i) + RevealPause,
                                    ConstTiming(i) + revealHold
                                  ),
                                  textAlign: "left",
                                  width: " 100%",
                                }}
                              >
                                {TrimPlayerName(
                                  DATA.DATAOBJ[key].player.data.attributes.Name,
                                  12
                                )}
                              </H1>
                              <div
                                style={{
                                  letterSpacing: "0px",
                                  color: theme.Copy[0],
                                  opacity: FadeIn(
                                    ConstTiming(i) + RevealPause,
                                    ConstTiming(i) + revealHold
                                  ),
                                  textAlign: "left",
                                  width: " 100%",
                                  margin: ".1em",
                                }}
                              >
                                <Performance DATA={DATA.DATAOBJ[key]} K={key} />
                              </div>
                              <P
                                style={{
                                  letterSpacing: "0px",
                                  color: theme.Copy[0],
                                  opacity: FadeIn(
                                    ConstTiming(i) + RevealPause,
                                    ConstTiming(i) + revealHold
                                  ),

                                  textAlign: "right",
                                  width: "100%",
                                  margin: "0px",
                                  position: "absolute",
                                  bottom: "-31px",
                                  right: "0",
                                }}
                              >
                                {DATA.DATAOBJ[key]?.team?.data.attributes.Name}
                              </P>
                            </Inner>
                          </div>
                        </Series.Sequence>
                      </Series>
                    </FrostedItem>
                  </Outer>
                </Sequence>
              </LI>
            );
          })}
        </UL>
      </FullWidthWrapper>
    </>
  );
};

const Performance = ({ DATA, K }) => {
  const BATTING = <DISPLAYBATTING DATA={DATA} />;
  const BOWLING = <DISPLAYBOWLING DATA={DATA} />;
  const ALLROUNDER = <DISPLAYALLROUNDER DATA={DATA} />;
  const KEEPER = <DISPLAYKEEPER DATA={DATA} />;

  const STATS = {
    Bat1: BATTING,
    Bat2: BATTING,
    Bat3: BATTING,
    AR: ALLROUNDER,
    Bowler1: BOWLING,
    Bowler2: BOWLING,
    Bowler3: BOWLING,
    Keeper: KEEPER,
  };

  return STATS[K];
};

const DISPLAYBATTING = ({ DATA }) => {
  const theme = useTheme();
  return (
    <H3
      style={{
        textAlign: "left",
        color: theme.Copy[0],
        letterSpacing: "-1px",
      }}
    >
      {DATA.BATTING_Runs} off {DATA.BATTING_Balls} ({DATA.BATTING_SR})
    </H3>
  );
};
const DISPLAYBOWLING = ({ DATA }) => {
  const theme = useTheme();
  return (
    <H3
      style={{
        textAlign: "left",
        color:theme.Copy[0],
        letterSpacing: "-1px",
      }}
    >
      {DATA.BOWLING_Wkts}/{DATA.BOWLING_Runs} off {DATA.BOWLING_Overs} (
      {DATA.BOWLING_Econ})
    </H3>
  );
};

const DISPLAYALLROUNDER = ({ DATA }) => {
  const theme = useTheme();
  return (
    <H3
      style={{
        textAlign: "left",
        color:theme.Copy[0],
        letterSpacing: "-1px",
      }}
    >
      {DATA.BATTING_Runs} off {DATA.BATTING_Balls} ({DATA.BATTING_SR}) <br />{" "}
      {DATA.BOWLING_Wkts}/{DATA.BOWLING_Runs} ({DATA.BOWLING_Overs})
    </H3>
  );
};
const DISPLAYKEEPER = ({ DATA }) => {
  const theme = useTheme();
  return (
    <>
      <H3
        style={{
          textAlign: "left",
          color: theme.Copy[0],
          letterSpacing: "-1px",
        }}
      >
        {DATA.PLAYERS_Stumpings !== undefined
          ? `${DATA.PLAYERS_Stumpings} Stumping(s)`
          : false}{" "}
      </H3>
      <H3
        style={{
          textAlign: "left",
          color: theme.Copy[0],
          letterSpacing: "-1px",
        }}
      >
        {DATA.PLAYERS_Catches !== undefined
          ? `${DATA.PLAYERS_Catches} Catch(s)`
          : false}
      </H3>
    </>
  );
};
