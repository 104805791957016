import { useState } from "react";
import {ENV} from '../../Config/RunTimeConfig'
import { parseCookies } from 'nookies'
import { useSelector,useDispatch } from 'react-redux'

import {userSetInvoice,userSetOrderHistory} from '../../store/Reducer/AUTH';


const qs = require('qs');


export const useGetUserInvoices=(ctx)=>{
    const [invoices, setInvoice] = useState(false)
    const dispatch = useDispatch()
    const jwt = parseCookies(ctx).jwt
    const AUTHID = parseCookies(ctx).AUTHID
 
    const FetchUserInvoices = async(_USER)=>{
    //console.log('_USER', AUTHID)
        const query = qs.stringify({
            filters: {  
              users_permissions_user: { id:AUTHID},
              /* orders:{ 
                isActive:{
                    $ne:false
                }
                } */
             }, 
            populate: { 
              orders: { 
                fields:['isActive','AssetPrice','AssetType','DataSet','Franchise','Name','Order_Date','Payment_Status','Sponsor'],
                //populate: ['render_lists','render_lists.Rendered_Still ','asset','asset.hashtags'],
                populate:{
                    render_lists:{
                        fields:['AssetType','DownloadURI','Status_Message','createdAt','hasBeenProcessed','isProcessing','hasRenderError']
                    }
                } 
              },
            },  
          }, {   
            encodeValuesOnly: true,  
          });
          const res = await fetch(`${ENV.API_URL}/api/invoices?${query}`, {  
            headers: { Authorization: `Bearer ${jwt}` }
        })
        const InvoiceResponse = await res.json();

      console.log(InvoiceResponse)
        dispatch(userSetInvoice(InvoiceResponse.data[0]?.attributes.orders));
        dispatch(userSetOrderHistory(InvoiceResponse.data[0]?.attributes?.orders));  
         
         
        setInvoice(true)
    }  

    return[invoices,FetchUserInvoices]
}
/*
AssetType: "TOPFIVECATCHES916"
DATA: {VideoSettings: {…}, OrderMetadata: {…}, DATAOBJ: {…}}
DownloadURI: "https://s3.ap-southeast-2.amazonaws.com/remotionlambda-69q0up4r9e/renders/72pvywwqt9/TOP5CATCHES_SYDNEY_WINTER_FULL_COMP.mp4"
Name: "Video 9:16 Facebook : Instagram"
Purpose: "Facebook : Instagram"
Ratio: "Video 9:16"
Status_Message: "Order is Ready!"
chunks: "69"
costs: 0.09108
createdAt: "2022-05-23T01:33:32.780Z"
hasBeenProcessed: true
hasRenderError: false
isProcessing: true
outBucket: "remotionlambda-69q0up4r9e"
outKey: "renders/72pvywwqt9/TOP5CATCHES_SYDNEY_WINTER_FULL_COMP.mp4"
publishedAt: "2022-05-23T01:33:32.778Z"
renderId: "72pvywwqt9"
renderSize: "33712822"
startedDate: "1653269644584"
totalFrames: "1380"
updatedAt: "2022-05-23T01:35:44.061Z"
*/





export const useAssetInvoice=(ctx)=>{

    const [InvoiceStatus, setInvoiceStatus] = useState(false);
    const [invoices,FetchUserInvoices] = useGetUserInvoices()
    const AUTH = useSelector((state) => state.AUTH);
    const jwt = parseCookies(ctx).jwt;



    const GetUserInvoices = async()=>{
        
        const query = qs.stringify({
            filters: {  users_permissions_user: { id:AUTH.AUTHUSER.id} },
            populate: '*', 
          }, {
            encodeValuesOnly: true,
          });
          const res = await fetch(`${ENV.API_URL}/api/invoices?${query}`, {  
            headers: { Authorization: `Bearer ${jwt}` }
        })
        const loginResponse = await res.json();

        return loginResponse
    }

 

    const AddToInvoice = async(_ORDERID, _INVOICEID)=>{
        //console.log(_ORDERID, _INVOICEID)
        const res = await fetch(`${ENV.API_URL}/api/orders/${_ORDERID}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({
                data: { 
                    invoice:[_INVOICEID]
                  }
            })
        })
        const OrderResponse = await res.json();
        //console.log('OrderResponse', OrderResponse)


        //use the Usewr Invoice hook to Fetch users Invoice for Store
        FetchUserInvoices()

    }

    const CreateNewInvoice = async(_ORDERID)=>{
        
        //console.log(AUTH.AUTHUSER.username, AUTH.AUTHUSER.id})
        const res = await fetch(`${ENV.API_URL}/api/invoices`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({
                data: {
                    Name: `Invoice_${AUTH.AUTHUSER.username}`,
                    users_permissions_user:[AUTH.AUTHUSER.id], 
                  
                  }
            })
        })
        const InvoiceResponse = await res.json();
        
        //console.log(InvoiceResponse)
        AddToInvoice(_ORDERID, InvoiceResponse.data.id)
    }



    const CreateInvoice = async (_OrderResponse)=>{

        const UserInvoices = await GetUserInvoices()
        
        //console.log(UserInvoices.data.length)
        if(UserInvoices.data.length != 0){
            // add order to invoice
            //console.log(UserInvoices.data[0].id)
            AddToInvoice(_OrderResponse.data.id, UserInvoices.data[0].id)
        }else{
            //console.log('NO INVOICE FOUND')
            CreateNewInvoice(_OrderResponse.data.id)
           // Create a new invoice
        }
        //console.log(UserInvoices.data, _OrderResponse)
        
    }

    return[InvoiceStatus,CreateInvoice]
}

