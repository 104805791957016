import {
  useCurrentFrame,
  useVideoConfig,
  spring,
  Series,
  Audio,
} from "remotion";
import { useTheme } from "styled-components";
// Components
/* import {FixtureResultsUL} from "./FixtureResultsUL" */
import { FullWidthWrapper } from "../../../utils/Containers";
import styled from "styled-components";
import { H1, H2 } from "../../../utils/Text";
import { FindIMGFORMAT } from "../../../../../../actions/UX";

import { Image } from "@mantine/core";
import { interpolateOpacityByFrame } from "../../../../Animation/interpolate";

// Compoents
import { PlayerIntro } from "./Components/PlayerIntro";
import { CareerStats } from "./Components/CareerStats";
import {BattingStats} from './Components/BattingStats';
import {BowlingStats} from './Components/BowlingStats';

const SpringToFrom = (int, FROM, TO, effect = "Default") => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  return spring({
    fps: fps / 2,
    from: FROM,
    to: TO,
    frame: frame - int,
    config: {
      damping: 10,
      stiffness: 55,
      mass: 0.9,
    },
  });
};

const SelectImage = (GALLERY, I) => {
  let USEIMAGE;
 
  const SELECTED = GALLERY.length > I ? I:Math.floor((Math.random() * GALLERY.length))

  USEIMAGE = GALLERY[SELECTED].attributes
  const IMGHeight = USEIMAGE.width > USEIMAGE.height ? 460 : 800;
  const IMGTOP = USEIMAGE.width > USEIMAGE.height ? 400 : 250;
  return { url: USEIMAGE.url, Height: IMGHeight, Top: IMGTOP };
};

const SeriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  flex-wrap: wrap;
  list-style: none;
`;

export const PLAYERMILESTONE_BODY = ({ DATA, AUDIO }) => {
  const MAIN = FindIMGFORMAT(DATA.DATAOBJ.image.data.attributes.formats);
  const GALLERY = DATA.DATAOBJ.ImageGallery.data;
  console.log(GALLERY)

  /*
 // For future reference, this FUNC is here so that it only renders on build not on each frame
  */
  const SelectImage = (GALLERY,INT) => {
    let USEIMAGE;
    let POSITION  = GALLERY.length > INT ? INT : Math.floor(Math.random() * GALLERY.length);
    USEIMAGE = GALLERY[POSITION].attributes;

    const IMGHeight = USEIMAGE.width > USEIMAGE.height ? 460 : 800;
    const IMGTOP = USEIMAGE.width > USEIMAGE.height ? 400 : 250;
    return { url: USEIMAGE.url, Height: IMGHeight, Top: IMGTOP };
  };


  return ( 
    <>
      <FullWidthWrapper>
        <Series>
          <Series.Sequence durationInFrames={120} layout="none">
            <PlayerIntro
              DATA={DATA}
              Duration={210}
              PROFILEPICTURE={MAIN}
          
            />
          </Series.Sequence>
          <Series.Sequence durationInFrames={150} layout="none">
            <CareerStats
              DATA={DATA}
              Duration={150}
              IMG={SelectImage(GALLERY,1)} 
            />
          </Series.Sequence>
          <Series.Sequence durationInFrames={180} layout="none">
            <BattingStats
              DATA={DATA}
              Duration={180}
              IMG={SelectImage(GALLERY, 2)}
            />
          </Series.Sequence>
          <Series.Sequence durationInFrames={180} layout="none">
            <BowlingStats
              DATA={DATA}
              Duration={180}
              IMG={SelectImage(GALLERY, 3)}
            />
          </Series.Sequence>
          <Series.Sequence durationInFrames={210} layout="none">
            <LMSNOTICE DATA={DATA} Duration={210} />
          </Series.Sequence>
        </Series>
      </FullWidthWrapper>

      <Audio
        src={`https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/MileStoneSoundFIle.mp4`}
        playbackRate={1}
        startFrom={0} // if composition is 30fps, then it will start at 2s
        volume={0.5}
      />
    </>
  );
};




const CongratsContainer = styled.div`
  border: 1px solid;
  width: 90%;
  margin: 35% 5%;
  padding: 3rem;
  border-radius: 10px;
  background-color: rgb(0 0 0 / 20%);
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const LMSNOTICE = ({ DATA, Duration }) => {
  //console.log(DATA.DATAOBJ.ImageGallery.data);
  const frame = useCurrentFrame();
  const theme = useTheme();
  return (
    <SeriesContainer>
      <CongratsContainer
        style={{
          height: `${SpringToFrom(0, 0, 52)}% `,
          opacity: interpolateOpacityByFrame(
            frame,
            Duration - 15,
            Duration - 5,
            1, 
            0
          ),
        }}
      >
        <H2
          style={{
            opacity: interpolateOpacityByFrame(frame, 15, 30, 0, 1),
            transform: `translateY(${SpringToFrom(15, -100, 0)}%)`,
            marginBottom: `${SpringToFrom(15, 0, 40)}px`,
            fontSize: "3em",
            lineHeight: "1.1em",
            width: "100%",
            color: theme.Copy[1],
          }}
        >
          From everyone here at LMS HQ and the wider LMS community.
        </H2>

        <H1
          style={{
            fontSize: "5.5em",
            width: "100%",
            color: theme.Copy[1],
            transform: `translateX(${SpringToFrom(7, 150, 0)}px)`,
          }}
        >
          {"Congratulations".split("").map((D, i) => {
            return (
              <span
                key={i}
                style={{
                  margin: 0,
                  opacity: interpolateOpacityByFrame(
                    frame,
                    1 + 1 * (i + 1),
                    2 + 1 * (i + 1),
                    0,
                    1
                  ),
                }}
              >
                {D}
              </span>
            );
          })}
        </H1>

        <H2
          style={{
            opacity: interpolateOpacityByFrame(frame, 15, 45, 0, 1),
            transform: `translateY(${SpringToFrom(15, 100, 0)}%)`,
            marginTop: `${SpringToFrom(15, 0, 40)}px`,
            fontSize: "3em",
            lineHeight: "1.1em",
            width: "100%",
            color: theme.Copy[1],
          }}
        >
          on an amazing ACHIEVEMENT!
        </H2>
      </CongratsContainer>

      {/* <ImageGallery
        GALLERY={DATA.DATAOBJ.ImageGallery.data}
        Duration={Duration}
      /> */}
    </SeriesContainer>
  );
};


const ImageGallery = (GALLERY, Duration) => {
  //console.log(GALLERY)
  const IMGSPEC = 300;
  const POSITION = [
    { x: 180, r: -10 },
    { x: 450, r: 12 },
    { x: 0, r: 10 },
    { x: 750, r: -12 },
  ];

  return (
    <>
      {GALLERY.GALLERY.slice(0, 4).map((IMG, i) => {
        return (
          <Image
            key={i}
            radius={10}
            height={IMGSPEC}
            width={IMGSPEC}
            src={FindIMGFORMAT(IMG.attributes.formats).url}
            style={{
              bottom: "0px",
              left: `${POSITION[i].x}px`,
              padding: "2%",
              position: "absolute",
              zIndex: "10",
              opacity: 100,
              transform: `translateY(${SpringToFrom(
                3 * i,
                800,
                0
              )}px) translateY(${SpringToFrom(190 + 3 * i, 0, 800)}px) rotate(${
                POSITION[i].r
              }deg)`,
            }}
          />
        );
      })}
      {GALLERY.GALLERY.slice(0, 4).map((IMG, i) => {
        return (
          <Image
            key={i}
            radius={10}
            height={IMGSPEC}
            width={IMGSPEC}
            src={FindIMGFORMAT(IMG.attributes.formats).url}
            style={{
              top: "0px",
              left: `${POSITION[i].x}px`,
              padding: "2%",
              position: "absolute",
              zIndex: "10",
              opacity: 100,
              transform: `translateY(${SpringToFrom(
                3 * i,
                -800,
                0
              )}px) translateY(${SpringToFrom(
                190 + 3 * i,
                0,
                -800
              )}px) rotate(${POSITION[i].r}deg)`,
            }}
          />
        );
      })}
    </>
  );
};
