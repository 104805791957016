import { Center, Loader, Stack } from "@mantine/core";
import { ColoredContainer } from "../../Theme/Structure/BodyContainer";
import { S } from "../../Theme/type";

export const IsLoading = () => {
  return (
    <Center>
      <Stack>
        <div>
          <Center>
            <Loader color="teal" variant="bars" />
          </Center>
        </div>
        <S colorID={0}>Updating</S>
      </Stack>
    </Center>
  );
};
