import { interpolate, useCurrentFrame } from "remotion";
import { TrimPlayerName } from "../../../../../../actions/UX";
import { H1, P } from "../../../utils/Text";
import { useTheme } from "styled-components";
import styled from "styled-components";
import { SpringToFrom } from "../../../../Animation/RemotionSpring";
import { interpolateOpacityByFrame } from "../../../../Animation/interpolate";
import {
  FromMiddle,
  FromBottomToTop,
  FromRightToLeft,
  FromLeftToRight,
} from "../../../../Animation/ClipWipe";

import {
  FullWidthWrapperStart,
  POTDWRAPPER,
} from "../../../utils/Containers";
import { getContrastColor } from "../../../../../../actions/colors";

export const DisplayPOTD = ({ _PLAY, _Durations }) => {
  const frame = useCurrentFrame();

  //console.log(_PLAY);
  //console.log(_Durations);
  const theme = useTheme();

  const ImgSize = interpolate(frame, [0, 180], [110, 100], {
    extrapolateRight: "clamp",
  });
  

  return (
    <>
      <FullWidthWrapperStart>
        <POTDWRAPPER
          style={{
            backgroundImage: `url(${_PLAY.IMAGE})`,
            backgroundSize: `${ImgSize}%`,
            clipPath: FromMiddle(0, "Default"),
            opacity: interpolateOpacityByFrame(
              frame,
              _Durations.Close - 10,
              _Durations.Close,
              1,
              0
            ),
            /*  transform: `
            translateY(${SpringToFrom(0, 1400, 0, "Springy100")}px)             
            translateY(${SpringToFrom(145, 100, -1300, "Springy100")}px)`, */
          }}
        >
          <TeamNameContainer
            style={{
              backgroundColor: theme.Copy[1],
              position: "relative",
              transform: `translateY(${SpringToFrom(
                _Durations.Close / 2,
                0,
                40,
                "Springy100"
              )}px)`,
              clipPath: FromBottomToTop(25, "Springy100"),
            }}
          >
            <P
              style={{
                color: getContrastColor(theme.Copy[1]),
                position: "absolute",
                margin: 0,
                left: "40px",
                top: "15px",
                opacity: interpolateOpacityByFrame(
                  frame,
                  _Durations.Close / 2,
                  _Durations.Close / 2 + 5,
                  1,
                  0
                ),
                clipPath: FromLeftToRight(7, "Springy100"),
              }}
            >
              {_PLAY.Team}
            </P>

            <P
              style={{
                position: "absolute",
                color: getContrastColor(theme.Copy[1]),
                left: "40px",
                bottom: "5px",
                margin: 0,
                opacity: interpolateOpacityByFrame(
                  frame,
                  _Durations.Close - 5,
                  _Durations.Close,
                  1,
                  0
                ),
                clipPath: FromLeftToRight(_Durations.Close / 2, "Springy100"),
              }}
            >
              Fours: {_PLAY.BATTING_fours} Sixes : {_PLAY.BATTING_sixes}
            </P>
          </TeamNameContainer>

          <NameContainer
            style={{
              backgroundColor: theme.Base[1],
              transform: `translateY(${SpringToFrom(
                _Durations.Close / 2,
                0,
                -60,
                "Springy100"
              )}px)`,
              clipPath: FromLeftToRight(15, "Springy100"),
            }}
          >
            <H1
              style={{
                position: "relative",
              }}
            >
              <span
                style={{
                  color: getContrastColor(theme.Base[1]),
                  position: "absolute",
                  left: "20px",
                  top: "25px",
                  opacity: interpolateOpacityByFrame(
                    frame,
                    _Durations.Close / 2,
                    _Durations.Close / 2 + 5,
                    1,
                    0
                  ),
                  clipPath: FromLeftToRight(25, "Springy100"),
                }}
              >
                {TrimPlayerName(_PLAY.Name)}
              </span>
              <span
                style={{
                  color: getContrastColor(theme.Base[1]),
                  position: "absolute",
                  left: "20px",
                  top: "25px",
                  opacity: interpolateOpacityByFrame(
                    frame,
                    _Durations.Close - 5,
                    _Durations.Close,
                    1,
                    0
                  ),
                  clipPath: FromLeftToRight(_Durations.Close / 2, "Springy100"),
                }}
              >
                {_PLAY.BATTING_Runs} ({_PLAY.BATTING_Balls}){" "}
                {_PLAY.BATTING_HowOut}
              </span>
            </H1>
          </NameContainer>
          <TeamLogo
            style={{
              backgroundImage: `url(${_PLAY.Logo})`,
              clipPath: FromRightToLeft(25, "Springy100"),
            }}
          />
        </POTDWRAPPER>
       {/*  <Timer Durations={_Durations} /> */}
      </FullWidthWrapperStart>
    </>
  );
};

const NameContainer = styled.div`
  position: absolute;
  width: 984px;
  height: 113px;
  left: 33px;
  top: 1169px;

  border-radius: 100px;
`;

const TeamNameContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 718px;
  height: 113px;
  left: 230px;
  top: 1113px;

  border: 2px solid;
  border-radius: 100px;
`;

const TeamLogo = styled.div`
  position: absolute;
  width: 192px;
  height: 192px;
  left: 866px;
  top: 1090px;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
`;

//
const TimerContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 10px;
`;

const Timer = ({ Durations }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();
  //console.log(((frame/Durations.Length)*100))
  return (
    <TimerContainer
      style={{
        width: `${(frame / Durations.Close) * 100}%`,
        backgroundColor: theme.Base[2],
      }}
    ></TimerContainer>
  );
};
