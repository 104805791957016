import {Series} from 'remotion';
// Assets
// Components
import {BodyContainer, GradientOverlay} from "./utils/Containers"
import {EndCredits} from './Components/EndCredits';
import {OpeningSponsorSequence} from "./Components/Opening_Sponsors"
import {OpeningTitles,DIVISIONWINNERSOPENINGTITLES} from './Components/Opening_Titles';

export const TournamentTopTail = (props)=>{

    return(
		<OuterContainer> 
			 <Series>  
				 <Series.Sequence durationInFrames={120}> 
						<OpeningTitles
							TournamentDetails={props.DATA.OrderMetadata.TournamentDetails}
					 		{...props}
						/>    
				</Series.Sequence>   
			 
				<Series.Sequence durationInFrames={120}>
					<OpeningSponsorSequence {...props}/>
				</Series.Sequence> 
				
				 
				<Series.Sequence durationInFrames={props.DATA.VideoSettings.VideoBodyTiming}>
					{ props.children } 
				</Series.Sequence>
				
				
				<Series.Sequence durationInFrames={120} > 
					<EndCredits {...props}/> 
				</Series.Sequence>
			 </Series> 
			  
	</OuterContainer>  
	)
}



const OuterContainer = (props)=>{
    return( 
			<BodyContainer {... props}>
				<GradientOverlay {... props}>
					{props.children}
				</GradientOverlay>
			</BodyContainer>
    )
}

// Bespoke Top and Tails
export const DivisionWinnersTopAndTail = (props)=>{
	//const frame = useCurrentFrame();
	console.log(props)
	
    return(
		<OuterContainer>  
			 <Series> 
				 <Series.Sequence durationInFrames={90}> 
						<DIVISIONWINNERSOPENINGTITLES 
							MainTitle={props.DATA.General.League}
							Subtitle={props.DATA.General.Team}
							VideoDate={props.DATA}
							TournamentLogo={props?.DATA.OrderMetadata?.AdditionalInfo?.TournamentLogo}
						/>  
				</Series.Sequence>   
 
				 
				
				<Series.Sequence durationInFrames={props.DATA.VideoSettings.VideoBodyTiming}>
				{ props.children }
				</Series.Sequence>
				{
					props.DATA.OrderMetadata.Sponsor !== false ? <Series.Sequence durationInFrames={120}><OpeningSponsorSequence {...props}/></Series.Sequence>:false
				}
				<Series.Sequence durationInFrames={120} >
					<EndCredits {...props}/>
				</Series.Sequence>
			 </Series>
			 
	</OuterContainer>  
	)
}