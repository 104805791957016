import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Month, RangeCalendar, Calendar } from "@mantine/dates";
import { Group, Stack } from "@mantine/core";

import { H4_UPPERCASE } from "../../Theme/type";

import moment from "moment";
import { BTN_CTA } from "../../Theme/Structure/Button/BTNS";

export const VideoPickDate = ({ setFromDate }) => {
  const [value, setValue] = useState(new Date());

  useEffect(() => {
    setFromDate(moment(value).format("YYYY-MM-DD"));
  }, [value]);
  return (
    <>
      <Stack>
        <H4_UPPERCASE>{moment(value).format("MMMM")}</H4_UPPERCASE>

        <Month month={value} value={value} onChange={setValue} />
      </Stack>
    </>
  );
};

export const VideoPickDateRange = ({ setFromDate }) => {
  const [CalendarType, setCalendarType] = useState(false);

  var date = new Date();
  date.setDate(date.getDate() + 4);
  const [value, setValue] = useState([new Date(), date]);

  const SelectedDates = (e) => {
    //console.log(e, isNaN(e[1]))

    if (e[0] === null || e[1] === null) return false;
    setValue(e);
    setFromDate([
      moment(e[0]).format("YYYY-MM-DD"),
      moment(e[1]).format("YYYY-MM-DD"),
    ]);
  };

  return (
    <Stack>
      {CalendarType ? (
        <SelectSingleDate setValue={setFromDate} />
      ) : (
        <SelectMultipleDates setFromDate={setFromDate} />
      )}

      <BTN_CTA
        BG="3"
        Color="0"
        CALLBACK={() => {
          setCalendarType(!CalendarType);
        }}
        LABEL={CalendarType ? "Change to Multiple Dates" : "Change to Single Date"}
      />
    </Stack>
  );
};

export const SelectMultipleDates = ({ setFromDate }) => {
  var date = new Date();
  date.setDate(date.getDate() + 4);
  const [value, setValue] = useState([new Date(), date]);

  const SelectedDates = (e) => {
    if (e[0] === null || e[1] === null) return false;
    setValue(e);
    setFromDate([
      moment(e[0]).format("YYYY-MM-DD"),
      moment(e[1]).format("YYYY-MM-DD"),
    ]);
  };
  return (
    <RangeCalendar
      value={value}
      onChange={SelectedDates}
      styles={(theme) => ({
        day: {
          "&[data-selected], &[data-in-range],&:focus": {
            backgroundColor: theme.colors.brand[3],
            color: theme.colors.brand[0],
          },
        },
      })}
    />
  );
};

const SelectSingleDate = ({ setValue }) => {
  const [SingleValue, setSingleValue] = useState(new Date());

  const SetSelectedDates = (e) => {
    setSingleValue(e);
    setValue([moment(e).format("YYYY-MM-DD"), moment(e).format("YYYY-MM-DD")]);
  };
  return (
    <Calendar
      value={SingleValue}
      onChange={SetSelectedDates}
      styles={(theme) => ({
        day: {
          "&[data-selected], &:focus": {
            "&, &:hover, &:focus": {
              backgroundColor: theme.colors.brand[3],
              color: theme.colors.brand[0],
            },
          },
        },
      })}
    />
  );
};
