import { useEffect, useState } from "react";
import { FindTeamLogoURI } from "../../../../../actions/video";
import { orderBy, sortBy, filter, groupBy, find } from "lodash";

const VideoOBJ = {
  VideoDurationinFrames: null,
  //FixturesPerPage:4,
  Openingfps: 0,
  VideoBodyTiming: 0,
  FixtureAnimateIn: 15,
  FixtureAnimateOut: 5,
  FixturePauseAfterLoad: 60,
  LeagueDurations: [],
  LeagueStartFrom: [],
  EndCreditsDurationStartAt: null,
  EndCreditsDuration: 60,
  RenderStill: [],
};
export const useCreateFixtures = () => {
  const VideoSettings = { ...VideoOBJ };
  const [Fixtures, setFixtures] = useState({ VideoSettings });
 
  const PrepStrapiData = async (DATA) => {
    const CLEANDATA = [];
    // Prepare Fixtures 
    DATA.map((Val, i) => {
      let item = Val.attributes; 

      console.log(item)
      console.log(find(item.teams.data, (o) => {
        return o.attributes.Name === item.AwayTeam;
      }).attributes.Form)



      CLEANDATA.push({
        awayTeam: item.AwayTeam,
        awayTeamLogo: FindTeamLogoURI(
          find(item.teams.data, (o) => {
            return o.attributes.Name === item.AwayTeam;
          })
        ),
        awayTeamForm: find(item.teams.data, (o) => {
          return o.attributes.Name === item.AwayTeam;
        }).attributes.Form,
        awayTeamWR: find(item.teams.data, (o) => {
          return o.attributes.Name === item.AwayTeam;
        }).attributes.World_Rank,
        homeTeam: item.HomeTeam,
        homeTeamLogo: FindTeamLogoURI(
          find(item.teams.data, (o) => {
            return o.attributes.Name === item.HomeTeam;
          })
        ),
        homeTeamForm: find(item.teams.data, (o) => {
          return o.attributes.Name === item.HomeTeam;
        }).attributes.Form,
        homeTeamWR: find(item.teams.data, (o) => {
          return o.attributes.Name === item.HomeTeam;
        }).attributes.World_Rank,
        date: item.Date,
        time: item.Time,
        unixTime: item.UnixTime,
        fixtureID: item.fixtureID,
        ground: item.ground.data?.attributes.Name,
        umpire: item.umpire.data?.attributes.Name,
        region: item.watch_list.data?.attributes.Name,
      });
    });

    return CLEANDATA;
  };

  const FindLeagueDurations = (DATAOBJ) => {
    const LeagueDurations = [];
    console.log("League length", Object.keys(DATAOBJ.Fixtures).length);
    Object.keys(DATAOBJ.Fixtures).map((League, i) => {
      console.log("League length", DATAOBJ.Fixtures[League]);
      
      LeagueDurations.push(
        Math.ceil(DATAOBJ.Fixtures[League].length / 4) * 270
      );

    });
    return LeagueDurations; 
  };

  const StartFrom = () => {
  
    const STARTFROM = [];
    //let TimeProgression=0
    Fixtures.VideoSettings.LeagueDurations.map((time, i) => {
      
      STARTFROM.push(270);
    });
    return STARTFROM;
  };

  const EndCreditsDurationStart = () => {
    //console.log(VideoSettings.LeagueStartFrom[(VideoSettings.LeagueStartFrom.length-1)],VideoSettings.LeagueDurations[(VideoSettings.LeagueDurations.length-1)])
    return (
      Fixtures.VideoSettings.LeagueStartFrom[Fixtures.VideoSettings.LeagueStartFrom.length - 1] +
      Fixtures.VideoSettings.LeagueDurations[Fixtures.VideoSettings.LeagueDurations.length - 1]
    );
  };

  const VideoDuration = () => {
    return 90 + 120 + 120 + Fixtures.VideoSettings.VideoBodyTiming;
  };
  const FindVideoBodyTiming = () => {
    //console.log(Fixtures.VideoSettings.LeagueDurations);
    return Fixtures.VideoSettings.LeagueDurations.reduce((a, b) => a + b, 0);
  };

  const FindFixtures = async (DATA, FILTERS) => {
    //
    const CleanData = await PrepStrapiData(DATA);
    
  

    const GroupedFixtures = groupBy(
      orderBy(CleanData, (item) => item.unixTime, ["asc"]),
      (o) => {
        return o.region;  
      }
    );

    console.log(GroupedFixtures)
    const OrderedByLeague = Object.keys(GroupedFixtures)
    .sort(function(a, b){
      return GroupedFixtures[b].unixTime - GroupedFixtures[a].unixTime;
  }).reduce(
      (accumulator, key) => {
        accumulator[key] = GroupedFixtures[key];
        return accumulator;
      },
      {}
    );
    console.log(OrderedByLeague)

    const DATAOBJ = { Fixtures: OrderedByLeague };

    Fixtures.VideoSettings.LeagueDurations = FindLeagueDurations(DATAOBJ);
    Fixtures.VideoSettings.LeagueStartFrom = StartFrom();
    Fixtures.VideoSettings.EndCreditsDurationStartAt = EndCreditsDurationStart();
    Fixtures.VideoSettings.VideoBodyTiming = FindVideoBodyTiming();
    Fixtures.VideoSettings.VideoDurationinFrames = VideoDuration();

    console.log({ ...Fixtures, ...DATAOBJ })
    setFixtures({ ...Fixtures, ...DATAOBJ });
  };

  return [Fixtures, FindFixtures];
};
