import { useEffect, useState } from "react";
import { useAuthNavigation } from "../../../Hooks/Auth/useAuthNavigation";
import {
  BodyContainer,
  VideoWizardContainer,
} from "../../../Theme/Structure/BodyContainer";
import { Loader, Space } from "@mantine/core";
// Product Components
import { LineChart } from "../../../Components/Charts/Line";
import { BarChart } from "../../../Components/Charts/Bar";

import { P } from "../../../Theme/type";
import moment from "moment";
import { filter, findIndex, orderBy } from "lodash";
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
export const ProductCharts = ({ NavPosition }) => {
  const [Navigation, FetchNavigation] = useAuthNavigation();
  const [NavItem, setNavItem] = useState(false);

  useEffect(() => {
    FetchNavigation();
  }, []);
  useEffect(() => {
    if (Navigation != false) {
      setNavItem(
        filter(Navigation, (o) => {
          return o.attributes.Name === NavPosition;
        })[0].attributes
      );
    }
  }, [Navigation]);

  if (!NavItem) return <Loader color="teal" size="sm" variant="bars" />;
  return (
    <>
      <BodyContainer>
        <BLOCK_Section_Title
          OBJ={{
            LABEL: `${NavPosition} Insights`,
            ICON: "ChartDots",
            DESCRIPTION: ` This section is to help give an insight into what assets are being
                    used by other franchise owners, when then are being used and how
                    often. It can be used to help guide your weekly, monthly and seasonal
                    posts.`,
            TOOLBAR: false,
          }}
        />
        <Space h="xl" />
        <PopularityLine NavItem={NavItem} />
        <Space h="xl" />
        <Space h="xl" />
        <WeekdayBar NavItem={NavItem} />
      </BodyContainer>
    </>
  );
};

const CreateSinglePointARR = (POINT) => {
  const OBJ = [];
  POINT.map((month, i) => {
    OBJ.push({ x: month, y: 0 });
  });
  return OBJ;
};

const PopularityLine = ({ NavItem }) => {
  //console.log(NavItem);

  const RenderPopularity = (NavItem) => {
    //console.log(NavItem)
    const OBJ = [];
    var monthNames = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    };
    const MONTHS = [];

    // Find all of the months in the DATA
    NavItem.assets.data.map((asset, i) => {
      //console.log(orderBy(asset.attributes.orders.data,['attributes.createdAt'],['asc']))
      asset.attributes.orders.data.map((order, ii) => {
        if (
          MONTHS.indexOf(moment(order.attributes.createdAt).format("MMMM")) ===
          -1
        ) {
          MONTHS.push(moment(order.attributes.createdAt).format("MMMM"));
        }
      });
    });

    MONTHS.sort(function (a, b) {
      return monthNames[a] - monthNames[b];
    });

   /*  NavItem.assets.data.map((asset, i) => {
      const NAMEINDEX = findIndex(OBJ, (o) => {
        return o.id === asset.attributes.Name;
      });
      if (NAMEINDEX === -1) {
        OBJ.push({
          id: asset.attributes.Name,
          data: CreateSinglePointARR(MONTHS),
        });
      }
    }); */

    OBJ.push({
      id: 'Total Downloads',
      data: CreateSinglePointARR(MONTHS),
    });

    // Fill that OBJ
    NavItem.assets.data.map((asset, i) => {
      asset.attributes.orders.data.map((order, ii) => {
        // now the rest
        const NAMEINDEX = findIndex(OBJ, (o) => {
          return o.id === 'Total Downloads';
        });
        const MONTHINDEX = findIndex(OBJ[NAMEINDEX].data, (o) => {
          return o.x === moment(order.attributes.createdAt).format("MMMM");
        });
        OBJ[NAMEINDEX].data[MONTHINDEX].y =
          OBJ[NAMEINDEX].data[MONTHINDEX].y + 1;
      });
    });
    //console.log(OBJ)
    return OBJ;
  };
  return (
    <>
      <BLOCK_Section_SubHeading LABEL="Asset Popularity" Color={5} />
      <P> Monthly Download Trends.</P>
      <VideoWizardContainer>
        <div style={{ height: "300px" }}>
          <LineChart DATA={RenderPopularity(NavItem)} />
        </div>
      </VideoWizardContainer>
    </>
  );
};

const WeekdayBar = ({ NavItem }) => {
  const CreateRow = (DAY, ASSETS) => {
    const OBJ = { DAY: DAY };
    ASSETS.map((ASSET, i) => {
      OBJ[ASSET] = 0;
    });
    return OBJ;
  };

  const RenderPopularity = (NavItem) => {
    const OBJ = [];
    const ASSETS = [];
    const DAYS = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    NavItem.assets.data.map((asset, i) => {
      if (ASSETS.indexOf(asset.attributes.Name) === -1) {
        ASSETS.push(asset.attributes.Name);
      }
    });

    DAYS.map((DAY, i) => {
      OBJ.push(CreateRow(DAY, ASSETS));
    });

    //console.log(OBJ)
    // Fill that OBJ
    NavItem.assets.data.map((asset, i) => {
      asset.attributes.orders.data.map((order, ii) => {
        const INDEX = findIndex(OBJ, (o) => {
          return o.DAY === moment(order.attributes.createdAt).format("dddd");
        });

        OBJ[INDEX][asset.attributes.Name]++;
      });
    });

    return [OBJ, ASSETS];
  };
  return (
    <>
      <BLOCK_Section_SubHeading LABEL="Weekday Downloads" Color={5} />
      <P>
        Download trend throughout the week. Gain insight into when the best time
        to Create and Post an Asset Type.
      </P>
      <VideoWizardContainer>
        <div style={{ height: "300px" }}>
          <BarChart
            DATA={RenderPopularity(NavItem)}
            Settings={{
              indexBy: "DAY",
            }}
          />
        </div>
      </VideoWizardContainer>
    </>
  );
};
