import { useSelector } from "react-redux";
// Theme
import { H2, H3, H4_UPPERCASE, P } from "../../../../Theme/type";
import { VideoWizardContainer } from "../../../../Theme/Structure/BodyContainer";
import { Grid, Group, Space } from "@mantine/core";
import { hasData } from "../../../../actions/video";
import moment from "moment";
export const DataCheck = () => {
  const RENDER = useSelector((state) => state.RENDER);
  console.log(RENDER);
  //console.log(VIDEODATA)
  const ConvertData = (FromDate) => {
    const d = new Date(`${FromDate}`);
    return moment(d).format("DD MMMM");
  };
  const ConvertTime = (FromDate) => {
    const TIME = FromDate.split(":");
    return `${TIME[0]}:${TIME[1]}`;
  };

  if (!hasData(RENDER.DATAOBJ))
    return (
      <VideoWizardContainer>
        <H3 colorID={6}>WARNING!</H3>
        <P>Insufficient Team/Player Data to properly process this video.</P>
        <P>
          This may be due to not enough in game data for a team selected in this
          match.
        </P>
        <P>
          We suggst swtiching to the Game of the Day LITE version as this
          version does not require player data.
        </P>
      </VideoWizardContainer>
    );
  return (
    <>
      <VideoWizardContainer>
        <H2>
          {RENDER.DATAOBJ.DATAOBJ.TeamA.Name} vs{" "}
          {RENDER.DATAOBJ.DATAOBJ.TeamB.Name}
        </H2>
        <P>
          {ConvertData(RENDER.DATAOBJ.DATAOBJ.Date)} -{" "}
          {ConvertTime(RENDER.DATAOBJ.DATAOBJ.Time)}
        </P>
        <P>
          {RENDER.DATAOBJ.DATAOBJ.ground} - {RENDER.DATAOBJ.DATAOBJ.umpire}
        </P>
      </VideoWizardContainer>
      <TeamDetails TEAM={RENDER.DATAOBJ.DATAOBJ.TeamA} />
      <TeamDetails TEAM={RENDER.DATAOBJ.DATAOBJ.TeamB} />
    </>
  );
};


const TeamDetails = ({ TEAM }) => {
  return (
    <VideoWizardContainer>
      <H3>{TEAM.Name}</H3>
      <H4_UPPERCASE>Rank</H4_UPPERCASE>
      <Group>
        <P>City : {TEAM.City_Rank}</P>
        <P>National :{TEAM.National_Rank}</P>
        <P>World : {TEAM.World_Rank}</P>
      </Group>
      <Space h="md" />
      <H4_UPPERCASE>Win Ratio ({TEAM.Win_Ratio})</H4_UPPERCASE>
      <Group>
        <P>Won : {TEAM.Won}</P>
        <P>Lost :{TEAM.Lost}</P>
      </Group>
      <Space h="md" />

      <H4_UPPERCASE colorID={5}>Players to Watch </H4_UPPERCASE>
      <Grid>
        <Grid.Col span={12}>
          <H2>Batsman</H2>
        </Grid.Col>
        <Grid.Col span={6}>
          <H3 colorID={5}> Highest Scorer</H3>
          <P>
            {TEAM.batsmanToWatch.Runs[0].Name} <br />
            RUNS : {TEAM.batsmanToWatch.Runs[0].RUNS} Strike Rate :
            {TEAM.batsmanToWatch.Runs[0].SR.toFixed(2)}
          </P>
        </Grid.Col>
        <Grid.Col span={6}>
          <H3 colorID={5}>Best Strike Rate </H3>{" "}
          <P>
            {TEAM.batsmanToWatch.SR[0].Name}
            <br /> RUNS : {TEAM.batsmanToWatch.SR[0].RUNS} Strike Rate :
            {TEAM.batsmanToWatch.SR[0].SR.toFixed(2)}
          </P>
        </Grid.Col>
        <Grid.Col span={12}>
          <H2>Bowlers</H2>
        </Grid.Col>
        <Grid.Col span={6}>
          <H3 colorID={5}>Most Wickets </H3>{" "}
          <P>
            {TEAM.bowlersToWatch.WKTS[0].Name} <br />
            WKTS : {TEAM.bowlersToWatch.WKTS[0].WKTS} ECO :
            {TEAM.bowlersToWatch.WKTS[0].ECO.toFixed(2)}
          </P>
        </Grid.Col>
        <Grid.Col span={6}>
          <H3 colorID={5}>Best Economy</H3>{" "}
          <P>
            {" "}
            {TEAM.bowlersToWatch.ECO[0].Name}
            <br /> WKTS : {TEAM.bowlersToWatch.ECO[0].WKTS} ECO :
            {TEAM.bowlersToWatch.ECO[0].ECO.toFixed(2)}
          </P>
        </Grid.Col>
      </Grid>
      <Space h="md" />
    </VideoWizardContainer>
  );
};
