import {useCurrentFrame } from 'remotion';
import { H1 } from '../utils/Text';
import { useTheme } from 'styled-components';
import {SpringToFrom} from '../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../Animation/interpolate';

// <LargeTitleTranslateIn POTDSTRING={} Durations={}/>
export const LargeTitleTranslateIn = ({POTDSTRING, Durations})=>{
    const theme = useTheme();
    const frame = useCurrentFrame();
    return(
        <H1
            style={{
                color:theme.Base[2],
                width:'100%',
                fontWeight:900,
                fontSize:'10rem',
                lineHeight:'11rem',
                textAlign:'center',
                transform: `translateX(${SpringToFrom(7,180,0)}px) translateY(${SpringToFrom((Durations.Close-Durations.Offset),0,400,'fast')}px)`,
            }}
            >
            {
                        POTDSTRING.split('').map((D,i)=>{
                            return(
                                    <span
                                        key={i}
                                        style={{
                                            margin:'0 -2px',
                                            opacity:interpolateOpacityByFrame(frame, (15+(1*(i+1))),(16+(1*(i+1))), 0, 1),
                                        
                                        }}>
                                        {D}</span>
                                )
                        })
                    }</H1>
    )
}