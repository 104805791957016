import { useEffect, useState } from "react";
import { Space, Progress } from "@mantine/core";
export const UpdateProgress = ({ Franchise, hasClicked }) => {
  const [Status, setStatus] = useState({ Label: "Starting", INT: 0 });
  const [stages, setStages] = useState(9);

  const BarColors = ["grape","teal","yellow","cyan","orange","pink","violet","gray","green","grape"];

  useEffect(() => {
    if (Franchise !== false) {
      if (Franchise.length !== 0) {
        setStatus(Franchise[Franchise.length - 1].attributes.UPDATEFORUI);
      }
    }
  }, [Franchise]);

  if (!hasClicked) return false;
  return (
    <>
      <Space h="md" />
      <Progress
        color={BarColors[Status.INT]}
        radius="xs"
        label={Status.Label}
        size="xl"
        value={(Status.INT / stages) * 100}
        striped
        animate
      />
    </>
  );
};
