import { useMediaQuery } from "@mantine/hooks";
import { Player } from "@remotion/player";
import { useSelector } from "react-redux";
import { PreviewVideoRenderWrapper } from "../../Components/interface/Wrapper/PreviewVideoRenderWrapper";
// Video Compositions

export const VIDEOPREVIEW = () => {
  const RENDER = useSelector((state) => state.RENDER);
  //console.log(RENDER.ASSET.Asset.video_components);
  const H=RENDER.ASSET.Asset.video_components.data[0].attributes.ratio.data.attributes.Height
  const W=RENDER.ASSET.Asset.video_components.data[0].attributes.ratio.data.attributes.Width
  //RENDER.ASSET.Asset.video_components.data[0].attributes.ratio.data.attributes.Height

  
  const largeScreen = useMediaQuery('(min-width: 900px)');

//PreviewVideoRenderWrapper
  return (
    <PreviewVideoRenderWrapper> 
    <Player
      id={RENDER.ASSET.Asset.ComponentName}
      component={RENDER.PREVIEWCOMPONENT}
      durationInFrames={RENDER.DATAOBJ.VideoSettings.VideoDurationinFrames}
      fps={30}
      numberOfSharedAudioTags={0}
      compositionWidth={parseInt(W)}
      compositionHeight={parseInt(H)}
      inputProps={{
        RENDER: RENDER,
      }} 
      style={{
        width: parseInt(W) * (largeScreen ? 0.35: 0.3),
        height: parseInt(H) * (largeScreen ? 0.35: 0.3),
      }}
      controls 
    />
    </PreviewVideoRenderWrapper> 
  );
}; 
