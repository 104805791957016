import { Series } from "remotion";
import { H1 } from "../../../../../utils/Text";
import { ScoreCardWrapper } from "./AnimateIn";
import { useTheme } from "styled-components";

export const FIXTURELIST = ({ DATA, Dimensions }) => {
  const theme = useTheme();
  

  const Durations = {
    Length: 270,
    Begin: 0, // bring in overlay
    TransitionAfter: 30, // slide overlay down
    RemoveScorcard: 180, // slide overlay down
    ItemsperPage:4
  };

  if (Dimensions.length === 0) return false;
  return (
    <Series>
      {Object.keys(DATA.Fixtures).map((Label, i) => {
          //console.log("Number of fixtures in the division ", DATA.Fixtures[Label].length, Math.ceil(DATA.Fixtures[Label].length / 4), Durations.Length )
         console.log(DATA.Fixtures[Label][0].region)
        return (
          <Series.Sequence
            key={i}
            durationInFrames={
              Math.ceil(DATA.Fixtures[Label].length / 4) * Durations.Length
            }
            layout="none"
          > 
            <H1
              style={{
                position: "fixed",
                top: "10px",
                fontSize: "35px",
                color: theme.Copy[0],
              }}
            >
              {DATA.Fixtures[Label][0].region}
            </H1> 
            <ScoreCardWrapper DATA={DATA} LABEL={Label} Durations={Durations} />
          </Series.Sequence>
        );
      })}
    </Series>
  );
};
