import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { orderBy } from "lodash";
const VideoOBJ = {
  Duration: 150,
  VideoBodyTiming: null,
  VideoDurationinFrames: null,
  RenderStill: [],
};

const SETTINGSFORTEST = {
  ECO: 3,
  MinWICKETS: 2,
  ThresholdWICKETS: 3,
};
export const useFindPOTDBOWLING = () => {
  const VideoSettings = { ...VideoOBJ };
  const [POTDBOWLING, setPOTDBOWLING] = useState({ VideoSettings });
  const AUTH = useSelector((state) => state.AUTH);
  const SETTINGS =
    AUTH.AUTHUSER.user_video_setting?.Settings.POTD.BOWLING !== undefined
      ? AUTH.AUTHUSER.user_video_setting?.Settings.POTD.BOWLING
      : SETTINGSFORTEST;
  // UTILS
  const isWKTS = (SCORE) => {
    return parseInt(SCORE, 10) > 1 ? true : false;
  };

  const hasECO = (SR) => {
    return SR.BOWLING_Wkts >= SETTINGS.ThresholdWICKETS
      ? true
      : SR.BOWLING_Econ <= SETTINGS.ECO &&
        SR.BOWLING_Wkts >= SETTINGS.MinWICKETS
      ? true
      : false;
  };

  const POTDDuration = (INT) => {
    return INT * POTDBOWLING.VideoSettings.Duration;
  };
  const honorableMentionaDuration = () => {
    return 300;
  };
  const VideoDurationinFrames = (INT) => {
    return POTDDuration(INT[0]) + honorableMentionaDuration() + 90 + 120 + 120;
  };
  /* const VideoEndCreditsDurationStartAt = (INT)=>{
       return ((POTDDuration(INT[0])+honorableMentionaDuration(INT[1]))+( POTDBOWLING.VideoSettings.OpeningSceneDuration))
    }  */
  const FindVideoBodyTiming = (INT) => {
    return POTDDuration(INT[0]) + honorableMentionaDuration();
  };
  const FindRenderStill = (LEN) => {
    let ARR = [40, 190];
    const Start = 300;
    let i = 0;
    while (i < LEN) {
      //console.log(Start+(i*120))
      ARR.push(Start + i * 120); 
      i++;
    }
    return ARR;
  };
  // Main Function
  const FindPOTDBOWLING = async (DATA) => {
    //
    //console.log(DATA)
    const ARR = [],
      HONERABLE = [],
      SHOWCASE = [];
    // Find POTDBOWLING

    DATA.map((game, i) => {  
      //console.log(game)
      const DEFAULTIMG = 'https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/playeroption4_de5eede10d.png'
      const DEFAULTLOGO = 'https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/default_Large_49110969a3.png'
      game.attributes.player_bowlings.data.map((player, i) => {
        let P = player.attributes;
        //console.log(P.player.data?.attributes.image.data?.attributes.url)

        if (isWKTS(P.BOWLING_Wkts))
          ARR.push({
            BOWLING_Econ: P.BOWLING_Econ,
            BOWLING_Maidens: P.BOWLING_Maidens,
            BOWLING_Overs: P.BOWLING_Overs,
            BOWLING_Runs: P.BOWLING_Runs,
            BOWLING_Wkts: P.BOWLING_Wkts,
            Name: P.player.data.attributes.Name,
            WORLDRANKINGBOWLING: P.player.data?.attributes.WORLDRANKINGBOWLING,
            WICKETS: P.player.data?.attributes.WICKETS,
            ECONOMY: P.player.data?.attributes.ECONOMY,
            IMAGE: P.player.data?.attributes.image.data?.attributes.url === undefined ? DEFAULTIMG:P.player.data?.attributes.image.data?.attributes.url, 
            Team: P.team?.data?.attributes.Name,
            Logo: P.team.data?.attributes.Logo.data?.attributes.url === undefined ? DEFAULTLOGO:P.team.data?.attributes.Logo.data?.attributes.url,
            ID: P.player.data.id, 
          }); 
      });
    });

    // HasLargerImage
    ARR.map((Player, i) => {
      hasECO(Player) ? SHOWCASE.push(Player) : HONERABLE.push(Player);
    });

    const DATAOBJ = {
      SHOWCASE: orderBy(
        SHOWCASE,
        [(obj) => parseInt(obj.BOWLING_Wkts, 10), "BOWLING_Econ"],
        ["desc", "asc"]
      ),
      HONERABLE: orderBy(
        HONERABLE.slice(0, 18),
        (obj) => parseInt(obj.BOWLING_Wkts, 10),
        ["desc"]
      ),
    };

    await CalculateDurations(DATAOBJ);

    setPOTDBOWLING({ ...POTDBOWLING, ...DATAOBJ });
  };

  const CalculateDurations = (DATAOBJ) => {
    //console.log(POTDBOWLING)
    POTDBOWLING.VideoSettings.POTDDuration = POTDDuration(
      DATAOBJ.SHOWCASE.length
    );
    POTDBOWLING.VideoSettings.HONERABLEDuration = honorableMentionaDuration(
      DATAOBJ.SHOWCASE.length
    );

    //POTDBOWLING.VideoSettings.EndCreditsDurationStartAt = VideoEndCreditsDurationStartAt([DATAOBJ.SHOWCASE.length,DATAOBJ.HONERABLE.length])
    POTDBOWLING.VideoSettings.VideoDurationinFrames = VideoDurationinFrames([
      DATAOBJ.SHOWCASE.length,
      DATAOBJ.HONERABLE.length,
    ]);
    POTDBOWLING.VideoSettings.VideoBodyTiming = FindVideoBodyTiming([
      DATAOBJ.SHOWCASE.length,
      DATAOBJ.HONERABLE.length,
    ]);
    POTDBOWLING.VideoSettings.RenderStill = FindRenderStill(
      DATAOBJ.SHOWCASE.length,
      VideoSettings
    );
  };

  useEffect(() => {}, [POTDBOWLING]);

  return [POTDBOWLING, FindPOTDBOWLING];
};
