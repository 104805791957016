import { DatePicker,DateRangePicker } from '@mantine/dates';
import moment from 'moment';

export const VideoDatePicker = ({setFromDate})=>{
    const SelectedDate=(e)=>{  setFromDate(moment(e).format('YYYY-MM-DD'))}
    return(<DatePicker placeholder="Pick date" label="Event date" required  onChange={SelectedDate}/>)
} 

export const DataDateRangePicker = ({setDateRange})=>{ 
    const SelectedDate=(e)=>{  
        //console.log(e, isNaN(e[1]))
        if(e[0] === null || e[1] === null)
            return false
                setDateRange([moment(e[0]).format('YYYY-MM-DD'),moment(e[1]).format('YYYY-MM-DD')])
    }
    return(<DateRangePicker placeholder="Pick date" label=""  onChange={SelectedDate}/>)
} 