import { useSelector } from "react-redux";

// Theme
import { VideoWizardContainer } from "../../../../Theme/Structure/BodyContainer";
import { BTN_ICON_LINK } from "../../../../Theme/Structure/Button/BTNS";
import { H3 } from "../../../../Theme/type";
import {
  TABLE_TD,
  TABLE_THEAD_WRAPPER,
  TABLE_WRAPPER,
} from "../../../../Components/interface/Wrapper/Table";
import { Avatar } from "@mantine/core";

export const DataCheck = () => {
  const RENDER = useSelector((state) => state.RENDER);
  console.log(RENDER);
  return false
  return (
    <>
    <H3>Fixture Data</H3>
      {Object.keys(RENDER.DATAOBJ.Fixtures).map((Fixture, i) => {
        return (
          <VideoWizardContainer key={i}>
            <H3 colorID={5}>
              {Fixture} ({RENDER.DATAOBJ.Fixtures[Fixture].length} Fixtures)
            </H3>

            <TABLE_WRAPPER>
              <TABLE_THEAD_WRAPPER
                DISPLAY={["None", "table-header-group"]}
                HEADERS={["Home", "Away", "Score Card"]} 
              />
              <TABLE_THEAD_WRAPPER
                DISPLAY={["table-header-group", "None"]}
                HEADERS={["Home","", "", "", "", "Away","Results", "POM","Score Card"]}
              />

              <tbody>
                {RENDER.DATAOBJ.Fixtures[Fixture].map((item, ii) => {
                  //console.log(item.fixtureID)
                  return (
                    <tr key={ii}>
                      
                      <TABLE_TD>{item.homeTeam} </TABLE_TD>
                      <TABLE_TD HIDE={true}><Avatar src={item.homeTeamLogo} /> </TABLE_TD>
                      
                      <TABLE_TD HIDE={true}>{item.homeTeamResult}</TABLE_TD>
                      <TABLE_TD HIDE={true}>{item.awayTeamResult} </TABLE_TD>
                      <TABLE_TD HIDE={true}><Avatar src={item.awayTeamLogo} /> </TABLE_TD>
                      <TABLE_TD>{item.awayTeam} </TABLE_TD>
                      
                      <TABLE_TD HIDE={true}>{item.resultStatement}</TABLE_TD>
                      <TABLE_TD HIDE={true}>{item.MOM.Name}</TABLE_TD>
                      
                      <TABLE_TD>
                        <BTN_ICON_LINK
                          TIPOBJ={{
                            LABEL: "Scorecard",
                            COLOR: "gray",
                            POSITION: "top",
                          }}
                          TO={`https://www.lastmanstands.com/leagues/scorecard/match-info?fixtureid=${item.fixtureID}`}
                          ICON="ExternalLink"
                        />
                      </TABLE_TD>
                    </tr>
                  );
                })}
              </tbody>
            </TABLE_WRAPPER>
          </VideoWizardContainer>
        );
      })}
    </>
  );
};
