import { useEffect, useState } from "react"
import { ColoredContainer } from "../../../Theme/Structure/BodyContainer"
import {useFetchSelectedTournament} from '../../../Hooks/Content/useTournaments'
import { H3 } from "../../../Theme/type" 
import { UISkeletonLines } from "../../../Components/Global/Skeleton"
import { Group, Loader, Space } from "@mantine/core"
export const IsTournamentUpdating = ({SelectedID,isUpdating, setisUpdating})=>{
  
    return isUpdating ? 
            <IsUpdatingComponent SelectedID={SelectedID} setisUpdating={setisUpdating}/>
                :false
}

 



const IsUpdatingComponent = ({SelectedID,setisUpdating})=>{
    const [TournamentData,FetchSelectedTournament] = useFetchSelectedTournament()
        
     useEffect(() => {
        const interval = setInterval(() => {
            //console.log('Run Interval whilst updating')
            //console.log('SelectedID ',SelectedID)
            FetchSelectedTournament(SelectedID)
         }, 5000)

         return () => clearInterval(interval);
      }, []);


      useEffect(() => {
        if(TournamentData){
            if(!TournamentData.attributes.franchise.data.attributes.isUpdating)
            {
                setisUpdating(false)
            }
        }
        
        }, [TournamentData]);


    return(
        <>
        <Space h='md' />
            <ColoredContainer Padding='xs' BGColor={3}>
                <Group position="apart">
                <H3>Please wait whilst we Gathering Tournament Info  </H3>
                <Loader  color="teal" variant="bars" size="xs"/>
                </Group>
            </ColoredContainer>
        </>)
}




