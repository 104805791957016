import { useEffect, useState } from "react"
import { orderBy,find } from "lodash";


const OffsetTime = 90
const VideoOBJ={
    OpeningSceneDuration:90,
    EndCreditsDuration:120,
    SponsorTiming:120,
    ItemOffset:OffsetTime,
    VideoBodyTiming:(OffsetTime*8),
    VideoDurationinFrames:(((90+120)+(OffsetTime*8)+120)), 
    RenderStill:[40,190,880,1000]
} 
 
export const useFindTeamOfTheWeek = ()=>{
    const VideoSettings = { ...VideoOBJ };
    const [TeamOfTheWeek ,setTeamOfTheWeek ] = useState({VideoSettings})
 
    const DEFAULTIMG = 'https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/playeroption4_de5eede10d.png'
      const DEFAULTLOGO = 'https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/default_Large_49110969a3.png'

    // UTILS
    const findTopAllrounder= async (DATA)=>{
        /*
           4. Best all rounder - How should we gauge this you reckon?
        */   
        const Bat=[],Bowl=[] 
            DATA.map((Game,i)=>{  
               
                Game.attributes.player_bowlings.data.map((RUNS,i)=>{ 
                    if(RUNS.attributes.BOWLING_Wkts > 0)
                        Bowl.push(RUNS.attributes)
                })
                Game.attributes.player_battings.data.map((RUNS,i)=>{
                    Bat.push(RUNS.attributes)
                })
            })


            //console.log(Bowl)
            //console.log(Bat)

            const BowlOrdered = orderBy(Bowl,['BOWLING_Wkts','BOWLING_Econ','BOWLING_Overs'],['desc','asc','desc']).slice(0,50)
            const BatOrdered = orderBy(Bat,(o)=>{return parseInt(o.BATTING_Runs)},['desc']).slice(0,50)
 
            const ALLROUNDERS=[]
            BowlOrdered.map((bowl,i)=>{
                
                let batruns = find(BatOrdered, (o)=>{return o.player.data.id === bowl.player.data.id})
                if(batruns != undefined)       
                 ALLROUNDERS.push({...batruns,...bowl})
                    
            })
            
         console.log(orderBy(ALLROUNDERS,['BATTING_SR','BATTING_Runs','BOWLING_Wkts'],['desc','desc','desc']).slice(0,1))
           return orderBy(ALLROUNDERS,['BATTING_SR','BATTING_Runs','BOWLING_Wkts'],['desc','desc','desc']).slice(0,1)

    }

    const findBowlersEco = async (DATA,TopBowlers, TopAllrounder)=>{
        /*
            Best economy bowler (more than 3 overs, differentiated by wickets if equal)
        */   
        const ARR=[],NotInArr=[TopAllrounder[0].player.data.id,TopBowlers[0].player.data.id,TopBowlers[1].player.data.id, ]
            DATA.map((Bowling,i)=>{
                Bowling.attributes.player_bowlings.data.map((RUNS,i)=>{
                    if(NotInArr.indexOf(RUNS.attributes.player.data?.id) === -1 && 
                        RUNS.attributes.BOWLING_Overs > 3)
                            ARR.push(RUNS.attributes)
                })
            })
            //console.log(orderBy(ARR,['BOWLING_Econ','BOWLING_Wkts','BOWLING_Overs'],['asc','desc','desc']).slice(0,10))
        return orderBy(ARR,['BOWLING_Econ','BOWLING_Wkts','BOWLING_Overs'],['asc','desc','desc']).slice(0,1)
    }

    const findTopBowlers = async (DATA, TopAllrounder)=>{
        /*
            7. Highest wicket taker (differentiated by figures if equal)
            8. 2nd highest wicket taker (differentiated by figures if equal)
        */   
        const ARR=[],NotInArr=[TopAllrounder[0].player.data.id]
            DATA.map((Bowling,i)=>{
                Bowling.attributes.player_bowlings.data.map((RUNS,i)=>{
                    if(NotInArr.indexOf(RUNS.attributes.player.data?.id) === -1)
                        ARR.push(RUNS.attributes)
                })
            })
        return orderBy(ARR,['BOWLING_Wkts','BOWLING_Econ','BOWLING_Overs'],['desc','asc','desc']).slice(0,10)
    }


    const findTopScorer = async (DATA, TopAllrounder,FastestFifty)=>{
        /*
            1. Highest runscorer
            2. 2nd highest runscorer
        */   
        const ARR=[],NotInArr=[TopAllrounder[0].player.data.id,FastestFifty[0].player.data.id]
            DATA.map((batting,i)=>{
                batting.attributes.player_battings.data.map((RUNS,i)=>{
                    if(NotInArr.indexOf(RUNS.attributes.player.data?.id) === -1)
                        ARR.push(RUNS.attributes)
                })
            })
        return orderBy(ARR,(o)=>{return parseInt(o.BATTING_Runs)},['desc']).slice(0,2)
    }


    const FindFastestFifty = async (DATA,TopAllrounder)=>{
        /*
             Fastest 50 (if two equal, then the higher score, otherwise joint position maybe?)
        */   
       //,TopRunScorers[0].player.data.id,TopRunScorers[1].player.data.id
       console.log(TopAllrounder)
        const ARR=[], NotInArr=[TopAllrounder[0].player.data.id]
      //console.log(NotInArr)  
        DATA.map((batting,i)=>{
                batting.attributes.player_battings.data.map((RUNS,i)=>{
                  
                    if(NotInArr.indexOf(RUNS.attributes.player.data?.id) === -1)
                        if(RUNS.attributes.BATTING_Runs > 50)
                            ARR.push(RUNS.attributes)
                }) 
            })
        return orderBy(ARR,['BATTING_SR','BATTING_sixes','BATTING_fours'],['desc','desc','desc']).slice(0,1)
    }

    const findKeeper = async (DATA,TopRunScorers,TopBowlers, TopAllrounder)=>{
        /*
           Best wicket keeper - Catches & Stumpings first, then runs as differentiator
        */   
        const STUMP=[], Catch=[],NotInArr=[TopAllrounder[0].player.data.id,TopBowlers[0].player.data.id,TopBowlers[1].player.data.id,TopRunScorers[0].player.data.id,TopRunScorers[1].player.data.id]
            DATA.map((Keeper,i)=>{
                
                Keeper.attributes.player_stumpings.data.map((RUNS,i)=>{
                    if(NotInArr.indexOf(RUNS.attributes.player.data?.id) === -1)
                    STUMP.push(RUNS.attributes)
                })
                Keeper.attributes.player_catches.data.map((RUNS,i)=>{
                    if(NotInArr.indexOf(RUNS.attributes.player.data?.id) === -1)
                    Catch.push(RUNS.attributes)
                })
            })
          
            //console.log(STUMP.length, STUMP, Catch.length, Catch)
            const KEEPER=[]

            STUMP.map((stumped,i)=>{
                
                let Catches = find(Catch, (o)=>{return o.player.data?.id === stumped.player.data.id})      
                    KEEPER.push({...stumped,...Catches})
                    
            })

          //console.log(KEEPER.length, KEEPER)
            if(KEEPER.length !== 0 )
                return orderBy(KEEPER,['PLAYERS_Stumpings','PLAYERS_Catches'],['desc','desc']).slice(0,1)
                    return orderBy(Catch,['PLAYERS_Catches'],['desc']).slice(0,1)            
    }


    const StripStrapiOBJ = (OBJ)=>{
        console.log(OBJ)
        if(OBJ === undefined){
            return {...OBJ}
        }else{
            const IMAGE=  OBJ.player.data?.attributes?.image?.data === null ? DEFAULTIMG:OBJ.player.data?.attributes.image.data?.attributes.url;
            const Name = OBJ.player.data?.attributes.Name 
            const ID=OBJ.player.data.id
            return {...OBJ,IMAGE,Name,ID}
        }
       
    }


    // Main Function
    const FindTeamOfTheWeek =async(DATA)=>{
       
        const TopAllrounder = await findTopAllrounder(DATA)
        const FastestFifty = await FindFastestFifty(DATA,TopAllrounder)
        const TopRunScorers =  await findTopScorer(DATA, TopAllrounder, FastestFifty)
        const TopBowlers = await findTopBowlers(DATA, TopAllrounder)
        const BowlerEco = await findBowlersEco(DATA, TopBowlers, TopAllrounder)
        const TopKeeper = await findKeeper(DATA,TopRunScorers,TopBowlers, TopAllrounder)
        
        const DATAOBJ={
                Bat1:StripStrapiOBJ(TopRunScorers[0]),
                Bat2:StripStrapiOBJ(TopRunScorers[1]),
                Bat3:StripStrapiOBJ(FastestFifty[0]),
                AR:StripStrapiOBJ(TopAllrounder[0]),
                Bowler1:StripStrapiOBJ(TopBowlers[0]),
                Bowler2:StripStrapiOBJ(TopBowlers[1]),
                Bowler3:StripStrapiOBJ(BowlerEco[0]),
                Keeper:StripStrapiOBJ(TopKeeper[0]) 
        } 
        //console.log({...TeamOfTheWeek,DATAOBJ:{...DATAOBJ}})
       setTeamOfTheWeek({...TeamOfTheWeek,DATAOBJ:{...DATAOBJ}}) 
       //setResults({...Results,...DATAOBJ})
    } 




    useEffect(()=>{
     //console.log(TeamOfTheWeek)
        
    },[TeamOfTheWeek.VideoSettings.VideoDurationinFrames])

    return[TeamOfTheWeek ,FindTeamOfTheWeek]
}