import { Group, Image, Stack } from "@mantine/core";
import { useEffect, useState } from "react";


// UI ELEMENTS
import { BLOCK_Section_SubHeading_INVERT } from "../../../Components/interface/BLOCK_Section_SubHeading";
import { H3 } from "../../../Theme/type";

export const DefaultProfileImageContainer = ({ SelectedPlayer }) => {
  const [ImageRatio, setRatio] = useState(150);

  useEffect(() => {
    //console.log(SelectedPlayer.attributes?.image?.data?.attributes?.url)
  }, [SelectedPlayer]);
  return (
    <>
      <Group position="center"> 
        <Image
          src={SelectedPlayer.attributes?.image?.data?.attributes?.url}
          placeholder
          width={ImageRatio}
          height={ImageRatio}
          radius={(ImageRatio/10)}
          fit={"cover"}
        />
        
        <Stack>
                <H3 lineheight={'.8em'} colorID={3}>{SelectedPlayer.attributes.Name}</H3>
                <H3 lineheight={'.8em'} colorID={3}>{`Games : ${SelectedPlayer.attributes.MATCHES}`}</H3>
                <H3 lineheight={'.8em'} colorID={3}>{`STATUS : ${SelectedPlayer.attributes.PLAYERPROFILELEVEL}`}</H3>
   
           </Stack>
      
      </Group>
    </>
  );
};