import { useCurrentFrame } from "remotion";
import { useTheme } from "styled-components";
// Components
import { H1, P } from "../../../../utils/Text";
import { Group } from "@mantine/core";
import { interpolateOpacityByFrame } from "../../../../../Animation/interpolate";
import { SpringToFrom } from "../../../../../Animation/RemotionSpring";

// Components
import {
  SECTIONLABEL,
  PlayerImage,
  StatItem,
  CONTENTBG,
  SeriesWrapper,
} from "./UIELEMENTS";

export const CareerStats = ({ DATA, Duration, IMG }) => {
  const theme = useTheme();
  const frame = useCurrentFrame();

  const STATS = [
    {
      Label: "MATCHES",
      Value: DATA.DATAOBJ.MATCHES,
    },
    {
      Label: "STATUS",
      Value: DATA.DATAOBJ.PLAYERPROFILELEVEL,
    },
  ];

  const STATITEMOBJ = {
    WIDTH: "48%",
    TOP: 0,
    END: 130,
  };

  console.log(IMG)

  return (
    <SeriesWrapper>
      <SECTIONLABEL LABEL={"LMS Career"} Duration={Duration} />
      <PlayerImage IMG={IMG} DATA={DATA} INT={1} />

      <CONTENTBG Duration={Duration}>
        <H1
          style={{
            fontSize: "70px",
            padding: "10px 0 0 0",
            width: "100%",
            color: theme.Copy[0],
            transform: `translateY(${SpringToFrom(
              15,
              -100,
              0,
              "Springy100"
            )}%)`,
            opacity: interpolateOpacityByFrame(frame, 15, 30, 0, 1),
          }}
        >
          {DATA.DATAOBJ.Name}
        </H1>
        <P
          style={{
            fontSize: "30px",
            padding: "10px 0",
            margin: "0",
            fontWeight: 600,
            width: "100%",
            textAlign: "center",
            color: theme.Copy[0],
            transform: `translateY(${SpringToFrom(15, 100, 0, "Springy100")}%)`,
            opacity: interpolateOpacityByFrame(frame, 15, 30, 0, 1),
          }}
        >
          {DATA.DATAOBJ.TYPEBATTING}
        </P>
        <P
          style={{
            fontSize: "30px",
            fontWeight: 600,
            padding: "10px 0",
            width: "100%",
            margin: "0",
            textAlign: "center",
            color: theme.Copy[0],
            transform: `translateY(${SpringToFrom(25, 100, 0, "Springy100")}%)`,
            opacity: interpolateOpacityByFrame(frame, 25, 40, 0, 1),
          }}
        >
          {DATA.DATAOBJ.TYPEBOWLING}
        </P>

        <Group position="center" style={{ width: "100%" }} grow>
          {STATS.map((stat, i) => {
            return (
              <StatItem
                key={i}
                I={i}
                stat={stat}
                WIDTH={"48%"}
                STATITEMOBJ={STATITEMOBJ}
              />
            );
          })}
        </Group>
      </CONTENTBG>
    </SeriesWrapper>
  );
};
