/* eslint-disable react/jsx-pascal-case */
import {
  Group,
  Image,
  createStyles,
  Card,
  Text,
  Badge,
  Center,
  Stack,
  Box,
} from "@mantine/core";
import moment from "moment";
import { TABLE_TD } from "../../../../Components/interface/Wrapper/Table";
import { H3 } from "../../../../Theme/type";

import { DownloadCTABTNS } from "./DownloadItemBTNS";
const useStyles = createStyles((theme) => ({
  card: {
    position: "relative",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  title: {
    fontWeight: 700,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1.2,
  },

  body: {
    padding: theme.spacing.xs,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
}));

export const DownloadItemCard = (props) => {
  const { classes } = useStyles();
  const { DOWNLOAD, FilterItems } = props;

  console.log(DOWNLOAD);

  const HeaderImage = (DOWNLOAD) => {
    const IMG =
      DOWNLOAD?.attributes.asset.data?.attributes.HeaderImage.data[0]
        .attributes;

    return IMG === undefined
      ? undefined
      : IMG?.formats?.small?.url !== undefined
      ? IMG.formats.small.url
      : IMG.url;
  };

  const DaysFrom = (FromDate) => {
    const ExpiresIn = 7;
    const ARR = FromDate.toString().split(",");
    const d = new Date(`${ARR[0]}`);
    var a = moment(d);
    var b = moment(new Date());
    return a.diff(b, "days") + ExpiresIn;
  };

  const BadgeColor = (DAYS) => {
    //DaysFrom(DOWNLOAD.attributes.Order_Date)
    if (DAYS === 7) return 7;
    if (DAYS < 3) return 6;
    if (DAYS < 7) return 5;
  };
  let isSponsorFilter =
    DOWNLOAD.attributes.Sponsor.id === FilterItems.Sponsor
      ? true
      : !FilterItems.Sponsor
      ? true
      : false;

  let isFilter =
    DOWNLOAD.attributes.AssetType === FilterItems.Type
      ? true
      : !FilterItems.Type
      ? true
      : false;

  if (!isFilter || !isSponsorFilter) return false;
  return (
    <Card withBorder radius="md" p={0} className={classes.card}>
      <Group
        noWrap
        spacing={0}
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <Image src={HeaderImage(DOWNLOAD)} height={200} width={140} />
        <Badge
          variant="filled"
          size="xs"
          sx={(theme) => ({
            backgroundColor:
              theme.colors.brand[
                BadgeColor(DaysFrom(DOWNLOAD.attributes.Order_Date))
              ],
            marginLeft: "3px",
            position: "absolute",
            top: "5px",
            right: "5px",
          })}
        >
          Expires : {DaysFrom(DOWNLOAD.attributes.Order_Date)} days
        </Badge>

        <div className={classes.body}>
          <H3 colorID={5} letterspacing={"-1px"} lineheight={"1.1em"}>
            {DOWNLOAD.attributes.Name}
          </H3>
          <Text
            transform="uppercase"
            color="dimmed"
            weight={700}
            size="xs"
            mt={10}
            mb={10}
          >
            {DOWNLOAD.attributes.DataSet}
          </Text>

          <DownloadCTABTNS {...props} />
        </div>
      </Group>
    </Card>
  );
};
// Created: {ConvertData(DOWNLOAD.attributes.createdAt)} for{" "}

export const DownloadTableRow = (props) => {
  const { classes } = useStyles();
  const { DOWNLOAD, FilterItems } = props;

  console.log(DOWNLOAD);


  const RemoveAssettype=(NAME,STR)=>{
    console.log(NAME,STR)
    if(NAME ===null)
      return NAME
        return NAME.replace(STR, '')
  }

  const HeaderImage = (DOWNLOAD) => {
    const IMG =
      DOWNLOAD?.attributes.asset.data?.attributes.HeaderImage.data[0]
        .attributes;

    return IMG === undefined
      ? undefined
      : IMG?.formats?.small?.url !== undefined
      ? IMG.formats.small.url
      : IMG.url;
  };

  const DaysFrom = (FromDate) => {
    const ExpiresIn = 7;
    const ARR = FromDate.toString().split(",");
    const d = new Date(`${ARR[0]}`);
    var a = moment(d);
    var b = moment(new Date());
    return a.diff(b, "days") + ExpiresIn;
  };

  const BadgeColor = (DAYS) => {
    //DaysFrom(DOWNLOAD.attributes.Order_Date)
    if (DAYS === 7) return 7;
    if (DAYS < 3) return 6;
    if (DAYS < 7) return 5;
  };
  let isSponsorFilter =
    DOWNLOAD.attributes.Sponsor.id === FilterItems.Sponsor
      ? true
      : !FilterItems.Sponsor
      ? true
      : false;

  let isFilter =
    DOWNLOAD.attributes.AssetType === FilterItems.Type
      ? true
      : !FilterItems.Type
      ? true
      : false;

  if (!isFilter || !isSponsorFilter) return false;
  return (
    <tr>
      <TABLE_TD>
        <Center>
          <Stack align="center" justify="flex-start">
            <Image
              src={HeaderImage(DOWNLOAD)}
              height={20 * 5}
              width={15 * 5}
              radius={10}
            />
            <Badge
              variant="filled"
              size="xs"
              sx={(theme) => ({
                backgroundColor:
                  theme.colors.brand[
                    BadgeColor(DaysFrom(DOWNLOAD.attributes.Order_Date))
                  ],
                marginTop: "0px",
              })}
            >
              Expires : {DaysFrom(DOWNLOAD.attributes.Order_Date)} days
            </Badge>
          </Stack>
        </Center>
      </TABLE_TD>

      <TABLE_TD>
      <Text
          transform="uppercase"
          color="dimmed"
          weight={700}
          size="xs"
          mt={10}
          mb={0}
        >
          {DOWNLOAD.attributes.asset.data.attributes.Name}
          
          
        </Text>
        <H3
          underline={true}
          colorID={5}
          size={20}
          transform={`uppercase`}
          letterspacing={"-1px"}
          lineheight={"1.1em"}
        >
           
         
          {RemoveAssettype(DOWNLOAD.attributes.Name,DOWNLOAD.attributes.AssetType)}
          
        </H3>
        <Text
          transform="uppercase"
          color="dimmed"
          weight={700}
          size="xs"
          mt={10}
          mb={0}
        >
          Dataset : {DOWNLOAD.attributes.DataSet}
        </Text>
        <Text
          transform="uppercase"
          color="dimmed"
          weight={700}
          size="xs"
          mt={0}
          mb={0}
        >
          Made for : {DOWNLOAD.attributes.Franchise}
        </Text>
      </TABLE_TD>
      <TABLE_TD>
        <DownloadCTABTNS {...props} />
      </TABLE_TD>
    </tr>
  );
};
