import { Highlight } from "@mantine/core";

export const HIGHLIGHTER = (props) => {
  const { WORDS } = props;
  return (
    <Highlight
      highlight={WORDS}
      highlightColor="teal"
      highlightStyles={(theme) => ({
        backgroundImage: theme.fn.linearGradient(
          45,
          theme.colors.brand[5],
          theme.colors.brand[7]
        ),
        fontWeight: 900,
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      })}
    >
      {props.children}
    </Highlight>
  );
};
