import { useCurrentFrame, Video, AbsoluteFill, Series } from "remotion";
import { useTheme } from "styled-components";
import styled from "styled-components";
import { H3 } from "../../../../../utils/Text";
import { Box, Group, Image, Stack } from "@mantine/core";

import { TrimPlayerName } from "../../../../../../../../actions/UX";
import { SpringToFrom } from "../../../../../../Animation/RemotionSpring";
import { interpolateOpacityByFrame } from "../../../../../../Animation/interpolate";
import {
  FromRightToLeft,
  FromMiddle,
  FromLeftToRight,
  EraseFromMiddle,
} from "../../../../../../Animation/ClipWipe";

//import LMS from "../../../../../Assets/defaultLarge.png";
import { LargeTitleTranslateIn } from "../../../../../GlobalComponents/LargeTitleTranslateIn";
import { POTDPlayerImage } from "../../../../../GlobalComponents/POTD/POTDPlayerImage";
import { TripleDropTransition } from "../../../../../GlobalComponents/TripleDropTransition";
import { BottomUnderlayForCopy } from "../../../../../GlobalComponents/BottomUnderlayForCopy";
import { SmallCopyTranslateIn } from "../../../../../GlobalComponents/SmallCopyTranslateIn";
import { TeamLogoLarge } from "../../../../../GlobalComponents/POTD/TeamLogoLarge";
import { TeamLogoSmall } from "../../../../../GlobalComponents/POTD/MainLogo";
import { AudioBaseTrack } from "../../../../../GlobalComponents/Audio/BaseTrack";
import { AnimatedStrokeAroundImage } from "../../../../../GlobalComponents/AnimatedStrokeAroundImage";

export const InterviewWrapper = ({ DATA, OBJ }) => {
  return (
    <>
      <TripleDropTransition
        Dimensions={OBJ.Dimensions}
        Durations={{
          Close: OBJ.FPS,
          Offset: 30,
        }}
      />
      <InterViewOverlays DATA={DATA} TEAM={OBJ.TEAM} DURATION={OBJ.FPS} />
      <CREATEINTERVIEW
        INTERVIEW={DATA.OrderMetadata.INTERVIEWS[OBJ.TEAM].url}
      />
    </>
  );
};

const INTFADE = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
  height:100%;
  background-color:rgb(8, 75, 58);
`;

const CREATEINTERVIEW = ({ INTERVIEW }) => {
  const frame = useCurrentFrame();
  return (
    <AbsoluteFill>
        <INTFADE
        style={{
          opacity: interpolateOpacityByFrame(
            frame,
            0,
            7,
            1,
            0
          ),
        }} />
      <Video src={INTERVIEW} />
    </AbsoluteFill>
  );
};

const InterViewOverlays = ({ DATA, TEAM, DURATION }) => {
  //console.log(DATA,TEAM)
  return (
    <>
      <ScoresAndResults DATA={DATA} DURATION={DURATION} />
      <PlayersScores DATA={DATA.DATAOBJ[TEAM]} DURATION={DURATION} />
      <PlayerNameAndLogo
        NAME={DATA.OrderMetadata.INTERVIEWS[TEAM].Name}
        TEAMNAME={DATA.OrderMetadata.INTERVIEWS[TEAM].Team}
        LOGO={DATA.DATAOBJ[TEAM].LOGO}
        DURATION={DURATION}
      />
    </>
  );
};

const PlayerNameLogoWrapper = styled.div`
  position: absolute;
  bottom: 110px;
  z-index: 100;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 0.5em;
  border-radius: 10px;
`;
const InnerLabel = styled.div`
  display: flex;
  background: linear-gradient(180deg, #277f68 0%, #ffffff 100%);
  border-radius: 10px;
`;
const ImageWrapper = styled.div`
  padding: 1em;
  background-color: white;
  border-radius: 10px;
`;

const PlayerNameAndLogo = ({ NAME, LOGO, DURATION, TEAMNAME }) => {
  const theme = useTheme();
  const frame = useCurrentFrame();
  //console.log('TEAMNAME', TEAMNAME)
  return (
    <PlayerNameLogoWrapper
      style={{
        transform: `translateY(${SpringToFrom(190, 0, 500, "Springy100")}px)`,
      }}
    >
      <InnerLabel
        style={{
          clipPath: FromRightToLeft(15, "fast"),
        }}
      >
        <ImageWrapper>
          <Image
            src={LOGO}
            width={130}
            height={130}
            style={{
              clipPath: FromMiddle(30, "Springy100"),
            }}
          />
        </ImageWrapper>
        <Stack
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            alignContent: "center",
            minWidth: "800px",
          }}
        >
          <H3
            style={{
              fontSize: "3.5em",
              fontWeight: 400,
              lineHeight: "1em",
              color: theme.Base[2],
              padding: "0em 0.3em",
              textAlign: "left",
              letterSpacing: "0px",
              width: "100%",
              clipPath: FromRightToLeft(30, "Springy100"),
            }}
          >
            {NAME}
          </H3>
          <H3
            style={{
              fontSize: "2em",
              fontWeight: 400,
              lineHeight: "1em",
              color: theme.Base[4],
              padding: "0.3em 0.5em 0 ",
              textAlign: "left",
              letterSpacing: "0px",
              width: "100%",
              borderTop: "1px solid white",
              clipPath: FromRightToLeft(30, "Springy100"),
            }}
          >
            {TEAMNAME}
          </H3>
        </Stack>
      </InnerLabel>
    </PlayerNameLogoWrapper>
  );
};

const ScoresWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: -1px;
  top: 0px;
  padding: 0.2em 1em;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  background: linear-gradient(
    90deg,
    rgba(225, 225, 225, 0.8) 30%,
    rgba(34, 124, 101, 1) 30%
  );
`;

const ScoresAndResults = ({ DATA, DURATION }) => {
  const InformationHold = 210;
  const Rounds = Math.round(DURATION / InformationHold);
  //console.log(DURATION, (Math.round(DURATION/InformationHold)))
  const theme = useTheme();
  return (
    <ScoresWrapper>
      <H3
        style={{
          color: theme.Base[1],
          textAlign: "left",
          fontSize: "1.8em",
          letterSpacing: "0px",
          fontWeight: 400,
          clipPath: FromRightToLeft(0, "Springy100"),
          lineHeight: "2.2em",
        }}
      >
        MATCH RESULT:{" "}
      </H3>
      <Box>
        <Series>
          {[...Array(Rounds)].map((item, i) => {
            return (
              <>
                <Series.Sequence
                  durationInFrames={InformationHold}
                  layout="none"
                >
                  <ScoreTitleWrapper
                    VALUE={`${DATA.DATAOBJ.HomeTeam} - ${DATA.DATAOBJ.HomeTeamResult}`}
                  />
                </Series.Sequence>

                <Series.Sequence
                  durationInFrames={InformationHold}
                  layout="none"
                >
                  <ScoreTitleWrapper
                    VALUE={`${DATA.DATAOBJ.AwayTeam} - ${DATA.DATAOBJ.AwayTeamResult}`}
                  />
                </Series.Sequence>
                <Series.Sequence
                  durationInFrames={InformationHold}
                  layout="none"
                >
                  <ScoreTitleWrapper
                    VALUE={`${DATA.DATAOBJ.ResultStatement}`}
                  />
                </Series.Sequence>
              </>
            );
          })}
        </Series>
      </Box>
    </ScoresWrapper>
  );
};

const ScoreTitleWrapper = ({ VALUE }) => {
  return (
    <H3
      style={{
        color: "white",
        textAlign: "left",
        fontSize: "2.2em",
        clipPath: FromLeftToRight(15, "Springy100"),
        lineHeight: "1.1em",
      }}
    >
      {VALUE}{" "}
    </H3>
  );
};

const PlayerPerformances = styled.div`
  padding: 1em;
  text-align: left;
`;

const PlayersScores = ({ DATA, DURATION }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();
  const START = DURATION / 2;

  const InformationHold = 180;
  const Rounds = Math.round(DURATION / InformationHold);
  //console.log(DURATION, (Math.round(DURATION/InformationHold)))

  return (
    <Box
      style={{
        position: "absolute",
        bottom: "0px",

        width: "100%",
        zIndex: 1,
        opacity: interpolateOpacityByFrame(
          frame,
          DURATION - 30,
          DURATION - 15,
          1,
          0
        ),

        transform: `translateY(${SpringToFrom(START, 400, 0, "fast")}px)`,
      }}
    >
      <PlayerPerformances
        style={{
          clipPath: FromLeftToRight(START + 3, "Springy100"),
          backgroundColor: "rgb(34,124,101,0.7 )",
        }}
      >
        <H3
          style={{
            textAlign: "left",
            letterSpacing: "1px",
            fontWeight: 900,
            color: theme.Base[2],
          }}
        >
          Key Performances
        </H3>
      </PlayerPerformances>
      <Group position="apart" grow spacing={0}>
        <Box
          style={{
            padding: "1em ",
            backgroundColor: "rgb(214, 221, 219,0.8)",
          }}
        >
          <TOWATCHBAT
            ARR={DATA.batsmanToWatch}
            START={START + 7}
            Rounds={Rounds}
            InformationHold={InformationHold}
          />
        </Box>
        <Box
          style={{
            padding: "1em",
            backgroundColor: "rgb(214, 221, 219,0.9)",
          }}
        >
          <TOWATCHBALL
            ARR={DATA.bowlersToWatch}
            START={START + 7}
            Rounds={Rounds}
            InformationHold={InformationHold}
          />
        </Box>
      </Group>
    </Box>
  );
};

const TOWATCHBAT = ({ ARR, START, Rounds, InformationHold }) => {
  // console.log(ARR)

  return (
    <>
      <Series>
        {[...Array(Rounds)].map((a, i) => {
          return (
            <>
              {[...Array(ARR.length)].map((item, ii) => {
                return (
                  <Series.Sequence
                    key={i}
                    durationInFrames={InformationHold}
                    layout="none"
                  >
                    <PlayerPerformanceWrapper
                      P={ARR[ii]}
                      OBJ={{
                        IMAGE: "left",
                        NAME: TrimPlayerName(
                          ARR[ii].attributes.player.data.attributes.Name
                        ),
                        IMG: ARR[ii].attributes.player.data?.attributes.image
                          .data?.attributes.url,
                        LABEL: `${ARR[ii].attributes.BATTING_Runs} (${ARR[ii].attributes.BATTING_Balls})`,
                      }}
                    />
                  </Series.Sequence>
                );
              })}
            </>
          );
        })}
      </Series>
    </>
  );
};

const TOWATCHBALL = ({ ARR, START, Rounds, InformationHold }) => {
  return (
    <>
      <Series>
        {[...Array(Rounds)].map((item, i) => {
          return (
            <>
              {[...Array(ARR.length)].map((item, ii) => {
                return (
                  <Series.Sequence
                    key={i}
                    durationInFrames={InformationHold}
                    layout="none"
                  >
                    <PlayerPerformanceWrapper
                      P={ARR[ii]}
                      OBJ={{
                        IMAGE: "right",
                        NAME: TrimPlayerName(
                          ARR[ii].attributes.player.data.attributes.Name
                        ),
                        IMG: ARR[ii].attributes.player.data?.attributes.image
                          .data?.attributes.url,
                        LABEL: `${ARR[ii].attributes.BOWLING_Wkts}/${ARR[ii].attributes.BOWLING_Runs} (${ARR[ii].attributes.BOWLING_Overs})`,
                      }}
                    />
                  </Series.Sequence>
                );
              })}
            </>
          );
        })}
      </Series>
    </>
  );
};

const PlayerPerformanceWrapper = ({ OBJ }) => {
  return (
    <Group  position={OBJ.IMAGE === "left"?'left':'right'}>
      {OBJ.IMAGE === "left" ? (
        <Image src={OBJ.IMG} width={120} height={120} radius={10} />
      ) : (
        false
      )}

      <Box>
        <H3
          style={{
            color: "#5e5e5e",
            textAlign:OBJ.IMAGE === "left"?'left':'right',
            fontSize: "2.4em",
            lineHeight: "1em",
            fontWeight: 600,
            clipPath: FromRightToLeft(0, "Springy100"),
          }}
        >
          {OBJ.NAME}
        </H3>
        <H3
          style={{
            color: "#5e5e5e",
            textAlign: OBJ.IMAGE === "left"?'left':'right',
            fontSize: "2.2em",
            lineHeight: "1em",
            clipPath: FromRightToLeft(0, "Springy100"),
          }}
        >
          {OBJ.LABEL}
        </H3>
      </Box>
      {OBJ.IMAGE === "right" ? (
        <Image src={OBJ.IMG} width={120} height={120} radius={10} />
      ) : (
        false
      )}
    </Group>
  );
};

export const AnimateIn = ({ POTD, Dimensions, Durations, DATA }) => {
  return (
    <>
      <Details POTD={POTD} Durations={Durations} DATA={DATA} />
      <TripleDropTransition Dimensions={Dimensions} Durations={Durations} />
      <POTDPlayerImage IMG={POTD.IMAGE} Durations={Durations} />
    </>
  );
};

const DetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  z-index: 100;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: center;
  flex-direction: column;
`;

const ColoredMask = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;
const ScoreContainer = styled.div`
  position: relative;
  padding: 1rem 0;
  margin: 0.5rem 0;
  border-radius: 10px;
  width: 100%;
`;

const Details = ({ POTD, Durations, DATA }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();
  //console.log(POTD)

  const POTDSTRING = POTD.BATTING_Runs + "(" + POTD.BATTING_Balls + ")";

  return (
    <>
      <ColoredMask
        style={{
          backgroundColor: theme.Base[1],
          opacity: interpolateOpacityByFrame(frame, 0, 0 + 5, 1, 0),
        }}
      />
      <BottomUnderlayForCopy Durations={Durations} DATA={DATA} />
      <DetailsContainer>
        <TeamLogoLarge Durations={Durations} LOGO={POTD.Logo} />
        <ScoreContainer
          style={{
            overflow: "hidden",
            transform: `translateY(${SpringToFrom(
              7,
              -360,
              -370,
              "Springy100"
            )}px) translateY(${SpringToFrom(
              Durations.TransitionAfter,
              0,
              400,
              "Springy100"
            )}px)`,
          }}
        >
          <SmallCopyTranslateIn
            fontWeight="400"
            STR={POTD.Name}
            Duration={Durations.Close - Durations.Offset}
          />
          <TeamLogoSmall Logo={POTD.Logo} Durations={Durations} />
          <LargeTitleTranslateIn
            POTDSTRING={POTDSTRING}
            Durations={Durations}
          />
          <SmallCopyTranslateIn
            Size="sm"
            STR={`SR : ${POTD.BATTING_SR}`}
            Duration={Durations.Close - Durations.Offset}
          />
        </ScoreContainer>
      </DetailsContainer>
      <AnimatedStrokeAroundImage
        COLOR={1}
        DIVIDE={4}
        Durations={Durations}
        STYLES={{ width: "450px", height: "450px", top: "180px" }}
      />
      <AudioBaseTrack />
    </>
  );
};
