/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { Space, Group, Loader, Box, SimpleGrid } from "@mantine/core";

import { VideoWizardContainer } from "../../Theme/Structure/BodyContainer";
import {
  BTN_ICON_CALLBACK,
  BTN_ICON_LINK,
} from "../../Theme/Structure/Button/BTNS";

// Profile Comonents
import { DefaultProfileImageContainer } from "./Components/DefaultProfileImageContainer";
import { TrackedPerformances } from "./Components/TrackedPerformances";
import { AddNewItemToGallery } from "./Components/AddNewItemToGallery";
import { PlayerFullGallery } from "./Components/PlayerFullGallery";
import { useFetchPlayersLMSDetails } from "../../Hooks/user/useFetchPlayersLMSDetails";
import { useGetPlayerSingle } from "../../Hooks/Content/usePlayer";

import { P, P_Bold } from "../../Theme/type";
// UI ELEMENTS
import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";
import { BLOCK_Section_SubHeading_INVERT } from "../../Components/interface/BLOCK_Section_SubHeading";
import { BLOCK_ToolBar } from "../../Components/interface/BLOCK_Toolbar";

export default function PlayerProfile(props) {
  const { SelectedPlayer, setSelectedPlayer, setSectionID } = props;
  const [AddNew, setAddNew] = useState(false);
  const [FetchStatus, FetchDetails] = useFetchPlayersLMSDetails();
  const [Player, GetPlayer] = useGetPlayerSingle();

  // Handle Clicks
  const handlePlayerFetch = (_PLAYER) => {
    FetchDetails(_PLAYER.id, _PLAYER.attributes.PlayerID);
  };
  // on return
  useEffect(() => {
    if (FetchStatus === false) {
      GetPlayer(SelectedPlayer.id);
    }
  }, [FetchStatus]);
  useEffect(() => {
    if (Player != false) {
      setSelectedPlayer(Player);
    }
  }, [Player]);
  useEffect(() => {}, [AddNew]);

  return (
    <>
      <BLOCK_ToolBar
        TOOLS={[
          <BTN_ICON_CALLBACK
            CALLBACK={() => {
              setSectionID(0);
              setSelectedPlayer(false);
            }}
            ICON="Search"
            TIPOBJ={{
              LABEL: "Select a new Player",
              COLOR: "gray",
              POSITION: "top",
            }}
          />,
          <BTN_ICON_LINK
            TO={`https://www.lastmanstands.com/cricket-player/t20&playerid=${SelectedPlayer.attributes.PlayerID}`}
            ICON="ExternalLink"
            TIPOBJ={{
              LABEL: "Players LMS Profile",
              COLOR: "gray",
              POSITION: "top",
            }}
          />,
          FetchStatus ? (
            <Loader size="sm" variant="dots" color="white" />
          ) : (
            <BTN_ICON_CALLBACK
              CALLBACK={() => {
                handlePlayerFetch(SelectedPlayer);
              }}
              ICON="Refresh"
              TIPOBJ={{
                LABEL: "Refresh Player Stats",
                COLOR: "purple",
                POSITION: "top",
              }}
            />
          ),
        ]}
      />
      <Space h="xl" />
      <DefaultProfileImageContainer SelectedPlayer={SelectedPlayer} />

      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Player Gallery",
          ICON: "Photo",
          DESCRIPTION: "All images stored for player",
          TOOLBAR: false,
        }}
      />
      <BLOCK_ToolBar
        TOOLS={[
          <BTN_ICON_CALLBACK
            ICON="CameraSelfie"
            CALLBACK={() => {
              setAddNew(!AddNew);
            }}
            LABEL="Add New Image"
            TIPOBJ={{
              LABEL: "Add New Image",
              COLOR: "green",
              POSITION: "top",
            }}
          />,
        ]}
      />
      {!AddNew ? (
        false
      ) : (
        <>
          <AddNewItemToGallery
            setAddNew={setAddNew}
            SelectedPlayer={SelectedPlayer}
            setSelectedPlayer={setSelectedPlayer}
          />
        </>
      )}
      <Space h="md" />
      <PlayerFullGallery
        SelectedPlayer={SelectedPlayer}
        setSelectedPlayer={setSelectedPlayer}
      />
      <Space h="md" />
      <PlayersLMSStats SelectedPlayer={SelectedPlayer} />
      <Space h="xl" />
      <TrackedPerformances SelectedPlayer={SelectedPlayer} />
    </>
  );
}

const PlayersLMSStats = ({ SelectedPlayer }) => {
  const S = SelectedPlayer.attributes;
  const Width = useMediaQuery("(min-width: 769px)");
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Career Stats",
          ICON: "Download",
          DESCRIPTION: "All images stored for player",
          TOOLBAR: false,
        }}
      />
      <Group position="apart">
        {/* <BLOCK_Section_SubHeading_INVERT LABEL="PLAYING STATS" Color={5} />
      <ColoredContainer BGColor={1} Padding={"xs"}>
        <SimpleGrid cols={2} spacing="xs">
          <StatRow ROW={["MATCHES", S.MATCHES]} />
          <StatRow ROW={["STATUS", S.PLAYERPROFILELEVEL]} />
        </SimpleGrid>
      </ColoredContainer> */}
        <Box style={{ width: Width ? "48%" : "100%" }}>
          <BLOCK_Section_SubHeading_INVERT LABEL="BATTING" Color={5} />
          <VideoWizardContainer>
            <SimpleGrid cols={2} spacing="xs">
              <StatRow ROW={["National", S.NATIONALRANKINGBATTING]} />
              <StatRow ROW={["World", S.WORLDRANKINGBATTING]} />
              <StatRow ROW={["INN", S.INNINGS]} />
              <StatRow ROW={["RUNS", S.RUNS]} />
              <StatRow ROW={["AVG", S.BATTINGAVERAGE]} />
              <StatRow ROW={["SR", S.STRIKERATE]} />
              <StatRow ROW={["HS", S.HIGHSCORE]} />
              <StatRow ROW={["50's", S.FIFTIES]} />
              <StatRow ROW={["100's", S.HUNDREDS]} />
            </SimpleGrid>
          </VideoWizardContainer>
        </Box>
        <Box style={{ width: Width ? "48%" : "100%" }}>
          <BLOCK_Section_SubHeading_INVERT LABEL="Bowling" Color={5} />
          <VideoWizardContainer>
            <SimpleGrid cols={2} spacing="xs">
              <StatRow ROW={["National", S.NATIONALRANKINGBOWLING]} />
              <StatRow ROW={["World", S.WORLDRANKINGBOWLING]} />
              <StatRow ROW={["OVERS", S.OVERS]} />
              <StatRow ROW={["WKTS", S.WICKETS]} />
              <StatRow ROW={["AVG", S.BOWLINGAVERAGE]} />
              <StatRow ROW={["ECO", S.ECONOMY]} />
              <StatRow ROW={["BF", S.BESTFIGURES]} />
              <StatRow ROW={["3W", S.W3]} />
              <StatRow ROW={["5W", S.W5]} />
            </SimpleGrid>
          </VideoWizardContainer>
        </Box>
      </Group>
    </>
  );
};

const StatRow = ({ ROW }) => {
  return (
    <>
      <P>{ROW[0]}</P>
      <P_Bold>{ROW[1]}</P_Bold>
    </>
  );
};
/*
 const OBJ={
    

      TYPEBATTING :$Info('#pp-2020-player-bat-style').find(`p`).text(),
      TYPEBOWLING:$Info('#pp-2020-player-bowl-style').find(`p`).text(),
      :$Info('#circle-inner').find(`#circle-inner-level-name`).text(),
      isUpdating:false,
      LastUpdate:FINISHED
    }
*/
