import { useCurrentFrame} from 'remotion';
import { Image } from '@mantine/core';
import { useTheme } from 'styled-components'
import {CalendarEvent,Map2,Clock,Building,BuildingSkyscraper,World} from 'tabler-icons-react';
import styled from 'styled-components';

import moment from 'moment';
// Animation
import {interpolateOpacityByFrame} from '../../../../../../../Animation/interpolate'
import {SpringToFrom} from '../../../../../../../Animation/RemotionSpring'
import { FromMiddle,FromBottomToTop } from '../../../../../../../Animation/ClipWipe';
// Components
import {TopUnderlayForCopy} from '../../../../../../GlobalComponents/BottomUnderlayForCopy'
import { H1, H2,H3,H4 } from '../../../../../../utils/Text';


export const WinLoseRatios = ({D,STYLE})=>{
    const frame = useCurrentFrame();
    const theme = useTheme();
    const Theme_Stats = theme.GOTW.Stats[STYLE]
    return(
        <>
              
              <WinRatioInnerContainer
              style={{
                opacity:interpolateOpacityByFrame(frame, 45,50, 0, 1),
            }}>
              <WinLostRatioIntContainer
                 
                >
                    <H3>Won : {D.Won}</H3>
                    <H3>Lost : {D.Lost}</H3>
                </WinLostRatioIntContainer>
                  <WinRatioPositionContainer
                   
                  style={{
                      opacity:interpolateOpacityByFrame(frame, 45,60, 0, 1),
                      height: `${SpringToFrom(45,0,parseInt(D.Win_Ratio))}%`,
                      width:`${SpringToFrom(45,0,100)}%`,
                      backgroundColor:Theme_Stats.RatioFill,
                      
                  }}
                  >
                      <H3
                      style={{
                          opacity:interpolateOpacityByFrame(frame, 60,75, 0, 1),
                         fontSize:'3em',
                         color:Theme_Stats.FILLCOPY,
                      }}
                      >Win Ratio: {D.Win_Ratio}</H3>
                  </WinRatioPositionContainer>
              </WinRatioInnerContainer>
             
              
          </>
    )
}

const WinRatioInnerContainer = styled.div`
display: flex;
flex-direction:column;
align-items: center;
justify-content: flex-end;
align-content: center;
width: 100%;
border-radius: 10px;
height:300px;
background: radial-gradient(97.01% 88.57% at 50% 8.49%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.15) 100%);

box-sizing: border-box;
backdrop-filter: blur(1px);
padding:1%;
margin:50px 0 0 0;`

const WinRatioPositionContainer = styled.div`
display: flex;
flex-direction:column;
align-items: center;
justify-content: center;
align-content: center;
border-radius: 5px;
width: 100%;
background-color: lightblue;`
const WinLostRatioIntContainer = styled.div`  
display: flex;
justify-content: space-evenly;
width:100%;
margin:15px;
`