/* eslint-disable react/jsx-pascal-case */
// HOOKS
import { useGetRegionalSeasons } from "../../../Hooks/Content/useRegionalSeasons";
import { useGetGroupOfLeagues } from "../../../Hooks/Content/useLeagues";
// Actions
import { MergeTop5APICallBack } from "../../../actions/EvaluationUtils";

import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";

import { ActionIcon, Container, Input, Table, Space } from "@mantine/core";

import { ICONDB } from "../../../Theme/icons";
import { orderBy, filter } from "lodash";
// UI Elements
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
//import { PageHeader } from "../../../Components/interface/ProductHeader";
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";
import { SelectRegionSeason } from "../../../Components/settings/SelectRegionSeason";
import { useEffect, useState } from "react";
import {
  TABLE_TD,
  TABLE_WRAPPER,
} from "../../../Components/interface/Wrapper/Table";

export const PlayerStatsTableBatting = ({ SeasonID }) => {
  const [getLeagues, setgetLeagues] = useGetGroupOfLeagues();
  const [DATA, setDATA] = useState(false);
  const [SortBy, setSortBy] = useState("Name");
  const [asc, setasc] = useState(true);
  const [PlayerName, FindPlayerName] = useState(false);

  let timeout = null;
  const FetchPlayerStats = (DATA) => {
    const ARR = [];
    DATA.attributes.watch_lists.data.map((WL, i) => {
      // console.log(WL);
      ARR.push(WL.id);
    });
    setgetLeagues(ARR);
  };

  const handleChange = (e) => {
    if (e.target.value.length > 3) {
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        FindPlayerName(e.target.value);
      }, 500);
    } else {
      FindPlayerName(false);
    }
  };

  const CreateRows = (DATA) => {
    return DATA.map((element, i) => {
      return (
        <tr key={i}>
          <TABLE_TD>{i + 1}</TABLE_TD>
          <TABLE_TD>{element.Name}</TABLE_TD>
          <TABLE_TD>{element.BATTING_INNINGS}</TABLE_TD>
          <TABLE_TD>{element.BATTING_Runs}</TABLE_TD>
          <TABLE_TD>{element.BATTING_DismissalCount}</TABLE_TD>
          <TABLE_TD>{element.BATTING_Balls}</TABLE_TD>
          <TABLE_TD>{element.BATTING_fours}</TABLE_TD>
          <TABLE_TD>{element.BATTING_sixes}</TABLE_TD>
          <TABLE_TD>
            {((element.BATTING_Runs / element.BATTING_Balls) * 100).toFixed(2)}
          </TABLE_TD>
          <TABLE_TD>
            {(element.BATTING_Runs / element.BATTING_DismissalCount).toFixed(2)}
          </TABLE_TD>
        </tr>
      );
    });
  };

  useEffect(() => {
    // console.log(asc);
    if (getLeagues) {
      let ITEM = orderBy(
        MergeTop5APICallBack(getLeagues, SortBy),
        [SortBy, "BATTING_Balls"],
        [asc ? "asc" : "desc", asc ? "desc" : "asc"]
      );

      setDATA(
        !PlayerName
          ? ITEM
          : filter(ITEM, function (o) {
              return o.Name.toLowerCase().includes(PlayerName.toLowerCase());
            })
      );
    }
  }, [getLeagues, SortBy, asc, PlayerName]);

  useEffect(() => {
    if (SeasonID !== false) FetchPlayerStats(SeasonID);
  }, [SeasonID]);

  useEffect(() => {
   if(DATA){
    const ARR=[]
    DATA.slice(0,20).map((p,i)=>{
      console.log(p)
      ARR.push({Name:p.Name,Runs:p.BATTING_Runs,Balls:p.BATTING_Balls, Inn:p.BATTING_INNINGS, Dismissed:p.BATTING_DismissalCount,Fours:p.BATTING_fours, Sixes:p.BATTING_sixes })
    })
    console.log(ARR)
   }
    

  }, [DATA]);

  if (!DATA) return false;
  return (
    <>
      <Space h="xl" />
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Player List",
          ICON: "ChartDots",
          DESCRIPTION:
            "Use the dots above the title to sortby this Category, Click twice to change order",
          TOOLBAR: false,
        }}
      />
      <Space h="xl" />

      <Container size="lg"  p={0}>
        <Input
          onChange={handleChange}
          placeholder="Filter Players by Name"
          styles={(theme) => ({
            input: {
              "&, &:focus": {
                borderColor: theme.colors.brand[2],
                backgroundColor: theme.colors.brand[2],
                color: theme.colors.brand[0],
              },
            },
          })}
        />
        <Space h="xl" />
        <BLOCK_Section_SubHeading
          LABEL={`Players Listed ${DATA.length}`}
          Color={5}
        />

        <VideoWizardContainer>
          <TABLE_WRAPPER>
            <thead>
              <tr>
                <th></th>
                <th>
                  <ActionIconController
                    setSortBy={setSortBy}
                    SortBy={SortBy}
                    setasc={setasc}
                    asc={asc}
                    Label="Name"
                  />
                  Name
                </th>
                <th>
                  <ActionIconController
                    setSortBy={setSortBy}
                    SortBy={SortBy}
                    setasc={setasc}
                    asc={asc}
                    Label="BATTING_INNINGS"
                  />
                  INNS
                </th>
                <th>
                  <ActionIconController
                    setSortBy={setSortBy}
                    SortBy={SortBy}
                    setasc={setasc}
                    asc={asc}
                    Label="BATTING_Runs"
                  />
                  Runs
                </th>
                <th>
                  <ActionIconController
                    setSortBy={setSortBy}
                    SortBy={SortBy}
                    setasc={setasc}
                    asc={asc}
                    Label="BATTING_DismissalCount"
                  />
                  Dismissals
                </th>
                <th>
                  <ActionIconController
                    setSortBy={setSortBy}
                    SortBy={SortBy}
                    setasc={setasc}
                    asc={asc}
                    Label="BATTING_Balls"
                  />
                  BF
                </th>

                <th>
                  <ActionIconController
                    setSortBy={setSortBy}
                    SortBy={SortBy}
                    setasc={setasc}
                    asc={asc}
                    Label="BATTING_fours"
                  />
                  Fours
                </th>
                <th>
                  <ActionIconController
                    setSortBy={setSortBy}
                    SortBy={SortBy}
                    setasc={setasc}
                    asc={asc}
                    Label="BATTING_sixes"
                  />
                  Sixes
                </th>
                <th>
                  {/* <ActionIconController
                      setSortBy={setSortBy}
                      SortBy={SortBy}
                      setasc={setasc}
                      asc={asc}
                      Label="BATTING_SR"
                    /> */}
                  SR
                </th>
                <th>
                  {/* <ActionIconController
                      setSortBy={setSortBy}
                      SortBy={SortBy}
                      setasc={setasc}
                      asc={asc}
                      Label="BATTING_AVG"
                    /> */}
                  AVG
                </th>
              </tr>
            </thead>
            <tbody>{CreateRows(DATA)}</tbody>
          </TABLE_WRAPPER>
        </VideoWizardContainer>
      </Container>
    </>
  );
};

const ActionIconController = ({ setSortBy, SortBy, setasc, asc, Label }) => {
  return (
    <ActionIcon
      sx={(theme) => ({
        color: SortBy === Label ? theme.colors.brand[4] : theme.colors.brand[1],
      })}
      onClick={() => {
        setSortBy(Label);
        setasc(!asc);
      }}
    >
      {ICONDB.Point.Component}
    </ActionIcon>
  );
};
