import { Audio, Series } from "remotion";
import styled from "styled-components";
import { orderBy } from "lodash";
import { PageTimer } from "./UI/Timer";
import { FixtureCard } from "./UI/FixtureCard";

const WRAPPER = styled.div`
  display: flex;
  flex-direction: column;
  place-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 160px;
  width: 96%;
`;

export const FixturePageWrapper = ({ DATA, LABEL, Durations }) => {
  const CreatePairs = () => {
    let chunkSize = 4;
    //console.log(orderBy(DATA.Fixtures[LABEL], ["time"]));
    //ground
    const ARR = [];
    for (let i = 0; i < DATA.Fixtures[LABEL].length; i += chunkSize) {
      const chunk = orderBy(DATA.Fixtures[LABEL], ["time"]).slice(
        i,
        i + chunkSize
      );
      ARR.push(chunk);
    }

    return ARR;
  };

  return (
    <Series>
      {CreatePairs().map((Fixture, i) => {
        return (
          <Series.Sequence
            key={i}
            durationInFrames={Durations.Length}
            layout="none"
          >
            <WRAPPER key={i}>
              {Fixture.map((GAME, i) => {
                return (
                  <FixtureCard
                    GAME={GAME}
                    key={i}
                    Durations={Durations}
                    LEN={Fixture.length}
                  />
                );
              })}
            </WRAPPER>

            <PageTimer Durations={Durations} />

            <Audio
              src={`https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/TournamentsResultsItem_1.mp3`}
              playbackRate={1}
              startFrom={0} // if composition is 30fps, then it will start at 2s
              volume={1}
            />
          </Series.Sequence>
        );
      })}
    </Series>
  );
};
