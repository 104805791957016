import { useCurrentFrame } from "remotion";
import { useTheme } from "styled-components";
// Components
/* import {FixtureResultsUL} from "./FixtureResultsUL" */
import styled from "styled-components";
import { H1 } from "../../../../utils/Text";
import { Image } from "@mantine/core";
//import {CalendarEvent,Map2,Clock,Building,BuildingSkyscraper,World} from 'tabler-icons-react';
import { interpolateOpacityByFrame } from "../../../../../Animation/interpolate";
import { SpringToFrom } from "../../../../../Animation/RemotionSpring";
import {SeriesWrapper} from './UIELEMENTS'

const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
  align-content: center;
`;



export const PlayerIntro = ({ DATA, Duration, PROFILEPICTURE }) => {
  //console.log(DATA.VideoSettings.DisplayThisMilestone)
  const frame = useCurrentFrame();
  const theme = useTheme();
  //ImageRatio
  //console.log(ImageRatio(PROFILEPICTURE), PROFILEPICTURE);

  return ( 
    <SeriesWrapper>
      <IntroContainer>
        <div
          style={{
            height: `${SpringToFrom(30, 0, 700)}px`,
            width: `${700}px`,
          }}
        >
          <Image
            radius={700}
            width={700}
            height={700}
            src={PROFILEPICTURE.url}
            style={{
              textAlign: "center",

              padding: "0%",
              borderRadius: 10000,
              transform: `scale(${SpringToFrom(
                30,
                0,
                1
              )})   scale(${SpringToFrom(Duration - 10, 1, 0)})`,
            }}
          />
        </div>
        <H1
          style={{
            fontSize: "5em",
            marginTop: `${SpringToFrom(30, 0, 80)}px`,
            width: "100%",
            transform: `translateX(${SpringToFrom(0, 50, 15)}px)`,
          }}
        >
          {DATA.DATAOBJ.Name.split("").map((D, i) => {
            return (
              <span
                key={i}
                style={{
                  margin: 0,
                  opacity: interpolateOpacityByFrame(
                    frame,
                    1 + 1 * (i + 1),
                    2 + 1 * (i + 1),
                    0,
                    1
                  ),
                }}
              >
                {D}
              </span>
            );
          })}
        </H1>

        <H1
          style={{
            fontSize: "10em",
            lineHeight: ".8em",
            fontWeight: 900,
            width: "100%",
            marginTop: `${SpringToFrom(30, 0, 50)}px`,
            color: theme.Copy[0],
            transform: `translateX(${SpringToFrom(0, 50, 15)}px)`,
          }}
        >
          {`${DATA.DATAOBJ[DATA.VideoSettings.DisplayThisMilestone.KEY]} ${
            DATA.VideoSettings.DisplayThisMilestone.LABEL
          }`
            .split("")
            .map((D, i) => {
              return (
                <span
                  key={i}
                  style={{
                    margin: 0,
                    opacity: interpolateOpacityByFrame(
                      frame,
                      1 + 1 * (i + 1),
                      2 + 1 * (i + 1),
                      0,
                      1
                    ),
                    //transform: `translateY(${SpringToFrom(3+(7*i),100,0)}px)`
                  }}
                >
                  {D}
                </span>
              );
            })}
        </H1>
      </IntroContainer>
    </SeriesWrapper>
  );
};
