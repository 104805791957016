import { useCurrentFrame } from "remotion";
import { useTheme } from "styled-components";
import styled from "styled-components";
export const PageTimer = ({ Durations }) => {
    
    const frame = useCurrentFrame();
    const theme = useTheme();
    //console.log(((frame/Durations.Length)*100))
    return (
      <TimerContainer
        style={{
          width: `${(frame / Durations.Length) * 100}%`,
          backgroundColor: theme.Base[0],
        }}
      ></TimerContainer>
    );
  };

  const TimerContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 10px;
`;