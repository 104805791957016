import { createStyles, Avatar, Text, Group } from "@mantine/core";
import { IconPhoneCall, IconAt } from "@tabler/icons";
import { H3,P } from "../../../Theme/type";

const useStyles = createStyles((theme) => ({
  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[5],
  },

  name: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

export function MilestoneCard({ player, VAR }) {
 
  return (
    <div>
      <Group noWrap>
        <Avatar
          src={player?.image?.formats?.thumbnail?.url}
          size={94}
          radius="md"
        />
        <div>
            
            <H3 colorID={3} lineheight={'1.2em'} size={'sm'} weight={900} transform={'uppercase'}>{player.Name}</H3>
            <H3 colorID={4} lineheight={'1.2em'}>{VAR} {player[VAR]}</H3>
            <P size={'sm'} colorID={5} >ID : {player.PlayerID}</P>
            <P size={'sm'} colorID={5} >STATUS : {player.PLAYERPROFILELEVEL}</P>
          
        </div>
      </Group>
    </div>
  );
}
/*
 
    <tr key={player.Name}>
      <TABLE_TD>{player.PlayerID}</TABLE_TD>
      <TABLE_TD>{player.Name}</TABLE_TD>
      <TABLE_TD>{player[VAR]}</TABLE_TD>
      <TABLE_TD>
        <BTN_ICON_LINK
          TO={`https://www.lastmanstands.com/cricket-player/t20&playerid=${player.PlayerID}`}
          ICON="ExternalLink"
          TIPOBJ={{
            LABEL: "Players Profile",
            COLOR: "gray",
            POSITION: "left",
          }}
        />
      </TABLE_TD>
    </tr>
*/
