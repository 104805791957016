// Core
import { useCurrentFrame } from 'remotion';
import { Center,  Image, Stack } from '@mantine/core';

// Animations
import {SpringToFrom} from '../../../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../../../Animation/interpolate'

export const Section_TeamlogoandSeason=({DATA, DURATION})=>{
 
    const frame = useCurrentFrame();
    return(
      <>
            <Stack 
                justify="center" 
                spacing={0}
                style={{
                    padding:'1em 0 '
                }}
            >
                    <Center>
                        <Image 
                        src={DATA.DATAOBJ.General.LOGO} 
                        width={250}
                        height={250}
                        radius={300}
                        style={{
                            position: 'relative',
                            zIndex: '100',
                            opacity:interpolateOpacityByFrame(frame,7,15, 0, 1),
                            transform: `scale( ${SpringToFrom(7,0,1,'Springy100')}) scale(${SpringToFrom((DURATION-15),1,0,'Springy100')})`,
                        }}
                    />
                    </Center>
            </Stack>
      </>
    )
  }