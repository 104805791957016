
export const LOGOMAIN=()=>{ 
    return(
        <svg viewBox="0 0 1112 1260" fill="none" >
			<path fillRule="evenodd" clipRule="evenodd" d="M1.68942 723.91C24.6323 737.067 44.6895 748.53 64.7071 760.059C89.2861 774.221 113.273 789.553 138.59 802.235C154.535 810.228 158.726 820.735 158.429 837.729C157.407 895.723 158.039 953.74 158.039 1011.75C158.039 1018.39 158.039 1025.02 158.039 1036.03C185.655 1020.16 209.702 1006.84 233.052 992.4C236.612 990.199 238.496 982.785 238.522 977.765C238.876 911.564 239.206 845.349 238.109 779.161C237.99 772.093 230.381 762.573 223.644 758.602C155.229 718.259 86.4005 678.605 17.3372 639.376C5.73192 632.781 0.439955 625.703 0.482926 611.544C1.0151 432.471 0.737446 253.395 0.889495 74.3252C0.942381 10.4599 51.611 -18.8459 106.57 12.8753C198.295 65.8134 290.113 118.593 381.461 172.169C388.125 176.078 395.486 185.852 395.532 192.946C396.379 318.417 396.147 443.891 396.088 569.368C396.088 572.338 395.407 575.306 394.663 581.386C368.494 566.49 344.09 552.688 319.776 538.734C297.358 525.867 275.43 512.081 252.511 500.192C241.153 494.304 238.406 486.886 238.515 474.898C239.071 412.48 238.459 350.052 239.041 287.634C239.157 275.497 236.083 267.97 224.867 262.138C203.349 250.946 182.634 238.218 159.179 224.743C158.653 234.137 158.085 239.623 158.082 245.104C158.029 337.162 158.296 429.223 157.761 521.28C157.685 534.296 161.8 541.562 173.194 548.022C242.277 587.214 310.745 627.497 379.845 666.659C391.665 673.361 396.574 680.485 396.508 694.505C395.919 816.826 395.952 939.151 396.465 1061.47C396.521 1075.14 392.458 1082.73 380.324 1089.63C288.834 1141.71 197.823 1194.63 106.653 1247.26C52.5035 1278.51 1.03827 1249.83 0.929188 1187.84C0.66145 1039.66 0.833317 891.484 0.863066 743.312C0.856455 738.32 1.27294 733.324 1.68942 723.91Z" fill="#EEF2F3"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M477.837 1031.13C477.837 762.653 477.837 497.311 477.837 228.705C485.377 232.403 491.402 234.953 497.035 238.185C534.75 259.851 571.697 282.981 610.314 302.873C630.177 313.103 635.32 325.676 635.141 347.138C634.054 476.372 634.599 605.619 634.599 734.863C634.599 742.113 634.599 749.363 634.599 761.073C662.335 745.024 686.544 731.929 709.513 716.941C714.018 713.997 715.39 703.73 715.417 696.834C715.777 594.702 715.668 492.569 715.668 390.434C715.671 383.551 715.671 376.668 715.671 366.064C723.786 370.01 729.746 372.472 735.279 375.66C847.217 440.213 959.108 504.842 1071 569.46C1124.77 600.508 1124.77 659.711 1071.02 690.728C1038.27 709.635 1005.54 728.575 972.74 747.413C967.481 750.433 961.944 752.968 953.829 757.105C953.829 735.564 955.052 716.762 953.244 698.248C952.487 690.47 947.116 680.617 940.736 676.361C920.421 662.807 898.642 651.443 874.506 637.552C873.904 646.054 873.154 651.85 873.144 657.652C873.045 701.781 873.58 745.926 872.589 790.032C872.427 797.206 867.565 807.432 861.724 810.846C738.716 882.688 615.266 953.76 491.865 1024.91C488.229 1027.01 484.16 1028.35 477.837 1031.13Z" fill="#EEF2F3"/>
		</svg>
    )
} 
export const LOGOMAINDARK=()=>{ 
    return(
        <svg viewBox="0 0 1112 1260" fill="none" >
			<path fillRule="evenodd" clipRule="evenodd" d="M1.68942 723.91C24.6323 737.067 44.6895 748.53 64.7071 760.059C89.2861 774.221 113.273 789.553 138.59 802.235C154.535 810.228 158.726 820.735 158.429 837.729C157.407 895.723 158.039 953.74 158.039 1011.75C158.039 1018.39 158.039 1025.02 158.039 1036.03C185.655 1020.16 209.702 1006.84 233.052 992.4C236.612 990.199 238.496 982.785 238.522 977.765C238.876 911.564 239.206 845.349 238.109 779.161C237.99 772.093 230.381 762.573 223.644 758.602C155.229 718.259 86.4005 678.605 17.3372 639.376C5.73192 632.781 0.439955 625.703 0.482926 611.544C1.0151 432.471 0.737446 253.395 0.889495 74.3252C0.942381 10.4599 51.611 -18.8459 106.57 12.8753C198.295 65.8134 290.113 118.593 381.461 172.169C388.125 176.078 395.486 185.852 395.532 192.946C396.379 318.417 396.147 443.891 396.088 569.368C396.088 572.338 395.407 575.306 394.663 581.386C368.494 566.49 344.09 552.688 319.776 538.734C297.358 525.867 275.43 512.081 252.511 500.192C241.153 494.304 238.406 486.886 238.515 474.898C239.071 412.48 238.459 350.052 239.041 287.634C239.157 275.497 236.083 267.97 224.867 262.138C203.349 250.946 182.634 238.218 159.179 224.743C158.653 234.137 158.085 239.623 158.082 245.104C158.029 337.162 158.296 429.223 157.761 521.28C157.685 534.296 161.8 541.562 173.194 548.022C242.277 587.214 310.745 627.497 379.845 666.659C391.665 673.361 396.574 680.485 396.508 694.505C395.919 816.826 395.952 939.151 396.465 1061.47C396.521 1075.14 392.458 1082.73 380.324 1089.63C288.834 1141.71 197.823 1194.63 106.653 1247.26C52.5035 1278.51 1.03827 1249.83 0.929188 1187.84C0.66145 1039.66 0.833317 891.484 0.863066 743.312C0.856455 738.32 1.27294 733.324 1.68942 723.91Z" fill="#4A5060"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M477.837 1031.13C477.837 762.653 477.837 497.311 477.837 228.705C485.377 232.403 491.402 234.953 497.035 238.185C534.75 259.851 571.697 282.981 610.314 302.873C630.177 313.103 635.32 325.676 635.141 347.138C634.054 476.372 634.599 605.619 634.599 734.863C634.599 742.113 634.599 749.363 634.599 761.073C662.335 745.024 686.544 731.929 709.513 716.941C714.018 713.997 715.39 703.73 715.417 696.834C715.777 594.702 715.668 492.569 715.668 390.434C715.671 383.551 715.671 376.668 715.671 366.064C723.786 370.01 729.746 372.472 735.279 375.66C847.217 440.213 959.108 504.842 1071 569.46C1124.77 600.508 1124.77 659.711 1071.02 690.728C1038.27 709.635 1005.54 728.575 972.74 747.413C967.481 750.433 961.944 752.968 953.829 757.105C953.829 735.564 955.052 716.762 953.244 698.248C952.487 690.47 947.116 680.617 940.736 676.361C920.421 662.807 898.642 651.443 874.506 637.552C873.904 646.054 873.154 651.85 873.144 657.652C873.045 701.781 873.58 745.926 872.589 790.032C872.427 797.206 867.565 807.432 861.724 810.846C738.716 882.688 615.266 953.76 491.865 1024.91C488.229 1027.01 484.16 1028.35 477.837 1031.13Z" fill="#4A5060"/>
		</svg>
    )
} 