
import { useState } from 'react';
import {FranchiseDataChecker} from './SelectAGame'
import {SelectedGame} from './SelectedGame'
// UI ELEMENTS
export const GameReviews = () => {
    const [Selected,isSelected] = useState(false)
    const [GameData,hasGameData] = useState(false)

    if(Selected){ 
        return <SelectedGame isSelected={isSelected} GameData={GameData}/>
    }else{
        return <FranchiseDataChecker isSelected={isSelected} hasGameData={hasGameData}/>
    }
 
};
