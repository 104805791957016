// Components
import { TopUnderlayForCopy, BottomUnderlayForCopy } from "./UnderLays";

export const TOPTOBOTTOM = ({ DATA, POSITIONING }) => {
  //console.log(DATA);
  /*
   {
            TOP:[-100,-80],
            BOTTOM:[100,55],
          }
  */
  const Durations = {
    Begin: 0,
  };
  return (
    <>
      <TopUnderlayForCopy
        Durations={Durations}
        POSITIONSTARTFROMTOP={POSITIONING.TOP[0]}
        POSITIONFROMTOP={POSITIONING.TOP[1]}
      />
      <BottomUnderlayForCopy
        Durations={Durations}
        POSITIONSTARTFROMTOP={POSITIONING.BOTTOM[0]}
        POSITIONFROMTOP={POSITIONING.BOTTOM[1]}
      />
    </>
  );
};
