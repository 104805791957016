import { useState } from "react";
//import { useSelector } from 'react-redux'
import { ENV } from "../../Config/RunTimeConfig";
import { parseCookies } from "nookies";
const qs = require("qs");

export const useFindTeamName = (ctx) => { 
  const [TeamName, setTeamName] = useState([]);
  const [error, setError] = useState(null);

  const jwt = parseCookies(ctx).jwt;

  const FindTeamName = async (_PARTIALNAME) => {
    setTeamName([]);
    setError(null);
    const query = qs.stringify(
      {
        pagination: {
          page: 1,
          pageSize: 5,
        },
        filters: {
          $or: [
            {
              Name: { $containsi: _PARTIALNAME },
            },
            /* {
              player_battings: {
                player: { Name: { $containsi: _PARTIALNAME } },
              },
            },
            {
              player_bowlings: {
                player: { Name: { $containsi: _PARTIALNAME } },
              },
            }, */
          ],
        },
        populate: [
          "Logo",
        
        ],
      },
      {
        encodeValuesOnly: true,
      }
    ); 

    try {
      const res = await fetch(`${ENV.API_URL}/api/teams?${query}`, { 
        headers: {
          Authorization: `Bearer ${jwt}`,
        }, 
      });
      const loginResponse = await res.json();
      if (res.ok) {
        setTeamName(loginResponse.data);
      } else {
        setError(loginResponse.message);
      }
    } catch (error) {
      setError("An unexpected error occured. Please try again later.");
    }
  };
  return [TeamName, FindTeamName, error];
};




export const useFindSingleTeam = (ctx) => {
  const [TeamName, setTeamName] = useState(false);

  //const UXSTATE = useSelector((state) => state.UXSTATE);
  const jwt = parseCookies(ctx).jwt;

  const FindTeamName = async (_ID) => {
    setTeamName(false);
    const query = qs.stringify(
      {
        populate: [
          "Logo",
          "TeamPhoto",
          "ImageGallery",
          "player_battings",
          "player_battings.player",
          "player_bowlings",
          "player_bowlings.player",
          "player_catches",
          "player_catches.player",
          "player_stumpings",
          "player_stumpings.player",
          "player_moms",
          "player_moms.player",
        ],
        
      },
      {
        encodeValuesOnly: true,
      }
    );

    const res = await fetch(`${ENV.API_URL}/api/teams/${_ID}?${query}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    const loginResponse = await res.json();

    //console.log(loginResponse)
    setTeamName(loginResponse.data);
  };
  return [TeamName, FindTeamName];
};


export const useFindSingleTeamForComentary = (ctx) => {
  const [Team, setTeam] = useState(false);

  //const UXSTATE = useSelector((state) => state.UXSTATE);
  const jwt = parseCookies(ctx).jwt;

  const FindTeam = async (_ID,_LEAGUEID) => {
    setTeam(false);
    const query = qs.stringify(
      {
        filters:{
          "player_battings":{
            "fixture":{
              id:_LEAGUEID 
            }
          }
        },
        populate: [
          "Logo",
          "TeamPhoto",
          "ImageGallery",
         
        ],
        
      },
      {
        encodeValuesOnly: true,
      }
    );

    const res = await fetch(`${ENV.API_URL}/api/teams/${_ID}?${query}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    const loginResponse = await res.json();

  //console.log(loginResponse)
    setTeam(loginResponse.data);
  };
  return [Team,FindTeam];
  /*
   "player_battings",
          "player_battings.player",
          "player_bowlings",
          "player_bowlings.player",
          "player_catches",
          "player_catches.player",
          "player_stumpings",
          "player_stumpings.player",
          "player_moms",
          "player_moms.player",
  */
};