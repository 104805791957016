import { useEffect, useState } from "react";
import { SelectRegionDivisions } from "../../settings/SelectRegionDivision";
import { SelectMultipleRegionDivision } from "../../settings/SelectMultipleRegionDivision";
import { H3 } from "../../../Theme/type";
import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";
import { Group, Stack } from "@mantine/core";

import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
export const SelectAnActiveDivision = ({
  setSelectedDivision,
  SelectedDivision,
  setSelectedQueryDivision,
  SelectedQueryDivision
}) => {
  const [value, setValue] = useState(SelectedDivision);
  const [DisplaySelected, setDisplaySelected] = useState(false);
  const [ItemState, setItemState] = useState(0);


  const handleChange = (e) => {
    //console.log(e)
    setValue(e);
    setSelectedDivision(e);
    setDisplaySelected(e);
    setSelectedQueryDivision(e)
    setItemState(10);
  };


  useEffect(()=>{
    //console.log(SelectedQueryDivision)
  },[SelectedQueryDivision])


  return (
    <>
      <BLOCK_Section_SubHeading
        LABEL="Select Main Division"
        BGColor={0}
        Color={DisplaySelected ? 7 : 5}
      />
  
      <VideoWizardContainer DividerSize={ItemState} Padding="xs">
        <Group position="apart" spacing="sm" grow>
         {/*  <SelectRegionDivisions CALLBACK={handleChange} DefaultValue={value} /> */}

          <SelectMultipleRegionDivision CALLBACK={handleChange} />


         
        </Group>
      </VideoWizardContainer>
    </> 
  );
};