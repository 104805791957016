/* eslint-disable react/jsx-pascal-case */
import { useSelector } from "react-redux";
// ACTIONS
import { RemoveWashouts } from "../../../actions/Stats";
import {
  ColoredContainer,
  ContentContainerx3,
  VideoWizardContainer,
} from "../../../Theme/Structure/BodyContainer";
import { H3, P } from "../../../Theme/type";
import { Box, Center, Container, Group, Space } from "@mantine/core";
import { BarChart } from "../../../Components/Charts/Bar";
import { LineChart } from "../../../Components/Charts/Line";

import { ICONDB } from "../../../Theme/icons";
import { orderBy } from "lodash";

// UI Elements
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";

export const StatisticsBowling = () => {
  const UXSTATE = useSelector((state) => state.UXSTATE);

  //console.log(UXSTATE.SelectedFranchise)
  if (UXSTATE.SelectedFranchise.attributes?.FranchiseStats === undefined)
    return <>Loading Data</>;
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Bowling Statistics Overview",
          ICON: "ChartDots",
          DESCRIPTION: "A statistical overview of the active seasons listed in the Manage Leagues page under the Franchise Tab.",
          TOOLBAR: false,
        }}
      />

      <Space h="xl" />

      <BarChartWicketsByFixture
        STATS={UXSTATE.SelectedFranchise.attributes.FranchiseStats}
      />
      <LineChartBowlingWicketAverages
        STATS={UXSTATE.SelectedFranchise.attributes.FranchiseStats}
      />
      <LineChartBowlingEconomyAverages
        STATS={UXSTATE.SelectedFranchise.attributes.FranchiseStats}
      />
    </>
  );
};

export const TournamentBowlingContainer = ({ FranchiseStats }) => {
  return (
    <>
      <BLOCK_Section_SubHeading LABEL="BOWLING" Color={5} />

      <BarChartWicketsByFixture
        STATS={FranchiseStats.franchise.data.attributes.FranchiseStats}
      />
      <LineChartBowlingWicketAverages
        STATS={FranchiseStats.franchise.data.attributes.FranchiseStats}
      />
      <LineChartBowlingEconomyAverages
        STATS={FranchiseStats.franchise.data.attributes.FranchiseStats}
      />
    </>
  );
};

const BASESTATS = ({ STATS }) => {
  return (
    <>
      <Space h="sm" />
      <ColoredContainer Padding="xs" BGColor={5}>
        <Group position="apart" spacing="xl" grow>
          <H3 colorID={1}>Active Season Overview</H3>
          <P colorID={1} align="right">
            {ICONDB.BoxMultiple6.Component}
          </P>
        </Group>
      </ColoredContainer>
      <TotalFixturesByMatchDay STATS={STATS} />
      <ContentContainerx3 BGColor={1}>
        <Group position="apart" grow>
          <BasicPod
            VALUE={STATS.FIXTURES}
            LABEL="Completed Fixtures"
            ICON="CalendarEvent"
          />
          <BasicPod VALUE={STATS.RUNS} LABEL="Runs Scored" ICON="Cricket" />
          <BasicPod
            VALUE={STATS.WICKETS}
            LABEL="Wickets Taken"
            ICON="BallTennis"
          />
          <BasicPod
            VALUE={STATS.OVERS.toFixed(1)}
            LABEL="Overs Bowled"
            ICON="Refresh"
          />
        </Group>
      </ContentContainerx3>
      <ContentContainerx3 BGColor={3}>
        <H3 align="center" colorID={0}>
          Average Season Score {(STATS.RUNS / STATS.FIXTURES / 2).toFixed(1)} /{" "}
          {(STATS.WICKETS / STATS.FIXTURES / 2).toFixed(1)} (
          {(STATS.OVERS / STATS.FIXTURES / 2).toFixed(1)})
        </H3>
      </ContentContainerx3>
    </>
  );
};

const BasicPod = ({ VALUE, LABEL, ICON }) => {
  //console.log(VALUE,LABEL,ICON)
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.colors.brand[0],
        borderRadius: 3,
        borderColor: theme.colors.brand[0],
        borderWidth: 1,
        borderStyle: "solid",
        boxShadow: "5px 5px 10px rgba(148, 148, 148, 0.25)",
        textAlign: "center",
        width: "22%",
        padding: "1em 0.5em",
      })}
    >
      <Center>
        <H3 colorID={5} weight={900} transform="uppercase">
          {VALUE}
        </H3>
      </Center>
      <Center>
        <H3 colorID={4} weight={900} transform="uppercase">
          {ICONDB[ICON].Component}
        </H3>
      </Center>
      <Center>
        <H3 colorID={2} transform="uppercase">
          {LABEL}
        </H3>
      </Center>
    </Box>
  );
};
//{colorID=4,size="lg", align='left',weight=400, underline=false,transform="capitalize" }

const TotalFixturesByMatchDay = ({ STATS }) => {
  const RenderHistory = (TYPE) => {
    const ASSETS = TYPE;
    const REMOVEWASHOUTS = RemoveWashouts(STATS.OverTheSeason);
    return [orderBy(REMOVEWASHOUTS, ["id"], ["asc"]), ASSETS];
  };
  return (
    <>
      <ColoredContainer BGColor={0}>
        <H3>Fixture count by Matchday</H3>
        <P>
          Total number of fixtures for a given matchday in all active franchise
          leagues
        </P>
      </ColoredContainer>
      <ContentContainerx3 BGColor={1}>
        <div style={{ height: "300px" }}>
          <BarChart
            DATA={RenderHistory(["FIXTURES"])}
            Settings={{
              indexBy: "date",
            }}
          />
        </div>
      </ContentContainerx3>
    </>
  );
};

const BarChartWicketsByFixture = ({ STATS }) => {
  const RenderHistory = (TYPE) => {
    const ASSETS = TYPE;
    const REMOVEWASHOUTS = RemoveWashouts(STATS.OverTheSeason);
    return [orderBy(REMOVEWASHOUTS, ["id"], ["asc"]), ASSETS];
  };
  return (
    <>
    <Container size="lg"  p={0}>
        <BLOCK_Section_Title
          OBJ={{
            LABEL: "Matchday Wickets Totals",
            ICON: "ChartDots",
            DESCRIPTION:
              "The combined wickets taken over a matchday in all active franchise leagues",
            TOOLBAR: false,
          }}
        />
      </Container>
     
      <Container size="lg"  p={0}>
        <VideoWizardContainer>
          <div style={{ height: "300px" }}>
            <BarChart
              DATA={RenderHistory(["WICKETS"])}
              Settings={{
                indexBy: "date",
              }}
            />
          </div>
        </VideoWizardContainer>
      </Container>
    </>
  );
};

const LineChartBowlingWicketAverages = ({ STATS }) => {
  const CreateData = (VAR) => {
    const ARR = [];
    const REMOVEWASHOUTS = RemoveWashouts(STATS.OverTheSeason);
    orderBy(REMOVEWASHOUTS, ["id"], ["asc"]).map((item, i) => {
      ARR.push({ x: item.date, y: item[VAR] });
    });
    return ARR;
  };

  const CreateSeasonBAttingAVG = () => {
    //console.log(STATS)
    const ARR = [],
      FIX = [],
      AVGRUN = [];
    const REMOVEWASHOUTS = RemoveWashouts(STATS.OverTheSeason);
    orderBy(REMOVEWASHOUTS, ["id"], ["asc"]).map((item, i) => {
      FIX.push(1);
      AVGRUN.push(item.AVGTOTALINNINGSWICKETS);
      ARR.push({
        x: item.date,
        y: AVGRUN.reduce((a, b) => a + b) / FIX.reduce((a, b) => a + b),
      });
    });
    return ARR;
  };

  const RenderLineData = () => {
    const OBJ = [
      {
        id: "Season",
        data: CreateSeasonBAttingAVG(),
      },{
        id: "Match Day",
        data: CreateData("AVGTOTALINNINGSWICKETS"),
      }
    ];

    return OBJ;
  };
  return (
    <>
    <Container size="lg"  p={0}>
        <BLOCK_Section_Title
          OBJ={{
            LABEL: "Matchday Wicket Averages and Seasonal Norms",
            ICON: "ChartDots",
            DESCRIPTION:
              "Average number of Wickets per Game against the Season average number of wickets per game",
            TOOLBAR: false,
          }}
        />
      </Container>

      <Container size="lg"  p={0}>
        <VideoWizardContainer>
          <div style={{ height: "400px" }}>
            <LineChart DATA={RenderLineData()} />
          </div>
        </VideoWizardContainer>
      </Container>
    </>
  );
};

const LineChartBowlingEconomyAverages = ({ STATS }) => {
  const CreateData = (VAR) => {
    const ARR = [];
    const REMOVEWASHOUTS = RemoveWashouts(STATS.OverTheSeason);
    orderBy(REMOVEWASHOUTS, ["id"], ["asc"]).map((item, i) => {
      ARR.push({ x: item.date, y: item[VAR] });
    });
    return ARR;
  };

  const CreateSeasonInningAVG = () => {
    //console.log(STATS)
    const ARR = [],
      FIX = [],
      AVGRUN = [];
    const REMOVEWASHOUTS = RemoveWashouts(STATS.OverTheSeason);
    orderBy(REMOVEWASHOUTS, ["id"], ["asc"]).map((item, i) => {
      FIX.push(1);
      AVGRUN.push(item.AVGBOWLINGECO);
      ARR.push({
        x: item.date,
        y: AVGRUN.reduce((a, b) => a + b) / FIX.reduce((a, b) => a + b),
      });
    });
    return ARR;
  };
  const CreateSeasonBAttingAVG = () => {
    //console.log(STATS)
    const ARR = [],
      FIX = [],
      AVGRUN = [];
    const REMOVEWASHOUTS = RemoveWashouts(STATS.OverTheSeason);
    orderBy(REMOVEWASHOUTS, ["id"], ["asc"]).map((item, i) => {
      FIX.push(1);
      AVGRUN.push(item.AVGBOWLINGSTRIKERATE);
      ARR.push({
        x: item.date,
        y: AVGRUN.reduce((a, b) => a + b) / FIX.reduce((a, b) => a + b),
      });
    });
    return ARR;
  };

  const RenderStrikeRateLineData = () => {
    const OBJ = [
      {
        id: "Season",
        data: CreateSeasonBAttingAVG(),
      },
      {
        id: "Match day",
        data: CreateData("AVGBOWLINGSTRIKERATE"),
      },
    ];

    return OBJ;
  };

  const RenderEconomyLineData = () => {
    const OBJ = [
      {
        id: "Season",
        data: CreateSeasonInningAVG(),
      },
      {
        id: "Match day",
        data: CreateData("AVGBOWLINGECO"),
      },
    ];

    return OBJ;
  };
  return (
    <>
      <Container size="lg"  p={0}>
        <BLOCK_Section_Title
          OBJ={{
            LABEL: "Strike Rates",
            ICON: "ChartDots",
            DESCRIPTION:
              "Bowling strike rate by Game days against the seaonal average",
            TOOLBAR: false,
          }}
        />
      </Container>

      <Container size="lg"  p={0}>
        <VideoWizardContainer>
          <div style={{ height: "400px" }}>
            <LineChart DATA={RenderStrikeRateLineData()} />
          </div>
        </VideoWizardContainer>
      </Container>

      <Container size="lg"  p={0}>
        <BLOCK_Section_Title
          OBJ={{
            LABEL: "Economy",
            ICON: "ChartDots",
            DESCRIPTION:
            "Bowling Economy by Game days against the seaonal average",
            TOOLBAR: false,
          }}
        />
      </Container>

      <Container size="lg"  p={0}>
        <VideoWizardContainer>
          <div style={{ height: "400px" }}>
            <LineChart DATA={RenderEconomyLineData()} />
          </div>
        </VideoWizardContainer>
      </Container>
    </>
  );
};
