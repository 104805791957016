import {gql,} from "@apollo/client";

export const QUERY = (APISETTINGS)=>{
    return gql`query POTD_Batting {
      watchLists(filters:{ id:{in: [${APISETTINGS.IDS}]} }){
        data{
          attributes{
            Name
          } 
        }
      }
        fixtures(
          pagination: { start: 0, limit: -1 }
          filters: {
            and: [
              {  
                Date:{
                  gte:"${APISETTINGS.Date[0]}"
                }
              },
              { 
                Date:{
                  lte:"${APISETTINGS.Date[1]}"
                }
              }
            ]
            hasResult: { eq: true }
            watch_list: { id: { in: [${APISETTINGS.IDS}] } } 
          } 
        ) { 
          data {
            id
            attributes {
              fixtureID
              UnixTime
              HomeTeamResult
              AwayTeamResult
              ResultStatement
              teams {
                data {
                  id
                  attributes {
                    Name
                    Logo {
                      data {
                        id
                        attributes {
                          
                          url
                        }
                      }
                    }
                  }
                }
              }
              player_battings(pagination: { start: 0, limit: -1 }) {
                data {
                  id
                  attributes {
                    BATTING_Runs
                    BATTING_Balls
                    BATTING_fours
                    BATTING_sixes
                    BATTING_SR
                    BATTING_HowOut
                    team {
                      data {
                        id
                        attributes {
                          Name
                          TeamID
                          Logo {
                            data {
                              id
                              attributes {
                                
                                url
                              }
                            }
                          }
                        }
                      }
                    }
                    player {
                      data {
                        id
                        attributes {
                          Name
                          WORLDRANKINGBATTING
                          TYPEBATTING
                          FIFTIES
                          BATTINGAVERAGE
                          PLAYERPROFILELEVEL
                          image {
                            data {
                              id
                              attributes {
                                url
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
      
            }
          }
        }
      } `
}
  
