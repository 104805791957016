import { useCurrentFrame } from "remotion";
import { TrimPlayerName } from "../../../../../../../actions/UX";

import { HONERABLELI, POTDUL } from "../../../../utils/html";
import { H3, H2, H1 } from "../../../../utils/Text";
import {
  HonerableImageBox,
  HONORABLEMENTIONS,
  SectionTitleContainer,
} from "../../../../utils/Containers";
import { SpringToFrom } from "../../../../../Animation/RemotionSpring";
import { interpolateOpacityByFrame } from "../../../../../Animation/interpolate";
// Components

export const HONORABLE = ({ DATA }) => {
  return (
    <HONORABLEMENTIONS>
      <SectionTitleContainer>
        <H1>honorable mentions</H1>
      </SectionTitleContainer>
      <POTDUL>
        {DATA.HONERABLE.map((arr, i) => {
          return <DisplayHonorable _PLAY={arr} _INT={i} />;
        })}
      </POTDUL>
    </HONORABLEMENTIONS>
  );
};

export const DisplayHonorable = ({ _PLAY, _INT }) => {
  const frame = useCurrentFrame();

  const POTDSTRING = _PLAY.BATTING_Runs + "(" + _PLAY.BATTING_Balls + ")";
  return (
    <HONERABLELI
      style={{
        opacity: interpolateOpacityByFrame(frame, 5 + _INT, 15 + _INT, 0, 1),
        transform: `translateY(${SpringToFrom(
          5 + _INT,
          150,
          0,
          "Springy100"
        )}px`,
      }}
    >
      <HonerableImageBox style={{ backgroundImage: `url(${_PLAY.Logo})` }} />
      <H3 style={{ textAlign: "left", marginLeft: "40px" }}>
        {TrimPlayerName(_PLAY.Name)}
      </H3>
      <H2 style={{ textAlign: "left", marginLeft: "40px" }}>
        {POTDSTRING} {_PLAY.BATTING_HowOut}
      </H2>
    </HONERABLELI>
  );
};
