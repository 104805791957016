import { useState } from "react";
import { ThemeProvider } from 'styled-components'



// Import Design Templates for MATCHDAYRESULT.
// Add new deisng patterns below
// GLASS
import {THISWEEKINLMS_ARROWS} from '../../theme/Templates/ARROWS/index'

// END


export const Build_THISWEEKINLMS_4by5 = (props)=>{
	const {RENDER} = props
	
	const TEMPLATES={
		"Arrows":<THISWEEKINLMS_ARROWS RENDER={RENDER} />, 
	} 
    return (     
		<ThemeProvider theme={RENDER.THEME.VideoTheme.Theme} >    
			{ 
				TEMPLATES[RENDER.THEME.VideoTemplate]    
			} 
		</ThemeProvider>     
    );    
}