import { useState } from "react";
import { ThemeProvider } from 'styled-components'
import {RemotionThemes} from '../../theme/themes'



// Import Design Templates for MATCHDAYRESULT.
// Add new deisng patterns below
// GLASS
import {POTDBATTING_GLASS} from '../../theme/Templates/GLASS/index'
import {POTDBATTING_ARROWS} from '../../theme/Templates/ARROWS/index'
//import {POTDBATTING_SQUARE} from '../../theme/Templates/SQUARE/index'
import {POTDBATTING_TOURNAMENT_GLASS} from '../../theme/Templates/TOURNAMENT_GLASS/index'
import {POTDBATTING_ROUNDED} from "../../theme/Templates/ROUNDED/index"
// END


export const Build_POTDBATTING_4by5 = (props)=>{
	const {DATA,RENDER} = props

	console.log(RENDER)
	const TEMPLATES={
		"Glass":<POTDBATTING_GLASS  DATA={DATA}  RENDER={RENDER}/>,
		"Arrows":<POTDBATTING_ARROWS  DATA={DATA} RENDER={RENDER}/>,
		"Rounded":<POTDBATTING_ROUNDED DATA={DATA} RENDER={RENDER}/>,
		"TOURNAMENT_GLASS":<POTDBATTING_TOURNAMENT_GLASS  DATA={DATA} RENDER={RENDER}/>,
	}      
    return (  
		<ThemeProvider theme={RENDER.THEME.VideoTheme.Theme} >  
			{
				TEMPLATES[RENDER.THEME.VideoTemplate] 
			}
		</ThemeProvider>   
    );   
}