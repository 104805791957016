import { ThemeProvider } from 'styled-components'
//import {RemotionThemes} from '../../theme/themes'



// Import Design Templates for MATCHDAYRESULT.
// Add new deisng patterns below
// GLASS
import {TOP5_4by5} from '../../theme/Templates/GLASS/index'
import {ARROWS_TOP5_4by5} from '../../theme/Templates/ARROWS/index'
import {TOURNAMENT_TOP5_4by5} from '../../theme/Templates/TOURNAMENT_GLASS/index'
// END


export const Build_LEADERBOARDSIXES_4by5 = (props)=>{
	const { RENDER } = props;
	//console.log(DATA)
	const TEMPLATES={
		"Glass":<TOP5_4by5  RENDER={RENDER} MainTitle='SIXES' Subtitle='TOP 5'/>,
		"Arrows":<ARROWS_TOP5_4by5   RENDER={RENDER} VARIABLE={'Sixes'}/>,
		"TOURNAMENT_GLASS":<TOURNAMENT_TOP5_4by5  RENDER={RENDER} MainTitle='Top 5 Most Sixes' VARIABLE='Sixes Hit'/>,    
	} 
    return (   
		<ThemeProvider theme={RENDER.THEME.VideoTheme.Theme}>
		{TEMPLATES[RENDER.THEME.VideoTemplate]}
	  </ThemeProvider> 
    );  
}


/* export const Build_LEADERBOARDSIXES_9by16 = ({DATA, Theme,Template='Glass',RenderType})=>{
	const [useTemplate, setTemplate] = useState(Template);
	const THEME = Theme?.attributes?.Theme === undefined ?Theme.Theme:Theme.attributes.Theme
	//console.log(DATA)
	const TEMPLATES={
		"Glass":<TOP5_9by16  DATA={DATA} RenderType={RenderType} MainTitle='SIXES' Subtitle='TOP 5'/> 
	} 
    return ( 
		<ThemeProvider theme={THEME} >  
			{
				TEMPLATES[useTemplate] 
			}
		</ThemeProvider> 
    );  
} */