import { useState } from "react";
import {  Modal, Group, Image } from "@mantine/core";
import { Carousel, useAnimationOffsetEffect } from "@mantine/carousel";
import { BTN_CREATE } from "../../Theme/Structure/Button/BTNS";

export const ModalGallery = ({ Gallery, BTNLABEL='Examples' }) => {
  const TRANSITION_DURATION = 200;
  const [opened, setOpened] = useState(false);
  const [embla, setEmbla] = useState(null);

  useAnimationOffsetEffect(embla, TRANSITION_DURATION);

  return (
    <>
      <Group position="center">
        <BTN_CREATE CALLBACK={() => setOpened(true)} LABEL={BTNLABEL} />
      </Group>
      <Modal
        opened={opened}
        size="md"
        padding={0}
        transitionDuration={TRANSITION_DURATION}
        withCloseButton={false}
        onClose={() => setOpened(false)}
      >
        <Carousel
          loop
          getEmblaApi={setEmbla}
          slideSize="100%"
          height={600}
          width={"auto"}
          orientation="vertical"
          slideGap={0}
          controlsOffset="xs"
          withIndicators
        >
          {Gallery.map((IMG, i) => {
            return (
              <Carousel.Slide key={i}>
                <Image src={IMG.attributes.url} height={600} />
              </Carousel.Slide>
            );
          })}
        </Carousel>
      </Modal>
    </>
  );
};
