import { interpolate, useCurrentFrame } from "remotion";
import { TrimPlayerName } from "../../../../../../actions/UX";
import { H1, H2, POTD_SR } from "../../../utils/Text";
import { useTheme } from "styled-components";
import styled from "styled-components";
import { SpringToFrom } from "../../../../Animation/RemotionSpring";
import { FromMiddle, FromBottomToTop } from "../../../../Animation/ClipWipe";

import {
  FullWidthWrapperStart,
  FrostedPOTD,
  ContainerBasicFlexColumn,
  POTDImageBox,
  POTDWRAPPER,
} from "../../../utils/Containers";

export const DisplayPOTD = ({ _PLAY, _Durations }) => {
  const frame = useCurrentFrame();

  const theme = useTheme();

  const ImgSize = interpolate(frame, [0, 180], [100, 110], {
    extrapolateRight: "clamp",
  });

  return (
    <>
      <FullWidthWrapperStart>
        <POTDWRAPPER
          style={{
            backgroundImage: `url(${_PLAY.IMAGE})`,
            backgroundSize: `${ImgSize}%`,
            transform: `
            translateY(${SpringToFrom(0, 1400, 0, "Springy100")}px)             
            translateY(${SpringToFrom(145, 100, -1300, "Springy100")}px)`,
          }}
        >
          <POTD_SR>Strike Rate : {_PLAY.BATTING_SR}</POTD_SR>
          <POTDImageBox
            style={{
              backgroundImage: `url(${_PLAY.Logo})`,
              position: "absolute",
              top: "100px",
              left: "10px",
              clipPath: FromBottomToTop(5, "Springy100"),
            }}
          />
          <FrostedPOTD
            style={{
              clipPath: FromBottomToTop(5, "Springy100"),
            }}
          >
            <ContainerBasicFlexColumn style={{ width: "100%" }}>
              <H1
                style={{
                  color: theme.Copy[0],
                  clipPath: FromMiddle(15, "Springy100"),
                }}
              >
                {TrimPlayerName(_PLAY.Name)}
              </H1>
              <H1
                style={{
                  color: theme.Copy[0],
                  clipPath: FromMiddle(20, "Springy100"),
                }}
              >
                {_PLAY.BATTING_Runs} ({_PLAY.BATTING_Balls}){" "}
                {_PLAY.BATTING_HowOut}
              </H1>
              <H2
                style={{
                  color: theme.Copy[0],
                  clipPath: FromMiddle(25, "Springy100"),
                }}
              >
                {_PLAY.Team}
              </H2>
            </ContainerBasicFlexColumn>
          </FrostedPOTD>
        </POTDWRAPPER>
        <Timer Durations={_Durations} />
      </FullWidthWrapperStart>
    </>
  );
};

//
const TimerContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 10px;
`;

const Timer = ({ Durations }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();
  //console.log(((frame/Durations.Length)*100))
  return (
    <TimerContainer
      style={{
        width: `${(frame / Durations.Close) * 100}%`,
        backgroundColor: theme.Base[2],
      }}
    ></TimerContainer>
  );
};
