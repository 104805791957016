import { useSelector } from "react-redux";
// Theme
import { H2, P_WARNING, H3, H4_UPPERCASE, P } from "../../../../Theme/type";
import { VideoWizardContainer } from "../../../../Theme/Structure/BodyContainer";
import { Grid, Group, Space, Stack, Table } from "@mantine/core";
import moment from "moment";
import {
  TABLE_TD,
  TABLE_THEAD_WRAPPER,
  TABLE_WRAPPER,
} from "../../../../Components/interface/Wrapper/Table";

export const DataCheck = () => {
  const RENDER = useSelector((state) => state.RENDER);

  const ConvertData = (FromDate) => {
    const d = new Date(`${FromDate}`);
    return moment(d).format("DD MMMM");
  };
  const ConvertTime = (FromDate) => {
    const TIME = FromDate.split(":");
    return `${TIME[0]}:${TIME[1]}`;
  };

  if (!RENDER.DATAOBJ.DATAOBJ.data) return <DataMissing />;
  return (
    <>
      <VideoWizardContainer>
        <Group position="apart">
          <H3 colorID={5} weight={900} size={25} letterspacing={-2}>
            {RENDER.DATAOBJ.DATAOBJ.HomeTeam}
          </H3>
          <H3 colorID={5} weight={900} size={25} letterspacing={-2}>
            {RENDER.DATAOBJ.DATAOBJ.HomeTeamResult}
          </H3>
        </Group>

        <Group position="apart">
          <H3 colorID={4} weight={900} size={25} letterspacing={-2}>
            {RENDER.DATAOBJ.DATAOBJ.AwayTeam}
          </H3>
          <H3 colorID={4} weight={900} size={25} letterspacing={-2}>
            {RENDER.DATAOBJ.DATAOBJ.AwayTeamResult}
          </H3>
        </Group>

        <P align="center">{RENDER.DATAOBJ.DATAOBJ.ResultStatement}</P>
      </VideoWizardContainer>
      <TeamDetails TEAM={RENDER.DATAOBJ.DATAOBJ.TeamA} />
      <TeamDetails TEAM={RENDER.DATAOBJ.DATAOBJ.TeamB} />
    </>
  );
};
//<FixtureMetaData VIDEODATA={VIDEODATA}/>

const TeamDetails = ({ TEAM }) => {
 
  return (
    <VideoWizardContainer>
      <H3 colorID={5} weight={900}>
        {TEAM.Name}
      </H3>

      <H3 weight={600}>Batting</H3>

      <TABLE_WRAPPER>
        <TABLE_THEAD_WRAPPER
          DISPLAY={["table-header-group", "None"]}
          HEADERS={[" ", "Runs", "Balls", "HowOut"]}
        />
        <tbody>
          {TEAM.BattingOrder.map((bat, i) => {
            return (
              <tr key={i}>
                <TABLE_TD>
                  {bat.attributes.player.data.attributes.Name}
                </TABLE_TD>
                <TABLE_TD>{bat.attributes.BATTING_Runs}</TABLE_TD>
                <TABLE_TD>{bat.attributes.BATTING_Balls}</TABLE_TD>
                <TABLE_TD>{bat.attributes.BATTING_HowOut}</TABLE_TD>
              </tr>
            );
          })}
        </tbody>
      </TABLE_WRAPPER>
      <H3 weight={600}>Bowling</H3>
      <TABLE_WRAPPER>
        <TABLE_THEAD_WRAPPER
          DISPLAY={["table-header-group", "None"]}
          HEADERS={[" ", "Wickets", "Runs", "Overs"]}
        />
        <tbody>
          {TEAM.BowlingOrder.map((bat, i) => {
            return (
              <tr key={i}>
                <TABLE_TD>
                  {bat.attributes.player.data.attributes.Name}
                </TABLE_TD>
                <TABLE_TD>{bat.attributes.BOWLING_Wkts}</TABLE_TD>
                <TABLE_TD>{bat.attributes.BOWLING_Runs}</TABLE_TD>
                <TABLE_TD>{bat.attributes.BOWLING_Overs}</TABLE_TD>
              </tr>
            );
          })}
        </tbody>
      </TABLE_WRAPPER>
    </VideoWizardContainer>
  );
};

const DataMissing = () => {
  return (
    <VideoWizardContainer>
      <P_WARNING>Player Data missing</P_WARNING>
      <P>
        Player data for this fixture is not available at this time. Verify this
        fixture in the Datacheck section of your franchise page.{" "}
      </P>
    </VideoWizardContainer>
  );
};
