import {
  Center,
  Grid,
  createStyles,
  Group,
  Container,
  Space,
  Title,
  Text,
} from "@mantine/core";
import styled from "styled-components";
import { SectionWrapper } from "../Components/Containers";
import { H1 } from "../Components/type";

const useStyles = createStyles((theme) => ({
  highlight: {
    color: theme.colors.Public[3],
  },
  title: {
    textAlign: "center",
    fontWeight: 100,
    fontSize: 40,
    letterSpacing: -1,
    color: theme.colors.Public[1],
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    "@media (max-width: 520px)": {
      fontSize: 28,
      textAlign: "left",
    },
  },
}));
export const IndexByLine002 = () => {
  const { classes, theme } = useStyles();
  return (
    <SectionWrapper>
      <Container
        sx={(theme) => ({
          "@media (min-width: 1200px)": {
            padding: " 2em 0",
          },
          "@media (max-width: 1200px) and (min-width: 800px)": {
            padding: " 2em 0",
          },
          "@media (max-width: 800px) and (min-width: 755px)": {
            padding: " 2em 0",
          },
          "@media (max-width: 755px) and (min-width: 576px)": {
            padding: " 2em 0",
          },
          "@media (max-width: 576px)": {
            padding: " 2em 0",
          },
        })}
      >
        <Title className={classes.title}>
          Highlight{" "}
          <Text component="span" className={classes.highlight} inherit>
            quality weekly performances
          </Text>{" "}
          creating exposure for seasoned players and visual awareness for new
          players
        </Title>
      </Container>
    </SectionWrapper>
  );
};
