/* eslint-disable react/jsx-pascal-case */
import { useSelector } from "react-redux";
import { useFindMissingWatchList } from "../../Hooks/Auth/useFindNullFixtures";

import { useEffect, useState } from "react";

import { BLOCK_Section_SubHeading } from "../../Components/interface/BLOCK_Section_SubHeading";
import { UISkeletonLines } from "../../Components/Global/Skeleton";

import { AccessDenied } from "../../Components/interface/AccessDenied";
import { BTN_CTA } from "../../Theme/Structure/Button/BTNS";
import { BLOCK_ToolBar } from "../../Components/interface/BLOCK_Toolbar";
import { VideoWizardContainer } from "../../Theme/Structure/BodyContainer";
import { P } from "../../Theme/type";

export const FindMissingWatchList = () => {
  const [MissingWatchList, DeleteMissingWatchList] = useFindMissingWatchList();
  const [isSyncing, setSync] = useState(false);

  //const UXSTATE = useSelector((state) => state.UXSTATE);
  const AUTH = useSelector((state) => state.AUTH);
  //***********************  */ Find Missing Watch List
  const FindMissingWatchList = () => {
    //console.log('Find Null OBJS');
    DeleteMissingWatchList();
    setSync(true);
  };
  useEffect(() => {
    console.log(MissingWatchList);
    if (MissingWatchList !== null) {
      setSync(false);
    }
    setSync(false);
  }, [MissingWatchList]);

  if (isSyncing) return <UISkeletonLines />;
  if (AUTH.AUTHUSER.id !== 1) return <AccessDenied />;
  return (
    <VideoWizardContainer>
      <BLOCK_Section_SubHeading LABEL="Find Fixtures with No Home" Color={5} />
      <P>
        Find and remove all Fixtures that are not associated with a Watch List
      </P>
      <BLOCK_ToolBar
        TOOLS={[
          <BTN_CTA
            CALLBACK={FindMissingWatchList}
            LABEL={"Remove Homeless Fixtures"}
          />,
        ]}
      />
    </VideoWizardContainer>
  );
};
