import { useEffect, useState } from "react";
import { SelectADataSet } from "../CreateVideoComponents/SelectADataset";
import { SelectDateRange } from "../CreateVideoComponents/SelectDateRange";
import { pick } from "lodash";
import { SelectAPlayer } from "../CreateVideoComponents/SelectAPlayer";
import { SelectAGameForPlayerOfTheMatch } from "../CreateVideoComponents/SelectAGameFormPlayerOfTheMatch";
import { SelectLIVEGame } from "../CreateVideoComponents/SelectAGame";
import { SelectAnActiveDivision } from "../CreateVideoComponents/SelectAnActiveDivision";
import { SelectATeamFromDivision } from "../CreateVideoComponents/SelectATeamFromDivision";

export const DataCollection_FixturesOverDateRange = ({ setINPUTDATA }) => {
  const [Dataset, setDataset] = useState(false);
  const [FromDate, setFromDate] = useState(false);

  useEffect(() => {
    //setINPUTDATA([Dataset,FromDate])

    //console.log(pick(Dataset.attributes, ["Name", "watch_lists"]));
    setINPUTDATA({
      DATASET: pick(Dataset.attributes, ["Name", "watch_lists"]),
      DATERANGE: FromDate,
    });
  }, [Dataset, FromDate]);

  return (
    <>
      <SelectADataSet
        setDataset={setDataset}
        SelectedDataset={Dataset?.attributes?.Name}
      />
      , 
      <SelectDateRange setFromDate={setFromDate} FromDate={FromDate} />
    </>
  );
};

export const DataCollection_OnlyDataset = ({ setINPUTDATA }) => {
  const [Dataset, setDataset] = useState(false);
  //const [FromDate, setFromDate] = useState(false);

  useEffect(() => {
    //console.log(Dataset);
    if (Dataset) {
      setINPUTDATA({
        DATASET: pick(Dataset.attributes, ["Name", "watch_lists"]),
      });
    }
  }, [Dataset]);

  return (
    <>
      <SelectADataSet
        setDataset={setDataset}
        SelectedDataset={Dataset?.attributes?.Name}
      />
    </>
  );
};

export const DataCollection_OnlyDateRange = ({ setINPUTDATA }) => {
  const [Dataset, setDataset] = useState(false);
  const [FromDate, setFromDate] = useState(false);

  useEffect(() => {
    //setINPUTDATA([Dataset,FromDate])

    //console.log(pick(Dataset.attributes, ["Name", "watch_lists"]));
    setINPUTDATA({
      DATASET: pick(Dataset.attributes, ["Name", "watch_lists"]),
      DATERANGE: FromDate,
    });
  }, [Dataset, FromDate]);

  return (
    <>
      <SelectDateRange setFromDate={setFromDate} FromDate={FromDate} />
    </>
  );
};

export const DataCollection_SelectSinglePlayer = ({ setINPUTDATA }) => {
  const [usePlayer, setusePlayer] = useState(false);
  useEffect(() => {
    //console.log(usePlayer);
    setINPUTDATA({
      usePlayer: usePlayer,
    });
  }, [usePlayer]);

  return (
    <>
      <SelectAPlayer setusePlayer={setusePlayer} />
    </>
  );
};

export const DataCollection_SinglerGamePlayerMOM = ({ setINPUTDATA }) => {
  const [Dataset, setDataset] = useState(false);
  const [FromDate, setFromDate] = useState(false);
  const [SelectGame, setSelectGame] = useState(false);
  useEffect(() => {
    //setINPUTDATA([Dataset,FromDate])

    //console.log(pick(Dataset.attributes, ["Name", "watch_lists"]));
    setINPUTDATA({
      DATASET: pick(Dataset.attributes, ["Name", "watch_lists"]),
      SelectGame: SelectGame?.id,
    });
  }, [Dataset, SelectGame]);

  return (
    <>
      <SelectADataSet
        setDataset={setDataset}
        SelectedDataset={Dataset?.attributes?.Name}
      />
      ,
      <SelectAGameForPlayerOfTheMatch
        setSelectGame={setSelectGame}
        SelectedGame={SelectGame}
        SelectedDataset={Dataset}
      />
    </>
  );
};

export const DataCollection_SelectGameoftheWeek = ({ setINPUTDATA }) => {
  const [Dataset, setDataset] = useState(false);
  const [SelectGame, setSelectGame] = useState(false);
  const [SelectWinner, setSelectWinner] = useState(false);
  const [SelectIsLiveStreamed, setSelectIsLiveStreamed] = useState(null);
  useEffect(() => {
    //setINPUTDATA([Dataset,FromDate])

    //console.log(pick(Dataset.attributes, ["Name", "watch_lists"]));
    setINPUTDATA({
      DATASET: pick(Dataset.attributes, ["Name", "watch_lists"]),
      SelectGame: SelectGame?.id,
      SelectWinner: SelectWinner,
      SelectIsLiveStreamed: SelectIsLiveStreamed,
    });
  }, [Dataset, SelectGame, SelectWinner, SelectIsLiveStreamed]);

  return (
    <>
      <SelectADataSet 
        setDataset={setDataset}
        SelectedDataset={Dataset?.attributes?.Name}
      />

      <SelectLIVEGame
        setSelectGame={setSelectGame}
        SelectedGame={SelectGame}
        SelectedDataset={Dataset}
        SelectWinner={SelectWinner}
        setSelectWinner={setSelectWinner}
        SelectIsLiveStreamed={SelectIsLiveStreamed}
        setSelectIsLiveStreamed={setSelectIsLiveStreamed}
      />
    </>
  );
};

export const DataCollection_SelectCompletedGame = ({ setINPUTDATA }) => {
  const [Dataset, setDataset] = useState(false);
  const [SelectGame, setSelectGame] = useState(false);

  useEffect(() => {
    //setINPUTDATA([Dataset,FromDate])

    //console.log(pick(Dataset.attributes, ["Name", "watch_lists"]));
    setINPUTDATA({
      DATASET: pick(Dataset.attributes, ["Name", "watch_lists"]),
      SelectGame: SelectGame?.id,
    });
  }, [Dataset, SelectGame]);

  return (
    <>
      <SelectADataSet
        setDataset={setDataset}
        SelectedDataset={Dataset?.attributes?.Name}
      />

      <SelectAGameForPlayerOfTheMatch
        setSelectGame={setSelectGame}
        SelectedGame={SelectGame}
        SelectedDataset={Dataset}
      />
    </>
  );
};


export const DataCollection_SelectTeamFromDivision = ({ setINPUTDATA }) => {
  const [SelectedDivision, setSelectedDivision] = useState(false);
  const [SelectedQueryDivision, setSelectedQueryDivision] = useState(false);
  const [SelectedTeam, setSelectedTeam] = useState(false);

  useEffect(() => {
    //console.log(SelectedDivision, SelectedTeam);
    setINPUTDATA({
      SelectedDivision: SelectedDivision,
      SelectedTeam: SelectedTeam,
    });
  }, [SelectedDivision, SelectedTeam]);

  return (
    <>
      <SelectAnActiveDivision
        setSelectedDivision={setSelectedDivision}
        
        SelectedDivision={SelectedDivision?.attributes?.Name}

        setSelectedQueryDivision={setSelectedQueryDivision}
        SelectedQueryDivision={SelectedQueryDivision}
      /> 
 
      <SelectATeamFromDivision
        Division={SelectedDivision}
        SelectedTeam={SelectedTeam}
        setSelectedTeam={setSelectedTeam}
      />
    </>
  );
};
