import { useEffect, useState } from "react";

import { ENV } from "../../Config/RunTimeConfig";
import { parseCookies } from "nookies";

// FUNC : GET
export const useGetHowTos = (ctx) => {
  const [HowTos, setHowTos] = useState(null);
  const jwt = parseCookies(ctx).jwt;

  const GetHowTos = async () => {
    const res = await fetch(`${ENV.API_URL}/api/how-tos`, {
      headers: { Authorization: `Bearer ${jwt}` },
    });
    const HowTosResponse = await res.json();
  //console.log(HowTosResponse);
    setHowTos(HowTosResponse.data);
  };

  useEffect(() => {}, [HowTos]);

  return [HowTos, GetHowTos];
};
