import { useEffect, useState } from "react";
import { useAuthNavigation } from "../../Hooks/Auth/useAuthNavigation";
import ReactMarkdown from "react-markdown";

import { Header, P } from "../../Theme/type";
import { BodyContainer } from "../../Theme/Structure/BodyContainer";
import { ICONDB } from "../../Theme/icons";
import { filter } from "lodash";
import { Grid, Image, Select, Space, Divider, MediaQuery } from "@mantine/core";

import { find } from "lodash";
import { UILargeComponent } from "../Global/Skeleton";
import MDstyle from "../../markdown-styles.module.css";

import { BLOCK_ToolBar } from "./BLOCK_Toolbar";

export const ProductHeader = ({ NavPosition, setOrderbyValue }) => {
  const [Navigation, FetchNavigation] = useAuthNavigation();
  const [NavItem, setNavItem] = useState(false);

  useEffect(() => {
    FetchNavigation();
  }, []);
  useEffect(() => {
    if (Navigation != false) {
      setNavItem(
        filter(Navigation, (o) => {
          return o.attributes.Name === NavPosition;
        })[0].attributes
      );
    }
  }, [Navigation]);

  if (!NavItem) return <UILargeComponent />;
  return (
    <BodyContainer>
      <Grid grow gutter="xs" justify="center" align="flex-start">
        <Grid.Col span={3} offset={0}>
          <Header>
            {ICONDB[NavItem.Icon].Component} {NavItem.Name}
          </Header>

         {/*  <ReactMarkdown className={MDstyle.reactMarkDown}>
            {NavItem.Description}
          </ReactMarkdown> */}

          <Space h="md" />
        </Grid.Col>
        <Grid.Col span={2}>
          {/* <Image
            src={NavItem.NavPoster?.data?.attributes.url}
            width="auto"
            height={250}
          /> */}
        </Grid.Col>
      </Grid>
      <Divider my="sm" />
      <BLOCK_ToolBar
        TOOLS={[<OrderProducts setOrderbyValue={setOrderbyValue} />]}
      />
    </BodyContainer>
  );
};

const OrderProducts = ({ setOrderbyValue }) => {
  // Filter: Sort by NEW,Popular,Alpha
  const [value, setValue] = useState("");

  const OPTIONS = [
    {
      OBJ: { Key: "attributes.Name", DIR: "asc", Label: "Name" },
      value: "Name",
      label: "Name",
    },
    {
      OBJ: {
        Key: "attributes.orders.data.length",
        DIR: "desc",
        Label: "Popularity",
      },
      value: "Popularity",
      label: "Popularity",
    },
    {
      OBJ: {
        Key: "attributes.updatedAt",
        DIR: "desc",
        Label: "Recently Updated",
      },
      value: "Recently Updated",
      label: "Recently Updated",
    },
  ];

  const handleChange = (e) => {
    //console.log(find(OPTIONS,(o)=>{ return o.label === e}))
    setValue(e);
    setOrderbyValue(
      find(OPTIONS, (o) => {
        return o.label === e;
      }).OBJ
    );
  };
  useEffect(() => {}, [value]);
  return (
    <Select
      label=""
      placeholder="Order By"
      onChange={handleChange}
      value={value} 
      data={OPTIONS}
      styles={(theme) => ({
        input:{
          '&, &:focus':{
            borderColor: theme.colors.brand[5],
            color: theme.colors.brand[5]
          }
        },
        item: {
          // applies styles to selected item
          '&[data-selected], &:focus': {
            '&, &:hover, &:focus': {
              backgroundColor:theme.colors.brand[3],
              color: theme.colors.brand[4],
            },
          },

          // applies styles to hovered item (with mouse or keyboard)
          '&[data-hovered], :focus': {
            backgroundColor:theme.colors.brand[5],
            color: theme.colors.brand[0],
          },
        },
      })}
    />
  );
};

export const PageHeader = ({ NavPosition }) => {
  const [Navigation, FetchNavigation] = useAuthNavigation();
  const [NavItem, setNavItem] = useState(false);

  useEffect(() => {
    FetchNavigation();
  }, []);
  useEffect(() => {
    console.log(Navigation)
    if (Navigation != false) {
      setNavItem(
        filter(Navigation, (o) => {
          return o.attributes.Name === NavPosition;
        })[0].attributes
      );
    }
  }, [Navigation]);

  //console.log(NavItem)

  if (!NavItem) return <UILargeComponent />;
  return (
    <BodyContainer>
      <Grid grow gutter="xs" justify="center" align="flex-start">
        <Grid.Col
          xs={11}
          lg={6}
          sx={(theme) => ({
            "@media (min-width: 1200px)": {
              paddingLeft: "8%",
            },
            "@media (max-width: 576px)": {
              paddingLeft: "0%",
            },
          })}
        >
          <Header>
            {NavItem.Name} {ICONDB[NavItem.Icon].Component}{" "}
          </Header>

          <ReactMarkdown className={MDstyle.reactMarkDown}>
            {NavItem.Description}
          </ReactMarkdown>
          <Divider my="sm" />
          <Space h="md" />
        </Grid.Col>

        <MediaQuery smallerThan="lg" styles={{ display: "none" }}>
          <Grid.Col xs={0} md={2}>
            <Image
              src={NavItem.NavPoster.data.attributes.url}
              width="auto"
              height={130}
            />
          </Grid.Col>
        </MediaQuery>
      </Grid>
    </BodyContainer>
  );
};
