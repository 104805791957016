import { orderBy } from "lodash";
// CORE Components
import { SupImages } from "./SuplimentaryImages";
import { StatContainer } from "./StatContainer";

import { COPY, EMPASISVALUE } from "./Copy";

export const SectionBattingTeamScores = ({ DATA, OBJ }) => {
  const IMAGES = orderBy(
    DATA.DATAOBJ.TeamStats.PLAYERS,
    ["BATTING_50"],
    ["desc"]
  ).slice(0, 5);

  return (
    <>
      <Sentence
        DURATION={OBJ.DURATION}
        Name={DATA.DATAOBJ.General.Team}
        Runs={DATA.DATAOBJ.TeamStats.BATTING.RUNS}
        BALLS={DATA.DATAOBJ.TeamStats.BATTING.BALLS}
        Games={DATA.DATAOBJ.General.Played}
        TotalInnings={DATA.DATAOBJ.TeamStats.BATTING.WICKETS.length}
        WicketsLost={DATA.DATAOBJ.TeamStats.BATTING.WICKETS.reduce((a, b) => a + b, 0)}
      />

      <StatContainer 
        LABEL={`BATTING`}
        DURATION={OBJ.DURATION}
        TIMINGS={{
          SlideIn: 0,
          LabelPosition: 100,
          ClipIn: 15,
          SlideUp: 30,
        }}
      >
        <SupImages
          DATA={IMAGES}
          DURATION={OBJ.DURATION}
          Limit={5}
          VAR={"BATTING_50"}
          Label="Top 5  Fifties"
        />
      </StatContainer>
    </>
  );
};

const Sentence = ({
  Runs,
  Games,
  WicketsLost,
  BALLS,
  DURATION,
}) => {
  return (
    <>
      <COPY DURATION={DURATION}>
        THEIR AVERAGE INNINGS TOTAL WAS<br />{" "}
        <EMPASISVALUE VALUE={(Runs / Games).toFixed(1)} />
        <EMPASISVALUE VALUE={`/`} />
        <EMPASISVALUE VALUE={(WicketsLost / Games).toFixed(1)} /> <br />
        facing an average of {(BALLS / Games).toFixed(0)} balls or {((BALLS / Games) / 5).toFixed(1)} overs  per game.
      </COPY>
    </>
  );
};