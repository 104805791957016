import { useEffect, useRef, useState } from "react";

import {
  Container,
  ScrollArea,
  BackgroundImage,
  Space,
  Center,
  SimpleGrid,
  Box,
  createStyles,
  Divider,
  Badge,
} from "@mantine/core";

import { UISkeletonLines } from "../../../Components/Global/Skeleton";
import moment from "moment";

// UI ELEMENTS
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";

import { H3, P, S } from "../../../Theme/type";
import { useGetMostRecentOrders } from "../../../Hooks/Content/useOrders";
import { CALLBACKIcon } from "../../../Theme/Structure/Button/ActionIcons";
import { PreviewVideoModal } from "../../../Components/Global/PlayVideoModal";
import {
  TABLE_TD,
  TABLE_THEAD_WRAPPER,
  TABLE_WRAPPER,
} from "../../../Components/interface/Wrapper/Table";
import { AssetQuickLink } from "./AssetQuickLink";
import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";

const ICON_SIZE = 60;
const useStyles = createStyles((theme) => ({
  card: {
    position: "relative",
    overflow: "visible",
    padding: theme.spacing.xl,
    paddingTop: theme.spacing.xl * 1.5 + ICON_SIZE / 3,
  },

  icon: {
    position: "absolute",
    top: -ICON_SIZE / 3,
    left: `calc(50% - ${ICON_SIZE / 2}px)`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
  },
}));

export const RecentlyCreatedAsset = () => {
  const [orders, GetOrders] = useGetMostRecentOrders();
  const [opened, setOpened] = useState(false);
  const [videoURL, setVideoURL] = useState(false);
  const { classes } = useStyles();

  useEffect(() => {
    GetOrders();
  }, []);

  useEffect(() => {
    console.log(orders);
  }, [orders]);

  if (orders === null) return <UISkeletonLines />;
  return (
    <>
      <Space h={10} />
      <ScrollArea style={{ height: 400 }} type="auto" offsetScrollbars>
        <TABLE_WRAPPER>
          <tbody>
            {orders.map((order, i) => {
              return (
                <TableROW
                  item={order}
                  setOpened={setOpened}
                  D
                  key={i}
                  setVideoURL={setVideoURL}
                />
              );
            })}
          </tbody>
        </TABLE_WRAPPER>
      </ScrollArea>
      <PreviewVideoModal
        setOpened={setOpened}
        opened={opened}
        videoURL={videoURL}
      />
    </>
  );
};

const TableROW = ({ item, setOpened, setVideoURL }) => {
  const inputRef = useRef(null);

  const SetVideoModal = (Item) => {
    setOpened(true);
    setVideoURL(Item);
  };

  const DaysFrom = (FromDate) => {
    const ARR = FromDate.toString().split(",");
    const d = new Date(`${ARR[0]}`);
    var a = moment(d);
    var b = moment(new Date());
    const HoursAgo = b.diff(a, "hours");

    return HoursAgo < 24
      ? `${HoursAgo} Hours ago`
      : `${b.diff(a, "days")} days ago`;
  };

  return (
    <tr>
      <TABLE_TD> {item?.attributes.asset.data?.attributes.Name}</TABLE_TD>
      <TABLE_TD> {item.attributes.Franchise}</TABLE_TD>
      <TABLE_TD> {DaysFrom(item.attributes.createdAt)}</TABLE_TD>

      <TABLE_TD>
    
        {item?.attributes.render_lists.data[0]?.attributes.hasBeenProcessed ? (
          <CALLBACKIcon
            ref={inputRef}
            TIPOBJ={{ LABEL: "Preview", COLOR: "orange" }}
            ICON={`PlayerPlay`}
            CALLBACK={() => {
              SetVideoModal(
                item.attributes.render_lists.data[0].attributes.DownloadURI
              );
            }}
          />
        ) : (
          "Processing..."
        )}
      </TABLE_TD>
    </tr>
  );
};

const OrderItem = ({ item, setOpened, setVideoURL }) => {
  const inputRef = useRef(null);

  const SetVideoModal = (Item) => {
    setOpened(true);
    setVideoURL(Item);
  };

  const DaysFrom = (FromDate) => {
    const ARR = FromDate.toString().split(",");
    const d = new Date(`${ARR[0]}`);
    var a = moment(d);
    var b = moment(new Date());
    const HoursAgo = b.diff(a, "hours");

    return HoursAgo < 24
      ? `${HoursAgo} Hours ago`
      : `${b.diff(a, "days")} days ago`;
  };

  return (
    <>
      <BackgroundImage
        src={
          item.attributes.asset.data.attributes.HeaderImage.data[0].attributes
            .url
        }
        radius="sm"
        p={"md"}
        sx={(theme) => ({
          filter: "blur(3px)",
          opacity: "0.1",
          height: "100%",
          zIndex: 0,
          position: "absolute",
        })}
      />
      <Box
        p={"md"}
        sx={(theme) => ({
          height: "100%",
          zIndex: 100,
          position: "relative",
        })}
      >
        <Badge
          variant="filled"
          size="xs"
          sx={(theme) => ({
            backgroundColor: theme.colors.brand[4],
            marginLeft: "3px",
            position: "absolute",
            top: "5px",
            right: "5px",
          })}
        >
          {DaysFrom(item.attributes.createdAt)}
        </Badge>
        <Space h="lg" />
        <H3
          colorID={5}
          size="xl"
          align="center"
          weight={900}
          transform="uppercase"
        >
          {item.attributes.asset.data.attributes.Name}
        </H3>
        <P
          colorID={3}
          size="sm"
          weight={100}
          align="center"
          transform="uppercase"
        >
          Created by LMS {item.attributes.Franchise}
        </P>
        <Space h="lg" />
        <Center>
          {item.attributes.render_lists.data[0].attributes.hasBeenProcessed ? (
            <CALLBACKIcon
              ref={inputRef}
              TIPOBJ={{ LABEL: "Preview", COLOR: "orange" }}
              ICON={`PlayerPlay`}
              CALLBACK={() => {
                SetVideoModal(
                  item.attributes.render_lists.data[0].attributes.DownloadURI
                );
              }}
            />
          ) : (
            "Processing..."
          )}
        </Center>
      </Box>
    </>
  );
};
