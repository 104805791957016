import {
  Sequence,
  useCurrentFrame,
  useVideoConfig,
  spring,
  Series,
} from "remotion";
import { useTheme } from "styled-components";
// Components
import { LargeLabel } from "../../Components/LeagueLabel";

import { FrostedItem } from "../../utils/Containers";

/* import {FixtureResultsUL} from "./FixtureResultsUL" */
import {
  FullWidthWrapper,
  FullWidthWrapperStart,
  Top5InnerContainer,
  ValueLabel,
  PositionContainer,
  PlayerImage,
  Top5TeamLogo,
  NameContainer, 
} from "../../utils/Containers";

import { H1 } from "../../utils/Text";
import { FindIMGFORMAT } from "../../../../../actions/UX";
import { BodySponsorSequence } from "../../Components/TopandTail/TOP_Sponsor_Sequence";
import { interpolateOpacityByFrame } from "../../../Animation/interpolate";

const SpringToFrom = (int, FROM, TO, effect = "Default") => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  return spring({
    fps: fps / 2,
    from: FROM,
    to: TO,
    frame: frame - int,
    config: {
      damping: 10,
      stiffness: 55,
      mass: 0.9,
    },
  });
};

export const TOP5_EXTRAMETA_BODY_45 = ({
  DATA,
  Subtitle,
  MainTitle,
  InfoTitle,
}) => {
  /*  const M = measureSpring({
        fps: 15,
        config:{ 
            damping: 10,
            stiffness: 55,
            mass: 0.9,
        }
      }) */

  const Timing = {
    Intro: 60,
    Item: 120,
    Out: 30,
    DelayIn: 15,
    ItemTotal: 60 + 30 + 120,
    Total: function (i) {
      return this.ItemTotal * i;
    },
  };

  return (
    <>
      <FullWidthWrapper>
        <LargeLabel LABEL={`${Subtitle} ${MainTitle}`} />
        <FullWidthWrapperStart>
          {DATA.DATAOBJ.CATEGORY.map((key, i) => {
            //console.log(key)
            return (
              <Sequence
                from={Timing.Total(i)}
                durationInFrames={Timing.ItemTotal}
                id="Player"
                key={i}
              >
                <FullWidthWrapperStart>
                  <Series id="Player">
                    <Series.Sequence durationInFrames={Timing.Intro} id="Intro">
                      <AnimateIN Timing={Timing} INT={i} />
                    </Series.Sequence>

                    <Series.Sequence
                      durationInFrames={Timing.Item}
                      id="PlayerItem"
                    >
                      <DisplayStat
                        Timing={Timing}
                        INT={i}
                        NAME={key.Name}
                        VARIABLE={key.VARIABLE}
                        IMG={key.IMAGE}
                        LOGO={
                          FindIMGFORMAT(
                            key.Team.Logo.data.attributes.formats,
                            "Team"
                          ).url
                        }
                        TEAM={key.Team.Name}
                        POSITION={5 - i}
                        MainTitle={MainTitle}
                        InfoTitle={InfoTitle}
                        METADATA={key}
                      />
                    </Series.Sequence>

                    <Series.Sequence durationInFrames={Timing.Out} id="Outro">
                      <AnimateOut
                        Timing={Timing}
                        INT={i}
                        NAME={key.Name}
                        TEAM={key.Team.Name}
                        VARIABLE={key.VARIABLE}
                        IMG={key.IMAGE}
                        LOGO={
                          FindIMGFORMAT(
                            key.Team.Logo.data.attributes.formats,
                            "Team"
                          ).url
                        }
                        POSITION={5 - i}
                        MainTitle={MainTitle}
                        InfoTitle={InfoTitle}
                        METADATA={key}
                      />
                    </Series.Sequence>
                  </Series>
                </FullWidthWrapperStart>
              </Sequence>
            );
          })}

          {!DATA.Sponsor  ? ( 
            false
          ) : (
            <BodySponsorSequence DATA={DATA} />
          )}
        </FullWidthWrapperStart>
      </FullWidthWrapper>
    </>
  );
};

/* TOP 5 VIDEO SECTIONS */

const AnimateIN = ({ Timing, INT }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();
  //console.log(Timing.ItemTotal*INT, INT)
  return (
    <Top5InnerContainer>
      <H1
        style={{
          width: "100%",

          fontSize: "30em",
          zIndex: 1000,
          opacity: interpolateOpacityByFrame(
            frame,
            Timing.DelayIn - 7,
            Timing.DelayIn,
            0,
            1
          ),
          transform: `scale(${SpringToFrom(
            Timing.Intro - Timing.DelayIn,
            1,
            0
          )})`,
          color: theme.Copy[0],
        }}
      >
        {5 - INT}
      </H1>
      <FrostedItem
        style={{
          opacity: interpolateOpacityByFrame(frame, 0, Timing.DelayIn, 0, 1),
          transform: `scale(${SpringToFrom(0, 0, 1)})`,
          display: "flex",
          alignItems: "center",
          minWidth: "500px",
          position: "absolute",
          width: `500px`,
          height: `500px`,
          bottom: `380px`,
          borderRadius: `100%`,
          padding: 0,
          zIndex: 100,
          boxShadow: "0px 50px 80px rgba(0, 0, 0, 0.25)",
        }}
      />
    </Top5InnerContainer>
  );
};

const DisplayStat = ({
  Timing,
  INT,
  VARIABLE,
  NAME,
  TEAM,
  IMG,
  LOGO,
  POSITION,
  MainTitle,
  InfoTitle,
  METADATA,
}) => {
  const frame = useCurrentFrame();
  const theme = useTheme();

  //console.log(InfoTitle[0].Label)
  return (
    <Top5InnerContainer>
      <ValueLabel
        style={{ width: `${SpringToFrom(Timing.DelayIn, 0, 450)}px` }}
      >
        <H1
          style={{
            opacity: interpolateOpacityByFrame(
              frame,
              Timing.DelayIn,
              Timing.DelayIn * 2,
              0, 
              1
            ),
            width: "100%",
            color: "white",
            fontWeight: 400,
            fontSize: "3em",
          }}
        >
          {" "}
          {InfoTitle[0].Label}: {METADATA[InfoTitle[0].Key]}
        </H1>
      </ValueLabel>

      <ValueLabel
        style={{
          top: "250px",
          width: `${SpringToFrom(Timing.DelayIn, 0, 450)}px`,
        }}
      >
        <H1
          style={{
            opacity: interpolateOpacityByFrame(
              frame,
              Timing.DelayIn,
              Timing.DelayIn * 2,
              0,
              1
            ),
            width: "100%",
            color: "white",
            fontWeight: 400,
            fontSize: "3em",
          }}
        >
          {" "}
          {InfoTitle[1].Label}: {METADATA[InfoTitle[1].Key]}
        </H1>
      </ValueLabel>
      <ValueLabel
        style={{
          left: "0",
          width: `${SpringToFrom(Timing.DelayIn, 0, 450)}px`,
        }}
      >
        <H1
          style={{
            opacity: interpolateOpacityByFrame(
              frame,
              Timing.DelayIn,
              Timing.DelayIn * 2,
              0,
              1
            ),
            width: "100%",
            color: "white",
            fontWeight: 400,
            fontSize: "3em",
          }}
        >
          {InfoTitle[2].Label}: {METADATA[InfoTitle[2].Key]}
        </H1>
      </ValueLabel>
      <FrostedItem
        style={{
          display: "flex",
          alignItems: "center",
          width: `${SpringToFrom(0, 500, 700, "fast")}px`,
          height: `${SpringToFrom(0, 500, 150, "fast")}px`,
          position: "absolute",
          bottom: `${SpringToFrom(0, 300, 50, "fast")}px`,
          borderRadius: `${SpringToFrom(0, 100, 2, "fast")}%`,
          flexDirection: "column",
          padding: 0,
          zIndex: 100,
          boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.25)",
        }}
      />

      <PlayerImage
        style={{
          backgroundImage: `url(${IMG})`,
          clipPath: `polygon(0 ${SpringToFrom(
            0,
            50,
            100
          )}%, 100% ${SpringToFrom(0, 50, 100)}%, 100% ${SpringToFrom(
            0,
            50,
            0
          )}%, 0 ${SpringToFrom(0, 50, 0)}%)`,
        }}
      />

      <Top5TeamLogo
        style={{
          backgroundImage: `url(${LOGO})`,
          opacity: interpolateOpacityByFrame(frame, 0, Timing.DelayIn, 0, 1),
        }}
      />

      <PositionContainer
        style={{
          height: `${SpringToFrom(0, 0, 250)}px`,
          opacity: interpolateOpacityByFrame(frame, 0, Timing.DelayIn, 0, 1),
        }}
      >
        <H1
          style={{
            opacity: interpolateOpacityByFrame(frame, 0, Timing.DelayIn, 0, 1),
            color:  theme.Copy[0],
            fontSize: "7em",
            marginTop: "-100px",
          }}
        >
          {POSITION}
        </H1>
      </PositionContainer>

      <NameContainer>
        <H1
          style={{
            opacity: interpolateOpacityByFrame(frame, 0, Timing.DelayIn, 0, 1),
            width: "100%",
            color: theme.Copy[0],
            fontWeight: 800,
            lineHeight: 0,
          }}
        >
          {NAME}
        </H1>
        <H1
          style={{
            opacity: interpolateOpacityByFrame(frame, 0, Timing.DelayIn, 0, 1),
            width: "100%",
            color: theme.Copy[0],
            fontWeight: 800,
            lineHeight: 0, 
          }}
        >
          {TEAM}
        </H1>
      </NameContainer>
    </Top5InnerContainer>
  );
};

const AnimateOut = ({
  Timing,
  INT,
  VARIABLE,
  NAME,
  TEAM,
  IMG,
  LOGO,
  POSITION,
  MainTitle,
  InfoTitle,
  METADATA,
}) => {
  const frame = useCurrentFrame();
  const theme = useTheme();
  return (
    <Top5InnerContainer>
      <ValueLabel
        style={{ width: `${SpringToFrom(Timing.DelayIn, 450, 0)}px` }}
      >
        <H1
          style={{
            opacity: interpolateOpacityByFrame(frame, 0, Timing.DelayIn, 1, 0),
            width: "100%",
            color: "white",
            fontWeight: 400,
            fontSize: "3em",
          }}
        >
          {InfoTitle[0].Label}: {METADATA[InfoTitle[0].Key]}
        </H1>
      </ValueLabel>

      <ValueLabel
        style={{
          top: "250px",
          width: `${SpringToFrom(Timing.DelayIn, 450, 0)}px`,
        }}
      >
        <H1
          style={{
            opacity: interpolateOpacityByFrame(frame, 0, Timing.DelayIn, 1, 0),
            width: "100%",
            color: "white",
            fontWeight: 400,
            fontSize: "3em",
          }}
        >
          {InfoTitle[1].Label}: {METADATA[InfoTitle[1].Key]}
        </H1>
      </ValueLabel>

      <ValueLabel
        style={{
          left: "0",
          width: `${SpringToFrom(Timing.DelayIn, 450, 0)}px`,
        }}
      >
        <H1
          style={{
            opacity: interpolateOpacityByFrame(frame, 0, Timing.DelayIn, 1, 0),
            width: "100%",
            color: "white",
            fontWeight: 400,
            fontSize: "3em",
          }}
        >
          {InfoTitle[2].Label}: {METADATA[InfoTitle[2].Key]}
        </H1>
      </ValueLabel>

      <FrostedItem
        style={{
          display: "flex",
          alignItems: "center",
          width: "700px",
          height: "150px",
          borderRadius: `2%`,
          position: "absolute",
          bottom: `50px`,
          flexDirection: "column",
          padding: 0,
          zIndex: 100,
        }}
      />
      <PlayerImage
        style={{
          backgroundImage: `url(${IMG})`,
          clipPath: `polygon(0 ${SpringToFrom(
            Timing.DelayIn,
            100,
            50
          )}%, 100% ${SpringToFrom(
            Timing.DelayIn,
            100,
            50
          )}%, 100% ${SpringToFrom(Timing.DelayIn, 0, 50)}%, 0 ${SpringToFrom(
            Timing.DelayIn,
            0,
            50
          )}%)`,
        }}
      />
      <Top5TeamLogo
        style={{
          backgroundImage: `url(${LOGO})`,
          opacity: interpolateOpacityByFrame(
            frame,
            Timing.DelayIn,
            Timing.Out,
            1,
            0
          ),
        }}
      />

      <PositionContainer
        style={{
          height: `${SpringToFrom(Timing.DelayIn, 250, 0)}px`,
          opacity: interpolateOpacityByFrame(
            frame,
            Timing.DelayIn,
            Timing.Out,
            1,
            0
          ),
        }}
      >
        <H1
          style={{
            opacity: interpolateOpacityByFrame(
              frame,
              Timing.DelayIn,
              Timing.Out,
              1,
              0
            ),
            color:  theme.Copy[0],
            fontSize: "7em",
            marginTop: "-100px",
          }}
        >
          {POSITION}
        </H1>
      </PositionContainer>
      <NameContainer>
        <H1
          style={{
            opacity: interpolateOpacityByFrame(
              frame,
              Timing.DelayIn,
              Timing.Out,
              1,
              0
            ),
            width: "100%",
            fontWeight: 800,
            lineHeight: 0,
            zIndex: 1000,
            color:  theme.Copy[0],
          }}
        >
          {NAME}
        </H1>
        <H1
          style={{
            opacity: interpolateOpacityByFrame(
              frame,
              Timing.DelayIn,
              Timing.Out,
              1,
              0
            ),
            width: "100%",
            fontWeight: 800,
            lineHeight: 0,
            zIndex: 1000,
            color:  theme.Copy[0],
          }}
        >
          {TEAM}
        </H1>
      </NameContainer>
    </Top5InnerContainer>
  );
};
