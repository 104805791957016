import { Sequence, useCurrentFrame, useVideoConfig, spring } from "remotion";
import {
  DefaultTeamLogo,
  hasTeamLogo,
  FindIMGFORMAT,
  TrimPlayerName
} from "../../../../../../actions/UX";
import { GLOBALUL, LI_Fixture } from "../../../utils/html";
import {
  FrostedItem,
  TeamNamesContainer,
  TeamLogoRight,
  TeamLogoLeft,
  MetaRight,
  MetaLeft,
  FullWidthWrapper,
} from "../../../utils/Containers";
import { H2, H1, P, P_Bold } from "../../../utils/Text";

import moment from "moment";
import { useTheme } from "styled-components";

export const UpcomingFixtureUL = ({ LISTFIXTURES, VideoSettings }) => {
  //console.log(VideoSettings)
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const theme = useTheme();
  const TEAMLOGO = (TEAM) => {
    return TEAM;
  };
  const SpringConfig = {
    Springy900: {
      damping: 10,
      stiffness: 200,
      mass: 1,
    },
    Springy500: {
      damping: 10,
      stiffness: 200, 
      mass: 1,
    },
    Springy100: {
      damping: 10,
      stiffness: 150,
      mass: 0.3,
    },
    Stiff: {
      damping: 80,
      stiffness: 100,
      mass: 0.1,
    },
  };

  const StaggerSpringIn = (int) => {
    return spring({
      fps,
      from: 0,
      to: 1,
      frame: frame - int,
      config: SpringConfig.Springy100,
    });
  };
  const momentFormat = (DATA, TIME) => {
    const d = new Date(`${DATA} ${TIME}`);
    return moment(d).format("dd DD MMMM h:mma");
  };

  const sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  };

  const FixturePages = sliceIntoChunks(
    LISTFIXTURES,
    VideoSettings.FixturesPerPage
  );
  const FixtureDuration =
    VideoSettings.FixtureAnimateIn +
    VideoSettings.FixtureAnimateOut +
    VideoSettings.FixturePauseAfterLoad;

  return (
    <FullWidthWrapper>
      {FixturePages.map((Page, i) => {
        return (
          <Sequence
            from={FixtureDuration * VideoSettings.FixturesPerPage * i}
            durationInFrames={FixtureDuration * VideoSettings.FixturesPerPage}
          >
            <GLOBALUL>
              {Page.map((fixture, ii) => {
                return (
                  <LI_Fixture
                    key={ii}
                    style={{
                      transform: `scale(${StaggerSpringIn(
                        VideoSettings.FixturePauseAfterLoad * ii
                      )})`,
                    }}
                  >
                    <Sequence
                      from={VideoSettings.FixturePauseAfterLoad * ii}
                      durationInFrames={
                        FixtureDuration * VideoSettings.FixturesPerPage
                      }
                    >
                      <TeamLogoLeft
                        style={{
                          backgroundImage: `url(${TEAMLOGO(
                            fixture.homeTeamLogo
                          )})`,
                          transform: `scale(${StaggerSpringIn(
                            VideoSettings.FixturePauseAfterLoad * ii + 7
                          )}) `,
                        }}
                      />
                      
 
                      <TeamLogoRight
                        style={{
                          backgroundImage: `url(${TEAMLOGO(
                            fixture.awayTeamLogo
                          )})`,
                          transform: `scale(${StaggerSpringIn(
                            VideoSettings.FixturePauseAfterLoad * ii + 10
                          )}) `,
                        }}
                      />

                      <TeamNamesContainer>
                        <H2
                          style={{
                            color: theme.Copy[0],
                          }}
                        >
                          {TrimPlayerName(fixture.homeTeam)} 
                        </H2>
                        <P
                          style={{
                            color: theme.Copy[2],
                          }}
                        >
                          vs
                        </P>
                        <H2
                          style={{
                            color: theme.Copy[0],
                          }}
                        >
                          {TrimPlayerName(fixture.awayTeam)}
                        </H2>
                      </TeamNamesContainer>
                      <MetaRight>
                        <P_Bold
                          style={{
                            color: theme.Copy[2],
                          }}
                        >
                          {momentFormat(fixture.date, fixture.time)}
                        </P_Bold>
                      </MetaRight>
                      <MetaLeft>
                        <P_Bold
                          style={{
                            color: theme.Copy[2],
                          }}
                        >
                          {fixture.ground}
                        </P_Bold>
                      </MetaLeft>

                      <FrostedItem />
                    </Sequence>
                  </LI_Fixture>
                );
              })}
            </GLOBALUL>
          </Sequence>
        );
      })}
    </FullWidthWrapper>
  );
};
