import {
  Box,
  Container,
  Grid,
  SimpleGrid,
  Skeleton,
  useMantineTheme,
  Image,
  Center,
  Group,
} from "@mantine/core";
import { BLOCK_ToolBar } from "../../../../../../Components/interface/BLOCK_Toolbar";
import { BTN_CREATE, BTN_ICON_CALLBACK } from "../../../../../../Theme/Structure/Button/BTNS";
import { H3 } from "../../../../../../Theme/type";

// Compoents
import { PlayersStandingInLeague } from "./PlayerStandingsInSelectedLeague";

export const ViewSinglePlayer = ({
  SelectedPlayer,
  setSelectedPlayer,
  Fixture,
}) => {
  return (
    <>
    
    <BLOCK_ToolBar
          TOOLS={[
            <BTN_ICON_CALLBACK
            CALLBACK={() => {
              setSelectedPlayer(false);
            }}
              ICON={"User"}
              TIPOBJ={{
                LABEL: "Back to Player List",
                COLOR: "red",
                POSITION: "top",
              }}
            />,
          ]}
        />
      
      <Container my="md">
        <SimpleGrid
          cols={2}
          spacing="md"
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        >
          <Grid gutter="md">
            <Grid.Col>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colors.brand[0],
                  borderRadius: 5,
                  padding: "1em",
                })}
              >
                <Center>
                  <Image
                    src={SelectedPlayer.image.data?.attributes.url}
                    width={"200px"}
                    height={"200px"}
                    radius={100}
                  />
                </Center>
                <Center>
                  <H3>{SelectedPlayer.Name}</H3>
                </Center>
                {Detail(["MATCHES", SelectedPlayer.MATCHES])}
                {Detail(["LMS STATUS", SelectedPlayer.PLAYERPROFILELEVEL])}
                {Detail(["BATTING TYPE", SelectedPlayer.TYPEBATTING])}
                {Detail(["BOWLING TYPE", SelectedPlayer.TYPEBOWLING])}
              </Box>
            </Grid.Col>
            <Grid.Col span={6}>
              <H3 colorID={4} weight={900} letterspacing={`-1px`}>
                Batting Rank
              </H3>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colors.brand[1],
                  borderRadius: 5,
                  padding: "1em",
                })}
              >
                {Detail(["National", SelectedPlayer.NATIONALRANKINGBATTING])}
                {Detail(["World", SelectedPlayer.WORLDRANKINGBATTING])}
              </Box>
            </Grid.Col>
            <Grid.Col span={6}>
              <H3 colorID={4} weight={900} letterspacing={`-1px`}>
                Bowling Rank 
              </H3>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colors.brand[1],
                  borderRadius: 5,
                  padding: "1em",
                })}
              >
                {Detail(["National", SelectedPlayer.NATIONALRANKINGBOWLING])}
                {Detail(["World", SelectedPlayer.WORLDRANKINGBOWLING])}
              </Box>
            </Grid.Col>
          </Grid>
          <Box
            sx={(theme) => ({
              backgroundColor: theme.colors.brand[0],
              borderRadius: 5,
              padding: "0",
            })}
          >
            <H3 colorID={4} weight={900} letterspacing={`-1px`}>
              Batting
            </H3>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.colors.brand[1],
                borderRadius: 5,
                padding: "1em",
              })}
            >
              {Detail(["INNINGS", SelectedPlayer.INNINGS])}
              {Detail(["RUNS", SelectedPlayer.RUNS])}
              {Detail(["BATTINGAVERAGE", SelectedPlayer.BATTINGAVERAGE])}
              {Detail(["STRIKERATE", SelectedPlayer.STRIKERATE])}
              {Detail(["FIFTIES", SelectedPlayer.FIFTIES])}
              {Detail(["HIGHSCORE", SelectedPlayer.HIGHSCORE])}
              {Detail(["HUNDREDS", SelectedPlayer.HUNDREDS])}
            </Box>
            <H3 colorID={4} weight={900} letterspacing={`-1px`}>
              Bowling
            </H3>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.colors.brand[1],
                borderRadius: 5,
                padding: "1em",
                marginTop: ".5em",
              })}
            >
              {Detail(["WICKETS", SelectedPlayer.WICKETS])}
              {Detail(["OVERS", SelectedPlayer.OVERS])}
              {Detail(["BOWLINGAVERAGE", SelectedPlayer.BOWLINGAVERAGE])}
              {Detail(["ECONOMY", SelectedPlayer.ECONOMY])}
              {Detail(["BESTFIGURES", SelectedPlayer.BESTFIGURES])}
              {Detail(["W3", SelectedPlayer.W3])}
              {Detail(["W5", SelectedPlayer.W5])}
            </Box>
          </Box>
        </SimpleGrid>
      </Container>
      <PlayersStandingInLeague
        Fixture={Fixture}
        SelectedPlayer={SelectedPlayer}
      />
    </>
  );
};

const Detail = (DATA) => {
  return (
    <Group position="apart">
      <H3 colorID={5}>{DATA[0]}</H3>
      <H3 colorID={3} weight={900} letterspacing={`-1px`}>
        {DATA[1]}
      </H3>
    </Group>
  );
};
/*
 colorID = 4,
    size = "lg",
    lineheight = "auto",
    align = "left",
    weight = 400,
    underline = false,
    transform = "capitalize",
    letterspacing = "auto",
*/
