import { useState } from "react";
import {
  createStyles,
  Header,
  Group,
  ActionIcon,
  Container,
  Burger,
  Drawer,
  Image,
  Divider,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconBrandFacebook, IconBrandInstagram } from "@tabler/icons";
import { H1, H3, H2 } from "../../Theme/type";

const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 56,

    [theme.fn.smallerThan("sm")]: {
      justifyContent: "flex-start",
    },
  },

  links: {
    width: 260,

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  social: {
    width: 260,

    [theme.fn.smallerThan("sm")]: {
      width: "auto",
      marginLeft: "auto",
    },
  },

  burger: {
    marginRight: theme.spacing.md,

    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color: theme.colors.Public[1],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor: theme.colors.Public[2],
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.colors.Public[2],
      color: theme.colors.Public[0],
    },
  },
}));

const links = [
  {
    link: "/",
    label: "Home",
  },
  {
    link: "/GiveItAGo",
    label: "Give it a try",
  },
  {
    link: "/login",
    label: "Sign in",
  },
];

export function IndexHeader({ INDEX = 0 }) {
  const [opened, { toggle }] = useDisclosure(false);
  const [active, setActive] = useState(links[INDEX].link);
  const { classes, cx } = useStyles();

  const items = links.map((link) => (
    <a
      key={link.label}
      href={link.link}
      className={cx(classes.link, {
        [classes.linkActive]: active === link.link,
      })}
      onClick={(event) => {
        //event.preventDefault();
        setActive(link.link);
      }}
    >
      {link.label}
    </a>
  ));

  return (
    <Header height={56} mb={120}>
      <Container className={classes.inner}>
        <Burger
          opened={opened}
          onClick={toggle}
          size="sm"
          className={classes.burger}
        />
        <Group className={classes.links} spacing={5}>
          {items}
        </Group>

        <Image src={"/StattoLogo.png"} height={50} width={50} fit="contain" />

        <Group spacing={0} className={classes.social} position="right" noWrap>
          {/* <ActionIcon size="lg">
            <IconBrandTwitter size={18} stroke={1.5} />
          </ActionIcon> */}
          <ActionIcon
            size="lg"
            component="a"
            href="https://www.facebook.com/groups/1162830104321820"
          >
            <IconBrandFacebook size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon
            size="lg"
            component="a"
            href="https://www.instagram.com/statto_social/"
          >
            <IconBrandInstagram size={18} stroke={1.5} />
          </ActionIcon>
        </Group>
      </Container>
      <Drawer
        opened={opened}
        onClose={toggle}
        title="Menu"
        padding="xl"
        size="xl"
        position="bottom"
        overlayBlur={3}
        overlayOpacity={0.65}
        transitionDuration={450}
  transitionTimingFunction="ease"
      >
        {items}

        <Divider my={20} />
        <Group position="apart">
          <H3 colorID={5} weight={900}>
            Social Statto
          </H3>
          <Image src={"/StattoLogo.png"} height={50} width={50} fit="contain" />
        </Group>
      </Drawer>
    </Header>
  );
}
