/* eslint-disable react/jsx-pascal-case */
import { useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { useOrderAsset } from "../../Hooks/Content/useOrders";
import { useIsDuplicateOrder } from "../../Hooks/shop/useisDuplicateOrder";
import { Box, Container, Space } from "@mantine/core";
// Theme
import { H1, H3, P } from "../../Theme/type"; 
//import { ColoredContainer } from "../../../Theme/Structure/BodyContainer";
import { BTN_CREATE } from "../../Theme/Structure/Button/BTNS";

/* import { BLOCK_Section_Title } from "../../interface/BLOCK_Section_Title";
import { BLOCK_Section_SubHeading } from "../../interface/BLOCK_Section_SubHeading"; */
import { BLOCK_ToolBar } from "../../Components/interface/BLOCK_Toolbar";
import { BLOCK_Section_SubHeading } from "../../Components/interface/BLOCK_Section_SubHeading";
import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";
import { VideoWizardContainer } from "../../Theme/Structure/BodyContainer";

export const OrderContainer = (props) => {
  const RENDER = useSelector((state) => state.RENDER);
  const AUTH = useSelector((state) => state.AUTH);

  const { VideoTitle, showAsset, isConfirmOrder, setisConfirmOrder } = props;

  const [orderStatus, CreateOrder] = useOrderAsset();
  const [processingOrder, setprocessingOrder] = useState(false);
  const [isDuplicate, FindIsDuplicate] = useIsDuplicateOrder();

  const ProcessOrder = () => {
    //setprocessingOrder(true);
    CreateOrder(RENDER, AUTH.AUTHUSER.id);
  };

  const OrderProcessStatus = (status) => {
    setprocessingOrder(false);
    // need an error handle in here
    setisConfirmOrder(status);
  };

  useEffect(() => {
    FindIsDuplicate(RENDER.META.NameVideo); 
  }, [RENDER.META.NameVideo]);

  useEffect(() => {}, [processingOrder]);
  useEffect(() => {
    OrderProcessStatus(orderStatus);
  }, [orderStatus]);

  if (!AUTH.AUTHUSER.hasFullAccess) return <DemoAccount />;
  if (isDuplicate) return <AlreadyPurchased />;
  if (processingOrder) return <ProcessingOrder />;
  return (
    <>
      {isConfirmOrder !== false ? (
        <OrderConfirmed isConfirmOrder={isConfirmOrder} />
      ) : (
        <OrderDetails
          ProcessOrder={ProcessOrder}
          showAsset={showAsset}
          {...props}
        />
      )}
    </>
  );
};

export const AlreadyPurchased = () => {
  return (
    <Box width={"50%"} mt={50}>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Asset already created...",
          ICON: "AlertTriangle",
          DESCRIPTION:false,
          TOOLBAR: false,
        }}
      />
      <VideoWizardContainer>
        <P>
          This Asset has been rendered and available for download, Go to your
          Downloads page to view this Video or delete it to re render any
          changes.
        </P>
      </VideoWizardContainer>
    </Box>
  );
};

export const ProcessingOrder = () => {
  return (
    <Box>
      <H3 colorID={5}>processingOrder</H3>
    </Box>
  );
};

export const OrderConfirmed = ({ isConfirmOrder }) => {
  return (
    <>
      {isConfirmOrder != null ? (
        <VideoWizardContainer>
          <BLOCK_Section_SubHeading LABEL="Order Confirmed" Color={5} />

          <Space h={10} />
          <P>Your order has been sent for processing!</P>
          <Space h={5} />
          <P>
            Check your DOWNLOADS page in your home tab shortly for your Video.
          </P>
        </VideoWizardContainer>
      ) : (
        <P>There was an Error with this Order</P>
      )}
    </>
  );
};

export const OrderDetails = (props) => {
  const { ProcessOrder } = props;
  const RENDER = useSelector((state) => state.RENDER);
  //console.log(RENDER);
  return (
    <>
      <Container
        size="xl"
        m="0"
        p="0"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
        }}
      >
        <Box
          style={{
            width: "100%",
            marginTop: "1em",
          }}
        >
          <BLOCK_ToolBar
            TOOLS={[
              <BTN_CREATE
                TIPOBJ={{
                  LABEL: "Confirm Create Asset",
                  COLOR: "gray",
                  POSITION: "top",
                }}
                CALLBACK={() => {
                  ProcessOrder();
                }}
                LABEL={`Confirm Order`}
                BG={7}
                COPY={0}
              />

              
            ]}
          />
        </Box>
        <Box
          py={5}
          px={5}
          mt={10}
          mb={10}
          sx={(theme) => ({
            backgroundColor: `${theme.colors.brand[0]}`,

            justifyContent: "start",
            alignItems: "start",
            alignContent: "start",
            alignSelf: "stretch",
            borderRadius: "5px",
          })}
        >
          <H3 weight={900} lineheight={'.9em'} colorID={3} transform='uppercase' align="left">
            {RENDER.META.NameVideo}
          </H3>

      
       
        </Box>
      </Container>
    </>
  );
};
/*
<BTN_CREATE
                CALLBACK={() => {
                  props.setCreate(false);
                }}
                TIPOBJ={{
                  LABEL: "Cancel Asset",
                  COLOR: "gray",
                  POSITION: "top",
                }}
                LABEL={`Cancel`}
                BG={6}
                COPY={0}
              />, 
*/
const DemoAccount = () => {
  return (
    <Container
      size="xl"
      m="0"
      p="0"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
      }}
    >
      <H3>Orders are prohibited on a Demo Account</H3>
      <P>Contact us via the facebook page to gain access to this feature.</P>
    </Container>
  );
};
