import { Routes, Route,useLocation, useNavigate  } from "react-router-dom";

// Pages
import {PAGE_LANDING} from './PAGE_Landing'
import { PAGE_Login } from "./PAGE_login";
import {PAGE_GiveItAGO} from './PAGE_GiveItAgo'
import {PAGE_Signup} from './PAGE_Signup';
import {PAGE_Terms} from './PAGE_Terms'
import {PAGE_User} from './PAGE_UserAgreement'
// Components
import { PublicShell } from "../Components/PublicShell"; 
import { useEffect } from "react";

export const INDEX = () => {
    let navigate = useNavigate();
  let location = useLocation();
  console.log(location.pathname)
 
/*   useEffect(()=>{
    if (location.pathname !== "/") {
        navigate("/", { replace: true }); 
      } 
  },[location.pathname]) */
    
  
  return (
    <PublicShell>
      <Routes>
        <Route path="/login" element={<PAGE_Login />} />
        <Route path="/" element={<PAGE_LANDING />} />
        <Route path="/GiveItAGo" element={<PAGE_GiveItAGO />} />
        <Route path="/Terms" element={<PAGE_Terms />} />
        <Route path="/Users" element={<PAGE_User />} />
        <Route path="/Signup" element={<PAGE_Signup />} />
        
      </Routes> 
    </PublicShell>
  );
};
//
