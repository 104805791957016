import { useEffect, useState } from "react";
import { Radio } from "@mantine/core";
import { useGetSponsors } from "../../Hooks/Content/useSponsors";
import { find } from "lodash";
import { UISkeletonLines } from "../Global/Skeleton";

export const RadioVideoSponsor = ({ DefaultValue, CALLBACK }) => {
  //const [VideoTheme,FetchVideoTheme] = useSelectVideoTheme()
  const [Sponsors, GetSponsors] = useGetSponsors();
  const [value, setValue] = useState(DefaultValue);

  const handleChange = (e) => {
    if (e === "No Sponsor") {
      CALLBACK(false);
      setValue("No Sponsor");
    } else {
      const SPONSOR = find(Sponsors, (o) => {
        return o.attributes.Name === e;
      });
      //console.log(SPONSOR)
      CALLBACK(SPONSOR);
      setValue(SPONSOR.attributes.Name);
    }
  };

  useEffect(() => {
    GetSponsors();
  }, []);
  useEffect(() => {}, [Sponsors]);
  if (Sponsors === null) return <UISkeletonLines />;
  return (
    <Radio.Group
      orientation="vertical"
      label=""
      description=""
      spacing="sm"
      size="lg"
      value={value}
      onChange={handleChange}
    >
      <Radio value={"No Sponsor"} color="violet" label="No Sponsor" />
      {Sponsors.map((ITEM, i) => {
        if (ITEM.attributes.isActive)
          return (
            <Radio
              color="green"
              value={ITEM.attributes.Name}
              label={ITEM.attributes.Label}
              key={i}
            />
          );
      })}
    </Radio.Group>
  );
};
