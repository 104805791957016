import { useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { isSelectedFranchise } from "../../store/Reducer/UX";
import { ENV } from "../../Config/RunTimeConfig";
import { parseCookies } from "nookies";
import { find } from "lodash";
const qs = require("qs");

// FUNC : GET SINGLE

// FUNC : CREATE
// usage // this function is used exclusivly for Creating a new tournament
export const useCreateFranchise = (ctx) => {
  const [Franchise, setFranchise] = useState(false);
  const jwt = parseCookies(ctx).jwt;
  const CreateFranchise = async (_DATA) => {
    //console.log(_DATA);

    const res = await fetch(`${ENV.API_URL}/api/franchises`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        data: _DATA,
      }),
    });
    const JsonFranchises = await res.json();
    //console.log(JsonFranchises);
    _DATA.FRANCHISEID = JsonFranchises.data.id;

console.log(_DATA)

    setFranchise(_DATA);
  };
  return [Franchise, CreateFranchise];
};

// FUNC : UPDATE
// FUNC : DELETE

///
 
// FUNC : GET
export const useGetFranchises = (ctx) => {
  const [Franchises, setFranchises] = useState(false);
  const dispatch = useDispatch();

  const FetchFranchises = async (_USER) => {
    const jwt = parseCookies(ctx).jwt;
    const AUTHID = parseCookies(ctx).AUTHID;
    const isActiveFranchise = parseCookies(ctx).isSelectedFranchise;
    //console.log("isActiveFranchise is", isActiveFranchise)

    const query = qs.stringify(
      {
        filters: {
          users_permissions_user: { id: { $eq: AUTHID } },
          subscriptions: { isActive: true },
        },
        fields: ["Name", "FranchiseStats", "PATH", "isUpdating", "LastUpdate"],
        populate: [
          "regional_seasons",
          "franchise_update_requests",
          "tournament",
          "watch_lists",
          "subscriptions",
        ],
      },
      {
        encodeValuesOnly: true,
      }
    );

    const res = await fetch(`${ENV.API_URL}/api/franchises/?${query}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    const JsonFranchises = await res.json();
 

    const ActiveFranchise = find(JsonFranchises.data, (o) => {
      return o.id === parseInt(isActiveFranchise);
    });



    dispatch(isSelectedFranchise(ActiveFranchise));
    setFranchises(JsonFranchises);
  };

  return [Franchises, FetchFranchises];
};
/*
FranchiseStats: {RUNS: 145495, OVERS: 22546.39999999999, WICKETS: 5538, FIXTURES: 648, TeamStats: [,…],…}
LastUpdate: "1661996259"
Name: "Sydney"
PATH: "play-cricket/t20/fixtures/city?city=sydney"
createdAt: "2022-04-14T13:41:33.106Z"
franchise_update_requests: {,…}
isUpdating: false
regional_seasons: {data: [{id: 2,…}, {id: 3,…}, {id: 4,…}, {id: 7,…}, {id: 8,…},…]}
tournament: {data: null}
updatedAt: "2022-09-01T01:37:39.220Z"
users_permissions_user: {data: {id: 1,…}}
watch_lists:
*/

// GET SINGLE
export const useGetFranchise = (ctx) => {
  const [Franchise, setFranchise] = useState(false);
  const UXSTATE = useSelector((state) => state.UXSTATE);

  //console.log("UXSTATE", UXSTATE)
  const FetchFranchise = async () => {
    const jwt = parseCookies(ctx).jwt;

    const query = qs.stringify(
      {
        populate:["franchise_update_requests"],
        fields: ["Name", "isUpdating", "LastUpdate"],
      },
      {
        encodeValuesOnly: true,
      }
    );
    if(UXSTATE?.SelectedFranchise?.id !== undefined){
      const res = await fetch(`${ENV.API_URL}/api/franchises/${UXSTATE.SelectedFranchise.id}?${query}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      const JsonFranchises = await res.json();
      setFranchise(JsonFranchises);
    }
   
  };
  return [Franchise, FetchFranchise];
};
