import {gql,} from "@apollo/client";


export const QUERY = (APISETTINGS)=>{
    return gql`query TOP_5 {
      playerCatches(
        pagination: { start: 0, limit: -1 }
        filters:{PLAYERS_Catches:{  gte:1},fixture:{watch_list:{ id:{in: [${APISETTINGS.IDS}]}}}}
      ){
        data{
         id
          attributes{ 
            PLAYERS_Catches
             team {
                  data {
                    id
                    attributes {
                      Name
                      TeamID
                      Logo {
                        data {
  
                          attributes {
                            url
                          }
                        }
                      }
                    }
                }
            }
            fixture{
                data{
                  attributes{
                    watch_list{
                      data{
                        attributes{
                          Name
                        }
                      }
                    }
                  }
                }
            }
            player{
              data{
                id
                attributes{
                  Name
                   image {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                }
              }
            }
          }
        }
    }
  }`
}
  
