import { useRef,useEffect,useState } from 'react';
import {FullWidthWrapper} from '../../../utils/Containers'
import {FIXTURELIST} from './Series/FixtureList';

export const MATCHDAYRESULTS_BODY=({DATA,VideoSettings,RenderType})=>{

	const [Dimensions, setDimensions] = useState([]);
  
	const ref = useRef(null);
	useEffect(() => { setDimensions([ref.current.clientHeight,ref.current.clientWidth]); }, []);

  return(
	<FullWidthWrapper ref={ref}> 
		<FIXTURELIST DATA={DATA} Dimensions={Dimensions}/>
	</FullWidthWrapper> 
  )  
} 