import { ThemeProvider } from "styled-components";

// Import Design Templates for MATCHDAYRESULT.
// Add new deisng patterns below
// GLASS
import { TOP5_ExtraMeta_4by5 } from "../../theme/Templates/GLASS/index";
import { TOURNAMENT_TOP5_4by5 } from "../../theme/Templates/TOURNAMENT_GLASS/index";
// END

export const Build_TOPFIVEFASTESTFIFTIES_4by5 = (props) => {
  const { RENDER } = props;

  const TEMPLATES = {
    Glass: (
      <TOP5_ExtraMeta_4by5
        RENDER={RENDER}
        MainTitle="Fastest Fifties"
        Subtitle="TOP 5"
        InfoTitle={[
          { Label: "Runs", Key: "RUNS" },
          { Label: "Balls", Key: "BALLS" },
          { Label: "Strike Rate", Key: "VARIABLE" },
        ]}
      />
    )
  };
  return (
    <ThemeProvider theme={RENDER.THEME.VideoTheme.Theme}>
      {TEMPLATES[RENDER.THEME.VideoTemplate]}
    </ThemeProvider>
  );
};
