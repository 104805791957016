/* eslint-disable react/jsx-pascal-case */
import { useSelector } from "react-redux";
import { useUpdateMeVideoSettings } from "../../../Hooks/Content/useVideoSettings";
import { useAuthUser } from "../../../Hooks/Auth/useAuthUserMe";
import {
  BorderedContainer,
  ColoredContainer,
  VideoWizardContainer,
} from "../../../Theme/Structure/BodyContainer";
import { H3, P } from "../../../Theme/type";

import { BowlingPOTDSelectMinWickets } from "../../../Components/settings/Select_VideoSetting_BowlingPOTD_Min_Wickets";
import { BowlingPOTDSelectMaxEconomy } from "../../../Components/settings/Select_VideoSetting_BowlingPOTD_Min_Economy";

import { useEffect, useState } from "react";
import {
  UISkeletonCircle,
  UISkeletonLines,
} from "../../../Components/Global/Skeleton";
import {
  BLOCK_Section_SubHeading_INVERT,
  BLOCK_Section_SubHeading,
} from "../../../Components/interface/BLOCK_Section_SubHeading";
import { Container, Group, Highlight, Space } from "@mantine/core";

import { PageHeader } from "../../../Components/interface/ProductHeader";
import { HIGHLIGHTER } from "../../../Components/interface/Wrapper/Highlighter";

export const BowlingPOTDSettings = () => {
  const AUTH = useSelector((state) => state.AUTH);
  const SETTINGSPATH = AUTH.AUTHUSER.user_video_setting.Settings.POTD.BOWLING;
  const [authUser, FetchauthUser] = useAuthUser();
  const [UpdateUser, PutUpdateUser] = useUpdateMeVideoSettings();

  const [updating, setupdating] = useState(false);

  const [ThresholdWICKETS, setThresholdWICKETS] = useState(
    SETTINGSPATH.ThresholdWICKETS
  );
  const [MinWICKETS, setMinWICKETS] = useState(SETTINGSPATH.MinWICKETS);
  const [MinECO, setMinECO] = useState(SETTINGSPATH.ECO);

  const [Settings, setSettings] = useState(
    AUTH.AUTHUSER.user_video_setting.Settings
  );
  /*
"BOWLING": {
      "WICKETS": 2,
      "ECO": 2
    }
*/
//console.log(SETTINGSPATH);

  const UpdateSETTINGS = (PATH, VALUE) => {
    const ARR = {
      ...Settings,
      POTD: {
        ...Settings.POTD,
        BOWLING: { ...Settings.POTD.BOWLING, [PATH]: VALUE },
      },
    };
  //console.log(ARR);
    setSettings(ARR);
  };

  // SET Threshold number of Wickets
  useEffect(() => {
    if (ThresholdWICKETS !== SETTINGSPATH.ThresholdWICKETS) {
      UpdateSETTINGS("ThresholdWICKETS", ThresholdWICKETS);
    }
  }, [ThresholdWICKETS]);

  // SET min number of Wickets
  useEffect(() => {
    if (MinWICKETS !== SETTINGSPATH.MinWICKETS) {
      UpdateSETTINGS("MinWICKETS", MinWICKETS);
    }
  }, [MinWICKETS]);

  // SET ECO for min Wickets
  useEffect(() => {
    if (MinECO !== SETTINGSPATH.ECO) {
      UpdateSETTINGS("ECO", MinECO);
    }
  }, [MinECO]);

  useEffect(() => {
    PutUpdateUser(Settings, AUTH.AUTHUSER.user_video_setting.id);
    setupdating(true);
  }, [Settings]);

  useEffect(() => {
    FetchauthUser();
  }, [UpdateUser]);
  useEffect(() => {
    setupdating(false);
  }, [authUser]);

  if (updating) return <UISkeletonLines />;
  return (
    <>
      <PageHeader NavPosition="Bowling Settings" />

      <Space h="xl" />
      <BLOCK_Section_SubHeading LABEL="Play of the Day: Bowling" Color={5} />

      <ColoredContainer Padding="xs" BGColor={0}>
        <P>
          Use these settings to fine tune the Bowling POTD videos to your
          franchise. It is recommended that franchises with a high number of
          teams and leagues use a higher threshold to limit video length.
        </P>
        <Space h="xl" />
        <P>With the current settings, the follow statment is true.</P>
        <P weight={900} colorID={4} align="left">
          To be automatically eligable for the bowling POTD a players must take{" "}
          {ThresholdWICKETS} wickets or more. A player with a bowling economy of{" "}
          {MinECO} or under with {MinWICKETS} Wickets will also be included.
        </P>
      </ColoredContainer>
      <Space h="lg" />

      <Container size="lg"  p={0}>
        <VideoWizardContainer>
          <Group position="apart">
            <P colorID={3}>
              <HIGHLIGHTER WORDS={["WICKETS", "Automatically"]}>
                What is the threshold of WICKETS required to be Automatically
                featured?
              </HIGHLIGHTER>
            </P>
            <BowlingPOTDSelectMinWickets
              CALLBACK={setThresholdWICKETS}
              VALUE={ThresholdWICKETS}
            />
          </Group>
        </VideoWizardContainer>
      </Container>

      <Container size="lg"  p={0}>
        <VideoWizardContainer>
          <Group position="apart">
            <P colorID={3}>
              <HIGHLIGHTER WORDS={["Minimum Wickets"]}>
                What is the Minimum Wickets required Combined with the Bowling Economy to be featured?
              </HIGHLIGHTER>
            </P>
            <BowlingPOTDSelectMinWickets
              CALLBACK={setMinWICKETS}
              VALUE={MinWICKETS}
            />
          </Group>
        </VideoWizardContainer>
      </Container>

      <Container size="lg"  p={0}>
        <VideoWizardContainer>
          <Group position="apart">
            <P colorID={3}>
              <HIGHLIGHTER WORDS={["Bowling Economy"]}>
                What is the Maximum Bowling Economy required to be featured?
              </HIGHLIGHTER>
            </P>
            <BowlingPOTDSelectMaxEconomy CALLBACK={setMinECO} VALUE={MinECO} />
          </Group>
        </VideoWizardContainer>
      </Container>
    </>
  );
};
