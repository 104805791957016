import {
  Box,
  Center,
  Grid,
  Group,
  Image,
  SimpleGrid,
  Stack,
} from "@mantine/core";
// Styled Comps
import { useTheme } from "styled-components";
import styled from "styled-components";
// Animations
import { SpringToFrom } from "../../../../../Animation/RemotionSpring";
import { H1, H3 } from "../../../../utils/Text";

import { orderBy } from "lodash";

export const Section_TeamStats = ({ DATA, DURATION }) => {
  const theme = useTheme();

  //console.log(DATA, DATA.TeamStats.PLAYERS.length)

  const TotalFifties = (DATA) => {
    const ARR = [];
    DATA.TeamStats.PLAYERS.map((player, i) => {
      ARR.push(player.BATTING_50);
    });
    return ARR.reduce((a, b) => a + b, 0);
  };

  return (
    <>
      <Grid
        grow
        gutter="xs"
        justify="space-around"
        sx={(theme) => ({
          position: "relative",
          width: "100%",
          height: "100%",
          zIndex: 100,
        })}
      >
        <Grid.Col span={5}>
          <Stack spacing="xs">
            <StatContainer
              LABEL={`BATTING`}
              DURATION={DURATION}
              TIMINGS={{
                SlideIn: 0,
                LabelPosition: 100,
                ClipIn: 15,
                SlideUp: 30,
              }}
            >
              <StatItem
                LABEL={`RUNS*`}
                ITEM={`${DATA.TeamStats.BATTING.RUNS}`}
              />
              <StatItem LABEL={`50s Scored`} ITEM={TotalFifties(DATA)} />

              <StatItem
                LABEL={`FOURs`}
                ITEM={`${DATA.TeamStats.BATTING.FOUR}`}
              />
              <StatItem
                LABEL={`SIXeS`}
                ITEM={`${DATA.TeamStats.BATTING.SIX}`}
              />
            </StatContainer>

            <StatContainer
              LABEL={`BOWLING`}
              DURATION={DURATION}
              TIMINGS={{
                SlideIn: 3,
                LabelPosition: 100,
                ClipIn: 15,
                SlideUp: 30,
              }}
            >
              <StatItem
                LABEL={`WICKETS`}
                ITEM={` ${DATA.TeamStats.BOWLING.WICKETS}`}
              />
              <StatItem
                LABEL={`RUNS CONCEEDED`}
                ITEM={` ${DATA.TeamStats.BOWLING.RUNS}`}
              />
              <StatItem
                LABEL={`OVERS BOWLED`}
                ITEM={` ${DATA.TeamStats.BOWLING.OVERS}`}
              />
              <StatItem
                LABEL={`MADIENS`}
                ITEM={` ${DATA.TeamStats.BOWLING.MADIENS}`}
              />
            </StatContainer>

            <StatContainer
              LABEL={`FIELDING`}
              DURATION={DURATION}
              TIMINGS={{
                SlideIn: 7,
                LabelPosition: 40,
                ClipIn: 15,
                SlideUp: 30,
              }}
            >
              <StatItem
                LABEL={`CATCHES`}
                ITEM={` ${DATA.TeamStats.FIELDING.CATCHES}`}
              />
            </StatContainer>
          </Stack>
        </Grid.Col>
        <Grid.Col span={7}>
          <Stack spacing="xs">
            <StatContainer
              LABEL={`Season`}
              DURATION={DURATION}
              DIRECTION={1500}
              TIMINGS={{
                SlideIn: 10,
                LabelPosition: 100,
                ClipIn: 15,
                SlideUp: 30,
              }}
            >
              <StatItem
                LABEL={`Players Used`}
                ITEM={` ${DATA.TeamStats.PLAYERS.length}`}
              />
              <StatItem
                LABEL={`Games Played`}
                ITEM={` ${DATA.General.Played}`}
              />
              <StatItem
                LABEL={`Win Ratio`}
                ITEM={` ${(
                  (DATA.General.Won / DATA.General.Played) *
                  100
                ).toFixed(0)}%`}
              />
              <StatItem
                LABEL={`BALLS FACED`}
                ITEM={`${DATA.TeamStats.BATTING.BALLS}`}
              />
            </StatContainer>

            <H3
              style={{
                color: theme.WINNERS.COPY_ALT,
                padding: ".5em 0 ",
                letterSpacing: "2px",
                clipPath: `inset(0 ${SpringToFrom(
                  7,
                  50,
                  0,
                  "Springy100"
                )}% 0 ${SpringToFrom(7, 50, 0, "Springy100")}%)`,
                transform: `translateY(${SpringToFrom(
                  0,
                  -1000,
                  0,
                  "Stiff"
                )}px) translateY(${SpringToFrom(
                  DURATION - 15,
                  0,
                  -1000,
                  "Springy100"
                )}px)`,
              }}
            >
              Participation Awards
            </H3>
            <SupImages DATA={DATA} DURATION={DURATION} />
          </Stack>
        </Grid.Col>
      </Grid>
    </>
  );
};

const StatContainer = (props) => {
  const theme = useTheme();
  const { LABEL, DURATION, TIMINGS, DIRECTION = -1000 } = props;
  /*
    TIMINGS={{
                        SlideIn:0,
                        LabelPosition:100,
                        ClipIn
                        SlideUp:75
                    }}
    */
  return (
    <FrostedBlock
      style={{
        background: theme.WINNERS.GLASS,
        transform: `translateX(${SpringToFrom(
          TIMINGS.SlideIn,
          DIRECTION,
          0,
          "Springy100"
        )}px) translateX(${SpringToFrom(
          DURATION - 15,
          0,
          DIRECTION,
          "Springy100"
        )}px)`,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        alignContent: "flex-start",
        padding: "2rem 5rem",
      }}
    >
      <H1
        style={{
          color: theme.WINNERS.COPY_PRIMARY,
          width: "100%",
          padding: "1.8rem 0rem",
          fontWeight: "900",
          fontSize: "5rem",
          textAlign: "left",
          clipPath: `inset(0 ${SpringToFrom(
            TIMINGS.ClipIn,
            50,
            0,
            "Springy100"
          )}% 0 ${SpringToFrom(TIMINGS.ClipIn, 50, 0, "Springy100")}%)`,
          transform: `translateY(${SpringToFrom(
            TIMINGS.SlideUp,
            TIMINGS.LabelPosition,
            0,
            "Springy100"
          )}px) translateY(${SpringToFrom(
            DURATION - 15,
            0,
            -1000,
            "Springy100"
          )}px)`,
        }}
      >
        {LABEL}
      </H1>
      {props.children}
    </FrostedBlock>
  );
};

const StatItem = ({ LABEL, ITEM }) => {
  const theme = useTheme();
  const start = 35;
  return (
    <Group position="apart" spacing="xl" grow style={{ width: "100%" }}>
      <H3
        style={{
          width: "50%",
          padding: ".8rem 0",
          textAlign: "left",
          fontSize: "2.2rem",
          color: theme.WINNERS.COPY_PRIMARY,
          clipPath: `inset(0 ${SpringToFrom(
            start,
            50,
            0,
            "Springy100"
          )}% 0 ${SpringToFrom(start, 50, 0, "Springy100")}%)`,
        }}
      >
        {LABEL}
      </H3>
      <H3
        style={{
          width: "50%",
          padding: ".8rem 0",
          textAlign: "left",
          fontSize: "2.8rem",
          fontWeight: "600",
          color: theme.WINNERS.COPY_SUPLIMENTARY,
          clipPath: `inset(0 ${SpringToFrom(
            start,
            50,
            0,
            "Springy100"
          )}% 0 ${SpringToFrom(start, 50, 0, "Springy100")}%)`,
        }}
      >
        {ITEM}
      </H3>
    </Group>
  );
};

const SupImages = ({ DATA, DURATION }) => {
  const theme = useTheme();
  const ThreeImg = orderBy(
    DATA.TeamStats.PLAYERS,
    ["ARR_RUNS.length"],
    ["desc"]
  ).slice(0, 3);

  return (
    <>
      <SimpleGrid cols={3} spacing="xl">
        {ThreeImg.map((IMG, i) => {
          return (
            <div
              style={{
                transform: `translateY(${SpringToFrom(
                  7 * i,
                  1000,
                  0,
                  "Springy100"
                )}px) translateY(${SpringToFrom(
                  DURATION - 15,
                  0,
                  1000,
                  "Springy100"
                )}px)`,
              }}
            >
              <H3
                style={{
                  color: theme.WINNERS.COPY_PRIMARY,
                }}
              >
                {IMG.Name}
              </H3>
              <Center>
                <Box
                  style={{
                    height: "480px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Image src={IMG.image} />
                </Box>
              </Center>
            </div>
          );
        })}
      </SimpleGrid>
    </>
  );
};

const FrostedBlock = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
`;
