import { createStyles, Container, Title, Text, Button, Image } from '@mantine/core';
import { useEffect } from 'react';
import { useGetOrdersPublicCount } from '../../Hooks/Content/useOrders';

//https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1080&q=80
const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: '#11284b',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage:
      'linear-gradient(250deg, rgba(130, 201, 30, 0) 0%, #062343 70%), url(/HeroIMG.jpg)',
    paddingTop: theme.spacing.xl * 3,
    paddingBottom: theme.spacing.xl * 3,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('md')]: {
      flexDirection: 'column',
    },
  },

  image: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  content: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    marginRight: theme.spacing.xl * 3,

    [theme.fn.smallerThan('md')]: {
      marginRight: 0,
    },
  },

  title: {
 
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    lineHeight: 1.05,
    maxWidth: 500,
    fontSize: 48,
    color:theme.colors.Public[0],

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
      fontSize: 34,
      lineHeight: 1.15,
    },
  },

  description: {
    color:theme.colors.Public[0],
    opacity: 0.75,
    maxWidth: 500,

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
    },
  },count: {
    color:theme.colors.Public[0],
    opacity: 0.75,
    fontWeight:900,
    maxWidth: 500,
    textTransform: 'uppercase',
    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
    },
  },

  control: {
    paddingLeft: 50,
    paddingRight: 50,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 22,

    [theme.fn.smallerThan('md')]: {
      width: '100%',
    },
  },
}));

export function HeroImageRight() {
  const { classes } = useStyles();
  //useGetOrdersPublic
  const [order,GetOrders]=useGetOrdersPublicCount()

  useEffect(()=>{GetOrders()},[])
  useEffect(()=>{console.log(order)},[order])


  return (
    <div className={classes.root}>
      
      <Container size="lg">
        <div className={classes.inner}>
       
        
          <div className={classes.content}>
          <Image 
          src={'/StattoLogoLight.png'}
          height={150}
          width={150}
          fit='contain'
        />
            <Title className={classes.title}>
              The{' '}
              <Text
                component="span"
                inherit
                variant="gradient"
                gradient={{ from: 'blue', to: 'green' }}
              >
               Social Media Assistant
              </Text>{' '}
              for LMS franchise owners

            </Title>

            <Text className={classes.description} mt={30}>
            Create professional looking social media videos and images for your lastmanstands franchise in minutes
            </Text>
            <Text className={classes.count} mt={0}>
              Currently  {' '}
            <Text
                component="span"
                inherit
                variant="gradient"
                gradient={{ from: 'blue', to: 'green' }}
              >
               {order} 
              </Text> videos have been created for LMS franchises.
            </Text>
            
           {/*  <Button
              variant="gradient"
              gradient={{ from: 'blue', to: 'green' }}
              size="xl"
              className={classes.control}
              mt={40}
            >
              Get started
            </Button> */}
          </div>
        </div>
       
      </Container>
    </div>
  );
}