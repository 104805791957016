import { useState } from "react" 
import {ENV} from '../../Config/RunTimeConfig'
import { parseCookies } from 'nookies'
const qs = require('qs');

// FUNC : GET SINGLE
// FUNC : CREATE
// FUNC : DELETE 

// FUNC : GET 
export const useSelectVideoTemplate= (ctx)=>{

    const [VideoTemplate,setVideoTemplate] = useState(null);
    const jwt = parseCookies(ctx).jwt
    
    const FetchVideoTemplate = async ()=>{
        const query = qs.stringify({populate: '*'}, {encodeValuesOnly: true,});
        
        const res = await fetch(`${ENV.API_URL}/api/templates?${query}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                //Authorization: `Bearer ${jwt}`
            },
        })
        const Template = await res.json();
      //console.log(Template)  

        setVideoTemplate(Template.data)
    }
    return [VideoTemplate,FetchVideoTemplate]
}


// FUNC : UPDATE 
export const useChangeDefaultTemplate= (ctx)=>{

    const [ChangeTemplate,setChangeTemplate] = useState(null);
   
    const jwt = parseCookies(ctx).jwt
    const AUTHID = parseCookies(ctx).AUTHID
    
    const PutChangeTemplate = async (_DATA)=>{
        const res = await fetch(`${ENV.API_URL}/api/users/${AUTHID}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(_DATA)
        })
        const GroupingStatus = await res.json();
      //console.log(GroupingStatus)

        setChangeTemplate(GroupingStatus)
    }
    return[ChangeTemplate,PutChangeTemplate]
}