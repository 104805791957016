// Styled Comps
import styled from "styled-components";
// Animations
import { SpringToFrom } from '../../../../../../Animation/RemotionSpring';

export const StatContainer = (props) => {
  
  const {  DURATION, HEIGHT=400} = props;

  return (
    <FrostedBlock
      style={{
        height:`${HEIGHT}px`,
        transform: `translateY(${SpringToFrom(90,390, 0,"Springy100" )}px) 
                    translateY(${SpringToFrom(DURATION -15, 0,390,"Springy100" )}px)`,
      }}
    >
      {props.children}
    </FrostedBlock>
  );
};

const FrostedBlock = styled.div`
  width: 100%;
  overflow:hidden,
  
  display: flex;
  justify-content: center;
    align-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    width: 100%;
    margin: 0%;
    overflow:hidden;
    background: rgba(255, 255, 255, 0.15);
`;
