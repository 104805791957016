/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// ACtions
import { useAuthUser } from "../../Hooks/Auth/useAuthUserMe";
import {
  useSelectVideoTheme,
  useChangeDefaultTheme,
} from "../../Hooks/Content/useVideoTheme";
import { useChangeDefaultTemplate } from "../../Hooks/Content/useVideoTemplate";

// STructure
import {
  ColoredContainer,
  VideoWizardContainer,
} from "../../Theme/Structure/BodyContainer";
import { Space, Group, Container } from "@mantine/core";

// FOrm Elements
import { SelectVideoTheme } from "../../Components/settings/SelectVideoTheme";
import { SelectVideoTemplates } from "../../Components/settings/SelectVideoTemplate";
import { BTN_CTA } from "../../Theme/Structure/Button/BTNS";
// Components
import { IsLoading } from "../../Components/Global/isLoadingDefault";
import { P } from "../../Theme/type";
import { BLOCK_Section_SubHeading_INVERT } from "../../Components/interface/BLOCK_Section_SubHeading";
export const SelectDefaultVideoSettings = () => {
  return (
    <>
      <BLOCK_Section_SubHeading_INVERT LABEL="Default Template" Color={5} />
      <Container size="lg" p={0}>
        <VideoWizardContainer>
          <Space h="sm" />
          <ColoredContainer BGColor={3} Padding={10}>
            <FetchTemplates />
          </ColoredContainer>
        </VideoWizardContainer>
      </Container>
      <Space h="lg" />
      <BLOCK_Section_SubHeading_INVERT LABEL="Default Theme" Color={5} />
      <Container size="lg" p={0}>
        <VideoWizardContainer>
          <Space h="sm" />
          <ColoredContainer BGColor={3} Padding={10}>
            <FetchThemes />
          </ColoredContainer>
        </VideoWizardContainer>
      </Container>
    </>
  );
};

const FetchThemes = () => {
  const AUTH = useSelector((state) => state.AUTH);
  const [authUser, FetchauthUser] = useAuthUser();
  const [ChangeTheme, PutChangeTheme] = useChangeDefaultTheme();
  const [Theme, setTheme] = useState(AUTH.AUTHUSER.theme.Name);
  const [updating, setupdating] = useState(false);
  const [changeOption, setchangeOption] = useState(false);

  const handleChange = (_DATA) => {
    setupdating(true);
    PutChangeTheme({ theme: _DATA.id });
    FetchauthUser();
  };
  const handleClick = () => {
    setchangeOption(!changeOption);
  };

  useEffect(() => {
    setupdating(false);
    setchangeOption(false);
    setTheme(AUTH.AUTHUSER.theme.Name);
  }, [AUTH]);
  if (updating) return <IsLoading />;
  return (
    <Group position="apart">
      <P colorID={0}>{`Selected : ${Theme}`}</P>
      <div>
        {changeOption ? (
          <SelectVideoTheme CALLBACK={handleChange} />
        ) : (
          <BTN_CTA
            CALLBACK={handleClick}
            LABEL={changeOption ? `Back` : `Change Theme`}
          />
        )}
      </div>
    </Group>
  );
};

/*TEMPLATES */
const FetchTemplates = () => {
  const AUTH = useSelector((state) => state.AUTH);
  const [authUser, FetchauthUser] = useAuthUser();

  const [ChangeTemplate, PutChangeTemplate] = useChangeDefaultTemplate();
  const [Theme, setTheme] = useState(AUTH.AUTHUSER.template.Name);
  const [updating, setupdating] = useState(false);
  const [changeOption, setchangeOption] = useState(false);

  const handleChange = (_DATA) => {
    setupdating(true);
    PutChangeTemplate({ template: _DATA.id });
    FetchauthUser();
  };
  const handleClick = () => {
    setchangeOption(!changeOption);
  };

  useEffect(() => {
    setupdating(false);
    setchangeOption(false);
    setTheme(AUTH.AUTHUSER.template.Name);
  }, [AUTH]);
  if (updating) return <IsLoading />;
  return (
    <Group position="apart">
      <P colorID={0}>{`Selected : ${Theme}`}</P>
      <div>
        {changeOption ? (
          <SelectVideoTemplates CALLBACK={handleChange} />
        ) : (
          <BTN_CTA
            CALLBACK={handleClick}
            LABEL={changeOption ? `Back` : `Change Template`}
          />
        )}
      </div>
    </Group>
  );
};
