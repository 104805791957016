/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { useGetOrders } from "../../Hooks/Content/useOrders";
import { Container, Group, SimpleGrid } from "@mantine/core";

// Components
import { PreviewVideoModal } from "../../Components/Global/PlayVideoModal";

import { orderBy } from "lodash";

import { SelectVideoSponsor } from "../../Components/settings/SelectVideoSponsor";
import { SelectAssetType } from "../../Components/settings/SelectAssetType";
import { UISkeletonLines } from "../../Components/Global/Skeleton";

// UI ELEMENTS
import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";
import { BLOCK_ToolBar } from "../../Components/interface/BLOCK_Toolbar";

import {
  DownloadItemCard,
  DownloadTableRow,
} from "./Components/DownloadItemWrapper/index";
import { TABLE_WRAPPER } from "../../Components/interface/Wrapper/Table";
import { VideoWizardContainer } from "../../Theme/Structure/BodyContainer";

const ProcessedDownloads = () => {
  const [orders, GetOrders] = useGetOrders();
  const [RefreshRender, setRefreshRender] = useState(true);
  const [opened, setOpened] = useState(false);
  const [videoURL, setVideoURL] = useState(false);
  const [FilterItems, setFilterItems] = useState({
    FromDate: false,
    Type: false,
    Sponsor: false,
  });
  const SetVideoModal = (Item) => {
    //console.log(Item[0].attributes.DownloadURI);
    setOpened(true);
    setVideoURL(Item[0].attributes.DownloadURI);
  };

  useEffect(() => {
    if (RefreshRender) GetOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RefreshRender]);

  useEffect(() => {
    setRefreshRender(false);
  }, [orders]);

  if (orders === null) return <UISkeletonLines />;
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Downloadables",
          ICON: "ChartDots",
          DESCRIPTION: `Showing ${orders.length} Results`,
          TOOLBAR: false,
        }}
      />

      <Container size="lg" p={20}>
        <VideoWizardContainer>
        <TABLE_WRAPPER>
          <tbody>
            {orderBy(orders, ["attributes.createdAt"], ["desc"]).map(
              (DOWNLOAD, i) => {
                console.log(DOWNLOAD)
                return (
                  <DownloadTableRow
                    DOWNLOAD={DOWNLOAD}
                    key={i}
                    SetVideoModal={SetVideoModal}
                    setRefreshRender={setRefreshRender}
                    RefreshRender={RefreshRender}
                    FilterItems={FilterItems}
                  />
                );
              }
            )}
          </tbody>
        </TABLE_WRAPPER>
        </VideoWizardContainer>
      </Container>
      <PreviewVideoModal
        setOpened={setOpened}
        opened={opened}
        videoURL={videoURL}
      />
    </>
  );
};
//

export default ProcessedDownloads;
