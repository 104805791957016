import { useEffect, useState } from "react";
import { useCurrentFrame } from "remotion";
import { useTheme } from "styled-components";
// Components
/* import {FixtureResultsUL} from "./FixtureResultsUL" */
import styled from "styled-components";
import { H3, H1 } from "../../../../utils/Text";

// Components
import { Image, Box, Group } from "@mantine/core";
import { interpolateOpacityByFrame } from "../../../../../Animation/interpolate";
import { SpringToFrom } from "../../../../../Animation/RemotionSpring";
import { FromMiddle } from "../../../../../Animation/ClipWipe";

const SeriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  flex-wrap: wrap;
  list-style: none;
`;
const SideBar = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: column;
  height: 33%;
  margin: 0;
  box-sizing: border-box;
  z-index: 1000;
  position: absolute;
  bottom: 0;
  backdrop-filter: blur(10px);
  box-shadow: 0px -30px 50px rgba(0, 0, 0, 0.5);
`;

const StatLabelBG = styled.div`
  position: absolute;
  height: 60px;
  width: 100%;
  bottom: 0px;
  z-index: 5000;
  
`;

export const SeriesWrapper = (props) => {
  return <SeriesContainer>{props.children}</SeriesContainer>;
};

export const CONTENTBG = (props) => {
  const { Duration } = props;
  const theme = useTheme();
  return (
    <SideBar
      style={{
       
        background: theme.BackgroundGradient,
        width: `${SpringToFrom(0, 0, 100)}% `,
        transform: `translateY(${SpringToFrom(Duration - 35, 0, 100)}%)`,
      }}
    >
      {props.children}
    </SideBar>
  );
};
export const SECTIONLABEL = ({ LABEL, Duration }) => {
  const theme = useTheme();

  return (
    <StatLabelBG
      style={{
        width: `${SpringToFrom(7, 0, 100, "Springy100")}%`,
        
        left: 0,
        transform: `translateY(${SpringToFrom(
          Duration - 35,
          0,
          200,
          "Springy100"
        )}px)`,
      }}
    >
      <H1
        style={{
          fontSize: "50px",
          zIndex: "3000",
          fontWeight:600,
          letterSpacing:'2px',
          width:'100%',
          color: theme.Copy[0],
          transform: `translateY(${SpringToFrom(
            30,
            100,
            0,
            "Springy100"
          )}px) translateY(${SpringToFrom(
            Duration - 35,
            5,
            100,
            "Springy100"
          )}px)`,
        }}
      >
        {LABEL}
      </H1>
    </StatLabelBG>
  );
};

export const PlayerImage = ({ DATA, IMG, INT = 1 }) => {
  const IMGSIZE = "100%";
  const frame = useCurrentFrame();

  return (
    <Image
      radius={10}
      height={IMGSIZE}
      width={IMGSIZE}
      src={IMG.url}
      style={{
        padding: "2%",
        position: "absolute",
        zIndex: "100",
        top: "10px",

        clipPath: FromMiddle(0, "Springy100"),
        opacity: interpolateOpacityByFrame(frame, 145, 180, 1, 0),
        transform: `scale(${interpolateOpacityByFrame(
          frame,
          0,
          145,
          1,
          1.1
        )}) scale(${SpringToFrom(145, 1.1, 0, "Springy100")})`,
      }}
    />
  );
};



export const StatItem = ({ stat, STATITEMOBJ, I }) => {
  const theme = useTheme();

  
  return (
    <Box
      style={{
        position:'relative',
        border: `1px solid ${theme.Base[0]}`,
        height: "160px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        padding: "1%",
        alignContent: "center",
        borderRadius: "0px",
        alignItems: "center",
        backgroundColor: theme.Base[0], 
        borderTop:`5px solid ${theme.Base[1]}`,
        //borderBottom:`2px solid ${theme.Copy[0]}`,
        boxShadow: "8px 8px 20px rgba(0, 0, 0, 0.15)",
        transform: `translateY(${SpringToFrom(
          30 + 3 * I,
          300,
          STATITEMOBJ.TOP,
          "Springy100"
        )}%) translateY(${SpringToFrom(
          STATITEMOBJ.END,
          STATITEMOBJ.TOP,
          300,
          "Springy100"
        )}%)`,
        width: STATITEMOBJ.WIDTH,
      }}
    >
      <H3
        style={{
          color: theme.Copy[2],
          fontSize: "60px",
          marginBottom: "10px",
          fontWeight: 900,
          letterSpacing:'0px',
          
        }}
      >
        {stat.Value}
      </H3>
      <H3
        style={{
          color: theme.Copy[0],
          fontSize: "40px",
        }}
      >
        {stat.Label}
      </H3>
    </Box>
  );
};

export const GroupStats = (props) => {
  return (
    <Group position="apart" spacing="xs" style={{ width: "96%", margin:'0 2%' }} grow>
      {props.children}
    </Group>
  );
};
