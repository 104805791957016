import {useCurrentFrame} from 'remotion';
import {IntroContainer,} from "../../utils/Containers"
import {OpeningDate} from "../../utils/Text"
import { useTheme } from 'styled-components';
import moment from 'moment';

//import {SpringToFrom} from '../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../Animation/interpolate'
import {FromMiddle} from '../../../Animation/ClipWipe'
export const DisplayVideoDate=({VideoDate})=>{
    const theme = useTheme();
    //console.log(VideoDate)
    const frame = useCurrentFrame();


    const DisplayDates =()=>{
        const START = moment(VideoDate[0]).format('DD MMMM')
        const END = moment(VideoDate[1]).format('DD MMMM')
        return START===END ? `${START}`:`${START} - ${END}`
    }


    if(!VideoDate)
    return false
    return(
        <IntroContainer>
            
                <OpeningDate style={{
                    color:theme.Base[4],
                    clipPath:FromMiddle(30,'Springy100'),
                    opacity:interpolateOpacityByFrame(frame, 30,60, 0, 1),
                    //transform: `translateY(${SpringToFrom(30,20,0,'Springy100','slow')}px)`
                }}
                    >
                    { DisplayDates()}  
                </OpeningDate> 
        </IntroContainer>
    )
}
