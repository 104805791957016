import { useCurrentFrame, useVideoConfig, spring } from "remotion";
import { useTheme } from "styled-components";
import { LineDashed } from "tabler-icons-react";
import {
  FindIMGFORMAT,
  FindTopBatting,
  FindTopBowling,
  TrimPlayerName,
} from "../../../../../../actions/UX";
import {
  MOMContainer,
  MOMInnerContainer,
  TeamLogoRight,
  PlayerPerformances,
  PerformanceContainer,
  ResultStatment,
  HighlightStatment,
} from "../../../utils/Containers";
import { H2, H3, H4, P } from "../../../utils/Text";

export const PlayerPerformanceDetails = (props) => {
  const { GAME, RenderType } = props;
  //console.log(GAME)
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const theme = useTheme();
  /* 
  const PLAYERLOGO = ()=>{	 	    
    const PLAYERIMG = FindIMGFORMAT(GAME.attributes?.player_moms.data[0]?.attributes.player.data?.attributes.image.data?.attributes.formats)
    //console.log(GAME.attributes?.player_moms, PLAYERIMG);
    if(RenderType === 'live')
          return '/PlayerPreviewPlaceholder.png'
            return PLAYERIMG ? PLAYERIMG.url:'/PlayerPreviewPlaceholder.png'

}  */

  const SpringConfig = {
    Springy900: {
      damping: 10,
      stiffness: 200,
      mass: 1,
    },
    Springy500: {
      damping: 10,
      stiffness: 200,
      mass: 1,
    },
    Springy100: {
      damping: 10,
      stiffness: 150,
      mass: 0.3,
    },
    Stiff: {
      damping: 80,
      stiffness: 100,
      mass: 0.1,
    },
  };
  const StaggerSpringIn = (int) => {
    return spring({
      fps,
      from: 0,
      to: 1,
      frame: frame - int,
      config: SpringConfig.Springy100,
    });
  };

  console.log(GAME);
  return (
    <>
      <HighlightStatment>
        {GAME.homeTeam} vs {GAME.awayTeam}
      </HighlightStatment>

      <PlayerPerformances>
        {GAME.topBatting.map((player, i) => {
          return (
            <PerformanceContainer key={i}>
              <H4
                style={{
                  letterSpacing: "0px",
                  fontWeight: 400,
                  color: theme.Copy[0],
                  transform: `scale(${StaggerSpringIn(0)}) `,
                }}
              >
                {TrimPlayerName(player.attributes.player.data.attributes.Name)}
              </H4>
              <H4
                style={{
                  letterSpacing: "0px",
                  fontWeight: 400,
                  transform: `scale(${StaggerSpringIn(7)}) `,
                }}
              >
                {player.attributes.BATTING_Runs} (
                {player.attributes.BATTING_Balls}){" "}
              </H4>
            </PerformanceContainer>
          );
        })}

        {GAME.topBowling.map((player, i) => {
          return (
            <PerformanceContainer key={i}>
              <H4
                style={{
                  letterSpacing: "0px",
                  fontWeight: 400,
                  color: theme.Copy[0],
                  transform: `scale(${StaggerSpringIn(15)}) `,
                }}
              >
                {TrimPlayerName(player.attributes.player.data.attributes.Name)}{" "}
              </H4>
              <H4
                style={{
                  letterSpacing: "0px",
                  fontWeight: 400,
                  color: theme.Copy[0],
                  transform: `scale(${StaggerSpringIn(22)}) `,
                }}
              >
                {player.attributes.BOWLING_Wkts}/
                {player.attributes.BOWLING_Runs} (
                {player.attributes.BOWLING_Overs}){" "}
              </H4>
            </PerformanceContainer>
          );
        })}
      </PlayerPerformances>
      {GAME.MOM.Name !== undefined ? (
        <MOM GAME={GAME} StaggerSpringIn={StaggerSpringIn} />
      ) : (
        false
      )}

      <ResultStatment>{GAME.resultStatement}</ResultStatment>
    </>
  );
};

const MOM = ({ GAME, StaggerSpringIn }) => {
  return (
    <MOMContainer>
      <TeamLogoRight
        style={{
          backgroundImage: `url(${GAME.MOM.Image})`,
          transform: `scale(${StaggerSpringIn(0)}) `,
        }}
      ></TeamLogoRight>
      <MOMInnerContainer>
        <H2>M.O.M</H2>
        <H3 style={{ fontWeight: 600, lineHeight: "1.5em" }}>
          {TrimPlayerName(GAME.MOM.Name)}
        </H3>
      </MOMInnerContainer>
    </MOMContainer>
  );
};
