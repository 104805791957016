import { Series } from "remotion";
// Global
// Components
import { OuterContainer } from "./OuterWrapper";
import { EndCredits } from "./TAIL_End_Credits";
import { OpeningSponsorSequence } from "./TOP_Sponsor_Sequence";
import {
  BasicOpeningTitles,
  DIVISIONWINNERSOPENINGTITLES, 
} from "./TOP_Opening_Titles";
import { TEAMSEASONALREVIEWOPENINGTITLES } from "./TeamSeasonalReviewOpeningTitles";

export const TopAndTail = (props) => {
  //const frame = useCurrentFrame();
  const {RENDER} = props
  console.log(RENDER)
  return (
    <OuterContainer>
      <Series>
        <Series.Sequence durationInFrames={90}>
          <BasicOpeningTitles
            MainTitle={props.MainTitle} 
            Subtitle={props.Subtitle} 
            VideoDate={RENDER.INPUTDATA.DATERANGE}
           
          />
        </Series.Sequence> 

        {RENDER.SPONSORSHIP !== false && RENDER.SPONSORSHIP !== null? (
          <Series.Sequence durationInFrames={120}>
            <OpeningSponsorSequence {...props} /> 
          </Series.Sequence>
        ) : (
          false
        )}

        <Series.Sequence
          durationInFrames={RENDER.DATAOBJ.VideoSettings.VideoBodyTiming}
        >
          {props.children}
        </Series.Sequence>
        <Series.Sequence durationInFrames={120}>
          <EndCredits {...props} />
        </Series.Sequence>
      </Series>
    </OuterContainer>
  );
};

// Bespoke Top and Tails
export const DivisionWinnersTopAndTail = (props) => {
  //const frame = useCurrentFrame();
  const {DATA} = props
  console.log(DATA)
  return (
    <OuterContainer>
      <Series>
        <Series.Sequence durationInFrames={90}>
          <DIVISIONWINNERSOPENINGTITLES
            MainTitle={props.MainTitle}
            Subtitle={props.Subtitle}
            VideoDate={props.DATA}
          />
        </Series.Sequence>

        <Series.Sequence
          durationInFrames={DATA.DATAOBJ.VideoSettings.VideoBodyTiming}
        >
          {props.children}
        </Series.Sequence>
        {DATA.SPONSORSHIP !== false ? (
          <Series.Sequence durationInFrames={120}>
            <OpeningSponsorSequence  RENDER={DATA} />
          </Series.Sequence>
        ) : (
          false
        )}
        <Series.Sequence durationInFrames={120}>
          <EndCredits {...props} />
        </Series.Sequence>
      </Series>
    </OuterContainer>
  );
};

export const TeamSeasonReviewTopAndTail = (props) => {
  const {DATA} = props
  console.log(DATA)
  return (
    <OuterContainer>
      <Series>
        <Series.Sequence durationInFrames={90}>
          <TEAMSEASONALREVIEWOPENINGTITLES
             MainTitle={props.MainTitle}
             Subtitle={props.Subtitle}
             VideoDate={props.DATA}
          />
        </Series.Sequence>

        <Series.Sequence
         durationInFrames={DATA.DATAOBJ.VideoSettings.VideoBodyTiming}
        >
          {props.children}
        </Series.Sequence>

        <Series.Sequence durationInFrames={120}>
          <EndCredits {...props} />
        </Series.Sequence>
      </Series>
    </OuterContainer>
  );
};
