import { ThemeProvider } from 'styled-components'


// Import Design Templates for MATCHDAYRESULT.
// Add new deisng patterns below
// GLASS
import {DIVISIONWINNERS_4by5} from '../../theme/Templates/GLASS/index'
//import {TOURNAMENT_WINNERS} from '../../theme/Templates/TOURNAMENT_GLASS/index'
//import {ARROWS_WINNERS} from '../../theme/Templates/ARROWS/index'
// END


export const Build_DIVISIONWINNERS_4by5 = (props)=>{
	const { RENDER } = props;
	const TEMPLATES={
		"Glass":<DIVISIONWINNERS_4by5  RENDER={RENDER} />, 
		//"TOURNAMENT_GLASS":<TOURNAMENT_WINNERS RENDER={RENDER} />,
		//"Arrows":<ARROWS_WINNERS  RENDER={RENDER} />  
	} 
    return (     
		<ThemeProvider theme={RENDER.THEME.VideoTheme.Theme}>
		{TEMPLATES[RENDER.THEME.VideoTemplate]}
	  </ThemeProvider>    
    );    
}
