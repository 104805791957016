import { createStyles, Text, Container, Title } from "@mantine/core";
import { VideoWizardContainer } from "../../Theme/Structure/BodyContainer";

const useStyles = createStyles((theme) => ({
  title: {
    color: theme.colors.Public[1],
    fontFamily: `"Roboto", ${theme.fontFamily}`,
  },
  description: {
    maxWidth: 600,
    margin: "auto",
    color: theme.colors.Public[1],
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.colors.Public[2],
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export function GiveitaGo() {
  const { classes } = useStyles();
  return (
    <>
      <Container size={"sm"} py={80}>
        <Title
          order={2}
          className={classes.title}
          align="center"
          mt="md"
          mb={30}
        >
          Give it A go
        </Title>
        <Text
          
          
          align="center"
       
          className={classes.description}
          sx={(theme) => ({
            color: theme.colors.brand[3],
            fontFamily: "Roboto",
          })}
        >
          Explore our selection of curated video assets from the most recent Summer season in Australia! Customize your video with our wide range of templates, themes, and music options. Simply select a video asset and data set to view and start creating!
        </Text>
       
      </Container>
    </>
  );
}
