import { useEffect, useState } from "react";
import { Radio } from "@mantine/core";
import { useGetRegionalSeasons } from "../../Hooks/Content/useRegionalSeasons";
import { find,omit } from "lodash";

export const RadioDataset = ({ CALLBACK, DefaultValue }) => {
  const [getRegionalSeasons, FetchRegionalSeasons] = useGetRegionalSeasons();
  const [value, setValue] = useState(DefaultValue);
  const handleChange = (e) => {
    const THEME = find(getRegionalSeasons, (o) => {
      return o.attributes.Name === e;
    });
 
    
    CALLBACK(THEME);
    setValue(THEME.attributes.Name);
  };

  useEffect(() => {
    FetchRegionalSeasons();
  }, []);
  useEffect(() => {}, [getRegionalSeasons]);
  if (getRegionalSeasons === null) return <>LOADING</>;
  return (
    <Radio.Group
      orientation="vertical"
      label="Select a Dataset"
      description=""
      spacing="sm"
      size="lg"
      value={value}
      onChange={handleChange}
    >
      {getRegionalSeasons.map((Season, i) => {
        if (!Season.attributes.isComplete)
          return (
            <Radio
              color="green"
              value={Season.attributes.Name}
              label={Season.attributes.Name}
              key={i}
            />
          );
      })}
    </Radio.Group>
  );
};
