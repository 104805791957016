/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
// HOOKS
import { useGetRegionalSeasons } from "../../../Hooks/Content/useRegionalSeasons";

import { Container, Radio, Space } from "@mantine/core";

// UI Elements
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
import { BLOCK_ToolBar } from "../../../Components/interface/BLOCK_Toolbar";

//import { PageHeader } from "../../../Components/interface/ProductHeader";
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";
import { SelectRegionSeason } from "../../../Components/settings/SelectRegionSeason";

// Batting
import { PlayerStatsTableBatting } from "./PlayerListBatting";
import { SeasonBattingAverages } from "./BattingAverages";
import { SeasonBattingFunFacts } from "./BattingFunFacts";
// BOwling
import { SeasonBowlingAverages } from "./BowlingAverages";
import { PlayerStatsTableBowling } from "./PlayerListBowling";

export const PlayersContainer = () => {
  //const UXSTATE = useSelector((state) => state.UXSTATE);
  const [getRegionalSeasons, FetchRegionalSeasons] = useGetRegionalSeasons();
  const [SeasonID, setSeasonID] = useState(false);
  const [RadioChange, setRadioChange] = useState("Batting");
  useEffect(() => {
    FetchRegionalSeasons();
  }, []);
  useEffect(() => {
  // console.log(getRegionalSeasons);
  }, [getRegionalSeasons]);
  useEffect(() => {
  // console.log(SeasonID);
  }, [SeasonID]);

  if (getRegionalSeasons === null) return <>Loading Data</>;
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Player Statistics Overview",
          ICON: "ChartDots",
          DESCRIPTION: "Isolate a players season overview by dataset",
          TOOLBAR: false,
        }}
      />

      <Container size="lg"  p={0}>
        <Space h="md" />
        <BLOCK_ToolBar
          TOOLS={[
            <SelectBatBowl setRadioChange={setRadioChange} />,
            <SelectRegionSeason
              DATA={getRegionalSeasons}
              CALLBACK={setSeasonID}
            />,
          ]}
        />

        <Space h="xl" />

        {!SeasonID ? (
          false
        ) : (
          <BLOCK_Section_SubHeading
            LABEL={`Display ${RadioChange} Statistics for ${SeasonID.attributes?.Name}`}
            Color={5}
          />
        )}

        <Space h="md" />
      </Container>
      {RadioChange === "Batting" ? (
        <BattingGroup SeasonID={SeasonID} />
      ) : (
        <BowlingGroup SeasonID={SeasonID} />
      )}
    </>
  );
};

const BattingGroup = ({ SeasonID }) => {
  return (
    <>
      <SeasonBattingAverages SeasonID={SeasonID} />
      <SeasonBattingFunFacts SeasonID={SeasonID}/>
      <PlayerStatsTableBatting SeasonID={SeasonID} />
    </>
  );
};

const BowlingGroup = ({ SeasonID }) => {
  return (
    <>
      <SeasonBowlingAverages SeasonID={SeasonID} />
      <PlayerStatsTableBowling SeasonID={SeasonID} />
    </>
  );
};

const SelectBatBowl = ({ setRadioChange }) => {
  const handleRadioChange = (e) => {
    setRadioChange(e);
  };
  return (
    <Radio.Group
      onChange={handleRadioChange}
      name="favoriteFramework"
      label=""
      description=""
      spacing="xs"
      offset={0}
      size="lg"
    >
      <Radio color="teal" value="Batting" label="Batting" />
      <Radio color="teal" value="Bowling" label="Bowling" />
    </Radio.Group>
  );
};
