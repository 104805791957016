import { Group, SimpleGrid } from "@mantine/core";

import {
  SECTIONLABEL,
  PlayerImage,
  StatItem,
  CONTENTBG,
  SeriesWrapper,
  GroupStats
} from "./UIELEMENTS";

export const BowlingStats = ({ DATA, Duration, IMG }) => {
  const STATITEMOBJ = {
    WIDTH: "100%",
    TOP: 10,
    END: 160,
  };
  const OBJ = [
    { LABEL: "WICKETS", PATH: "WICKETS" },
    { LABEL: "BF", PATH: "BESTFIGURES" },
    { LABEL: "Average", PATH: "BOWLINGAVERAGE" },
    { LABEL: "ECONOMY", PATH: "ECONOMY" },
    { LABEL: "OVERS", PATH: "OVERS" },
    { LABEL: "W3", PATH: "W3" },
  ];

  return (
    <SeriesWrapper>
      <SECTIONLABEL LABEL={"Bowling Career"} Duration={Duration} />
      <PlayerImage IMG={IMG} DATA={DATA} INT={3} />

      <CONTENTBG Duration={Duration}>
        
          
          <SimpleGrid cols={3} style={{ width:'100%'}}>
          {OBJ.map((ITEM, i) => {
            return (
              <StatItem
                key={i}
                I={i}
                stat={{
                  Label: ITEM.LABEL,
                  Value: DATA.DATAOBJ[ITEM.PATH],
                }}
                STATITEMOBJ={STATITEMOBJ}
              />
            );
          })}
        </SimpleGrid>
      </CONTENTBG>
    </SeriesWrapper>
  );
};
