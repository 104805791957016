import { useState } from "react" 
import {ENV} from '../../Config/RunTimeConfig'
import { parseCookies } from 'nookies'
const qs = require('qs');

// FUNC : GET SINGLE
// FUNC : CREATE
// FUNC : DELETE 


// FUNC : GET 
export const useAudio= (ctx)=>{

    const [VideoAudio,setVideoAudio] = useState(null);
    const jwt = parseCookies(ctx).jwt
    
    const FetchVideoTheme = async ()=>{
        const query = qs.stringify({
           populate: '*',  
          }, {
            encodeValuesOnly: true,
          });

        const res = await fetch(`${ENV.API_URL}/api/audio-options?${query}`, { 
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                //Authorization: `Bearer ${jwt}`
            },
          
        })
        const Themes = await res.json();
      console.log(Themes)

      setVideoAudio(Themes.data)
    }
    return [VideoAudio,FetchVideoTheme]
}


// FUNC : UPDATE 