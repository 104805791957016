import { Container } from "@mantine/core"

export const PageWrapper = (props)=>{
    return(
        <Container fluid={true} 
        sx={(theme) => ({
            backgroundColor: theme.colors.brand[0],
            padding:'0'
          })}>
            {props.children}
        </Container>
    )
}



export const SectionWrapper = (props)=>{
    const { BGCOLOR=0 } = props
    return(
        <Container fluid={true} 
            sx={(theme) => ({
            backgroundColor: theme.colors.Public[BGCOLOR],
            padding:'0px 0 0 0',
            position:'relative',
                overflow:'hidden'
            
          })}>
            {props.children}
        </Container>
    )
}
