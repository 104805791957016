import { useEffect } from "react"
import { Select } from '@mantine/core';
import {find} from 'lodash'
import {useGetSponsors} from '../../Hooks/Content/useSponsors'
import { useMediaQuery } from "@mantine/hooks";

export const SelectVideoSponsor = ({CALLBACK, LABEL='Sponsor',Placeholder="Is this video sponsored?"})=>{
  
  const Width = useMediaQuery("(min-width: 769px)");
  
  const [Sponsors, GetSponsors] = useGetSponsors()
  const handleChange=(e)=>{
        if(e === false){
            CALLBACK(false)
        }else{
            const SPONSOR = find(Sponsors, (o)=>{return o.id === e})
            CALLBACK(SPONSOR)
        }
    }

  const CreateDATA=()=>{
    const ARR=[{value: false, label:'No Sponsor'}]
    Sponsors.map((ITEM,i)=>{
      ARR.push({ value: ITEM.id, label: ITEM.attributes.Name })
    })
    return ARR
  }
 


  useEffect(()=>{  GetSponsors() },[])
  useEffect(()=>{ },[Sponsors])
    
    if(Sponsors === null)
    return(<>Loading</>)
    return (
        <Select
          label={LABEL} 
          
          placeholder={Placeholder}
          data={CreateDATA()} 
          onChange={handleChange}
          styles={(theme) => ({
            root:{
              width:Width?'auto':'100%'
            },
            input:{
              '&, &:focus':{
                borderColor: theme.colors.brand[5],
                color: theme.colors.brand[5]
              }
            },
            item: {
              // applies styles to selected item
              '&[data-selected], &:focus': {
                '&, &:hover, &:focus': {
                  backgroundColor:theme.colors.brand[3],
                  color: theme.colors.brand[4],
                },
              },
    
              // applies styles to hovered item (with mouse or keyboard)
              '&[data-hovered], :focus': {
                backgroundColor:theme.colors.brand[5],
                color: theme.colors.brand[0],
              },
            },
          })} 
        />
      );
}