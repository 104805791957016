import { useRef,useEffect,useState } from 'react';
// Components
import {FullWidthWrapper} from '../../utils/Containers'
import {DisplayTop5List} from './Series/Top5List'


export const  TOP5_BODY =({DATA,VARIABLE})=>{
  const [Dimensions, setDimensions] = useState([]);
  
  const ref = useRef(null);
  useEffect(() => {
    setDimensions([ref.current.clientHeight,ref.current.clientWidth]);
  }, []);

  return(
    <FullWidthWrapper ref={ref}> 
        <DisplayTop5List  DATA={DATA} Dimensions={Dimensions} VARIABLE={VARIABLE}/>
    </FullWidthWrapper>   
  )
} 