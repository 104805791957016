import { useState } from "react";
import { ENV } from "../../Config/RunTimeConfig";
import { parseCookies } from "nookies";

const qs = require("qs");

// FUNC : CREATE

// FUNC : DELETE

// FUNC : GET
export const useGetFixtures = () => {
  const [Fixtures, setFixtures] = useState(false);

  const GetFixtures = async (_ID) => {
    const IDS = typeof _ID.id === "undefined" ? _ID : [_ID.id];
    const query = qs.stringify(
      {
        pagination: {
          pageSize: 100,
        },
        filters: {
          watch_list: {
            id: {
              $in: IDS,
            },
          },
        },
        populate: {
          ground: {
            populate: "*",
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

    const res = await fetch(`${ENV.API_URL}/api/fixtures?${query}`);
    const DataReturn = await res.json();
    //console.log(DataReturn)
    setFixtures(DataReturn.data);
  };
  return [Fixtures, GetFixtures];
};

// FUNC : GET CUSTOM STRIPED VERSION
// this func is for the Franchise DAta PAge, it could pull in a lot of data, so we have removed some of the items
export const useGetStripedDownFixtures = () => {
  const [Fixtures, setFixtures] = useState(false);

  const GetFixtures = async (_ID, _DATERANGE) => {
    //const IDS = typeof _ID.id === 'undefined' ? _ID: [_ID.id]
    const FROM = new Date(_DATERANGE[0]);
    const TO = new Date(_DATERANGE[1]);
    let STARTDATE = Math.floor(FROM.getTime() - 1 * 24 * 60 * 60 * 1000) / 1000;
    let ENDDATE = Math.floor(TO.getTime()) / 1000;

    //console.log(STARTDATE, ENDDATE)
    const query = qs.stringify(
      {
        populate: {
          player_bowlings: {
            populate: "*",
          },
          player_battings: {
            populate: "*",
          },
          player_moms: {
            populate: "*",
          },
          teams: {
            populate: ["Logo"],
          },
        },
        pagination: {
          pageSize: 1000,
        },
        filters: {
          $and: [
            {
              UnixTime: {
                $gte: STARTDATE,
              },
            },
            {
              UnixTime: {
                $lte: ENDDATE,
              },
            },
          ],
          watch_list: {
            id: {
              $in: _ID,
            },
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

    const res = await fetch(`${ENV.API_URL}/api/fixtures?${query}`);
    const DataReturn = await res.json();
    //console.log(DataReturn)
    setFixtures(DataReturn.data);
  };
  return [Fixtures, GetFixtures];
};

// //api/fixtures/:id

// FUNC : GET CUSTOM FIXTURES FROM WATCHLIST ID
// this hook is used for the Fetch a Match video
export const useGetUpcomingFixtures = () => {
  const [Fixtures, setFixtures] = useState(false);
  let d = new Date();
  let Now = Math.floor(new Date(Date.now() -1 * 24 * 60 * 60 * 1000) / 1000);
  let Fortnight = Math.floor(
    new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) / 1000
  );

  const GetFixtures = async (_ID) => {
    setFixtures(false);
    const _IDS = [];
    _ID.map((id, i) => {
      _IDS.push(id.id);
    });
    //console.log(_IDS)

    const query = qs.stringify(
      {
        pagination: {
          pageSize: 1000,
        },
        filters: {
          UnixTime: {
            $gte: Now,
            $lte: Fortnight,
          },
          watch_list: {
            id: {
              $in: _IDS,
            },
          },
        },
        populate: {
          ground: {
            populate: "*",
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

    const res = await fetch(`${ENV.API_URL}/api/fixtures?${query}`);
    const DataReturn = await res.json();
    //console.log(DataReturn)
    setFixtures(DataReturn.data);
  };
  return [Fixtures, GetFixtures];
};

// FUNC : GET CUSTOM FIXTURES FROM WATCHLIST ID
// this hook is used for the Fetch a Match for the Player of teh Match Video
export const useGetPreviousFixtures = () => {
  const [Fixtures, setFixtures] = useState(false);
  let d = new Date();
  let Now = Math.floor(new Date() / 1000);
  let Fortnight = Math.floor(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) / 1000
  );

  const GetFixtures = async (_ID) => {
    setFixtures(false);
    const _IDS = [];
    _ID.map((id, i) => {
      _IDS.push(id.id);
    });
    //console.log(_IDS)

    const query = qs.stringify(
      {
        pagination: {
          pageSize: 1000,
        },
        filters: {
          UnixTime: {
            $lte: Now,
            $gte: Fortnight,
          },
          watch_list: {
            id: {
              $in: _IDS,
            },
          },
        },
        populate: {
          ground: {
            populate: "*",
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

    const res = await fetch(`${ENV.API_URL}/api/fixtures?${query}`);
    const DataReturn = await res.json();
    //console.log(DataReturn)
    setFixtures(DataReturn.data);
  };
  return [Fixtures, GetFixtures];
};

// FUNC : GET SINGLE
export const useGetFixture = (ctx) => {
  const [getFixture, setgetFixture] = useState(null);
  const jwt = parseCookies(ctx).jwt;

  const query = qs.stringify(
    {
      populate: {
        //teams: {populate: "*"},
        player_battings: { populate: "*" },
        player_bowlings: { populate: "*" },
        player_moms: { populate: "*" },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const FetchGetFixture = async (_ID) => {
    const res = await fetch(`${ENV.API_URL}/api/fixtures/${_ID}?${query}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    });
    const Results = await res.json();
    //console.log(Results);
    setgetFixture(Results.data);
  };
  return [getFixture, FetchGetFixture];
};

// Get single fixture based on Fixture ID
export const useGetFixtureForComentaty = () => {
  const [Fixture, setFixture] = useState(false);

  const GetFixture = async (ID) => {
    // If set to false , the return UI to baseline state
    if (ID === false) {
      setFixture(false);
    } else {
      const query = qs.stringify(
        {
          pagination: {
            pageSize: 100,
          },
          filters: {
            fixtureID: {
              $eq: ID,
            },
          },
          populate: {
            watch_list: {
              fields: ["Name", "STATS"],
            },
            teams: {
              populate: "*",
            },
            ground: {
              populate: "*",
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      const res = await fetch(`${ENV.API_URL}/api/fixtures?${query}`);
      const DataReturn = await res.json();
      //console.log(DataReturn)
      setFixture(DataReturn.data);
    }
  };
  return [Fixture, GetFixture];
};

export const useGetFixtureForTeamFromLeague = () => {
  const [Fixtures, setFixtures] = useState(false);

  const GetFixtures = async (ID, LEAGUE) => {
    setFixtures(false);
    const query = qs.stringify(
      {
        pagination: {
          pageSize: 100,
        },
        filters: {
          teams: {
            id: {
              $in: ID,
            },
          },
          watch_list: {
            id: LEAGUE,
          },
        },
        populate: {
          watch_list: {
            fields: ["Name", "STATS"],
          },
          teams: {
            populate: "*",
          },
          player_battings: {
            fields: [
              "BATTING_Runs",
              "BATTING_fours",
              "BATTING_sixes",
              "BATTING_Balls",
              "BATTING_HowOut",
            ],
            populate: ["player", "player.image", "team"],
          },
          player_bowlings: {
            fields: [
              "BOWLING_Overs",
              "BOWLING_Runs",
              "BOWLING_Wkts",
              "BOWLING_Maidens",
              "BOWLING_Econ",
            ],
            populate: ["player", "player.image", "team"],
          },
          player_catches: {
            fields: ["PLAYERS_Catches"],
            populate: ["player", "player.image", "team"],
          },
          ground: {
            populate: "*",
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

    const res = await fetch(`${ENV.API_URL}/api/fixtures?${query}`);
    const DataReturn = await res.json();
    //console.log(DataReturn.data);
    setFixtures(DataReturn.data);
  };
  return [Fixtures, GetFixtures];
};
// FUNC : UPDATE
export const useUpdateGetFixtures = (ctx) => {
  const [updateFixture, setupdateFixture] = useState(null);
  const jwt = parseCookies(ctx).jwt;
  const PutUpdateFixture = async (_ID, _DATA) => {
    const res = await fetch(`${ENV.API_URL}/api/fixtures/${_ID}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({ data: _DATA }),
    });
    const Results = await res.json();
    //console.log(Results)

    setupdateFixture(Results);
  };
  return [updateFixture, PutUpdateFixture];
};

export const useSyncSingleGame = (ctx) => {
  const [SyncFixture, setSyncFixture] = useState(null);
  const jwt = parseCookies(ctx).jwt;
  const GetSyncFixture = async (_ID, _DATA) => {
    const res = await fetch(`${ENV.API_URL}/api/fixture/SyncSingleGame/${_ID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      
    });
    const Results = await res.json();
    console.log(Results)

    setSyncFixture(Results);
  };
  return [SyncFixture, GetSyncFixture];
};
