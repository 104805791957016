import { useState } from "react";
import {RadioDataset} from '../../settings/RadioDataset'
import { H3} from "../../../Theme/type";
import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";
import { Group, Stack } from "@mantine/core";


import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
export const SelectADataSet= ({setDataset,SelectedDataset})=>{
    
    const [value, setValue] = useState(SelectedDataset)
    const [DisplaySelected,setDisplaySelected] = useState(false)
    const [ItemState, setItemState] = useState(0)

    const handleChange=(e)=>{
            setValue(e); 
            setDataset(e)
            setDisplaySelected(e)
            setItemState(10) 
        }
      return(
            <> 
            <BLOCK_Section_SubHeading LABEL='Select a  Dataset' BGColor={0} Color={DisplaySelected ? 7:5}/>
               
                <VideoWizardContainer DividerSize={ItemState}  Padding='xs'>
                    <Group position="apart" spacing="xl" grow>
                        <RadioDataset  CALLBACK={handleChange} DefaultValue={value}/>
                    </Group>
                </VideoWizardContainer>
            </>
      ) 
  }


/*   const RenderSelected = ({SELECTED})=>{
    
    //console.log(SELECTED.attributes.Name)
    return(
        <Stack align="center" justify="space-around">
            <H3 colorID={5}>{SELECTED.attributes.Name}</H3> 
        </Stack>
    )
  } */