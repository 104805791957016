import { Series, useCurrentFrame } from "remotion";
// Components

import { LeagueLabel } from "../../../Components/LeagueLabel";
import { LEAGUESTANDINGS_UL } from "./Series/LEAGUESTANDINGSUL";
import { FullWidthWrapper } from "../../../utils/Containers";

import { useTheme } from "styled-components";
import styled from "styled-components";
export const LEAGUESTANDINGS_BODY = ({ DATA, VideoSettings }) => {
  //const theme = useTheme()
  console.log(VideoSettings.VideoDivisionTimings);
  return (
    <>
      <Series>
        {Object.keys(DATA.DATAOBJ).map((item, i) => {
          console.log(item);
          return (
            <Series.Sequence
              durationInFrames={VideoSettings.VideoDivisionTimings[i]}
              key={i}
            >
              <FullWidthWrapper>
                <LeagueLabel LABEL={DATA.DATAOBJ[item].Name} />
                <LEAGUESTANDINGS_UL
                  LeagueDurations={VideoSettings.VideoDivisionTimings[i]}
                  VideoSettings={VideoSettings}
                  LEAGUETABLE={DATA.DATAOBJ[item]}
                />
              </FullWidthWrapper>
              <Timer Durations={VideoSettings.VideoDivisionTimings[i]} />
            </Series.Sequence>
          ); 
        })}
      </Series>
    </>
  );
};

const TimerContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 10px;
`;

const Timer = ({ Durations }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();

  return (
    <TimerContainer
      style={{
        width: `${(frame / Durations) * 100}%`,
        backgroundColor: theme.Base[0],
      }}
    ></TimerContainer>
  );
};
