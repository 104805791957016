// Components

// Sections
import { IndexHeader } from "../Sections/INDEX_HEADER";
import { IndexTagline } from "../Sections/INDEX_TAGLINE";
import { IndexCreateContent } from "../Sections/INDEX_CREATECONTENT";
import { IndexByLine001 } from "../Sections/INDEX_ByLine001";
import { Subgrid } from "../Sections/INDEX_EXAMPLES";
import { SponsorsBanner } from "../Sections/INDEX_SPONSORED";
import { IndexSubscriptions } from "../Sections/INDEX_SUBSCRIPTIONS";
import { FeaturesCards } from "../Sections/INDEX_FEATURES";
import { IndexByLine002 } from "../Sections/INDEX_ByLine002";
import { FooterSimple } from "../Sections/INDEX_Footer";
import { HeroImageRight } from "../Sections/INDEX_HERO";
import { FeaturesAsymmetrical } from "../Sections/INDEX_USEDBY";
import { GetInTouchSimple } from "../Sections/INDEX_Contact";
import { FeaturedTestimonials } from "../Sections/INDEX_Testimonials";
import { CardsCarousel } from "../Sections/INDEX_Carousel";
export const PAGE_LANDING = () => {
  return (
    <>
      <IndexHeader INDEX={0} />
      <HeroImageRight />
      <FeaturesCards /> 
      <FeaturedTestimonials />
      <IndexByLine002 />
      
      <IndexByLine001 />
      {/* <CardsCarousel /> */}
      <Subgrid />
      <FeaturesAsymmetrical />
      <IndexCreateContent />
      <SponsorsBanner />
      
      {/* <IndexSubscriptions /> */}
      <GetInTouchSimple />
      
      <FooterSimple />
    </>
  );
};
/*
 <IndexTagline />
*/
