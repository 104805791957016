import { Series,useCurrentFrame } from 'remotion';
import styled from 'styled-components';
import { useTheme } from 'styled-components';
import {SpringToFrom} from '../../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../../Animation/interpolate'
import {TrimPlayerName} from '../../../../../../actions/UX'
import { H1 } from '../../../utils/Text';

// Components
import {TripleDropTransition} from '../../../GlobalComponents/TripleDropTransition'
import {BottomUnderlayForCopy} from '../../../GlobalComponents/BottomUnderlayForCopy'
import {POTDPlayerImage} from '../../../GlobalComponents/POTD/POTDPlayerImage'
import {AnimatedStrokeAroundImage} from '../../../GlobalComponents/AnimatedStrokeAroundImage'


//import LMS from "../../../../../Assets/defaultLarge.png";
/* import {LargeTitleTranslateIn} from '../../../GlobalComponents/LargeTitleTranslateIn'
import {SmallCopyTranslateIn} from '../../../GlobalComponents/SmallCopyTranslateIn'
import {TeamLogoLarge} from '../../../GlobalComponents/POTD/TeamLogoLarge' */
import {TeamLogoSmall} from '../../../GlobalComponents/POTD/MainLogo'
import {AudioBaseTrack} from '../../../GlobalComponents/Audio/BaseTrack'
import { useState } from 'react';

export  const DisplayTop5List=({DATA,Dimensions,VARIABLE,PREFIX})=>{
  //console.log(DATA)

    const theme = useTheme();
    const frame = useCurrentFrame();
    const Durations={
        Total:180,
        Begin:0, // bring in overlay
        TransitionAfter:45, // slide overlay down
        Close:165, // remove overlay prep nect item 
        Offset:15, 
    }
    return(
        
        <Series>
        {
            DATA.DATAOBJ.CATEGORY.map((ITEM,i)=>{
                return(
                    <Series.Sequence durationInFrames={Durations.Total} layout="none" key={i}>
                    
                    <ColoredMask 
                        style={{
                            backgroundColor:theme.Base[1],
                            opacity:interpolateOpacityByFrame(frame,(Durations.Total*i),((Durations.Total*i)+10), 1, 0),
                        }}
                    />
                  
                        <POTDPlayerImage IMG={ITEM.IMAGE} Durations={Durations}/>
                        <TripleDropTransition Dimensions={Dimensions} Durations={Durations}/>
                        <BottomUnderlayForCopy  DATA={DATA} Durations={Durations}/>
                         
                       

                        <Position Value={5-i} Durations={Durations}
                        />
                      <TeamLogoSmall Logo={ITEM.Team.Logo.data.attributes.url} Durations={Durations} />

                        <DetailsContainer>
                            <Label STR={`${PREFIX} ${ITEM.VARIABLE} ${VARIABLE}`} fontWeight='400' Size='md' Durations={Durations}/>
                            <Label STR={TrimPlayerName(ITEM.Name)} fontWeight='900' Size='lg' Durations={Durations}/>
                            <Label STR={ITEM.Team.Name} fontWeight='200' Size='sm' Durations={Durations}/>
                        </DetailsContainer>
                        
                        <AudioBaseTrack />
                    </Series.Sequence>
                    
                )
            })
        }
        </Series>
    )
}

const Position=({Value,Durations})=>{
    const theme = useTheme();
    const frame = useCurrentFrame();
   
    return(
        <>
        <PositionCircle
            style={{
                backgroundColor:theme.Base[0],
                filter: 'drop-shadow(0px 20px 10px rgba(0, 0, 0, 0.50))',
                opacity:interpolateOpacityByFrame(frame, Durations.TransitionAfter,(Durations.TransitionAfter+15), 1,0),
                transform: `scale(${SpringToFrom(Durations.Begin,0,1,'Springy100')}) scale(${SpringToFrom(Durations.TransitionAfter,1,0)})`,
            }}>
             <PositionCircleValue
             style={{
                color:theme.Base[1],
            }}
            >{Value}</PositionCircleValue>
             
        </PositionCircle>
        <AnimatedStrokeAroundImage COLOR={1} DIVIDE={4} Durations={Durations} STYLES={{ width:'550px',height:'550px',top: '260px' }}/>
        <AnimatedStrokeAroundImage COLOR={3} DIVIDE={4} Durations={Durations} STYLES={{ width:'450px',height:'450px',top: '310px',transform: 'rotate(90deg)' }}/>
        </>
    )
}


const Label = ({STR,fontWeight,Durations,Size='md'})=>{
    const theme = useTheme();
    const frame = useCurrentFrame();
    const FS={
        xs:'2rem',
        sm:'3rem',
        md:'4rem',
        lg:'5rem',
        xl:'6rem'
    }
    return( <H1
        style={{
                color:theme.Copy[1], 
                width:'100%',
                textAlign:'center',
                fontSize:FS[Size],
                lineHeight:FS[Size],
                margin:'.5rem 0',
                letterSpacing:'-5px',
                fontWeight:fontWeight,
                marginTop: `${SpringToFrom(Durations.TransitionAfter,80,0)}px`,
                opacity:interpolateOpacityByFrame(frame,Durations.TransitionAfter,(Durations.TransitionAfter+10), 0, 1),
                transform: `translateY(${SpringToFrom((Durations.Close-Durations.Offset),0,300)}px)`,
            }}
    >   {STR}  </H1>)
}



const VariableContainerWrapper = ({ITEM,Durations})=>{
    return(
        <VariableContainer
                    style={{
                    transform: `translateY(${SpringToFrom(Durations.TransitionAfter,-100,0)}%)`
                }}>
            <Label STR={`${ITEM.VARIABLE}`} fontWeight='900' Size='xl' Durations={Durations}/>
            <Label STR={'RUNS'} fontWeight='400' Size='sm' Durations={Durations}/>
        </VariableContainer>
    )
}


const SVGStroke = ({Durations, COLOR,DIVIDE})=>{
    const theme = useTheme();
    const frame = useCurrentFrame();
    const [length, setLength] = useState(null);
   //console.log(length)
  
    return(
        <svg viewBox="0 0 839 839" fill="none">
            <circle 
                ref={(ref) => {
                    // The ref is `null` on component unmount
                    if (ref) {
                    setLength(ref.getTotalLength());
                    }
                }}
                cx="420" 
                cy="420" 
                r="415" 
                strokeDashoffset={SpringToFrom((Durations.Begin),length,0,'Springy100')} 
                strokeDasharray={length/DIVIDE} 
                stroke={theme.Base[COLOR]} 
                strokeWidth={SpringToFrom((Durations.Begin),50,10,'Springy100')}
                style={{ opacity:interpolateOpacityByFrame(frame, Durations.TransitionAfter,(Durations.TransitionAfter+5), 1,0) }}
                />
            </svg>
    )
}

const OuterCircle = styled.div`
z-index: 1000;
position: absolute;
display: flex;
align-items: center;
justify-content: center;
z-index:1000`

const PositionCircle = styled.div`
background-color: white;
width: 500px;
height: 500px;
z-index: 1000;
position: absolute;
border-radius: 1000px;
display: flex;
align-items: center;
justify-content: center;
top: 285px;
`
const PositionCircleValue = styled.h1`
font-family: arial;
font-size: 22em;
font-weight: 900;
`

const ColoredMask= styled.div`
    width:100%;
    height:100%;
    position:absolute;

`
const DetailsContainer = styled.div`
    width:100%;
    height:25%;
    position:absolute;
    bottom: 0;
    display:flex;
    z-index:100;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction:column;
   
`

    const VariableContainer = styled.div` position:absolute;
        padding: 0   ;
        margin:0  ;
   
        width:100%;
        top:0;
        z-index:9;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        align-content: baseline;
        background-color: rgba(0, 0, 0, 0.8);
        border-bottom: 5px solid #fff;
        `