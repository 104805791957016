import { Table, Space, Grid } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";
import { TrimPlayerName } from "../../../actions/UX";
import { findIndex, orderBy } from "lodash";

// UI ELEMENTS
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";
import {
  BLOCK_Section_SubHeading,
  BLOCK_Section_SubHeading_INVERT,
} from "../../../Components/interface/BLOCK_Section_SubHeading";
import {
  TABLE_TD,
  TABLE_THEAD_WRAPPER,
  TABLE_WRAPPER,
} from "../../../Components/interface/Wrapper/Table";

export const TrackedPerformances = ({ SelectedTeam }) => {
//console.log(SelectedTeam);

  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: `${SelectedTeam.attributes.Name} - Tracked Performances`,
          ICON: "User",
          DESCRIPTION: `The tracked performances for ${SelectedTeam.attributes.Name}`,
          TOOLBAR: false,
        }}
      />

      <BLOCK_Section_SubHeading_INVERT LABEL={`Batting`} Color={3} />
      <VideoWizardContainer>
        <BattingTable DATA={SelectedTeam.attributes.player_battings.data} />
      </VideoWizardContainer>
      <Space h="md" />
      <BLOCK_Section_SubHeading_INVERT LABEL={`Bowling`} Color={3} />

      <VideoWizardContainer>
        <BowlingTable DATA={SelectedTeam.attributes.player_bowlings.data} />
      </VideoWizardContainer>
      <Space h="md" />

      <Grid>
        <Grid.Col span={6}>
          <BLOCK_Section_SubHeading_INVERT LABEL={`Catches`} Color={3} />

          <VideoWizardContainer>
            <CatchesTable DATA={SelectedTeam.attributes.player_catches.data} />
          </VideoWizardContainer>
        </Grid.Col>
        <Grid.Col span={6}>
          <BLOCK_Section_SubHeading_INVERT LABEL={`Stumpings`} Color={3} />

          <VideoWizardContainer>
            <StumpingsTable
              DATA={SelectedTeam.attributes.player_stumpings.data}
            />
          </VideoWizardContainer>
        </Grid.Col>
      </Grid>
    </>
  );
};

// PLAYER PERFORMANCE
const BattingTable = ({ DATA }) => {
  const SMRemoval = useMediaQuery("(min-width: 769px)");

  const ReduceData = () => {
    const ARR = [];

    DATA.map((item, i) => {
      //console.log(item)
      const INDEX = findIndex(ARR, function (o) {
        return o.Name === item.attributes.player.data.attributes.Name;
      });

      if (INDEX === -1) {
        ARR.push({
          Name: item.attributes.player.data.attributes.Name,
          Player: item.attributes.player.data.attributes,
          BATTING_Balls: [parseInt(item.attributes.BATTING_Balls)],
          BATTING_Runs: [parseInt(item.attributes.BATTING_Runs)],
          BATTING_fours: [parseInt(item.attributes.BATTING_fours)],
          BATTING_sixes: [parseInt(item.attributes.BATTING_sixes)],
          BATTING_HowOut: [item.attributes.BATTING_HowOut],
          BATTING_Total: parseInt(item.attributes.BATTING_Runs),
          Total_Balls: parseInt(item.attributes.BATTING_Balls),
          Total_fours: parseInt(item.attributes.BATTING_fours),
          Total_sixes: parseInt(item.attributes.BATTING_sixes),
          TotalINN: 1,
          NotOuts: item.attributes.BATTING_HowOut === "Not Out" ? 1 : 0,
        });
      } else {
        ARR[INDEX].BATTING_Balls.push(parseInt(item.attributes.BATTING_Balls));
        ARR[INDEX].BATTING_Runs.push(parseInt(item.attributes.BATTING_Runs));
        ARR[INDEX].BATTING_fours.push(parseInt(item.attributes.BATTING_fours));
        ARR[INDEX].BATTING_sixes.push(parseInt(item.attributes.BATTING_sixes));
        ARR[INDEX].BATTING_HowOut.push(item.attributes.BATTING_HowOut);
        ARR[INDEX].BATTING_Total = ARR[INDEX].BATTING_Runs.reduce(
          (a, b) => a + b
        );
        ARR[INDEX].Total_Balls = ARR[INDEX].BATTING_Balls.reduce(
          (a, b) => a + b
        );
        ARR[INDEX].Total_fours = ARR[INDEX].BATTING_fours.reduce(
          (a, b) => a + b
        );
        ARR[INDEX].Total_sixes = ARR[INDEX].BATTING_sixes.reduce(
          (a, b) => a + b
        );
        ARR[INDEX].TotalINN = ARR[INDEX].TotalINN + 1;
        ARR[INDEX].NotOuts =
          item.attributes.BATTING_HowOut === "Not Out"
            ? ARR[INDEX].NotOuts + 1
            : ARR[INDEX].NotOuts;
      }
    });
    return orderBy(ARR, ["BATTING_Total"], ["desc"]);
  };

  return (
    <TABLE_WRAPPER>
      <TABLE_THEAD_WRAPPER
        DISPLAY={["None", "table-header-group"]}
        HEADERS={[" ", "I", "R", "B", "4", "6", "AVG"]}
      />
      <TABLE_THEAD_WRAPPER
        DISPLAY={["table-header-group", "None"]}
        HEADERS={[
          "",
          SMRemoval ? "INN" : "R",
          SMRemoval ? "RUNS" : "BF",
          SMRemoval ? "BALLS" : "SR",
          SMRemoval ? "Fours" : "HO",
          SMRemoval ? "Sixes" : "4s",
          SMRemoval ? "AVG" : "6s",
          SMRemoval ? "Strike Rate" : "6s",
        ]}
      />

      <tbody>
        {ReduceData().map((BAT, i) => {
          return (
            <tr key={i}>
              <TABLE_TD>{TrimPlayerName(BAT.Name)}</TABLE_TD>
              <TABLE_TD>{BAT.TotalINN}</TABLE_TD>
              <TABLE_TD>{BAT.BATTING_Total}</TABLE_TD>
              <TABLE_TD>{BAT.Total_Balls}</TABLE_TD>
              <TABLE_TD>{BAT.Total_fours}</TABLE_TD>
              <TABLE_TD>{BAT.Total_sixes}</TABLE_TD>
              <TABLE_TD>
                {(BAT.BATTING_Total / (BAT.TotalINN - BAT.NotOuts)).toFixed(2)}
              </TABLE_TD>
              <TABLE_TD HIDE={true}>
                {((BAT.BATTING_Total / BAT.Total_Balls) * 100).toFixed(2)}
              </TABLE_TD>
            </tr>
          );
        })}
      </tbody>
    </TABLE_WRAPPER>
  );
};

/* BOWLING TABLE*/
const BowlingTable = ({ DATA }) => {
  const SMRemoval = useMediaQuery("(min-width: 769px)");
  const ReduceData = () => {
    const ARR = [];

    DATA.map((item, i) => {
      const INDEX = findIndex(ARR, function (o) {
        return o.Name === item.attributes.player.data.attributes.Name;
      });

      if (INDEX === -1) {
        ARR.push({
          Name: item.attributes.player.data.attributes.Name,
          Player: item.attributes.player.data.attributes,
          BOWLING_Wkts: [parseInt(item.attributes.BOWLING_Wkts)],
          BOWLING_Runs: [parseInt(item.attributes.BOWLING_Runs)],
          BOWLING_Maidens: [parseInt(item.attributes.BOWLING_Maidens)],
          BOWLING_Overs: [item.attributes.BOWLING_Overs],

          Total_WKTS: parseInt(item.attributes.BOWLING_Wkts),
          Total_Runs: parseInt(item.attributes.BOWLING_Runs),
          Total_Maidens: parseInt(item.attributes.BOWLING_Maidens),
          Total_Overs: item.attributes.BOWLING_Overs,
        });
      } else {
        ARR[INDEX].BOWLING_Wkts.push(parseInt(item.attributes.BOWLING_Wkts));
        ARR[INDEX].BOWLING_Runs.push(parseInt(item.attributes.BOWLING_Runs));
        ARR[INDEX].BOWLING_Maidens.push(
          parseInt(item.attributes.BOWLING_Maidens)
        );
        ARR[INDEX].BOWLING_Overs.push(item.attributes.BOWLING_Overs);

        ARR[INDEX].Total_WKTS = ARR[INDEX].BOWLING_Wkts.reduce((a, b) => a + b);
        ARR[INDEX].Total_Runs = ARR[INDEX].BOWLING_Runs.reduce((a, b) => a + b);
        ARR[INDEX].Total_Maidens = ARR[INDEX].BOWLING_Maidens.reduce(
          (a, b) => a + b
        );
        ARR[INDEX].Total_Overs = ARR[INDEX].BOWLING_Overs.reduce(
          (a, b) => a + b
        );
      }
    });
    return orderBy(ARR, ["Total_WKTS"], ["desc"]);
  };

  return (
    <TABLE_WRAPPER>
      <TABLE_THEAD_WRAPPER
        DISPLAY={["None", "table-header-group"]}
        HEADERS={[" ", "W", "F", "O", "ECO"]}
      />
      <TABLE_THEAD_WRAPPER
        DISPLAY={["table-header-group", "None"]}
        HEADERS={[
          "",
          SMRemoval ? "Wickets" : "R",
          SMRemoval ? "For" : "BF",
          SMRemoval ? "Overs" : "SR",
          SMRemoval ? "Madiens" : "HO",
          SMRemoval ? "AVG" : "4s",
          SMRemoval ? "Eco" : "6s",
          SMRemoval ? "Strike Rate" : "6s",
        ]}
      />

      <tbody>
        {ReduceData().map((BAT, i) => {
          return (
            <tr key={i}>
              <TABLE_TD>{TrimPlayerName(BAT.Name)}</TABLE_TD>
              <TABLE_TD>{BAT.Total_WKTS}</TABLE_TD>
              <TABLE_TD>{BAT.Total_Runs}</TABLE_TD>
              <TABLE_TD>{BAT.Total_Overs.toFixed(1)}</TABLE_TD>
              <TABLE_TD HIDE={true}>{BAT.Total_Maidens}</TABLE_TD>
              <TABLE_TD HIDE={true}>
                {(BAT.Total_Runs / BAT.Total_WKTS).toFixed(2)}
              </TABLE_TD>
              <TABLE_TD>
                {(BAT.Total_Runs / BAT.Total_Overs).toFixed(2)}
              </TABLE_TD>
              <TABLE_TD HIDE={true}>
                {((BAT.Total_Overs / BAT.Total_WKTS) * 5).toFixed(2)}
              </TABLE_TD>
            </tr>
          );
        })}
      </tbody>
    </TABLE_WRAPPER>
  );
};

/* Catches */
const CatchesTable = ({ DATA }) => {
  const ReduceData = () => {
    const ARR = [];
    DATA.map((item, i) => {
      const INDEX = findIndex(ARR, function (o) {
        return o.Name === item.attributes.player.data.attributes.Name;
      });

      if (INDEX === -1) {
        ARR.push({
          Name: item.attributes.player.data.attributes.Name,
          Player: item.attributes.player.data.attributes,
          PLAYERS_Catches: [parseInt(item.attributes.PLAYERS_Catches)],
          Total_CATCH: parseInt(item.attributes.PLAYERS_Catches),
        });
      } else {
        ARR[INDEX].PLAYERS_Catches.push(
          parseInt(item.attributes.PLAYERS_Catches)
        );
        ARR[INDEX].Total_CATCH = ARR[INDEX].PLAYERS_Catches.reduce(
          (a, b) => a + b
        );
      }
    });
    return orderBy(ARR, ["Total_CATCH"], ["desc"]);
  };

  return (
    <TABLE_WRAPPER>
      <TABLE_THEAD_WRAPPER HEADERS={["Catches", ""]} />

      <tbody>
        {ReduceData().map((BAT, i) => {
          return (
            <tr key={i}>
              <TABLE_TD>{TrimPlayerName(BAT.Name)}</TABLE_TD>
              <TABLE_TD>{BAT.Total_CATCH}</TABLE_TD>
            </tr>
          );
        })}
      </tbody>
    </TABLE_WRAPPER>
  );
};

const StumpingsTable = ({ DATA }) => {
  const ReduceData = () => {
    const ARR = [];
    DATA.map((item, i) => {
      //console.log(item)
      const INDEX = findIndex(ARR, function (o) {
        return o.Name === item.attributes.player.data.attributes.Name;
      });

      if (INDEX === -1) {
        ARR.push({
          Name: item.attributes.player.data.attributes.Name,
          Player: item.attributes.player.data.attributes,
          PLAYERS_Stumpings: [parseInt(item.attributes.PLAYERS_Stumpings)],
          Total_STUMP: parseInt(item.attributes.PLAYERS_Stumpings),
        });
      } else {
        ARR[INDEX].PLAYERS_Stumpings.push(
          parseInt(item.attributes.PLAYERS_Stumpings)
        );
        ARR[INDEX].Total_STUMP = ARR[INDEX].PLAYERS_Stumpings.reduce(
          (a, b) => a + b
        );
      }
    });
    return orderBy(ARR, ["Total_STUMP"], ["desc"]);
  };

  return (
    <TABLE_WRAPPER>
      <TABLE_THEAD_WRAPPER HEADERS={["Stumpings", ""]} />

      <tbody>
        {ReduceData().map((BAT, i) => {
          return (
            <tr key={i}>
              <TABLE_TD>{TrimPlayerName(BAT.Name)}</TABLE_TD>
              <TABLE_TD>{BAT.Total_STUMP}</TABLE_TD>
            </tr>
          );
        })}
      </tbody>
    </TABLE_WRAPPER>
  );
};
