/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { restoreOBJ } from "../../../../store/Reducer/VideoRendering";
// HOOKS
import { useFetchAsset } from "../../../../Hooks/Assets/useFetchAsset";

// Layout
import { BodyContainer } from "../../../../Theme/Structure/BodyContainer";
import {
  Index_Header,
  IndexTitle,
} from "../../../../Components/Videos/Layouts/Video_Index";


// Components
import { UISkeletonLinesWithCopy } from "../../../../Components/Global/Skeleton";
import { FetchAssetRawData } from "./FetchData";
import { AssetToolBar } from "../../Components/AssetInputToolBar";

const Upcoming_Fixtures = ({ AssetID }) => {
  const dispatch = useDispatch();

  // UI Controllers
  const [start, setStart] = useState(false);

  // Component Controllers
  const [FindAsset, FetchAsset] = useFetchAsset();
  const [showAsset, setShowAsset] = useState(false);

  const [isDataConfirmed, setIsDataConfirmed] = useState(false); 
  // Fetch and Store ASSET TYPE
  useEffect(() => {
    FetchAsset(AssetID);
  }, []);
  useEffect(() => {
    setShowAsset(FindAsset);
  }, [FindAsset]); 

  useEffect(() => {
    dispatch(restoreOBJ());
  }, [start]);

  if (showAsset === false || showAsset === undefined)
    return <UISkeletonLinesWithCopy Copy={"Building Asset Information"} />;
  return (
    <BodyContainer>
      <IndexTitle showAsset={showAsset} setStart={setStart} start={start} />

      {start ? (
        <AssetInputs
          setStart={setStart}
          setIsDataConfirmed={setIsDataConfirmed}
          isDataConfirmed={isDataConfirmed}
          showAsset={showAsset}
        />
      ) : (
        <Index_Header setStart={setStart} start={start} showAsset={showAsset} />
      )}
    </BodyContainer>
  );
}

export default Upcoming_Fixtures;


const AssetInputs = ({
  setStart,
  setIsDataConfirmed,
  isDataConfirmed,
  showAsset,
}) => {
  return (
    <>
      <AssetToolBar setStart={setStart} />
      <FetchAssetRawData
        setIsDataConfirmed={setIsDataConfirmed}
        isDataConfirmed={isDataConfirmed}
        Asset={showAsset}
      />
    </>
  );
};

/* import { useEffect, useState } from "react";
import { useFetchAsset } from "../../../../Hooks/Assets/useFetchAsset";
import { useSelector } from "react-redux";
// Components
//import AssetWeeklyAside from "../../../Components/Videos/Aside_Weekly"
import { VideoWizard } from "../../../../Components/Videos/VideoWizard";
import Loading from "./Loading";

// Theme
import { BodyContainer } from "../../../../Theme/Structure/BodyContainer";

// Layout
import {
  Index_Header,
  IndexTitle,
} from "../../../../Components/Videos/Layouts/Video_Index";
// Create the Video From
import { SelectVideoTemplate } from "../../../../Components/Videos/CreateVideoComponents/SelectTemplate";
import { SelectTheme } from "../../../../Components/Videos/CreateVideoComponents/SelectTheme";
import { SelectASponsor } from "../../../../Components/Videos/CreateVideoComponents/SelectSponsor";
import { SelectADataSet } from "../../../../Components/Videos/CreateVideoComponents/SelectADataset";
import { SelectDateRange } from "../../../../Components/Videos/CreateVideoComponents/SelectDateRange";
import { UISkeletonLinesWithCopy } from "../../../../Components/Global/Skeleton";
const UpComingFixtures = ({ AssetID }) => {
  // USER
  const AUTH = useSelector((state) => state.AUTH);

  // Component Controllers
  const [FindAsset, FetchAsset] = useFetchAsset();
  const [showAsset, setShowAsset] = useState(false);
  const [start, setStart] = useState(false); 
  const [Create, setCreate] = useState(false);
  const [isTrueCTA, setisTrueCTA] = useState(true); 
  //Stored Dataset
  const [FromDate, setFromDate] = useState(false);
  const [Layout, setLayout] = useState(false);
  const [VideoTheme, setVideoTheme] = useState({
    attributes: AUTH.AUTHUSER.theme,
  });
  const [isSponsored, setIsSponsored] = useState(false);
  const [Dataset, setDataset] = useState(false);

  useEffect(() => {
    FetchAsset(AssetID);
  }, []);
  useEffect(() => {}, []);
  useEffect(() => {
    setShowAsset(FindAsset);
  }, [FindAsset]);
  useEffect(() => {}, [FromDate, VideoTheme]);
  useEffect(() => {
    const OBJ = {
      Layout: Layout?.Name,
      VideoTheme: VideoTheme?.attributes?.Name,
      Dataset: Dataset?.attributes?.Name,
      FromDate: FromDate,
    };
    const areFalsy = Object.values(OBJ).every((value) => value);
    setisTrueCTA(!areFalsy);
  }, [Layout, VideoTheme, Dataset, FromDate]);

  if (showAsset === false || showAsset === undefined)
    return <UISkeletonLinesWithCopy Copy={"Building Asset Information"} />;
  return (
    <BodyContainer>
      <IndexTitle showAsset={showAsset} setStart={setStart} start={start} />
      {start ? (
        <>
          {Create ? (
            ""
          ) : (
            <VideoWizard
              FORMELE={[
                {
                  Component: (
                    <SelectVideoTemplate
                      setLayout={setLayout}
                      Layout={Layout}
                      AssetID={AssetID}
                    />
                  ),
                },
                {
                  Component: (
                    <SelectTheme
                      setTheme={setVideoTheme}
                      Theme={VideoTheme?.attributes?.Name}
                      Layout={Layout?.Name}
                    />
                  ),
                },
                {
                  Component: (
                    <SelectADataSet
                      setDataset={setDataset}
                      SelectedDataset={Dataset?.attributes?.Name}
                    />
                  ),
                },
                {
                  Component: (
                    <SelectDateRange
                      setFromDate={setFromDate}
                      SelectedDataset={FromDate}
                    />
                  ),
                },
                {
                  Component: (
                    <SelectASponsor
                      setSponsor={setIsSponsored}
                      Sponsor={isSponsored.Name}
                    />
                  ),
                },
              ]}
              setCreate={setCreate}
              setCTASTATE={isTrueCTA}
              AssetID={showAsset.id}
              setStart={setStart}
            />
          )}
        </>
      ) : (
        // eslint-disable-next-line react/jsx-pascal-case
        <Index_Header setStart={setStart} start={start} showAsset={showAsset} />
      )}
      {Create ? (
        <Loading
          setCreate={setCreate}
          Filters={{
            SeasonID: Dataset,
            FromDate: FromDate,
            VideoTheme: VideoTheme,
            isSponsored: isSponsored,
            showAsset: showAsset,
            Template: Layout,
          }}
        />
      ) : (
        false
      )}
    </BodyContainer>
  );
};

export default UpComingFixtures;
 */