import styled from 'styled-components';

export const PlaceHolder = styled.div`width: 150px;`
/* LISTS */
export const GLOBALUL = styled.ul`
    width:100%;
    height: 100%;
    position: relative;
    margin:0;
    padding:0;
`
 

export const POTDUL = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin: 0px 0 0 30px;
    padding: 25px;
    width: 100%; 
    flex-grow: 1;
    
`


export const LI_Fixture = styled.li`
    width:  90%;
    height: 175px;
    margin: 8% 5%;
    position: relative;
    list-style:none;

`

export const MatchDayLI = styled(LI_Fixture)`
    margin: 7% 5%;
`
export const HONERABLELI = styled.li`
position: relative;
width: 48%;
max-height: 100px;
list-style: none;
margin: 0 1% 10px;
padding: 3px;
background: radial-gradient(97.01% 88.57% at 50% 8.49%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.3) 100%);
border: 1px solid rgba(255, 255, 255, 0.5);
box-sizing: border-box;
backdrop-filter: blur(15px);
display: flex;
flex-direction: column;
justify-content: space-evenly;
`
export const LeagueLadderLI = styled.li`
width: 97%;
    height: 130px;
    margin: 0.5% 1% 0.5% 2%;
    position: relative;
    list-style: none;

`