import { useSelector } from "react-redux";
import { AccessDenied } from "../../Components/interface/AccessDenied";
import { PageHeader } from "../../Components/interface/ProductHeader";
import CreateTournament from "../Tournaments/CreateTournament";

const Tournaments = () => {
  const AUTH = useSelector((state) => state.AUTH);
  if (!AUTH.AUTHUSER.hasFullAccess) return <AccessDenied />;
  return (
    <>
      <PageHeader NavPosition="Tournaments" />
      <CreateTournament />
    </>
  );
};
export default Tournaments;
