import { interpolate, useCurrentFrame, useVideoConfig, spring } from "remotion";
import { TrimPlayerName } from '../../../../../../actions/UX';
import styled from "styled-components";

import { useTheme } from "styled-components";

import { SpringToFrom } from '../../../../Animation/RemotionSpring';
//import { interpolateOpacityByFrame } from '../../../../Animation/interpolate';
import {
  FromMiddle,
  FromTopToBottom,
  FromBottomToTop,
} from "../../../../Animation/ClipWipe";

import {
  FullWidthWrapperStart,
  FrostedPOTD,
  ContainerBasicFlexColumn,
  POTDImageBox, 
  POTDWRAPPER,
} from '../../../utils/Containers';
import { H1, H2, H3, POTD_SR } from "../../../utils/Text";

export const DisplayPOTD = ({ _PLAY, _Durations }) => {
  const frame = useCurrentFrame();

  const ImgSize = interpolate(frame, [0, 180], [100, 110], {
    extrapolateRight: "clamp",
  });

  return (
    <>
      <FullWidthWrapperStart>
        <POTDWRAPPER
          style={{
            backgroundImage: `url(${_PLAY.IMAGE})`,
            backgroundSize: `${ImgSize}%`,
            transform: `
            translateY(${SpringToFrom(0, 1400, 0, "Springy100")}px)             
            translateY(${SpringToFrom(145, 100, -1300, "Springy100")}px)`,
          }}
        >
          <POTD_SR>Economy : {_PLAY.BOWLING_Econ}</POTD_SR>
          <POTDImageBox
            style={{
              backgroundImage: `url(${_PLAY.Logo})`,
              position: "absolute",
              top: "100px",
              left: "10px",
              clipPath: FromTopToBottom(5, "Springy100"),
            }}
          />
          <FrostedPOTD
            style={{
              clipPath: FromBottomToTop(5, "Springy100"),
            }}
          >
            <ContainerBasicFlexColumn style={{ width: "100%" }}>
              <H1
                style={{
                  clipPath: FromMiddle(15, "Springy100"),
                }}
              >
                {TrimPlayerName(_PLAY.Name)}
              </H1>
              <H2
                style={{
                  clipPath: FromMiddle(20, "Springy100"),
                }}
              >
                {_PLAY.Team}
              </H2>
              <H1
                style={{
                  clipPath: FromMiddle(25, "Springy100"),
                }}
              >
                {_PLAY.BOWLING_Wkts}/{_PLAY.BOWLING_Runs}
              </H1>
              <H3
                style={{
                  clipPath: FromMiddle(30, "Springy100"),
                }}
              >
                ({_PLAY.BOWLING_Overs} overs)
              </H3>
            </ContainerBasicFlexColumn>
          </FrostedPOTD>
        </POTDWRAPPER>
        <Timer Durations={_Durations} />
      </FullWidthWrapperStart>
    </>
  );
};

const TimerContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 10px;
`;

const Timer = ({ Durations }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();
  //console.log(((frame/Durations.Length)*100))
  return (
    <TimerContainer
      style={{
        width: `${(frame / Durations.Close) * 100}%`,
        backgroundColor: theme.Base[2],
      }}
    ></TimerContainer> 
  );
};
