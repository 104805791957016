import { useCurrentFrame, Series } from "remotion";
import { Image } from "@mantine/core";
import { useTheme } from "styled-components";

import styled from "styled-components";

// Animation
import { interpolateOpacityByFrame } from "../../../../../../Animation/interpolate";
import { SpringToFrom } from "../../../../../../Animation/RemotionSpring";
import {
  FromMiddle,
  FromTopToBottom,
} from "../../../../../../Animation/ClipWipe";
// Components
import { TopUnderlayForCopy } from "../../../../../GlobalComponents/BottomUnderlayForCopy";
import { H1, H2, H3, P } from "../../../../../utils/Text";


const SeriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  flex-wrap: wrap;
  list-style: none;
`;

export const MoM = ({ DATA, Duration }) => {
  console.log(DATA.DATAOBJ.Performances.MOM);
  const D = DATA.DATAOBJ;
  const frame = useCurrentFrame();
  const theme = useTheme();

  const Durations = {
    Begin: 0,
    TransitionAfter: 180 * 4,
  };

  return (
    <SeriesContainer>
      <TopUnderlayForCopy
        DATA={DATA}
        Durations={Durations}
        BOTTOM={`1100px`}
        POSITIONSTARTFROMTOP={0}
        POSITIONFROMTOP={-35}
      />

      <Series>
        <Series.Sequence durationInFrames={Duration} layout="none">
          <ManOfTheMatch
            ARR={DATA.DATAOBJ.Performances.MOM[0]}
            Duration={Duration}
            Label="Player of the Match"
            INT={3}
          />
        </Series.Sequence>
      </Series>

      <Image
        src={DATA?.OrderMetadata?.TournamentDetails?.LOGO.data?.attributes.url}
        radius={0}
        height={150}
        width={"auto"}
        style={{
          marginTop: "0px",
          marginBottom: "0px",
          position: "absolute",
          bottom: "0",
          left: "38%",
          mixBlendMode: "luminosity",
          zIndex: 5000,
          opacity: interpolateOpacityByFrame(frame, 30, 60, 0, 1),
        }}
      />
    </SeriesContainer>
  );
};

const ManOfTheMatch = ({
  ARR,

  INT,
  Label,
  Duration,
}) => {
  const frame = useCurrentFrame();

  const theme = useTheme();

  if (ARR.length === 0) return false;
  return (
    <>
      <H3
        style={{
          width: "100%",
          color: theme.Copy[1],
          letterSpacing: `${interpolateOpacityByFrame(
            frame,
            15,
            150,
            -5,
            0
          )}px`,
          fontSize: "7em",
          fontWeight: 100,
          margin: "6% 0px 0",
          lineHeight: "1em",
          opacity: interpolateOpacityByFrame(frame, 15, 45, 0, 1),
        }}
      >
        {Label}
      </H3>

      <CategoryDiv
        style={{
          //width: `${SpringToFrom((3*INT),0,98)}%`,
          borderColor: `rgba(255, 255, 255, ${interpolateOpacityByFrame(
            frame,
            15,
            30,
            0,
            1
          )})`,
          opacity: interpolateOpacityByFrame(
            frame,
            Duration - 25 + INT * 3,
            Duration,
            1,
            0
          ),
        }}
      >
        <Players>
          <PlayerContainer
            style={{
              opacity: interpolateOpacityByFrame(frame, 180 - 7, 180, 1, 0),
            }}
          >
            <Image
              radius={10}
              width={600}
              height={700}
              src={ARR.IMAGE}
              style={{
                //transform: `scale(${SpringToFrom((15+(5*i)),0,1)})`,
                clipPath: FromTopToBottom(7, "Springy100"),
                backgroundColor: theme.Base[1],
                filter: "drop-shadow(13px 17px 16px rgba(0, 0, 0, 0.25))",
                marginBottom: "50px",
              }}
            />
            <ShadowOffCanvas
              style={{
                opacity: interpolateOpacityByFrame(frame, 0, 30, 0, 1),
              }}
            />
            <H1
              style={{
                fontSize: "3em",
                fontWeight: 400,
                lineHeight: "1em",
                clipPath: FromMiddle(7, "Springy100"),
                color: theme.Copy[0],
              }}
            >
              {ARR.Name}
            </H1>
            <H2
              style={{
                fontSize: "4em",
                lineHeight: "1.2em",
                fontWeight: 900,
                clipPath: FromMiddle(15, "Springy100"),
                color: theme.Copy[2],
                letterSpacing: "-4px",
              }}
            >
              {ARR.Team}
            </H2>
          </PlayerContainer>
        </Players>
      </CategoryDiv>
    </>
  );
};
/** INNER COMPOENENTS */
const Players = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
`;
const CategoryDiv = styled.div`
  width: 98%;
  min-height: 70%;
  margin: 5% 1% 0;
  position: relative;

  box-sizing: border-box;
  backdrop-filter: blur(1px);
  border-radius: 10px;
  z-index: 1000;
`;

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0.5em;
`;

const ShadowOffCanvas = styled.div`
  position: absolute;
  width: 460px;
  height: 15px;
  top: 740px;
  background: rgba(28, 28, 28, 0.5);
  mix-blend-mode: soft-light;
  -webkit-filter: blur(80px);
  filter: blur(15px);
  border-radius: 2000px;
`;
