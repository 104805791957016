import { useEffect, useState } from "react"
//import {  useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import {ENV} from '../../Config/RunTimeConfig'
import { parseCookies } from 'nookies'
//import {userSetInvoice,userSetOrderHistory} from '../../store/Reducer/AUTH';
const qs = require('qs');


// FUNC : GET 
export const useGetSponsors = (ctx)=>{
    const [Sponsors, setSponsors] = useState(null)
    const AUTH = useSelector((state) => state.AUTH);
    const jwt = parseCookies(ctx).jwt;

        const GetSponsors = async()=>{
            setSponsors(null)
            const query = qs.stringify({
                filters: {  users_permissions_user: { id:AUTH.AUTHUSER.id} },
                populate: '*', 
              }, {
                encodeValuesOnly: true,
              });
              const res = await fetch(`${ENV.API_URL}/api/sponsors?${query}`, {  
                headers: { Authorization: `Bearer ${jwt}` }
            })
            const SponsorsResponse = await res.json();
      //console.log(SponsorsResponse)
        setSponsors(SponsorsResponse.data)
        }

        useEffect(()=>{},[Sponsors])

        return[Sponsors,GetSponsors]
}

// FUNC : CREATE 
export const useCreateSponsor=(ctx)=>{

    const [Sponsor, setSponsor] = useState(false)
    //const dispatch = useDispatch()
    const jwt = parseCookies(ctx).jwt
  

    const CreateSponsor = async(_DATA)=>{
        //console.log(_DATA)
        await fetch(`${ENV.API_URL}/api/sponsors`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({
                data: _DATA
            })
        })
        setSponsor(true)
    }  

    return[Sponsor,CreateSponsor]
} 

// FUNC : UPDATE 
export const useArchiveSponsor=(ctx)=>{

    const [Sponsor, setSponsor] = useState(false)
    const jwt = parseCookies(ctx).jwt
  

    const ArchiveSponsor = async(_ID,_DATA)=>{
        setSponsor(false)
      //console.log(_ID,_DATA)
        const res = await fetch(`${ENV.API_URL}/api/sponsors/${_ID}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({
                data: _DATA
            })
        })
        const InvoiceResponse = await res.json();
      //console.log(InvoiceResponse)
        setSponsor(true)
    }  

    useEffect(()=>{},[Sponsor])

    return[Sponsor,ArchiveSponsor]
} 