import { parseCookies } from "nookies";
import { useEffect } from "react";

import { useSelector } from "react-redux";
import ProcessAuth from "../Auth/ProcessAuth";
import LMSSocialMediaManager from "../Auth/index";

// PUBLIC SITE
import { INDEX } from "./Pages/index";
import { useIsLoggedUser } from "../Hooks/Auth/isLoggedUser";

export const CheckAuth = (ctx) => {
  const AUTH = useSelector((state) => state.AUTH);
  const jwt = parseCookies(ctx).jwt;
  const AUTHID = parseCookies(ctx).AUTHID;

  const [loggedUser, FindloggedUser] = useIsLoggedUser();

  const isLoggedIn = () => {
    return jwt !== undefined && AUTHID !== undefined ? <Auth /> : <INDEX />;
  };
  useEffect(() => {}, [AUTH]);
  return isLoggedIn();
};

// Auth
const Auth = () => {
  const AUTH = useSelector((state) => state.AUTH);
  useEffect(() => {}, [AUTH.AUTHUSER]);
  return AUTH.AUTHUSER !== false ? <LMSSocialMediaManager /> : <ProcessAuth />;
};
