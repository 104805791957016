import { Table, Space, Grid } from "@mantine/core";
import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";
import { useMediaQuery } from "@mantine/hooks";

// UI ELEMENTS
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";
import { BLOCK_Section_SubHeading_INVERT } from "../../../Components/interface/BLOCK_Section_SubHeading";
import {
  TABLE_TD,
  TABLE_THEAD_WRAPPER,
  TABLE_WRAPPER,
} from "../../../Components/interface/Wrapper/Table";

export const TrackedPerformances = ({ SelectedPlayer }) => {
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: `${SelectedPlayer.attributes.Name} - Tracked Performances`,
          ICON: "User",
          DESCRIPTION: `All Performances stored in the system for ${SelectedPlayer.attributes.Name}`,
          TOOLBAR: false,
        }}
      />

      <BLOCK_Section_SubHeading_INVERT
        LABEL={`Batting (${SelectedPlayer?.attributes?.player_battings?.data.length} Innings Counted)`}
        Color={2}
      />
      <VideoWizardContainer BGColor={1}>
        <BattingTable DATA={SelectedPlayer.attributes.player_battings.data} />
      </VideoWizardContainer>
      <Space h="md" />
      <BLOCK_Section_SubHeading_INVERT
        LABEL={`Bowling (${SelectedPlayer.attributes.player_bowlings.data.length} Innings Counted)`}
        Color={2}
      />

      <VideoWizardContainer BGColor={1}>
        <BowlingTable DATA={SelectedPlayer.attributes.player_bowlings.data} />
      </VideoWizardContainer>
      <Space h="md" />

      <Grid>
        <Grid.Col md={6} sm={12}>
          <BLOCK_Section_SubHeading_INVERT
            LABEL={`Catches (${SelectedPlayer.attributes.player_catches.data.length} Innings Counted)`}
            Color={2}
          />

          <VideoWizardContainer BGColor={1}>
            <CatchesTable
              DATA={SelectedPlayer.attributes.player_catches.data}
            />
          </VideoWizardContainer>
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <BLOCK_Section_SubHeading_INVERT
            LABEL={`Stumpings (${SelectedPlayer.attributes.player_stumpings.data.length} Innings Counted)`}
            Color={2}
          />

          <VideoWizardContainer BGColor={1}>
            <StumpingsTable
              DATA={SelectedPlayer.attributes.player_stumpings.data}
            />
          </VideoWizardContainer>
        </Grid.Col>
      </Grid>
    </>
  );
};

// PLAYER PERFORMANCE
const BattingTable = ({ DATA }) => {
  const SMRemoval = useMediaQuery("(min-width: 769px)");
  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
    return formattedDate;
  }

  const sortedArray = DATA.sort((a, b) => {
    const dateA = new Date(a?.attributes?.fixture?.data?.attributes.Date);
    const dateB = new Date(b?.attributes?.fixture?.data?.attributes.Date);
    return dateB - dateA;
  });


  return (
    <TABLE_WRAPPER>
      
      <TABLE_THEAD_WRAPPER
          DISPLAY={["None", "table-header-group"]}
          HEADERS={[" ", "R", "BF",'4','6']}
        />
      <TABLE_THEAD_WRAPPER 
       DISPLAY={[ "table-header-group","None"]}
        HEADERS={[
          SMRemoval ? "Date" : "D",
          SMRemoval ? "For" : "F",
          SMRemoval ? "Runs" : "R",
          SMRemoval ? "Balls" : "BF",
          SMRemoval ? "Strike Rate" : "SR",
          SMRemoval ? "How Out" : "HO",
          SMRemoval ? "Fours" : "4s",
          SMRemoval ? "Sixes" : "6s",
        ]}
      />

      <tbody>
        {sortedArray.map((BAT, i) => {
          console.log(BAT)
          return (
            <tr key={i}>
              
              <TABLE_TD>{formatDate(BAT?.attributes?.fixture?.data?.attributes?.Date)}</TABLE_TD>
              <TABLE_TD>{BAT.attributes.team?.data.attributes.Name}</TABLE_TD>
              <TABLE_TD>{BAT.attributes.BATTING_Runs}</TABLE_TD>
              <TABLE_TD>{BAT.attributes.BATTING_Balls}</TABLE_TD>
              <TABLE_TD HIDE={true}>{BAT.attributes.BATTING_SR}</TABLE_TD>
              <TABLE_TD HIDE={true}>{BAT.attributes.BATTING_HowOut}</TABLE_TD>
              <TABLE_TD>
                {BAT.attributes.BATTING_fours}
              </TABLE_TD>
              <TABLE_TD>
                {BAT.attributes.BATTING_sixes}
              </TABLE_TD>
            </tr>
          );
        })}
      </tbody>
    </TABLE_WRAPPER>
  );
};

const BowlingTable = ({ DATA }) => {
  const SMRemoval = useMediaQuery("(min-width: 769px)");
  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
    return formattedDate;
  }

  return (
    <TABLE_WRAPPER>

<TABLE_THEAD_WRAPPER
          DISPLAY={["None", "table-header-group"]}
          HEADERS={[" ", "W", "F",'O', 'Eco']}
        />
      <TABLE_THEAD_WRAPPER
       DISPLAY={[ "table-header-group","None"]}
        HEADERS={[
           SMRemoval ? "Date" : "D",
          SMRemoval ? "For" : "F",
          SMRemoval ? "Wickets" : "W",
          SMRemoval ? "For" : "F",
          SMRemoval ?"Overs" : "O",
          SMRemoval ? "Madiens" : "M",
          SMRemoval ? "Economy" : "Eco",
        ]}
      />
     
      <tbody>
        {DATA.map((BAT, i) => {
          console.log(DATA)
          return (
            <tr key={i}>
               <TABLE_TD>{formatDate(BAT?.attributes?.fixture?.data?.attributes.Date)}</TABLE_TD>
              <TABLE_TD>{BAT.attributes.team?.data.attributes.Name}</TABLE_TD>
              <TABLE_TD>{BAT.attributes.BOWLING_Wkts}</TABLE_TD>
              <TABLE_TD>{BAT.attributes.BOWLING_Runs}</TABLE_TD>
              <TABLE_TD>{BAT.attributes.BOWLING_Overs}</TABLE_TD>
              <TABLE_TD HIDE={true}>{BAT.attributes.BOWLING_Maidens}</TABLE_TD>
              <TABLE_TD>{BAT.attributes.BOWLING_Econ}</TABLE_TD>
            </tr>
          );
        })}
      </tbody>
    </TABLE_WRAPPER>
  );
};

const CatchesTable = ({ DATA }) => {
  return (
    <TABLE_WRAPPER>
      <TABLE_THEAD_WRAPPER
        HEADERS={[
          "Catches",
          "",
        ]}
      />
  
      <tbody>
        {DATA.map((BAT, i) => {
          return (
            <tr key={i}>
              <TABLE_TD>{BAT.attributes.team?.data.attributes.Name}</TABLE_TD>
              <TABLE_TD>{BAT.attributes.PLAYERS_Catches}</TABLE_TD>
            </tr>
          );
        })}
      </tbody>
    </TABLE_WRAPPER>
  );
};

const StumpingsTable = ({ DATA }) => {
  return (
    <TABLE_WRAPPER>
      <TABLE_THEAD_WRAPPER
        HEADERS={[
          "Stumpings",
          "",
        ]}
      />
     
      <tbody>
        {DATA.map((BAT, i) => {
          return (
            <tr key={i}>
              <TABLE_TD>{BAT.attributes.team?.data.attributes.Name}</TABLE_TD>
              <TABLE_TD>{BAT.attributes.PLAYERS_Stumpings}</TABLE_TD>
            </tr>
          );
        })}
      </tbody>
    </TABLE_WRAPPER>
  );
};
