import styled from "styled-components";
import { useTheme } from "styled-components";
import { H1, H2 } from "../../../../utils/Text";
import { Image } from "@mantine/core";
import { interpolateOpacityByFrame } from "../../../../../Animation/interpolate";
import { SpringToFrom } from "../../../../../Animation/RemotionSpring";
import {
  EraseFromMiddle,
  FromMiddle,
  FromBottomToTop,
  FromLeftToRight,
} from "../../../../../Animation/ClipWipe";
import {
  FullWidthWrapperStart,
  FrostedPOTD,
  ContainerBasicFlexColumn,
  POTDImageBox,
  POTDWRAPPER,
} from "../../../../utils/Containers";
import { StatsContainer } from "../Components/StatsContainer";
export const WrapUp = ({ DATA }) => {
  return (
    <>
      <FullWidthWrapperStart
        style={{
          position: "absolute",
        }}
      >
        <POTDWRAPPER
          style={{
            clipPath: EraseFromMiddle(
              DATA.VideoSettings.SectionDuration[6] - 25,
              "Springy100"
            ),
            transform: `
            translateY(${SpringToFrom(
              0,
              1400,
              130,
              "Springy100"
            )}px)             
            `,
          }}
        >
          <FrostedPOTD
            style={{
              height: "1200px",
              clipPath: FromBottomToTop(0, "Springy100"),
            }}
          >
            <Container>
              <H1
                style={{
                  width: "100%",
                  fontSize: "4.5em",
                  lineHeight: "1.1em",
                  clipPath: FromLeftToRight(30, "Springy100"),
                }}
              >
                Congratulations {DATA.DATAOBJ.MoM.Name}.
              </H1>
              <POTDImageBox
                style={{
                  backgroundImage: `url(${DATA.DATAOBJ.MoM.TEAMLOGO})`,
                  position: "absolute",
                  top: "430px",
                  left: "430px",
                  zIndex: "1000",
                  clipPath: FromBottomToTop(5, "Springy100"),
                }}
              />
              <H2
                style={{
                  lineHeight: "1.3em",
                  fontSize: "2.1em",
                  clipPath: FromMiddle(45, "Springy100"),
                }}
              >
                a brilliant performance for {DATA.DATAOBJ.MoM.TEAM} and a well deserved LASTMAN STANDS
                Player of the Match Award.
              </H2>
            </Container>
          </FrostedPOTD>
        </POTDWRAPPER>
      </FullWidthWrapperStart>
    </>
  );
};

const Container = styled.div`
  width: 800px;
`;
