import { useEffect, useState } from "react";
import { Group, Image, Center, Space } from "@mantine/core";

// Hooks
import { UploadPlayerGalleryImage } from "../../../Hooks/Utils/useUploadPlayerImage";
import { useGetPlayerSingle } from "../../../Hooks/Content/usePlayer";

// UI ELEMENTS
//import {BLOCK_Section_Title} from '../../../Components/interface/BLOCK_Section_Title'
import { BLOCK_Section_SubHeading_INVERT } from "../../../Components/interface/BLOCK_Section_SubHeading";
import { BTN_CREATE } from "../../../Theme/Structure/Button/BTNS";
import DropZone from "../../../Components/Global/DropZone";
import { ColoredContainer } from "../../../Theme/Structure/BodyContainer";

// Add new item to Gallery
export const AddNewItemToGallery = ({
  setAddNew,
  SelectedPlayer,
  setSelectedPlayer,
}) => {
  const [isClosedDisabled, setCloseDisabled] = useState(false);

  const [GalleryImage, UploadGalleryImage] = UploadPlayerGalleryImage();
  // quietly fetch the player again after upload
  const [Player, GetPlayer] = useGetPlayerSingle();

  const [ImageFile, setImageFile] = useState(false);
  const handleFileUpload = (_FILE, _PLAYER) => {
    UploadGalleryImage(_FILE.id, _PLAYER);
  };

  const AddAnother = () => {
    setImageFile(false);
  };

  useEffect(() => {
    if (ImageFile?.id !== undefined) {
      handleFileUpload(ImageFile, SelectedPlayer);
    }
  }, [ImageFile]);

  useEffect(() => {
    if (GalleryImage === true) {
      GetPlayer(SelectedPlayer.id);
    }
  }, [GalleryImage]);

  useEffect(() => {
    if (Player !== false) {
      setSelectedPlayer(Player);
      setCloseDisabled(false);
    }
  }, [Player]);
  return (
    <>
      <BLOCK_Section_SubHeading_INVERT LABEL="Add a new Image." Color={5} />
      <Space h="md" /> 
      <Group position="right" spacing="sm" >
        {!ImageFile ? (
          false
        ) : (
          <BTN_CREATE
            BG={7}
            COPY={0}
            CALLBACK={() => {
              AddAnother(true);
            }}
            LABEL="Add Another"
            TIPOBJ={{
              LABEL: "Upload another image to the Gallery",
              COLOR: "green",
              POSITION: "right",
            }}
          />
        )}
        <BTN_CREATE
          BG={6}
          COPY={0}
          CALLBACK={() => {
            setAddNew(false);
          }}
          LABEL="Close"
          DISABLED={isClosedDisabled}
          TIPOBJ={{
            LABEL: "Close Uploader",
            COLOR: "red",
            POSITION: "top",
          }}
        />
      </Group>
      <Space h="md" />
      {!ImageFile ? (
        <DropZone
          setImageFile={setImageFile}
          setCloseDisabled={setCloseDisabled}
        />
      ) : (
        <>
          <ColoredContainer BGColor={0}>
            <Center>
              <Image
                radius="md"
                height={300}
                width={200}
                src={ImageFile.url}
                alt="Player Image"
                fit="cover"
                withPlaceholder
                caption='New Image added to Profile'
              />
            </Center>
          </ColoredContainer>
        </>
      )}
      <Space h="xl" />
    </>
  );
};
