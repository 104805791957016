import { useState } from "react" 
import { useSelector } from 'react-redux'
import {ENV} from '../../Config/RunTimeConfig'
import { parseCookies } from 'nookies'
const qs = require('qs');



export const useFetchPlayersLMSDetails = (ctx)=>{

    const [FetchStatus,setFetchStatus] = useState(null)
    const UXSTATE = useSelector((state) => state.UXSTATE);
    const jwt = parseCookies(ctx).jwt
     
    const FetchDetails = async (_ID,_LMS)=>{
      //console.log(_ID,_LMS)
        setFetchStatus(true)
        const query = qs.stringify({
            filters: {
                hasCompleted:{ $null: true,},
                franchise: { id:{ $eq: UXSTATE.SelectedFranchise.id,}}, 
              },
              populate: '*',  
          }, {
            encodeValuesOnly: true, 
          });

        const res = await fetch(`${ENV.API_URL}/api/players/Fetch/${_ID}/${_LMS}?${query}`, {
            headers: {
                Authorization: `Bearer ${jwt}`  
            }
        })
        const loginResponse = await res.json();
      //console.log(loginResponse)
        setFetchStatus(false)
    }
    return[FetchStatus,FetchDetails] 
}