import { Container } from "@mantine/core";
// UI ELEMENTS

// COMPONENTS
import { MATCHDAYRESULTSWRITEUP } from "../SocialMediaContent/MATCHDAYRESULTS";
import { LEAGUESTANDINGSWRITEUP } from "../SocialMediaContent/AILEAGUESTANDINGS";
import { UPCOMINGFIXTURESWRITEUP } from "../SocialMediaContent/UPCOMINGFIXTURES";
import { BATTINGPOTDWRITEUP } from "../SocialMediaContent/POTDBATTING";
import { BOWLINGPOTDWRITEUP } from "../SocialMediaContent/POTDBOWLING";
import {PLAYEROFTHEMATCHWRITEUP} from '../SocialMediaContent/PLAYEROFTHEMATCH';
import {TEAMSEASONALREVIEWWRITEUP} from '../SocialMediaContent/AITEAMSEASONALREVIEW';
// UI
import { BLOCK_Section_Title } from "../../../../Components/interface/BLOCK_Section_Title";

import { P } from "../../../../Theme/type";
import { VideoWizardContainer } from "../../../../Theme/Structure/BodyContainer";

export const TAB_WRITEUP = ({ Order }) => {
  const OBJ = {
    /*  POTDBATTING: <POTDBATTING Order={Order} />,
        POTDBOWLING: <POTDBOWLING Order={Order} />,
        
        LEADERBOARDSIXES: <TOP5LIST Order={Order} />,
        TOP5CATCHES: <TOP5LIST Order={Order} />,
        TOP5RUNS: <TOP5LIST Order={Order} />,
        TOP5WICKETS: <TOP5LIST Order={Order} />,
        TOP5FASTESTFIFTIES: <TOP5LIST Order={Order} />, */
    POTDBOWLING: <BOWLINGPOTDWRITEUP Order={Order} />,
    POTDBATTING: <BATTINGPOTDWRITEUP Order={Order} />,
    UPCOMINGFIXTURES: <UPCOMINGFIXTURESWRITEUP Order={Order} />,
    MATCHDAYRESULTS: <MATCHDAYRESULTSWRITEUP Order={Order} />,
    LEAGUESTANDINGS: <LEAGUESTANDINGSWRITEUP Order={Order} />, 
    PLAYEROFTHEMATCH:<PLAYEROFTHEMATCHWRITEUP Order={Order} />,
    TEAMSEASONALREVIEW:<TEAMSEASONALREVIEWWRITEUP Order={Order} />
  };

  return (
    <Container size="lg" my="xl">
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Create Social Media Copy",
          ICON: "MessageCircle",
          DESCRIPTION: "",
          TOOLBAR: false,
        }}
      />

      <Container size="lg" p="xl">
        {OBJ[Order.attributes.AssetType]}
      </Container>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Include LMS Hashtags",
          ICON: "MessageCircle",
          DESCRIPTION: "",
          TOOLBAR: false,
        }}
      />
      <Container size="lg" p="xl">
        <VideoWizardContainer>
          <Hashtags HashTags={Order} />
        </VideoWizardContainer>
      </Container>
    </Container>
  );
};

const Hashtags = ({ HashTags }) => {
  if (HashTags === undefined)
    return <>There are no Hashatgs associated with this Asset</>;
  return (
    <>
      <Container size="lg" p="xl">
        <P>
          {HashTags.attributes.asset.data.attributes.hashtags.data.map(
            (tag, i) => {
              return <span key={i}>{tag.attributes.Name} &nbsp;</span>;
            }
          )}
        </P>
      </Container>
    </>
  );
};
