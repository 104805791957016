import { useCurrentFrame } from "remotion";

// Animations

import { interpolateOpacityByFrame } from "../../../../../../Animation/interpolate";

import { Box, Center, Group, Image, Stack } from "@mantine/core";
import { H3 } from "../../../../../utils/Text";
import { TrimPlayerName } from "../../../../../../../../actions/UX";
import { getContrastColor } from "../../../../../../../../actions/colors";
export const MoMImage = ({ IMG, Name, Durations }) => {
  const frame = useCurrentFrame();
  const IMGSIZE = 230
  return (
    <Box
      style={{
        padding: ".5em",
        position: "absolute",
        top: "60px",
        width: "100%",
        opacity: interpolateOpacityByFrame(
          frame,
          Durations.RemoveScorcard - 7,
          Durations.RemoveScorcard,
          0,
          1
        ),
      }}
    >
      <Stack position="apart" style={{ width: "100%" }}>
        <H3
          style={{
            fontSize: "2.1rem",
            lineHeight: "2.1rem",
            width: "100%",
            color: getContrastColor('#ffffff'),
        
          }}
        >
          Player of the Match 
        </H3>
        <Center>
          <Image src={IMG} width={IMGSIZE} height={IMGSIZE} radius={IMGSIZE} />
        </Center>
        <H3
          style={{
            fontSize: "2.1rem",
            lineHeight: "2.1rem",
            width: "100%",
            color: getContrastColor('#ffffff'),
          }}
        >
         
          {TrimPlayerName(Name)}
        </H3>
      </Stack>
    </Box>
  );
};
