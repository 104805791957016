import { useEffect, useState } from "react";
import { Image, Center, Grid, Loader, Box, Card, Group, ScrollArea, Modal } from "@mantine/core";

import { useAssignNewDefaultImage } from "../../../Hooks/Utils/useUploadPlayerImage";
import { useGetPlayerSingle } from "../../../Hooks/Content/usePlayer";

import { H3 } from "../../../Theme/type";
import { BTN_ICON_CALLBACK } from "../../../Theme/Structure/Button/BTNS";
import { useDeletePlayerImage } from "../../../Hooks/Utils/useUploadPlayerImage";


// UI ELEMENTS
//import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";
import { BLOCK_Section_SubHeading_INVERT } from "../../../Components/interface/BLOCK_Section_SubHeading";

// Player Gallery
export const PlayerFullGallery = ({ SelectedPlayer, setSelectedPlayer }) => {
  //console.log(SelectedPlayer.attributes.ImageGallery.data)
  const GALLERY = SelectedPlayer.attributes.ImageGallery.data;
  const [PlayerImage, AssignPlayerImage] = useAssignNewDefaultImage();

  const [Player, GetPlayer] = useGetPlayerSingle();
  const [isAssignNew, setisAssignNew] = useState(false);
  const [isRemoveImage, setisRemoveImage] = useState(false);
  const [Modalopened, setModalOpened] = useState(false);
  const [FullImage, setFullImage] = useState(false);

  const AssignNewDefault = (_ID, _PLAYER) => {
    setisAssignNew(true);
    AssignPlayerImage(_ID, _PLAYER);
  };

  const RemoveImage = (_ID, _PLAYER) => { 
    setisRemoveImage(_ID);
  };

  useEffect(() => {
    if (PlayerImage !== false) {
      GetPlayer(SelectedPlayer.id);
    }
  }, [PlayerImage]);

  useEffect(() => {
    if (Player !== false) {
      setisAssignNew(false);
      setSelectedPlayer(Player);
    }
  }, [Player]);

  useEffect(() => {}, [SelectedPlayer]);

  if (GALLERY === null)
    return (
      <BLOCK_Section_SubHeading_INVERT
        LABEL="Player Gallery is currently empty"
        Color={6}
      />
    );
  return (
    <>
      <ScrollArea style={{ height: 510 }} type="auto"  scrollbarSize={4}>
      <Grid gutter="0">
        {GALLERY.map((IMG, i) => {
          return (
            <Grid.Col span={6} md={4} mb={20}  lg={3} xl={2} key={i}>
              <Card shadow="xl" p={0} radius="md" withBorder
              sx={(theme) => ({
                overflow: "visible",
                position: "relative",
                backgroundColor: theme.colors.brand[0],
                "&:hover": {
                  backgroundColor: theme.colors.brand[1],
                  color: theme.colors.brand[0],
                },
              })}>
                
                {isRemoveImage === IMG.id ? (
                  <ConfirmRemoveImage
                    RemoveImage={RemoveImage}
                    IMG={IMG}
                    setSelectedPlayer={setSelectedPlayer}
                    SelectedPlayer={SelectedPlayer}
                  />
                ) : (
                  <Card.Section>
                    <Image
                      radius="xs"
                      height={180}
                      src={IMG.attributes.url}
                      alt="Player Image"
                    />
                  </Card.Section>
                )}
                
                </Card>
                  <Box
                    style={{
                      padding: "5px",
                    }}
                  >
                    {!isAssignNew && isRemoveImage != IMG.id ? (
                      <CTABTN
                        AssignNewDefault={AssignNewDefault}
                        RemoveImage={RemoveImage}
                        IMG={IMG}
                        SelectedPlayer={SelectedPlayer}
                        isRemoveImage={isRemoveImage}
                        Modalopened={Modalopened} 
                        setModalOpened={setModalOpened}
                        setFullImage={setFullImage}
                      />
                    ) : (
                      <Center>
                        <Loader color="teal" size="xs" variant="bars" />
                      </Center>
                    )}
                  </Box>
                
              
            </Grid.Col>
          );
        })}
      </Grid>
      </ScrollArea>
      <ImageModal Modalopened={Modalopened} setModalOpened={setModalOpened} FullImage={FullImage}/>
    </>
  );
};

const ConfirmRemoveImage = ({
  RemoveImage,
  IMG,
  SelectedPlayer,
  setSelectedPlayer,
}) => {
  const [DeleteImage, processDeleteImage] = useDeletePlayerImage();
  const [Player, GetPlayer] = useGetPlayerSingle();
  const [isAssignNew, setisAssignNew] = useState(false);

  const handleDelete = () => {
    //console.log('delete')
    setisAssignNew(true);
    processDeleteImage(IMG, SelectedPlayer);
  };

  useEffect(() => {
    //console.log(DeleteImage)
  }, [DeleteImage]);

  useEffect(() => {
    if (DeleteImage !== false) {
      GetPlayer(SelectedPlayer.id);
    }
  }, [DeleteImage]);

  useEffect(() => {
    if (Player !== false) {
      setisAssignNew(false);
      setSelectedPlayer(Player);
    }
  }, [Player]);


  return (
    <>
      <Group position="center"
        style={{
          position:'absolute',
          zIndex:1000,
            bottom:'5px',
            width:'100%'
        }}
      >
        <H3 colorID={6}>
          <BTN_ICON_CALLBACK
            BG={0}
            COLOR={6}
            CALLBACK={() => {
              RemoveImage(false);
            }}
            ICON="CircleX"
            TIPOBJ={{
              LABEL: "Cancel Delete Image",
              COLOR: "red",
              POSITION: "top",
            }}
          />
        </H3>
        <BTN_ICON_CALLBACK
          BG={0}
          COLOR={7}
          CALLBACK={() => {
            handleDelete(false);
          }}
          ICON="CircleCheck"
          TIPOBJ={{
            LABEL: "Confirm Delete Image",
            COLOR: "green",
            POSITION: "top",
          }}
        />
      </Group>
      
        {!isAssignNew ? (
          <Card.Section>
            <Image
              height={180}
              src={IMG.attributes.url}
              alt="Remove Image"
              style={{filter: 'blur(2px)'}}
            />
          </Card.Section>
        ) : (
          false
        )}
     
    </>
  );
};

const CTABTN = ({
  AssignNewDefault,
  RemoveImage,
  IMG,
  SelectedPlayer,
  isRemoveImage,
  Modalopened,
  setModalOpened,
  setFullImage
}) => {

  if (
    isRemoveImage === IMG.id ||
    IMG.id === SelectedPlayer.attributes.image.data?.id
  )
    return (
      <Center>
        <BTN_ICON_CALLBACK
          BG={0}
          COLOR={3}
          CALLBACK={() => {}}
          ICON="MoodSmile"
          TIPOBJ={{
            LABEL: "Current Profile",
            COLOR: "orange",
            POSITION: "top",
          }}
        />
      </Center>
    );
  return (
    <Group position="center" gutter='xs'>
      <BTN_ICON_CALLBACK
        BG={0}
        COLOR={6}
        CALLBACK={() => {
          setModalOpened(!Modalopened)
          setFullImage(IMG.attributes.url)
        }}
        ICON="Eye"
        TIPOBJ={{
          LABEL: "View Full Image",
          COLOR: "blue",
          POSITION: "top",
        }}
      />

      <BTN_ICON_CALLBACK
        BG={0}
        COLOR={8}
        CALLBACK={() => {
          AssignNewDefault(IMG.id, SelectedPlayer.id);
        }}
        ICON="Photo"
        TIPOBJ={{
          LABEL: "Select as profile picture",
          COLOR: "green",
          POSITION: "top",
        }}
      />
      <BTN_ICON_CALLBACK
        BG={0}
        COLOR={6}
        CALLBACK={() => {
          RemoveImage(IMG.id, SelectedPlayer.id);
        }}
        ICON="Trash"
        TIPOBJ={{
          LABEL: "Remove Image",
          COLOR: "red",
          POSITION: "top",
        }}
      />
       
    </Group>
  );
};


const ImageModal=(props)=>{
  const {Modalopened,setModalOpened,FullImage} = props
  return(
    
    <Modal
    withCloseButton={false}
            centered 
            size="lg"
            overlayColor={'#4A5060'}
            overlayOpacity={0.9}
            overlayBlur={3}
            transition="fade"
            padding='0'
            shadow='xl'
    opened={Modalopened}
    onClose={() => setModalOpened(false)}
  >
    {/* Modal content */}
    <Image
      src={FullImage}
    />
  </Modal>
  )
}