/* eslint-disable react/jsx-pascal-case */
import { useSelector } from "react-redux";
import {
  ColoredContainer,
  VideoWizardContainer,
} from "../../../Theme/Structure/BodyContainer";
import { H3, P } from "../../../Theme/type";

import { Space, MediaQuery, Box, Container, Divider } from "@mantine/core";

//import {ICONDB} from '../../Theme/icons'
// Product Components
//import {LineChart} from '../../Components/Charts/Line'
import { BarChart } from "../../../Components/Charts/Bar";
//import {Radar} from '../../Components/Charts/Radar'
import moment from "moment";
import { filter, orderBy, findIndex } from "lodash";

// UI ELEMENTS
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";

export const DownloadHistory = () => {
  const AUTH = useSelector((state) => state.AUTH);

  //console.log("AUTH", AUTH.OrderHistory)

  if (AUTH.OrderHistory === undefined) return false;
  return (
    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
      <Box>
        <WeekdayBar OrderHistory={AUTH.OrderHistory.data} />
        {/* <TypeofAsset OrderHistory={AUTH.OrderHistory.data} /> */}
        <Space h={30} />
        <Divider my="lg" color="white" />
        <Space h={30} />
      </Box>
    </MediaQuery>
  );
};

const WeekdayBar = ({ OrderHistory }) => {
  const CreateRow = (DAY, ASSETS) => {
    const OBJ = { DAY: DAY };
    ASSETS.map((ASSET, i) => {
      OBJ[ASSET] = 0;
    });
    return OBJ;
  };

  const RenderPopularity = (OrderHistory) => {
    const OBJ = [];
    const ASSETS = [];

    OrderHistory.map((order, ii) => {
      //const INDEX = findIndex(OBJ, (o)=>{ return o.DAY === moment(order.attributes.createdAt).format('ddd')})
      if (ASSETS.indexOf(order.attributes.AssetType) === -1)
        ASSETS.push(order.attributes.AssetType);
    });

    const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    DAYS.map((DAY, i) => {
      OBJ.push(CreateRow(DAY, ASSETS));
    });

    OrderHistory.map((order, ii) => {
      //console.log(order)
      const INDEX = findIndex(OBJ, (o) => {
        return o.DAY === moment(order.attributes.Order_Date).format("ddd");
      });
      OBJ[INDEX][order.attributes.AssetType]++;
    });

    OBJ.map((item, i) => {
      //console.log(item)
      for (var key in item) {
        if (item[key] < 2) delete item[key];
      }
    });

    return [OBJ, ASSETS];
  };
  if (OrderHistory === undefined) return false;
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Weekday Downloads",
          ICON: "ChartDots",
          DESCRIPTION:
            "Bar chart shows your download trends over the weekdays. This can give insight into when the best time to Create and Post an Asset Type.",
          TOOLBAR: false,
        }}
      />

      <Space h="sm" />

      <Container size="xl" p={0}>
        <VideoWizardContainer>
          <div style={{ height: "400px" }}>
            <P></P>
            <BarChart
              DATA={RenderPopularity(OrderHistory)}
              Settings={{
                indexBy: "DAY",
              }}
            />
          </div>
        </VideoWizardContainer>
      </Container>
 
    </>
  );
};

const TypeofAsset = ({ OrderHistory }) => {
  const CreateRow = (item) => {
    /* const OBJ = {"DAY":DAY}
       ASSETS.map((ASSET,i)=>{
                OBJ[ASSET]=0
            }) */
    return {
      ASSET: item,
      DOWNLOADS: 0,
    };
  };

  const RenderPopularity = (OrderHistory) => {
    const OBJ = [];
    const ASSETS = [];

    OrderHistory.map((order, ii) => {
      //const INDEX = findIndex(OBJ, (o)=>{ return o.DAY === moment(order.attributes.createdAt).format('ddd')})
      if (ASSETS.indexOf(order.attributes.AssetType) === -1)
        ASSETS.push(order.attributes.AssetType);
    });

    ASSETS.map((item, i) => {
      OBJ.push(CreateRow(item));
    });

    OrderHistory.map((order, ii) => {
      const INDEX = findIndex(OBJ, (o) => {
        return o.ASSET === order.attributes.AssetType;
      });
      OBJ[INDEX].DOWNLOADS++;
    });

    return [
      orderBy(
        filter(OBJ, (o) => {
          return o.DOWNLOADS > 3;
        }),
        ["DOWNLOADS"],
        ["desc"]
      ),
      ["DOWNLOADS"],
    ];
  };
  if (OrderHistory === undefined) return false;
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Asset Preference",
          ICON: "ChartDots",
          DESCRIPTION:
            "The volume to which assets are created against all assets in the library.(Min 3 Download per asset)",
          TOOLBAR: false,
        }}
      />

      <Space h="sm" />
      <Container size="xl">
        <VideoWizardContainer>
          <div style={{ height: "400px" }}>
            <BarChart
              DATA={RenderPopularity(OrderHistory)}
              Settings={{
                indexBy: "ASSET",
              }}
            />
          </div>
        </VideoWizardContainer>
      </Container>
    </>
  );
};
