import { Box, Center, Container, Group, Space } from '@mantine/core'
import styled from 'styled-components';
import {SectionWrapper} from '../Components/Containers';
import {H2} from '../Components/type'

const SocialmediaWrapper = styled.div`
    width:60px;
    height:60px;
    @media (max-width: 576px) {
        height:40px;
        width:40px;
      }
`

const SocialMediaContainer = styled.div`

box-sizing: border-box;
width: 550px;
height: 65px;
background: #E4F6FF;
border: 1px solid #4A819E;
border-radius: 100px; 
@media (max-width: 576px) {
  
    width:90%;
  }
`


export const IndexTagline=()=>{ 
    return(
        <SectionWrapper BGCOLOR={0}>
            <Container
            sx={(theme) => ({
                position:'relative',
                '@media (min-width: 1200px)': {
                    padding:'0 0 5em 0',
                    marginTop:'-50px'
                },
                '@media (max-width: 1200px) and (min-width: 800px)': {
                    padding:'0 0 5em 0',
                    marginTop:'-50px'
                },
                '@media (max-width: 800px) and (min-width: 755px)': {
                    padding:'0 0 5em 0',
                    marginTop:'-50px'
                },'@media (max-width: 755px) and (min-width: 576px)': {
                    padding:'0 0 5em 0',
                    marginTop:'-50px'
                },'@media (max-width: 576px)': {
                    padding:'0 0 5em 0',
                    marginTop:'-0px'
                }
              })}>
            <Center>
               
                <Box sx={(theme) => ({
                '@media (min-width: 1200px)': {
                    width:'200px',
                },
                '@media (max-width: 1200px) and (min-width: 800px)': {
                    width:'200px',
                },
                '@media (max-width: 800px) and (min-width: 755px)': {
                    width:'200px',
                },'@media (max-width: 755px) and (min-width: 576px)': {
                    width:'200px',
                },'@media (max-width: 576px)': {
                    width:'160px',
                }
              })}><SVGICON /></Box>
                    
               
            </Center>
            <Center>

            <Box sx={(theme) => ({
                '@media (min-width: 1200px)': {
                    width:'50%',
                },
                '@media (max-width: 1200px) and (min-width: 800px)': {
                    width:'50%',
                },
                '@media (max-width: 800px) and (min-width: 755px)': {
                    width:'80%',
                },'@media (max-width: 755px) and (min-width: 576px)': {
                    width:'80%',
                },'@media (max-width: 576px)': {
                    width:'98%',
                }
              })}>
                <H2 weight={400} align='center'>The social media assistant for LMS franchise owners</H2>
            </Box>
            </Center>
            <Space h='md'/>
            <Center>
                <SocialMediaContainer>
                    <Group position='center' 
                    align='center'
                        style={{
                            display:'flex',
                            justifyContent:'center',
                            height:'65px'
                        }}
                    >
                        <SocialmediaWrapper><FB /></SocialmediaWrapper>
                        <SocialmediaWrapper><FB /></SocialmediaWrapper>
                        <SocialmediaWrapper><FB /></SocialmediaWrapper>
                        <SocialmediaWrapper><FB /></SocialmediaWrapper>
                        <SocialmediaWrapper><FB /></SocialmediaWrapper>
                    </Group>
                   
                </SocialMediaContainer>
            </Center>
            </Container>
        </SectionWrapper>
    )
}


const SVGICON = ()=>{
    return(
        <svg viewBox="0 0 177 177" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="88.5" cy="88.5" r="66.375" stroke="#EC8E1F" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
            <circle cx="88.5" cy="73.75" r="22.125" stroke="#EC8E1F" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M45.489 139.011C49.2373 126.536 60.7239 117.996 73.75 118H103.25C116.294 117.996 127.792 126.558 131.526 139.056" stroke="#EC8E1F" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}


const FB=()=>{
    return(
        <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.1667 41.6667V58.3333H41.6667V87.5H58.3334V58.3333H70.8334L75 41.6667H58.3334V33.3333C58.3334 31.0321 60.1988 29.1667 62.5 29.1667H75V12.5H62.5C50.9941 12.5 41.6667 21.8274 41.6667 33.3333V41.6667H29.1667" stroke="#4A5060" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    )
}