import { useState } from "react";
import { RadioVideoSponsor } from "../../settings/RadioVideoSponsor";
import { H3 } from "../../../Theme/type";
import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";
import { Group, Image, Stack } from "@mantine/core";
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
export const SelectASponsor = ({ setSponsor, Sponsor }) => {
  const [value, setValue] = useState(Sponsor);
  const [DisplaySelected, setDisplaySelected] = useState(false);
  const [ItemState, setItemState] = useState(0);
  const handleChange = (e) => {
    setValue(e);
    setSponsor(e);
    setDisplaySelected(e);
    setItemState(10);
  };
  return (
    <>
      <BLOCK_Section_SubHeading
        LABEL="Is this asset Sponsored?"
        BGColor={0}
        Color={3} 
      />

      <Group position="apart" spacing="sm" mt={10} mb={20}  grow>
        <RadioVideoSponsor CALLBACK={handleChange} DefaultValue={value} />
      </Group>
    </>
  );
};

/* const RenderSelected = ({ SELECTED }) => {
  //console.log(SELECTED.attributes.Logo.data.attributes.url)
  return (
    <Stack align="center" justify="space-around"  mb={20}>
      <Image
        radius={100}
        width={50}
        src={SELECTED.attributes.Logo.data.attributes.url}
        withPlaceholder
      />
      <H3 colorID={5}>{SELECTED.attributes.Name}</H3>
      <H3 colorID={5}>{SELECTED.attributes.Tagline}</H3>
    </Stack>
  );
};
 */