import { Player } from "video-react";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";

//import Loading from './Loading'
// Theme
import { H3, Header, P } from "../../../Theme/type";
import {
  SectionContainer,
  RaisedContainer,
  BodyContainer,
  ColoredContainer,
  AssetHeaderContainer,
  VideoWizardContainer,
} from "../../../Theme/Structure/BodyContainer";
import {
  ColorSwatch,
  Container,
  Divider,
  Grid,
  Image,
  SimpleGrid,
  Space,
  Group,
  Center,
  Table,
  Box,
} from "@mantine/core";
import { BTN_CTA } from "../../../Theme/Structure/Button/BTNS";
// Product Components
import { LineChart } from "../../../Components/Charts/Line";
import { BarChart } from "../../../Components/Charts/Bar";
import moment from "moment";
import MDstyle from "../../../markdown-styles.module.css";
// UI ELEMENTS
//import {BLOCK_Section_Title} from '../../../Components/interface/BLOCK_Section_Title'
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";

import { filter, findIndex } from "lodash";
export const VideoIndexContainer = (props) => {
  const { setStart, start, showAsset } = props;
  return (
    <BodyContainer>
      <H3 colorID={5}>Video Assets</H3>
      <Index_Header setStart={setStart} start={start} showAsset={showAsset} />
      {props.children}
    </BodyContainer>
  );
};

export const IndexTitle = ({ showAsset, start, setStart }) => {
  const AUTH = useSelector((state) => state.AUTH);

  const ORDERS =
    AUTH.OrderHistory === undefined
      ? 0
      : filter(AUTH.OrderHistory.data, (o) => {
          return o.attributes.AssetType === showAsset.ComponentName;
        });

  //console.log("ORDERS", ORDERS)

  return (
    <>
      <AssetHeaderContainer BGColor={0} Radius={5}>
        <Center p="md">
          <Header
            lineHeight={".75em"} 
            fontSize={`3.4em`}
            align="right"
            colorID={0}
            weight={900}
          >
            {showAsset.Name}
          </Header>
        </Center>
      </AssetHeaderContainer>

     
    </>
  );
};
//colorID=4,size="lg", align='left',weight=400, underline=false,transform="capitalize"

export const Index_Header = ({ showAsset, start, setStart }) => {
  return (
    <SectionContainer>
      <Grid gutter="sm">
        <Grid.Col lg={5} md={7} sm={12} orderMd={2} order={1}>
          <Box my={20}>
            {start ? (
              false
            ) : (
              <BTN_CTA
                LABEL={`Build Asset`} 
                BG="7"
                Color="0"
                CALLBACK={() => {
                  setStart(true);
                }}
              />
            )}
          </Box>

          <BLOCK_Section_SubHeading LABEL="VIDEO EXAMPLE" Color={4} />
          <Space h="md" />

          <Box
            style={{
              boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.25)",
              margin: "0 2.5em",
            }}
          >
            <Player>
              <source src={showAsset.ExampleVideoURL} />
            </Player>
          </Box>
          <Space h="xl" />
          {showAsset?.Examples?.data !== null ? (
            <DisplayStillImages showAsset={showAsset} />
          ) : (
            false
          )}

          <BLOCK_Section_SubHeading LABEL="Associated Hashtags" Color={4} />
          <Space h="md" />
          <P colorID={5}>
            {showAsset.hashtags.data.map((hash, i) => {
              return <span key={i}>{hash.attributes.Name} &nbsp;</span>;
            })}
          </P>
        </Grid.Col>

        <Grid.Col lg={7} md={5} sm={12} orderMd={1} order={2}>
          <VideoWizardContainer>
            <BLOCK_Section_SubHeading LABEL="Description" Color={5} />
            <Space h="sm" />
            <ReactMarkdown className={MDstyle.reactMarkDown}>
              {showAsset.description}
            </ReactMarkdown>
          </VideoWizardContainer>

          <DisplayTemplates Templates={showAsset.templates.data} />
          {/* <DisplaySocialOption Options={showAsset.video_components.data} /> */}
          <TrendingCharts showAsset={showAsset} />
        </Grid.Col>
      </Grid>
    </SectionContainer>
  );
};

/** THIS IS DEAD, it is in 'know your league, which needs updating */
export const Index_VideoDescription = ({ showAsset, setStart }) => {
  return (
    <>
      <Grid grow my={0}>
        <Grid.Col span={5}>
          <RaisedContainer>
            <Player>
              <source src={showAsset.ExampleVideoURL} />
            </Player>
          </RaisedContainer>
        </Grid.Col>
        <Grid.Col
          span={7}
          sx={(theme) => ({ backgroundColor: theme.colors.brand[0] })}
        >
          <H3>Asset Options</H3>
          <Divider />
          <Space h="xl" />
          {showAsset.video_components.data.map((asset, i) => {
            return (
              <div key={i}>
                <P>{asset.attributes.Name} </P>
                <P>{asset.attributes.UsedFor}</P>
              </div>
            );
          })}
          <Space h="xl" />
          <DisplayTemplates Templates={showAsset.templates.data} />
        </Grid.Col>
      </Grid>
    </>
  );
};

/* ************** */
// ASSET METADATA
/* ************** */

const DisplayStillImages = ({ showAsset }) => {
  return (
    <>
      <BLOCK_Section_SubHeading LABEL="STILLS EXAMPLE" Color={4} />
      <Space h="md" />

      <SimpleGrid cols={3} breakpoints={[{ minWidth: "md", cols: 4 }]}>
        {showAsset?.Examples?.data.map((ex, i) => {
          return <Image width={100} src={ex.attributes.url} key={i} />;
        })}
      </SimpleGrid>

      <Space h="xl" />
    </>
  );
};

const DisplayTemplates = ({ Templates }) => {
  return (
    <>
      <VideoWizardContainer>
        <BLOCK_Section_SubHeading LABEL="Templates" Color={5} />

        {/* <P>
          Templates have a set of unique characteristics and animations. Select
          a template option when creating a video to preview the design before
          purchasing. A list of Templates currently available for this asset.
        </P> */}

        <Space h="xs" />
        <Container size="xs">
          <SimpleGrid cols={2} breakpoints={[{ minWidth: "md", cols: 4 }]}>
            {Templates.map((asset, i) => {
              return (
                <Box key={i}>
                  <Center>
                    <Image
                      width={80}
                      height={80}
                      radius={10}
                      src={`${asset.attributes.Icon.data.attributes.url}`}
                    />
                  </Center>
                  <H3 colorID={2} align="center" transform="uppercase">
                    {asset.attributes.Name}
                  </H3>
                </Box>
              );
            })}
          </SimpleGrid>
        </Container>
      </VideoWizardContainer>
    </>
  );
};

const DisplaySocialOption = ({ Options }) => {
  return (
    <>
      <VideoWizardContainer>
        <BLOCK_Section_SubHeading LABEL="Options" Color={5} />
        <Space h="sm" />
        <P>
          As each Social media platform has a preference for a specific ratio
          and quality, we want to give as many options as possible to ensure
          your content can be used on as many platforms as possible.
        </P>

        <Space h="xs" />

        <Table
          horizontalSpacing="xl"
          verticalSpacing="xs"
          fontSize="sm"
          striped
          highlightOnHover
        >
          <thead>
            <tr>
              <th>Ratio</th>
              <th>Still</th>
              <th>Used with</th>
            </tr>
          </thead>
          <tbody>
            {Options.map((asset, i) => {
              return (
                <tr key={i}>
                  <td>{asset.attributes.ratio.data.attributes.Name}</td>
                  <td>{asset.attributes.hasStills ? "yes" : "no"}</td>
                  <td>
                    <Group>
                      {asset.attributes.social_media_platforms.data.map(
                        (sm, i) => {
                          return (
                            <Image
                              width={30}
                              src={sm.attributes.Logo.data.attributes.url}
                              key={i}
                            />
                          );
                        }
                      )}
                    </Group>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </VideoWizardContainer>
    </>
  );
};

const TrendingCharts = ({ showAsset }) => {
  return (
    <>
      <VideoWizardContainer>
        <BLOCK_Section_SubHeading LABEL="Trending Charts" Color={5} />

        {/* <P>
          As each Social media platform has a preference for a specific ratio
          and quality, we want to give as many options as possible to ensure
          your content can be used on as many platforms as possible.
        </P> */}

        <Space h="xs" />
        <PopularityLine showAsset={showAsset} />
        <WeekdayBar showAsset={showAsset} />
      </VideoWizardContainer>
    </>
  );
};

const PopularityLine = ({ showAsset }) => {
  const RenderPopularity = () => {
    const OBJ = [
      {
        id: showAsset.Name,
        data: [],
      },
    ];

    // Find all of the months in the DATA
    showAsset.orders.data.map((order, ii) => {
      const MONTHINDEX = findIndex(OBJ[0].data, (o) => {
        return o.x === moment(order.attributes.createdAt).format("MMMM");
      });
      if (MONTHINDEX === -1) {
        OBJ[0].data.push({
          x: moment(order.attributes.createdAt).format("MMMM"),
          y: 1,
        });
      } else {
        OBJ[0].data[MONTHINDEX].y = OBJ[0].data[MONTHINDEX].y + 1;
      }
    });
    return OBJ;
  };

  return (
    <ColoredContainer BGColor={0}>
      <div style={{ height: "300px" }}>
        <H3>Asset Popularity</H3>
        <P>The total number of Downloads per month.</P>
        <LineChart DATA={RenderPopularity()} />
      </div>
    </ColoredContainer>
  );
};

const WeekdayBar = ({ showAsset }) => {
  const CreateRow = (DAY, ASSETS) => {
    const OBJ = { DAY: DAY };
    ASSETS.map((ASSET, i) => {
      OBJ[ASSET] = 0;
    });
    return OBJ;
  };

  const RenderPopularity = (showAsset) => {
    const OBJ = [];
    const ASSETS = [showAsset.Name];
    const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    DAYS.map((DAY, i) => {
      OBJ.push(CreateRow(DAY, ASSETS));
    });

    showAsset.orders.data.map((order, ii) => {
      const INDEX = findIndex(OBJ, (o) => {
        return o.DAY === moment(order.attributes.createdAt).format("ddd");
      });
      OBJ[INDEX][showAsset.Name]++;
    });

    return [OBJ, ASSETS];
  };
  return (
    <ColoredContainer BGColor={0}>
      <div style={{ height: "300px" }}>
        <H3>Weekday Downloads</H3>
        <P>
          Download trend over the weekdays. This can give insight into when the
          best time to Create and Post an Asset Type.
        </P>
        <BarChart
          DATA={RenderPopularity(showAsset)}
          Settings={{
            indexBy: "DAY",
          }}
        />
      </div>
    </ColoredContainer>
  );
};
