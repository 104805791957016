/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUnpublishOrder } from "../../../../Hooks/Content/useOrders";

import { P } from "../../../../Theme/type";
import { Container, Space } from "@mantine/core";
import { ColoredContainer } from "../../../../Theme/Structure/BodyContainer";

import { BTN_CTA } from "../../../../Theme/Structure/Button/BTNS";
//
import { BLOCK_Section_Title } from "../../../../Components/interface/BLOCK_Section_Title";
import { useGetUserInvoices } from "../../../../Hooks/Content/useInvoices";

export const TAB_SETTINGS = ({ ORDER }) => {
  const [Unpublish, UpdateUnpublish] = useUnpublishOrder();
  const [invoices, FetchUserInvoices] = useGetUserInvoices();

  const [confirm, setConfirm] = useState(false);
  let location = useLocation();
  let navigate = useNavigate();
  const UnPublishItem = (ID) => {
    UpdateUnpublish({ publishedAt: null }, ID);
  };
  useEffect(() => {
    if (Unpublish !== null) {
      FetchUserInvoices();
    }
  }, [Unpublish]);
  useEffect(() => {
    if (invoices) {
      if (location.pathname === `/downloadable/${ORDER.id}`) {
        navigate("/downloads", { replace: true });
      }
    }
  }, [invoices]);

  return (
    <Container size="lg" my="xl">
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Delete Asset",
          ICON: "Trash",
          DESCRIPTION: "",
          TOOLBAR: false,
        }}
      />
      <ColoredContainer BGColor={1}>
        <P>
          Videos have a lifespan of 7 days, However, should the video contain a
          mistake or inappropriate images, you can remove it from your account.
          By doing so will free up the Namespace to re-create the asset in
          question.
        </P>
        <Space h="xl" />
        {!confirm ? (
          <BTN_CTA
            CALLBACK={() => {
              setConfirm(true);
            }}
            LABEL={"DELETE ASSET"}
            BG="7"
            Color="0"
          />
        ) : (
          <BTN_CTA
            CALLBACK={() => {
              UnPublishItem(ORDER.id);
            }}
            LABEL={"Confirm DELETE"}
            BG="6"
            Color="0"
          />
        )}
      </ColoredContainer>
    </Container>
  );
};
