import { useSelector } from "react-redux";
// Theme
import { H1, H2, H3, H4_UPPERCASE, P } from "../../../../Theme/type";

import { VideoWizardContainer } from "../../../../Theme/Structure/BodyContainer";
import moment from "moment";
import { Group, Avatar, Text } from "@mantine/core";

export const DataCheck = () => {
  const RENDER = useSelector((state) => state.RENDER);
  const MOM = RENDER.DATAOBJ.DATAOBJ.MoM;
  const IMGRATO = 120;
  console.log(RENDER, MOM);
  const ConvertData = (FromDate) => {
    const d = new Date(`${FromDate}`);
    return moment(d).format("DD MMMM");
  };
  const ConvertTime = (FromDate) => {
    const TIME = FromDate.split(":");
    return `${TIME[0]}:${TIME[1]}`;
  };

  if (!MOM) return <NoMOMFound />;
  return (
    <VideoWizardContainer>
      <Group noWrap position="center">
        <Avatar src={MOM.IMAGE} size={150} radius="md" />
        <div>
          <Text
            size="xs"
            sx={{ textTransform: "uppercase" }}
            weight={700}
            color="dimmed"
          >
            Player of the Match
          </Text>

          <Text size="lg" weight={500} c>
            {MOM.Name}
          </Text>

          <Group noWrap spacing={10} mt={3}>
            <Text size="xs" color="dimmed">
              {RENDER.DATAOBJ.DATAOBJ.TeamA.Name} vs{" "}
              {RENDER.DATAOBJ.DATAOBJ.TeamB.Name}
            </Text>
          </Group>

          <Group noWrap spacing={10} mt={5}>
            <Text size="xs" color="dimmed">
              {ConvertData(RENDER.DATAOBJ.DATAOBJ.Date)} -{" "}
              {ConvertTime(RENDER.DATAOBJ.DATAOBJ.Time)}
            </Text>
          </Group>
        </div>
      </Group>
    </VideoWizardContainer>
  );
};

const NoMOMFound = () => {
  return <>No Player of the Match Found for this Game</>;
};
