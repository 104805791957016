import { ThemeProvider } from "styled-components";
import { LEAGUESTANDINGS_GLASS } from "../../theme/Templates/GLASS/index";

export const Build_LEAGUESTANDINGS_4by5 = (props) => {
  const { RENDER } = props;

  const TEMPLATES = {
    Glass: <LEAGUESTANDINGS_GLASS RENDER={RENDER} />,

  };

  return (
    <ThemeProvider theme={RENDER.THEME.VideoTheme.Theme}>
      {TEMPLATES[RENDER.THEME.VideoTemplate]}
    </ThemeProvider>
  );
};
