import { Series } from "remotion";

import { FullWidthWrapper } from "../../../../utils/Containers";
// SERIES SEQUENCES
import { TheTeamsWrapper } from "./Series/TheTeams";
import { StatsWrapper } from "./Series/Thestats";
import { ThePlayers } from "./Series/ThePlayers";
import { MoM } from "./Series/MoM";

export const MATCHREVIEW_BODY = ({ DATA, VideoSettings, RENDER }) => {
 
  return (
    <>
      <FullWidthWrapper>
        <Series> 
          <Series.Sequence 
            durationInFrames={VideoSettings.SectionDuration[2]}
            layout="none"
          >
            <TheTeamsWrapper
              DATA={DATA}
              Duration={VideoSettings.SectionDuration[2]}
            />
          </Series.Sequence>
          <Series.Sequence
            durationInFrames={VideoSettings.SectionDuration[3]}
            layout="none"
          >
            <StatsWrapper
              DATA={DATA}
              Duration={VideoSettings.SectionDuration[3]}
              INN={{
                first: "TeamA", 
                second: "TeamB",
              }}
            />
          </Series.Sequence>

          <Series.Sequence
            durationInFrames={VideoSettings.SectionDuration[4]}
            layout="none"
          >
            <StatsWrapper
              DATA={DATA}
              Duration={VideoSettings.SectionDuration[4]}
              INN={{
                first: "TeamB",
                second: "TeamA",
              }} 
            />
          </Series.Sequence>

          <Series.Sequence
            durationInFrames={VideoSettings.SectionDuration[5]}
            layout="none"
          >
            <ThePlayers
              DATA={DATA}
              Duration={VideoSettings.SectionDuration[5]}
            />
          </Series.Sequence>

          <Series.Sequence
            durationInFrames={VideoSettings.SectionDuration[6]}
            layout="none"
          >
            <MoM DATA={DATA} Duration={VideoSettings.SectionDuration[6]} />
          </Series.Sequence>
        </Series>
      </FullWidthWrapper>
    </>
  );
};
