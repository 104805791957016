import { useEffect, useState } from "react";
import { UISkeletonLines } from "../../../Components/Global/Skeleton";
import { PageHeader } from "../../../Components/interface/ProductHeader";
import { useSelectVideoTemplate } from "../../../Hooks/Content/useVideoTemplate";
import {
  Card,
  Avatar,
  Text,
  Group,
  SimpleGrid,
  Container,
} from "@mantine/core";
import { ModalGallery } from "../../../Components/Global/ModalCarouselGallery";

export const TEMPLATES = () => {
  const [VideoTemplate, FetchVideoTemplate] = useSelectVideoTemplate();
  const [opened, setOpened] = useState(false);
  const [Gallery, setGallery] = useState([]);
  useEffect(() => {
    FetchVideoTemplate();
  }, []);
  useEffect(() => {
  //console.log(VideoTemplate);
  }, [VideoTemplate]);
  if (VideoTemplate === null) return <UISkeletonLines />;
  return (
    <>
      <PageHeader NavPosition="Templates" />
      <Container size={"lg"}>
        
        <SimpleGrid
          cols={1}
          breakpoints={[
            { minWidth: "xs", cols: 2 },
            { minWidth: "md", cols: 2 },
            { minWidth: "lg", cols: 3 },
          ]}
        >
          {VideoTemplate.map((DATA, i) => {
            return (
              <DisplaySingleTemplate
                key={i}
                DATA={DATA}
                setOpened={setOpened}
                setGallery={setGallery}
              />
            );
          })}
        </SimpleGrid>
      </Container>
    </>
  );
};

const DisplaySingleTemplate = ({ DATA, setOpened, setGallery }) => {
//console.log(DATA.attributes.Examples?.data);
  return (
    <Card withBorder p="xl" radius="md">
      <Card.Section
        sx={{
          backgroundImage: `url(${DATA.attributes.Image?.data?.attributes.url})`,
          height: 140,
          backgroundPosition: "center",
        }}
      />
      <Avatar
        src={DATA.attributes.Icon.data.attributes.url}
        size={80}
        radius={80}
        mx="auto"
        mt={-30}
      />
      <Text align="center" size="lg" weight={500} mt="sm" transform="uppercase">
        {DATA.attributes.DisplayName}
      </Text>
      <Text align="center" size="sm" color="dimmed">
        Video Assets : {DATA.attributes.assets.data.length}
      </Text>
      <Text align="center" size="sm" color="dimmed">
        Themes : {DATA.attributes.themes.data.length}
      </Text>
      <Text align="left" size="sm">
        {DATA.attributes.description}
      </Text>

      <Group mt="md" position="center" spacing={30}></Group>

      {DATA.attributes.Examples?.data !== null ? (
        <ModalGallery Gallery={DATA.attributes.Examples.data} />
      ) : (
        false
      )}
    </Card>
  );
};
