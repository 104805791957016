import { useFetchSelectedTournament } from "../../../Hooks/Content/useTournaments";
import {
  BTN_ICON_CALLBACK,
  BTN_ICON_LINK,
} from "../../../Theme/Structure/Button/BTNS";

import { useEffect, useState } from "react";
import {
  ColoredContainer,
  ContentContainerx3,
  VideoWizardContainer,
} from "../../../Theme/Structure/BodyContainer";
import { H3, H4_UPPERCASE, Header, P } from "../../../Theme/type";
//import {ICONDB} from '../../../Theme/icons'
import {
  Box,
  Group,
  Image,
  ScrollArea,
  SimpleGrid,
  Space,
} from "@mantine/core";

import DropZone from "../../../Components/Global/DropZoneVideo";

// HOOKS
import { UploadVideoToTournament } from "../../../Hooks/Utils/useUploadVideo";
import { DisplayVideos } from "./DisplayVideos";

// UI ELEMENTS
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";
import { BLOCK_ToolBar } from "../../../Components/interface/BLOCK_Toolbar";
export const UploadTournamentVideo = ({ Selected, SelectedID, setRefresh }) => {
  const [Active, setActive] = useState(false);

  useEffect(() => {
  //console.log("Selected", Selected);
  }, [Selected]);

  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Tournament Videos",
          ICON: "Upload",
          DESCRIPTION:
            "Upload tournament and Interview Videos here to use in the Tournament Assets. Click the Upload button on the right to begin uploading.",
          TOOLBAR: false,
        }}
      />
      <BLOCK_ToolBar
        TOOLS={[
          <BTN_ICON_CALLBACK
            TIPOBJ={{
              LABEL: "Video Uploader",
              COLOR: "gray",
              POSITION: "top",
            }}
            CALLBACK={() => {
              setActive(!Active);
            }}
            ICON={Active ? "CircleX" : "Upload"}
          />,
        ]}
      />

      <VideoWizardContainer>
        {Active ? (
          <VideoDropZone
            setActive={setActive}
            setRefresh={setRefresh}
            SelectedID={SelectedID}
          />
        ) : (
          <DisplayVideos Selected={Selected} />
        )}

        <Space h="xl" />
      </VideoWizardContainer>
    </>
  );
};

const VideoDropZone = ({ setActive, SelectedID, setRefresh }) => {
  const [ImageFile, setImageFile] = useState(false);
  const [isClosedDisabled, setCloseDisabled] = useState(false);

  const [GalleryVideo, UploadGalleryVideo] = UploadVideoToTournament();

  const handleFileUpload = (_FILE, _PLAYER) => {
    UploadGalleryVideo(_FILE.id, _PLAYER);
  };

  useEffect(() => {
    if (ImageFile?.id !== undefined) {
    //console.log(ImageFile);
      handleFileUpload(ImageFile, SelectedID);
    }
  }, [ImageFile]);

  useEffect(() => {
    if (GalleryVideo !== null) {
      setActive(false);
      setRefresh(true);
    }
  }, [GalleryVideo]);

  return (
    <Box>
      <H3>Uploader</H3>
      <P>
        Drag n Drop you videos on to the container below or Click the Image to
        launch a file picker
      </P>
      <P colorID={4}>
        NB: for the best restults possible,Shoot all video in 30 FPS and Convert
        all video formats to .webM
      </P>
      <DropZone
        setImageFile={setImageFile}
        setCloseDisabled={setCloseDisabled}
      />
    </Box>
  );
};
