import { useState } from "react" 
import { useSelector } from 'react-redux'
import {ENV} from '../../Config/RunTimeConfig'
import { parseCookies } from 'nookies'


export const useGetPlayerMilestones = (ctx)=>{

    const [Milestones,setMilestones] = useState(false)
    const UXSTATE = useSelector((state) => state.UXSTATE);
    const jwt = parseCookies(ctx).jwt
    const Franchise = parseCookies(ctx).isSelectedFranchise
    
     
    const FetchMilestones = async ()=>{
          const res = await fetch(`${ENV.API_URL}/api/players/Milestones/${Franchise}`, {
                headers: {
                        Authorization: `Bearer ${jwt}`
                    }
                })
        const loginResponse = await res.json();
        setMilestones(loginResponse)
    }
    return[Milestones,FetchMilestones]   
}