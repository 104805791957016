import { orderBy } from "lodash";
// CORE Components
import { SupImages } from "./SuplimentaryImages";
import { StatContainer } from "./StatContainer";
import { COPY, EMPASISVALUE } from "./Copy";
export const SectionBowling = ({ DATA, OBJ }) => {
  const IMAGES = orderBy(
    DATA.DATAOBJ.TeamStats.PLAYERS,
    ["BOWLING_Wkts"],
    ["desc"]
  ).slice(0, 5);
  return (
    <>
      <Sentence
        DURATION={OBJ.DURATION}
        Name={DATA.DATAOBJ.General.Team}
        Wickets={DATA.DATAOBJ.TeamStats.BOWLING.WICKETS}
        Overs={DATA.DATAOBJ.TeamStats.BOWLING.OVERS}
        Games={DATA.DATAOBJ.General.Played}
        Conceded={DATA.DATAOBJ.TeamStats.BOWLING.RUNS}
      />

      <StatContainer
        LABEL={`BOWLING`}
        DURATION={OBJ.DURATION}
        TIMINGS={{
          SlideIn: 3,
          LabelPosition: 100,
          ClipIn: 15,
          SlideUp: 30,
        }}
      >
        <SupImages
          DATA={IMAGES}
          DURATION={OBJ.DURATION}
          Limit={5}
          VAR={"BOWLING_Wkts"}
          Label="Top 5 Wickets Taken"
        />
      </StatContainer>
    </>
  );
};

const Sentence = ({ Name, Overs, Wickets, Conceded, Games, DURATION }) => {
  return (
    <>
      <COPY DURATION={DURATION}>
        {Name} TOOK <EMPASISVALUE VALUE={Wickets} /> WICKETS IN THEIR {Games}{" "}
        GAMES AT AN AVERAGE OF
        <EMPASISVALUE VALUE={(Conceded / Wickets).toFixed(1)} /> RUNS PER
        WICKET, TAKING A WICKET EVERY
        <EMPASISVALUE VALUE={((Overs * 5) / Wickets).toFixed(1)} /> BALLS.
      </COPY>
    </>
  );
};