/* eslint-disable react/jsx-pascal-case */
import { parseCookies, setCookie } from "nookies";
import { SelectFixtureID } from "./SelectFixtureID";
import { FixtureResults } from "./FixtureResults";
import { Container, Table } from "@mantine/core";
import { BLOCK_Section_Title } from "../../../../../Components/interface/BLOCK_Section_Title";
import { BTN_CREATE } from "../../../../../Theme/Structure/Button/BTNS";
import { VideoWizardContainer } from "../../../../../Theme/Structure/BodyContainer";
import {
  TABLE_TD,
  TABLE_WRAPPER,
} from "../../../../../Components/interface/Wrapper/Table";
// FIND A FIXTURE
export const FindFixtureWrapper = ({ GetFixture, Fixture, setConfirm }) => {
  //GetFixture={GetFixture} Fixture={Fixture} setConfirm={setConfirm}
  return (
    <>
      <Container size={"md"} mb="xl">
        <SelectFixtureID GetFixture={GetFixture} />
      </Container>
      <FixtureResults
        Fixture={Fixture}
        setConfirm={setConfirm}
        GetFixture={GetFixture}
      />
      <SearchHistory GetFixture={GetFixture} setConfirm={setConfirm} />
    </>
  );
};

const SearchHistory = ({ GetFixture }) => {
  const cookies = parseCookies();
  const HISTORY =
    cookies.hasCommentaryHistory === undefined
      ? []
      : JSON.parse(cookies.hasCommentaryHistory);

  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Recent COMMENTARY  History",
          ICON: "Download",
          DESCRIPTION: "Some of the past games",
          TOOLBAR: false,
        }}
      />

      <Container size={"lg"}>
        <VideoWizardContainer>
          <TABLE_WRAPPER>
            <tbody>
              {HISTORY.reverse().map((history, i) => {
                return (
                  <tr key={i}>
                    <TABLE_TD> {history.Home}</TABLE_TD>
                    <TABLE_TD>vs</TABLE_TD>
                    <TABLE_TD>{history.Away}</TABLE_TD>
                    <TABLE_TD>
                      <BTN_CREATE
                        CALLBACK={() => {
                          GetFixture(history.id);
                        }}
                        LABEL="View"
                      />
                    </TABLE_TD>
                  </tr>
                );
              })}
            </tbody>
          </TABLE_WRAPPER>
        </VideoWizardContainer>
      </Container>
    </>
  );
};
