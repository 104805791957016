import { useCurrentFrame } from "remotion";
import { useTheme } from "styled-components";
//import styled from "styled-components";

// Animations
import { SpringToFrom } from "../../../../../../Animation/RemotionSpring";
import { interpolateOpacityByFrame } from "../../../../../../Animation/interpolate";
import { FromMiddle } from "../../../../../../Animation/ClipWipe";
import { Center, Group, Image, Stack } from "@mantine/core";

import { P } from "../../../../../utils/Text";
import { TrimTeamName } from "../../../../../../../../actions/UX";
import { getContrastColor } from "../../../../../../../../actions/colors";

export const TeamNames = ({ Durations, GAME, LEN }) => {
  const theme = useTheme();
  const frame = useCurrentFrame();
  return (
    <div
      style={{
        backgroundColor: theme.Base[1],
        border:`2px solid ${theme.Base[2]}` ,
        height: "120px",
        position: "absolute",
        top: "60px",
        borderRadius: "100px",
        padding: "15px",
        width: "100%  ",
        clipPath: FromMiddle(15),
       
      }}
    >
      <TeamsAndResult GAME={GAME} Durations={Durations} LEN={LEN} />
    </div>
  );
};

const TeamsAndResult = ({ GAME, LEN }) => {
  //console.log(GAME);
  const theme = useTheme();
  return (
    <Group position="apart">
      <Center>
        <Stack>
          <LOGOS FromTO={[390, 0]} LOGO={GAME.homeTeamLogo} LEN={LEN} />
        </Stack>
      </Center>

      <DisplayP VALUE={TrimTeamName(GAME.homeTeam, 20)} />
      <DisplayP VALUE={"v"} />
      <DisplayP VALUE={TrimTeamName(GAME.awayTeam, 20)} />
      <Center>
        <Stack>
          <LOGOS FromTO={[-390, 0]} LOGO={GAME.awayTeamLogo} LEN={LEN} />
        </Stack>
      </Center>
    </Group>
  );
};

const LOGOS = ({ LOGO, FromTO, LEN }) => {
  const RATIO = LEN === 4 ? 90 : 90;
  return (
    <Image
      src={LOGO}
      width={RATIO}
      height={RATIO}
      radius={100}
      style={{
        transform: ` translateX(${SpringToFrom(
          30,
          FromTO[0],
          FromTO[1],
          "Springy100"
        )}px) scale(${SpringToFrom(10, 0, 1, "Springy100")})`,
      }}
    />
  );
};

const DisplayP = ({ VALUE }) => {
  const theme = useTheme();
  
  return (
    <P
      style={{
        textAlign: "center",
        fontSize: "35px",
        lineHeight: "35px",
        margin: "0",
        padding: 0,
        fontWeight: "400",
        letterSpacing: "-2px",
        clipPath: FromMiddle(35),
        color: getContrastColor(theme.Base[1]), 
      }}
    >
      {VALUE}
    </P>
  );
};
