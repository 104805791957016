import { useCurrentFrame} from 'remotion';
import {IntroContainer} from "../../utils/Containers"
import {VideoLabelH2, } from "../../utils/Text"
import {SpringToFrom} from '../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../Animation/interpolate'
import {FromMiddle} from '../../../Animation/ClipWipe'
import { useTheme } from 'styled-components';
export const VideoTypeLabel = (props)=>{
    const {VideoLabel} = props
    const frame = useCurrentFrame();
    const theme = useTheme();
    return(
	<IntroContainer
        style={{ padding:0}}
    >       
             <VideoLabelH2
            style={{
                color:theme.Base[4],
                display:'flex',
                flexWrap:'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                fontSize: '7em',
                lineHeight:'0.9em',
                opacity:interpolateOpacityByFrame(frame, 0,60, 0, 1),
                transform: `translateY(${SpringToFrom(0,10,0,'Springy100')}px)`,
                clipPath:FromMiddle(0,'Springy100'),
            }}
            >{VideoLabel}
           </VideoLabelH2>	
	</IntroContainer>
    )
}