import { useEffect, useState } from "react";
import { FindTeamLogoURI } from "../../../../../actions/video";

const VideoOBJ = {
  VideoDurationinFrames: null,
  SectionDuration: [90, 120, 180, 210, 180, 120],
  VideoBodyTiming: [180, 210, 180].reduce((a, b) => a + b),
  RenderStill: [70, 210, 320, 560, 750],
};
export const useCreateFixtures = () => {
  const VideoSettings = { ...VideoOBJ };
  const [Fixtures, setFixtures] = useState({ VideoSettings });
 
  const VideoDuration = () => {
    return Fixtures.VideoSettings.SectionDuration.reduce((a, b) => a + b);
  };

  const CreateTeamOBJ = (TEAM) => {
    //console.log(TEAM.Logo.data.attributes.formats)
    return {
      Name: TEAM.Name,
      City_Rank: TEAM.City_Rank,
      National_Rank: TEAM.National_Rank,
      World_Rank: TEAM.World_Rank,
      Form: TEAM.Form,
      Games: TEAM.Games,
      Won: TEAM.Won,
      Lost: TEAM.Lost,
      Win_Ratio: TEAM.Win_Ratio,
      //batsmanToWatch: batsmanToWatch(TEAM.player_battings),
      //bowlersToWatch: bowlersToWatch(TEAM.player_bowlings),
      LOGO: FindTeamLogoURI({ attributes: TEAM }),
    };
  };

  const FindFixtures = async (DATA) => {
    const DATAOBJ = {
      TeamA: CreateTeamOBJ(DATA.attributes.teams.data[0].attributes),
      TeamB: CreateTeamOBJ(DATA.attributes.teams.data[1].attributes),
      Date: DATA.attributes.Date,
      Time: DATA.attributes.Time,
      ground: DATA.attributes.ground.data.attributes.Name,
      umpire: DATA.attributes.umpire.data.attributes.Name,
    };

    Fixtures.VideoSettings.VideoDurationinFrames = VideoDuration();

    setFixtures({ ...Fixtures, DATAOBJ: { ...DATAOBJ } });
  };

  useEffect(() => {}, [Fixtures]);

  return [Fixtures, FindFixtures];
};
