import { ThemeProvider } from 'styled-components'

// Import Design Templates for MATCHDAYRESULT.
// Add new deisng patterns below
// GLASS
import {PLAYERMILESTONE_4by5} from '../../theme/Templates/GLASS/index'
// END


export const Build_PLAYERMILESTONE_4by5 = (props)=>{ 
	const {DATA,RENDER} = props
	const TEMPLATES={
		"Glass":<PLAYERMILESTONE_4by5  DATA={DATA}  RENDER={RENDER} MainTitle='Milestone' Subtitle='Career' /> 
	} 
    return (    
		<ThemeProvider theme={RENDER.THEME.VideoTheme.Theme} >  
			{
				TEMPLATES[RENDER.THEME.VideoTemplate] 
			}
		</ThemeProvider>  
    );     
} 
