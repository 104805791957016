import { Container } from "@mantine/core";
// COMPONENTS
import { POTDBATTING } from "../SocialMediaContent/POTDBATTING";
import { POTDBOWLING } from "../SocialMediaContent/POTDBOWLING";
import { UpComingFixtures } from "../SocialMediaContent/UPCOMINGFIXTURES";
import { TOP5LIST } from "../SocialMediaContent/TOP5";
import { MATCHDAYRESULTS } from "../SocialMediaContent/MATCHDAYRESULTS";
import { VideoWizardContainer } from "../../../../Theme/Structure/BodyContainer";
import { BLOCK_Section_Title } from "../../../../Components/interface/BLOCK_Section_Title";
export const TAB_RAWDATA = ({ Order }) => {
  const OBJ = {
    POTDBATTING: <POTDBATTING Order={Order} />,
    POTDBOWLING: <POTDBOWLING Order={Order} />,
    UPCOMINGFIXTURES: <UpComingFixtures Order={Order} />,
    LEADERBOARDSIXES: <TOP5LIST Order={Order} />,
    TOP5CATCHES: <TOP5LIST Order={Order} />,
    TOP5RUNS: <TOP5LIST Order={Order} />,
    TOP5WICKETS: <TOP5LIST Order={Order} />,
    TOP5FASTESTFIFTIES: <TOP5LIST Order={Order} />,
    MATCHDAYRESULTS: <MATCHDAYRESULTS Order={Order} />,
  };
  return (
    <Container size="lg" my="xl">
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Raw Data",
          ICON: "Database",
          DESCRIPTION: "",
          TOOLBAR: false,
        }}
      />
      <Container size="md" p="xl">
        <VideoWizardContainer>
          {OBJ[Order.attributes.AssetType]}
        </VideoWizardContainer>
      </Container>
    </Container>
  );
};
