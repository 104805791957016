import { useState } from "react";
import { RadioVideoLayout } from "../../settings/RadioVideoLayout";
import { H3 } from "../../../Theme/type";
import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";
import { Group, Image, Stack } from "@mantine/core";
import { pick } from "lodash";

import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
import { SelectVideoTemplates } from "../../settings/SelectVideoTemplate";
export const SelectVideoTemplate = ({ setLayout, Layout, AssetID }) => {
  const [value, setValue] = useState(Layout);
  const [DisplaySelected, setDisplaySelected] = useState(false);
  const [ItemState, setItemState] = useState(0);

  const handleChange = (e) => {
    const Template = pick(e.attributes, ["Name", "Icon"]);
    //console.log(Template)
    setValue(Template.Name);
    setLayout(Template);
    setDisplaySelected(Template);
    setItemState(10);
  };

  return (
    <>
      <BLOCK_Section_SubHeading
        LABEL="Select a Template"
        BGColor={0}
        Color={DisplaySelected ? 7 : 5}
      />

      <VideoWizardContainer DividerSize={ItemState} Padding="xs">
        <Group position="apart" spacing="xl" grow>
          <RadioVideoLayout
            CALLBACK={handleChange}
            DefaultValue={value}
            AssetID={AssetID}
          />
          {/* {DisplaySelected ? (
            <RenderSelected SELECTED={DisplaySelected} />
          ) : (
            false
          )} */}
        </Group>
      </VideoWizardContainer>
    </>
  );
};

/* const RenderSelected = ({ SELECTED }) => {
  return (
    <Stack align="center" justify="space-around">
      <Image radius={100} width={60} src={SELECTED.Icon.data.attributes.url} />
      <H3 colorID={5}>{SELECTED.Name}</H3>
    </Stack>
  );
}; */

export const DropDownSelectVideoTemplate = ({
  setLayout,
  Layout,
  AssetComponentName,
}) => {
  const [value, setValue] = useState(Layout);
  const [DisplaySelected, setDisplaySelected] = useState(false);
  const [ItemState, setItemState] = useState(0);

  const handleChange = (e) => {
    const Template = pick(e.attributes, ["Name", "Icon"]);
    //console.log(Template)
    setValue(Template.Name);
    setLayout(Template);
    setDisplaySelected(Template);
    setItemState(10);
  };

  return (
    <>
      <BLOCK_Section_SubHeading
        LABEL="Select a Template"
        BGColor={0}
        Color={3} 
      /> 

      <Group position="apart" spacing="xl" mt={10} mb={20} grow>
        <SelectVideoTemplates
          CALLBACK={handleChange}
          DefaultValue={value}
          AssetComponentName={AssetComponentName}
        />
      </Group>
    </>
  );
};
