import { useEffect } from "react";
import {
  Container,
  BackgroundImage,
  Space,
  Center,
  SimpleGrid,
  Box,
  createStyles,
  Divider,
  Badge,
  Card,
  Avatar,
  Group,
} from "@mantine/core";
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";
import { useGetSponsors } from "../../../Hooks/Content/useSponsors";
import { P } from "../../../Theme/type";
import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";
import { BTN_ICON_INTERNALLINK } from "../../../Theme/Structure/Button/BTNS";

const useStyles = createStyles((theme) => ({
  rating: {
    position: "absolute",
    top: theme.spacing.xs,
    right: theme.spacing.xs + 2,
    pointerEvents: "none",
  },
}));

export const Sponsorships = () => {
  const [Sponsors, GetSponsors] = useGetSponsors();
  const { classes, theme } = useStyles();
  useEffect(() => {
    GetSponsors();
  }, []);
  useEffect(() => {
    console.log(Sponsors);
    //setUpdate(false);
  }, [Sponsors]);
  if (!Sponsors) return false;
  if (Sponsors.length === 0) return <NoSponsors />;
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Sponsors",
          ICON: "ReportMoney",
          DESCRIPTION:
            "Monetize your Statto content by adding a sponsor to your videos.",
          TOOLBAR: false,
        }}
      />
      <Container size={"xl"}>
        <VideoWizardContainer>
          <BLOCK_Section_SubHeading
            LABEL={`showing ${Sponsors.slice(0, 3).length} of ${
              Sponsors.length
            }`}
            Color={5}
          />
          <SimpleGrid
            cols={3}
            spacing="sm"
            mt={7}
            breakpoints={[
              { maxWidth: "lg", cols: 2 },
              { maxWidth: "md", cols: 1 },
            ]}
          >
            {Sponsors.slice(0, 3).map((sponsor, i) => {
              return (
                <Card
                  key={i}
                  //shadow="md"
                  radius="md"
                  sx={(theme) => ({
                    position: "relative",
                    backgroundColor: theme.colors.brand[0],
                    border: `1px solid ${theme.colors.brand[1]}`,
                    padding: theme.spacing.xl,
                    borderRadius: theme.radius.sm,
                    "&:hover": {
                      boxShadow: `${theme.shadows.md} !important`,
                      transform: "scale(1.03)",
                      backgroundColor: theme.colors.brand[1],
                    },
                  })}
                  p="md"
                >
                  <Badge
                    className={classes.rating}
                    color={sponsor.attributes.isActive ? "green" : "red"}
                  >
                    {sponsor.attributes.isActive ? "Active" : "Archived"}
                  </Badge>
                  <Avatar src={sponsor.attributes.Logo.data.attributes.url} />
                  <P transform="uppercase" weight={200} size={23} colorID={3}>
                    {sponsor.attributes.Name}
                  </P>
                  <P size={"sm"} colorID={5} weight={900}>
                    {sponsor.attributes.Label}
                  </P>
                  <P size={"sm"} colorID={5} weight={900}>
                    {sponsor.attributes.Tagline}
                  </P>
                  <P size={"sm"} colorID={5} weight={900}>
                    {sponsor.attributes?.URL}
                  </P>
                </Card>
              );
            })}
          </SimpleGrid>
          <Group position="right" mt={10}>
            <BTN_ICON_INTERNALLINK
              TO={"/create-new-sponsor"}
              ICON={"CirclePlus"}
              TIPOBJ={{
                LABEL: "Create New Sponsor",
                COLOR: "Green",
                POSITION: "top",
              }}
            />
          </Group>
        </VideoWizardContainer>
      </Container>
      <Space h={30} />
      <Divider my="lg" color="white" />
      <Space h={30} />
    </>
  );
};

const NoSponsors = () => {
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Sponsors",
          ICON: "ReportMoney",
          DESCRIPTION:
            "Monetize your Statto content by adding a sponsor to your videos.",
          TOOLBAR: false,
        }}
      />
      <Container size={"xl"}>
        <VideoWizardContainer>
          <BLOCK_Section_SubHeading
            LABEL={`Did you know you could add sponsors to your Statto Videos?`}
            Color={5}
          />

          <P>
            Statto gives you the option to monetize your content with a paid
            sponsorship deals, Its as easy as creating a sponsor under your
            franchise tab, and then selecting it in the sponsorship options when
            creating a video.
          </P>
          <Space h={10} />
          <P>
            Click the plus in the bottom corner to create your first sponsor!
          </P>
          <Group position="right" mt={10}>
            <BTN_ICON_INTERNALLINK
              TO={"/create-new-sponsor"}
              ICON={"CirclePlus"}
              TIPOBJ={{
                LABEL: "Create New Sponsor",
                COLOR: "Green",
                POSITION: "top",
              }}
            />
          </Group>
        </VideoWizardContainer>
      </Container>
      <Space h={30} />
      <Divider my="lg" color="white" />
      <Space h={30} />
    </>
  );
};
