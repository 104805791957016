import styled from "styled-components";
import { useTheme } from "styled-components";
import { H1, H3 } from "../../../../utils/Text";
import { interpolateOpacityByFrame } from "../../../../../Animation/interpolate";
import { SpringToFrom } from "../../../../../Animation/RemotionSpring";
import {
  EraseFromMiddle,
  FromLeftToRight,
  FromRightToLeft,
  FromBottomToTop,
} from "../../../../../Animation/ClipWipe";
import {
  FullWidthWrapperStart,
  FrostedPOTD,
  POTDWRAPPER,
} from "../../../../utils/Containers";

import { StatsContainer } from "../Components/StatsContainer";

export const Batting = ({ DATA }) => {
  return (
    <>
      <FullWidthWrapperStart
        style={{
          position: "absolute",
        }}
      >
        <POTDWRAPPER
          style={{
            clipPath: EraseFromMiddle(
              DATA.VideoSettings.SectionDuration[3] - 25,
              "Springy100"
            ),
            transform: `
            translateY(${SpringToFrom(
              0,
              1400,
              130,
              "Springy100"
            )}px)             
            `,
          }}
        >
          <FrostedPOTD
            style={{
              clipPath: FromBottomToTop(0, "Springy100"),
            }}
          >
            <StatsContainer
              Label="Batting"
              DIS={<Main DATA={DATA} />}
              SUP={<SUP DATA={DATA} />}
            />
          </FrostedPOTD>
        </POTDWRAPPER>
      </FullWidthWrapperStart>
    </>
  );
};

const Main = ({ DATA }) => {
  return (
    <H1
      style={{
        textAlign: "left",
        clipPath: FromLeftToRight(15, "Springy100")
      }}
    >
      {DATA.DATAOBJ.MoM.Batting.BATTING_Runs} (
      {DATA.DATAOBJ.MoM.Batting.BATTING_Balls}) <br />
      {DATA.DATAOBJ.MoM.Batting.BATTING_HowOut}
    </H1>
  );
};

const SUP = ({ DATA }) => {
  const theme = useTheme()
  return (
    <>
      <H3
        style={{
          textAlign: "right",
          fontSize: "2em",
          lineHeight: "1.1em",
          
          clipPath: FromRightToLeft(30, "Springy100")
        }}
      >
        STRIKE RATE : {DATA.DATAOBJ.MoM.Batting.BATTING_SR}
      </H3>
      <H3
        style={{
          textAlign: "right",
          fontSize: "2em",
          lineHeight: "1.1em",
          
          clipPath: FromRightToLeft(35, "Springy100")
        }}
      >
        Fours : {DATA.DATAOBJ.MoM.Batting.BATTING_fours}
      </H3>
      <H3
        style={{
          textAlign: "right",
          fontSize: "2em",
          
          lineHeight: "1.1em",
          clipPath: FromRightToLeft(40, "Springy100")
        }}
      >
        Sixes : {DATA.DATAOBJ.MoM.Batting.BATTING_sixes}
      </H3>
    </>
  );
};
