import { Container, Skeleton } from '@mantine/core';
import { ColoredContainer,BodyContainer } from '../../Theme/Structure/BodyContainer';
import { H3 } from '../../Theme/type';

export const UISkeletonLines = () => {
  return (
    <Container size='xl'>
     
      <Skeleton height={8} radius="xl" />
      <Skeleton height={8} mt={6} radius="xl" />
      <Skeleton height={8} mt={6} width="70%" radius="xl" />
    </Container>
  );
}

export const UISkeletonCircle = () => {
    return (
      <Container>
        <Skeleton height={100} circle mb="xl" />
      </Container>
    );
  }

  export const UISkeletonContent = () => {
    return (
      <ColoredContainer>
        <Skeleton height={80} mt={6} radius="xs" />
      </ColoredContainer>
    );
  }

  // Skeleton Groups

  export const UILargeComponent = () => {
    return (
      <ColoredContainer>
        <UISkeletonLines />
        <UISkeletonContent />
      </ColoredContainer>
    );
  }

  export const UILargeCard = () => {
    return (
      <BodyContainer>
        <UISkeletonCircle />
        <UISkeletonLines />
      </BodyContainer>
    );
  }

  export const UISkeletonLinesWithCopy = ({Copy='Building'}) => {
    return (
      <Container size='xl'>
        <H3 colorID={5}>{Copy}</H3>
        <Skeleton height={8} radius="xl" />
        <Skeleton height={8} mt={6} radius="xl" />
        <Skeleton height={8} mt={6} width="70%" radius="xl" />
      </Container>
    );
  }