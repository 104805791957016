import { useState } from "react" 
import axios from 'axios';
 
import {ENV} from '../../Config/RunTimeConfig'
import { parseCookies } from 'nookies'
import {uniq} from 'lodash'
const qs = require('qs');


/*  PROCESS UPLOAD TO COLLECTION*/
const STRAPI_UploadNewGallery = async(_URL,_JWT,_ARR)=>{
    const res = await fetch(`${_URL}`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${_JWT}`
        },
        body: JSON.stringify({
            data: {
                TournamentVideos: _ARR,
              }
        })
    })
    const PlayerGallery = await res.json();
  //console.log(PlayerGallery)
    return PlayerGallery
}



/* UPDLOAD VIDEO TO GALLERY*/
export const UploadVideoToTournament =(ctx)=>{ 

    const [GalleryVideo,setGalleryVideo] = useState(null);
    const jwt = parseCookies(ctx).jwt;

    const ConstructGalleryARR=async(_FILE, _TOURNAMENTID)=>{

        const query = qs.stringify({populate: '*'},{encodeValuesOnly: true,});
       
        const res = await fetch(`${ENV.API_URL}/api/tournaments/${_TOURNAMENTID}?${query}`, {
            headers: { Authorization: `Bearer ${jwt}`}
        })

        const TournamentResponse = await res.json();
      //console.log(TournamentResponse)
        const TournamentGallery = TournamentResponse.data.attributes.TournamentVideos.data
      
        
        if(TournamentGallery === null)
            return [_FILE]
        
        const ARR=[_FILE]
        TournamentGallery.map((IMG,i)=>{
                ARR.push(IMG.id)
                return true
            })
            return uniq(ARR)

    }


    const UploadGalleryVideo = async (_FILE, _TOURNAMENTID)=>{
        setGalleryVideo(false)
        const GALLERYARR =  await ConstructGalleryARR(_FILE, _TOURNAMENTID)

      //console.log(GALLERYARR)
        //_URL,_JWT,_ARR 
        const UploadStatus = await STRAPI_UploadNewGallery(`${ENV.API_URL}/api/tournaments/${_TOURNAMENTID}`,jwt,GALLERYARR)
        if(UploadStatus.data.id)
            setGalleryVideo(true)
    }
    return [GalleryVideo,UploadGalleryVideo]
}