/* eslint-disable react/jsx-pascal-case */
// HOOKS
import { useEffect, useState } from "react";
// Actions
import { ordinal_suffix_of } from "../../../../../../../actions/Stats";
import { ActionIcon, Table } from "@mantine/core";

import { ICONDB } from "../../../../../../../Theme/icons";
import { orderBy } from "lodash";
import { BTN_ICON_CALLBACK } from "../../../../../../../Theme/Structure/Button/BTNS";

import { P } from "../../../../../../../Theme/type";
import { TABLE_TD, TABLE_WRAPPER } from "../../../../../../../Components/interface/Wrapper/Table";

export const SORTABLETABLE = ({ DATA, CALLBACK, OBJ }) => {
  const [SortBy, setSortBy] = useState(OBJ.OrderBy);
  const [asc, setasc] = useState(false);
  const [List, setList] = useState(DATA);

  useEffect(() => {
    //console.log(DATA)
    if (DATA) {
      setList(
        orderBy(
          DATA,
          [SortBy, OBJ.OrderBy],
          [asc ? "asc" : "desc", asc ? "desc" : "asc"]
        )
      );
    }
  }, [SortBy, asc]);

  const SelectedColor = (PATH, SELECTED) => {
    //console.log(PATH, SELECTED);
    return PATH === SELECTED ? 4 : 3;
  };
  const SelectedWeight = (PATH, SELECTED) => {
    return PATH === SELECTED ? 600 : 100;
  };

  return (
    
    <TABLE_WRAPPER>
   
      <thead>
        <tr>
          <th></th>
          {OBJ.ITEMS.map((item, i) => {
            return (
              <th key={i}>
                <ActionIconController
                  setSortBy={setSortBy}
                  SortBy={SortBy}
                  setasc={setasc}
                  asc={asc}
                  Label={item.PATH}
                />
                {item.Name}
              </th>
            );
          })}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {List.map((element, i) => {
          return (
            
            <tr key={i}>
              <TABLE_TD>{ordinal_suffix_of(i + 1)}</TABLE_TD>
              {OBJ.ITEMS.map((item, i) => {
                return (
                  <TABLE_TD key={i}>
                    <P
                      colorID={SelectedColor(item.PATH, SortBy)}
                      weight={SelectedWeight(item.PATH, SortBy)}
                    >
                      {item.Name === "Name"
                        ? element?.Attributes === undefined
                          ? element[item.PATH]
                          : element.Attributes[item.PATH]
                        : item.ToFixed
                        ? element[item.PATH].toFixed(2)
                        : element[item.PATH]}
                    </P>
                  </TABLE_TD>
                );
              })}

              <TABLE_TD>
                {element?.Attributes !== undefined ? (
                  <BTN_ICON_CALLBACK
                    CALLBACK={() => {
                      CALLBACK(element.Attributes);
                    }}
                    ICON="Eye"
                    TIPOBJ={{
                      LABEL: `More on ${
                        element?.Attributes === undefined
                          ? element.Name
                          : element.Attributes.Name
                      }`,
                      COLOR: "green",
                      POSITION: "top",
                    }}
                  />
                ) : (
                  false
                )}
              </TABLE_TD>
            </tr>
          );
        })}
      </tbody>
    </TABLE_WRAPPER>
  );
};

const ActionIconController = ({ setSortBy, SortBy, setasc, asc, Label }) => {
  return (
    <ActionIcon
      sx={(theme) => ({
        color: SortBy === Label ? theme.colors.brand[4] : theme.colors.brand[1],
      })}
      onClick={() => {
        setSortBy(Label);
        setasc(!asc);
      }}
    >
      {ICONDB.Point.Component}
    </ActionIcon>
  );
};
