import { Table, ThemeIcon, ActionIcon, Modal } from "@mantine/core";
import { useEffect, useState } from "react";

import {
  useGetStripedDownFixtures,
  useUpdateGetFixtures,
} from "../../../Hooks/Content/useFixtures";
import { BTN_ICON_LINK } from "../../../Theme/Structure/Button/BTNS";

import { ICONDB } from "../../../Theme/icons";
import { orderBy } from "lodash";
import moment from "moment";
import { ContentContainerx3 } from "../../../Theme/Structure/BodyContainer";
import { IsLoading } from "../../../Components/Global/isLoadingDefault";
import { TABLE_TD, TABLE_THEAD_WRAPPER, TABLE_WRAPPER } from "../../../Components/interface/Wrapper/Table";

export const FixturesandResults = ({ Selected }) => {
  // Contructor
  const [Fixtures, setFixtures] = useGetStripedDownFixtures();
  const [updateFixture, PutUpdateFixture] = useUpdateGetFixtures();
  const [resyncPage, setResyncPage] = useState(false);

  /* ********************************** */
  // Utils
  /* ********************************** */
  const IDARR = () => {
    const ARR = [];
    Selected.franchise.data.attributes.watch_lists.data.map((ID, i) => {
      ARR.push(ID.id);
    });
    return ARR;
  };
  const ConvertData = (DateRange) => {
    const d = new Date(`${DateRange}`);
    return moment(d).format("D MMM");
  };

  const RefreshDOM = () => {
    setFixtures(IDARR(), [Selected.START, Selected.END]);
    setResyncPage(true);
  };
  const ResyncGame = (GAME) => {
    PutUpdateFixture(GAME, {
      hasPlayerSync: false,
      hasPlayers: false,
      hasRelational: false,
      hasResult: false,
    });
  };
  /* ********************************** */
  // Component setup
  /* ********************************** */
  useEffect(() => {
    setFixtures(IDARR(), [Selected.START, Selected.END]);
  }, []);
  /* ********************************** */

  /* ********************************** */
  // useEffect
  /* ********************************** */

  useEffect(() => {
    if (updateFixture !== null) RefreshDOM();
  }, [updateFixture]);
  useEffect(() => {
    if (Fixtures) setResyncPage(false);
  }, [Fixtures]);
  useEffect(() => {
  //console.log(Selected);
  }, [Selected]);

  const CreateFixtureRows = () => {
    return orderBy(Fixtures, ["attributes.Date"]).map((fixture, i) => {
      const f = fixture.attributes;

      return (
        <tr key={i}>
          
          <TABLE_TD>{ConvertData(f.Date)}</TABLE_TD>
          <TABLE_TD>
            {f.HomeTeamResult} <br />
            {f.HomeTeam}
          </TABLE_TD>
          <TABLE_TD>
            {f.AwayTeamResult} <br /> {f.AwayTeam}
          </TABLE_TD>
          <TABLE_TD>{f.ResultStatement}</TABLE_TD>
          <TABLE_TD>
            <BTN_ICON_LINK
              ICON="ExternalLink"
              TIPOBJ={{
                LABEL: "Visit Scorecard",
                COLOR: "green",
                POSITION: "top",
              }}
              TO={`https://www.lastmanstands.com/leagues/scorecard/1st-innings?fixtureid=${f.fixtureID}`}
            />
          </TABLE_TD>
          <TABLE_TD>
            {f.hasMeta ? (
              <ThemeIcon color="teal">{ICONDB.CircleCheck.Component}</ThemeIcon>
            ) : (
              <ThemeIcon>{ICONDB.CircleX.Component}</ThemeIcon>
            )}
          </TABLE_TD>
          <TABLE_TD>
            {f.hasRelational ? (
              <ThemeIcon color="teal">{ICONDB.CircleCheck.Component}</ThemeIcon>
            ) : (
              <ThemeIcon>{ICONDB.CircleX.Component}</ThemeIcon>
            )}
          </TABLE_TD>
          <TABLE_TD>
            {f.hasPlayerSync ? (
              <ThemeIcon color="teal">{ICONDB.CircleCheck.Component}</ThemeIcon>
            ) : (
              <ThemeIcon>{ICONDB.CircleX.Component}</ThemeIcon>
            )}
          </TABLE_TD>
          <TABLE_TD>
            {f.hasResult ? (
              <ThemeIcon color="teal">{ICONDB.CircleCheck.Component}</ThemeIcon>
            ) : (
              <ThemeIcon>{ICONDB.CircleX.Component}</ThemeIcon>
            )}
          </TABLE_TD>
          <TABLE_TD>
            <ActionIcon
              onClick={() => {
                ResyncGame(fixture.id);
              }}
            >
              {ICONDB.Refresh.Component}
            </ActionIcon>
          </TABLE_TD>
        </tr>
      );
    });
  };
  if (resyncPage)
    return (
      <ContentContainerx3>
        <IsLoading />
      </ContentContainerx3>
    );
  return (
    <>
      <TABLE_WRAPPER>
        
      <TABLE_THEAD_WRAPPER
        HEADERS={[
          "Date",
          "Home",
          "Away",
          "Results",
          "LMS",
          "Details",
          "Meta",
          "Players",
          "Result",
          "Reset"
        ]}
      />
        
        <tbody>{CreateFixtureRows(Fixtures)}</tbody>
      </TABLE_WRAPPER>
    </>
  );
};
