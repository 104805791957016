import {
  Settings,
  CalendarEvent,
  Calendar,
  CalendarTime,
  Search,
  Photo,
  MessageCircle,
  School,
  ListNumbers,
  Trash,
  ArrowsLeftRight,
  LayoutDashboard,
  User,
  CameraSelfie,
  Camera,
  Tournament, 
  Video,
  FileSearch,
  ShoppingCart,
  Home,
  ReportMoney,
  Logout,
  PlayerPlay,
  Download,
  Upload,
  CircleCheck,
  CircleX,
  Refresh,
  SquarePlus,
  ExternalLink,
  MoodSmile,
  InfoCircle,
  Select,
  CirclePlus,
  Battery4,
  BatteryOff,
  Cricket,
  BallTennis,
  BoxMultiple6,
  ChartDots,
  BrandAsana,
  Badge,
  Eye,
  Tag,
  ArrowBackUp,
  Login,
  Point,
  Click,
  Users,
  AlertTriangle,
  ColorSwatch,
  Layout2,
  Album,
  QuestionMark,
  Ballpen,
  Database

} from "tabler-icons-react";

export const ICONDB = {
  null: { Component: <LayoutDashboard /> },
  MessageCircle: { Component: <MessageCircle /> },
  LayoutDashboard: { Component: <LayoutDashboard /> },
  User: { Component: <User /> },
  CameraSelfie: { Component: <CameraSelfie /> }, 
  Camera: { Component: <Camera /> },
  Search: { Component: <Search /> },
  CalendarEvent: { Component: <CalendarEvent /> },
  Calendar: { Component: <Calendar /> },
  Photo: { Component: <Photo /> },
  Trash: { Component: <Trash /> },
  ArrowsLeftRight: { Component: <ArrowsLeftRight /> },
  Settings: { Component: <Settings /> },
  Logout: { Component: <Logout /> },
  CalendarTime: { Component: <CalendarTime /> },
  School: { Component: <School /> },
  Tournament: { Component: <Tournament /> },
  Video: { Component: <Video /> },
  FileSearch: { Component: <FileSearch /> },
  ShoppingCart: { Component: <ShoppingCart /> },
  Home: { Component: <Home /> },
  PlayerPlay: { Component: <PlayerPlay /> },
  Download: { Component: <Download /> },
  Upload: { Component: <Upload /> },
  CircleCheck: { Component: <CircleCheck /> },
  CirclePlus: { Component: <CirclePlus /> },
  CircleX: { Component: <CircleX /> },
  Refresh: { Component: <Refresh /> },
  ReportMoney: { Component: <ReportMoney /> },
  ListNumbers: { Component: <ListNumbers /> },
  SquarePlus: { Component: <SquarePlus /> },
  ExternalLink: { Component: <ExternalLink /> },
  MoodSmile: { Component: <MoodSmile /> },
  InfoCircle: { Component: <InfoCircle /> },
  Select: { Component: <Select /> },
  Battery4: { Component: <Battery4 /> },
  BatteryOff: { Component: <BatteryOff /> },
  Cricket: { Component: <Cricket /> },
  BallTennis: { Component: <BallTennis /> },
  BoxMultiple6: { Component: <BoxMultiple6 /> },
  ChartDots: { Component: <ChartDots /> },
  Badge: { Component: <Badge /> },
  BrandAsana: { Component: <BrandAsana /> },
  Eye: { Component: <Eye /> },
  Tag: { Component: <Tag /> },
  ArrowBackUp: { Component: <ArrowBackUp /> },
  Login: { Component: <Login /> },
  Point: { Component: <Point /> },
  Click: { Component: <Click /> },
  Users: { Component: <Users /> },
  AlertTriangle: { Component: <AlertTriangle /> },
  ColorSwatch: { Component: <ColorSwatch /> },
  Layout2: { Component: <Layout2 /> },
  Album: { Component: <Album /> },
  QuestionMark:{Component:<QuestionMark />},
  Ballpen:{Component:<Ballpen />},
  Database:{Component:<Database />},
};
