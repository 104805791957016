import styled from "styled-components";
import { useTheme } from "styled-components";
import { H1, H3 } from "../../../../utils/Text";
import { Image } from "@mantine/core";
import { interpolateOpacityByFrame } from "../../../../../Animation/interpolate";
import { SpringToFrom } from "../../../../../Animation/RemotionSpring";
import {
  EraseFromMiddle,
  FromBottomToTop,
  FromRightToLeft,
  FromLeftToRight,
} from "../../../../../Animation/ClipWipe";
import {
  FullWidthWrapperStart,
  FrostedPOTD,
  POTDWRAPPER,
} from "../../../../utils/Containers";
import { StatsContainer } from "../Components/StatsContainer";
export const Bowling = ({ DATA }) => {
  return (
    <>
      <FullWidthWrapperStart
        style={{
          position: "absolute",
        }}
      >
        <POTDWRAPPER
          style={{
            clipPath: EraseFromMiddle(
              DATA.VideoSettings.SectionDuration[4] - 25,
              "Springy100"
            ),
            transform: `
            translateY(${SpringToFrom(
              0,
              1400,
              130,
              "Springy100"
            )}px)             
            `,
          }}
        >
          <FrostedPOTD
            style={{
              clipPath: FromBottomToTop(0, "Springy100"),
            }}
          >
            <StatsContainer
              Label="Bowling"
              DIS={<Main DATA={DATA} />}
              SUP={<SUP DATA={DATA} />}
            />
          </FrostedPOTD>
        </POTDWRAPPER>
      </FullWidthWrapperStart>
    </>
  );
};

const Main = ({ DATA }) => {
  return (
    <H1
      style={{
        textAlign: "left",
        clipPath: FromLeftToRight(15, "Springy100"),
      }}
    >
      {DATA.DATAOBJ.MoM.Bowling.BOWLING_Wkts}/
      {DATA.DATAOBJ.MoM.Bowling.BOWLING_Runs} (
      {DATA.DATAOBJ.MoM.Bowling.BOWLING_Overs})
    </H1>
  );
};

const SUP = ({ DATA }) => {
  return (
    <>
      <H3
        style={{
          textAlign: "right",
          fontSize: "2em",
          lineHeight: "1.1em",
          clipPath: FromRightToLeft(30, "Springy100"),
        }}
      >
        Maidens : {DATA.DATAOBJ.MoM.Bowling.BOWLING_Maidens}
      </H3>
      <H3
        style={{
          textAlign: "right",
          fontSize: "2em",
          lineHeight: "1.1em",
          clipPath: FromRightToLeft(35, "Springy100"),
        }}
      >
        Economy : {DATA.DATAOBJ.MoM.Bowling.BOWLING_Econ}
      </H3>
    </>
  );
};
