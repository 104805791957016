import { Series, useCurrentFrame, Img } from "remotion";
import { Lottie } from "@remotion/lottie";

// Lottie JSON FIles
import animationData from "./Components/Lottie/lf20_klurhj0n.json";
import animationPercentage from "./Components/Lottie/percentage.json";
import animationDuck from "./Components/Lottie/Duck.json";

import LMS from "../../../../../../Assets/defaultLarge.png";
import styled from "styled-components";
// animations
import { EraseFromMiddle } from "../../../../../../Animation/ClipWipe";

import { interpolateOpacityByFrame } from "../../../../../../Animation/interpolate";
// Components
import { TOPTOBOTTOM } from "./Components/ToptoBottom";
import { COPY, EMPASISVALUE } from "./Components/Copy";
import { Space } from "@mantine/core";

const LMSIMGSTYLES = {
  mixBlendMode: "luminosity",
  position: "absolute",
  bottom: "10%",
  left: "10%",
  width: "80%",
  opacity: 0.04,
};

export const SECTION_BATTING = (props) => {
  const SectionLength = 180;
  return (
    <SeriesContainer>
      <Series>
        <Series.Sequence durationInFrames={SectionLength} layout="none">
          <BATTING_PANEL_1  {...props}/>
        </Series.Sequence>
        <Series.Sequence durationInFrames={SectionLength} layout="none">
          <BATTING_PANEL_2  {...props}/>
        </Series.Sequence>
        <Series.Sequence durationInFrames={SectionLength} layout="none">
          <BATTING_PANEL_2_5  {...props}/>
        </Series.Sequence>
        <Series.Sequence durationInFrames={SectionLength} layout="none">
          <BATTING_PANEL_3  {...props}/>
        </Series.Sequence>
        <Series.Sequence durationInFrames={SectionLength} layout="none">
          <BATTING_PANEL_3_5  {...props}/>
        </Series.Sequence>
      </Series>

      <TOPTOBOTTOM
        POSITIONING={{
          TOP: [-100, -79],
          BOTTOM: [100, 55],
        }}
      />
      <Img src={LMS} style={LMSIMGSTYLES} />
    </SeriesContainer>
  );
};

const BATTING_PANEL_1 = ({ DATA,RENDER }) => {
  //console.log(RENDER.META.Franchise);
  const SUMof = DATA.DATAOBJ.DATERANGESTATS.SUMof;
  const frame = useCurrentFrame();
  return (
    <SeriesContainer>
      <TITLE
        style={{
          opacity: interpolateOpacityByFrame(frame, 30, 50, 0, 1),
          clipPath: EraseFromMiddle(180 - 15, "Springy100"),
        }}
      >
        GENERAL
      </TITLE>

      <COPY DURATION={180}>
        <Lottie
          style={{
            width: "500px",
            margin: "0px 250px",
            textAlign: "center",
            opacity: interpolateOpacityByFrame(frame, 15, 50, 0, 1),
            clipPath: EraseFromMiddle(180 - 15, "Springy100"),
          }}
          animationData={animationData}
        />
        <EMPASISVALUE VALUE={SUMof.FIXTURES} /> Matches were played across{" "}
        {RENDER.META.Franchise} this week.
        <br />
      </COPY>
    </SeriesContainer>
  );
};

const BATTING_PANEL_2 = ({ DATA }) => {
  console.log(DATA);
  const SUMof = DATA.DATAOBJ.DATERANGESTATS.SUMof;
  const frame = useCurrentFrame();
  return (
    <SeriesContainer>
      <TITLE
        style={{
          opacity: interpolateOpacityByFrame(frame, 30, 50, 0, 1),
        }}
      >
        BATTING
      </TITLE>
      <COPY DURATION={180}>
        <EMPASISVALUE VALUE={SUMof.SIXES + SUMof.FOURS} /> boundaries were hit.{" "}
        <Space h={40} />
        Sixes : <EMPASISVALUE VALUE={SUMof.SIXES} />
        <br />
        fours : <EMPASISVALUE VALUE={SUMof.FOURS} />
      </COPY>
    </SeriesContainer>
  );
};
const BATTING_PANEL_2_5 = ({ DATA }) => {
  console.log(DATA);
  const SUMof = DATA.DATAOBJ.DATERANGESTATS.SUMof;
  const frame = useCurrentFrame();
  return (
    <SeriesContainer>
      <TITLE>BATTING</TITLE>
      <COPY DURATION={180}>
        <Lottie
          playbackRate={2}
          loop={true}
          style={{
            width: "250px",
            margin: "0px 370px",
            textAlign: "center",
            opacity: interpolateOpacityByFrame(frame, 0, 15, 0, 1),
            clipPath: EraseFromMiddle(180 - 15, "Springy100"),
          }}
          animationData={animationPercentage}
        />
        making up{" "}
        <EMPASISVALUE
          VALUE={(
            ((SUMof.SIXES * 6 + SUMof.FOURS * 4) / SUMof.RUNS) *
            100
          ).toFixed(2)}
        />
        % of the of <EMPASISVALUE VALUE={SUMof.RUNS} /> runs scored.
      </COPY>
    </SeriesContainer>
  );
};

const BATTING_PANEL_3 = ({ DATA }) => {
  //console.log(DATA);
  const SUMof = DATA.DATAOBJ.DATERANGESTATS.SUMof;
  
  return (
    <SeriesContainer>
      <TITLE>BATTING</TITLE>
      <COPY DURATION={180}>
        <EMPASISVALUE VALUE={SUMof.Over50s} /> scores of 50 or more were
        recorded and{" "}
        <EMPASISVALUE
          VALUE={DATA.DATAOBJ.DATERANGESTATS.Dismissals["Not Out"]}
        />{" "}
        batsman brought out the Red ink.
      </COPY>
    </SeriesContainer>
  );
};
const BATTING_PANEL_3_5 = ({ DATA }) => {
  //console.log(DATA);
  const SUMof = DATA.DATAOBJ.DATERANGESTATS.SUMof;
  const frame = useCurrentFrame();
  return (
    <SeriesContainer>
      <TITLE
        style={{
          clipPath: EraseFromMiddle(180 - 15, "Springy100"),
        }}
      >
        BATTING
      </TITLE>
      <COPY DURATION={180}>
        whilst <EMPASISVALUE VALUE={SUMof.Ducks} /> batsmen did not trouble the
        scorers!
        <Lottie
          playbackRate={1.5}
          loop={true}
          style={{
            width: "1000px",
            margin: "-300px 0px 0 ",
            textAlign: "center",
            opacity: interpolateOpacityByFrame(frame, 0, 15, 0, 1),
            clipPath: EraseFromMiddle(180 - 15, "Springy100"),
          }}
          animationData={animationDuck}
        />
      </COPY>
    </SeriesContainer>
  );
};

/** Containers */

const TITLE = styled.h1`
  font-family: "Arial";

  font-weight: 900;
  font-size: 7em;
  line-height: 1em;
  text-align: center;
  letter-spacing: -3px;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  color: ${(props) => props.theme.Copy[1]};
  position: absolute;
  top: 10px;
`;

const SeriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 3000;
`;
