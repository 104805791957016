import { useSelector } from "react-redux";
// Editors
import { EditPlayerImages } from "./Editors/Edit_PlayerImages";
import { EditMOMImage } from "./Editors/Edit_MOMImage";
import {EditTOTWImages} from './Editors/Edit_TOTWImages'
import {EditMilestoneImage} from './Editors/Edit_MilestoneImage'
import {EditTop5Images} from './Editors/Edit_Top5Images'
import {EditSeasonReviewImages} from './Editors/Edit_SeasonReview'
import {EditMatchReview} from './Editors/Edit_MatchReview'
import { useEffect } from "react";

export const ImageEditor = ({ isConfirmOrder }) => {
  const RENDER = useSelector((state) => state.RENDER);
  //console.log(RENDER.ASSET.Asset.media_editors.data);
  const components = {
    PlayerImageEditor: <PlayerImageEditor />,
    MOMImageEditor:<MOMImageEditor />,
    TOTWImageEditor:<TOTWImageEditor />,
    MilestoneImageEditor:<MilestoneImageEditor />,
    Top5ImageEditor:<Top5ImageEditor />,
    SeasonReviewImageEditor:<SeasonReviewImageEditor />,
    MatchReviewImageEditor:<MatchReviewImageEditor />
  };

  if (isConfirmOrder) return false;
  return (
    <>
      {RENDER.ASSET.Asset.media_editors.data.map((editor, i) => { 
        return <div key={i}>{components[editor.attributes.Editor]}</div>;
      })}
    </>
  );
};



export const PlayerImageEditor = () => {
  const RENDER = useSelector((state) => state.RENDER);
  useEffect(()=>{},[RENDER])
  return <EditPlayerImages PlayerImages={RENDER.DATAOBJ.SHOWCASE} />;
};
 

 
export const MOMImageEditor = ()=>{
  const RENDER = useSelector((state) => state.RENDER);
  useEffect(()=>{},[RENDER])
  return <EditMOMImage MOMIMG={RENDER.DATAOBJ} />;
}

export const TOTWImageEditor = () => {
  const RENDER = useSelector((state) => state.RENDER);
  useEffect(()=>{},[RENDER])
  return <EditTOTWImages PlayerImages={RENDER.DATAOBJ.DATAOBJ} />;
}; 

export const MilestoneImageEditor = () => {
  const RENDER = useSelector((state) => state.RENDER);
  useEffect(()=>{},[RENDER])
  return <EditMilestoneImage PlayerImages={RENDER.DATAOBJ.DATAOBJ} />; 
}; 


export const Top5ImageEditor = () => {
  const RENDER = useSelector((state) => state.RENDER);
  useEffect(()=>{},[RENDER])
  return <EditTop5Images PlayerImages={RENDER.DATAOBJ.DATAOBJ.CATEGORY} />;
}; 

export const SeasonReviewImageEditor = () => {
  const RENDER = useSelector((state) => state.RENDER);
  useEffect(()=>{},[RENDER])
  return <EditSeasonReviewImages PlayerImages={RENDER.DATAOBJ.TeamStats.PLAYERS} />;
}; 

export const MatchReviewImageEditor = () => {
  const RENDER = useSelector((state) => state.RENDER);
  console.log(RENDER)
  useEffect(()=>{},[RENDER])
 
  return <EditMatchReview PlayerImages={RENDER.DATAOBJ} />;
}; 



