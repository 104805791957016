import { Img,useCurrentFrame } from 'remotion';
// Styled Comps
import { useTheme } from 'styled-components'
import styled from 'styled-components';
import { H1, H3 } from '../../../../utils/Text';

// Animations
import {SpringToFrom} from '../../../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../../../Animation/interpolate'
import {FromMiddle} from '../../../../../Animation/ClipWipe'
import { Image } from '@mantine/core';


export const CongratsMsg = ({DURATION, DATA})=>{
    const theme = useTheme()
    
const frame = useCurrentFrame();
console.log(DATA)
    return(
       
        <FrostedBlock 
        style={{
            background:'transparent',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        }}
    >
         <H1 style={{ fontSize:'5rem', color:theme.Copy[0],width:'100%', marginBottom:'0em', clipPath:FromMiddle(15)}}>{DATA.DATAOBJ.General.Team}</H1>
            
         <Image 
                        src={DATA.DATAOBJ.General.LOGO} 
                        width={500}
                        height={500}
                        radius={500}
                        style={{
                            margin: '2rem 0px ',
                            position: 'relative',
                            
                            zIndex: '100',
                            opacity:interpolateOpacityByFrame(frame,7,15, 0, 1),
                            transform: `scale( ${SpringToFrom(7,0,1,'Springy100')}) scale(${SpringToFrom((DURATION-15),1,0,'Springy100')})`,
                        }}
                    />
          
           <H1 style={{ fontSize:'5rem', color:theme.Copy[0],width:'100%', marginBottom:'0', clipPath:FromMiddle(15)}}>Season Review!</H1>
          
         
        </FrostedBlock>
        
    )
}

const FrostedBlock = styled.div`
width: 100%;
height: 100%;
background: rgba(255, 255, 255, 0.5);
display: flex;
`