import { createSlice } from '@reduxjs/toolkit'

export const AUTHUSER = createSlice({
  name: 'AUTHUSER',
  initialState: {
    isUserSet:false,
    AUTHUSER: false,
    USER_INVOICE:false,
    USER_FRANCHISES:false,
    OrderHistory:false,
    
  },
  reducers: {
    setAuthUSer:(state, action)=>{
        state.AUTHUSER = action.payload
      },
      isUserSet:(state, action)=>{
        state.isUserSet = action.payload
      },
      userSetInvoice:(state, action)=>{
        state.USER_INVOICE = action.payload
      },
      userSetOrderHistory:(state, action)=>{
        state.OrderHistory = action.payload
      },
      userSetFranchises:(state, action)=>{
        state.USER_FRANCHISES = action.payload
      }
  }
}) 

export const { 
  setAuthUSer,
  isUserSet,
  userSetInvoice,
  userSetOrderHistory,
  userSetFranchises } = AUTHUSER.actions
export default AUTHUSER.reducer 