
console.log(process.env.NODE_ENV)


export const ENV={
    API_URL: process.env.NODE_ENV !== 'production' ? 'http://localhost:1337' :'https://lms-socialmediamanager-strapi2.herokuapp.com',
    APP_URL:process.env.NODE_ENV !== 'production' ?  'http://localhost:3000':'https://lms-socialmediamanager-fe.herokuapp.com',
    S3_URL:process.env.NODE_ENV !== 'production' ? 'http://localhost:3000':'https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com',
    RENDERED_URL:process.env.NODE_ENV !== 'production' ? 'http://localhost:8000':'https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com',
    GRAPH:process.env.NODE_ENV !== 'production' ? 'http://localhost:1337/graphql':'https://lms-socialmediamanager-strapi2.herokuapp.com/graphql',
}