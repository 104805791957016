/* eslint-disable react/jsx-pascal-case */
import { useState } from "react";

import { P } from "../../../../Theme/type";
import { Box, Container, Divider, Group, ColorSwatch } from "@mantine/core";
import { VideoWizardContainer } from "../../../../Theme/Structure/BodyContainer";
import { Player } from "video-react";
import { BTN_CTA } from "../../../../Theme/Structure/Button/BTNS";
//

import { UISkeletonLines } from "../../../../Components/Global/Skeleton";
import { BLOCK_Section_Title } from "../../../../Components/interface/BLOCK_Section_Title";
import { BLOCK_ToolBar } from "../../../../Components/interface/BLOCK_Toolbar";

export const TAB_VIDEO = ({ downloadables }) => {
  console.log(downloadables[0].attributes);
  const [isDownloading, setIsDownloading] = useState(false);
  const VIDEOOBJ = downloadables[0].attributes;
  const downloadVideo = (URL, FileName) => {
    setIsDownloading(true);
    fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/mp4",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${FileName}.mp4`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIsDownloading(false);
      });
  };

  function toMB(str) {
    let bytes = parseInt(str);
    return (bytes / (1024 * 1024)).toFixed(2);
}


  const swatches = VIDEOOBJ.THEME.VideoTheme.Theme.Base.map((color) => (
    <ColorSwatch key={color} color={color} size={15} radius={"sm"} />
  ));

  return (
    <Container size="lg" my="xl">
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Video",
          ICON: "Video",
          DESCRIPTION: "",
          TOOLBAR: false,
        }}
      />
      {isDownloading ? (
        <UISkeletonLines />
      ) : (
        <BLOCK_ToolBar
          TOOLS={[
            <BTN_CTA
              CALLBACK={() => {
                downloadVideo(VIDEOOBJ.DownloadURI, VIDEOOBJ.META.VideoOutName);
              }}
              LABEL="DOWNLOAD VIDEO"
            />,
          ]}
        />
      )}

      <VideoWizardContainer>
        <Group position="center" noWrap={false}>
          <Box
            style={{
              boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.25)",
              margin: "0 0 2em 0 ",
              width: "350px",
            }}
          >
            <Player>
              <source src={VIDEOOBJ.DownloadURI} />
            </Player>
          </Box>

          <Box
            style={{
              width: "auto",
            }}
          >
            <P weight={900} size={24} transform={`uppercase`} colorID={5}>
              Franchise : {VIDEOOBJ.META.Franchise}
            </P>

            <P weight={400} size={16} transform={`uppercase`} colorID={3}>
              Asset : {VIDEOOBJ.ASSET.Asset.Name}
            </P>

            <P weight={400} size={16} transform={`uppercase`} colorID={3}>
              {" "}
              <Group>Theme : {swatches}</Group>
            </P>

            <P weight={400} size={16} transform={`uppercase`} colorID={3}>
              Layout : {VIDEOOBJ.THEME.VideoTemplate}
            </P>
            <P weight={400} size={16} transform={`uppercase`} colorID={3}>
              Size : {toMB(VIDEOOBJ.renderSize)}MB
            </P>

            <Container size="lg" p={0}>
              <Divider
                my="xl"
                size="md"
                label="Download"
                labelPosition="center"
              />
            </Container>
            {isDownloading ? (
              <UISkeletonLines />
            ) : (
              <BTN_CTA
                CALLBACK={() => {
                  downloadVideo(
                    VIDEOOBJ.DownloadURI,
                    VIDEOOBJ.META.VideoOutName
                  );
                }}
                LABEL="DOWNLOAD VIDEO"
              />
            )}
          </Box>
        </Group>
      </VideoWizardContainer>
    </Container>
  );
};
