import { orderBy } from "lodash";
// CORE Components
import { SupImages } from "./SuplimentaryImages";
//import { StatItem } from "./StatItem";
import { StatContainer } from "./StatContainer";
import { COPY, EMPASISVALUE } from "./Copy";


export const SectionSeason = ({ DATA, OBJ }) => {
  console.log(DATA)
  const IMAGES = orderBy(
    DATA.DATAOBJ.TeamStats.PLAYERS,
    ["ARR_RUNS.length"],
    ["desc"]
  ).slice(0, 5);
  return (
    <>
   
      <Sentence
        DURATION={OBJ.DURATION}
        Name={DATA.DATAOBJ.General.Team}
        Games={DATA.DATAOBJ.General.Played}
        Won={DATA.DATAOBJ.General.Won}
        Lost={DATA.DATAOBJ.General.Won / DATA.DATAOBJ.General.Played}
        Ratio={((DATA.DATAOBJ.General.Won / DATA.DATAOBJ.General.Played) * 100).toFixed(0)}
        PlayerNumbers={DATA.DATAOBJ.TeamStats.PLAYERS.length}
      />
      <StatContainer
        LABEL={`Season`}
        DURATION={OBJ.DURATION}
        TIMINGS={{
          SlideIn: 30,
          LabelPosition: 100,
          ClipIn: 45,
          SlideUp: 45,
        }}
      >
        <SupImages
          DATA={IMAGES}
          DURATION={OBJ.DURATION}
          Label="most frequent players"
        />
      </StatContainer>
    </>
  );
};

const Sentence = ({ Name, Games, Won, Ratio, PlayerNumbers, DURATION }) => {
  return (
    <>
      <COPY DURATION={DURATION}>
        <EMPASISVALUE VALUE={PlayerNumbers} /> PLAYERS TURNED OUT FOR {Name}{" "}
        OVER THEIR <EMPASISVALUE VALUE={Games} /> GAMES THIS SEASON, WINNING{" "}
        <EMPASISVALUE VALUE={Won} /> games at a Win ratio of{" "}
        <EMPASISVALUE VALUE={`${Ratio}%`} />.
      </COPY>
    </>
  );
};
