import { Container } from "@mantine/core";
import { H3,P } from "../../Theme/type";

export const AccessDenied = () => {
    return (
        
      <Container
        size="xl"
        m="0"
        p="0"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
        }}
      >
        <H3>Access to this Feature is currently unavailable.</H3>
        <P>Contact us via the facebook page to gain access to this feature.</P>
      </Container>
    );
  };