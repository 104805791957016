import {
  useCurrentFrame,
  useVideoConfig,
  Audio,
  AbsoluteFill,
  Video,
} from "remotion";
import { LMSLOGO } from "../../../Assets/SVG/svg";
import { LogoContainer, EndTitleContainer } from "../../utils/Containers";
import {
  LASTMANSTANDS,
  StartLocalGoGlobal,
  StartLocalGoGlobalSpan,
} from "../../utils/Text";
import { interpolateOpacity } from "../../../Animation/interpolate";
import { SpringIn } from "../../../Animation/RemotionSpring";
//import {ClosingSponsorSequence} from "./OpeningSponsorSequence";

const VIDEO = {
  mixBlendMode: "luminosity",
  opacity: 0.1,
};

export const EndCredits = (props) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const opacity = interpolateOpacity(frame);
  return (
    <>
      <LogoContainer>
        <div
          style={{
            width: "500px",
            height: "500px",
            opacity,
            transform: `scale(${SpringIn(fps, frame - 0, "Stiff")})`,
            filter: "drop-shadow(-1px 17px 16px rgba(0, 0, 0, 0.25))",
          }}
        >
          <LMSLOGO />
        </div>
      </LogoContainer>
      <EndTitleContainer>
        <LASTMANSTANDS
          style={{
            opacity,
            transform: `scale(${SpringIn(fps, frame - 7, "Springy100")})`,
          }}
          {...props}
        >
          LAST MAN STANDS
        </LASTMANSTANDS>

        <StartLocalGoGlobal
          style={{
            opacity,
            transform: `scale(${SpringIn(fps, frame - 15, "Springy100")})`,
          }}
          {...props}
        >
          <span>Start locaL</span>{" "}
          <StartLocalGoGlobalSpan>Go Global</StartLocalGoGlobalSpan>
        </StartLocalGoGlobal>
      </EndTitleContainer>

      <AbsoluteFill>
        <Video
          startFrom={30}
          src={
            "https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/BGVideo002.mp4"
          }
          style={VIDEO}
          playbackRate={0.9}
        />
      </AbsoluteFill>
      <Audio
        src={`https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/OutroSoundFile.mp4`}
        playbackRate={1}
        startFrom={0} // if composition is 30fps, then it will start at 2s
      />
    </>
  );
};

