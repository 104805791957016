/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// 3rd party
import {
  Text,
  Input,
  MultiSelect,
  Space,
  Loader,
  Center,
  Stack,
  Container,
  Accordion,
} from "@mantine/core";
import { IconTag } from "@tabler/icons";
// Hooks
import {
  useGetRegionalSeasons,
  useCreateDivisionGrouping,
  usePutDivisionGroupingStatus,
} from "../../Hooks/Content/useRegionalSeasons";

import { orderBy } from "lodash";

// Compoennts
import { VideoWizardContainer } from "../../Theme/Structure/BodyContainer";

import {
  BTN_CREATE,
  BTN_ICON_CALLBACK,
} from "../../Theme/Structure/Button/BTNS";
import { H1, H3 } from "../../Theme/type";

// UI ELEMENTS
import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";
import { BLOCK_Section_SubHeading } from "../../Components/interface/BLOCK_Section_SubHeading";
import { BLOCK_ToolBar } from "../../Components/interface/BLOCK_Toolbar";
import { CALLBACKIcon } from "../../Theme/Structure/Button/ActionIcons";

export const FranchiseGroupRegions = () => {
  const [Create, setCreate] = useState(false);

  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Group your regions",
          ICON: "FileSearch",
          DESCRIPTION: `A Region Dataset is a way to group specific divisions or regions within your franchise together for a video. 
                    This could be by Region, Location or Grade. You can make as many datasets as you please. It is important to assign each dataset a descriptive name as to make it easily recognisable when selecting a dataset for a video.`,
          TOOLBAR: false,
        }}
      />

      <BLOCK_ToolBar
        TOOLS={[
          <CALLBACKIcon
            TIPOBJ={{ LABEL: "Add new Dataset", COLOR: "green" }}
            ICON={`CirclePlus`}
            CALLBACK={() => {
              setCreate(!Create);
            }}
          />,
        ]}
      />

      <Space h="md" />

      {Create ? <CreateNewRegion setCreate={setCreate} /> : <ShowRegionTable />}
    </>
  );
};

const CreateNewRegion = ({ setCreate }) => {
  const [DivisionGroup, CreateDivisionGroup] = useCreateDivisionGrouping();
  const AUTH = useSelector((state) => state.AUTH);
  const UXSTATE = useSelector((state) => state.UXSTATE);
  const [Name, setName] = useState("");
  const [Multi, setMulti] = useState([]);
  const [disabled, setdisabled] = useState(true);
  const [DataSent, setDatasent] = useState(false);

  const handleSubmit = () => {
    setDatasent(true);
    CreateDivisionGroup({
      Name: Name,
      watch_lists: Multi,
      users_permissions_user: AUTH.AUTHUSER.id,
      franchise: UXSTATE.SelectedFranchise.id,
      isComplete: false,
    });
  };
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleMulti = (e) => {
    setMulti(e);
  };
  const CreateMultiRegion = () => {
    let ARR = [];

    orderBy(
      UXSTATE.SelectedFranchise.attributes.watch_lists.data,
      ["attributes.Name"],
      ["asc"]
    ).map((League, i) => {
      if (!League.attributes.hasCompleted)
        ARR.push({ value: League.id, label: League.attributes.Name });
    });
    return ARR;
  };

  useEffect(() => {
    if (Name.length != 0 && Multi.length != 0) {
      setdisabled(false);
    }
  }, [Multi, Name]);

  useEffect(() => {
    if (DivisionGroup) {
      setDatasent(false);
      setCreate(false);
    }
  }, [DivisionGroup]);

  if (DataSent)
    return (
      <Container>
        <Center>
          <Stack>
            <Loader variant="bars" color="cyan" />

            <H1>Creating Group</H1>
          </Stack>
        </Center>
      </Container>
    );
  return (
    <>
      <BLOCK_Section_SubHeading LABEL="Create a New Dataset." Color={7} />
      <Space h="lg" />
      <Container size="lg"  p={0}>
        <Input.Wrapper
          id="Region-Name"
          required
          label="Region Divisions"
          description="Should your Franchise be split over different regions, you may wish to split them for the assets. Here you cvan create a region for your assets. Please be to the point and consise with your naming, as this label may feature in video descriptions and titles"
          error="A Region Name is Required"
        >
          <Input
            icon={<IconTag />}
            placeholder="Region Name"
            id="Region-Name"
            onChange={handleName}
          />
        </Input.Wrapper>

        <MultiSelect
          data={CreateMultiRegion()}
          label="Select the Divisions for this Grouping"
          placeholder="Select Divisions"
          onChange={handleMulti}
        />
        <Space h="md" />
        <BTN_CREATE
          CALLBACK={handleSubmit}
          LABEL={`Create`}
          DISABLED={disabled}
          BG="7"
          COPY="0"
          TIPOBJ={{
            LABEL: "Submit Dataset",
            COLOR: "green",
            POSITION: "right",
          }}
        />
      </Container>
    </>
  );
};

const ShowRegionTable = () => {
  const [getRegionalSeasons, FetchRegionalSeasons] = useGetRegionalSeasons();
  const [DivisionGroupingStatus, PutDivisionGroupingStatus] =
    usePutDivisionGroupingStatus();
  const [RegionalRows, setRegionalRows] = useState(false);
  useEffect(() => {
    FetchRegionalSeasons();
  }, []);

  const handleStatusChange = (NewStatus, id) => {
    //isComplete
    PutDivisionGroupingStatus({ isComplete: NewStatus }, id);
  };
  useEffect(() => {
    FetchRegionalSeasons();
  }, [DivisionGroupingStatus]);

  const CreateAccordionItem = (IsActive, IsNull) => {
    let rows;
    if (getRegionalSeasons != null) {
      const elements = getRegionalSeasons;
      //console.log(elements)
      rows = elements.map((element) => {
        if (
          element.attributes.isComplete === IsActive ||
          element.attributes.isComplete === IsNull
        )
          return (
            <Accordion.Item
              value={element.attributes.Name}
              key={element.attributes.Name}
            >
              <Accordion.Control>
                <H3 colorID={3}>
                  {element.attributes.Name} (
                  {element.attributes.watch_lists.data.length} items)
                </H3>
              </Accordion.Control>
              <Accordion.Panel>
                <H3 colorID={5}>Divisions/Regions in this Dataset</H3>
                <Space h="sm" />
                {element.attributes.watch_lists.data.map((item) => {
                  return (
                    <Text size="md" key={item.attributes.Name}>
                      {item.attributes.Name}
                    </Text>
                  );
                })}
                <Space h="sm" />
                <BLOCK_ToolBar
                  TOOLS={[
                    <BTN_ICON_CALLBACK
                      CALLBACK={() => {
                        handleStatusChange(
                          !element.attributes.isComplete,
                          element.id
                        );
                      }}
                      ICON={IsActive ? "CircleCheck" : "CircleX"}
                      TIPOBJ={{
                        LABEL: IsActive
                          ? `Reactivate ${element.attributes.Name}`
                          : `Archive ${element.attributes.Name}`,
                        COLOR: IsActive ? "green" : "red",
                        POSITION: "top",
                      }}
                    />,
                  ]}
                />
              </Accordion.Panel>
            </Accordion.Item>
          );
      });
    }
    return rows;
  };

  useEffect(() => {}, [getRegionalSeasons]);

  if (getRegionalSeasons === null) return <>Loading</>;
  return (
    <>
      <BLOCK_Section_SubHeading LABEL="Active Datasets" Color={7} />

      <Container size="lg"  p={0}>
        <VideoWizardContainer>
          <Accordion defaultValue="customization">
            {CreateAccordionItem(false, null)}
          </Accordion>
        </VideoWizardContainer>
      </Container>

      <Space h={20} />

      <BLOCK_Section_SubHeading LABEL="Inactive Datasets" Color={6} />
      <Container size="lg"  p={0}>
        <VideoWizardContainer>
          <Accordion defaultValue="customization">
            {CreateAccordionItem(true)}
          </Accordion>
        </VideoWizardContainer>
      </Container>
      <Space h={20} />
    </>
  );
};
