import {useCurrentFrame,Img } from 'remotion';
import styled from 'styled-components';
import {SpringToFrom} from '../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../Animation/interpolate';
import { useState } from 'react';


 const CenterTeamLogo = styled.div``
export const TeamLogoLarge = ({LOGO, Durations})=>{
    const frame = useCurrentFrame();

    const [size,setSize] = useState({ width:'100%'})

    const onImgLoad = ({ target: img }) => {
        const { offsetHeight, offsetWidth } = img;
      //console.log(offsetHeight, offsetWidth);
        if(offsetHeight === offsetWidth){
            setSize({ width:'40%'})
        }
        if(offsetHeight > offsetWidth){
            setSize({ width:'25%'})
        }
        if(offsetHeight < offsetWidth){
            setSize({ width:'30%'})
        }
      };

    return( 
        <CenterTeamLogo
                style={{
                    position:'absolute',
                    width:'100%',
                    top: '200px',
                    zIndex:500,
                    textAlign:'center', 
                    borderRadius:'1000px',
                    overflow:'hidden',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    display: 'flex',
                    filter: 'drop-shadow(0px 20px 10px rgba(0, 0, 0, 0.50))',
                    opacity:interpolateOpacityByFrame(frame, Durations.TransitionAfter,(Durations.TransitionAfter+15), 1,0),
                    transform: `scale(${SpringToFrom(Durations.Begin,0,1,'Springy100')}) scale(${SpringToFrom(Durations.TransitionAfter,1,0)})`,
                }}>
                    <div
                        style={{
                            width:'400px',
                            height:'400px',
                            overflow:'hidden',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                            display: 'flex',
                            backgroundColor:'white',
                            borderRadius:'1000px',
                        }}
                    >
                    <Img  
                    src={LOGO}
                    style={{
                        height:'100%',
                        zIndex:500,
                    }}
                />
                    </div>
              

               
                </CenterTeamLogo>
    )
}