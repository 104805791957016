import { Series, Audio } from "remotion";
import { useCurrentFrame } from 'remotion';
import { useTheme } from "styled-components";
// Components
import { Section_TeamlogoandSeason } from "./Components/TeamlogoandSeason";
import { FullWidthWrapper } from '../../../utils/Containers';
import { Section_WinLoseRatio } from "./Components/WinLoseRatio";

import { CongratsMsg } from "./Components/CongratulationsMsg";

// Components
import { SectionSeason } from "./Components/StatSections/Season";
import { SectionBatting } from "./Components/StatSections/Batting";
import { SectionBattingAverages } from "./Components/StatSections/Batting_Averages";
import { SectionBattingTeamScores } from "./Components/StatSections/Batting_teamscores";

import { SectionBoundaries } from "./Components/StatSections/Boundaries";
import { SectionBowling } from "./Components/StatSections/Bowling";
import { SectionFielding } from "./Components/StatSections/Fielding";

import { Box } from "@mantine/core";
import { interpolateOpacityByFrame } from '../../../../Animation/interpolate';
export const TEAMSEASONALREVIEW_BODY = ({ DATA, AUDIO }) => {
  //const MostRuns = orderBy(DATA.DATAOBJ.TeamStats.PLAYERS, ["BATTING_Runs"], ["desc"]);

//console.log(DATA.DATAOBJ.VideoSettings.VideoBodyTiming)
  const OBJ={
    DURATION:300
  }


  return ( 
    <>
      <FullWidthWrapper>
        <Box 
            style={{  
              height:'250px'
          }} 
       >
          <Section_TeamlogoandSeason DATA={DATA} DURATION={(DATA.DATAOBJ.VideoSettings.VideoBodyTiming-480)} />
        </Box>
           
      <Box style={{ height:'1100px'}}>
        <Series>

            <Series.Sequence durationInFrames={OBJ.DURATION} layout="none">
              <SectionSeason DATA={DATA} OBJ={OBJ}/>
              <Timer DURATION={OBJ.DURATION}/> 
            </Series.Sequence>
              <Series.Sequence durationInFrames={OBJ.DURATION} layout="none">
              <SectionBatting DATA={DATA} OBJ={OBJ}/>
              <Timer DURATION={OBJ.DURATION}/>
            </Series.Sequence>
             <Series.Sequence durationInFrames={OBJ.DURATION} layout="none">
              <SectionBattingAverages DATA={DATA} OBJ={OBJ}/>
              <Timer DURATION={OBJ.DURATION}/>
            </Series.Sequence>
            <Series.Sequence durationInFrames={OBJ.DURATION} layout="none">
              <SectionBattingTeamScores DATA={DATA} OBJ={OBJ}/>
              <Timer DURATION={OBJ.DURATION}/>
            </Series.Sequence>
          


            <Series.Sequence durationInFrames={OBJ.DURATION} layout="none">
              <SectionBoundaries DATA={DATA}  OBJ={OBJ}/>
              <Timer DURATION={OBJ.DURATION}/>
            </Series.Sequence>

            <Series.Sequence durationInFrames={OBJ.DURATION} layout="none">
              <SectionBowling DATA={DATA} OBJ={OBJ}/>
              <Timer DURATION={OBJ.DURATION}/>
            </Series.Sequence>

            <Series.Sequence durationInFrames={OBJ.DURATION} layout="none">
              <SectionFielding DATA={DATA} OBJ={OBJ} />
              <Timer DURATION={OBJ.DURATION}/>
            </Series.Sequence>
            <Series.Sequence durationInFrames={OBJ.DURATION} layout="none">
              <Section_WinLoseRatio DATA={DATA} OBJ={OBJ}/>
              <Timer DURATION={OBJ.DURATION}/>
            </Series.Sequence>

            <Series.Sequence durationInFrames={180} layout="none">
              <CongratsMsg DATA={DATA} DURATION={180} />
            </Series.Sequence> 
        </Series>
        </Box>
      </FullWidthWrapper>

      <Audio
        src={`https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/DivisionWinnerMainVersion1.mp3`}
        playbackRate={1}
        startFrom={0} // if composition is 30fps, then it will start at 2s
        volume={1}
      />
    </>
  );
};



  const Timer=({DURATION})=>{
    const frame = useCurrentFrame();
    const theme = useTheme();
    return(
      <Box
        style={{
          position:'absolute',
          height:'10px',
        
          width:`${interpolateOpacityByFrame(frame,0,DURATION, 0, 100)}%`,
          backgroundColor:theme.Base[1],
          zIndex:1000,
          bottom:0,
        }}>
      </Box>
    )
  }
