import styled from "styled-components";
import { useTheme } from "styled-components";
import { H1, H2, H3, P } from "../../../../utils/Text";
import { Image } from "@mantine/core";
//import { interpolateOpacityByFrame } from "../../../../../Animation/interpolate";
import { SpringToFrom } from "../../../../../Animation/RemotionSpring";
import {
  FromMiddle,
  FromBottomToTop,
  EraseFromMiddle,
} from "../../../../../Animation/ClipWipe";
import {
  FullWidthWrapperStart,
  FrostedPOTD,
  POTDWRAPPER,
  POTDImageBox,
} from "../../../../utils/Containers";
export const Overview = ({ DATA }) => {
  return (
    <>
      <SectionOne DATA={DATA} />
      <SectionTwo DATA={DATA} />
    </>
  );
};
//<H1>Player of the Match!</H1>
/** Containers */
const LogoContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-top: 200px;
`;

const SectionOne = ({ DATA }) => {
  const IMGSIZE = 500;
  return (
    <FullWidthWrapperStart
      style={{
        position: "absolute",
        transform: `translateY(${SpringToFrom(10, -1500, 100)}px) 
              translateY(${SpringToFrom(
                DATA.VideoSettings.SectionDuration[2] / 2,
                100,
                -1500
              )}px)`,
      }}
    >
      <LogoContainer style={{ marginBottom: "60px" }}>
        <Image
          radius={IMGSIZE}
          width={IMGSIZE}
          height={IMGSIZE}
          src={DATA.DATAOBJ.TeamA.LOGO}
          style={{
            transform: `translateX(${SpringToFrom(15, -1000, 25)}px)`,
          }}
        />

        <Image
          src={DATA.DATAOBJ.TeamB.LOGO}
          radius={IMGSIZE}
          width={IMGSIZE}
          height={IMGSIZE}
          style={{
            transform: `translateX(${SpringToFrom(15, 1000, -25)}px)`,
          }}
        />
      </LogoContainer>

      <TeamName Name={DATA.DATAOBJ.TeamA.Name} />
      <P style={{ margin: 0, clipPath: FromBottomToTop(30, "Springy100") }}>
        VS
      </P>
      <TeamName Name={DATA.DATAOBJ.TeamB.Name} />

      <H2
        style={{
          margin: "20px",
          fontSize: "3em",
          letterSpacing: "-4px",
          lineHeight: "1.3em",
          clipPath: FromBottomToTop(30, "Springy100"),
        }}
      >
        {DATA.DATAOBJ.ResultStatement}
      </H2>
      <H3 style={{ clipPath: FromBottomToTop(30, "Springy100") }}>
        {DATA.DATAOBJ.ground}
      </H3>
    </FullWidthWrapperStart>
  );
};

const TeamName = ({ Name }) => {
  const theme = useTheme();
  return (
    <H1
      style={{
        fontSize: "4em",
        fontWeight: 900,
        lineHeight: "1.1em",
        clipPath: FromBottomToTop(20, "Springy100"),
        color: theme.Copy[2],
      }}
    >
      {Name}
    </H1>
  );
};

const SectionTwo = ({ DATA }) => {
  const theme = useTheme();
  return (
    <FullWidthWrapperStart
      style={{
        position: "absolute",
        transform: `translateY(${SpringToFrom(
          DATA.VideoSettings.SectionDuration[2] / 2,
          2000,
          0
        )}px) 
          `,
      }}
    >
      <POTDWRAPPER
        style={{
          clipPath: EraseFromMiddle(
            DATA.VideoSettings.SectionDuration[2] - 25,
            "Springy100"
          ),
          transform: `
            translateY(${SpringToFrom(
              0,
              1400,
              130,
              "Springy100"
            )}px)             
            `,
        }}
      >
        <POTDImageBox
          style={{
            backgroundImage: `url(${DATA.DATAOBJ.MoM.TEAMLOGO})`,
            position: "absolute",
            bottom: "240px",
            left: "380px",
            zIndex: "1000",
            clipPath: FromBottomToTop(5, "Springy100"),
          }}
        />
        <FrostedPOTD
          style={{
            clipPath: FromBottomToTop(
              DATA.VideoSettings.SectionDuration[2] / 2 + 15,
              "Springy100"
            ),
          }}
        >
          <H3>Player of the Match</H3>
          <H1
            style={{
              fontSize: `clamp(2em,4em,5em)`,
              lineHeight: "1.2em",
              color: theme.Copy[2],
              clipPath: FromMiddle(
                DATA.VideoSettings.SectionDuration[2] / 2 + 25,
                "Springy100"
              ),
            }}
          >
            {DATA.DATAOBJ.MoM.Name}
          </H1>
          <H1
            style={{
              fontSize: "2.5em",
              lineHeight: "1em",
              clipPath: FromMiddle(
                DATA.VideoSettings.SectionDuration[2] / 2 + 25,
                "Springy100"
              ),
            }}
          >
            {DATA.DATAOBJ.MoM.TEAM}
          </H1>
        </FrostedPOTD>
      </POTDWRAPPER>
    </FullWidthWrapperStart>
  );
};
