import { ThemeProvider } from "styled-components";

// Import Design Templates for MATCHDAYRESULT.
// Add new deisng patterns below
// GLASS
import { TOP5_4by5 } from "../../theme/Templates/GLASS/index";
import { ARROWS_TOP5_4by5 } from "../../theme/Templates/ARROWS/index";
import { TOURNAMENT_TOP5_4by5 } from "../../theme/Templates/TOURNAMENT_GLASS/index";
// END

export const Build_TOPFIVERUNS_4by5 = (props) => {
  const { RENDER } = props;
  const TEMPLATES = {
    Glass: <TOP5_4by5 RENDER={RENDER} MainTitle="Runs" Subtitle="TOP 5" />,
    Arrows: <ARROWS_TOP5_4by5 RENDER={RENDER} VARIABLE={"Runs"} />,
    TOURNAMENT_GLASS: (
      <TOURNAMENT_TOP5_4by5
        RENDER={RENDER}
        MainTitle="Top 5 Runs"
        VARIABLE="Runs"
      />
    ),
  };
  return (
    <ThemeProvider theme={RENDER.THEME.VideoTheme.Theme}>
      {TEMPLATES[RENDER.THEME.VideoTemplate]}
    </ThemeProvider>
  );
};