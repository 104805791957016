import { Series,useCurrentFrame } from 'remotion';
import styled from 'styled-components';
import { useTheme } from 'styled-components';
import {SpringToFrom} from '../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../Animation/interpolate'
import { useState } from 'react';

export const AnimatedStrokeAroundImage = ({Durations, STYLES,COLOR,DIVIDE})=>{
    return(
        <OuterCircle style={STYLES} >
            <SVGStroke Durations={Durations} COLOR={COLOR} DIVIDE={DIVIDE}/>
        </OuterCircle>
    )
}
const OuterCircle = styled.div`
z-index: 1000;
position: absolute;
display: flex;
align-items: center;
justify-content: center;
z-index:1000`

const SVGStroke = ({Durations, COLOR,DIVIDE})=>{
    const theme = useTheme();
    const frame = useCurrentFrame();
    const [length, setLength] = useState(null);
   //console.log(length)
  
    return(
        <svg viewBox="0 0 839 839" fill="none">
            <circle 
                ref={(ref) => {
                    // The ref is `null` on component unmount
                    if (ref) {
                    setLength(ref.getTotalLength());
                    }
                }}
                cx="420" 
                cy="420" 
                r="415" 
                strokeDashoffset={SpringToFrom((Durations.Begin),length,0,'Springy100')} 
                strokeDasharray={length/DIVIDE} 
                stroke={theme.Base[COLOR]} 
                strokeWidth={SpringToFrom((Durations.Begin),50,10,'Springy100')}
                style={{ opacity:interpolateOpacityByFrame(frame, Durations.TransitionAfter,(Durations.TransitionAfter+5), 1,0) }}
                />
            </svg>
    )
}