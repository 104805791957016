/* eslint-disable react/jsx-pascal-case */

import { Space, Group, Container, Image, Center } from "@mantine/core";

//THEME
import {  H3, P } from "../../Theme/type";

import { BorderedContainer } from "../../Theme/Structure/BodyContainer";

// UTILS

import { BTN_CREATE, BTN_ICON_LINK } from "../../Theme/Structure/Button/BTNS";
// UI ELEMENTS

import {
  TABLE_WRAPPER,
  TABLE_THEAD_WRAPPER,
  TABLE_TD,
} from "../../Components/interface/Wrapper/Table";
import { GPT3Prompt } from "./GameData/GPT3Prompt";
import { BLOCK_ToolBar } from "../../Components/interface/BLOCK_Toolbar";

export const SelectedGame = ({ isSelected, GameData }) => {
  console.log(GameData);

  const FindTeamLogo = (TEAMS, TEAM) => {
    return TEAMS[0].attributes.Name === TEAM
      ? GameData.teams.data[0].attributes?.Logo.data?.attributes.url
      : GameData.teams.data[1].attributes?.Logo.data?.attributes.url;
  };

  return (
    <>
      <BorderedContainer>
        <Group position="apart" pacing="lx" grow>
          <H3 align="right">
            {GameData.HomeTeam} <br /> {GameData.HomeTeamResult}
          </H3>{" "}
          <Image
            width={80}
            height={80}
            src={`${FindTeamLogo(GameData.teams.data, GameData.HomeTeam)}`}
          />
          <P>vs</P>
          <Image
            width={80}
            height={80}
            src={`${FindTeamLogo(GameData.teams.data, GameData.AwayTeam)}`}
          />{" "}
          <H3>
            {GameData.AwayTeam} <br /> {GameData.AwayTeamResult}{" "}
          </H3>
        </Group>
      </BorderedContainer>

      <BLOCK_ToolBar
        TOOLS={[
          <BTN_CREATE
            LABEL="back"
            CALLBACK={() => {
              isSelected(false);
            }}
          />,
        ]}
      />
      <GPT3Prompt GAME={GameData} />

      <Group position="center" spacing="xl">
        <H3>{GameData.ResultStatement}</H3>
      </Group>

      <BorderedContainer BGColor={0}>
        <Center>
          <H3 weight={900} underline={true} colorID={5}>
            {GameData.HomeTeam} Batting Innings
          </H3>{" "}
        </Center>

        <Container>
          <TABLE_WRAPPER>
            <TABLE_THEAD_WRAPPER
              HEADERS={["Name", "Runs", "Balls", "Howout", " "]}
            />

            <tbody>
              {GameData.player_battings.data.map((Batting, i) => {
                if (
                  Batting.attributes.team.data.attributes.Name ===
                  GameData.HomeTeam
                )
                  return (
                    <tr key={i}>
                      <TABLE_TD>
                        {Batting.attributes.player.data.attributes.Name}
                      </TABLE_TD>
                      <TABLE_TD>{Batting.attributes.BATTING_Runs}</TABLE_TD>
                      <TABLE_TD>{Batting.attributes.BATTING_Balls}</TABLE_TD>
                      <TABLE_TD>{Batting.attributes.BATTING_HowOut}</TABLE_TD>
                      <TABLE_TD>
                        <BTN_ICON_LINK
                          ICON="ExternalLink"
                          TO={`https://www.lastmanstands.com/cricket-player/t20?playerid=${Batting.attributes.player.data.attributes.PlayerID}`}
                          TIPOBJ={{
                            LABEL: "Players Profile",
                            COLOR: "gray",
                            POSITION: "top",
                          }}
                        />
                      </TABLE_TD>
                    </tr>
                  );
              })}
            </tbody>
          </TABLE_WRAPPER>
        </Container>
        <Space h="xl" />
        <Center>
          <H3 weight={100} underline={false} colorID={4}>
            {GameData.AwayTeam} Bowling Innings
          </H3>{" "}
        </Center>

        <Container>
          <TABLE_WRAPPER>
            <TABLE_THEAD_WRAPPER
              HEADERS={["Name", "Overs", "Wickets", "Runs", " "]}
            />

            <tbody>
              {GameData.player_bowlings.data.map((bowling, i) => {
                if (bowling.attributes.team.data === null) return false;
                if (
                  bowling.attributes.team.data.attributes.Name ===
                  GameData.AwayTeam
                )
                  return (
                    <tr key={i}>
                      <TABLE_TD>
                        {bowling.attributes.player.data.attributes.Name}
                      </TABLE_TD>
                      <TABLE_TD>{bowling.attributes.BOWLING_Overs}</TABLE_TD>
                      <TABLE_TD>{bowling.attributes.BOWLING_Wkts}</TABLE_TD>
                      <TABLE_TD>{bowling.attributes.BOWLING_Runs}</TABLE_TD>
                      <TABLE_TD>
                        <BTN_ICON_LINK
                          ICON="ExternalLink"
                          TIPOBJ={{
                            LABEL: "Players Profile",
                            COLOR: "gray",
                            POSITION: "top",
                          }}
                          TO={`https://www.lastmanstands.com/cricket-player/t20?playerid=${bowling.attributes.player.data.attributes.PlayerID}`}
                        />
                      </TABLE_TD>
                    </tr>
                  );
              })}
            </tbody>
          </TABLE_WRAPPER>
        </Container>
      </BorderedContainer>

      <BorderedContainer BGColor={0}>
        <Center>
          <H3 weight={900} underline={true} colorID={5}>
            {GameData.AwayTeam} Batting Innings
          </H3>
        </Center>
        <Container>
          <TABLE_WRAPPER>
            <TABLE_THEAD_WRAPPER
              HEADERS={["Name", "Runs", "Balls", "Howout", " "]}
            />

            <tbody>
              {GameData.player_battings.data.map((Batting, i) => {
                if (
                  Batting.attributes.team.data.attributes.Name ===
                  GameData.AwayTeam
                )
                  return (
                    <tr key={i}>
                      <TABLE_TD>
                        {Batting.attributes.player.data.attributes.Name}
                      </TABLE_TD>
                      <TABLE_TD>{Batting.attributes.BATTING_Runs}</TABLE_TD>
                      <TABLE_TD>{Batting.attributes.BATTING_Balls}</TABLE_TD>
                      <TABLE_TD>{Batting.attributes.BATTING_HowOut}</TABLE_TD>
                      <TABLE_TD>
                        <BTN_ICON_LINK
                          ICON="ExternalLink"
                          TIPOBJ={{
                            LABEL: "Players Profile",
                            COLOR: "gray",
                            POSITION: "top",
                          }}
                          TO={`https://www.lastmanstands.com/cricket-player/t20?playerid=${Batting.attributes.player.data.attributes.PlayerID}`}
                        />
                      </TABLE_TD>
                    </tr>
                  );
              })}
            </tbody>
          </TABLE_WRAPPER>
        </Container>
        <Space h="xl" />
        <Center>
          <H3 weight={100} underline={false} colorID={4}>
            {GameData.HomeTeam} Bowling Innings
          </H3>{" "}
        </Center>
        <Container>
          <TABLE_WRAPPER>
            <TABLE_THEAD_WRAPPER
              HEADERS={["Name", "Overs", "Wickets", "Runs", " "]}
            />

            <tbody>
              {GameData.player_bowlings.data.map((bowling, i) => {
                if (bowling.attributes.team.data === null) return false;
                if (
                  bowling.attributes.team.data.attributes.Name ===
                  GameData.HomeTeam
                )
                  return (
                    <tr key={i}>
                      <TABLE_TD>
                        {bowling.attributes.player.data.attributes.Name}
                      </TABLE_TD>
                      <TABLE_TD>{bowling.attributes.BOWLING_Overs}</TABLE_TD>
                      <TABLE_TD>{bowling.attributes.BOWLING_Wkts}</TABLE_TD>
                      <TABLE_TD>{bowling.attributes.BOWLING_Runs}</TABLE_TD>
                      <TABLE_TD>
                        <BTN_ICON_LINK
                          ICON="ExternalLink"
                          TIPOBJ={{
                            LABEL: "Players Profile",
                            COLOR: "gray",
                            POSITION: "top",
                          }}
                          TO={`https://www.lastmanstands.com/cricket-player/t20?playerid=${bowling.attributes.player.data.attributes.PlayerID}`}
                        />
                      </TABLE_TD>
                    </tr>
                  );
              })}
            </tbody>
          </TABLE_WRAPPER>
        </Container>
      </BorderedContainer>
    </>
  );
};
