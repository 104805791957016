import { H2, H3, Header, P } from "../../../Theme/type";
import {
  SectionContainer,
  RaisedContainer,
  BodyContainer,
  ColoredContainer,
} from "../../../Theme/Structure/BodyContainer";
import { Center, Loader, Stack } from "@mantine/core";
import { Box } from "@mantine/core";
export const NoFixturesFound = () => {
  return (
    <RaisedContainer>
      <H2>No Data Found for these dates</H2>
    </RaisedContainer>
  );
};

export const DATAERROR = ({ ERROR }) => {
  //console.log(ERROR.message)
  return (
    <RaisedContainer>
      <H2>DATA ERROR</H2>
      <P align="center">
        The following Error(s) Occurred when fetching the data:{" "}
      </P>
      <P align="center">{ERROR.message}</P>
    </RaisedContainer>
  );
};

//colorID=4,size="lg", align='left',weight=400, underline=false,transform="capitalize"
export const FetchingData = () => {
  return (
    <ColoredContainer>
      <Stack>
        <Center>
          <Loader variant="bars" color="teal" />
        </Center>
        <Center>
          <H3 colorID={4} size="xl" weight={900} transform="uppercase">
            Working...
          </H3>
        </Center>
      </Stack>
    </ColoredContainer>
  );
};

export const EvaluatingData = () => {
  return (
    <ColoredContainer>
      <Center>
        <Stack>
          <Center>
            <Loader variant="bars" color="blue" />
          </Center>
          <Center>
            <H2>Evaluating Data</H2>{" "}
          </Center>
        </Stack>
      </Center>
    </ColoredContainer>
  );
};

export const VideoPreviewContainer = (props) => {
  return (
    <Box style={{ boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.25)", margin: "0" }}>
      {props.children}
    </Box>
  );
};
