import { gql } from "@apollo/client";

export const QUERY = (APISETTINGS) => {
  return gql`query LeagueStandings { watchLists(
    pagination: { start: 0, limit: -1 }
    filters:{id:{in:  [${APISETTINGS.IDS}]}}
      ){
        data{ 
        id
          attributes{
            Name
            LeagueTable
            teams {
              data {
                id
                attributes {
                  Name
                  Form
                  World_Rank
                  Logo {
                    data {
                      id
                      attributes {
                        formats
                        url 
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }
    }`;
};
