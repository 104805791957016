import { Series } from "remotion";
import { H1 } from "../../../../utils/Text";
import { useTheme } from "styled-components";
import { FixturePageWrapper } from "./FixturePage";
import { getContrastColor,getDominantColor } from "../../../../../../../actions/colors";

export const FIXTURELIST = ({ DATA, Dimensions }) => {
  const theme = useTheme();
  
  
  const Durations = {
    Length: 270,
    Begin: 0, // bring in overlay
    TransitionAfter: 30, // slide overlay down
    RemoveScorcard: 180, // slide overlay down
    ItemsperPage:4
  };

  if (Dimensions.length === 0) return false;
  return (
    <Series>
      {Object.keys(DATA.Fixtures).map((Label, i) => {
         console.log(DATA.Fixtures[Label][0].region)
        return (
          <Series.Sequence
            key={i}
            durationInFrames={
              Math.ceil(DATA.Fixtures[Label].length / 4) * Durations.Length
            }
            layout="none"
          > 
            <H1
              style={{
                position: "fixed",
                top: "10px",
                fontSize: "35px",
                color: getContrastColor(getDominantColor(theme.radialGradient)),
              }}
            >
              {DATA.Fixtures[Label][0].region}
            </H1> 
            <FixturePageWrapper DATA={DATA} LABEL={Label} Durations={Durations} />
          </Series.Sequence>
        );
      })}
    </Series>
  );
};
