import styled from "styled-components";
import { useTheme } from "styled-components";
import { H1, H3 } from "../../../../utils/Text";
import { Image } from "@mantine/core";
import { interpolateOpacityByFrame } from "../../../../../Animation/interpolate";
import { SpringToFrom } from "../../../../../Animation/RemotionSpring";
import {
  EraseFromMiddle,
  FromBottomToTop,
  FromLeftToRight,
} from "../../../../../Animation/ClipWipe";
import {
  FullWidthWrapperStart,
  FrostedPOTD,
  POTDWRAPPER,
} from "../../../../utils/Containers";

import { StatsContainer } from "../Components/StatsContainer";

export const Fielding = ({ DATA }) => {
  return (
    <>
      <FullWidthWrapperStart
        style={{
          position: "absolute",
        }}
      >
        <POTDWRAPPER
          style={{
            clipPath: EraseFromMiddle(
              DATA.VideoSettings.SectionDuration[5] - 25,
              "Springy100"
            ),
            transform: `
            translateY(${SpringToFrom(
              0,
              1400,
              130,
              "Springy100"
            )}px)             
            `,
          }}
        >
          <FrostedPOTD
            style={{
              clipPath: FromBottomToTop(0, "Springy100"),
            }}
          >
            <StatsContainer
              Label="Keeping"
              DIS={<Main DATA={DATA} />}
              SUP={<SUP DATA={DATA} />}
            />
          </FrostedPOTD>
        </POTDWRAPPER>
      </FullWidthWrapperStart>
    </>
  );
};

const Main = ({ DATA }) => {
  return (
    <>
      <H1
        style={{
          textAlign: "left",
          clipPath: FromLeftToRight(15, "Springy100"),
        }}
      >
        Catches :{" "}
        {DATA.DATAOBJ.MoM.Catches?.PLAYERS_Catches !== undefined
          ? DATA.DATAOBJ.MoM.Catches?.PLAYERS_Catches
          : 0}
      </H1>
      <H1
        style={{
          textAlign: "left",
          clipPath: FromLeftToRight(20, "Springy100"),
        }}
      >
        Stumping :{" "}
        {DATA.DATAOBJ.MoM.Stumpings?.PLAYERS_Stumpings !== undefined
          ? DATA.DATAOBJ.MoM.Stumpings?.PLAYERS_Stumpings
          : 0}
      </H1>
    </>
  );
};

const SUP = ({ DATA }) => {
  return <></>;
};
