import React from 'react';
import ReactGA from "react-ga4";
import {ENV} from './Config/RunTimeConfig'
import './App.css';
// store
import { Provider } from 'react-redux'
import configureStore from "./store/index"
import { BrowserRouter  } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  DefaultOptions 
} from "@apollo/client";

// theme
import {MantineProviderWrapper} from './Theme/MantineProvider'

// Login
import {CheckAuth} from "./Public/CheckAuth"

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const client = new ApolloClient({
  uri: ENV.GRAPH,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});



ReactGA.initialize("G-5MLHD6G08Z");
ReactGA.send("pageview");

const store = configureStore()


function App() {
  return (
   
      <BrowserRouter>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <div className="App">
            <MantineProviderWrapper>
              <CheckAuth /> 
            </MantineProviderWrapper>
          </div> 
        </Provider>
        </ApolloProvider>
      </BrowserRouter>
    
  );
}

export default App;
