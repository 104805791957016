import tinycolor from "tinycolor2";

/* Color functions, move to Actions/Utils */
export function getContrastColor(hexColor,COLORS={white:'#ffffff',dark:'#111111'}) {
    const bgColorObj = tinycolor(hexColor);
    return bgColorObj.isDark() ? COLORS.white : COLORS.dark;
  }

  export const lightenColor = (color) => {
    const darkColor = tinycolor(color).lighten(10); // darken the color by 10%
    return darkColor.toHexString(); // return the color as a hex string
  };

  export const darkenColor = (color) => {
    const darkColor = tinycolor(color).darken(10); // darken the color by 10%
    return darkColor.toHexString(); // return the color as a hex string
  };

  export const getComplimentaryColor = (hex) => {
    let color = tinycolor(hex);
   
    let complimentaryColor = color.complement();
  
    if (color.isDark()) {
      complimentaryColor = complimentaryColor.lighten(50);
    } else {
      complimentaryColor = complimentaryColor.darken(50);
    }
  
    return complimentaryColor.toHexString();
  };
   
  export function getDominantColor(gradient) {
    // Get the index of the start of the first color stop
    let startIndex = gradient.indexOf('rgba(');
  
    // Get the index of the end of the first color stop
    let endIndex = gradient.indexOf(')', startIndex);
  
    // Get the first color stop
    let colorStop = gradient.substring(startIndex, endIndex + 1);
  
    // Convert the RGBA color stop to RGB
    let rgb = colorStop.replace('rgba(', '').replace(')', '').split(',');
    let r = parseInt(rgb[0].trim());
    let g = parseInt(rgb[1].trim());
    let b = parseInt(rgb[2].trim());
  
    // Convert the RGB color to a hex code
    let hex = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  
    return hex;
  }
  
  

  
  

  /*. END COLORS */