/* eslint-disable react/jsx-pascal-case */
import { Container, Group, Space, Table } from "@mantine/core";
import {
  PositionInLeague,
  ordinal_suffix_of,
} from "../../../../../../actions/Stats";
import { VideoWizardContainer } from "../../../../../../Theme/Structure/BodyContainer";
import { H3, P } from "../../../../../../Theme/type";

import { find } from "lodash";
import { BLOCK_Section_Title } from "../../../../../../Components/interface/BLOCK_Section_Title";
import { BLOCK_Section_SubHeading } from "../../../../../../Components/interface/BLOCK_Section_SubHeading";
import {
  TABLE_WRAPPER,
  TABLE_THEAD_WRAPPER,
  TABLE_TD,
} from "../../../../../../Components/interface/Wrapper/Table";

export const PlayersStandingInLeague = ({ SelectedPlayer, Fixture }) => {
  const DATA = Fixture.attributes.watch_list.data.attributes.STATS.PLAYERS;
  const PLAYER = find(DATA, (o) => {
    return o.LMSID === SelectedPlayer.PlayerID;
  });

  const BATTINGSTATS = [
    { Label: "Runs", Path: "BATTING_Runs", Order: "desc" },
    { Label: "Average", Path: "BATTING_AVG", Order: "desc" },
    { Label: "Strike Rate", Path: "BATTING_SR", Order: "desc" },
    { Label: "Balls Faced", Path: "BATTING_Balls", Order: "desc" },
    { Label: "Fours", Path: "BATTING_fours", Order: "desc" },
    { Label: "Sixes", Path: "BATTING_sixes", Order: "desc" },
  ];

  const BOWLINGSTATS = [
    { Label: "Wickets", Path: "BOWLING_Wkts", Order: "desc" },
    { Label: "Most  Runs conceded", Path: "BOWLING_Runs", Order: "desc" },
    { Label: "Overs", Path: "BOWLING_Overs", Order: "desc" },
    { Label: "Madiens", Path: "BOWLING_Maidens", Order: "desc" },
  ];

  return (
    <Container>
      <Space h="xl" />
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Standing in Selected League",
          ICON: "Download",
          DESCRIPTION: `Lets look at where our selected player fits in with the league we are commentating on.`,
          TOOLBAR: false,
        }}
      />
      <Space h="md" />
      <BLOCK_Section_SubHeading LABEL="BATTING POSITION" Color={4} />
      <P>
        The table shows the league position {SelectedPlayer.Name} is for a
        particular skill set within this league ONLY.
      </P>
      <VideoWizardContainer>
        <TABLE_WRAPPER>
          <Thead />
          <tbody>
            {BATTINGSTATS.map((S, i) => {
              return TableROW([
                S.Label,
                PLAYER[S.Path],
                PositionInLeague(
                  DATA,
                  SelectedPlayer.PlayerID,
                  [S.Path],
                  [S.Order]
                ),
              ]);
            })}
          </tbody>
        </TABLE_WRAPPER>
      </VideoWizardContainer>
      <Space h="xl" />
      <BLOCK_Section_SubHeading LABEL="BOWLING POSITION" Color={4} />
      <P>
        The table shows the league position {SelectedPlayer.Name} is for a
        particular skill set within this league ONLY.
      </P>
      <VideoWizardContainer>
        <TABLE_WRAPPER>
          <Thead />
          <tbody>
            {BOWLINGSTATS.map((S, i) => {
              return TableROW([
                S.Label,
                PLAYER[S.Path],
                PositionInLeague(
                  DATA,
                  SelectedPlayer.PlayerID,
                  [S.Path],
                  [S.Order]
                ),
              ]);
            })}
          </tbody>
        </TABLE_WRAPPER>
      </VideoWizardContainer>
    </Container>
  );
};

const Thead = () => {
  return (
    <TABLE_THEAD_WRAPPER HEADERS={["Skill", "Value", "League Position"]} />
  );
};

const TableROW = (DATA) => {
  return (
    
    <tr key={DATA[0]}>
      <TABLE_TD>{DATA[0]}</TABLE_TD>
      <TABLE_TD>{DATA[1]}</TABLE_TD>
      <TABLE_TD>{DATA[2] !== 0 ? ordinal_suffix_of(DATA[2]) : "-"}</TABLE_TD>
    </tr>
  );
};
