import { useState } from "react";
import { FindTeamLogoURI, FindIMGFORMAT } from "../../../../../actions/video";
import { orderBy, groupBy, find, filter } from "lodash";

const VideoOBJ = {
  VideoDurationinFrames: 210,
  FixtureAnimateIn: 30,
  FixtureAnimateOut: 0,
  VideoBodyTiming: null,
  ScoreCardDuration: 270,
  ResultDuration: 120,
  LeagueDurations: [],
  LeagueStartFrom: [],
  FixturesPerPage: 4,
  RenderStill: [],
};
export const useBuildVideoData = () => {
  const VideoSettings = { ...VideoOBJ };
  const [Results, setResults] = useState({ VideoSettings });

  const FindTopBatting = (BATTING) => {
    return orderBy(
      BATTING.data,
      (obj) => parseInt(obj.attributes.BATTING_Runs, 10),
      ["desc"]
    );
  };
  const FindTopBowling = (BOWLING) => {
    return orderBy(
      BOWLING.data,
      (obj) => parseInt(obj.attributes.BOWLING_Wkts, 10),
      ["desc"]
    );
  };

  const PrepStrapiData = async (DATA) => {
    console.log(DATA);
    const REMOVENONGAMES = filter(DATA, (o) => {
      const REMOVESCORES = [
        "50 / 0 (20.0)",
        "50 / 0",
        "0 / 8 (1.0)",
        "/ 0 (0.0)",
      ];
      return (
        !REMOVESCORES.includes(o.attributes.HomeTeamResult) &&
        !REMOVESCORES.includes(o.attributes.AwayTeamResult)
      );
      /*
       o.HomeTeamResult !== '50 / 0 (20.0)' &&
        o.AwayTeamResult !== '50 / 0 (20.0)' ||
        o.HomeTeamResult !== '50 / 0' &&
        o.AwayTeamResult !== '50 / 0' ||
        o.HomeTeamResult !== '0 / 8 (1.0)' &&
        o.AwayTeamResult !== '50 / 0 (20.0)' ||
        o.HomeTeamResult !== '50 / 0 (20.0)' &&
        o.AwayTeamResult !== '0 / 8 (1.0)'
      */
    });

    console.log(REMOVENONGAMES);
    const CLEANDATA = [];
    // Prepare Fixtures
    REMOVENONGAMES.map((Val, i) => {
      let item = Val.attributes;
      /*
     const REMOVESCORES = [
        "50 / 0 (20.0)",
        "50 / 0",
        "0 / 8 (1.0)",
        "/ 0 (0.0)",
      ];
      return (
        !REMOVESCORES.includes(o.HomeTeamResult) &&
        !REMOVESCORES.includes(o.AwayTeamResult)
      );
     */

      console.log(FindTopBatting(item.player_battings).slice(0, 4));
      CLEANDATA.push({
        id: Val.id,
        awayTeam: item.AwayTeam,
        awayTeamResult: item.AwayTeamResult,
        awayTeamLogo: FindTeamLogoURI(
          find(item.teams.data, (o) => {
            return o.attributes.Name === item.AwayTeam;
          })
        ),
        date: item.Date,
        homeTeam: item.HomeTeam,
        homeTeamResult: item.HomeTeamResult,
        homeTeamLogo: FindTeamLogoURI(
          find(item.teams.data, (o) => {
            return o.attributes.Name === item.HomeTeam;
          })
        ),
        resultStatement: item.ResultStatement,
        time: item.Time,
        unixTime: item.UnixTime,
        fixtureID: item.fixtureID,
        topBatting: FindTopBatting(item.player_battings).slice(0, 8),
        topBowling: FindTopBowling(item.player_bowlings).slice(0, 8),
        MOM: {
          Name:
            item.player_moms.data.length === 0
              ? "New Player"
              : item.player_moms.data[0]?.attributes.player.data?.attributes
                  .Name,
          Image: FindIMGFORMAT(
            item.player_moms.data[0]?.attributes.player.data?.attributes.image
              .data?.attributes.formats
          ).url,
        },
        region: {
          Name: item.watch_list.data.attributes.Name,
        },
      });
    });

    return CLEANDATA;
  };

  const FindLeagueDurations = (DATAOBJ) => {
    const LeagueDurations = [];
    Object.keys(DATAOBJ.Fixtures).map((League, i) => {
      let IntFixtures = DATAOBJ.Fixtures[League].length;

      let TotalTime =
        Math.ceil(IntFixtures / 2) * Results.VideoSettings.ScoreCardDuration;

      LeagueDurations.push(TotalTime);
    });

    return LeagueDurations;
  };

  const StartFrom = () => {
    const STARTFROM = [];
    let TimeProgression = 0;
    Results.VideoSettings.LeagueDurations.map((time, i) => {
      //console.log('StartFrom', time, i)
      STARTFROM.push(TimeProgression);
      TimeProgression = TimeProgression + time;
    });
    return STARTFROM;
  };

  const EndCreditsDurationStart = () => {
    return (
      Results.VideoSettings.LeagueStartFrom[Results.VideoSettings.LeagueStartFrom.length - 1] +
      Results.VideoSettings.LeagueDurations[Results.VideoSettings.LeagueDurations.length - 1]
    );
  };

  const VideoDuration = () => {
    // return (VideoSettings.EndCreditsDurationStartAt+VideoSettings.EndCreditsDuration)
    return 90 + 120 + 120 + Results.VideoSettings.VideoBodyTiming;
  };

  const FindVideoBodyTiming = () => {
    return Results.VideoSettings.LeagueDurations.reduce((a, b) => a + b, 0);
  };

  const FindResults = async (DATA) => {
    console.log(DATA);
    // orderBy(DATA,['DATA.attributes.watch_list.data.attributes.Name'])
    const CleanData = await PrepStrapiData(DATA);
    //const FilteredDATA = filter(CleanData,(o)=>{ return o.attributes.player_moms.data.length != 0})
    const TEST = groupBy(CleanData, (o) => {
      return o.region.Name;
    }); 

    const sorted = Object.keys(TEST)
      .sort()
      .reduce((accumulator, key) => {
        accumulator[key] = TEST[key];
        return accumulator;
      }, {});

    //console.log(sorted);

    const DATAOBJ = { Fixtures: sorted };
    await CalculateDurations(DATAOBJ);
    //setResults({DATAOBJ, ...Results})
    setResults({ ...Results, ...DATAOBJ });
  };

  const CalculateDurations = async (DATAOBJ) => {
    Results.VideoSettings.LeagueDurations = FindLeagueDurations(DATAOBJ);
    Results.VideoSettings.LeagueStartFrom = StartFrom();
    Results.VideoSettings.EndCreditsDurationStartAt = EndCreditsDurationStart();
    Results.VideoSettings.VideoBodyTiming = FindVideoBodyTiming();
    Results.VideoSettings.VideoDurationinFrames = VideoDuration();
  };

  return [Results, FindResults];
};
