import { Series } from "remotion";
// Assets
// Components
import { BodyContainer, GradientOverlay } from "./utils/Containers";
import { EndCredits } from "./Components/EndCredits";
import { OpeningSponsorSequence } from "./Components/Opening_Sponsors";
import {
  OpeningTitles,
  DIVISIONWINNERSOPENINGTITLES,
} from "./Components/Opening_Titles";


export const ArrowsTopTail = (props) => {
  const {RENDER} = props
  //console.log(RENDER.SPONSORSHIP)
  return (
    <OuterContainer> 
      <Series>
        <Series.Sequence durationInFrames={90}>
          <OpeningTitles {...props} />
        </Series.Sequence>

        <Series.Sequence durationInFrames={120}>
          <OpeningSponsorSequence {...props} />
        </Series.Sequence>  
        <Series.Sequence
          durationInFrames={props.DATA.VideoSettings.VideoBodyTiming}
        >
          {props.children}
        </Series.Sequence>

        <Series.Sequence durationInFrames={210}>
          <EndCredits {...props} />
        </Series.Sequence>
      </Series>
    </OuterContainer>
  );
};

const OuterContainer = (props) => {
  return (
    <BodyContainer {...props}>
      <GradientOverlay {...props}>{props.children}</GradientOverlay>
    </BodyContainer>
  );
};

// Bespoke Top and Tails
export const DivisionWinnersTopAndTail = (props) => {
  //const frame = useCurrentFrame();
  //
  const {DATA} = props;
  console.log(DATA.SPONSORSHIP);
  return (
    <OuterContainer>
      <Series>
        <Series.Sequence durationInFrames={90}>
          <DIVISIONWINNERSOPENINGTITLES
            MainTitle={DATA.DATAOBJ.General.League}
            Subtitle={DATA.DATAOBJ.General.Team}
            VideoDate={props.DATA}
            TournamentLogo={
              props?.DATA.OrderMetadata?.AdditionalInfo?.TournamentLogo
            }
          />
        </Series.Sequence>

        <Series.Sequence
          durationInFrames={DATA.DATAOBJ.VideoSettings.VideoBodyTiming}
        >
          {props.children}
        </Series.Sequence>
        {DATA.SPONSORSHIP !== false ? (
          <Series.Sequence durationInFrames={120}>
            <OpeningSponsorSequence {...props} />
          </Series.Sequence> 
        ) : (
          false
        )}
        <Series.Sequence durationInFrames={120}>
          <EndCredits {...props} />
        </Series.Sequence>
      </Series>
    </OuterContainer>
  );
};
