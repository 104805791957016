import { useRef,useEffect,useState } from 'react';
import { Img } from 'remotion';
import {FullWidthWrapper} from '../../../../utils/Containers'
import {FIXTURELIST} from './Series/FixtureList';
import LMS from "../../../../../Assets/defaultLarge.png";
// Components

const LMSIMGSTYLES = {
	mixBlendMode: "luminosity",
	position: "absolute",
	bottom: "-20%",
	left: "0%",
	width: "100%",
	opacity: 0.05,
  };
  

export const MATCHDAYFIXTURES_BODY=({DATA,VideoSettings,RenderType})=>{

	const [Dimensions, setDimensions] = useState([]);
  
	const ref = useRef(null);
	useEffect(() => { setDimensions([ref.current.clientHeight,ref.current.clientWidth]); }, []);

  return(
	<FullWidthWrapper ref={ref}> 
		<FIXTURELIST DATA={DATA} Dimensions={Dimensions}/>
		<Img src={LMS} style={LMSIMGSTYLES} />
	</FullWidthWrapper>
  )  
}  