import { useEffect, useState } from 'react'
import { TextInput,Stack,Button, Text, Container, Center   } from '@mantine/core';
import { ColoredContainer, VideoWizardContainer } from '../../Theme/Structure/BodyContainer';
import {useAuthUserLocal} from "../../Hooks/Auth/useAuthUserLocal";
import { P_WARNING, H3 } from '../../Theme/type';
import {BTN_CTA} from "../../Theme/Structure/Button/BTNS"

function Login() {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [ERROR, setERROR] = useState(false)
    const [authUserLocal ,FetchauthUserLocal] = useAuthUserLocal()
 

    async function handleLogin() {
        const loginInfo = {
            identifier: username,
            password: password
        }  
        FetchauthUserLocal(loginInfo)
       }


       useEffect(()=>{
           //console.log(authUserLocal)
           if(authUserLocal?.data === undefined){
                setERROR(authUserLocal)
           }
       },[authUserLocal])
    
	return (
  
                    <Stack style={{ width:'100%' }}>
                    <TextInput 
                        label="Username" 
                        placeholder="Identifier"
                        size="lg" 
                        type="email"
                        radius={'sm'}
                        onChange={e => setUsername(e.target.value) } 
                        value={username}
                        styles={(theme) => ({
                            label:{color: theme.colors.brand[3]},
                            input:{
                              '&, &:focus':{
                                borderColor: theme.colors.brand[1],
                                color: theme.colors.brand[3]
                              }
                            },
                        
                          })}
                    />
                    <TextInput 
                        label="Password" 
                        placeholder="Password"
                        size="lg"
                        radius={'sm'}
                        type="password"
                        onChange={e => setPassword(e.target.value) }
                        value={password}
                        styles={(theme) => ({
                            label:{color: theme.colors.brand[3]},
                            input:{
                              '&, &:focus':{
                                borderColor: theme.colors.brand[1],
                                color: theme.colors.brand[3]
                              }
                            },
                        
                          })}
                    />
                        
                        <Center><P_WARNING>{ERROR}</P_WARNING></Center>
                        <BTN_CTA 
                            CALLBACK={() => handleLogin() }
                            LABEL={`Login`}
                            
                        />
                    
                    </Stack>
                 
                
	);
}
export default Login;