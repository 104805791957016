import { Series } from "remotion";
// Components

import { DisplayPOTD } from "./POTD";
import { DisplayHonorable } from "./Honorable";
import { Counter } from "./POTD_Counter";

import {
  HONORABLEMENTIONS,
} from '../../../utils/Containers';
import { POTDUL } from "../../../utils/html";

export const POTDBATTING_BODY = ({ DATA }) => {
  return (
    <>
      <SHOWCASE DATA={DATA} /> 
    </>
  );
};
//<HONERABLE DATA={DATA}/>

const SHOWCASE = ({ DATA }) => { 
  const Durations = {
    Begin: 0, // bring in overlay
    TransitionAfter: 60, // slide overlay down
    Close: 150, // remove overlay prep nect item
    Offset: 30,
  }; 
  return (
    <Series>
      {DATA.SHOWCASE.map((arr, i) => {
        return ( 
          <Series.Sequence 
            durationInFrames={Durations.Close}
            layout="none"
            key={i}
          >
            <Counter _INT={i + 1} _TOTAL={DATA.SHOWCASE.length} _Durations={Durations}/>
            <Series>
              <Series.Sequence durationInFrames={Durations.Close} layout="none">
                <DisplayPOTD
                  _PLAY={arr}
                  _GallerySettings={DATA.VideoSettings}
                  _INT={i}
                  _Durations={Durations}
                />
              </Series.Sequence>
            </Series>
          </Series.Sequence>
        ); 
      })}
      <Series.Sequence durationInFrames={300}>
        <HONORABLEMENTIONS>
          <Counter _INT={1} _TOTAL={1} _Durations={{Close:300}} _Title={'honorable mentions'}/>
          <POTDUL>
            {DATA.HONERABLE.slice(0,16).map((arr, i) => {
              return (
                <DisplayHonorable
                  key={i}
                  _PLAY={arr}
                  _GallerySettings={DATA.VideoSettings}
                  _INT={i}
                />
              );
            })}
          </POTDUL>
        </HONORABLEMENTIONS>
      </Series.Sequence>
    </Series>
  );
};