import { useEffect, useState } from "react";
import { FindTeamLogoURI } from "../../../../../actions/video";
import { orderBy, filter, findIndex, remove, pull } from "lodash";

const VideoOBJ = {
  VideoDurationinFrames: null,
  SectionDuration: [90, 120, 180, 210, 180 * 4, 180, 120],
  VideoBodyTiming: [180, 210, 180 * 4, 180].reduce((a, b) => a + b),
  RenderStill: [40, 65, 140, 320, 480, 700, 850, 1000, 1250],
};
const DEFAULTIMG =
  "https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/playeroption4_de5eede10d.png";
export const useCreateFixtures = () => {
  const VideoSettings = { ...VideoOBJ };
  const [Fixtures, setFixtures] = useState({ VideoSettings });
 
  const VideoDuration = () => {
    return Fixtures.VideoSettings.SectionDuration.reduce((a, b) => a + b);
  };

  const batsmanToWatch = (PLAYERS) => {
    const ARR = [];
    PLAYERS.data.map((player, i) => {
      let INDEX = findIndex(ARR, (o) => {
        return o.ID === player.attributes.player.data.id;
      });
      if (INDEX === -1) {
        ARR.push({
          ID: player.attributes.player.data.id,
          Name: player.attributes.player.data.attributes.Name,
          RUNS: parseInt(player.attributes.BATTING_Runs),
          BALLS: parseInt(player.attributes.BATTING_Balls),
          SR:(parseInt(player.attributes.BATTING_Runs) /
              parseInt(player.attributes.BATTING_Balls)) *
            100,
          IMAGE:player.attributes.player.data.attributes?.image.data?.attributes.url === undefined?DEFAULTIMG:player.attributes.player.data.attributes?.image.data?.attributes.url,
          GAMES: 1,
        });
      } else {
        ARR[INDEX].RUNS =
          ARR[INDEX].RUNS + parseInt(player.attributes.BATTING_Runs);
        ARR[INDEX].BALLS =
          ARR[INDEX].BALLS + parseInt(player.attributes.BATTING_Balls);
        ARR[INDEX].SR = (ARR[INDEX].RUNS / ARR[INDEX].BALLS) * 100;
        ARR[INDEX].GAMES = ARR[INDEX].GAMES + 1;
      }
    });

    let FilteredBat = filter(ARR, function (o) {
      return o.RUNS !== 0 && o.GAMES > 2;
    });
    let RunsMoreThan10 = filter(ARR, function (o) {
      return o.RUNS > 10 && o.GAMES > 2;
    });

    return {
      Runs: orderBy(FilteredBat, ["RUNS"], ["desc", "desc"]).slice(0, 1),
      SR: orderBy(RunsMoreThan10, ["SR"], ["desc"]).slice(0, 1),
    };
  };

  const bowlersToWatch = (PLAYERS) => {
    const ARR = [];
    //console.log(PLAYERS)
    PLAYERS.data.map((player, i) => {
      let INDEX = findIndex(ARR, (o) => {
        return o.ID === player.attributes.player.data.id;
      });
      if (INDEX === -1) {
        ARR.push({
          ID: player.attributes.player.data.id,
          Name: player.attributes.player.data.attributes.Name,
          WKTS: parseInt(player.attributes.BOWLING_Wkts),
          OVERS: parseInt(player.attributes.BOWLING_Overs),
          RUNS: parseInt(player.attributes.BOWLING_Runs),
          ECO:
            parseInt(player.attributes.BOWLING_Runs) /
            parseInt(player.attributes.BOWLING_Overs),
          IMAGE:player.attributes.player.data.attributes.image.data?.attributes.url=== undefined?DEFAULTIMG:player.attributes.player.data.attributes.image.data?.attributes.url,
          GAMES: 1,
        });
      } else {
        ARR[INDEX].WKTS =
          ARR[INDEX].WKTS + parseInt(player.attributes.BOWLING_Wkts);
        ARR[INDEX].OVERS =
          ARR[INDEX].OVERS + parseInt(player.attributes.BOWLING_Overs);
        ARR[INDEX].RUNS =
          ARR[INDEX].RUNS + parseInt(player.attributes.BOWLING_Runs);
        ARR[INDEX].ECO = ARR[INDEX].RUNS / ARR[INDEX].OVERS;
        ARR[INDEX].GAMES = ARR[INDEX].GAMES + 1;
      }
    });

    let FilteredBall = filter(ARR, function (o) {
      return o.OVERS !== 0 && o.GAMES > 2;
    });

    return {
      WKTS: orderBy(FilteredBall, ["WKTS"], ["desc"]).slice(0, 1),
      ECO: orderBy(FilteredBall, ["ECO"], ["asc"]).slice(0, 1),
    };
  };

  const CreateTeamOBJ = (TEAM) => {
    //console.log(TEAM.Logo.data.attributes.formats)
    return {
      Name: TEAM.Name,
      City_Rank: TEAM.City_Rank,
      National_Rank: TEAM.National_Rank,
      World_Rank: TEAM.World_Rank,
      Form: TEAM.Form,
      Games: TEAM.Games,
      Won: TEAM.Won,
      Lost: TEAM.Lost,
      Win_Ratio: TEAM.Win_Ratio,
      batsmanToWatch: batsmanToWatch(TEAM.player_battings),
      bowlersToWatch: bowlersToWatch(TEAM.player_bowlings),
      LOGO: FindTeamLogoURI({ attributes: TEAM }),
    };
  };

  const FindFixtures = async (DATA) => {
    const DATAOBJ = {
      TeamA: CreateTeamOBJ(DATA.attributes.teams.data[0].attributes),
      TeamB: CreateTeamOBJ(DATA.attributes.teams.data[1].attributes),
      Date: DATA.attributes.Date,
      Time: DATA.attributes.Time,
      ground: DATA.attributes.ground.data.attributes.Name,
      umpire: DATA.attributes.umpire.data.attributes.Name,
    };

    Fixtures.VideoSettings.VideoDurationinFrames = VideoDuration();

    setFixtures({ ...Fixtures, DATAOBJ: { ...DATAOBJ } });
  };

  useEffect(() => {}, [Fixtures]);

  return [Fixtures, FindFixtures];
};
