import { useEffect, useState } from "react";
import { FindTeamLogoURI } from "../../../../../actions/video";
import { find } from "lodash";
const VideoOBJ = {
  VideoDurationinFrames: null,
  SectionDuration: [90, 120, 210, 210, 210, 180, 180, 120],
  VideoBodyTiming: [210, 210, 210, 180, 180].reduce((a, b) => a + b),
  RenderStill: [70, 180, 270, 380, 520,700,900,1100],
};
export const useCreateFixtures = () => { 
  const VideoSettings = { ...VideoOBJ };
  const [Fixtures, setFixtures] = useState({ VideoSettings });

  const VideoDuration = () => {
    return Fixtures.VideoSettings.SectionDuration.reduce((a, b) => a + b);
  };

  const CreateTeamOBJ = (TEAM) => {
    //console.log(TEAM.Logo.data.attributes.formats)
    return {
      Name: TEAM.Name,
      City_Rank: TEAM.City_Rank,
      National_Rank: TEAM.National_Rank,
      World_Rank: TEAM.World_Rank,
      Form: TEAM.Form,
      Games: TEAM.Games,
      Won: TEAM.Won,
      Lost: TEAM.Lost,
      Win_Ratio: TEAM.Win_Ratio,
      LOGO: FindTeamLogoURI({ attributes: TEAM }),
    };
  };

  const FindPerformance = (ID, DATA) => {
    console.log(ID, DATA);
    console.log(
      find(DATA, (o) => {
        return o.attributes.player.data.attributes.PlayerID === ID;
      })
    );
    return find(DATA, (o) => {
      return o.attributes.player.data.attributes.PlayerID === ID;
    })?.attributes;
  };

  const FindFixtures = async (DATA) => {
    let DATAOBJ={}
    const PLAYER =
      DATA.attributes.player_moms.data[0]?.attributes.player.data.attributes;
    console.log("PLAYER", DATA);
    if (PLAYER == undefined) {
       DATAOBJ = {MoM:false};
    } else {
       DATAOBJ = {
        MoM: {
          Name: PLAYER.Name,
          MATCHES: PLAYER.MATCHES,
          WORLDRANKINGBATTING: PLAYER.WORLDRANKINGBATTING,
          WORLDRANKINGBOWLING: PLAYER.WORLDRANKINGBOWLING,
          HIGHSCORE: PLAYER.HIGHSCORE,
          FIFTIES: PLAYER.FIFTIES,
          RUNS: PLAYER.RUNS,
          WICKETS: PLAYER.WICKETS,
          ID:DATA.attributes.player_moms.data[0]?.attributes.player.data.id,
          PLAYERID: PLAYER.PlayerID,
          IMAGE: PLAYER.image.data?.attributes.url,
          TEAM: DATA.attributes.player_moms.data[0].attributes.team.data
            .attributes.Name,
          TEAMLOGO:
            DATA.attributes.player_moms.data[0].attributes.team.data.attributes
              .Logo.data.attributes.url,
          Batting: FindPerformance(
            PLAYER.PlayerID,
            DATA.attributes.player_battings.data
          ),
          Bowling: FindPerformance(
            PLAYER.PlayerID,
            DATA.attributes.player_bowlings.data
          ),
          Catches: FindPerformance(
            PLAYER.PlayerID,
            DATA.attributes.player_catches.data
          ),
          Stumpings: FindPerformance(
            PLAYER.PlayerID,
            DATA.attributes.player_stumpings.data
          ),
        },
        TeamA: CreateTeamOBJ(DATA.attributes.teams.data[0].attributes),
        TeamB: CreateTeamOBJ(DATA.attributes.teams.data[1].attributes),
        Date: DATA.attributes.Date,
        Time: DATA.attributes.Time,
        ground: DATA.attributes.ground.data.attributes.Name,
        umpire: DATA.attributes.umpire.data.attributes.Name,
        ResultStatement: DATA.attributes.ResultStatement,
      };
    }
    Fixtures.VideoSettings.VideoDurationinFrames = VideoDuration();
    console.log(DATAOBJ);
    setFixtures({ ...Fixtures, DATAOBJ: { ...DATAOBJ } });
  };

  useEffect(() => {}, [Fixtures]);

  return [Fixtures, FindFixtures];
};
