import { useState } from "react";
import {ENV} from '../../Config/RunTimeConfig'
import { parseCookies } from 'nookies'

const qs = require('qs');


export const useFindNullFixtures = (ctx)=>{

    const [FindNullFixtures,setFindNullFixtures] = useState(null);
   
    const jwt = parseCookies(ctx).jwt
    const AUTHID = parseCookies(ctx).AUTHID
    
    const DeleteFindNullFixtures = async (_ID,_DATA)=>{
      //console.log(_DATA, AUTHID)
        const res = await fetch(`${ENV.API_URL}/api/fixture/FindNull`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
          
        })
        const Results = await res.json();
    //console.log(Results)

      setFindNullFixtures(Results)
    }
    return[FindNullFixtures,DeleteFindNullFixtures]
}

export const useFindMissingWatchList = (ctx)=>{

  const [MissingWatchList,setMissingWatchList] = useState(null);
 
  const jwt = parseCookies(ctx).jwt
  const AUTHID = parseCookies(ctx).AUTHID
  
  const DeleteMissingWatchList = async (_ID,_DATA)=>{
    //console.log(_DATA, AUTHID)
      const res = await fetch(`${ENV.API_URL}/api/fixture/FindFixturesNoWatchList`, {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`
          },
        
      })
      const Results = await res.json();
  //console.log(Results)

  setMissingWatchList(Results)
  }
  return[MissingWatchList, DeleteMissingWatchList]
}




export const useFindDuplicatePerformanceFixtures= (ctx)=>{

    const [FindDuplicatePerformance,setFindDuplicatePerformance] = useState(null);
   
    const jwt = parseCookies(ctx).jwt
    const AUTHID = parseCookies(ctx).AUTHID
    
    const DeleteFindDuplicatePerformance = async (_ID,_DATA)=>{
      //console.log(_DATA, AUTHID)
        const res = await fetch(`${ENV.API_URL}/api/fixture/RemoveDuplicates`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
          
        })
        const Results = await res.json();
    //console.log(Results)

      setFindDuplicatePerformance(Results)
    }
    return[FindDuplicatePerformance,DeleteFindDuplicatePerformance]
}

export const useRemoveDuplicatePlayers= (ctx)=>{

  const [RemoveDuplicatePlayers,setRemoveDuplicatePlayers] = useState(null);
 
  const jwt = parseCookies(ctx).jwt
  const AUTHID = parseCookies(ctx).AUTHID
  
  const ProcessRemoveDuplicatePlayers = async (_ID,_DATA)=>{
    //console.log(_DATA, AUTHID)
      const res = await fetch(`${ENV.API_URL}/api/players/RemoveDuplicates`, {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`
          },
        
      })
      const Results = await res.json();
  //console.log(Results)

    setRemoveDuplicatePlayers(Results)
  }
  return[RemoveDuplicatePlayers,ProcessRemoveDuplicatePlayers]
}
//