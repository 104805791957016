/* eslint-disable react/jsx-pascal-case */
// HOOKS

// Actions

import { Container, Divider, Tabs } from "@mantine/core";

import { filter } from "lodash";
import { BTN_ICON_CALLBACK } from "../../../../../../Theme/Structure/Button/BTNS";

import { H3 } from "../../../../../../Theme/type";
import { BLOCK_ToolBar } from "../../../../../../Components/interface/BLOCK_Toolbar";
import { IconPokeball, IconBrandAsana } from "@tabler/icons";
// utils
import { SORTABLETABLE } from "./utils/SortableTable";
import {
  TAB_LIST,
  TAB_WRAPPER,
} from "../../../../../../Components/interface/Wrapper/TABS";
import { COPY_WARNING } from "../../../../../../Components/interface/BLOCK_Info";
export const LeagueLists = ({ Fixture, setPath }) => {
  if (Fixture.attributes.watch_list.data.attributes.STATS.PLAYERS.length === 0)
    return (
      <Container size={"lg"}>
        <H3 colorID={3} size={20} weight={400} lineheight={`1.5em`}>
          League: {Fixture.attributes.watch_list.data.attributes.Name}
        </H3>
        <BLOCK_ToolBar
          TOOLS={[
            <BTN_ICON_CALLBACK
              CALLBACK={() => {
                setPath(0);
              }}
              ICON={"ArrowBackUp"}
              TIPOBJ={{
                LABEL: `Back to Game Details`,
                COLOR: "red",
                POSITION: "top",
              }}
            />,
          ]}
        />
        <COPY_WARNING COPY="NO Games data stored for this league." />
      </Container>
    );
  return (
    <>
      <Container size={"lg"}>
        <H3 colorID={3} size={20} weight={400} lineheight={`1.5em`}>
          League: {Fixture.attributes.watch_list.data.attributes.Name}
        </H3>

        <BLOCK_ToolBar
          TOOLS={[
            <BTN_ICON_CALLBACK
              CALLBACK={() => {
                setPath(0);
              }}
              ICON={"ArrowBackUp"}
              TIPOBJ={{
                LABEL: `Back to Game Details`,
                COLOR: "red",
                POSITION: "top",
              }}
            />,
          ]}
        />

        <Divider my="xl" size="sm" labelPosition="center" label="THE LEAGUE" />
      </Container>
      <Container size={"lg"}>
        <TAB_WRAPPER defaultValue="Batting">
          <TAB_LIST
            ARR={[
              {
                VALUE: "Batting",
                LABEL: "Batting",
                ICON: <IconBrandAsana size={24} color="teal" />,
              },
              {
                VALUE: "Bowling",
                LABEL: "Bowling",
                ICON: <IconPokeball size={24} color="teal" />,
              },
            ]}
          />
          <Tabs.Panel value="Batting" pt="xs">
            <LeagueBatting
              Fixtures={
                Fixture.attributes.watch_list.data.attributes.STATS.PLAYERS
              }
              setPath={setPath}
            />
          </Tabs.Panel>

          <Tabs.Panel value="Bowling" pt="xs">
            <LeagueBolwing
              Fixtures={
                Fixture.attributes.watch_list.data.attributes.STATS.PLAYERS
              }
              setPath={setPath}
            />
          </Tabs.Panel>
        </TAB_WRAPPER>
      </Container>
    </>
  );
};

const LeagueBatting = ({ Fixtures, setPath }) => {
  const OBJ = {
    OrderBy: "BATTING_Runs",
    ITEMS: [
      { Name: "Name", PATH: "Name", ToFixed: false },
      { Name: "INN", PATH: "BATTING_INNINGS", ToFixed: false },
      { Name: "RUNS", PATH: "BATTING_Runs", ToFixed: false },
      { Name: "Balls Faced", PATH: "BATTING_Balls", ToFixed: false },
      { Name: "Fours", PATH: "BATTING_fours", ToFixed: false },
      { Name: "Sixes", PATH: "BATTING_sixes", ToFixed: false },
      { Name: "AVG", PATH: "BATTING_AVG", ToFixed: false },
      { Name: "SR", PATH: "BATTING_SR", ToFixed: false },
    ],
  };

  return (
    <SORTABLETABLE
      DATA={filter(Fixtures, (o) => {
        return o.BATTING_INNINGS !== undefined;
      })}
      CALLBACK={setPath}
      OBJ={OBJ}
    />
  );
};

const LeagueBolwing = ({ Fixtures, setPath }) => {
  const OBJ = {
    OrderBy: "BOWLING_Wkts",
    ITEMS: [
      { Name: "Name", PATH: "Name", ToFixed: false },
      { Name: "INN", PATH: "BOWLING_INNINGS", ToFixed: false },
      { Name: "Wickets", PATH: "BOWLING_Wkts", ToFixed: false },
      { Name: "Runs", PATH: "BOWLING_Runs", ToFixed: false },
      { Name: "Overs", PATH: "BOWLING_Overs", ToFixed: false },
      /* { Name: "AVG", PATH: "BOWLING_AVG", ToFixed: false },
          { Name: "ECO", PATH: "BOWLING_ECO", ToFixed: false },
          { Name: "SR", PATH: "BOWLING_SR", ToFixed: false }, */
    ],
  };

  return (
    <SORTABLETABLE
      DATA={filter(Fixtures, (o) => {
        return o.BOWLING_INNINGS !== undefined;
      })}
      CALLBACK={setPath}
      OBJ={OBJ}
    />
  );
};
