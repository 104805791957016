import {useCurrentFrame } from 'remotion';
import {IntroContainer} from "../../utils/Containers"
import {TournamentYearH1, } from "../../utils/Text"

import {SpringToFrom} from '../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../Animation/interpolate'


export const TournamentYear = (props)=>{
    const {VideoLabel} = props
    const frame = useCurrentFrame();
    return(
	<IntroContainer
        style={{ 
                padding:0,
                width:'90%',
            }}
    >
		<TournamentYearH1 
            style={{
                opacity:interpolateOpacityByFrame(frame, 15,30, 0,1),
                transform: `translateY(${SpringToFrom(15,-200,0,'Default')}px) translateY(${SpringToFrom(90,0,-200,'fast')}px)`
                 }} {... props} >
                    <div style={{ opacity:interpolateOpacityByFrame(frame, 90,100, 1,0), }}>
                        {VideoLabel}
                    </div>
                </TournamentYearH1>	
	</IntroContainer>
    )
}
//<OpeningBottomBar style={{width:`${widthWhite}px`}} {... props}/>