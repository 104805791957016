import { useEffect, useState } from "react";

// Components
import { SelectATeam } from "./Search";
import TeamProfile from "./Profile";

import { BodyContainer } from "../../Theme/Structure/BodyContainer";

const EditTeamProfile = () => {
  const [SectionID, setSectionID] = useState(0);
  const [SelectedTeam, setSelectedTeam] = useState(false);
  const SECTIONS = [
    {
      Component: (
        <SelectATeam
          setSectionID={setSectionID}
          setSelectedTeam={setSelectedTeam}
        />
      ),
      Label: "Search Teams",
    },
    {
      Component: (
        <TeamProfile
          SelectedTeam={SelectedTeam}
          setSelectedTeam={setSelectedTeam}
          setSectionID={setSectionID}
        />
      ),
      Label: "",
    },
  ];

  useEffect(() => {
    console.log(SelectedTeam);
  }, []);
  return (
    <>
      <BodyContainer>{SECTIONS[SectionID].Component}</BodyContainer>
    </>
  );
};
export default EditTeamProfile;
//<Dropzone />
