import { useCurrentFrame, useVideoConfig, spring } from "remotion";
import { useTheme } from "styled-components";

import {
  TeamLogoRight,
  TeamLogoLeft,
  TeamNamesContainer,
  ResultStatment,
} from "../../../utils/Containers";
import { H2, P } from "../../../utils/Text";

export const GameScoreCard = ({ GAME }) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const theme = useTheme();

  const SpringConfig = {
    Springy900: {
      damping: 10,
      stiffness: 200,
      mass: 1,
    },
    Springy500: {
      damping: 10,
      stiffness: 200,
      mass: 1,
    },
    Springy100: {
      damping: 10,
      stiffness: 150,
      mass: 0.3,
    },
    Stiff: {
      damping: 80,
      stiffness: 100,
      mass: 0.1,
    },
  };

  const StaggerSpringIn = (int) => {
    return spring({
      fps,
      from: 0,
      to: 1,
      frame: frame - int,
      config: SpringConfig.Springy100,
    });
  };

  return (
    <>
      <TeamLogoLeft
        style={{
          backgroundImage: `url(${GAME.homeTeamLogo})`,
          transform: `scale(${StaggerSpringIn(7)}) `,
        }}
      />

      <TeamLogoRight
        style={{
          backgroundImage: `url(${GAME.awayTeamLogo})`,
          transform: `scale(${StaggerSpringIn(10)}) `,
        }}
      />

      <TeamNamesContainer>
        <H2 style={{ lineHeight: "45px", color: theme.Copy[0] }}>
          {GAME.homeTeam} <br />
          <span style={{ fontWeight: 600 }}>{GAME.homeTeamResult}</span>
        </H2>
        <P style={{ color: theme.Copy[2] }}>vs</P>
        <H2 style={{ lineHeight: "45px", color: theme.Copy[0] }}>
          {GAME.awayTeam}
          <br />
          <span style={{ fontWeight: 600 }}>{GAME.awayTeamResult}</span>
        </H2>
      </TeamNamesContainer>

      <ResultStatment>{GAME.resultStatement}</ResultStatment>
    </>
  );
};
