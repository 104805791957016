
import { useState } from "react" 
import axios from 'axios';
 
import {ENV} from '../../Config/RunTimeConfig'
import { parseCookies } from 'nookies'


export const useUploadImageViaDropzone = (ctx)=>{

    const [DropZoneImage,setDropZoneImage] = useState(null);
    const jwt = parseCookies(ctx).jwt

    const UploadDropZoneImage = async (_FILE, _PLAYER)=>{
        //console.log(_FILE)
        const formData = new FormData()
    
        formData.append('files', _FILE[0])
    
        axios.post(`${ENV.API_URL}/api/upload`, formData)
        .then((response)=>{
          
            //console.log(response.data[0].url)
            const imageId = response.data[0]
            //console.log(imageId, _PLAYER.id)
            setDropZoneImage(imageId) 
           // UpdatePlayer(imageId, _PLAYER.id, response.data[0].url)
      
        }).catch((error)=>{
          //console.log(error)
        })
       
    }
    return[DropZoneImage,UploadDropZoneImage]
}