/* eslint-disable react/jsx-pascal-case */
// Hooks
// Components
import { Space } from "@mantine/core";
import { SelectDefaultVideoSettings } from "./SelectDefaultVideoSettings";
// UI ELEMENTS
import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";
import { PageHeader } from "../../Components/interface/ProductHeader";
export const SettingsVideoDefault = () => {
  return (
    <>
      <PageHeader NavPosition="Video Settings" />
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Asset Theme",
          ICON: "Download",
          DESCRIPTION:
            "Select a default template and theme for your assets, these settings will be loaded into every asset you create as the default option.",
          TOOLBAR: false,
        }}
      />

      <SelectDefaultVideoSettings />
      <Space h="xl" />
    </>
  );
};
