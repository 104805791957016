import { useEffect, useState } from "react";
import {useSelector } from "react-redux";

// Theme
import { Button, Image, Menu } from "@mantine/core";
import { H3 } from "../../../../Theme/type";
import { VideoWizardContainer } from "../../../../Theme/Structure/BodyContainer";
import {
  TABLE_WRAPPER,
  TABLE_THEAD_WRAPPER,
  TABLE_TD,
} from "../../../../Components/interface/Wrapper/Table";

import { COPY_WARNING } from "../../../../Components/interface/BLOCK_Info";
import { IconTrash } from "@tabler/icons";


export const DataCheck = () => {
  const RENDER = useSelector((state) => state.RENDER);
  const [update, setupdate] = useState(false);
  const [hasRemoved, setHasRemoved] = useState(false);


  console.log(RENDER)

  const RemoveItemFromResults = (DATA, POSITION, RETIME = false) => {
    DATA.splice(POSITION, 1);
    const TIMINGS = RENDER.DATAOBJ.VideoSettings;
    if (RETIME) {
      TIMINGS.VideoBodyTiming = TIMINGS.VideoBodyTiming - TIMINGS.Duration;
      TIMINGS.VideoDurationinFrames =
        TIMINGS.VideoDurationinFrames - TIMINGS.Duration;
    }
    setHasRemoved(true);
    setupdate(!update);
  };

  useEffect(() => {}, [update]);

  return (
    <>
      {hasRemoved ? (
        <COPY_WARNING COPY="A player has been removed from the results. Start over to reinstate all players." />
      ) : (
        false
      )}
      <VideoWizardContainer>
        <H3 colorID={5}>POTD Show case</H3>

        
        <TABLE_WRAPPER>
        <TABLE_THEAD_WRAPPER
          DISPLAY={["None", "table-header-group"]}
          HEADERS={[" ", "Player", "RUNS", ""]}
        />
          <TABLE_THEAD_WRAPPER
          DISPLAY={[ "table-header-group","None"]}
          HEADERS={[" ", "Player", "Team", "SR", "RUNS"]}
          />

         
 
          <tbody>
            {RENDER.DATAOBJ.SHOWCASE.map((Performance, ii) => {
              return (
                <tr key={ii}>
                  <TABLE_TD>
                    <Image
                      src={Performance.IMAGE}
                      height={50}
                      width={50}
                      radius={100}
                    />
                  </TABLE_TD>
                  <TABLE_TD>{Performance.Name}</TABLE_TD>
                  <TABLE_TD HIDE={true}>{Performance.Team}</TABLE_TD>
                  <TABLE_TD HIDE={true}>{Performance.BATTING_SR}</TABLE_TD>
                  <TABLE_TD>{Performance.BATTING_Runs}</TABLE_TD>
                  {/* <TABLE_TD>
                    <RemovePlayerBtn
                      RemoveItemFromResults={RemoveItemFromResults}
                      DATA={RENDER.DATAOBJ.SHOWCASE}
                      INT={ii}
                      RETIME={true}
                      Name={Performance.Name}
                    /> 
                  </TABLE_TD> */}
                </tr>
              );
            })}
          </tbody>
        </TABLE_WRAPPER>
      </VideoWizardContainer>
      {hasRemoved ? (
        <COPY_WARNING COPY="A player has been removed from the results.  Start over to reinstate all players." />
      ) : (
        false
      )}
      <VideoWizardContainer>
        <H3 colorID={5}>POTD Honerable Mentions</H3>

        <TABLE_WRAPPER>

        <TABLE_THEAD_WRAPPER
          DISPLAY={["None", "table-header-group"]}
          HEADERS={[" ", "Player", "RUNS", ""]}
        />
          <TABLE_THEAD_WRAPPER
          DISPLAY={[ "table-header-group","None"]}
          HEADERS={[" ", "Player", "Team", "SR", "RUNS", ""]}
          />
         

          <tbody>
            {RENDER.DATAOBJ.HONERABLE.map((Performance, ii) => {
              return (
                <tr key={ii}>
                  <TABLE_TD>
                    <Image
                      src={Performance.IMAGE}
                      height={50}
                      width={50}
                      radius={100}
                    />
                  </TABLE_TD>
                  <TABLE_TD>{Performance.Name}</TABLE_TD>
                  <TABLE_TD HIDE={true}>{Performance.Team}</TABLE_TD>
                  <TABLE_TD HIDE={true}>{Performance.BATTING_SR}</TABLE_TD>
                  <TABLE_TD>{Performance.BATTING_Runs}</TABLE_TD>
                  {/* <TABLE_TD>
                    <RemovePlayerBtn
                      RemoveItemFromResults={RemoveItemFromResults}
                      DATA={RENDER.DATAOBJ.HONERABLE}
                      INT={ii}
                      RETIME={false}
                      Name={Performance.Name}
                    />
                  </TABLE_TD> */}
                </tr>
              );
            })}
          </tbody>
        </TABLE_WRAPPER>
      </VideoWizardContainer>
    </>
  );
};

const RemovePlayerBtn = ({
  Name,
  RemoveItemFromResults,
  DATA,
  INT,
  RETIME,
}) => {
  return (
    <Menu shadow="md" width={300}>
      <Menu.Target>
        <Button
          uppercase
          sx={(theme) => ({
            backgroundColor: theme.colors.brand[0],
            color: theme.colors.brand[2],
            "&:hover": {
              backgroundColor: theme.colors.brand[6],
              color: theme.colors.brand[0],
            },
          })}
        >
          x
        </Button>
      </Menu.Target>

      <Menu.Dropdown
        sx={(theme) => ({
          backgroundColor: theme.colors.brand[1],
        })}
      >
        <Menu.Label
          sx={(theme) => ({
            color: theme.colors.brand[3],
            fontWeight: 900,
          })}
        >
          Confirm Removal
        </Menu.Label>

        <Menu.Item
          icon={<IconTrash size={14} />}
          onClick={() => {
            RemoveItemFromResults(DATA, INT, RETIME);
          }}
          sx={(theme) => ({
            color: theme.colors.brand[6],
            fontWeight: 900,
          })}
        >
          Remove {Name} from this Video
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
