import UsersDownloads from "./UsersDownloads";
import { PageHeader } from "../../Components/interface/ProductHeader";
const Downloads = () => {
  return (
    <>
      <PageHeader NavPosition="Downloads" />
      <UsersDownloads />
    </>
  );
};
export default Downloads;
