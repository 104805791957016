import { useState } from "react";
import { ENV } from "../../Config/RunTimeConfig";
import { orderBy } from "lodash";
const qs = require("qs");

export const useAuthNavigation = () => {
  const [Navigation, setNavigation] = useState(false);

  const FetchNavigation = async () => {
    const query = qs.stringify(
      {
        pagination: {
          pageSize: 100,
        },
        populate: {
          NavPoster: {
            populate: "*",
          }, 
          assets:{
            populate:{
              fields:['ComponentName'],
              orders:{
                fields:['id','createdAt']
              }
            }
          }
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

    const res = await fetch(`${ENV.API_URL}/api/navigations?${query}`);
    const Navigation = await res.json();
  //console.log(Navigation);

    setNavigation(orderBy(Navigation.data, ["attributes.Order"], ["asc"]));
  };
  return [Navigation, FetchNavigation];
};
