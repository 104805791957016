import { useTheme } from "styled-components";
import { H1, H3 } from "../../../../utils/Text";
import { Image } from "@mantine/core";
import { SpringToFrom } from "../../../../../Animation/RemotionSpring";
import { EraseFromMiddle, FromMiddle } from "../../../../../Animation/ClipWipe";

export const PlayerImage = ({ DATA }) => {
  const theme = useTheme();

  return (
    <>
      <H1
        style={{
          position: "absolute",
          top: 0,
          fontSize: `clamp(2em,4em,5em)`,
          lineHeight: "1.2em",
          color: theme.Copy[2],
          clipPath: FromMiddle(
            DATA.VideoSettings.SectionDuration[2],
            "Springy100"
          ),
          transform: `translateY(${SpringToFrom(
            DATA.VideoSettings.VideoBodyTiming -
              DATA.VideoSettings.SectionDuration[6],
            0,
            -200
          )}px) `,
        }}
      >
        {DATA.DATAOBJ.MoM.Name}
      </H1>
      <H1
        style={{
          position: "absolute",
          top: "80px",
          fontSize: `clamp(1.5em,2em,3em)`,
          lineHeight: "1em",
          clipPath: FromMiddle(
            DATA.VideoSettings.SectionDuration[2],
            "Springy100"
          ),
          transform: `translateY(${SpringToFrom(
            DATA.VideoSettings.VideoBodyTiming -
              DATA.VideoSettings.SectionDuration[6],
            0,
            -200
          )}px) `,
        }}
      >
        {DATA.DATAOBJ.MoM.TEAM}
      </H1>
      <Image
        src={DATA.DATAOBJ.MoM.IMAGE}
        fit="contain"
        height={`1080px`}
        width={`100%`}
        style={{
          position: "absolute",
          clipPath: EraseFromMiddle(
            DATA.VideoSettings.VideoBodyTiming - 30,
            "Springy100"
          ),
          transform: `translateY(${SpringToFrom(100, 2000, 0)}px) 
              `,
        }}
      />
    </>
  );
};
