import { useEffect, useState } from 'react';
import { Group, Text, useMantineTheme, Box  } from '@mantine/core';
import { Upload, Photo, X, Icon as TablerIcon } from 'tabler-icons-react';

import { Dropzone, DropzoneProps, MIME_TYPES } from '@mantine/dropzone';
import {useUploadImageViaDropzone} from '../../Hooks/user/useUploadImageViaDropzone'
import {ICONDB} from '../../Theme/icons'
import { H3, P } from '../../Theme/type';






export default function DropZone({setImageFile,setCloseDisabled }) {
    //[PlayerImage,UploadPlayerImage]
  const   [DropZoneImage,UploadDropZoneImage]= useUploadImageViaDropzone()
  const [ProcessingImage, setProcessingImage] = useState(false)
  const [DropState, setDropState] = useState(<Idol />)
  const theme = useMantineTheme();



  const handleFileUpload=(_FILE)=>{
    //console.log(_FILE)
    UploadDropZoneImage(_FILE)
    setProcessingImage(true)
    setCloseDisabled(true)
    setDropState(<OnDrop />)
  }

  useEffect(()=>{
    // Tell Component file is uploaded
    setProcessingImage(false)
    // Tell the Parent Component what the ID was
    setImageFile(DropZoneImage)
    // CHange the state on the Dropzone
    setDropState(<Idol />)
  },[DropZoneImage])
 

  return (
    <Dropzone 
          onDrop={(files) => handleFileUpload(files)}
          onReject={(files) =>  setDropState(<Rejected files={files}/>) }
          maxSize={50 * 1024 ** 2}
          accept={['video/webm',MIME_TYPES.mp4]}
          multiple={false}
          loading={ProcessingImage}
  >
  
      <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
        {DropState}
      </Group>
    </Dropzone>
 
  );
}



const Idol=()=>{
  return(
    <Group position='center'>
      <Box> 
          <P colorID={7}>{ICONDB['Photo'].Component}</P>
      </Box>
        
        <div>
          <Text size="xl" inline>
            <H3 colorID={7}>Drag video files here or click to select files</H3>
          </Text>         
        </div>
    </Group>
  )
}

const OnDrop=()=>{
  return(
    <Group position='center'>
      <Box> 
          <P colorID={5}>{ICONDB['Upload'].Component}</P>
      </Box>
        
        <div>
          <Text size="xl" inline>
            <H3 colorID={5}>Please Wait ...</H3>
            <H3 colorID={5}>Processing Uploaded</H3>
          
        
          </Text>
         
        </div>
    </Group>
  )
}

const Rejected=({files})=>{
//console.log('rejected files', files[0].errors)
  return(
    <Group position='center'>
      <Box> 
          <P colorID={6}>{ICONDB['CircleX'].Component}</P>
      </Box>
        
        <div>
          <Text size="xl" inline>
            <H3 colorID={6}>File upload rejected</H3>
          
          {
            files[0].errors.map((err,i)=>{
              return <H3 colorID={3} key={i}>{err.message}</H3>
            })
          }
          </Text>
         
        </div>
    </Group>
  )
}

