import {
  createStyles,
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
} from "@mantine/core";
import { IconVideo, IconChartDots, IconPhoto } from "@tabler/icons";
import { useRef } from "react";
import useOnScreen from "../../Hooks/Utils/useOnScreen";

const mockdata = [
  {
    title: "Player Image Storage",
    description:
      "Organize weekend  and seasonal images and videos for players and teams in a manageable way with our global player repository",
    icon: IconPhoto,
  },
  {
    title: "Video and Image Creator",
    description:
      "Create professional looking video and images from weekend and seasonal data in a matter of minutes without ever having to look at up scorecard or LMS player profile again",
    icon: IconVideo,
  },
  {
    title: "Statistics and Player milestones",
    description:
      "Monitor critical and unique franchise statistics to help track player development and create social media narratives.",
    icon: IconChartDots,
  },
];

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    color: theme.colors.Public[1],
    [theme.fn.smallerThan("sm")]: {
      fontSize: 24,
    },
  },

  description: {
    maxWidth: 600,
    margin: "auto",
    color: theme.colors.Public[1],
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.colors.Public[2],
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border: `1px solid ${theme.colors.Public[0]}`,
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.colors.Public[2],
      color: theme.colors.Public[1],
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },
}));

export function FeaturesCards() {
  const { classes, theme } = useStyles();

  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const features = mockdata.map((feature) => (
    <Card
      key={feature.title}
      shadow="md"
      radius="md"
      className={classes.card}
      p="xl"
    >
      <feature.icon size={50} stroke={2} color={theme.colors.Public[2]} />
      <Text size="lg" weight={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text size="sm" color="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));

  return (
    <Container size="lg" py={80}>
      <Group position="center">
        <Badge variant="filled" size="lg" color={"gray"}>
          What we offer
        </Badge>
      </Group>

      <Title
        order={2}
        className={classes.title}
        align="center"
        transform="uppercase"
        mt="sm"
      >
        Made by franchise managers for frachise managers
      </Title>

      <Text
        color="dimmed"
        className={classes.description}
        align="center"
        mt="md"
      >
        Statto Social was built from the ground up with franchise managers in
        mind. We aim to address a number of issues that we can face when
        curating social media content, and remove the weekly time sink that
        creating social media can create
      </Text>

      <SimpleGrid
        ref={ref}
        cols={3}
        spacing="xl"
        mt={50}
        breakpoints={[{ maxWidth: "md", cols: 1 }]}
      >
        {features}
      </SimpleGrid>
    </Container>
  );
}
