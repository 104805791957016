import {useCurrentFrame } from 'remotion';
import { useTheme } from 'styled-components';
import { H4 } from '../../utils/Text';
import {SpringToFrom} from '../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../Animation/interpolate'


export const BroughtToYouBy = ()=>{
    const frame = useCurrentFrame();
    const theme = useTheme();
    return(
        <div style={{
                opacity:interpolateOpacityByFrame(frame, 45,60, 0, 1),
                transform: `translateY(${SpringToFrom(45,-10,0,'Springy100','slow')}px)`
            }}
        >
                <H4 style={{ color:theme.Base[4],}} >
                    Brought to you by</H4>
            </div>
    )
}