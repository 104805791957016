import { Group, SimpleGrid } from "@mantine/core";

import {
  SECTIONLABEL,
  PlayerImage,
  StatItem,
  CONTENTBG,
  SeriesWrapper,
} from "./UIELEMENTS";

export const BattingStats = ({ DATA, Duration, IMG }) => {
  const OBJ = [
    { LABEL: "RUNS", PATH: "RUNS" },
    { LABEL: "INNINGS", PATH: "INNINGS" },
    { LABEL: "STRIKE RATE", PATH: "STRIKERATE" },
    { LABEL: "Average", PATH: "BATTINGAVERAGE" },
    { LABEL: "100's", PATH: "HUNDREDS" },
    { LABEL: "50's", PATH: "FIFTIES" },
  ];
  const STATITEMOBJ = {
    WIDTH: "100%",
    TOP: 10,
    END: 160,
  };

  return (
    <SeriesWrapper>
      <SECTIONLABEL LABEL={"Batting Career"} Duration={Duration} />
      <PlayerImage IMG={IMG} DATA={DATA} INT={2} />

      <CONTENTBG Duration={Duration}>
        <SimpleGrid cols={3} style={{ width:'100%'}}>
          {OBJ.map((ITEM, i) => {
            return (
              <StatItem
                key={i}
                I={i}
                stat={{
                  Label: ITEM.LABEL,
                  Value: DATA.DATAOBJ[ITEM.PATH],
                }}
                STATITEMOBJ={STATITEMOBJ}
              />
            );
          })}
          </SimpleGrid>
       
      </CONTENTBG>
    </SeriesWrapper>
  );
};
