import { Box, Center, Image, SimpleGrid } from "@mantine/core";
// Styled Comps
import { useTheme } from "styled-components";

// Animations
import { SpringToFrom } from '../../../../../../Animation/RemotionSpring';

import { H3, P } from '../../../../../utils/Text';
import { TrimPlayerName } from '../../../../../../../../actions/UX';

export const SupImages = ({ DATA, DURATION, VAR, Label }) => {
  const theme = useTheme();
  //const ThreeImg = orderBy(DATA.DATAOBJ.TeamStats.PLAYERS, ['ARR_RUNS.length'], ['desc']).slice(0,Limit)
  const ThreeImg = DATA;
  const IMGRATIO = 180;

  const FindFileExt = (filename) => {
    if(filename === undefined)
      return "https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/playeroption4_de5eede10d.png"
    else if(filename.split(".").pop() === "webp"){
      return "https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/playeroption4_de5eede10d.png"
    }else{
      return filename
    }
  };

  return (
    <>
      <H3
        style={{
          width: "100%",
          padding: "0 0 .5em 0 ",
          fontSize: "2.9rem",
          lineHeight: "1em",
          fontWeight: 100,
          color: theme.Copy[0],
          textAlign: "center",
        }}
      >
        {Label}
      </H3>
      <SimpleGrid cols={5} spacing="xl">
        {ThreeImg.map((IMG, i) => {
          return (
            <div
              key={i}
              style={{
                transform: `translateY(${SpringToFrom(
                  90 + 7 * i,
                  1000,
                  0,
                  "Springy100"
                )}px) translateY(${SpringToFrom(
                  DURATION - 15,
                  0,
                  1000,
                  "Springy100"
                )}px)`,
              }}
            >
              <Center>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Image
                    src={FindFileExt(IMG.IMAGE)}
                    width={IMGRATIO}
                    height={IMGRATIO}
                    radius={10}
                  />
                </Box>
              </Center>
              <P
                style={{
                  margin: "7px 0 0 0",
                  padding: "0",
                  fontWeight: 900,
                  fontSize: "2.5em",
                  lineHeight: "1.1em",
                  color: theme.Copy[2],
                }}
              >
                {IMG[VAR]}
              </P>
              <H3
                style={{
                  color: theme.Copy[0],
                  fontSize: "1.9em",
                  letterSpacing: "-1px",
                  lineHeight: ".9em",
                }}
              >
                {TrimPlayerName(IMG.Name)}
              </H3>
            </div>
          );
        })}
      </SimpleGrid>
    </>
  );
};
