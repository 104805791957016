import { useEffect, useState } from "react";
import { useFetchAssets } from "../../Hooks/Assets/useFetchAsset";
import { Container, Grid, SimpleGrid } from "@mantine/core";

import { PreviewVideoModal } from "../../Components/Global/PlayVideoModal";
import { AssetListings } from "../../Components/Global/AssetListings";
import {
  ContentContainerx3,
  VideoWizardContainer,
} from "../../Theme/Structure/BodyContainer";
import { ProductCharts } from "./Components/ProductRenderCharts";
import { UISkeletonLines } from "../../Components/Global/Skeleton";
// Product Components
import { ProductHeader } from "../../Components/interface/ProductHeader";
import { orderBy } from "lodash";
import { S } from "../../Theme/type";
import {
  CARD_VIDEO_ASSET,
  TABLE_VIDEO_ASSET,
} from "../../Components/interface/Cards/CARD_VIDEO_ASSET";
import {
  TABLE_THEAD_WRAPPER,
  TABLE_WRAPPER,
} from "../../Components/interface/Wrapper/Table";

export const AssetPlayerMilestone = () => {
  const SECTION = "Player Milestones";
  const [FindAssets, FetchAssets] = useFetchAssets();

  // Modal State
  const [opened, setOpened] = useState(false);
  const [videoURL, setVideoURL] = useState(false);
  const [OrderbyValue, setOrderbyValue] = useState({
    Key: "attributes.Name",
    DIR: "asc",
    Label: "Name",
  });
  useEffect(() => {
    FetchAssets(SECTION);
  }, []);
  useEffect(() => {}, [FindAssets]);

  const SetVideoModal = (Item) => {
    //console.log(Item.attributes.ExampleVideoURL)
    setOpened(true);
    setVideoURL(Item.attributes.ExampleVideoURL);
  };

  if (!FindAssets) return <UISkeletonLines />;
  return (
    <>
      <ProductHeader
        NavPosition="Player Milestones"
        setOrderbyValue={setOrderbyValue}
      />

      <Container size={"lg"}>
        <VideoWizardContainer>
          <S colorID={5} weight={800} align="right" underline={true}>
            Order by: {OrderbyValue.Label}
          </S>

          <TABLE_WRAPPER>
          

            <tbody>
              {orderBy(FindAssets, [OrderbyValue.Key], [OrderbyValue.DIR]).map(
                (Item, i) => {
                  //console.log(Performance)
                  return (
                    <TABLE_VIDEO_ASSET
                      key={i}
                      Item={Item}
                      SetVideoModal={SetVideoModal}
                    />
                  );
                }
              )}
            </tbody>
          </TABLE_WRAPPER>

         {/*  <SimpleGrid
            cols={1}
            breakpoints={[
              { minWidth: "xs", cols: 2 },
              { minWidth: "md", cols: 2 },
              { minWidth: "lg", cols: 3 },
            ]}
          >
            {orderBy(FindAssets, [OrderbyValue.Key], [OrderbyValue.DIR]).map(
              (Item, i) => {
                if (Item.attributes.navigation.data.attributes.Name === SECTION)
                  return (
                    <CARD_VIDEO_ASSET
                      THEMEINT={2}
                      DATA={Item}
                      key={i}
                      SetVideoModal={SetVideoModal}
                    />
                  );
              }
            )}
          </SimpleGrid> */}
        </VideoWizardContainer>
      </Container>
      <ProductCharts NavPosition="Player Milestones" />
      <PreviewVideoModal
        setOpened={setOpened}
        opened={opened}
        videoURL={videoURL}
      />
    </>
  );
};
{
  /* <Grid gutter="xl">
{
    orderBy(FindAssets,[OrderbyValue.Key],[OrderbyValue.DIR]).map((Item,i)=>{
    if(Item.attributes.navigation.data.attributes.Name === SECTION)
        return( <AssetListings Item={Item} key={i} SetVideoModal={SetVideoModal}/>)
    })
}
</Grid> */
}
