import { Image ,Input, Space,Textarea   } from '@mantine/core';

import { SelectVideoSponsor } from "../../../Components/settings/SelectVideoSponsor";

export const TournamentInput = ({handleInput,Meta})=>{
    return(
        <Input.Wrapper
            id={Meta.id}
            required={Meta.required}
            label={Meta.label}
            description={Meta.description}
            error={Meta.error}
        >
        <Input icon={Meta.ICON} placeholder={Meta.placeholder} id={Meta.id} onChange={handleInput}/>
     </Input.Wrapper>
    )
}


export const TournamentSponsorSelect = ({handleSelect,Meta})=>{
    return(
        <Input.Wrapper
            id={Meta.id}
            required={Meta.required}
            label={Meta.label}
            description={Meta.description}
            error={Meta.error}
        >
        <SelectVideoSponsor 
                        LABEL=''
                        Placeholder='Select a Title Sponsor'
                        CALLBACK={handleSelect}
                    /> 
     </Input.Wrapper>  
    )
}


export const TournamentTextArea = ({handleCopy,Meta})=>{
    return(
        <Input.Wrapper
        id={Meta.id}
        required={Meta.required}
        label={Meta.label}
        description={Meta.description}
        error={Meta.error}
    >
    <Textarea
                        placeholder="Social Media Copy"
                        id="Tournament-Name"
                        onChange={handleCopy}    
                        description="Tournament and Social media Information."
                        size="lg"
                        />
 </Input.Wrapper> 
        
    )
}