import {gql,} from "@apollo/client";


export const QUERY = (APISETTINGS)=>{
  return gql`query TOP_5 { watchLists(
    pagination: { start: 0, limit: -1 }
    filters:{id:{in:  [${APISETTINGS.IDS}]}}
      ){
        data{ 
        id
          attributes{
            STATS
          }
        }
    }
    }` 
}

/* export const Query = (APISETTINGS)=>{
    return gql`query TOP_5 {
      playerBowlings(
        pagination: { start: 0, limit: -1 }
        filters:{BOWLING_Wkts:{ gte:1 },fixture:{watch_list:{ id:{in: [${APISETTINGS.IDS}]}}}}
      ){
        data{
         id
          attributes{ 
            BOWLING_Wkts
            BOWLING_Runs
             team {
                  data {
                    id
                    attributes {
                      Name
                      TeamID
                      Logo {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                }
            }
            fixture{
                data{
                  attributes{
                    watch_list{
                      data{
                        attributes{
                          Name
                        }
                      }
                    }
                  }
                }
            }
            player{
              data{
                id
                attributes{
                  Name
                   image {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                }
              }
            }
          }
        }
    }
  }`
}
  
 */