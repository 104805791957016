import {useCurrentFrame } from 'remotion';
import { useTheme } from 'styled-components';

import { H1 } from '../../utils/Text';
import {SpringToFrom} from '../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../Animation/interpolate'


export const SponsorTagline = ({TAGLINE})=>{
    const frame = useCurrentFrame();
    const theme = useTheme();
    return(
        <div>
        <H1 style={{ margin:' 0', width:'100%', color:theme.Base[4]}}>{
            TAGLINE.split(' ').map((word,i)=>{
                return(
                    <span key={i} style={{
                            margin:'0 .1em', 
                            opacity:interpolateOpacityByFrame(frame, (45+(5*i)),(65+(5*i)), 0, 1),
                            transform: `translateY(${SpringToFrom(45,10,0,'Springy100','slow')}px)`, 
                            width:'100%'}
                        }>
                            {word}
                    </span>
                )
            })
        }</H1>
       
    </div>
    )
}