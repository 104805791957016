
import { TABLE_TD, TABLE_THEAD_WRAPPER, TABLE_WRAPPER } from "../../../../Components/interface/Wrapper/Table";
import { H3 } from "../../../../Theme/type";

export const TOP5LIST = ({ Order }) => {
    //console.log(Order)
    const LoopOBJ = (OBJ) => {
      //console.log(OBJ)
      const LIST = OBJ.map((Player, i) => {
        return (
          <tr key={i}>
            <TABLE_TD>{Player.Name}</TABLE_TD>
            <TABLE_TD>{Player.Team.Name}</TABLE_TD>
            <TABLE_TD>{Player.VARIABLE}</TABLE_TD>
          </tr>
        );
      });
      return LIST;
    };
  
    return (
      <>
        <H3>Top 5 List</H3>
  
        <TABLE_WRAPPER>
          {LoopOBJ(Order.attributes.DATA.DATAOBJ.CATEGORY)}
        </TABLE_WRAPPER>
      </>
    );
  };