import { ColoredContainer } from "../../Theme/Structure/BodyContainer";
import { S } from "../../Theme/type";
import { Box, Group } from "@mantine/core";

export const BLOCK_ToolBar = ({ TOOLS }) => {
  //console.log('Selected', Selected)
  return (
    <ColoredContainer Padding={5} BGColor={1}>
      <Group position="right">
        <Box>
          <Group position="apart">
            <S weight="900" transform={"uppercase"}>
              Options
            </S>
            {TOOLS.map((tool, i) => {
              return <span key={i}>{tool}</span>;
            })}
          </Group>
        </Box>
      </Group>
    </ColoredContainer>
  );
};
