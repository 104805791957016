import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuthUser } from "../../Hooks/Auth/useAuthUserMe";
import { useCreateTournament } from "../../Hooks/Content/useTournaments";

import { Center, Container, Group, Image, Space } from "@mantine/core";
import {
  VideoWizardContainer,
  BodyContainer,
  ContentContainerx3,
} from "../../Theme/Structure/BodyContainer";

import { BTN_CREATE } from "../../Theme/Structure/Button/BTNS";
import { H3, H1, P } from "../../Theme/type";

import DropZone from "../../Components/Global/DropZone";
import { IconTag } from "@tabler/icons";
import { UISkeletonLines } from "../../Components/Global/Skeleton";
import { IsLoading } from "../../Components/Global/isLoadingDefault";
import { SelectVideoSponsor } from "../../Components/settings/SelectVideoSponsor";
import { SelectMultipleDates } from "../../Components/settings/VideoPickDate";
import moment from "moment";
// Form Elements
import {
  TournamentInput,
  TournamentSponsorSelect,
  TournamentTextArea,
} from "./components/FormElements";

// UI ELEMENTS
import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";
import { BLOCK_Section_SubHeading } from "../../Components/interface/BLOCK_Section_SubHeading";

export const CreateNewTournament = ({ setCreate }) => {
  const [Tournament, CreateTournament] = useCreateTournament();

  const [authUser, FetchauthUser] = useAuthUser();

  const AUTH = useSelector((state) => state.AUTH);
  const [Name, setName] = useState("");
  const [Year, setYear] = useState("");
  const [Tagline, setTagline] = useState("");
  const [URL, setURL] = useState("");
  const [InfoURL, setInfoURL] = useState("");
  const [Sponsor, setSponsor] = useState("");
  const [Location, setLocation] = useState("");
  const [Dates, setDates] = useState("");
  const [COPY, setCOPY] = useState("");
  const [disabled, setdisabled] = useState(true);
  const [DataSent, setDatasent] = useState(false);
  const [ImageFile, setImageFile] = useState(false);

  const handleYear = (e) => {
    setYear(e.target.value);
  };
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleTagline = (e) => {
    setTagline(e.target.value);
  };
  const handleLocation = (e) => {
    setLocation(e.target.value);
  };
  const handleURL = (e) => {
   
    setURL(e.target.value);
  };
  const handleInfoURL = (e) => {
    setInfoURL(e.target.value);
  };
  const handleCopy = (e) => {
    setCOPY(e.target.value);
  };
  const handleSponsor = (e) => {
    setSponsor(e.id);
  };
  const handleDates = (e) => {
    setDates(e);
  };

  const handleSubmit = () => {
    setDatasent(true);
    CreateTournament({
      Name: Name,
      Tagline: Tagline,
      Year: Year,
      Location: Location,
      Infomation: COPY,
      URL: URL,
      LMSPAGE: InfoURL,
      users_permissions_user: AUTH.AUTHUSER.id,
      LOGO: ImageFile ? [ImageFile.id] : ImageFile,
      sponsor: [Sponsor],
      START: Dates[0],
      END: Dates[1],
      PATH:`play-cricket/t20/fixtures/city?city=${Name}`
    });
  };

  useEffect(() => {
    if (Name.length != 0 && Tagline.length != 0) {
      setdisabled(false);
    }
  }, [Name, Tagline, URL]);

  useEffect(() => {
    if (Tournament) {
      FetchauthUser();
    }
  }, [Tournament]);

  useEffect(() => {
    if (authUser) {
      setDatasent(false);
      setCreate(false);
    }
  }, [authUser]);

  if (DataSent) return <IsLoading />;
  return (
    <Container>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Request a new Tournament",
          ICON: "Tournament",
          DESCRIPTION: "Make a request for a Tournament Event.",
          TOOLBAR: false,
        }}
      />

      <BLOCK_Section_SubHeading LABEL="General Information" Color={5} />

      <VideoWizardContainer DividerSize={0} Padding="xs">
        <TournamentInput
          handleInput={handleName}
          Meta={{
            id: "Tournament-Name",
            required: true,
            label: "Tournament Name",
            description: "",
            error: "A Tournament Name is Required",
            placeholder: "Tournament Name",
            ICON: <IconTag />,
          }}
        />

        <TournamentInput
          handleInput={handleTagline}
          Meta={{
            id: "Tournament-tagline",
            required: false,
            label: "Tournament Tagline",
            description: "",
            error: "",
            placeholder: "Tag Line",
            ICON: <IconTag />,
          }}
        />

        <TournamentInput
          handleInput={handleYear}
          Meta={{
            id: "Tournament-year",
            required: false,
            label: "What year is this Tournament ",
            description: "",
            error: "",
            placeholder: "2022",
            ICON: <IconTag />,
          }}
        />

        <TournamentInput
          handleInput={handleLocation}
          Meta={{
            id: "Tournament-location",
            required: false,
            label: "What city is this Tournament located?",
            description: "",
            error: "",
            placeholder: "Sydney",
            ICON: <IconTag />,
          }}
        />
      </VideoWizardContainer>

      <BLOCK_Section_SubHeading LABEL="Select the Tournament Dates" Color={5} />

      <VideoWizardContainer DividerSize={0} Padding="xs">
        <Group position="apart">
          <SelectMultipleDates setFromDate={handleDates} />
          <H3 weight="400" align="center" colorID={5}>
            {moment(Dates[0]).format("dddd DD MMMM")} -{" "}
            {moment(Dates[1]).format("dddd DD MMMM")}
          </H3>
        </Group>
      </VideoWizardContainer>

      <BLOCK_Section_SubHeading LABEL="LMS Information" Color={5} />
      <VideoWizardContainer DividerSize={0} Padding="xs">
        <TournamentInput
          handleInput={handleInfoURL}
          Meta={{
            id: "Tournament-informationurl",
            required: false,
            label: "Tournament Information URL",
            description: "Provide the Lastmanstands.com URL that links to the Home page for this Tournament. e.g. https://www.lastmanstands.com/australiachamps/ ",
            error: "",
            placeholder: "https://www.lastmanstands.com/australiachamps/",
            ICON: <IconTag />,
          }}
        />
        <TournamentInput
          handleInput={handleURL}
          Meta={{
            id: "Tournament-fixturesurl",
            required: false,
            label: "Tournament Fixtures and Results URL",
            description: "** IMPORTANT ** Provide the URL to the FIXTURES page for this tournament. An incorrect URL may result in the delay of a tournament being approved! e.g. https://www.lastmanstands.com/leagues/standings/t20?leagueid=551&seasonid=97&divisionid=0",
            error: "",
            placeholder: "https://www.lastmanstands.com/leagues/standings/t20?leagueid=551&seasonid=97&divisionid=0",
            ICON: <IconTag />,
          }}
        />
      </VideoWizardContainer>

      <BLOCK_Section_SubHeading LABEL="Tournament Logo" Color={5} />
      <VideoWizardContainer DividerSize={0} Padding="xs">
        {!ImageFile ? (
          <DropZone setImageFile={setImageFile} />
        ) : (
          <SponsorLogo ImageFile={ImageFile} />
        )}
      </VideoWizardContainer>
      <BLOCK_Section_SubHeading
        LABEL="Tournament Sponsors and Social Media Copy"
        Color={5}
      />
      <VideoWizardContainer DividerSize={0} Padding="xs">
        <TournamentSponsorSelect
          handleSelect={handleSponsor}
          Meta={{
            id: "Tournament-sponsor",
            required: false,
            label: "Select a Title Sponsor",
            description: "",
            error: "",
            placeholder: "Select a Title Sponsor",
            ICON: <IconTag />,
          }}
        />

        <TournamentTextArea
          handleCopy={handleCopy}
          Meta={{
            id: "Tournament-sponsor",
            required: false,
            label: " Social Media Copy",
            description: "",
            error: "",
            placeholder: "Tournament Information",
            ICON: <IconTag />,
          }}
        />

        <Space h="md" />

        <Space h="md" />
        <BTN_CREATE
          CALLBACK={handleSubmit}
          LABEL={`Send Tournament Request`}
          DISABLED={disabled}
          TIPOBJ={{
            LABEL: "Send Tournament Request",
            COLOR: "green",
            POSITION: "top",
          }}
        />
      </VideoWizardContainer>
    </Container>
  );
};

const SponsorLogo = ({ ImageFile }) => {
  return (
    <>
      <Image
        radius="md"
        width={120}
        src={ImageFile.url}
        alt="Player or Team image Missing"
      />
    </>
  );
};
