import {
  TextInput,
  Textarea,
  SimpleGrid,
  Group,
  Title,
  Button,
  Container,
  createStyles,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { parseCookies,setCookie } from 'nookies'
import { useEffect, useState } from "react";
import { useCreateFreeTrial } from "../../Hooks/Content/useContactForm";
import { P } from "../../Theme/type";
import { SectionWrapper } from "../Components/Containers";
const useStyles = createStyles((theme) => ({}));

export function GetInTouchSimple() {
  const { classes, theme } = useStyles();
  const [showForm, setShowForm] = useState(true)
  const [FreeTrial,CreateFreeTrial] = useCreateFreeTrial()
  const [hasCookie, setHasCookie] = useState(parseCookies().hasAppliedForFreeTrial)

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      Franchise: "",
      message: "",
    },
    validate: {
      name: (value) => value.trim().length < 2,
      email: (value) => !/^\S+@\S+$/.test(value),
      Franchise: (value) => value.trim().length === 0,
    }, 
  });


const SubmitForm = ()=>{
  console.log(form.values)
  setShowForm(false)
  CreateFreeTrial({
    Name: form.values.name,
    Email: form.values.email,
    Franchise: form.values.Franchise,
  })
} 

useEffect(()=>{
  console.log(parseCookies().hasAppliedForFreeTrial)
  if(FreeTrial){
    setCookie(null, 'hasAppliedForFreeTrial', true , {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
    })
  }
  

},[FreeTrial])

if(hasCookie)
  return <AlreadyApplied />
if(!showForm)
return(<FormSent values={form.values} FreeTrial={FreeTrial}/>)

  return (
    <SectionWrapper BGCOLOR={1}>
      <Container size={"md"} py={90}>
        <form onSubmit={form.onSubmit(() => {
          SubmitForm()
        })}>
          <Title
            order={2}
            size="h1"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            })}
            weight={900}
            align="center"
            transform="uppercase"
            color={theme.colors.Public[0]}
          >
            Free Trial?
          </Title>
          <P align="center" colorID={0}>
            Apply for a free ONE MONTH trial of Statto Social.<br /> 
            Fill in the
            form below with your LMS details and we will set everything up for
            you
          </P>

          <SimpleGrid
            cols={2}
            mt="xl"
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <TextInput
              styles={{ label: { color: "white" } }}
              label="Name"
              placeholder="Your name"
              name="name"
              variant="filled"
              {...form.getInputProps("name")}
            />
            <TextInput
              styles={{ label: { color: "white" } }}
              label="LMS Email"
              placeholder="Your email"
              name="email"
              variant="filled"
              {...form.getInputProps("email")}
            />
          </SimpleGrid>

          <TextInput
            styles={{ label: { color: "white" } }}
            label="LMS Franchise Name"
            placeholder="Franchise Name"
            mt="md"
            name="Franchise"
            variant="filled"
            {...form.getInputProps("Franchise")}
          />
         
         

          <Group position="center" mt="xl">
            <Button
              type="submit"
              size="md"
              variant="gradient"
              gradient={{ from: "blue", to: "green" }}
            >
              Apply
            </Button>
          </Group>
          <P size={"xs"} align="center" colorID={4}>
            Statto Social is for Lastman stands Franchise owners and Franchise
            Management groups ONLY! we verify all enquiries through LMS channels
          </P>
        </form>
      </Container>
    </SectionWrapper>
  );
}

const AlreadyApplied = ()=>{
  const { classes, theme } = useStyles();
  return(
    <SectionWrapper BGCOLOR={0}>
    <Container size={"md"} py={90}>
    <Title
            order={2}
            size="h1"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            })}
            weight={900}
            align="center"
            transform="uppercase"
            color={theme.colors.Public[1]}
          >
            Already Applied
          </Title>
          <P align="center" colorID={3}>
          It looks like you've already requested a free trial. If this isn't the case, try clearing your cookies to see the form.
          </P>
    </Container></SectionWrapper>
  )
}


const FormSent = ({values, FreeTrial})=>{
  console.log(values)
  return(
    <SectionWrapper BGCOLOR={FreeTrial ?2:3}>
    <Container size={"md"} py={90}>
      {
      FreeTrial ? <MSG_FormSent />:<MSG_FormProcessing />
      }
     
    </Container></SectionWrapper>
  )
}


const MSG_FormProcessing=()=>{
  const { classes, theme } = useStyles();
  return(
    <>
    <Title
            order={2}
            size="h1"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            })}
            weight={900}
            align="center"
            transform="uppercase"
            color={theme.colors.Public[0]}
          >
            Processing Request
          </Title>
          <P align="center" colorID={0}>
          Statto is processing your request!
          </P>
          </>
  )
}


const MSG_FormSent=()=>{
  const { classes, theme } = useStyles();
  return(
    <>
    <Title
            order={2}
            size="h1"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            })}
            weight={900}
            align="center"
            transform="uppercase"
            color={theme.colors.Public[0]}
          >
            Form Sent
          </Title>
          <P align="center" colorID={0}>
          Statto will confirm the information received and be in touch with your free trial credentials shortly.
          </P>
          </>
  )
}