import { useEffect, useState } from "react";
import {
  Image,
  Center,
  Grid,
  Loader,
  ScrollArea,
  Card,
  Box,
  Group,
  Modal,
} from "@mantine/core";

import {
  useAssignNewDefaultLOGO,
  useAssignNewTeamPhoto,
  useDeleteTeamImage,
} from "../../../Hooks/Utils/useUploadTeamImages";

import { useFindSingleTeam } from "../../../Hooks/Content/useFindTeam";

import { H3 } from "../../../Theme/type";
import { BTN_ICON_CALLBACK } from "../../../Theme/Structure/Button/BTNS";

import { ColoredContainer } from "../../../Theme/Structure/BodyContainer";
// UI ELEMENTS
//import {BLOCK_Section_Title} from '../../../Components/interface/BLOCK_Section_Title'
import { BLOCK_Section_SubHeading_INVERT } from "../../../Components/interface/BLOCK_Section_SubHeading";
// Player Gallery
export const TeamFullGallery = ({ SelectedTeam, setSelectedTeam }) => {
  const GALLERY = SelectedTeam?.attributes.ImageGallery.data;

  const [TeamLogo, AssignTeamLogo] = useAssignNewDefaultLOGO();
  const [TeamPhoto,AssignTeamPhoto] = useAssignNewTeamPhoto()

  const [TeamName, FindTeamName] = useFindSingleTeam();

  const [isAssignNew, setisAssignNew] = useState(false);
  const [isRemoveImage, setisRemoveImage] = useState(false);
  const [Modalopened, setModalOpened] = useState(false);
  const [FullImage, setFullImage] = useState(false);

  const AssignNewDefault = (_ID, _PLAYER) => {
    setisAssignNew(true); 
    AssignTeamLogo(_ID, _PLAYER); 
  };
  const AssignNewTeamPhoto = (_ID, _PLAYER) => {
    setisAssignNew(true); 
    AssignTeamPhoto(_ID, _PLAYER); 
  };

  const RemoveImage = (_ID, _PLAYER) => {
    setisRemoveImage(_ID);
  };

  useEffect(() => {
    if (TeamLogo !== false || TeamPhoto !== false) {
      FindTeamName(SelectedTeam.id);
    }
  }, [TeamLogo,TeamPhoto]);

  useEffect(() => {
    //console.log("TeamName", TeamName)
    if (TeamName !== false) {
      setisAssignNew(false);
      setSelectedTeam(TeamName);
    }
  }, [TeamName]);

  useEffect(() => {}, [SelectedTeam]);

  if (GALLERY === null)
    return (
      <BLOCK_Section_SubHeading_INVERT
        LABEL="Team Gallery is currently empty"
        Color={6}
      />
    );
  return (
    <>
      <ScrollArea style={{ height: 510 }} type="auto" scrollbarSize={4}>
        <Grid gutter="0">
          {GALLERY.map((IMG, i) => {
            return (
              <Grid.Col span={6} md={4} mb={20} lg={3} xl={2} key={i}>
                <Card
                  shadow="xl"
                  p={0}
                  radius="md"
                  withBorder
                  sx={(theme) => ({
                    overflow: "visible",
                    position: "relative",
                    backgroundColor: theme.colors.brand[0],
                    "&:hover": {
                      backgroundColor: theme.colors.brand[1],
                      color: theme.colors.brand[0],
                    },
                  })}
                >
                  {isRemoveImage === IMG.id ? (
                    <ConfirmRemoveImage
                      RemoveImage={RemoveImage}
                      IMG={IMG}
                      setSelectedTeam={setSelectedTeam}
                      SelectedTeam={SelectedTeam}
                    />
                  ) : (
                    <Card.Section>
                      <Image
                        radius="xs"
                        height={180}
                        src={IMG.attributes.url}
                        alt="Team Image"
                      />
                    </Card.Section>
                  )}
                </Card>
                <Box
                  style={{
                    padding: "5px",
                  }}
                >
                  {!isAssignNew && isRemoveImage != IMG.id ? (
                    <CTABTN
                      AssignNewDefault={AssignNewDefault}
                      AssignNewTeamPhoto={AssignNewTeamPhoto}
                      RemoveImage={RemoveImage}
                      IMG={IMG}
                      SelectedTeam={SelectedTeam}
                      isRemoveImage={isRemoveImage}
                      Modalopened={Modalopened}
                      setModalOpened={setModalOpened}
                      setFullImage={setFullImage}
                    />
                  ) : (
                    <Center>
                      <Loader color="teal" size="xs" variant="bars" />
                    </Center>
                  )}
                </Box>
              </Grid.Col>
            );
          })}
        </Grid>
      </ScrollArea>
      <ImageModal
        Modalopened={Modalopened}
        setModalOpened={setModalOpened}
        FullImage={FullImage}
      />
    </>
  );
};

const ConfirmRemoveImage = ({
  RemoveImage,
  IMG,
  SelectedTeam,
  setSelectedTeam,
}) => {
  const [DeleteImage, processDeleteImage] = useDeleteTeamImage();

  const [TeamName, FindTeamName] = useFindSingleTeam();
  const [isAssignNew, setisAssignNew] = useState(false);

  const handleDelete = () => {
    //console.log('delete')
    setisAssignNew(true);
    processDeleteImage(IMG, SelectedTeam);
  };

  useEffect(() => {
    //console.log(DeleteImage)
  }, [DeleteImage]);

  useEffect(() => {
    if (DeleteImage !== false) {
      FindTeamName(SelectedTeam.id);
    }
  }, [DeleteImage]);

  useEffect(() => {
    if (TeamName !== false) {
      setisAssignNew(false);
      setSelectedTeam(TeamName);
    }
  }, [TeamName]);

  return (
    <>
      <Group position="center"
        style={{
          position:'absolute',
          zIndex:1000,
            bottom:'5px',
            width:'100%'
        }}
      >
        <H3 colorID={6}>
          <BTN_ICON_CALLBACK
            BG={0}
            COLOR={6}
            CALLBACK={() => {
              RemoveImage(false);
            }}
            ICON="CircleX"
            TIPOBJ={{
              LABEL: "Cancel Delete Image",
              COLOR: "red",
              POSITION: "top",
            }}
          />
        </H3>
        <BTN_ICON_CALLBACK
          BG={0}
          COLOR={7}
          CALLBACK={() => {
            handleDelete(false);
          }}
          ICON="CircleCheck"
          TIPOBJ={{
            LABEL: "Confirm Delete Image",
            COLOR: "green",
            POSITION: "top",
          }}
        />
      </Group>
   
        {!isAssignNew ? (
          <Image
            height={180}
            src={IMG.attributes.url}
            style={{filter: 'blur(2px)'}}
            alt="Remove Image"
          />
        ) : (
          false
        )}
     
    </>
  );
};

const CTABTN = ({
  AssignNewDefault,
  AssignNewTeamPhoto,
  RemoveImage,
  IMG,
  SelectedTeam,
  isRemoveImage,
  Modalopened,
  setModalOpened,
  setFullImage,
}) => {
  if (
    isRemoveImage === IMG.id ||
    IMG.id === SelectedTeam.attributes.Logo.data?.id
  )
    return (
      <Center>
        <BTN_ICON_CALLBACK
          BG={0}
          COLOR={3}
          CALLBACK={() => {}}
          ICON="MoodSmile"
          TIPOBJ={{
            LABEL: "Team Profile Image",
            COLOR: "green",
            POSITION: "top",
          }}
        />
      </Center>
    );
  return (
    <Group position="center" gutter='xs'>
        {/* <BTN_ICON_CALLBACK
        BG={0}
        COLOR={6}
        CALLBACK={() => {
          setModalOpened(!Modalopened)
          setFullImage(IMG.attributes.url)
        }}
        ICON="Eye"
        TIPOBJ={{
          LABEL: "View Full Image",
          COLOR: "blue",
          POSITION: "top",
        }}
      /> */}
      <BTN_ICON_CALLBACK
        BG={0}
        COLOR={8}
        CALLBACK={() => {
          AssignNewTeamPhoto(IMG.id, SelectedTeam.id);
        }}
        ICON="Users"
        TIPOBJ={{
          LABEL: "Select as Team Image", 
          COLOR: "blue",
          POSITION: "top",
        }}
      />
       <BTN_ICON_CALLBACK
        BG={0}
        COLOR={8}
        CALLBACK={() => {
          AssignNewDefault(IMG.id, SelectedTeam.id);
        }}
        ICON="Photo"
        TIPOBJ={{
          LABEL: "Select as Team Logo", 
          COLOR: "green",
          POSITION: "top",
        }}
      />
      <BTN_ICON_CALLBACK
        BG={0}
        COLOR={6}
        CALLBACK={() => {
          RemoveImage(IMG.id, SelectedTeam.id);
        }}
        ICON="Trash"
        TIPOBJ={{
          LABEL: "Remove Image",
          COLOR: "red",
          POSITION: "top",
        }}
      />
    </Group>
  );
};

const ImageModal = (props) => {
  const { Modalopened, setModalOpened, FullImage } = props;
  return (
    <Modal
      withCloseButton={false}
      centered
      size="lg"
      overlayColor={"#4A5060"}
      overlayOpacity={0.9}
      overlayBlur={3}
      transition="fade"
      padding="0"
      shadow="xl"
      opened={Modalopened}
      onClose={() => setModalOpened(false)}
    >
      {/* Modal content */}
      <Image src={FullImage} />
    </Modal>
  );
};
