import styled from 'styled-components';

/* Baseline COntainers*/

export  const ContainerBasicFlexColumn = styled.div`
position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
`
const ContainerBasicFlexRow = styled.div`
position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
`

export const BodyContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background:${props => props.theme.BackgroundGradient} 
`

export const FullWidthWrapper = styled.div` 
    width:100%;
    height:100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
`
export const FullWidthWrapperStart = styled.div` 
    width:100%;
    height:100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
`

export const IntroContainer = styled.div`
    width:100%;
    height:170px;
    position: relative;
    z-index:300;
 
`
export const LogoContainer = styled.div`
    width:100%;
    position: relative;
    z-index:300;
    display:flex;
    justify-content: center;
    align-items: center;
    align-content: center;
`
export const LogoInnerContainer = styled.div`
width: 400px;
height:400px;
z-index:100;
filter: drop-shadow(-2px 6px 11px rgba(0, 0, 0, 0.5));
`
export const EndTitleContainer = styled.div`
position: absolute;
left: 0px;
bottom:150px;
height:150px;
width:100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
align-content: center;
`

/** FROSTED */
export const FrostedContainer = styled.div`
    position: absolute; 
    width: 100%; 
    height: 100%;
    left: 0px;
    top: 0px;
    
    background: ${props => props.theme.radialGradient };
    box-sizing: border-box;
    backdrop-filter: blur(1px);
    z-index:1;
`
export const FrostedItem = styled.div`
    width:  100%;
    height: 100%;
    position: absolute;
    background: ${props => props.theme.radialGradient };
    border: 1px solid rgba(255, 255, 255, 0.5); 
    box-sizing: border-box;
    backdrop-filter: blur(1px);
    border-radius: 10px;`
    
    export const FrostedPOTD = styled(FrostedItem)`
    width: 1025px;
    height: 280px;
    left: -60px;
    bottom: -0px;
    backdrop-filter: blur(15px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index:500;`

    export const LeaderLiFrosted = styled(FrostedItem)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;
    flex-direction: row;
`
export const LeaderLiNonFrosted = styled(ContainerBasicFlexRow)`justify-content: space-between;`
/* SECTIONS*/

export const SeactionTitleContainer = styled.div`
width: 96%;
display: flex;
    justify-content: center;
    align-items: center;
    margin:2%;
`

export const TeamNamesContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    width: 78%;
    margin: 0px 10%;`


export const TeamLogoContainer = styled.div`
      position: absolute;
      width: 150px;
      height: 150px;
      top: 13px;
      z-index:10;
      border-radius: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
`
export const TeamLogoRight = styled(TeamLogoContainer)`
right: -50px;
`
export const TeamLogoLeft = styled(TeamLogoContainer)`
left: -50px;
`

export const MetaBelowBottom = styled.div`
position: absolute;
width: auto;
height: auto;
bottom: -60px;
font-family: 'Arial';
font-style: normal;
font-weight: 500;
font-size: 25px;
line-height:25px;
text-align: center;
letter-spacing: -1px;
text-transform: uppercase;
color: ${props => props.theme.Base[4]}; 
margin:0;
padding:0;
z-index:10;`

export const MetaRight= styled(MetaBelowBottom)`right: 0;`
export const MetaLeft= styled(MetaBelowBottom)`left: 0;`
export const ResultStatment = styled(MetaBelowBottom)`
    width: 100%;
    left: 0;
    bottom: -30px;
    color: ${props => props.theme.Copy[2] };
    letter-spacing: -0.01em;
`
export const HighlightStatment = styled.p`
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: -30px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height:30px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin:0;
    padding:1px 0;
    color: ${props => props.theme.Copy[1] };
    background-color: ${props => props.theme.Base[2]};    
    z-index:10;`

export const MOMContainer = styled(ContainerBasicFlexColumn)`
    position: absolute;
    right:0;
    width:360px;
    height:150px;
    text-align:left;
`
export const MOMInnerContainer = styled.div`
z-index: 100;
width: 90%;
margin-right: 100px;
`

export const PlayerPerformances = styled.div`
    position: absolute;
    left:0px;
    width:450px;
    height:170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    padding:10px 0 ;
    background-color: ${props => props.theme.Base[5]};
    padding: 5px 15px 5px 5px;
    filter: drop-shadow(7px 0px 4px rgba(0, 0, 0, 0.25));
    z-index:1000;
    top: 5px;
`

export const PerformanceContainer = styled(ContainerBasicFlexRow)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: center;
    padding-bottom:3px;
`

/**POTD */

export const POTDWRAPPER = styled.div`
width:1080px;
height:1350px;
background-repeat: no-repeat;
background-position: center;
background-size: 100%;
`
export const HONORABLEMENTIONS =  styled(ContainerBasicFlexColumn)`
justify-content: space-between;
width: 100%;
left: 0px;
top: 5px;
margin:0;`

export const POTDImageBox = styled.div`
margin-top:-150px;
width: 150px;
height: 150px;

background-repeat: no-repeat;
background-position: center;
background-size: cover;
border-radius:100%
`
export const HonerableImageBox = styled.div`
position: absolute;
width:60px;
height: 60px;
left: 5px;
top: 2px;
background-size: cover;
border-radius: 100%;

`

/* POTD League Ladder*/

export const LeagueTeamLogo = styled.div`
width: 120px;
height: 120px;
margin-left:-30px;
z-index:10;
background-color:#fff;
border-radius: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`
export const SmallContainer = styled.div`
width: 10%;
`


/* TOP 5 COntianers*/

export const Top5InnerContainer = styled.div`
                    display: flex;
                    alignItems: center;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    padding: 0;
                    zIndex: 100;
                    
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
        `

export const ValueLabel = styled.div`
    position: absolute;
    background-color: ${props => props.theme.Base[2]};
    width: 450px;
    height: 100px;
    top: 125px;
    right: 0;
    display: flex;
    justify-content: center;
    border-radius: 2%;
    align-items: center;
    z-index: 10000;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
    `

export const PositionContainer = styled.div`
position: absolute;
border-top: 20px solid ${props => props.theme.Base[2]}; 
width: 100px;
height: 125px;
bottom: 0;
z-index: 100;
background: ${props => props.theme.Base[2]};;
border-radius: 2%;
display: flex;
justify-content: center;
align-items: flex-start;
left: 5px;
padding-top: .5em;
box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
`
export const PlayerImage = styled.div`
    left: 220px;
    bottom:100px;
    width: 650px;
    height: 1000px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    border-radius: 1%;
`
export const Top5TeamLogo = styled.div`
left: 450px;
bottom: 170px;
width: 150px;
height: 150px;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
position: absolute;
border-radius: 100%;
z-index:1000;`

export const NameContainer = styled.div`
    width: 100%;
    margin: 120px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 160px;
    z-index: 100;
    position: absolute;
    bottom: 40px;` 