
import { useEffect, useState } from "react" 
import {ENV} from '../../Config/RunTimeConfig'
import { useDispatch } from 'react-redux'

import { parseCookies,setCookie } from 'nookies'
import {setAuthUSer} from '../../store/Reducer/AUTH';

// Stack API routes for preloading
import {useGetFranchises} from "../Content/useFranchises"
import {useGetUserInvoices} from "../Content/useInvoices"


const qs = require('qs');
export const useAuthUser = (ctx)=>{
    const [authUser,setauthUser] = useState(null)
    // Preload
    const [Franchises,FetchFranchises] = useGetFranchises()
    const [invoices,FetchUserInvoices] = useGetUserInvoices()

 
    const dispatch = useDispatch()
    const jwt = parseCookies(ctx).jwt 

    const FetchauthUser = async ()=>{
        setauthUser(null)
        const query = qs.stringify({
            populate: ['user_video_setting','theme','franchise','template', 'subscriptions','subscriptions.franchise','subscriptions.stripe_payment_products','subscriptions.stripe_payment_products.Image'], 
         
          }, {
            encodeValuesOnly: true,
          });

        const res = await fetch(`${ENV.API_URL}/api/users/me?${query}`, {
            headers: {
                Authorization: `Bearer ${jwt}` 
            }
        }) 
        const loginResponse = await res.json(); 
       console.log('loginResponse',loginResponse)
        if(loginResponse=== null){
            setauthUser(false) 
           }else{
            
            dispatch(setAuthUSer(loginResponse)) 
            setCookie(null, 'AUTHID', loginResponse.id , {
                maxAge: 30 * 24 * 60 * 60,
                path: '/',
            })
            setauthUser(loginResponse.id) 
        }
    }

    useEffect(()=>{
        if(authUser !== null || authUser !== false){
            FetchFranchises() 
            FetchUserInvoices()
        }
       
       
    },[authUser])
    return[authUser,FetchauthUser]
}