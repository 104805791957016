/* eslint-disable react/jsx-pascal-case */
import { useSelector } from "react-redux";
import {
  useFindNullFixtures,
} from "../../Hooks/Auth/useFindNullFixtures";

import { useEffect, useState } from "react";

import { BLOCK_Section_SubHeading } from "../../Components/interface/BLOCK_Section_SubHeading";
import { UISkeletonLines } from "../../Components/Global/Skeleton";

import { AccessDenied } from "../../Components/interface/AccessDenied";
import { BTN_CTA } from "../../Theme/Structure/Button/BTNS";
import { BLOCK_ToolBar } from "../../Components/interface/BLOCK_Toolbar";
import { VideoWizardContainer } from "../../Theme/Structure/BodyContainer";
import { P } from "../../Theme/type";

export const RemoveNullItems = () => {
  const [FindNullFixtures, DeleteFindNullFixtures] = useFindNullFixtures();

  const [isSyncing, setSync] = useState(false);

  //const UXSTATE = useSelector((state) => state.UXSTATE);
  const AUTH = useSelector((state) => state.AUTH);

  console.log(AUTH.AUTHUSER.id);

  const FindNull = () => {
    //console.log('Find Null OBJS');
    DeleteFindNullFixtures();
    setSync(true); 
  };
  useEffect(() => {
    console.log(FindNullFixtures);
    if(FindNullFixtures !== null){setSync(false);}
    setSync(false);
  }, [FindNullFixtures]);



  if (isSyncing) return <UISkeletonLines />;
  if (AUTH.AUTHUSER.id !== 1)
    return <AccessDenied />;
  return (
    <VideoWizardContainer>
          <BLOCK_Section_SubHeading LABEL="Find Null" Color={5} />
          <P>Find and remove all Fixtures that return a null value.</P>
          <BLOCK_ToolBar 
            TOOLS={[<BTN_CTA CALLBACK={FindNull} LABEL={"Find Null"} />]}
          />
        </VideoWizardContainer>
  );
};






