import {useCurrentFrame,Img } from 'remotion';

import {SpringToFrom} from '../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../Animation/interpolate';

// <TeamLogoMain Logo={} Durations={} />
export const TeamLogoSmall = ({Logo,Durations})=>{
    const frame = useCurrentFrame();
    return(
        <div
            style={{
                    position:'absolute',
                    right:'-80px',
                top: '120px',
                width:'185px',
                height:'185px',
                overflow:'hidden',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                display: 'flex',
                backgroundColor:'white',
                borderRadius:'1000px',
                opacity:interpolateOpacityByFrame(frame, 60,70, 0,1),
                transform: `translateX(${SpringToFrom(Durations.TransitionAfter,150,-100)}px) translateX(${SpringToFrom((Durations.Close-Durations.Offset),0,450)}px)`,
            }}
        >
            <Img  
                src={Logo}
                style={{
                    //position:'absolute',
                    width:'185px',
                   
                    zIndex:500,
                    //borderRadius:'1000px',
                   
                }}
            />
        </div>
    )
} 