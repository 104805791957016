/* eslint-disable react/jsx-pascal-case */
// HOOKS

import { useGetGroupOfLeagues } from "../../../Hooks/Content/useLeagues";
// Actions
import { MergeTop5APICallBack } from "../../../actions/EvaluationUtils";

import { Container } from "@mantine/core";

import { ICONDB } from "../../../Theme/icons";
import { orderBy, meanBy, sumBy } from "lodash";
// UI Elements

import { useEffect, useState } from "react";
import {
  RingProgress,
  Text,
  SimpleGrid,
  Paper,
  Center,
  Group,
} from "@mantine/core";
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";

export const SeasonBattingAverages = ({ SeasonID }) => {
  const [getLeagues, setgetLeagues] = useGetGroupOfLeagues();
  const [DATA, setDATA] = useState([]);
  const averageOf = [
    "BATTING_Runs",
    "BATTING_Balls",
    "BATTING_fours",
    "BATTING_sixes",
  ];

  const FetchPlayerStats = (DATA) => {
    const ARR = [];
    DATA.attributes.watch_lists.data.map((WL, i) => {
    // console.log(WL);
      ARR.push(WL.id);
    });
    setgetLeagues(ARR);
  };

  useEffect(() => {
    if (SeasonID !== false) FetchPlayerStats(SeasonID);
  }, [SeasonID]);

  useEffect(() => {
    if (getLeagues) {
      let ITEM = orderBy(
        MergeTop5APICallBack(getLeagues, "Name"),
        ["Name"],
        ["asc"]
      );
    // console.log(ITEM);
      setDATA(ITEM);
    }
  }, [getLeagues]);
  if (DATA.length === 0) return false;
  return (
    <Container size={"lg"}>
      
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Overview",
          ICON: "ChartDots",
          DESCRIPTION: "",
          TOOLBAR: false,
        }}
      />
      <SimpleGrid cols={4} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
        {averageOf.map((item, i) => {
          return (
            <Paper withBorder radius="md" p="xs" key={item}>
              <Group>
                <RingProgress
                  size={60}
                  roundCaps
                  thickness={4}
                  sections={[
                    {
                      value:
                        sumBy(DATA, (p) => p[item]) /
                        meanBy(DATA, (p) => p[item]),
                      color: "teal",
                    },
                  ]}
                  label={<Center>{ICONDB.Cricket.Component}</Center>}
                />

                <div>
                  <Text
                    color="dimmed"
                    size="xs"
                    transform="uppercase"
                    weight={700}
                  >
                    {item}
                  </Text>
                  <Text weight={700} size="md">
                    Total:{sumBy(DATA, (p) => p[item]).toFixed(0)}
                  </Text>
                  <Text weight={700} size="md">
                    AVG: {meanBy(DATA, (p) => p[item]).toFixed(2)}
                  </Text>
                </div>
              </Group>
            </Paper>
          );
        })}
      </SimpleGrid>
    </Container>
  );
};

/* 
interface StatsRingProps {
  data: {
    label: string;
    stats: string;
    progress: number;
    color: string;
    icon: 'up' | 'down';
  }[];
}

const icons = {
  up: IconArrowUpRight,
  down: IconArrowDownRight,
};

export function StatsRing({ data }: StatsRingProps) {
  const stats = data.map((stat) => {
    const Icon = icons[stat.icon];
    return (
      <Paper withBorder radius="md" p="xs" key={stat.label}>
        <Group>
          <RingProgress
            size={80}
            roundCaps
            thickness={8}
            sections={[{ value: stat.progress, color: stat.color }]}
            label={
              <Center>
                <Icon size={22} stroke={1.5} />
              </Center>
            }
          />

          <div>
            <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
              {stat.label}
            </Text>
            <Text weight={700} size="xl">
              {stat.stats}
            </Text>
          </div>
        </Group>
      </Paper>
    );
  });
  return (
    <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
      {stats}
    </SimpleGrid>
  );
} */
