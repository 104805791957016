import { useState, useEffect } from "react";

import { BTN_CTA } from "../../../Theme/Structure/Button/BTNS";
import { InputFindAPlayer } from "../../settings/InputFindAPlayer";
import { H3 } from "../../../Theme/type";
import {
  VideoWizardContainer,
} from "../../../Theme/Structure/BodyContainer";
import { Image, Group, Stack, Box } from "@mantine/core";
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
export const SelectAPlayer = ({ setusePlayer, usePlayer }) => {
  const [value, setValue] = useState(false);
  const [DisplaySelected, setDisplaySelected] = useState(false);
  const [ItemState, setItemState] = useState(0);

  const handleChange = (e) => {
    //console.log(e)
    setValue(e);
    setusePlayer(e);
    setDisplaySelected(e);
    setItemState(10);
  };

  useEffect(() => {}, [value]);
  return (
    <>
      <BLOCK_Section_SubHeading
        LABEL="Select a Player"
        BGColor={0}
        Color={DisplaySelected ? 7 : 5}
      />

      <VideoWizardContainer DividerSize={ItemState} Padding="xs">
        <Group position="apart" spacing={0} grow>
          <Box>
            {!value ? (
              <InputFindAPlayer CALLBACK={handleChange} DefaultValue={value} />
            ) : (
              <BTN_CTA
                CALLBACK={() => {
                  setValue(false);
                }}
                LABEL={"Change"}
              />
            )}
          </Box>
        {/*   <RenderSelected SELECTED={value} /> */}
        </Group>
      </VideoWizardContainer>
    </>
  );
};

/* const RenderSelected = ({ SELECTED }) => {
  //console.log(SELECTED)
  if (SELECTED === undefined) return <>Select a Player</>;
  return (
    <Stack align="center" justify="space-around">
      <Image
        width={80}
        height={80}
        radius={100}
        src={SELECTED?.attributes?.image.data.attributes.url}
      />
      <H3> {SELECTED?.attributes?.Name}</H3>
    </Stack>
  );
}; */
