import {gql,} from "@apollo/client";


export const QUERY = (APISETTINGS)=>{
    return gql`query TOP_6 { watchLists(
      pagination: { start: 0, limit: -1 }
      filters:{id:{in:  [${APISETTINGS.IDS}]}}
    ){
      data{ 
       id
        attributes{
          STATS
        }
      }
  }
}` 
}


/* export const Query = (APISETTINGS)=>{
  return gql`query TOP_6 {
    playerBattings(
      pagination: { start: 0, limit: -1 }
      filters:{BATTING_sixes:{ gte:1 },fixture:{watch_list:{ id:{in: [${APISETTINGS.IDS}]}}}}
    ){
      data{ 
       id
        attributes{
          BATTING_sixes
           team {
                data { 
                  id 
                  attributes {
                    Name
                    TeamID
                    Logo {
                      data {

                        attributes {
                          url
                        }
                      }
                    }
                  }
              }
          }
          
          player{
            data{
              id
              attributes{
                Name
                 image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
              }
            }
          }
        }
      }
  }
      }`
} */