import { useCurrentFrame,Audio,AbsoluteFill, Video, Img,interpolate } from 'remotion';
// styled components
import { useTheme } from 'styled-components'
import {FrostedContainer, FullWidthWrapper, LogoInnerContainer,LogoContainer} from "../utils/Containers"
import {interpolateOpacityByFrame} from '../../Animation/interpolate'
import {SpringToFrom} from '../../Animation/RemotionSpring'
import { Grid } from '@mantine/core';

// Opening Title Components
import {VideoTitle} from './OpeningTitles/VideoTitle'
import {DisplayFranchise} from './OpeningTitles/Franchise'
import {DisplayLocation} from './OpeningTitles/Location'

import { H2,H1, H3 } from '../utils/Text';
import {LMSLOGO} from "../../Assets/SVG/svg"
const VIDEO={
        mixBlendMode: 'luminosity',
        opacity :0.1, 
        
}
export const OpeningTitles=(props)=>{
    const {RENDER} = props
    console.log(RENDER, RENDER.META.Franchise)
    const frame = useCurrentFrame();  
    return(
        <FullWidthWrapper> 
                
                <VideoTitle TITLE={`last man stands`}/>
                <IntroLogo />
                <DisplayFranchise VideoLabel={RENDER.META.Franchise}/> 
                <DisplayLocation TournamentLocation={`www.lastmanstands.com`} /> 
               
            <FrostedContainer /> 
        
            <AbsoluteFill> 
                <div style={{ opacity:interpolateOpacityByFrame(frame, 110,120, 1,0), }}>
                <Video 
                    startFrom={60} 
                    src={'https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/BGVideo001.mp4'} 
                    style={VIDEO}
                />
                </div>
            </AbsoluteFill>
            <Audio 
				src={`https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/TournamentIntro.mp3`}
				playbackRate={1}
				startFrom={0} // if composition is 30fps, then it will start at 2s
			/>

        </FullWidthWrapper>
    ) 
} 


export const DIVISIONWINNERSOPENINGTITLES=({MainTitle,Subtitle,TEAMLOGO=null,VideoDate=null, TournamentLogo})=>{
    const theme = useTheme()
    const frame = useCurrentFrame();
      return(
          <FullWidthWrapper>
            <Grid 
                grow gutter="xs"
                justify="space-around" align="flex-end"
                columns={12}
                style={{
                    width:'100%',
                }}
            >
            <Grid.Col span={4}>
            <div
                    style={{ 
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        alignContent: 'end',
                        padding:'0rem 2rem',
                       
                    }}      
                >
                    
                </div>
            </Grid.Col>
            <Grid.Col span={8}>
           
            <div
             style={{
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'column',
                justifyContent: 'end',
                alignContent: 'end',
                padding:'3rem'
            }} 
            >
                <IntroTeamLogo LOGO={VideoDate.DATAOBJ.General.LOGO}/>
                 
                <H3 style={{ 
                    opacity:interpolateOpacityByFrame(frame,0,30, 0, 1),
                    fontSize: '4rem', 
                    padding: '3rem 0 1rem',
                    color:theme.Copy[1],
                    transform: `translateY(${SpringToFrom(0,60,0,'Springy100')}px) translateY(${SpringToFrom(80,0,60,'Springy100')}px)`,
                     }}>
                        <span style={{opacity:interpolateOpacityByFrame(frame,80,85, 1, 0) ,}}>Winners</span>
                </H3>
                <H1 style={{ 
                    color:theme.Copy[1],
                    opacity:interpolateOpacityByFrame(frame,7,35, 0, 1),
                    transform: `translateY(${SpringToFrom(7,60,0,'Springy100')}px) translateY(${SpringToFrom(75,0,60,'Springy100')}px)`,
                     textAlign: 'right', 
                     fontSize: '5rem', 
                     padding: '1rem 0' }} 
                >
                   <span style={{opacity:interpolateOpacityByFrame(frame,75,85, 1, 0) ,}}> {Subtitle}</span>
                </H1>
                
                <H2 style={{ 
                    color:theme.Copy[1],
                    opacity:interpolateOpacityByFrame(frame,15,45, 0, 1),
                    transform: `translateY(${SpringToFrom(15,60,0,'Springy100')}px) translateY(${SpringToFrom(70,0,60,'Springy100')}px)`,
                    fontSize: '3rem', 
                    padding: '1rem 0 3rem'}} >
                        <span style={{opacity:interpolateOpacityByFrame(frame,70,85, 1, 0) ,}}>{MainTitle}</span>
                </H2>
            </div>
            </Grid.Col>
            </Grid>

              <FrostedContainer />
            


              <AbsoluteFill>
                  <Video 
                      startFrom={60} 
                      src={'https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/BGVideo001.mp4'} 
                      style={VIDEO}
                  />
              </AbsoluteFill>
              <Audio 
                  src={`https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/DivisionWinnerIntroVersion1.mp3`}
                  playbackRate={1}
                  startFrom={0} // if composition is 30fps, then it will start at 2s
                  
            />
          </FullWidthWrapper>
      ) 
  }
 
  const IntroTeamLogo = ({LOGO})=>{

    const frame = useCurrentFrame();
  
    return(
	
        <LogoInnerContainer 
            style={{
                opacity:interpolateOpacityByFrame(frame,0,60, 0, 1),
                width: '600px',
                height: 'auto',
                transform: `scale(${SpringToFrom(75,1,0,'Springy100')})`
            }}>
            <Img src={LOGO} width={600} />
        </LogoInnerContainer>
	
    )
}

 const IntroLogo = ()=>{

    const frame = useCurrentFrame();

    return(
	<LogoContainer>
        <div style={{ opacity:interpolateOpacityByFrame(frame, 75,85, 1,0), }}>
        <LogoInnerContainer style={{opacity:interpolateOpacityByFrame(frame,0,30, 0, 1),}}>
            <LMSLOGO />
        </LogoInnerContainer>
        </div>
	</LogoContainer>
    )
} 