import {useState } from "react"

const OffsetTime = 210
const VideoOBJ={
    OpeningSceneDuration:90,
    EndCreditsDuration:120,
    SponsorTiming:120,
    ItemOffset:OffsetTime,
    VideoBodyTiming:[120, 150, 180, 180,  210].reduce((a, b) => a + b),
    VideoDurationinFrames:(((90)+[120, 150, 180, 180,  210].reduce((a, b) => a + b)+120)),
    RenderStill:[40,140,250,450,650,850]
}
 
export const useBuildVideoData = ()=>{
    const VideoSettings = { ...VideoOBJ };
    const [VIDEODATA ,setVIDEODATA] = useState({VideoSettings})
    
    // Main Function
    const FindVIDEODATA =async(DATA,Filters)=>{
        //console.log(DATA,Filters)
        VIDEODATA.VideoSettings['DisplayThisMilestone'] = Filters
        setVIDEODATA({...VIDEODATA,DATAOBJ:{...DATA.attributes}}) 
    } 

    return[VIDEODATA ,FindVIDEODATA]
}