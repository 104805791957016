import {gql,} from "@apollo/client";


export const QUERY = (APISETTINGS)=>{
  return gql`query TOP_10 { watchLists(
    pagination: { start: 0, limit: -1 }
    filters:{id:{in:  [${APISETTINGS.IDS}]}}
      ){
        data{ 
        id
          attributes{
            STATS 
          }
        }
    }
    }` 
}
