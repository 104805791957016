import { Series } from "remotion";
import { AnimateIn } from "./Series/AnimateIn";
import { HONORABLE } from "./HONORABLEMENTIONS";
export const SHOWCASE = ({ DATA, Dimensions }) => {
  const Durations = {
    Begin: 0, // bring in overlay
    TransitionAfter: 45, // slide overlay down
    Close: 150, // remove overlay prep nect item
    Offset: 30,
  };

  if (Dimensions.length === 0) return false;
  return (
    <>
      <Series>
        {DATA.SHOWCASE.map((POTD, i) => {
          return (
            <Series.Sequence
              durationInFrames={Durations.Close}
              layout="none"
              key={i}
            >
              <Series>
                <Series.Sequence
                  durationInFrames={Durations.Close}
                  layout="none"
                >
                  <AnimateIn
                    POTD={POTD}
                    Dimensions={Dimensions}
                    Durations={Durations}
                    DATA={DATA}
                  />
                </Series.Sequence>
              </Series>
            </Series.Sequence>
          );
        })}
        <Series.Sequence durationInFrames={300}>
          <HONORABLE DATA={DATA} Dimensions={Dimensions} />
        </Series.Sequence>
      </Series>
    </>
  );
};
