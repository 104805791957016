/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */

import { NumberInput } from "@mantine/core";

import { useEffect, useState } from "react";

export const SelectFixtureID = ({ GetFixture }) => {
  const [FixtureID, setFixtureID] = useState();

  let timeout = null;
  useEffect(() => {
    if (FixtureID > 5) {
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        GetFixture(FixtureID);
      }, 500);
    }
  }, [FixtureID]);
  return (
    <>
      <NumberInput
        onChange={(val) => setFixtureID(val)}
        placeholder="123456"
        label="Fixture ID"
        description="Type in an LMS Fixture ID below to view the Comentary statistics."
        size="md"
        withAsterisk
        hideControls
      />
    </>
  );
};
