import { Player } from "@remotion/player";

import { PreviewVideoRenderWrapper } from "../../Components/interface/Wrapper/PreviewVideoRenderWrapper";
// Video Compositions
// COMPS
// Fixtures
import { Build_MATCHDAYFIXTURES_4by5 } from "../../remotion/FE_Previews/UPCOMINGFIXTURES/Build_MATCHDAYFIXTURES_4by5";
import { Build_MATCHDAYRESULTS_4by5 } from "../../remotion/FE_Previews/RESULTS/Build_MATCHDAYRESULTS_4by5";
import { Build_TEAMOFTHEWEEK_4by5 } from "../../remotion/FE_Previews/TEAMOFTHEWEEK/Build_TEAMOFTHEWEEK_4by5";
import { Build_THISWEEKINLMS_4by5 } from "../../remotion/FE_Previews/THISWEEKINLMS/Build_THISWEEKINLMS";
import { Build_GAMEOFTHEWEEKLITE_4by5 } from "../../remotion/FE_Previews/GAMEOFTHEWEEKLITE/Build_GAMEOFTHEWEEKLITE_4by5";
import { Build_GAMEOFTHEWEEK_4by5 } from "../../remotion/FE_Previews/GAMEOFTHEWEEK/Build_GAMEOFTHEWEEK_4by5";
import { Build_MATCHREVIEW } from "../../remotion/FE_Previews/MATCHREVIEW/Build";
// Performances
import { Build_POTDBATTING_4by5 } from "../../remotion/FE_Previews/POTD/Build_POTDBATTING_4by5";
import { Build_POTDBOWLING_4by5 } from "../../remotion/FE_Previews/POTDBOWLING/Build_POTDBOWLING_4by5";
import { Build_PLAYEROFTHEMATCH_4by5 } from "../../remotion/FE_Previews/PLAYEROFTHEMATCH/Build";
// Top 5's
import { Build_TOPFIVERUNS_4by5 } from "../../remotion/FE_Previews/TOPFIVERUNS/Build_TOPFIVERUNS";
import { Build_TOPFIVEFOURS_4by5 } from "../../remotion/FE_Previews/TOPFIVEFOURS/Build_LEADERBOARD_FOURS_4by5";
import { Build_LEADERBOARDSIXES_4by5 } from "../../remotion/FE_Previews/LEADERBOARD_SIXES/Build_LEADERBOARD_SIXES_4by5";
import { Build_TOPFIVECATCHES_4by5 } from "../../remotion/FE_Previews/TOPFIVECATCHES/Build_TOPFIVECATCHES";
import { Build_TOPFIVEWICKETS_4by5 } from "../../remotion/FE_Previews/TOPFIVEWICKETS/Build_TOPFIVEWICKETS_4by5";
import { Build_TOPFIVEFASTESTFIFTIES_4by5 } from "../../remotion/FE_Previews/TOPFIVEFASTESTFIFTIES/Build_TOPFIVEFASTESTFIFTIES_4by5";

// Milestones

import { Build_PLAYERMILESTONE_4by5 } from "../../remotion/FE_Previews/PLAYERMILESTONE/Build_PLAYERMILESTONE";

// Seasonal
import { Build_DIVISIONWINNERS_4by5 } from "../../remotion/FE_Previews/DIVISIONWINNERS/Build_DIVISIONWINNERS";
import { Build_TEAMSEASONALREVIEW_4by5 } from "../../remotion/FE_Previews/TEAMSEASONALREVIEW/Build_TEAMSEASONALREVIEW";
import { useEffect } from "react";
const OBJ = {
  Build_POTDBATTING_4by5: Build_POTDBATTING_4by5,
  Build_POTDBOWLING_4by5: Build_POTDBOWLING_4by5,
  Build_MATCHDAYRESULTS_4by5: Build_MATCHDAYRESULTS_4by5,
  Build_MATCHDAYFIXTURES_4by5: Build_MATCHDAYFIXTURES_4by5,
  Build_TEAMOFTHEWEEK_4by5: Build_TEAMOFTHEWEEK_4by5,
  Build_THISWEEKINLMS_4by5: Build_THISWEEKINLMS_4by5,
  Build_TOPFIVERUNS_4by5: Build_TOPFIVERUNS_4by5,
  Build_TOPFIVEFOURS_4by5: Build_TOPFIVEFOURS_4by5,
  Build_LEADERBOARDSIXES_4by5: Build_LEADERBOARDSIXES_4by5,
  Build_TOPFIVECATCHES_4by5: Build_TOPFIVECATCHES_4by5,
  Build_TOPFIVEWICKETS_4by5: Build_TOPFIVEWICKETS_4by5,
  Build_TOPFIVEFASTESTFIFTIES_4by5: Build_TOPFIVEFASTESTFIFTIES_4by5,
  Build_PLAYERMILESTONE_4by5: Build_PLAYERMILESTONE_4by5,
  Build_PLAYEROFTHEMATCH_4by5: Build_PLAYEROFTHEMATCH_4by5,
  Build_GAMEOFTHEWEEKLITE_4by5: Build_GAMEOFTHEWEEKLITE_4by5,
  Build_GAMEOFTHEWEEK_4by5: Build_GAMEOFTHEWEEK_4by5,
  Build_DIVISIONWINNERS_4by5: Build_DIVISIONWINNERS_4by5,
  Build_TEAMSEASONALREVIEW_4by5: Build_TEAMSEASONALREVIEW_4by5,
  Build_MATCHREVIEW: Build_MATCHREVIEW,
};

export const GiveItAPreview = ({ RENDER }) => {
 
  useEffect(()=>{},[RENDER])
  //
  //console.log(RENDER.ASSET.Asset.video_components.data[0].attributes.ratio.data.attributes);
  const H =
    RENDER.ASSET.Asset.video_components.data[0].attributes.ratio.data.attributes
      .Height;
  const W =
    RENDER.ASSET.Asset.video_components.data[0].attributes.ratio.data.attributes
      .Width;
  const PREVIEWCOMPONENT =
    RENDER.ASSET.Asset.video_components.data[0].attributes.VideoComponent;
  //RENDER.ASSET.Asset.video_components.data[0].attributes.ratio.data.attributes.Height
  //PreviewVideoRenderWrapper
  return (
    <PreviewVideoRenderWrapper>
      <Player
        id={RENDER.ASSET.Asset.ComponentName}
        component={OBJ[PREVIEWCOMPONENT]}
        durationInFrames={RENDER.DATAOBJ.VideoSettings.VideoDurationinFrames}
        fps={30}
        numberOfSharedAudioTags={0}
        compositionWidth={parseInt(W)}
        compositionHeight={parseInt(H)}
        inputProps={{
          RENDER: RENDER,
        }}
        style={{
          width: parseInt(W) * 0.35,
          height: parseInt(H) * 0.35,
        }}
        controls
      />
    </PreviewVideoRenderWrapper>
  );
};
