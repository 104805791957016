/* eslint-disable react/jsx-pascal-case */
import { VideoWizardContainer } from "../../../../../Theme/Structure/BodyContainer";
import { IconCircleCheck, IconInfoCircle } from '@tabler/icons'
import { H2, H3, P } from "../../../../../Theme/type";
import {
  Box,
  Container,
  Grid,
  Group,
  List,
  SimpleGrid,
  Image,
  Space,
  ThemeIcon,
} from "@mantine/core";

import { orderBy, filter } from "lodash";
import {
  BTN_ICON_CALLBACK,
} from "../../../../../Theme/Structure/Button/BTNS";
import { BLOCK_ToolBar } from "../../../../../Components/interface/BLOCK_Toolbar";

export const TeamsOverview = ({ Fixture, setPath, setTeamID }) => {
  //console.log(Fixture.attributes.teams.data)
  return (
    <>
      <Container size="lg"  p={0}>
        {Fixture.attributes.teams.data.map((team, i) => {
          return (
            <TeamDetails
              team={team}
              HomeAway={i===0?'Home Team':'Away Team' }
              key={i}
              setPath={setPath}
              setTeamID={setTeamID}
            />
          );
        })}
      </Container>
    </>
  );
};

const TeamDetails = ({ team, setPath, setTeamID,HomeAway }) => {

  const FindFixtures = (FIXTURES) => {
    return orderBy(
      filter(FIXTURES, (o) => {
        return o.attributes.hasPlayers;
      }),
      ["attributes.Date"],
      ["desc"]
    ).slice(0, 3);
  };

  const resultColor=(Team,Results)=>{
    return Results.includes(Team) ?7:6
  }


  const T = team.attributes;

  return (
    <Container my="lg">
      <VideoWizardContainer>
        <SimpleGrid
          cols={2}
          spacing="md"
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        >
          <div>
            <H3>{HomeAway}</H3>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.colors.brand[1],
                borderRadius: 5,
                padding: "1em",
              })}
            >
              <H3 weight={100} align="center" size={30} transform="uppercase">
                {T.Name}
              </H3>
              <P colorID={3} weight={900} align="center">
                Recent : {T.Form}
              </P>
              <P colorID={4} weight={900} align="center">
                Win Ratio : {T.Win_Ratio}
              </P>
              {T.id}
            </Box>
            <Group
              position="center"
              sx={(theme) => ({
                marginTop: "1.5em",
              })}
            >
              <Image
                src={T.Logo.data.attributes.url}
                width={220}
                height={220}
                radius={220}
              />
            </Group>
          </div>
          <Grid gutter="md">
            <Grid.Col span={6}>
              <H3>Results</H3>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colors.brand[1],
                  borderRadius: 5,
                  padding: "1em",
                })}
              >
                <List
                
                 icon={
                  <ThemeIcon color="gray" size={24} radius="xl">
                    <IconInfoCircle size={16} />
                  </ThemeIcon>
                }>
                  <List.Item>Games {T.Games}</List.Item> 
                  <List.Item>Won {T.Won}</List.Item>
                  <List.Item>Lost {T.Lost}</List.Item>
                </List>
              </Box>
            </Grid.Col>
            <Grid.Col span={6}>
              <H3>Ranking</H3>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colors.brand[1],
                  borderRadius: 5,
                  padding: "1em",
                })}
              >
                <List
                
                 icon={
                  <ThemeIcon color="gray" size={24} radius="xl">
                    <IconInfoCircle size={16} />
                  </ThemeIcon>
                }>
                  <List.Item>City {T.City_Rank}</List.Item>
                  <List.Item>National {T.National_Rank}</List.Item>
                  <List.Item>City {T.World_Rank}</List.Item>
                </List>
              </Box>
            </Grid.Col>
            <Grid.Col>
              <H3>Recent History</H3>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colors.brand[1],
                  borderRadius: 5,
                  padding: "1em",
                })}
              >
                {FindFixtures(T.fixtures.data).map((G, i) => {
                  return (
                    <Box
                      key={i}
                      sx={(theme) => ({
                        marginBottom: "1em",
                        borderBottom: `1px solid ${theme.colors.brand[3]}`,
                        paddingBottom: "1em",
                      })}
                    >
                      <P colorID={3} align="center">
                       {G.attributes.HomeTeam} vs {G.attributes.AwayTeam}
                      </P>
                      <P colorID={resultColor(T.Name,G.attributes.ResultStatement)} weight={600} align="center">

                        {G.attributes.ResultStatement}
                      </P>
                    </Box>
                  );
                })}
              </Box>
            </Grid.Col>
          </Grid>
        </SimpleGrid>
        <Space h="md" />
        <BLOCK_ToolBar
          TOOLS={[
            <BTN_ICON_CALLBACK
              CALLBACK={() => {
                setPath(2);
                setTeamID(team.id);
              }}
              ICON={"InfoCircle"}
              TIPOBJ={{
                LABEL: `More on ${T.Name}`,
                COLOR: "yellow",
                POSITION: "top",
              }}
            />,
          ]}
        />
      </VideoWizardContainer>
    </Container>
  );
};
