import { Box, Container, Divider, MediaQuery, Space } from "@mantine/core";

export const BodyContainer = (props) => {
  return (
    <Container
      size="lg"
      p={"xs"}
      my="lg"
      sx={(theme) => ({
        backgroundColor: theme.colors.brand[0],
      })}
    >
      {props.children}
    </Container>
  );
};

export const SectionContainer = (props) => {
  const { PB = "xl", MB = "xl" } = props;
  return (
    <Container
      size="lg"
      mb={MB}
      pb={PB}
      sx={(theme) => ({
        backgroundColor: theme.colors.brand[0],
      })}
    >
      {props.children}
    </Container>
  );
};
export const ColoredContainer = (props) => {
  const { BGColor = 0, Padding = "xl", Radius = 3, size = "lg" } = props;
  return (
    <Container
      size={size}
      p={Padding}
      sx={(theme) => ({
        backgroundColor: theme.colors.brand[BGColor],
        borderRadius: Radius,
        "@media (max-width: 565px)": {
          padding: "5px",
        },
      })}
    >
      {props.children}
    </Container>
  );
};

export const FULLPAGE_Container = (props) => {
  const { BGColor = 0 } = props;
  return (
    <Container
      size="100%"
      p="xl"
      sx={(theme) => ({
        backgroundColor: theme.colors.brand[BGColor],
      })}
    >
      {props.children}
    </Container>
  );
};
export const BorderedContainer = (props) => {
  const { MarginBottom = 30, Padding = "lg", size = "lg" } = props;
  return (
    <Container
      size={size}
      p={Padding}
      sx={(theme) => ({
        backgroundColor: theme.colors.brand[0],
        borderColor: theme.colors.brand[1],
        borderWidth: 1,
        borderStyle: "solid",
        marginBottom: `${MarginBottom}px`,
        borderRadius: 3,
        "@media (max-width: 565px)": {
          padding: "5px",
        },
      })}
    >
      {props.children}
    </Container>
  );
};

export const RaisedContainer = (props) => {
  return (
    <Container
      size="xl"
      p="md"
      my="lg"
      sx={(theme) => ({
        backgroundColor: theme.colors.brand[0],
        borderRadius: 3,
        borderColor: theme.colors.brand[0],
        borderWidth: 1,
        borderStyle: "solid",
        boxShadow: "5px 5px 10px rgba(148, 148, 148, 0.25)",
        textAlign: "center",
      })}
    >
      {props.children}
    </Container>
  );
};

export const SideBarContainer = (props) => {
  return (
    <Container
      size="xl"
      p={3}
      m="0"
      sx={(theme) => ({
        backgroundColor: theme.colors.brand[0],
        borderRadius: 5,
        borderColor: theme.colors.brand[0],
        borderWidth: 1,
        borderStyle: "solid",
        margin: 0,
        textAlign: "center",
      })}
    >
      {props.children}
    </Container>
  );
};

export const HeaderBarItems = (props) => {
  return (
    <Container
      size="xl"
      p={3}
      m="0"
      sx={(theme) => ({
        backgroundColor: theme.colors.brand[0],
        borderColor: theme.colors.brand[0],
        display: "flex",
      })}
    >
      {props.children}
    </Container>
  );
};

export const ContentContainerx3 = (props) => {
  const { BGColor = 0, Padding = "xl" } = props;
  return (
    <BodyContainer>
      <MediaQuery largerThan="lg" styles={{ display: "none" }}>
        <Box>
          <BorderedContainer Padding="xs">
            <ColoredContainer BGColor={BGColor} Padding={"xs"}>
              {props.children}
            </ColoredContainer>
          </BorderedContainer>
        </Box>
      </MediaQuery>

      <MediaQuery smallerThan="lg" styles={{ display: "none" }}>
        <Box>
          <BorderedContainer Padding={Padding}>
            <ColoredContainer BGColor={BGColor} Padding={Padding}>
              {props.children}
            </ColoredContainer>
          </BorderedContainer>
        </Box>
      </MediaQuery>
    </BodyContainer>
  );
};

export const VideoWizardContainer = (props) => {
  const { BGColor = 0, mb='40',p=10 } = props;
  return (
    <>
      <Container
        size={"xl"}
        p={p}
        mb={mb}
        sx={(theme) => ({
          backgroundColor: theme.colors.brand[BGColor],
          borderRadius: "5px",
          boxShadow:
            "0 1px 3px rgba(0, 0, 0, 0.05),rgba(0, 0, 0, 0.05) 0px 36px 28px -7px,rgba(0, 0, 0, 0.04) 0px 17px 17px -7px",
          "@media (max-width: 565px)": { padding: "5px" },
        })}
      >
        {props.children}
      </Container>
      <Space h="md" />
    </>
  );
};

export const AssetHeaderContainer = (props) => {
  const { BGColor = 5, Padding = "xl", Radius = 3, size = "lg" } = props;
  return (
    <Container
      size={size}
      p={50}
      mb={55}
      sx={(theme) => ({
        background: `linear-gradient(180deg, ${theme.colors.brand[2]} 0%, rgba(255, 255, 255, 0) 100%)`,
        //borderRadius: Radius,
        boxShadow:
          "0 1px 3px rgba(0, 0, 0, 0.05),rgba(0, 0, 0, 0.05) 0px 36px 28px -7px,rgba(0, 0, 0, 0.04) 0px 17px 17px -7px",
        "@media (max-width: 565px)": {
          padding: "5px",
        },
      })}
    >
      {props.children}
    </Container>
  );
};
