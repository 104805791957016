import {useCurrentFrame, useVideoConfig,spring,} from 'remotion';
import {TrimPlayerName} from '../../../../../../actions/UX'

import {HONERABLELI} from '../../../utils/html'
import { H3, H2} from '../../../utils/Text';
import {HonerableImageBox} from '../../../utils/Containers'
// Components

export const DisplayHonorable = ({_PLAY,_INT})=>{

	const frame = useCurrentFrame();
    const { fps } = useVideoConfig();
 
 

     const SpringConfig ={
      
       Springy100:{ 
             damping: 10,
            stiffness: 150,
            mass: 0.3,
        },
        Stiff:{
            damping: 80,
            stiffness: 100,
            mass: 0.1,
        }
       
      } 

    const StaggerSpringIn = (int)=>{
        return  spring({
            fps,
            from: 0,
            to: 1,
            frame:frame-int,
			config:SpringConfig.Springy100,
          })
    }
  
    const StaggerSpringOut = (int)=>{
        return  spring({
            fps,
            from: 1,
            to: 0,
            frame:frame-int,
			config:SpringConfig.Stiff,
          })
    }

   
	return ( 
		<HONERABLELI
			style={{
            transform: `
            scale(${StaggerSpringIn(15+_INT)}) 
            scale(${StaggerSpringOut((150*2))})` }}
		>
			<HonerableImageBox style={{ backgroundImage:`url(${_PLAY.Logo})`}}  />
			<H3 style={{textAlign:'left',marginLeft: '80px'}}>{TrimPlayerName(_PLAY.Name)}</H3>
			<H2 style={{textAlign:'left',marginLeft: '80px'}}>{_PLAY.BATTING_Runs} ({_PLAY.BATTING_Balls}) {_PLAY.BATTING_HowOut}</H2>
		</HONERABLELI>
	);
}