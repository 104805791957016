import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ENV } from "../../Config/RunTimeConfig";
import { parseCookies, setCookie } from "nookies";
import { useAssetInvoice } from "./useInvoices";
import {pick} from 'lodash'
const qs = require("qs");

const GetDate = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  return today;
};

// FUNC : GET

export const useGetOrders = (ctx) => {
  const [orders, setOrders] = useState(null);
  const AUTHID = parseCookies(ctx).AUTHID;
  const jwt = parseCookies(ctx).jwt;

  const GetOrders = async () => { 
    const query = qs.stringify(
      {
        pagination: {
          page: 1,
          pageSize: 25,
        },
        sort: ["createdAt:desc"],
        filters: {
          users_permissions_user: { id: { $eq: AUTHID } },
          isActive: { $ne: false },
        },
        populate: [
          "asset",
          "asset.hashtags",
          "asset.HeaderImage",
          "invoice",
          "render_lists",
        ],
      },
      {
        encodeValuesOnly: true,
      }
    );

    const res = await fetch(`${ENV.API_URL}/api/orders?${query}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    const orderResponse = await res.json();
  //console.log(orderResponse);
    setOrders(orderResponse.data);
  };

  return [orders, GetOrders];
};


export const useGetOrdersPublicCount = (ctx) => {
  const [orders, setOrders] = useState(null);


  const GetOrders = async () => {
    const res = await fetch(`${ENV.API_URL}/api/order/count`, {
      headers: {},
    });
    const orderResponse = await res.json();
    //console.log(orderResponse);
    setOrders(orderResponse);
  };

  return [orders, GetOrders];
};

/*
AssetPrice: 5
AssetType: "UPCOMINGFIXTURES"
DATA: {VideoSettings: {…}, OrderMetadata: {…}, Fixtures: {…}}
DataSet: "Aussie Champs"
Franchise: "Aussie Champs"
Name: "AUSSIE CHAMPS TOURNAMENT FIXTURES 17 SEPTEMBER 2019"
Order_Date: "2022-08-11"
Payment_Status: false
Sponsor: {Name: 'Lastmanstands Player APP', Tagline: 'The LMS Player APP', URL: 'Now available for download', createdAt: '2022-05-21T06:02:39.187Z', updatedAt: '2022-07-11T12:33:22.337Z', …}
asset: {data: {…}}
createdAt: "2022-08-11T07:28:18.492Z"
invoice: {data: {…}}
publishedAt: "2022-08-11T07:28:18.490Z"
render_lists: {data: Array(1)}
updatedAt: "2022-08-11T07:28:19.334Z"
users_permissions_user: {data: {…}}
*/



// GET Most recent 10 VIdeos
export const useGetMostRecentOrders = (ctx) => {
  const [orders, setOrders] = useState(null);
  const AUTHID = parseCookies(ctx).AUTHID;
  const jwt = parseCookies(ctx).jwt;

  const GetOrders = async () => { 
    const query = qs.stringify(
      {
        pagination: {
      
          pageSize: 6,
        },
        sort: ["createdAt:desc"],
        /* filters: {
          users_permissions_user: { id: { $eq: AUTHID } },
          isActive: { $ne: false },
        }, */
        populate: [
          "asset",
          "asset.hashtags",
          "asset.HeaderImage",
          "invoice",
          "render_lists",
        ],
      },
      {
        encodeValuesOnly: true,
      }
    );

    const res = await fetch(`${ENV.API_URL}/api/orders?${query}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    const orderResponse = await res.json();
    console.log(orderResponse);
    setOrders(orderResponse.data);
  };

  return [orders, GetOrders];
};
// FUNC : GET SINGLE
export const useGetOrder = (ctx) => {
  const [order, setOrder] = useState(null);
  const AUTHID = parseCookies(ctx).AUTHID;
  const jwt = parseCookies(ctx).jwt;

  const GetOrder = async (_ID) => {
    const query = qs.stringify(
      {
        filters: {
          users_permissions_user: { id: { $eq: AUTHID } },
        },
        populate: [
          "asset",
          "asset.hashtags ",
          "invoice",
          "render_lists",
          "render_lists.Rendered_Still",
        ],
      },
      {
        encodeValuesOnly: true,
      }
    );

    const res = await fetch(`${ENV.API_URL}/api/orders/${_ID}?${query}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    const orderResponse = await res.json();
  //console.log(orderResponse);
    setOrder(orderResponse.data);
  };

  useEffect(() => {}, [order]);
  return [order, GetOrder];
};
// FUNC : UPDATE
// FUNC : DELETE

// FUNC : CREATE
export const useOrderAsset = (ctx) => {
  // Get from Store
  const RENDER = useSelector((state) => state.RENDER);
  const AUTH = useSelector((state) => state.AUTH);
  const UXSTATE = useSelector((state) => state.UXSTATE);
  
  const [orderStatus, setorderStatus] = useState(false);
  const [InvoiceStatus, CreateInvoice] = useAssetInvoice();

  const jwt = parseCookies(ctx).jwt;

  const CreateRenderItem = async (_ORDER, _ASSET, _DATA, _USER, _NAME) => {

  

    
    await fetch(`${ENV.API_URL}/api/render-lists`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        data: {
          Name: `${RENDER.META.NameVideo} ${_ASSET.attributes.Name}`,
          Ratio: `${_ASSET.attributes.Name}`,
          Purpose: `${_ASSET.attributes.UsedFor}`,
          DATA: _DATA.DATAOBJ,
          VideoComponent:_ASSET.attributes.VideoComponent,
          THEME:RENDER.THEME,
          SPONSORSHIP:RENDER.SPONSORSHIP,
          META:RENDER.META,
          ASSET:RENDER.ASSET,
          INPUTDATA:RENDER.INPUTDATA,
          AssetType: _ASSET.attributes.Label,
          VideoTemplate: RENDER.THEME.VideoTemplate,
          DataSet: RENDER.INPUTDATA?.DATASET?.Name,
          order: [_ORDER.data.id],
          Status_Message: "In Cue",
        },
      }),
    });
  };

  const CreateOrder = async () => {
  
    const res = await fetch(`${ENV.API_URL}/api/orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        data: {
          Name: RENDER.META.NameVideo,
          DATA: RENDER.DATAOBJ,
          asset: [RENDER.ASSET.Asset.id],
          users_permissions_user: [AUTH.AUTHUSER.id],
          Order_Date: GetDate(),
          AssetPrice: RENDER.ASSET.Asset.Price,
          AssetType: RENDER.ASSET.Asset.ComponentName,
          Franchise: UXSTATE.SelectedFranchise.attributes.Name,
          Sponsor: RENDER.SPONSORSHIP,
          VideoTemplate: RENDER.THEME.VideoTemplate,
          DataSet:RENDER.INPUTDATA?.DATASET?.Name,
        },
      }),
    });
    const OrderResponse = await res.json();

    if (OrderResponse.data != null) {
      CreateInvoice(OrderResponse);
      setorderStatus(true);

      RENDER.ASSET.Asset.video_components.data.map((ASSET, i) => {
        CreateRenderItem(OrderResponse, ASSET, RENDER, AUTH.AUTHUSER.id, RENDER.META.NameVideo);
      });
    } else {
      setorderStatus(null);
    }
  };

  useEffect(() => {}, [InvoiceStatus]);

  return [orderStatus, CreateOrder];
};

// UPDATE
export const useUnpublishOrder = (ctx) => {
  const [Unpublish, setUnpublish] = useState(null);
  //const AUTHID = parseCookies(ctx).AUTHID
  const jwt = parseCookies(ctx).jwt;

  const UpdateUnpublish = async (_DATA, _ID) => {
  //console.log(_DATA, _ID);
    /* const query = qs.stringify({
            filters: {
                users_permissions_user: { id:{ $eq: AUTHID,}},
              },
              populate: ['asset','asset.hashtags ','invoice','render_lists','render_lists.Rendered_Still'],  
          }, {
            encodeValuesOnly: true, 
          }); */

    const res = await fetch(`${ENV.API_URL}/api/orders/${_ID}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        data: _DATA,
      }),
    });
    const orderResponse = await res.json();
  //console.log(orderResponse);
    setUnpublish(orderResponse.data);
  };

  useEffect(() => {}, [Unpublish]);
  return [Unpublish, UpdateUnpublish];
};
