import { useState } from "react";
import { useSelector } from 'react-redux'
import { findIndex,  } from "lodash"


export const useIsDuplicateOrder = ()=>{
    const [isDuplicate, setisDuplicate] = useState(false)
    const AUTH = useSelector((state) => state.AUTH);
    
    
    const FindIsDuplicate = (NAME)=>{
            console.log("NAME", NAME)
            const INDEX = findIndex(AUTH?.OrderHistory?.data, (o)=>{return o.attributes.Name === NAME})
          

            console.log("INDEX", INDEX, "OrderHistory",  AUTH?.OrderHistory?.data)
            INDEX=== -1 ? setisDuplicate(false) :
            AUTH?.OrderHistory?.data[INDEX].attributes.isActive ?  setisDuplicate(true) : setisDuplicate(false) 
            
 
            //AUTH?.OrderHistory?.data[INDEX].attributes.isActive ?
            //INDEX=== -1 ? setisDuplicate(false):setisDuplicate(true) :setisDuplicate(false)
    }

    return [isDuplicate, FindIsDuplicate]
} 