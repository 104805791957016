/* eslint-disable react/jsx-pascal-case */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Hooks
import {
  useGetFranchises,
  useGetFranchise,
} from "../../../Hooks/Content/useFranchises";

// Mantine
import { Group, Select, Space } from "@mantine/core";
import { useMediaQuery, useInterval } from "@mantine/hooks";
// UTILS
import { setCookie } from "nookies";
import { isSelectedFranchise } from "../../../store/Reducer/UX";
import { H3, P } from "../../../Theme/type";
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";
import { ColoredContainer } from "../../../Theme/Structure/BodyContainer";

import { BTN_CTA } from "../../../Theme/Structure/Button/BTNS";
import { UISkeletonLines } from "../../../Components/Global/Skeleton";
import { NoActiveFranchise } from "../NoActiveFranchise";

export const SelectAFranchise = () => {
  const UXSTATE = useSelector((state) => state.UXSTATE);
  const [changeFranchise, setChange] = useState(false);
  // hooks
  const Width = useMediaQuery("(min-width: 769px)");
  const [Franchises, FetchFranchises] = useGetFranchises();
  const [Franchise, FetchFranchise] = useGetFranchise();
  // useEffect
  useEffect(() => {
    FetchFranchises();
    FetchFranchise();
  }, []);

  useEffect(() => {}, [Franchise]);

  const handleClick = () => {
    setChange(true);
  };

  if (!Franchises || Franchise?.data?.attributes.isUpdating)
    return <UISkeletonLines />;
  if (Franchises.data.length === 0) return <NoActiveFranchise />;
  return (
    <>
      {UXSTATE.SelectedFranchise ? (
        ""
      ) : (
        <>
          <H3>Default Franchise Not Selected</H3>
          <P>Please select a Franchise from the list below to proceed</P>
          <Space h="xl" />{" "}
        </>
      )}

      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Select Franchise",
          ICON: "Select",
          DESCRIPTION:
            "Alternate between your LMS franchises  using the select option below",
          TOOLBAR: false,
        }}
      />

      <ColoredContainer BGColor={3} Padding={10}>
        <Group position="apart" grow>
          <P colorID={0}>
            Current Franchise : {UXSTATE?.SelectedFranchise?.attributes?.Name}{" "}
          </P>

          {changeFranchise === true ? (
            <SelectOptions DATA={Franchises.data} />
          ) : (
            <BTN_CTA
              CALLBACK={handleClick}
              fullWidth={!Width}
              LABEL={`Change Franchise`}
            />
          )}
        </Group>
      </ColoredContainer>
    </>
  );
};

export const SelectAFranchiseMini = () => {

  const [changeFranchise, setChange] = useState(false);
  // hooks
  const Width = useMediaQuery("(min-width: 769px)");
  const [Franchises, FetchFranchises] = useGetFranchises();
  const [Franchise, FetchFranchise] = useGetFranchise();
  // useEffect
  useEffect(() => {
    FetchFranchises();
    FetchFranchise();
  }, []);

  useEffect(() => {}, [Franchise]);

  const handleClick = () => {
    setChange(true);
  };

  if (!Franchises || Franchise?.data?.attributes.isUpdating)
    return <UISkeletonLines />;
  if (Franchises.data.length === 0) return <NoActiveFranchise />;
  return (
    <>
 
      {changeFranchise === true ? (
        <SelectOptions DATA={Franchises.data} />
      ) : (
        <Group position="right">
        <BTN_CTA
          CALLBACK={handleClick}
          fullWidth={!Width}
          LABEL={`Change Franchise`}
        /></Group>
      )}
    </>
  );
};

const SelectOptions = ({ DATA }) => {
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setCookie(null, "isSelectedFranchise", e.id, {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    });
    dispatch(isSelectedFranchise(e));
  };

  const CreateFranchiseSelect = () => {
    let ARR = [];
    DATA.map((fr, i) => {
      ARR.push({ value: fr, label: fr.attributes.Name });
    });
    return ARR;
  };
  return (
    <Select
      label=""
      placeholder="Select Franchise"
      data={CreateFranchiseSelect()}
      onChange={handleChange}
      styles={(theme) => ({
        input: {
          "&, &:focus": {
            borderColor: theme.colors.brand[5],
            color: theme.colors.brand[5],
          },
        },
        item: {
          // applies styles to selected item
          "&[data-selected], &:focus": {
            "&, &:hover, &:focus": {
              backgroundColor: theme.colors.brand[3],
              color: theme.colors.brand[4],
            },
          },

          // applies styles to hovered item (with mouse or keyboard)
          "&[data-hovered], :focus": {
            backgroundColor: theme.colors.brand[5],
            color: theme.colors.brand[0],
          },
        },
      })}
    />
  );
};
