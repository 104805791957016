import { useCurrentFrame } from "remotion";
import { Image } from "@mantine/core";
import { useTheme } from "styled-components";
import styled from "styled-components";

// Animation
import { interpolateOpacityByFrame } from "../../../../../../Animation/interpolate";
import { SpringToFrom } from "../../../../../../Animation/RemotionSpring";
import {
  FromMiddle,
  FromBottomToTop,
} from "../../../../../../Animation/ClipWipe";
// Components
import { TopUnderlayForCopy } from "../../../../../GlobalComponents/BottomUnderlayForCopy";
import { H1, H2, H3 } from "../../../../../utils/Text";

const SeriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  flex-wrap: wrap;
  list-style: none;
`;
const PredictionContainer = styled.div`
  position: relative;
  width: 92%;
  margin: 0% 4% 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;

  box-sizing: border-box;
  backdrop-filter: blur(1px);
  border-radius: 10px;
`;
const WinnersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ThePrediction = ({ DATA, RENDER }) => {
  const frame = useCurrentFrame();
  const WINNER =
    DATA.DATAOBJ.TeamA.Name === RENDER.INPUTDATA.SelectWinner
      ? DATA.DATAOBJ.TeamA
      : DATA.DATAOBJ.TeamB;
  const theme = useTheme();
  const Durations = {
    Begin: 0,
    TransitionAfter: 180 * 4,
  };

  //console.log(DATA.OrderMetadata.SelectIsLiveStreamed)

  const TIME = (TIME) => {
    const GAMETIME = TIME.split(":");
    //console.log(GAMETIME)
    return `${GAMETIME[0]}:${GAMETIME[1]}`;
  };

  return (
    <SeriesContainer>
      <TopUnderlayForCopy
        DATA={DATA}
        Durations={Durations}
        BOTTOM={`1100px`}
        POSITIONSTARTFROMTOP={-35}
        POSITIONFROMTOP={-50}
      />

      <PredictionContainer
        style={{ opacity: interpolateOpacityByFrame(frame, 170, 178, 1, 0) }}
      >
        <WinnersContainer>
          <H2
            style={{
              fontSize: "5em",
              lineHeight: "1.1em",
              color: theme.Copy[0],
              clipPath: FromBottomToTop(7, "Springy100"),
              opacity: interpolateOpacityByFrame(frame, 60, 70, 1, 0),
            }}
          >
            Our Prediction
          </H2>
          <Image
            src={WINNER.LOGO}
            radius={300}
            width={300}
            height={300}
            style={{
              textAlign: "center",
              transform: `scale(${SpringToFrom(60, 0, 1)}) scale(${SpringToFrom(
                160,
                1,
                0
              )})`,
              opacity: interpolateOpacityByFrame(frame, 168, 170, 1, 0),
            }}
          />
          <H1
            style={{
              fontSize: "5em",
              marginTop: ".4em",
              clipPath: FromMiddle(60, "Springy100"),
              opacity: interpolateOpacityByFrame(frame, 168, 170, 1, 0),
              color: theme.Copy[0],
            }}
          >
            {WINNER.Name}
          </H1>
        </WinnersContainer>
        <H3
          style={{
            clipPath: FromMiddle(60, "Springy100"),
            color: theme.Copy[0],
            fontSize: "5em",
            letterSpacing: "3px",
            lineHeight: ".8em",
            fontWeight: 900,
          }}
        >
          {RENDER.INPUTDATA.SelectIsLiveStreamed
            ? `Follow this game on LMS TV from ${TIME(DATA.DATAOBJ.Time)}`
            : false}{" "}
        </H3>

        <H2
          style={{
            transform: `scale(${SpringToFrom(75, 0, 1)}) scale(${SpringToFrom(
              160,
              1,
              0
            )})`,
            opacity: interpolateOpacityByFrame(frame, 168, 170, 1, 0),
            color: theme.Copy[0],
            fontSize: "4em",
          }}
        >
          GOOD LUCK TO BOTH TEAMS!
        </H2>
      </PredictionContainer>
    </SeriesContainer>
  );
};
