/* eslint-disable react/jsx-pascal-case */
import { useSelector } from "react-redux";
import { Table, Space, Container } from "@mantine/core";
// Hooks
import { useChangeLeagueStatus } from "../../Hooks/Content/useLeagues";

// Compoennts

import { P } from "../../Theme/type";
import { PageHeader } from "../../Components/interface/ProductHeader";
import { ICONDB } from "../../Theme/icons";
import {
  BTN_ICON_CALLBACK,
  BTN_ICON_LINK,
} from "../../Theme/Structure/Button/BTNS";
// UI ELEMENTS
import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";
import { BLOCK_Section_SubHeading } from "../../Components/interface/BLOCK_Section_SubHeading";
import { VideoWizardContainer } from "../../Theme/Structure/BodyContainer";
import { useEffect, useState } from "react";
import { UISkeletonLines } from "../../Components/Global/Skeleton";
import {
  TABLE_WRAPPER,
  TABLE_THEAD_WRAPPER,
} from "../../Components/interface/Wrapper/Table";
import { AccessDenied } from "../../Components/interface/AccessDenied";

import { orderBy, sortBy } from "lodash";

export const FranchiseManagerLeagues = () => {
  const UXSTATE = useSelector((state) => state.UXSTATE);
  const AUTH = useSelector((state) => state.AUTH);

  console.log(AUTH.AUTHUSER.hasFullAccess);

  //console.log(UXSTATE.SelectedFranchise.attributes.watch_lists.data)
  const [LeagueStatus, ChangeLeagueStatus] = useChangeLeagueStatus();
  const [isUpdating, setIsUpdating] = useState(false);

  const changeStatus = (id, status) => {
    ChangeLeagueStatus({ hasCompleted: status }, id);
    setIsUpdating(true);
  };

  useEffect(() => {
    if (LeagueStatus) {
      setIsUpdating(false);
    }
  }, [LeagueStatus]);

  const CreateTable = (IsActive, IsNull) => {
    //const ARR=[]
    const Leagues = UXSTATE.SelectedFranchise.attributes.watch_lists.data;
    console.log(sortBy(Leagues, (item) => item.attributes.Name, ["asc"]));
    return orderBy(Leagues, (item) => item.attributes.Name, ["asc"]).map(
      (element, i) => {
        if (
          element.attributes.hasCompleted === IsActive ||
          element.attributes.hasCompleted === IsNull
        )
          return (
            <tr key={element.attributes.Name}>
              <td>
                {element.attributes.hasCompleted ? (
                  <P colorID={6}>{ICONDB.CircleX.Component}</P>
                ) : (
                  <P colorID={7}>{ICONDB.CircleCheck.Component}</P>
                )}
              </td>

              <td>{element.attributes.Name}</td>

              <td>
                <BTN_ICON_LINK
                  ICON="ExternalLink"
                  TIPOBJ={{
                    LABEL: "Visit League",
                    COLOR: "gray",
                    POSITION: "top",
                  }}
                  TO={`https://www.lastmanstands.com/leagues/standings/${element.attributes.PATH}`}
                />
              </td>
              <td>
                <BTN_ICON_CALLBACK
                  CALLBACK={() => {
                    changeStatus(element.id, !element.attributes.hasCompleted);
                  }}
                  ICON={
                    element.attributes.hasCompleted ? "CircleCheck" : "CircleX"
                  }
                  TIPOBJ={{
                    LABEL: element.attributes.hasCompleted
                      ? "Reactivate League"
                      : "Archive League",
                    COLOR: element.attributes.hasCompleted ? "Green" : "Red",
                    POSITION: "top",
                  }}
                />
              </td>
            </tr>
          );
      }
    );
  };
  if (!AUTH.AUTHUSER.hasFullAccess) return <AccessDenied />;
  if (isUpdating) return <UISkeletonLines />;
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Manage your Leagues",
          ICON: "Download",
          DESCRIPTION: "Manage your current and completed leagues",
          TOOLBAR: false,
        }}
      />
      <Space h={"md"} />

      <BLOCK_Section_SubHeading LABEL="Active Leagues" Color={7} />
      <Container size="lg" py={10}>
        <P>Here is a list of all of your current active leagues. These are the leagues that will be used for
          video content and statistic generation when a data sync is run. 
          In order to assure you have the most accurate data for your franchise, archive any old or completed leagues from this list. 
          </P>
      </Container>
      <Container size="lg" p={0}>
        <VideoWizardContainer>
          <TABLE_WRAPPER>
            <TABLE_THEAD_WRAPPER HEADERS={["", "League", "LMS", ""]} />
            <tbody>{CreateTable(false, null)}</tbody>
          </TABLE_WRAPPER>
        </VideoWizardContainer>
      </Container>
      <Space h={80} />
      <BLOCK_Section_SubHeading LABEL="Previous Leagues" Color={6} />
      <Container size="lg" py={10}>
        <P>
          Any Leagues marked as Previous for longer that 14 days will be
          automatically marked as completed and will be removed from the system.
        </P>
      </Container>
      <Container size="lg" p={0}>
        <VideoWizardContainer>
          <TABLE_WRAPPER>
            <TABLE_THEAD_WRAPPER HEADERS={["", "League", "LMS", ""]} />

            <tbody>{CreateTable(true)}</tbody>
          </TABLE_WRAPPER>
        </VideoWizardContainer>
      </Container>
    </>
  );
};
