import { useEffect, useState } from "react";
import { useFindTeamName } from "../../Hooks/Content/useFindTeam";
import { Image, Input, Button, Table, Space } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { H3, P } from "../../Theme/type";
import { IsLoading } from "../../Components/Global/isLoadingDefault";
import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";
import { BTN_ICON_CALLBACK } from "../../Theme/Structure/Button/BTNS";
import { PageHeader } from "../../Components/interface/ProductHeader";
import {
  TABLE_TD,
  TABLE_THEAD_WRAPPER,
  TABLE_WRAPPER,
} from "../../Components/interface/Wrapper/Table";
//setSectionID={setSectionID} setSelectedTeam

export const SelectATeam = ({ setSectionID, setSelectedTeam }) => {
  const [TeamName, FindTeamName, error] = useFindTeamName();
  const [isSeaching, setIsSearching] = useState(false)

  let timeout = null;
  const handleChange = (e) => {
    const value = e.target.value.trim(); // remove whitespace from the input value

    if (value.length > 4) {
      // check that the input value is greater than 3 characters

      // clear the previous timeout if it exists
      if (timeout) {
        clearTimeout(timeout);
      }

      // set a new timeout to call the FindTeamName function
      timeout = setTimeout(() => {
        FindTeamName(value);
        setIsSearching(true)
      }, 1000);
    }
  };
  useEffect(() => {
    console.log(error)
    if(TeamName.length !== 0 || error !== null ){
      setIsSearching(false)
    }
  
  }, [TeamName]);

  return (
    <>
      <PageHeader NavPosition="Teams" />
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Team Search",
          ICON: "Search",
          DESCRIPTION:
            "A player's name or the team they have played for can be used as a search term.",
          TOOLBAR: false,
        }}
      />
      <H3 align="right">Results {TeamName.length}</H3>
      <Input
        onChange={handleChange}
        styles={(theme) => ({
          input: {
            "&, &:focus": {
              borderColor: theme.colors.brand[2],
              backgroundColor: theme.colors.brand[2],
              color: theme.colors.brand[0],
            },
          },
        })}
      />
      <Space h="xl" />
      {isSeaching ? (
        <>{error ? <div>An error occurred: {error}</div> : <IsLoading />}</>
      ) : (
        <Results
          TeamName={TeamName}
          setSectionID={setSectionID}
          setSelectedTeam={setSelectedTeam}
        />
      )}
    </>
  );
};

const Results = ({ TeamName, setSectionID, setSelectedTeam }) => {
  const SMRemoval = useMediaQuery("(min-width: 992px)");
  if (TeamName === null) return false;
  if (TeamName.length === 0)
    return (
      <>
        <Space h="md" />
        <P colorID={4}>
          There are currently {TeamName.length} Results for the search term
          provided! Please check the spelling is correct.
        </P>
      </>
    );

  return (
    <>
      <TABLE_WRAPPER>
        <TABLE_THEAD_WRAPPER
          DISPLAY={["None", "table-header-group"]}
          HEADERS={["", "Team", "Games", " "]}
        />
        <TABLE_THEAD_WRAPPER
          DISPLAY={["table-header-group", "None"]}
          HEADERS={[" ", "Team", "ID", "Games", "Win Ratio", "Ranking", " "]}
        />

        <tbody>
          {TeamName.map((Team, i) => {
            return (
              <tr key={i}>
                <TABLE_TD>
                  <Image
                    src={`${Team?.attributes.Logo.data?.attributes.url}`}
                    radius={100}
                    width={50}
                    height={50}
                    alt={Team.attributes.Name}
                  />
                </TABLE_TD>
                <TABLE_TD>{Team.attributes.Name}</TABLE_TD>
                <TABLE_TD HIDE={true}>{Team.attributes.TeamID}</TABLE_TD>
                <TABLE_TD>{Team.attributes.Games}</TABLE_TD>
                <TABLE_TD HIDE={true}>{Team.attributes.Win_Ratio}</TABLE_TD>
                <TABLE_TD HIDE={true}>{Team.attributes.World_Rank}</TABLE_TD>
                <TABLE_TD>
                  <BTN_ICON_CALLBACK
                    ICON="Eye"
                    TIPOBJ={{
                      LABEL: `View profile for ${Team.attributes.Name}`,
                      COLOR: "gray",
                      POSITION: "top",
                    }}
                    CALLBACK={() => {
                      setSectionID(1);
                      setSelectedTeam(Team);
                    }}
                  />
                </TABLE_TD>
              </tr>
            );
          })}
        </tbody>
      </TABLE_WRAPPER>
    </>
  );
};
