import { Series } from "remotion";
import { H1 } from "../../../../utils/Text";
import { ScoreCardWrapper } from "./ScoreCardWrapper";
import { useTheme } from "styled-components";

export const FIXTURELIST = ({ DATA, Dimensions }) => {
  const theme = useTheme();
  //console.log(DATA)
  const Durations = {
    Length: 270,
    Begin: 0, // bring in overlay
    TransitionAfter: 30, // slide overlay down
    RemoveScorcard: 180, // slide overlay down
  };

  if (Dimensions.length === 0) return false;
  return (
    <Series>
      {Object.keys(DATA.Fixtures).map((Label, i) => {
        //console.log(DATA.Fixtures[Label].length,Math.ceil(DATA.Fixtures[Label].length/2),((DATA.Fixtures[Label].length/2)*Durations.Length))
        return (
          <Series.Sequence
            key={i}
            durationInFrames={
              Math.ceil(DATA.Fixtures[Label].length / 2) * Durations.Length
            }
            layout="none"
          >
            <H1
              style={{
                position: "fixed",
                top: "10px",
                fontSize: "35px",
                lineHeight: ".9em",
                color: theme.Copy[0],
              }}
            >
              {Label}
            </H1>
            <ScoreCardWrapper DATA={DATA} LABEL={Label} Durations={Durations} />
          </Series.Sequence>
        );
      })}
    </Series>
  );
};
