import {Audio } from 'remotion';

export const AudioBaseTrack = ()=>{
    return(
        <Audio 
            src={`https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/TournamentPOTDItem.mp3`}
            playbackRate={1}
            startFrom={0} // if composition is 30fps, then it will start at 2s
        />
    )
} //https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/TournamentTitleMusic_v2.mp3