import { useEffect } from "react";
import { Routes, Route,useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
// Auth Routes
import Dashboard from "../Dashboard/Dashboard";
import {DisplayMilestones} from '../Milestones/index'
import {AchievedDisplayMilestones} from '../Milestones/Achieved'
import {RenderPreview} from '../RenderPreview/index'
//STATISTICS

import {SeasonStats} from "../Numbers/StatisticsSeasonGeneral";
import {GeneralContainer} from '../Numbers/General/index'
import {StatisticsBatting} from '../Numbers/Batting/index'
import {StatisticsBowling} from '../Numbers/Bowling/index'
import {StatisticsTeams} from '../Numbers/Teams/index'
import {PlayersContainer} from '../Numbers/Players/index'
import {CommentaryAssistant} from '../Numbers/CommentaryAssistant/index'


//import UsersOrders from "../UNUSED_UsersOrders";
import Downloads from "../Downloads/downloads";
import { DownloadItem } from "../Downloads/DownloadItem";

//REPOSITORY
import EditPlayerProfile from "../PlayerProfile/RepositoryPlayer";
import EditTeamProfile from "../TeamProfile/RepositoryTeam";
// SETTINGS
import {SettingsVideoDefault} from '../settings/SettingsDefaultVideo'
import {BattingPOTDSettings} from '../settings/VideoSettings/BattingPOTD' 
import {BowlingPOTDSettings} from '../settings/VideoSettings/BowlingPOTD' 
import {SettingsCreateNewSponsor} from '../settings/SettingsCreateNewSponsor'
import {THEMES} from '../settings/Themes/index'
import {TEMPLATES} from '../settings/Templates/index'
// Writeups
import {GameReviews} from "../Writeups/index";
//FRANCHISE
import {FranchiseHub} from "../Franchise"; 
import {FranchiseDataChecker} from "../Franchise/FranchiseDataChecker";
import {FranchiseGroupRegions} from '../Franchise/FranchiseGroupRegions'
import {FranchiseManagerLeagues} from '../Franchise/FranchiseManageLeagues'
import Tournaments from "../Franchise/tournaments";
//ADMIN
import Logout from "../Admin/logout";
import { ADMINHUB } from "../../Auth/Admin/index";
import {SUPERADMINHUB} from '../../Auth/SuperAdmin/index';
import { HOW_TO } from "../Howto";

ReactGA.initialize("G-5MLHD6G08Z");

export const MAINROUTES = () => {
  let location = useLocation();

  useEffect(() => {
    // Google Analytics
    console.log(window.location.pathname)
    ReactGA.send({ hitType: "pageview", page: window.location.pathname }); 
  }, [location]);


  return(
    <>
        <HomeRoutes />
        <REPOSITORYRoutes />
        <STATISTICSRoutes />
        <FRANCHISERoutes />
        <SETTINGSRoutes /> 
        <WriteupRoutes />
        <ADMINRoutes />
    </>
  );
};

const HomeRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/downloads" element={<Downloads />} />
      <Route path="/downloadable/:id" element={<DownloadItem />} />
      <Route path="/milestones" element={<DisplayMilestones />} />
      <Route path="/approaching-milestone" element={<DisplayMilestones />} />
      <Route path="/achieved-milestone" element={<AchievedDisplayMilestones />} />
      <Route path="/preview-order" element={<RenderPreview />} />
      
     {/*  <Route path="/orders" element={<UsersOrders />} /> */}
    </Routes>
  );
};
//REPOSITORY
const REPOSITORYRoutes = () => {
  return (
    <Routes>
      <Route path="/player" element={<EditPlayerProfile />} />
      <Route path="/teams" element={<EditTeamProfile />} />
    </Routes>
  );
};


//STATISTICS
const STATISTICSRoutes = () => {
  return (
    <Routes>
      <Route path="/franchise-statistics" element={<GeneralContainer />} />
      <Route path="/franchise-statistics-batting" element={<StatisticsBatting />} />
      <Route path="/franchise-statistics-bowling" element={<StatisticsBowling />} />
      <Route path="/franchise-statistics-teams" element={<StatisticsTeams />} />
      <Route path="/franchise-statistics-players" element={<PlayersContainer />} />
      <Route path="/commentary-assistant" element={<CommentaryAssistant />} />
      
      
    </Routes>
  );
};

//FRANCHISE
const FRANCHISERoutes = () => {
  return (
    <Routes>
      <Route path="/franchise" element={<FranchiseHub />} />
      <Route path="/franchise-data-checker" element={<FranchiseDataChecker />} /> 
      <Route path="/franchise-group-region" element={<FranchiseGroupRegions />} /> 
      <Route path="/franchise-manage-leagues" element={<FranchiseManagerLeagues />} /> 
      
      <Route path="/tournaments" element={<Tournaments />} />
    </Routes>
  );
};

//SETTINGS
const SETTINGSRoutes = () => {
  return (
    <Routes>
      <Route path="/settings" element={<SettingsVideoDefault />} />
      <Route path="/create-new-sponsor" element={<SettingsCreateNewSponsor />} />
      <Route path="/settings-batting" element={<BattingPOTDSettings />} />
      <Route path="/settings-bowling" element={<BowlingPOTDSettings />} />
      <Route path="/themes" element={<THEMES />} />
      <Route path="/templates" element={<TEMPLATES />} />
      
      
    </Routes>
  );
};

//Writeups

const WriteupRoutes = () => {
  return (
    <Routes>
      <Route path="/game-reviews" element={<GameReviews />} />
    </Routes>
  );
};
//ADMIN
const ADMINRoutes = () => {
  return (
    <Routes>
      <Route path="/logout" element={<Logout />} />
      <Route path="/admin" element={<ADMINHUB />} />
      <Route path="/super-admin" element={<SUPERADMINHUB />} />
      <Route path="/how-to" element={<HOW_TO />} />
    </Routes>
  );
};
