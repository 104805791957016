import { Series, useCurrentFrame, Img } from "remotion";
import { Lottie } from "@remotion/lottie";

import animationthumb from "./Components/Lottie/thumb.json";
import LMS from "../../../../../../Assets/defaultLarge.png";
import styled, { useTheme } from "styled-components";
// Animation
import { EraseFromMiddle } from "../../../../../../Animation/ClipWipe";

import { interpolateOpacityByFrame } from "../../../../../../Animation/interpolate";

// Components

import { COPY, EMPASISVALUE } from "./Components/Copy";
import { H3 } from "../../../../../utils/Text";
import { TOPTOBOTTOM } from "./Components/ToptoBottom";
const LMSIMGSTYLES = {
  mixBlendMode: "luminosity",
  position: "absolute",
  bottom: "-50%", 
  left: "-50%",
  width: "200%",
  opacity: 0.05,
};
export const SECTION_AVERAGES = ({ DATA, RENDER }) => {
  const theme = useTheme();
  console.log(DATA.DATAOBJ.DATERANGESTATS.AVG);
  const SectionLength = 180;

  const SetPositioning = {
    Top: {
      TOP: [-100, -25],
      BOTTOM: [-10, 80],
    },
    Bottom: {
      TOP: [-25, -100],
      BOTTOM: [80, -4],
    },
  };
  //Delete items from OBJ
  //delete DATA.DATAOBJ.DATERANGESTATS.AVG.AVGBOWLINGSTRIKERATE;
  //delete DATA.DATAOBJ.DATERANGESTATS.AVG.AVGWICKETS;
  const ARR = [
    "AVGTOTALINNINGSRUNS",
    "AVGTOTALINNINGSOVERS",
    "AVGTOTALINNINGSWICKETS",
    "AVGBATTINGRUNS",
  ];
  /*
const OBJ = {
    AVGBATTINGRUNS: "Batting Average",
    AVGBOWLINGECO: "Bowling Economy",

    AVGTOTALINNINGSOVERS: "Overs per Inning",
    AVGTOTALINNINGSRUNS: "Average Inning Runs",
    AVGTOTALINNINGSWICKETS: "Average Wickets Lost",
   
  };
*/
  return (
    <SeriesContainer>
      <Series>
        {ARR.map((AVG, i) => {
          return (
            <Series.Sequence durationInFrames={SectionLength} layout="none">
              <TITLE
                style={{
                  color: `${theme.Copy[0]}`,
                }}
              >
                How does it compare?
              </TITLE>
              <AVGWrapper
                DATA={DATA}
                key={AVG}
                KEY={AVG}
                POSITION={
                  i % 2 === 0 ? SetPositioning.Top : SetPositioning.Bottom
                }
              />
              <Img src={LMS} style={LMSIMGSTYLES} />
            </Series.Sequence>
          );
        })}
      </Series>
    </SeriesContainer>
  );
};

const AVGWrapper = ({ DATA, KEY, POSITION }) => {
  console.log(DATA);
  const frame = useCurrentFrame();

  const SeasonCompare = (WEEK, SEASON) =>
    WEEK >= SEASON ? " Up On " : " Down On ";
  const SeasonCompareThumb = (WEEK, SEASON) => (WEEK >= SEASON ? 0 : 180);

  return (
    <SeriesContainer>
      <div style={{ zIndex: 3000 }}>
        <COPY DURATION={180}>
          <SectionLabel KEY={KEY} />
          <br />
          <EMPASISVALUE
            VALUE={DATA.DATAOBJ.DATERANGESTATS.AVG[KEY].toFixed(2)}
          />
        </COPY>
      </div>

      <H3
        style={{
          zIndex: 5000,
          position: "absolute",
          bottom: "280px",
          lineHeight: "1em",
          fontSize: "3em",
          opacity: interpolateOpacityByFrame(frame, 15, 30, 0, 1),
          clipPath: EraseFromMiddle(180 - 15, "Springy100"),
        }}
      >
        {SeasonCompare(
          DATA.DATAOBJ.DATERANGESTATS.AVG[KEY].toFixed(2),
          DATA.DATAOBJ.SEASONAVERAGES[KEY].toFixed(2)
        )}{" "}
        the Season Average {DATA.DATAOBJ.SEASONAVERAGES[KEY].toFixed(2)}
      </H3>
      <Lottie
        loop={true}
        playbackRate={0.3}
        style={{
          width: "600px",

          textAlign: "center",
          position: "absolute",
          bottom: "180px",
          zIndex: 1000,
          opacity: interpolateOpacityByFrame(frame, 0, 15, 0, 1),
          clipPath: EraseFromMiddle(180 - 15, "Springy100"),
          transform: `rotate(${SeasonCompareThumb(
            DATA.DATAOBJ.DATERANGESTATS.AVG[KEY].toFixed(2),
            DATA.DATAOBJ.SEASONAVERAGES[KEY].toFixed(2)
          )}deg)`,
        }}
        animationData={animationthumb}
      />
      <TOPTOBOTTOM POSITIONING={POSITION} />
    </SeriesContainer>
  );
};

/** Containers */
const SeriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const TITLE = styled.h1`
  font-family: "Arial";
  z-index: 300;
  font-weight: 900;
  font-size: 7em;
  line-height: 1em;
  text-align: center;
  letter-spacing: -3px;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  color: ${(props) => props.theme.Copy[1]};
  position: absolute;
  top: 10px;
`;

const SectionLabel = ({ KEY }) => {
  const OBJ = {
    AVGTOTALINNINGSRUNS: "Average Inning Score",
    AVGTOTALINNINGSOVERS: "Overs per Inning",
    AVGTOTALINNINGSWICKETS: "Average Wickets Lost",
    AVGBATTINGRUNS: "Batting Average",
  };

  return OBJ[KEY];
};
