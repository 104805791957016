/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateMatchReviewImages } from "../../../store/Reducer/VideoRendering";
import { Container, Grid } from "@mantine/core";

// Components
import { SinglePlayerImagewithChangeOption } from "./Components/SinglePlayerImagewithChangeOptions";

// UTILS
import { findIndex } from "lodash";
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";

// Components
import { UploadNewPlayerImageContainer } from "./Components/UploadNewPlayerImageContainer";
import { H3 } from "../../../Theme/type";

/* ******************************************************************** 
// PLAYER IMAGE, UI DISPLAY WITH CHANGE OFD IMAGE OPTION

1. Image Gallery Containers
2. 

/* ******************************************************************** */
export const EditMatchReview = ({ PlayerImages }) => {

  const [NewImage, SetNewImage] = useState(false);
  const [Player, SetPlayer] = useState(false);
  const [selectFromGallery, SetFromGallery] = useState(false);
  const dispatch = useDispatch();
  const ChangeImage = (id) => {
    SetPlayer(id);
    SetNewImage(true);
  };

  useEffect(() => {
    SetNewImage(false);
  }, [PlayerImages, selectFromGallery]);

  const UpdateDATAOBJ = (ImageFile) => {
    Object.keys(PlayerImages.DATAOBJ.Performances).map((key, i) => {
      const UPDATEINDEX = findIndex(
        PlayerImages.DATAOBJ.Performances[key],
        (o) => {
          return o.ID === Player.ID;
        }
      );
      if (UPDATEINDEX !== -1) {
        dispatch(
          updateMatchReviewImages({
            ID: UPDATEINDEX,
            key: key,
            url: ImageFile.url,
          })
        );
      }
    });
  };

  if (NewImage)
    return (
      <UploadNewPlayerImageContainer
        Player={Player}
        SetNewImage={SetNewImage}
        UpdateDATAOBJ={UpdateDATAOBJ}
      />
    );
  return (
    <>
      <Container size={"lg"} my={30}>
        <BLOCK_Section_SubHeading LABEL="Image Selector" Color={5} />
        {Object.keys(PlayerImages.DATAOBJ.Performances).map((perf, i) => {
         
          return (
            <Container key={i}>
              <H3>Player Performance : {perf}</H3>
              <Grid columns={12} justify="flex-start" align="flex-start">
                {PlayerImages.DATAOBJ.Performances[perf].map((IMAGE, i) => {
                  console.log(IMAGE);
                  return (
                    <Grid.Col
                      span={6}
                      xs={6}
                      sm={4}
                      md={3}
                      xl={3}
                      justify="center"
                      align="center"
                      key={i}
                    >
                      <SinglePlayerImagewithChangeOption
                        IMAGE={IMAGE.IMAGE}
                        NAME={IMAGE.Name}
                        PLAYER={IMAGE}
                        SetFromGallery={SetFromGallery}
                        ChangeImage={ChangeImage}
                        DispatchFUNC={updateMatchReviewImages}
                        INDEX={i}
                        hasGallery={false}
                      />
                    </Grid.Col>
                  );
                })}
              </Grid>
            </Container>
          );
        })}
      </Container>
    </>
  );
};
