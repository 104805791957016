import { MantineProvider } from '@mantine/core';

export const MantineProviderWrapper = (props)=>{
    return(
        <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          /** Put your mantine theme override here */ 
          colorScheme: 'light',
          fontFamily: 'Roboto,Roboto,sans-serif',
          colors: {
            brand: [ '#EEF2F3','#D0D3D6', '#667A86', '#333638', '#C98127', '#84B1CB','#F9716D', '#54B288', '#00C0FF', '#1B6B96'],
            Public: [ '#f9f9f9', '#333638','#84B1CB', '#C98127','#EEF2F3','#ffff'],
            gradients:[
              'linear-gradient(0deg, #84B1CB 0%, #84B1CB 100%)',
              'linear-gradient(180deg, #84B1CB 0%, #84B1CB 100%)',
                'linear-gradient(180deg, #F0F0F0 0%, #D7D7D7 100%)',
                'linear-gradient(180deg, #D7D7D7 0%, #F0F0F0 100%)',
              ]
           
            /* brand: [ '#EEF2F3','#DFE3E6', '#616161', '#4A5060', '#EC8E1F', '#4A819E','#F9716D', '#4FB575', '#82c5f0', '#1B6B96'],
            Public: [ '#f9f9f9', '#4A5060','#4A819E', '#EC8E1F','#EEF2F3','#ffff'], */
           /*  gradients:[
              'linear-gradient(0deg, #4A819E 0%, #99DCFF 100%)',
              'linear-gradient(180deg, #4A819E 0%, #99DCFF 100%)',
                'linear-gradient(180deg, #F0F0F0 0%, #D7D7D7 100%)',
                'linear-gradient(180deg, #D7D7D7 0%, #F0F0F0 100%)',
              ] */
          }, 
          primaryColor: 'brand',
        }}
    
       >{props.children}</MantineProvider> 
    )
}