import { useState } from "react"
import { orderBy,groupBy,findIndex } from "lodash";


const OffsetTime = 210
const VideoOBJ={
    OpeningSceneDuration:90,
    EndCreditsDuration:120,
    SponsorTiming:120, 
    ItemOffset:OffsetTime,
    VideoBodyTiming:(OffsetTime*5),
    VideoDurationinFrames:(((90+120)+(OffsetTime*5)+120)),  
    RenderStill:[40,190,360,560,760,950,1160,1300]
}
 
export const useBuildVideoData = ()=>{
    const VideoSettings = { ...VideoOBJ };
    const [VIDEODATA ,setVIDEODATA] = useState({VideoSettings})
 
    const FindTeam = (TEAMS)=>{
        const TEAM = groupBy(TEAMS,'TeamID')
      //console.log(TEAM)
        const sorted_categories  = Object.keys(TEAM).sort(function (one, other) {
        return one.length - other.length;
     })
     return TEAM[sorted_categories[0]]
}

    // UTILS
    const findSIXES=(DATA)=>{
  
      
        const ARR=[]
        DATA.map((ITEM,i)=>{
            
            let INDEX = findIndex(ARR, (o)=>{return o.ID === ITEM.attributes.player.data.id})
        
                if(parseInt(ITEM.attributes.BATTING_Runs) > 50){
                    ARR.push({
                        ID:ITEM.attributes.player.data.id,
                        Name:ITEM.attributes.player.data.attributes.Name,
                        VARIABLE: parseInt(ITEM.attributes.BATTING_SR),
                        RUNS: parseInt(ITEM.attributes.BATTING_Runs),
                        BALLS: parseInt(ITEM.attributes.BATTING_Balls),
                        IMAGE:ITEM?.attributes.player.data?.attributes.image.data?.attributes.url,
                        TeamCount:[ITEM.attributes.team.data.attributes], 
                    })
                }


        })

     
        const SLICE = orderBy(ARR,['VARIABLE'],['desc']).slice(0,5)
        SLICE.map((top5,i)=>{ SLICE[i].Team = FindTeam(top5.TeamCount)[0]})
      
        return SLICE;
    }


    // Main Function
    const FindVIDEODATA =async(DATA)=>{
       
        const CATEGORY = await findSIXES(DATA)
      //console.log(CATEGORY)
        const DATAOBJ={ CATEGORY:CATEGORY.reverse(),}
        setVIDEODATA({...VIDEODATA,DATAOBJ:{...DATAOBJ}}) 
       //setResults({...Results,...DATAOBJ})
    } 

    return[VIDEODATA ,FindVIDEODATA] 
}