
import { useState } from "react" 
import { useSelector } from 'react-redux'


export const useIsLoggedUser = (ctx)=>{
    const [loggedUser,setloggedUser] = useState(null);
    const AUTH = useSelector((state) => state.AUTH); 

    const FindloggedUser = async (ID)=>{
        //console.log(parseInt(ID), AUTH.AUTHUSER.id)
        parseInt(ID) === AUTH.AUTHUSER.id ? setloggedUser(true):setloggedUser(false)
        // if(loggedUser != null){ loggedUser ? true:Router.push('/')}
    }

    return[loggedUser,FindloggedUser]
}