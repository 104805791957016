import { useSelector } from 'react-redux'


import {ColoredContainer, BorderedContainer, ContentContainerx3 } from "../../../Theme/Structure/BodyContainer"
import {H3, H4_UPPERCASE, P} from '../../../Theme/type';
import { Box, Center, Group, Space  } from '@mantine/core';
import {BarChart} from '../../../Components/Charts/Bar'
import {LineChart} from '../../../Components/Charts/Line'

import {ICONDB} from '../../../Theme/icons';
import {orderBy} from 'lodash'

// UI Elements
import {BLOCK_Section_SubHeading} from '../../../Components/interface/BLOCK_Section_SubHeading'
import { BLOCK_Section_Title } from '../../../Components/interface/BLOCK_Section_Title';


export const StatisticsTeams = ()=>{
    const UXSTATE = useSelector((state) => state.UXSTATE);
    if (UXSTATE.SelectedFranchise.attributes?.FranchiseStats === undefined)
    return <>Loading Data</>;
    return(
        <>
        <BLOCK_Section_Title
        OBJ={{
          LABEL: "Team Statistics Overview",
          ICON: "ChartDots",
          DESCRIPTION: "A statistical overview of the active seasons.",
          TOOLBAR: false,
        }}
      />

      <Space h="xl" />
        
  
            <BarChartTopTeamsRuns STATS={UXSTATE.SelectedFranchise.attributes.FranchiseStats} />
            <BarChartTopTeamsRunsAVG STATS={UXSTATE.SelectedFranchise.attributes.FranchiseStats}/>
            <BarChartTopTeamsWickets STATS={UXSTATE.SelectedFranchise.attributes.FranchiseStats} />
            <BarChartTopTeamsWicketsAVG STATS={UXSTATE.SelectedFranchise.attributes.FranchiseStats} />
        </>
      
    )
}

export const TournamentTeamsContainer = ({FranchiseStats})=>{ 
    return(
        <>
            <BLOCK_Section_SubHeading LABEL='TOP TEAMS' Color={5}/>
            <BarChartTopTeamsRuns STATS={FranchiseStats.franchise.data.attributes.FranchiseStats} />
            <BarChartTopTeamsRunsAVG STATS={FranchiseStats.franchise.data.attributes.FranchiseStats}/>
            <BarChartTopTeamsWickets STATS={FranchiseStats.franchise.data.attributes.FranchiseStats} />
            <BarChartTopTeamsWicketsAVG STATS={FranchiseStats.franchise.data.attributes.FranchiseStats} />
        </>
      
    )
}


   



// TEAMS
const BarChartTopTeamsRuns=({STATS})=>{
    const RenderHistory=(TYPE)=>{
        const ASSETS=TYPE
        return [orderBy(STATS.TeamStats,['RUNSFOR'],['desc']).slice(0,10),ASSETS];
    }
    return(
        <>
          <ColoredContainer Padding='xs'>
                    <H3 colorID={3} weight={900} >Total Team Runs</H3>
                    <P>TOP 10 Teams by runs, Against there Runs Against</P>
                </ColoredContainer>
                <ContentContainerx3  BGColor={1}>
                    <div style={{height:'450px'}}>
                       
                        <BarChart 
                        DATA={RenderHistory(['RUNSFOR','RUNSAGAINST'])} 
                        Settings={{
                          indexBy:"Name"
                          }
                      }
                      />
                        </div>
                </ContentContainerx3>
        </>
    )
}

const BarChartTopTeamsRunsAVG=({STATS})=>{
    const RenderHistory=(TYPE)=>{
        const ASSETS=TYPE
        return [orderBy(STATS.TeamStats,['TEAMBATTINGAVG'],['desc']).slice(0,10),ASSETS];
    }
    return(
        <>
          <ColoredContainer Padding='xs'>
                    <H3 colorID={3} weight={900} >Average Team Runs</H3>
                    <P>TOP 10 Teams by there average runs scored per game, Against there average runs against</P>
                </ColoredContainer>
                <ContentContainerx3  BGColor={1}>
                    <div style={{height:'450px'}}>
                       
                        <BarChart 
                        DATA={RenderHistory(['TEAMBATTINGAVG','TEAMCONCEDINGAVG'])} 
                        Settings={{
                          indexBy:"Name"
                          }
                      }
                      />
                        </div>
                </ContentContainerx3>
        </>
    )
}

const BarChartTopTeamsWickets=({STATS})=>{
        const RenderHistory=(TYPE)=>{
        const ASSETS=TYPE
        return [orderBy(STATS.TeamStats,['WICKETSFOR'],['desc']).slice(0,10),ASSETS];
    }
    return(
        <>
          <ColoredContainer Padding='xs'>
                <H3 colorID={3} weight={900} >Total Team by Wickets Taken</H3>
                    <P>TOP 10 Teams by wickets Taken, Against there Wickets Lost</P>
                </ColoredContainer>
                <ContentContainerx3  BGColor={1}>
                    <div style={{height:'450px'}}>
                       
                        <BarChart 
                        DATA={RenderHistory(['WICKETSFOR','WICKETSAGAINST'])} 
                        Settings={{
                          indexBy:"Name"
                          }
                      }
                      />
                        </div>
                </ContentContainerx3>
        </>
    )
}

const BarChartTopTeamsWicketsAVG=({STATS})=>{
        const RenderHistory=(TYPE)=>{
        const ASSETS=TYPE
        return [orderBy(STATS.TeamStats,['TEAMWICKETAVG'],['desc']).slice(0,10),ASSETS];
    }
    return(
        <>
          <ColoredContainer Padding='xs'>
                    <H3 colorID={3} weight={900} >Average Team Wickets</H3>
                    <P>TOP 10 Teams by there Average Wickets taken per game, against there wickets lost.</P>
                </ColoredContainer>
                <ContentContainerx3  BGColor={1}>
                    <div style={{height:'450px'}}>
                       
                        <BarChart 
                        DATA={RenderHistory(['TEAMWICKETAVG','TEAMWICKETLOSTAVG'])} 
                        Settings={{
                          indexBy:"Name"
                          }
                      }
                      />
                        </div>
                </ContentContainerx3>
        </>
    )
}