export const ImageRatio = (IMAGE) => {
  /*
     height:320,
        width:'auto'
    */
   console.log(IMAGE)
  if (IMAGE.width > IMAGE.height) {
    //
    return { 
      XLarge: { height: 700, width: "auto" },
      Large: { height: 500, width: "auto" },
      MainTitle: { height: 320, width: "auto" },
      SponsorTitle:{ height: 250, width: "auto" },
      SponsorLogo:{ height: 300, width: "auto" },
      Medium:{ height: 200, width: "auto" },
      Small:{ height: 150, width: "auto" },
    };
  } else if (IMAGE.width < IMAGE.height) {
    //it's a portrait
    return {
      XLarge: { height:  "auto", width: 700},
      Large: { height:  "auto", width: 500 },
      MainTitle: { height: "auto", width: 320 },
      SponsorTitle:{ height: 'auto', width: 250 },
      SponsorLogo:{ height: 'auto', width: 300 },
      Medium:{ height: "auto",width: 200 },
      Small:{ height: "auto",width: 150 }
    };
  } else {
    //image width and height are equal, therefore it is square.
    return {
      XLarge: { height:  700, width: 700},
      Large: { height:  500, width: 500 },
      MainTitle: { height: 320, width: 320 },
      SponsorTitle:{ height: 250, width: 250 },
      SponsorLogo:{ height: 300, width: 300 },
      Medium:{ height: 200,width: 200 },
      Small:{ height: 150,width: 150 }
    };
  }
};
