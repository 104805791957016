import { useCurrentFrame, Audio, AbsoluteFill, Video, Img } from "remotion";
import { useTheme } from "styled-components";
// styled components
import {
  FrostedContainer,
  FullWidthWrapper,
  LogoInnerContainer,
} from "../../utils/Containers";
import { H3, H1, H2 } from "../../utils/Text";
import { Grid, Image } from "@mantine/core";
import { SpringToFrom } from "../../../Animation/RemotionSpring";
import { interpolateOpacityByFrame } from "../../../Animation/interpolate";

const VIDEO = {
  mixBlendMode: "luminosity",
  opacity: 0.1,
};

export const TEAMSEASONALREVIEWOPENINGTITLES = ({
  MainTitle,
  Subtitle,
  TEAMLOGO = null,
  VideoDate = null,
}) => {
  console.log(VideoDate);
  const theme = useTheme();
  const frame = useCurrentFrame();
  return (
    <FullWidthWrapper>
      <H1
        style={{
          color: theme.Copy[0],
          position:'absolute',
          zIndex:500,
          top:'30px',
          left:'220px',
          width:'auto',
          opacity: interpolateOpacityByFrame(frame, 0, 30, 0, 1),
          transform: `scale(${SpringToFrom(75, 1, 0, "Springy100")})`,
          textAlign: "left",
          fontSize: "5rem",
          padding: "1rem 0",
        }}
      >
        {VideoDate.DATAOBJ.General.Team}
      </H1>
      <IntroTeamLogo LOGO={VideoDate.DATAOBJ.General.LOGO} />
      

      <H3
        style={{
          opacity: interpolateOpacityByFrame(frame, 0, 30, 0, 1),
          fontSize: "3.4rem",
          padding: "3rem 0 1rem",
          color: theme.Copy[0],
          position:'absolute',
          bottom:'20px',
          zIndex:1500,
          transform: `scale(${SpringToFrom(75, 1, 0, "Springy100")})`,
        }}
      >
       Season Review
      </H3>
      {
        VideoDate.DATAOBJ.General.HeroImage === undefined ?false : <TeamImage URL={VideoDate.DATAOBJ.General.HeroImage} />
      }
      
      <FrostedContainer />

      <AbsoluteFill>
        <Video
          startFrom={60}
          src={
            "https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/BGVideo001.mp4"
          }
          style={VIDEO}
        />
      </AbsoluteFill>
      <Audio
        src={`https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/DivisionWinnerIntroVersion1.mp3`}
        playbackRate={1}
        startFrom={0} // if composition is 30fps, then it will start at 2s
      />
    </FullWidthWrapper>
  );
};

const IntroTeamLogo = ({ LOGO }) => {
  const frame = useCurrentFrame();
  const RATIO = 200;
  return (
    <LogoInnerContainer
      style={{
        opacity: interpolateOpacityByFrame(frame, 0, 60, 0, 1),
        width: `${RATIO}px`,
        height: "auto",
        transform: `scale(${SpringToFrom(75, 1, 0, "Springy100")})`,
        position: "absolute",
        top: "10px",
        left:'10px',
        zIndex:500,
      }}
    >
      <Image src={LOGO} width={RATIO} radius={RATIO} height={RATIO} />
    </LogoInnerContainer>
  );
};
//<Img src={LOGO} width={500} height={500} style={{ borderRadius:'500px'}} />

const TeamImage = ({ URL }) => {
  const frame = useCurrentFrame();
  const RATIO = 1350;
  return (
    <div
      style={{
        objectFit: "cover",
        height: "1350px",
        position: "absolute",
        top: "0px",
        opacity: interpolateOpacityByFrame(frame, 0, 60, 0, 1),
        
        
        zIndex:400,
        transform: `scale(${SpringToFrom(75, 1, 0, "Springy100")})`,
      }}
    >
      <Image src={URL} width={'auto'} radius={10} height={RATIO} />
    </div>
  );
};
