import { Loader } from "@mantine/core"
import { useEffect } from "react"
import {useRunFranchiseChecker} from "../../../Hooks/Auth/useRunFranchiseChecker"
import { BTN_ICON_CALLBACK } from "../../../Theme/Structure/Button/BTNS"

export const TournamentSyncData = ({_ID,isUpdating,setisUpdating})=>{
    const [FranchiseChecker, RunFranchiseChecker]  = useRunFranchiseChecker()
    

    const handleSync = ()=>{
        setisUpdating(true)
      //console.log("SYNC TOURNAMENT ", _ID)
        RunFranchiseChecker(_ID)
    }


    useEffect(()=>{
        //console.log('FranchiseChecker ', FranchiseChecker)
        if(FranchiseChecker !== false){
            setisUpdating(false);
          
            //console.log('FranchiseChecker not false ', FranchiseChecker)
        }
       
    },[FranchiseChecker])

    if(isUpdating)
    return(<Loader color="teal" size="md"/>)
    return(
        <BTN_ICON_CALLBACK 
        CALLBACK={()=>{handleSync()}}  
        ICON='Refresh'
        BG={1}
        COLOR={7} 
        TIPOBJ={{
            LABEL:'Sync Tournament data',
            COLOR:'blue',
            POSITION:'top'
        }}
    />
    )
}