import {
  Image,
  Text,
  Container,
  ThemeIcon,
  Title,
  SimpleGrid,
  createStyles,
} from "@mantine/core";
import { SectionWrapper } from "../Components/Containers";
const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: 80,
    paddingBottom: 50,
  },

  item: {
    display: "flex",
  },

  itemIcon: {
    padding: theme.spacing.xs,
    marginRight: theme.spacing.md,
  },

  itemTitle: {
    marginBottom: 0,
    color: theme.colors.Public[1],
  },

  supTitle: {
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: 800,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.Public[2],
    letterSpacing: 0.5,
  },

  title: {
    lineHeight: 1,
    textAlign: "center",
    marginTop: theme.spacing.xs,
    color: theme.colors.Public[1],
  },

  description: {
    textAlign: "center",
    marginTop: theme.spacing.xs,
  },

  highlight: {
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,
    padding: 5,
    paddingTop: 0,
    borderRadius: theme.radius.sm,
    display: "inline-block",
    color: theme.colorScheme === "dark" ? theme.white : "inherit",
  },
}));

const Testimonials = [
  /* {
    image: "5991_1eb4e5f35d.jpeg",
    title: "Phil Callaghan",
    franchise: "LMS Melbourne",
    description:
      "They divvy up their prey evenly among the members of their pack",
  }, */
  {
    image: "114878_066f18f3d3.jpeg",
    title: "Trent Thomson",
    franchise: "LMS Central Coast",
    description:
      "Statto makes the posting of match day reviews and individual milestones a breeze. The app is user friendly and the videos add a professional touch to my local league.",
  },
  /* {
    image: "147855_75f8e2a4bc.jpeg",
    title: "Matt Turbill",
    franchise: "LMS beaudesert",
    description: "Fans obsess over the particular length and angle of its arms",
  }, */
];

export function FeaturedTestimonials() {
  const { classes } = useStyles();

  const supTitle = "How our users are finding it";

  const items = Testimonials.map((item, i) => (
    <div className={classes.item} key={i}>
      <Image
        src={`/${item.image}`}
        radius={100}
        width={100}
        height={100}
        style={{ margin: "0 10px 0 0" }}
      />

      <div>
        <Text weight={700} size="lg" className={classes.itemTitle}>
          {item.title}
        </Text>
        <Text weight={400} size="sm" className={classes.itemTitle}>
          {item.franchise}
        </Text>

        <Text color="dimmed">{item.description}</Text>
      </div>
    </div>
  ));

  return (
    <SectionWrapper>
      <Container size={"lg"} className={classes.wrapper}>
        <Text className={classes.supTitle}>{supTitle}</Text>

        <Title className={classes.title} order={2} transform="uppercase">
          Testimonals from the LMS World
        </Title>

        <SimpleGrid
          cols={3}
          spacing={50}
          breakpoints={[{ maxWidth: 765, cols: 1, spacing: 40 }]}
          style={{ marginTop: 30 }}
        >
          {items}
        </SimpleGrid>
      </Container>
    </SectionWrapper>
  );
}
