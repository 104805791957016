/* eslint-disable react/jsx-pascal-case */
import { useSelector } from "react-redux";
import { useFindDuplicatePerformanceFixtures } from "../../Hooks/Auth/useFindNullFixtures";

import { useEffect, useState } from "react";

import { BLOCK_Section_SubHeading } from "../../Components/interface/BLOCK_Section_SubHeading";
import { UISkeletonLines } from "../../Components/Global/Skeleton";

import { AccessDenied } from "../../Components/interface/AccessDenied";
import { BTN_CTA } from "../../Theme/Structure/Button/BTNS";
import { BLOCK_ToolBar } from "../../Components/interface/BLOCK_Toolbar";
import { VideoWizardContainer } from "../../Theme/Structure/BodyContainer";
import { P } from "../../Theme/type";

export const FindDuplicatesInStrapi = () => {
  const [FindDuplicatePerformance, DeleteFindDuplicatePerformance] =
    useFindDuplicatePerformanceFixtures();

  const [isSyncing, setSync] = useState(false);

  //const UXSTATE = useSelector((state) => state.UXSTATE);
  const AUTH = useSelector((state) => state.AUTH);

  console.log(AUTH.AUTHUSER.id);

  const FindDuplicates = () => {
    //console.log('Find DUPOLICATES OBJS');
    setSync(true);
    DeleteFindDuplicatePerformance();
  };

  useEffect(() => {
    console.log(FindDuplicatePerformance);
    if (FindDuplicatePerformance !== null) {
      setSync(false);
    }
  }, [FindDuplicatePerformance]);

  if (isSyncing) return <UISkeletonLines />;
  if (AUTH.AUTHUSER.id !== 1) return <AccessDenied />;
  return (
    <VideoWizardContainer>
      <BLOCK_Section_SubHeading LABEL="Find Duplicate" Color={5} />
      <P>Find and remove all Duplicate player stats</P>
      <BLOCK_ToolBar
        TOOLS={[
          <BTN_CTA CALLBACK={FindDuplicates} LABEL={"Find Duplicates"} />,
        ]}
      />
    </VideoWizardContainer>
  );
};
