
import { useState } from "react" 
import { useSelector,useDispatch } from 'react-redux'
import {ENV} from '../../Config/RunTimeConfig'

import { parseCookies } from 'nookies'
const qs = require('qs');

// FUNC : GET SINGLE

// FUNC : GET 
export const useGetRegionalSeasons = (ctx)=>{

    const [getRegionalSeasons,setgetRegionalSeasons] = useState(null)
    const UXSTATE = useSelector((state) => state.UXSTATE);
    const jwt = parseCookies(ctx).jwt
    
    const FetchRegionalSeasons = async ()=>{
        if(UXSTATE?.SelectedFranchise?.id === undefined)
        return false
        
        const query = qs.stringify({
            filters: {
                franchise: { id:{ $eq: UXSTATE.SelectedFranchise.id,}},
              },
              fields:['Name','isComplete'], 
              populate:{
                watch_lists:{
                    fields:['Name']
                }
              } ,    
          }, {
            encodeValuesOnly: true,
          });

        const res = await fetch(`${ENV.API_URL}/api/regional-seasons?${query}`, { 
            headers: {
                Authorization: `Bearer ${jwt}`
            }
        })
        const loginResponse = await res.json();

        setgetRegionalSeasons(loginResponse.data)
    }
    return[getRegionalSeasons,FetchRegionalSeasons] 
}
/*
Name: "Sydney East Winter 2022"
createdAt: "2022-04-23T04:48:34.129Z"
franchise: {data: {id: 1, attributes: {Name: "Sydney", PATH: "play-cricket/t20/fixtures/city?city=sydney",…}}}
isComplete: false
updatedAt: "2022-06-25T01:35:15.231Z"
users_permissions_user: {data: {id: 1,…}}
watch_lists
*/

// FUNC : CREATE
export const useCreateDivisionGrouping=(ctx)=>{

    const [DivisionGroup, setDivisionGroup] = useState(false)
    const dispatch = useDispatch()
    const jwt = parseCookies(ctx).jwt

    const CreateDivisionGroup = async(_DATA)=>{
        //console.log(_DATA)
        const res = await fetch(`${ENV.API_URL}/api/regional-seasons`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({
                data: _DATA
            })
        })
        const InvoiceResponse = await res.json();
        //console.log(InvoiceResponse)
        setDivisionGroup(true)
    }  

    return[DivisionGroup,CreateDivisionGroup] 
}




// FUNC : UPDATE 
export const usePutDivisionGroupingStatus= (ctx)=>{

    const [DivisionGroupingStatus,setDivisionGroupingStatus] = useState(null);
    const jwt = parseCookies(ctx).jwt
    
    const PutDivisionGroupingStatus = async (_DATA,_ID)=>{
        const res = await fetch(`${ENV.API_URL}/api/regional-seasons/${_ID}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}` 
            },
            body: JSON.stringify({
                data: _DATA 
            })
        })
        const GroupingStatus = await res.json();
        //console.log(GroupingStatus)

        setDivisionGroupingStatus(GroupingStatus.data)
    }
    return[DivisionGroupingStatus,PutDivisionGroupingStatus]
}