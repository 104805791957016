import { useEffect, useState } from 'react';
import { Group,Image, Center, Space } from '@mantine/core';

import {UploadTeamGalleryImage} from '../../../Hooks/Utils/useUploadTeamImages'

//import {useGetPlayerSingle} from '../../../Hooks/Content/usePlayer'
import {useFindSingleTeam} from '../../../Hooks/Content/useFindTeam'
import {ColoredContainer} from '../../../Theme/Structure/BodyContainer'
import { BTN_CREATE } from '../../../Theme/Structure/Button/BTNS';
import DropZone from '../../../Components/Global/DropZone'
// UI ELEMENTS
//import {BLOCK_Section_Title} from '../../../Components/interface/BLOCK_Section_Title'
import {BLOCK_Section_SubHeading_INVERT} from '../../../Components/interface/BLOCK_Section_SubHeading'
// Add new item to Gallery

export const AddNewItemToGallery = ({setAddNew, SelectedTeam,setSelectedTeam})=>{

    const [isClosedDisabled, setCloseDisabled] = useState(false)

    const [GalleryImage,UploadGalleryImage] = UploadTeamGalleryImage()  
    // quietly fetch the player again after upload
    const [TeamName,FindTeamName]  = useFindSingleTeam()
    const [ImageFile, setImageFile] = useState(false);

    const AddAnother=()=>{setImageFile(false)}

      useEffect(()=>{
          if( ImageFile?.id !== undefined){
             UploadGalleryImage(ImageFile,SelectedTeam)
          }
      },[ImageFile]) 
      
      useEffect(()=>{
          if(GalleryImage === true){
            FindTeamName(SelectedTeam.id)
          }
      },[GalleryImage]) 


      useEffect(()=>{
        if(TeamName !== false){
            setSelectedTeam(TeamName);
            setCloseDisabled(false)
        }
       
      },[TeamName])
    return(
        <>
           <BLOCK_Section_SubHeading_INVERT LABEL='Add a new Gallery Image' Color={5}/>
            <Group position="right" spacing="sm" grow >
                {
                    !ImageFile ? false : <BTN_CREATE BG={7} COPY={0}   CALLBACK={()=>{ AddAnother(true)}} LABEL="Add Another"  TIPOBJ={{
                        LABEL: "Upload another image to the Gallery",
                        COLOR: "green",
                        POSITION: "right",
                      }}/>
                }
                <BTN_CREATE BG={6} COPY={0} CALLBACK={()=>{ setAddNew(false)}} LABEL="Close" 
                TIPOBJ={{
                    LABEL: "Close Uploader",
                    COLOR: "red",
                    POSITION: "top",
                  }}
                  DISABLED={isClosedDisabled} />
            </Group>
            <Space h='md' /> 
            
        {
                    !ImageFile ? <DropZone setImageFile={setImageFile} setCloseDisabled={setCloseDisabled}/> : <>
                        <ColoredContainer  BGColor={1}>
                                <Center>
                                    <Image
                                        radius="md"
                                        height={300}
                                        width={300}
                                        src={ImageFile.url}
                                        alt="Team Image"
                                        fit="cover"
                                    />
                            
                            </Center>
                        </ColoredContainer>
                    </>
                }
            <Space h='md' />
        </>
    )
}