/* eslint-disable react/jsx-pascal-case */
import { FORMATTTIME } from "../../../../../actions/UX";
// UI Elements
import { H3, P } from "../../../../../Theme/type";
import { BTN_ICON_CALLBACK } from "../../../../../Theme/Structure/Button/BTNS";
import { Container, Divider, Space } from "@mantine/core";
import { BLOCK_ToolBar } from "../../../../../Components/interface/BLOCK_Toolbar";

export const GameMeta = ({ Fixture, setPath,setConfirm }) => {
  return (
    <>
      <Container>
        
        <H3 lineheight={`2em`} size={30}>
          {Fixture.attributes.HomeTeam} vs {Fixture.attributes.AwayTeam}
        </H3>
        <H3 colorID={3} size={20} weight={400} lineheight={`1.5em`}>
          League: {Fixture.attributes.watch_list.data.attributes.Name}
        </H3>

        <P>Date : {FORMATTTIME(Fixture.attributes.Date)}</P>
        <P>Kick-off : {Fixture.attributes.Time}</P>
        <BLOCK_ToolBar
          TOOLS={[
            
            <BTN_ICON_CALLBACK
              CALLBACK={() => {
                setPath(1);
              }}
              ICON={"InfoCircle"}
              TIPOBJ={{
                LABEL: `More on the League`,
                COLOR: "yellow", 
                POSITION: "top",
              }}
            />,<BTN_ICON_CALLBACK
              CALLBACK={() => {
                setConfirm(false);
              }}
              ICON={"ArrowBackUp"}
              TIPOBJ={{
                LABEL: `Select a new Game`,
                COLOR: "red",
                POSITION: "top",
              }}
            />
          ]}
        />
        
        <Divider my="xl"  size="sm"   labelPosition="center"  label="THE TEAMS"/>
      </Container>
      
    </>
  );
};
