import { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import {ENV} from '../../Config/RunTimeConfig'
import { parseCookies,setCookie } from 'nookies'
const qs = require('qs');

// FUNC : GET 

export  const useGetSubscriptions = (ctx)=>{
    const [Subscription, setSubscription] = useState(false)
    const AUTHID = parseCookies(ctx).AUTHID
    const jwt = parseCookies(ctx).jwt
   
    const GetSubscription = async ()=>{
        const query = qs.stringify({
          
            sort: ['createdAt:desc'],
            filters: {
                users_permissions_user: { id:{ $eq: AUTHID,}},
              },
              populate: ['franchise'],  
          }, {
            encodeValuesOnly: true,
          });

        const res = await fetch(`${ENV.API_URL}/api/subscriptions?${query}`, {
            headers: {
                Authorization: `Bearer ${jwt}`
            }
        })
        const subscriptionsResponse = await res.json(); 
       //console.log(subscriptionsResponse)
        setSubscription(subscriptionsResponse.data) 
    }
   
    return[Subscription,GetSubscription] 
}