/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetOrder } from "../../Hooks/Content/useOrders";

import { Header } from "../../Theme/type";
import { Space, Tabs } from "@mantine/core";
import { ColoredContainer } from "../../Theme/Structure/BodyContainer";

import { UISkeletonLines } from "../../Components/Global/Skeleton";

// TABS
import { TAB_VIDEO } from "./Components/TABS/Video";
import { TAB_IMAGES } from "./Components/TABS/Images";
import { TAB_RAWDATA } from "./Components/TABS/RawData";
import { TAB_SETTINGS } from "./Components/TABS/Settings";
import { TAB_WRITEUP } from "./Components/TABS/GTP3Writeups";

import {
  IconPhoto,
  IconMessageCircle,
  IconSettings,
  IconVideo,
  IconDatabase,
} from "@tabler/icons";
export const DownloadItem = () => {
  let { id } = useParams();
  const [order, GetOrder] = useGetOrder();
  //const AUTH = useSelector((state) => state.AUTH);
  const [Order, setOrder] = useState(false);
  useEffect(() => {
    GetOrder(id);
  }, [id]);
  useEffect(() => {
    setOrder(order);
  }, [order]);

  if (order === null) return <UISkeletonLines />;
  return (
    <>
      <Space h="xl" />
      <ColoredContainer Padding={0}>
        <Header>DOWNLOAD : {order.attributes.AssetType}</Header>
      </ColoredContainer>
      <Space h="xl" />

      <ColoredContainer Padding={0} size={"md"}>
        <Tabs defaultValue="Video" variant="pills" radius="sm" color="blue">
          <Tabs.List grow position="center">
            <Tabs.Tab value="Video" icon={<IconVideo size={24} />}>
              Video
            </Tabs.Tab>
            <Tabs.Tab value="Images" icon={<IconPhoto size={24} />}>
              Images
            </Tabs.Tab>
            <Tabs.Tab value="Writeup" icon={<IconMessageCircle size={24} />}>
              Create Post
            </Tabs.Tab>
            <Tabs.Tab value="Data" icon={<IconDatabase size={24} />}>
              Data
            </Tabs.Tab>
            <Tabs.Tab value="settings" icon={<IconSettings size={24} />}>
              Settings
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="Video" pt="xs">
            <TAB_VIDEO downloadables={order.attributes.render_lists.data} />
          </Tabs.Panel>
          <Tabs.Panel value="Images" pt="xs">
            <TAB_IMAGES
              GALLERY={
                order.attributes.render_lists.data[0].attributes?.Rendered_Still
                  ?.data 
              }
            />
          </Tabs.Panel>
          <Tabs.Panel value="Writeup" pt="xs">
            <TAB_WRITEUP Order={order} />
          </Tabs.Panel>
          <Tabs.Panel value="Data" pt="xs">
            <TAB_RAWDATA Order={order} />
          </Tabs.Panel>
          <Tabs.Panel value="settings" pt="xs">
            <TAB_SETTINGS ORDER={order} />
          </Tabs.Panel>
        </Tabs>
      </ColoredContainer>
    </>
  );
};
