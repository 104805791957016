import { Table } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { P } from "../../../Theme/type";

export const TABLE_WRAPPER = (props) => {
  return (
    <Table
      striped
      highlightOnHover
      verticalSpacing="xs"
      fontSize="sm"
      liststyletype={"none"}
    >
      {props.children}
    </Table>
  );
};

export const TABLE_THEAD_WRAPPER = ({
  HEADERS,
  DISPLAY = ["table-header-group", "table-header-group"],
}) => {
  const Width = useMediaQuery("(min-width: 992px)");
  return (
    <thead style={{ display: Width ? DISPLAY[0] : DISPLAY[1] }}>
      <tr>
        {HEADERS.map((item, i) => {
          return (
            <th key={i}>
              <P weight={900} colorID={5} size={"xs"} transform="uppercase">
                {item}
              </P>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export const TABLE_TD = (props) => {
  const Width = useMediaQuery("(max-width: 992px)");
  const { HIDE = false, Padding='10px 10px' } = props;
  return (
    <td
      style={{ padding:Padding, display: Width ? (HIDE ? "none" : "table-cell") : "table-cell" }}
    >
      <P>{props.children}</P>
    </td>
  );
};
