import { groupBy, remove, findIndex, orderBy, omit, pick } from "lodash";

const DEFAULTIMAGE='https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/playeroption4_de5eede10d.png'
/* **************************************************** */
/*  UTILS FOR TOP 5 VIDEOS */
/* **************************************************** */
export const MergeTop5APICallBack = (DATA, PATH) => {
  const PLAYERSHASH = [];
  let INDEX;
  DATA.map((item, i) => {
    item.attributes.STATS.PLAYERS.map((player, i) => {
      INDEX = findIndex(PLAYERSHASH, (o) => {
        return o.id === player.id;
      });
      if (INDEX === -1)
        PLAYERSHASH.push({
          Name: player.Name,
          LMSID: player.LMSID,
          id: player.id,
        });
    });
  });

  DATA.map((item, i) => {
    item.attributes.STATS.PLAYERS.map((player, i) => {
      INDEX = findIndex(PLAYERSHASH, (o) => {
        return o.id === player.id;
      });
      if (INDEX != -1) {
        PLAYERSHASH[INDEX] = sumObjectsByKey(PLAYERSHASH[INDEX], player);
      }
    });
  });

  let ITEMS = remove(PLAYERSHASH, function (n) {
    return n[PATH] !== undefined;
  });

  return ITEMS;
};

export const CleanTop5DATA = (Player, VARIABLE) => {
  const ARR = [];
  Player.map((ITEM, i) => {
    //console.log(ITEM?.image?.data?.attributes?.url)
    ARR.push({
      LMSID: ITEM.LMSID,
      ID: ITEM.id,
      Name: ITEM.Name,
      VARIABLE: ITEM[VARIABLE],
      IMAGE: ITEM?.image?.data?.attributes?.url !== undefined ? ITEM?.image?.data?.attributes?.url:DEFAULTIMAGE, 
      TeamCount: StrapiTeams(
        ITEM.player_battings.data,
        ITEM.player_bowlings.data
      ),
    });
  }); 

  ARR.map((top5, i) => {
    ARR[i].Team = FindTeam(top5.TeamCount)[0];
    ARR[i].TeamCount = [];
  });

  //console.log(ARR);
  return { CATEGORY: ARR.reverse() };
};

const StrapiTeams = (BAT, BOWL) => {
  const ARR = [];
  BAT.map((item, i) => {
    ARR.push(item.attributes.team.data.attributes);
  });
  BOWL.map((item, i) => {
    ARR.push(item.attributes.team.data.attributes);
  });

  return ARR;
};

const FindTeam = (TEAMS) => {
  const TEAM = groupBy(TEAMS, "TeamID");
  //console.log(TEAM)
  //const sorted_categories  = Object.keys(TEAM).sort()
  const sorted_categories = orderBy(
    TEAM,
    (o) => {
      return o.length;
    },
    ["desc"]
  );
  //console.log(sorted_categories[0]);
  return sorted_categories[0];
};

const sumObjectsByKey = (...objs) => {
  return objs.reduce((a, b) => {
    for (let k in b) {
      // exclude name and ID from sumof
      if (k === "Name" || k === "LMSID" || k === "id") {
        a[k] = b[k];
      } else if (b.hasOwnProperty(k)) {
        a[k] = (a[k] || 0) + b[k];
      }
    }
    return a;
  }, {});
};
/* END TOP 5 **************************************************** */

export const OrderPlayerRankings = (DATA, PATH) => {
  const PLAYERSHASH = [];
  let INDEX;
  DATA.map((item, i) => {
    item.attributes.STATS.PLAYERS.map((player, i) => {
      console.log(player);
      INDEX = findIndex(PLAYERSHASH, (o) => {
        return o.id === player.id;
      });
      if (INDEX === -1)
        PLAYERSHASH.push({
          Name: player.Name,
          LMSID: player.LMSID,
          id: player.id,
          Variable: parseInt(player[PATH]),
        });
    });
  });

  console.log(PLAYERSHASH);

  /*   DATA.map((item, i) => {
    item.attributes.STATS.PLAYERS.map((player, i) => {
      INDEX = findIndex(PLAYERSHASH, (o) => {
        return o.id === player.id;
      });
      if (INDEX != -1) {
        PLAYERSHASH[INDEX] = sumObjectsByKey(PLAYERSHASH[INDEX], player);
      }
    });
  });

  let ITEMS = remove(PLAYERSHASH, function (n) {
    return n[PATH] !== undefined;
  }); */

  return PLAYERSHASH;
};
