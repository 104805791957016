import {useSelector } from "react-redux";
// Theme
import { H2, H3, H4_UPPERCASE, P } from "../../../../Theme/type";
import {

  VideoWizardContainer,
} from "../../../../Theme/Structure/BodyContainer";
import { Grid, Group, Space, Stack } from "@mantine/core";
import moment from "moment";
export const DataCheck = ({ VIDEODATA }) => {
  const RENDER = useSelector((state) => state.RENDER);
  console.log(RENDER)
  const ConvertData = (FromDate) => {
    const d = new Date(`${FromDate}`);
    return moment(d).format("DD MMMM");
  };
  const ConvertTime = (FromDate) => {
    const TIME = FromDate.split(":");
    return `${TIME[0]}:${TIME[1]}`;
  };


  return (
    <>
      <VideoWizardContainer>
        <H2>
          {RENDER.DATAOBJ.DATAOBJ.TeamA.Name} vs {RENDER.DATAOBJ.DATAOBJ.TeamB.Name}
        </H2>
        <P>
          {ConvertData(RENDER.DATAOBJ.DATAOBJ.Date)} -{" "}
          {ConvertTime(RENDER.DATAOBJ.DATAOBJ.Time)}
        </P>
        <P>
          {RENDER.DATAOBJ.DATAOBJ.ground} - {RENDER.DATAOBJ.DATAOBJ.umpire}
        </P>
      </VideoWizardContainer>
      <TeamDetails TEAM={RENDER.DATAOBJ.DATAOBJ.TeamA} />
      <TeamDetails TEAM={RENDER.DATAOBJ.DATAOBJ.TeamB} />
    </>
  );
};
//<FixtureMetaData VIDEODATA={VIDEODATA}/>

const TeamDetails = ({ TEAM }) => {

  return (
    <VideoWizardContainer>
      <H3>{TEAM.Name}</H3>
      <H4_UPPERCASE>Rank</H4_UPPERCASE>
      <Group>
        <P>City : {TEAM.City_Rank}</P>
        <P>National :{TEAM.National_Rank}</P>
        <P>World : {TEAM.World_Rank}</P>
      </Group>
      <Space h="md" />
      <H4_UPPERCASE>Win Ratio ({TEAM.Win_Ratio})</H4_UPPERCASE>
      <Group>
        <P>Won : {TEAM.Won}</P>
        <P>Lost :{TEAM.Lost}</P>
      </Group>

    </VideoWizardContainer>
  );
};
