import { useEffect } from "react";

// Hooks
import { useGetLeagues } from "../../Hooks/Content/useLeagues";
import { useGetRegionalSeasons } from "../../Hooks/Content/useRegionalSeasons";
// Components
import { LeagueNumbers } from "./DashboardWidgets/LeagueNumbers";
import { AssetQuickLink } from "./DashboardWidgets/AssetQuickLink";
import { UpcomingFixtures } from "./DashboardWidgets/UpcomingFixtures";
import { DownloadHistory } from "./DashboardWidgets/UserDownloadHistory";
import { RecentlyCreatedAsset } from "./DashboardWidgets/RecentlyCreatedAsset";
import { Sponsorships } from "./DashboardWidgets/Widget_Sponsorships";
// Widgets

import { IwouldLikeTo } from "./DashboardWidgets/IWouldLikeTo";
import { DashHeader } from "./DashboardWidgets/DashHeader";
import { BASESTATS } from "../Numbers/General";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const [getLeagues, FetchgetLeagues] = useGetLeagues();
  const [getRegionalSeasons, FetchRegionalSeasons] = useGetRegionalSeasons();
  const UXSTATE = useSelector((state) => state.UXSTATE);

  // Leageus
  useEffect(() => {
    FetchgetLeagues();
    FetchRegionalSeasons();
  }, []);
  useEffect(() => {}, [getLeagues, getRegionalSeasons]);

  return (
    <>
      <DashHeader />
      <IwouldLikeTo />
      <LeagueNumbers />
      <Sponsorships />

      <UpcomingFixtures />
      <DownloadHistory />
    </>
  );
};
export default Dashboard;

//<RecentlyCreatedAsset />
