import { useEffect, useState } from "react";
import {
  Card,
  Image,
  Input,
  Text,
  Grid,
  Button,
  Group,
  useMantineTheme,
  Table,
  Loader,
  Space,
} from "@mantine/core";
import {
  BodyContainer,
  BorderedContainer,
  ColoredContainer,
} from "../../Theme/Structure/BodyContainer";
import { useFindPlayerName } from "../../Hooks/user/useFindPlayerName";
import { IsLoading } from "../../Components/Global/isLoadingDefault";

export const InputFindAPlayer = ({ CALLBACK }) => {
  const [PlayerName, FindPlayerName] = useFindPlayerName();
  const [SelectedPlayer, setSelectedPlayer] = useState();
  const handleChange = (e) => {
    FindPlayerName(e.target.value);
  };
  useEffect(() => {
  //console.log(PlayerName);
  }, [PlayerName]);
  useEffect(() => {
    CALLBACK(SelectedPlayer);
  }, [SelectedPlayer]);

  return (
    <>
      <Input onChange={handleChange} />
      {PlayerName === false ? (
        <>
          <Space h="xl" />
          <IsLoading />
        </>
      ) : (
        <Results
          PlayerName={PlayerName}
          setSelectedPlayer={setSelectedPlayer}
        />
      )}
    </>
  );
};

const Results = ({ PlayerName, setSelectedPlayer }) => {
  const TeamCheck = (_PLAYER) => {
    //console.log(_PLAYER.attributes)
    const ARR = [];
    _PLAYER.attributes.player_battings.data.map((item, i) => {
      if (item.attributes.team?.data?.attributes.Name)
        ARR.push(item.attributes.team.data.attributes.Name);
    });
    _PLAYER.attributes.player_bowlings.data.map((item, i) => {
      if (item.attributes.team?.data?.attributes.Name)
        ARR.push(item.attributes.team.data.attributes.Name);
    });
    //console.log(_PLAYER.attributes.Name, ARR)
    return ARR[0];
  };
  //console.log(PlayerName)
  if (PlayerName === null) return false;
  if (PlayerName.length === 0) return false;

  return (
    <>
      
      <ColoredContainer BGColor={1} Padding={3}>
        <Table>
          <tbody>
            {PlayerName.slice(0, 5).map((Player, i) => {
              //console.log('PlayerName', Player)
              return (
                <tr key={i}>
                  <td>
                    <Image
                      src={`${Player.attributes?.image?.data?.attributes?.url}`}
                      radius={10}
                      width={30}
                      height={30}
                      alt={Player.attributes.Name}
                    />
                  </td>
                  <td>{Player.attributes.Name}</td>
               
                  <td>{TeamCheck(Player)}</td>
                  <td>
                    <Button
                      variant="light"
                      color="blue"
                      fullWidth
                      style={{ marginTop: 14 }}
                      onClick={() => {
                        setSelectedPlayer(Player);
                      }}
                    >
                      Select
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </ColoredContainer>
    </>
  );
};
