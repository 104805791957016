import { IndexHeader } from "../Sections/INDEX_HEADER";
import { HeroImageRight } from "../Sections/INDEX_HERO";
import { FooterSimple } from "../Sections/INDEX_Footer";

import { GetInTouchSimple } from "../Sections/INDEX_Contact";

export const PAGE_Signup = ()=>{
    return(
        <>
        <IndexHeader INDEX={1} />
        <HeroImageRight />
  
        <GetInTouchSimple />
  
        <FooterSimple />
      </>
    )
} 