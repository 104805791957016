import { orderBy, filter, flatten, findIndex } from "lodash";
import { useEffect, useState } from "react";

export const  ordinal_suffix_of=(i)=> {
  var j = i % 10,
      k = i % 100;
  if (j == 1 && k != 11) {
      return i + "st";
  }
  if (j == 2 && k != 12) {
      return i + "nd";
  }
  if (j == 3 && k != 13) {
      return i + "rd";
  }
  return i + "th";
}

export const RemoveWashouts = (DATA) => {
  return filter(DATA, (o) => {
    return (
      o.AVGTOTALINNINGSRUNS !== 25 &&
      o.AVGTOTALINNINGSRUNS !== 50 &&
      o.FIXTURES !== 0
    );
  });
};

/* **************************************** */
/* Stats for LMS TV Commentary Feature */
/* **************************************** */

const SUM = (VAL1, VAL2) => {
  return parseFloat(VAL1) + parseFloat(VAL2);
};
const WasOut = (OUT) => {
  return OUT !== "Not Out" ? 1 : 0;
};
const BatAvg = (RUNS, INN) => {
  return parseFloat(RUNS / INN);
};
const BatSR = (RUNS, BALLS) => {
  return parseFloat((RUNS / BALLS) * 100); 
};

const BowlAvg = (WKT, RUNS) => {
  return RUNS / WKT;
};
const BowlEco = (Overs, RUNS) => {
  return RUNS / Overs;
};
const BowlSR = (WKT, Overs) => {
  let balls = Math.floor(Overs) * 5;
  if (Overs % 1 !== 0) {
    balls = balls + (Overs % 1).toFixed(1).split(".")[1];
  }

  return balls / WKT;
};

const CLEANDATA = (DATA, TEAMID, OBJ) => {
  const ARR = [];
  DATA.map((item, i) => {
    if (item.attributes[OBJ].data.length !== 0) {
      ARR.push(
        filter(item.attributes[OBJ].data, (o) => {
          return o.attributes.team.data.id === TEAMID;
        })
      );
    }
  });
  return flatten(ARR);
};

export const BattingList = (DATA, TEAMID) => {
  const BATTING = CLEANDATA(DATA, TEAMID, "player_battings");

  const STAT = [];

  BATTING.map((INN, i) => {
    //console.log(INN);
    const INDEX = findIndex(STAT, function (o) {
      return o.PlayerID === INN.attributes.player.data.attributes.PlayerID;
    });

    if (INDEX === -1) {
      STAT.push({
        PlayerID: INN.attributes.player.data.attributes.PlayerID,
        Attributes: INN.attributes.player.data.attributes,
        BATTING_INNINGS: 1,
        BATTING_Balls: SUM(INN.attributes.BATTING_Balls, 0),
        BATTING_Runs: SUM(INN.attributes.BATTING_Runs, 0),
        BATTING_fours: SUM(INN.attributes.BATTING_fours, 0),
        BATTING_sixes: SUM(INN.attributes.BATTING_sixes, 0),
        BATTING_INN: [WasOut(INN.attributes.BATTING_HowOut)],
        BATTING_AVG: BatAvg(
          INN.attributes.BATTING_Runs,
          WasOut(INN.attributes.BATTING_HowOut)
        ),
        BATTING_SR: BatSR(
          INN.attributes.BATTING_Runs,
          INN.attributes.BATTING_Balls
        ),
      });
    } else {
      STAT[INDEX].BATTING_INNINGS = SUM(STAT[INDEX].BATTING_INNINGS, 1);
      STAT[INDEX].BATTING_Balls = SUM(
        STAT[INDEX].BATTING_Balls,
        INN.attributes.BATTING_Balls
      );
      STAT[INDEX].BATTING_Runs = SUM(
        STAT[INDEX].BATTING_Runs,
        INN.attributes.BATTING_Runs
      );
      STAT[INDEX].BATTING_fours = SUM(
        STAT[INDEX].BATTING_fours,
        INN.attributes.BATTING_fours
      );
      STAT[INDEX].BATTING_sixes = SUM(
        STAT[INDEX].BATTING_sixes,
        INN.attributes.BATTING_sixes
      );
      STAT[INDEX].BATTING_INN.push(WasOut(INN.attributes.BATTING_HowOut));
      STAT[INDEX].BATTING_AVG = BatAvg(
        STAT[INDEX].BATTING_Runs,
        STAT[INDEX].BATTING_INN.reduce((a, b) => a + b)
      );
      STAT[INDEX].BATTING_SR = BatSR(
        STAT[INDEX].BATTING_Runs,
        STAT[INDEX].BATTING_Balls
      );
    }
  });

  return STAT;
};

export const BowlingList = (DATA, TEAMID) => {
  const BOWLING = CLEANDATA(DATA, TEAMID, "player_bowlings");

  console.group(BOWLING);
  const STAT = [];

  BOWLING.map((INN, i) => {
    //console.log(INN);
    const INDEX = findIndex(STAT, function (o) {
      return o.PlayerID === INN.attributes.player.data.attributes.PlayerID;
    });

    if (INDEX === -1) {
      STAT.push({
        PlayerID: INN.attributes.player.data.attributes.PlayerID,
        Attributes: INN.attributes.player.data.attributes,
        BOWLING_INNINGS: 1,
        BOWLING_Overs: SUM(INN.attributes.BOWLING_Overs, 0),
        BOWLING_Runs: SUM(INN.attributes.BOWLING_Runs, 0),
        BOWLING_Wkts: SUM(INN.attributes.BOWLING_Wkts, 0),
        BOWLING_AVG: BowlAvg(
          INN.attributes.BOWLING_Wkts,
          INN.attributes.BOWLING_Runs
        ),
        BOWLING_ECO: BowlEco(
          INN.attributes.BOWLING_Overs,
          INN.attributes.BOWLING_Runs
        ),
        BOWLING_SR: BowlSR(
          INN.attributes.BOWLING_Wkts,
          INN.attributes.BOWLING_Overs
        ),
      });
    } else {
      STAT[INDEX].BOWLING_Wkts = SUM(
        STAT[INDEX].BOWLING_Wkts,
        INN.attributes.BOWLING_Wkts
      );
      STAT[INDEX].BOWLING_Overs = SUM(
        STAT[INDEX].BOWLING_Overs,
        INN.attributes.BOWLING_Overs
      );
      STAT[INDEX].BOWLING_Runs = SUM(
        STAT[INDEX].BOWLING_Runs,
        INN.attributes.BOWLING_Runs
      );
      STAT[INDEX].BOWLING_INNINGS = SUM(STAT[INDEX].BOWLING_INNINGS, 1);
      STAT[INDEX].BOWLING_AVG = BowlAvg(
        STAT[INDEX].BOWLING_Wkts,
        STAT[INDEX].BOWLING_Runs
      );
      STAT[INDEX].BOWLING_ECO = BowlEco(
        STAT[INDEX].BOWLING_Overs,
        STAT[INDEX].BOWLING_Runs
      );
      STAT[INDEX].BOWLING_SR = BowlSR(
        STAT[INDEX].BOWLING_Wkts,
        STAT[INDEX].BOWLING_Overs
      );
    }
  });

  //console.log(STAT);
  return STAT;
};

export const PositionInLeague = (DATA, ID, VARIABLE, Order = "asc") => {
  const ORDERDATA = orderBy(
    filter(DATA, (o) => {
      return parseFloat(o[VARIABLE]) && !isNaN(parseInt(o[VARIABLE]));
    }),
    (obj) => parseInt(obj[VARIABLE], 10),
    [Order]
  );
  //console.log(VARIABLE, ORDERDATA);
  return (
    findIndex(ORDERDATA, (o) => {
      return o.LMSID === ID;
    }) + 1
  );
};
