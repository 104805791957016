import { useState,useEffect } from "react";
import {VideoPickDateRange} from '../../settings/VideoPickDate';
import { H3} from "../../../Theme/type";
import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";
import { Group, Stack } from "@mantine/core";
import moment from 'moment';
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading"
export const SelectDateRange= ({setFromDate,FromDate})=>{
    
    const [value, setValue] = useState(FromDate)
    const [ItemState, setItemState] = useState(0)
    const [DisplaySelected,setDisplaySelected] = useState(false)


    useEffect(()=>{ 
        //console.log('value',value)
         setFromDate(value)
         setDisplaySelected(value)
        setItemState(10)
    },[value])
      
    return( 
        <> 
        <BLOCK_Section_SubHeading LABEL='Select a Date Range' BGColor={0} Color={DisplaySelected ? 7:5}/>
         
            <VideoWizardContainer  DividerSize={ItemState} Padding='xs'>
                <Group position="apart" spacing="xl" >
                   {/*  <VideoPickDate setFromDate={setValue}/>  */}
                    <VideoPickDateRange setFromDate={setValue}/> 
                    
                   {/*  {
                            DisplaySelected ? <RenderSelected DATE={value}/>  : false
                        } */}
                </Group>
             
            </VideoWizardContainer> 
               
        </>
      )
  } 
 
  /* const RenderSelected = ({DATE})=>{ 
    //console.log(DATE)
    return(
        <Stack align="center" justify="space-around">
            <H3 colorID={5}>From {moment(DATE[0]).format('ddd DD MMMM') } to {moment(DATE[1]).format('ddd DD MMMM') }</H3>
        </Stack>
    )
  } */