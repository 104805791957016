import {
  Center,
  Container,
  Grid,
  Group,
  Image,
  SimpleGrid,
  Box,
} from "@mantine/core";
import styled from "styled-components";
import { P } from "../../Theme/type";
import { SectionWrapper } from "../Components/Containers";
import { ExtraLarge, H2 } from "../Components/type";



export const IndexCreateContent = () => {
  return (
    <SectionWrapper BGCOLOR={4}>
      <Container
        size="lg"
        sx={(theme) => ({
          "@media (min-width: 1200px)": {
            padding: " 5em 0",
          },
          "@media (max-width: 1200px) and (min-width: 800px)": {
            padding: " 5em 0",
          },
          "@media (max-width: 800px) and (min-width: 755px)": {
            padding: " 5em 0",
          },
          "@media (max-width: 755px) and (min-width: 576px)": {
            padding: " 5em 0",
          },
          "@media (max-width: 576px)": {
            padding: " 5em 0",
          },
        })}
      >
        <SimpleGrid
          spacing="lg"
          breakpoints={[
            { minWidth: "md", cols: 2, spacing: "sm" },
            { maxWidth: "sm", cols: 1, spacing: "sm" },
            { maxWidth: "xs", cols: 1, spacing: "sm" },
          ]}
        >
          <Box
            style={{
              position: "relative",
              zIndex: 100,
            }}
          >
            <ExtraLarge weight={900} align="left" colorID={2}>
              The easy way to create engaging social media content
            </ExtraLarge>
            <H2 weight={100} align="left">
              Remove the weekly hassle of checking scorecards and player lookups
            </H2>
          </Box>
          <Center>
            <Image
              src={"/FloatingVideos.png"}
              align="center"
              width={"300px"}
              sx={(theme) => ({
                "@media (min-width: 1200px)": {
                  width: "350px",
                },
                "@media (max-width: 1200px) and (min-width: 800px)": {
                  width: "350px",
                },
                "@media (max-width: 800px) and (min-width: 755px)": {
                  width: "300px",
                },
                "@media (max-width: 755px) and (min-width: 576px)": {
                  width: "300px",
                },
                "@media (max-width: 576px)": {
                  width: "200px",
                },
              })}
            />
          </Center>
        </SimpleGrid>
      </Container>
    </SectionWrapper>
  );
};
