/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { Space, SimpleGrid, Loader } from "@mantine/core";

import { VideoWizardContainer } from "../../Theme/Structure/BodyContainer";
import {
  BTN_ICON_CALLBACK,
  BTN_ICON_LINK,
} from "../../Theme/Structure/Button/BTNS";

// Profile Comonents
import { DefaultProfileImageContainer } from "./Components/DefaultProfileImageContainer";
import { TrackedPerformances } from "./Components/TrackedPerformances";
import { AddNewItemToGallery } from "./Components/AddNewItemToGallery";
import { TeamFullGallery } from "./Components/TeamFullGallery";

// UI ELEMENTS
import { BLOCK_ToolBar } from "../../Components/interface/BLOCK_Toolbar";
import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";

import { H2, P, P_Bold } from "../../Theme/type";
import { useFindSingleTeam } from "../../Hooks/Content/useFindTeam";

export default function TeamProfile(props) {
  const { SelectedTeam, setSelectedTeam, setSectionID } = props;
  const [AddNew, setAddNew] = useState(false);
  const [TeamMeta, setTeamMeta] = useState(false);
  // HOOK
  const [TeamName, FindTeamName] = useFindSingleTeam();
  useEffect(() => {}, [AddNew]);

  useEffect(() => {
    if (TeamMeta === false) {
      console.log(SelectedTeam);
      FindTeamName(SelectedTeam.id);
    }
  }, [SelectedTeam]);

  useEffect(() => {
    if (TeamName !== false) {
      setSelectedTeam(TeamName);
      setTeamMeta(true);
    }
  }, [TeamName]);

  if (!TeamMeta)
    return (
      <>
        <H2>Fetching {SelectedTeam.attributes.Name} Team Data</H2>
        <Loader color="teal" variant="bars" />
      </>
    );

  return (
    <>
      <BLOCK_ToolBar
        TOOLS={[
          <BTN_ICON_CALLBACK
            CALLBACK={() => {
              setSectionID(0);
              setSelectedTeam(false);
              setTeamMeta(false);
            }}
            ICON="Search"
            TIPOBJ={{
              LABEL: "Select a new Team",
              COLOR: "gray",
              POSITION: "top",
            }}
          />,
          <BTN_ICON_LINK
            TO={`https://www.lastmanstands.com/team-profile/t20/?teamid=${SelectedTeam.attributes.TeamID}`}
            ICON="ExternalLink"
            TIPOBJ={{
              LABEL: "Team Profile",
              COLOR: "gray",
              POSITION: "top",
            }}
          />,
        ]}
      />

      <Space h="xl" />
      <DefaultProfileImageContainer SelectedTeam={SelectedTeam} />

      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Team Gallery",
          ICON: "Photo",
          DESCRIPTION: "All images stored for player",
          TOOLBAR: false,
        }}
      />

      <BLOCK_ToolBar
        TOOLS={[
          <BTN_ICON_CALLBACK
            ICON="CameraSelfie"
            CALLBACK={() => {
              setAddNew(!AddNew);
            }}
            LABEL="Add New Image"
            TIPOBJ={{
              LABEL: "Add new Team Image",
              COLOR: "green",
              POSITION: "top",
            }}
          />,
        ]}
      />

      {!AddNew ? (
        false
      ) : (
        <>
          <AddNewItemToGallery
            setAddNew={setAddNew}
            SelectedTeam={SelectedTeam}
            setSelectedTeam={setSelectedTeam}
          />
        </>
      )}
      <Space h="md" />
      <TeamFullGallery
        SelectedTeam={SelectedTeam}
        setSelectedTeam={setSelectedTeam}
      />
      <Space h="xl" />
      <TeamsLMSStats SelectedTeam={SelectedTeam} />
      <Space h="xl" />
      <TrackedPerformances SelectedTeam={SelectedTeam} />
    </>
  );
}

/*

 <AvatarGallery  SelectedTeam={SelectedTeam}/> 


*/

const TeamsLMSStats = ({ SelectedTeam }) => {
  const S = SelectedTeam.attributes;
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Team History",
          ICON: "Download",
          DESCRIPTION: "Teams history as seen on LMS",
          TOOLBAR: false,
        }}
      />
      <VideoWizardContainer>
        <SimpleGrid cols={2} spacing="xs">
          <StatRow ROW={["MATCHES", S.Games]} />
          <StatRow ROW={["Form", S.Form]} />
          <StatRow ROW={["Lost", S.Lost]} />
          <StatRow ROW={["Won", S.Won]} />
          <StatRow ROW={["Win_Ratio", S.Win_Ratio]} />
          <StatRow ROW={["Innings Count", S.player_battings.data.length]} />
          <StatRow ROW={["Bowling Count", S.player_bowlings.data.length]} />
          <StatRow ROW={["Catches Count", S.player_catches.data.length]} />
          <StatRow ROW={["Stumpings Count", S.player_stumpings.data.length]} />
        </SimpleGrid>
      </VideoWizardContainer>
    </>
  );
};

const StatRow = ({ ROW }) => {
  return (
    <>
      <P>{ROW[0]}</P>
      <P_Bold>{ROW[1]}</P_Bold>
    </>
  );
};
