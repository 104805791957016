import { Button, Badge, ActionIcon, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { ICONDB } from "../../../Theme/icons";
import { ToolTipWrapper } from "./BtnToolTipWrapper";

export const BTN_CTA = ({
  CALLBACK,
  LABEL,
  fullWidth = true,
  TIPOBJ = false,
  BG = "5",
  Color = "0",
}) => {
  return (
    <ToolTipWrapper TIPOBJ={TIPOBJ}>
      <Button
        fullWidth={fullWidth}
        uppercase
        onClick={CALLBACK}
        sx={(theme) => ({
          backgroundColor: theme.colors.brand[BG],
          color: theme.colors.brand[Color],
          "&:hover": {
            backgroundColor: theme.colors.gray[Color],
            color: theme.colors.brand[BG],
          },
        })}
      >
        {LABEL}
      </Button>
    </ToolTipWrapper>
  );
};

export const BTN_CREATE = ({
  CALLBACK,
  LABEL,
  DISABLED,
  BG = 8,
  COPY = 0,
  TIPOBJ = false,
}) => {
  return (
    <ToolTipWrapper TIPOBJ={TIPOBJ}>
      <Button
        onClick={CALLBACK}
        sx={(theme) => ({
          backgroundColor: theme.colors.brand[BG],
          color: theme.colors.brand[COPY],
          "&:hover": {
            backgroundColor: theme.colors.brand[COPY],
            color: theme.colors.brand[BG],
          },
        })}
        disabled={DISABLED}
      >
        {LABEL}
      </Button>
    </ToolTipWrapper>
  );
};

/* export const BTN_ERROR = ({CALLBACK, LABEL, DISABLED})=>{
  return(
      <Button 
          onClick={CALLBACK}
          sx={(theme) => ({
              backgroundColor: theme.colors.brand[6],
              color:theme.colors.brand[0],
              '&:hover': {
                backgroundColor: theme.colors.gray[5],
                color:theme.colors.brand[6],
              },
            })}
            disabled={DISABLED}
      >{LABEL}</Button>
  )
} */

export const BTN_ASIDE = ({
  CALLBACK,
  LABEL,
  DISABLED,
  BADGE = false,
  TIPOBJ = false,
}) => {
  return (
    <ToolTipWrapper TIPOBJ={TIPOBJ}>
      <Button
        variant="subtle"
        onClick={CALLBACK}
        sx={(theme) => ({
          color: theme.colors.brand[5],
          "&:hover": {
            backgroundColor: theme.colors.brand[1],
            color: theme.colors.brand[4],
          },
        })}
        disabled={DISABLED}
      >
        {LABEL}
        {BADGE ? (
          <Badge
            variant="filled"
            size="xs"
            sx={(theme) => ({
              backgroundColor: theme.colors.brand[8],
              marginLeft: "3px",
            })}
          >
            {BADGE}
          </Badge>
        ) : (
          false
        )}
      </Button>
    </ToolTipWrapper>
  );
};

export const BTN_ASIDE_LINK = ({ TO, LABEL, DISABLED, TIPOBJ = false }) => {
  return (
    <ToolTipWrapper TIPOBJ={TIPOBJ}>
      <Button
        component={Link}
        variant="subtle"
        to={TO}
        sx={(theme) => ({
          color: theme.colors.brand[3],
          "&:hover": {
            backgroundColor: theme.colors.brand[3],
            color: theme.colors.brand[1],
          },
        })}
        disabled={DISABLED}
      >
        <Text transform="uppercase" size="sm" weight={500}>
          {LABEL}
        </Text>
      </Button>
    </ToolTipWrapper>
  );
};
export const BTN_ASIDE_LINK_INVERT = ({
  TO,
  LABEL,
  DISABLED,
  TIPOBJ = false,
}) => {
  return (
    <ToolTipWrapper TIPOBJ={TIPOBJ}>
      <Button
        component={Link}
        variant="subtle"
        to={TO}
        sx={(theme) => ({
          color: theme.colors.brand[1],
          backgroundColor: theme.colors.brand[3],
          "&:hover": {
            backgroundColor: theme.colors.brand[1],
            color: theme.colors.brand[3],
          },
        })}
        disabled={DISABLED}
      >
        <Text transform="uppercase" size="sm" weight={500}>
          {LABEL}
        </Text>
      </Button>
    </ToolTipWrapper>
  );
};

export const BTN_DOWNLOAD = ({ TO, LABEL, DISABLED, TIPOBJ = false, target="_self" }) => {
  return (
    <ToolTipWrapper TIPOBJ={TIPOBJ}>
     
      <Button
        component={"a"}
        target={target}
        variant="subtle"
        fullWidth
        href={TO}
        sx={(theme) => ({
          backgroundColor: theme.colors.brand[4],
          color: theme.colors.brand[0],
          "&:hover": {
            backgroundColor: theme.colors.brand[3],
            color: theme.colors.brand[4],
          },
        })}
        disabled={DISABLED}
      >
        {LABEL}
      </Button>
     
    </ToolTipWrapper>
  );
};

/* export const BTN_OFFSITE_LINK = ({TO, LABEL, DISABLED})=>{
  return(
      <Button 
      component="a"
      target="_blank"
      rel="noopener noreferrer"
      href={TO}
          sx={(theme) => ({
              color:theme.colors.brand[1],
              backgroundColor: theme.colors.brand[3],
              '&:hover': {
                backgroundColor: theme.colors.brand[1],
                color:theme.colors.brand[3],
              },
            })}
            disabled={DISABLED}
      ><Text transform="uppercase" size="sm" weight={500}>{LABEL}</Text></Button>
  )
} */

/* export const PreviewIcon =({CALLBACK, ICON='PlayerPlay'})=> {
  return (
    <ActionIcon  onClick={CALLBACK}  size="md" radius="xl" variant="light"
        sx={(theme) => ({     
          backgroundColor: theme.colors.brand[0],
          color:theme.colors.brand[5],
            '&:hover': {
              backgroundColor: theme.colors.brand[4],
              color:theme.colors.brand[1],
            },
          })}>
      {ICONDB[ICON].Component}
    </ActionIcon>
  );
}
 */
/* export const BTN_ICON_DOWNLOAD =({TO, LABEL, DISABLED})=> {
  return (
    <ActionIcon 
    component="a"
    variant="light"
    target='_blank'
    href={TO}
      size="md" radius="xl" 
        sx={(theme) => ({     
          backgroundColor: theme.colors.brand[0],
            color:theme.colors.brand[5],
            '&:hover': {
              backgroundColor: theme.colors.brand[4],
              color:theme.colors.brand[1],
            },
          })}>
      {ICONDB.Download.Component}
    </ActionIcon>
  );
} */

export const BTN_ICON_LINK = ({
  TO,
  ICON = "Settings",
  DISABLED,
  BG = 0,
  COLOR = 5,
  TIPOBJ = false,
}) => {
  return (
    <ToolTipWrapper TIPOBJ={TIPOBJ}>
      <ActionIcon
        component="a"
        variant="light"
        target="_blank"
        href={TO}
        size="lg"
        radius="sm"
        sx={(theme) => ({
          borderColor: theme.colors.brand[1],
          background: theme.colors.gradients[3],
          color: theme.colors.brand[3],
          "&:hover": {
            //backgroundColor: theme.colors.brand[3],
            background: theme.colors.gradients[2],
            color: theme.colors.brand[2],
          },
        })}
      >
        {ICONDB[ICON].Component}
      </ActionIcon>
    </ToolTipWrapper>
  );
};

export const BTN_ICON_CALLBACK = ({
  CALLBACK,
  ICON = "Settings",
  DISABLED,
  BG = 0,
  COLOR = 5,
  TIPOBJ = false,
}) => {
  return (
    <ToolTipWrapper TIPOBJ={TIPOBJ}>
      <ActionIcon
        onClick={CALLBACK}
        size="lg"
        radius="sm"
        variant="light"
        sx={(theme) => ({
          borderColor: theme.colors.brand[1],
          background: theme.colors.gradients[3],
          color: theme.colors.brand[3],
          "&:hover": {
            //backgroundColor: theme.colors.brand[3],
            background: theme.colors.gradients[2],
            color: theme.colors.brand[2],
          },
        })}
        disabled={DISABLED}
      >
        {ICONDB[ICON].Component}
      </ActionIcon>
    </ToolTipWrapper>
  );
};

export const BTN_ICON_INTERNALLINK = ({
  TO = "/",
  ICON = "Settings",
  BG = 0,
  COLOR = 5,
  TIPOBJ = false,
  DISABLED,
}) => {
  return (
    <ToolTipWrapper TIPOBJ={TIPOBJ}>
      <ActionIcon
        component={Link}
        variant="light"
        to={TO}
        size="md"
        radius="xl"
        sx={(theme) => ({
          backgroundColor: theme.colors.brand[BG],
          color: theme.colors.brand[COLOR],
          "&:hover": {
            backgroundColor: theme.colors.brand[COLOR],
            color: theme.colors.brand[BG],
          },
        })}
      >
        {ICONDB[ICON].Component}
      </ActionIcon>
    </ToolTipWrapper>
  );
};
