
import { Switch } from '@mantine/core';

export const SwitchIsLiveStreamed = ({setSelectIsLiveStreamed})=>{
    //SelectedGame={SelectedGame} SelectWinner={SelectWinner} setSelectWinner={setSelectWinner}
    
    const handleChange=(e)=>{setSelectIsLiveStreamed(e.currentTarget.checked)}
    return (
        <Switch 
            onChange={handleChange}    
            label="Is this game Livestreamed on LMS TV?"
            color="green"
            />
      );
} 



