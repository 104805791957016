import { Img } from "remotion";
import { ImageRatio } from "../../../../../actions/Images";

export const SponsorLogoINTRO = ({Sponsor}) => { 
  const styles=ImageRatio(Sponsor.Logo.data.attributes).SponsorLogo
    return <Img style={styles} src={`${Sponsor.Logo?.data?.attributes.url}`} />;
  };

export const SponsorLogoEND = ({Sponsor}) => {
  const styles=ImageRatio(Sponsor.Logo.data.attributes).SponsorLogo
    return <Img style={styles} src={`${Sponsor.Logo?.data?.attributes.url}`} />;
  };