/* eslint-disable react/jsx-pascal-case */
import { useSelector } from "react-redux";

import { Container } from "@mantine/core";
import { PageHeader } from "../../Components/interface/ProductHeader";

import { AccessDenied } from "../../Components/interface/AccessDenied";

import { RemoveNullItems } from "./RemoveNull";
import { FindMissingWatchList } from "./FindMissingWatchList";
import { FindDuplicatesInStrapi } from "./FindDuplicates";
import { FindDuplicatePlayersInStrapi } from "./FindDuplicatePlayers";
export const SUPERADMINHUB = () => {
  //const UXSTATE = useSelector((state) => state.UXSTATE);
  const AUTH = useSelector((state) => state.AUTH);

  if (AUTH.AUTHUSER.id !== 1) return <AccessDenied />;
  return (
    <>
      <PageHeader NavPosition="Super Admin" />
      <Container size="lg" p={0}>
        <RemoveNullItems />
        <FindMissingWatchList />
        <FindDuplicatesInStrapi />
        <FindDuplicatePlayersInStrapi />
      </Container>
    </>
  );
};
