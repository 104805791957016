import { useCurrentFrame } from 'remotion';
import { Box } from "@mantine/core";
import { H3 } from '../../../../../../utils/Text';
import { EraseFromMiddle, FromLeftToRight, FromBottomToTop } from '../../../../../../../Animation/ClipWipe';
import { SpringToFrom } from '../../../../../../../Animation/RemotionSpring';
import { interpolateOpacityByFrame } from '../../../../../../../Animation/interpolate';
import { useTheme } from "styled-components";

export const COPY = (props) => {
    const {HEIGHT=900, DURATION} = props
    const frame = useCurrentFrame();
  return (
    <Box
      style={{
        height:`${HEIGHT}px`,
        padding: "1em 3em",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        clipPath: EraseFromMiddle((DURATION-15),'Springy100'),
      }}
    >
      <H3
        style={{
          fontSize: "3.5em",
          lineHeight: "1.6em",
          fontWeight: 400,
          textAlign: "center",
          clipPath: FromLeftToRight(15,'Springy100'),
          opacity:interpolateOpacityByFrame(frame,15,50, 0, 1),
          transform: `translateY( ${SpringToFrom(15,-30,0,'Springy100')}px)`,
        }}
      >
        {props.children}
      </H3>
    </Box>
  ); 
};

export const EMPASISVALUE = ({ VALUE }) => {
  const theme = useTheme();

  return (
    <span
      style={{
        color: theme.Copy[2],
        fontSize: `2.1em`,
        fontWeight: `900`,
        letterSpacing: `-6px`,
        
        //opacity:interpolateOpacityByFrame(frame,30,45, 0, 1),
        clipPath: FromBottomToTop(30,'Springy100')
      }}
    >
      {VALUE}
    </span>
  );
};
