import { Select } from "@mantine/core";
import { MultiSelect } from "@mantine/core";
import { useSelector } from "react-redux";
import { orderBy } from "lodash";
export const SelectMultipleRegionDivision = ({ CALLBACK }) => {
  const UXSTATE = useSelector((state) => state.UXSTATE);
  const handleChange = (e) => {
    CALLBACK(e);
  };

  const CreateFranchiseSelect = () => {
    let ARR = [];
    UXSTATE.SelectedFranchise.attributes.watch_lists.data.map((Season, i) => {
      if (!Season.attributes.hasCompleted)
        ARR.push({ value: Season, label: Season.attributes.Name, key: i });
    });

    return orderBy(ARR, ["label"]);
  };
  return (
    <>
      <MultiSelect
        label=""
        placeholder="Select a Region"
        data={CreateFranchiseSelect()} 
        onChange={handleChange}
      />
    </>
  );
};
