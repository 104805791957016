import { useEffect, useState } from "react";
import { orderBy } from "lodash";
import { useSelector } from "react-redux";
const VideoOBJ = {
  Duration: 150,
  VideoBodyTiming: 0,
  VideoDurationinFrames: 0,
  RenderStill: [],
  POTDDuration: 0,
};

const SETTINGSFORTEST = {
  MINRUNS: 50,
  STRIKERATE: 100,
};

export const useFindPOTDBATTING = () => {
  const AUTH = useSelector((state) => state.AUTH);
  const SETTINGS =
    AUTH.AUTHUSER.user_video_setting?.Settings.POTD.BATTING !== undefined
      ? AUTH.AUTHUSER.user_video_setting?.Settings.POTD.BATTING
      : SETTINGSFORTEST;
  const VideoSettings = { ...VideoOBJ };
  const [POTDBATTING, setPOTDBATTING] = useState({ VideoSettings });

  // UTILS
  const isFifty = (SCORE) => {
    return parseInt(SCORE, 10) >= SETTINGS.MINRUNS ? true : false;
  };

  const hasSR = (SR) => {
    return SR.BATTING_SR > SETTINGS.STRIKERATE || SR.BATTING_Runs > 56
      ? true
      : false;
  };

  const POTDDuration = (INT) => {
    return INT * POTDBATTING.VideoSettings.Duration;
  };
  const honorableMentionaDuration = () => {
    return 300;
  };

  const VideoDurationinFrames = (INT) => {
    return POTDDuration(INT[0]) + honorableMentionaDuration() + 90 + 120 + 120;
  };
  /*   const VideoEndCreditsDurationStartAt = (INT) => {
    return (
      POTDDuration(INT[0]) +
      honorableMentionaDuration() +
      POTDBATTING.VideoSettings.Openingfps
    );
  }; */
  const FindVideoBodyTiming = (INT) => {
    return POTDDuration(INT[0]) + honorableMentionaDuration();
  };

  const FindRenderStill = (LEN) => {
    let ARR = [40, 190];
    const Start = 300;
    let i = 0;
    while (i < LEN) {
      //console.log(Start+(i*120))
      ARR.push(Start + i * 120);
      i++;
    }
    return ARR;
  };

  // Main Function
  const FindPOTDBATTING = async (DATA) => {
    //console.log(DATA)
    const ARR = [],
      HONERABLE = [],
      SHOWCASE = [];
    // Find POTDBATTING

    DATA.map((game, i) => {
      const DEFAULTIMG =
        "https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/playeroption4_de5eede10d.png";
      game.attributes.player_battings.data.map((player, i) => {
        //console.log(player)
        let P = player.attributes;
        if (isFifty(P.BATTING_Runs)) {
          //console.log(P.player.data)
          ARR.push({
            BATTING_Balls: P.BATTING_Balls,
            BATTING_HowOut: P.BATTING_HowOut,
            BATTING_Runs: P.BATTING_Runs,
            BATTING_SR: P.BATTING_SR,
            BATTING_fours: P.BATTING_fours,
            BATTING_sixes: P.BATTING_sixes,
            Name: P.player.data?.attributes.Name,
            WORLDRANKINGBATTING: P.player.data?.attributes.WORLDRANKINGBATTING,
            TYPEBATTING: P.player.data?.attributes.TYPEBATTING,
            FIFTIES: P.player.data?.attributes.FIFTIES,
            BATTINGAVERAGE: P.player.data?.attributes.BATTINGAVERAGE,
            PLAYERPROFILELEVEL: P.player.data?.attributes.PLAYERPROFILELEVEL,
            IMAGE:
              P.player.data?.attributes.image.data?.attributes.url === undefined
                ? DEFAULTIMG
                : P.player.data?.attributes.image.data?.attributes.url,
            Team: P.team.data.attributes.Name,
            Logo: P.team.data?.attributes.Logo.data?.attributes.url,
            ID: P.player.data.id,
          });
        }
      });
    });

    ARR.map((Player, i) => {
      Player.IMAGE !== undefined || Player.Name.length !== 0
        ? hasSR(Player)
          ? SHOWCASE.push(Player)
          : HONERABLE.push(Player)
        : HONERABLE.push(Player);
    });

    const DATAOBJ = {
      SHOWCASE: orderBy(SHOWCASE, (obj) => parseInt(obj.BATTING_Runs, 10), [
        "desc",
      ]),
      HONERABLE: orderBy(
        HONERABLE.slice(0, 22),
        (obj) => parseInt(obj.BATTING_Runs, 10),
        ["desc"]
      ),
    };

    const waiting = await CalculateDurations(DATAOBJ);
    console.log({ ...POTDBATTING, ...DATAOBJ }, waiting);

    setPOTDBATTING({ ...POTDBATTING, ...DATAOBJ });
    //setResults({...Results,...DATAOBJ})
  };

  const CalculateDurations = async (DATAOBJ) => {
    POTDBATTING.VideoSettings.VideoBodyTiming = await FindVideoBodyTiming([
      DATAOBJ.SHOWCASE.length,
      DATAOBJ.HONERABLE.length,
    ]);
    POTDBATTING.VideoSettings.POTDDuration = await POTDDuration(
      DATAOBJ.SHOWCASE.length
    );
    POTDBATTING.VideoSettings.HONERABLEDuration =
      await honorableMentionaDuration(DATAOBJ.HONERABLE.length);

    POTDBATTING.VideoSettings.VideoDurationinFrames =
      await VideoDurationinFrames([
        DATAOBJ.SHOWCASE.length,
        DATAOBJ.HONERABLE.length,
      ]);

      POTDBATTING.VideoSettings.RenderStill = await FindRenderStill(
      DATAOBJ.SHOWCASE.length,
      VideoSettings
    );
  };

  useEffect(() => {}, [POTDBATTING]);

  return [POTDBATTING, FindPOTDBATTING];
};
