import {useCurrentFrame } from 'remotion';
import { useTheme } from 'styled-components';
import { P } from '../../utils/Text';
import {SpringToFrom} from '../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../Animation/interpolate'


export const SponsorCTA = ({CTA})=>{
    const frame = useCurrentFrame();
    const theme = useTheme();
    return(
        <div style={{
            opacity:interpolateOpacityByFrame(frame, 45,60, 0, 1),
            transform: `translateY(${SpringToFrom(45,10,0,'Springy100','slow')}px)`}}
            
            >
                <P style={{ color:theme.Base[4],}}>{CTA}</P>
            </div>
    )
}