/* eslint-disable react/jsx-pascal-case */
// HOOKS
import { useEffect, useState } from "react";
import { useGetFixtureForTeamFromLeague } from "../../../../../Hooks/Content/useFixtures";

import { BTN_ICON_CALLBACK } from "../../../../../Theme/Structure/Button/BTNS";
import { find } from "lodash";

import { Container, Group, Image, Space, Table, Tabs } from "@mantine/core";
import { H3, P } from "../../../../../Theme/type";
import { IconPokeball, IconBrandAsana } from "@tabler/icons";
// Components
import { ListBatsmen } from "./Components/BatsmanList";
import { ListBowlers } from "./Components/BowlingList";
import { ViewSinglePlayer } from "./Components/ViewSinglePlayer";
import { UISkeletonLines } from "../../../../../Components/Global/Skeleton";
import { BLOCK_ToolBar } from "../../../../../Components/interface/BLOCK_Toolbar";
import {
  TAB_LIST,
  TAB_WRAPPER,
} from "../../../../../Components/interface/Wrapper/TABS";
import { COPY_WARNING } from "../../../../../Components/interface/BLOCK_Info";

export const TeamWrapper = ({ Fixture, setPath, teamID }) => {
  // /api/teams/:id
  // HOOKS
  const [Fixtures, GetFixtures] = useGetFixtureForTeamFromLeague();
  // useState
  const [SelectedPlayer, setSelectedPlayer] = useState(false);
  const [Leagueid, setLeagueID] = useState(
    Fixture.attributes.watch_list.data.id
  );
  const [Team, FindTeam] = useState(
    find(Fixture.attributes.teams.data, (o) => {
      return o.id === teamID;
    }).attributes
  );

  // FUNC

  // useEffect
  useEffect(() => {
    GetFixtures(teamID, Leagueid);
  }, []);

  useEffect(() => {}, [Fixtures]);

  //console.log(Fixture)
  if (Fixture.attributes.watch_list.data.attributes.STATS.PLAYERS.length === 0)
  return (
    
    <Container size={"lg"}>
      <H3 colorID={3} size={20} weight={400} lineheight={`1.5em`}>
        League: {Fixture.attributes.watch_list.data.attributes.Name}
      </H3>
      <BLOCK_ToolBar
        TOOLS={[
          <BTN_ICON_CALLBACK
            CALLBACK={() => {
              setPath(0);
            }}
            ICON={"ArrowBackUp"}
            TIPOBJ={{
              LABEL: `Back to Game Details`,
              COLOR: "red",
              POSITION: "top",
            }}
          />,
        ]}
      />
      <COPY_WARNING COPY="NO Games data stored for this Team." />
      <P>This is likely due to the {Team.Name} not having played a game in {Fixture.attributes.watch_list.data.attributes.Name} yet. </P>
      
    </Container>
  );
  if (Fixtures === false) return <UISkeletonLines />;
  return (
    <>
      <Space h="xl" />
      <Group position="center">
        <Image
          src={Team.Logo.data?.attributes.url}
          width={80}
          height={80}
          radius={120}
        />
        <H3>Player Information : {Team.Name}</H3>
      </Group>

      {!SelectedPlayer ? (
        <ListWrapper
          Fixtures={Fixtures}
          Team={Team}
          teamID={teamID}
          setSelectedPlayer={setSelectedPlayer}
        />
      ) : (
        <ViewSinglePlayer
          SelectedPlayer={SelectedPlayer}
          setSelectedPlayer={setSelectedPlayer}
          Fixture={Fixture}
        />
      )}

      <BLOCK_ToolBar
        TOOLS={[
          <BTN_ICON_CALLBACK
            CALLBACK={() => {
              setPath(0);
            }}
            ICON={"ArrowBackUp"}
            TIPOBJ={{
              LABEL: `Back to Game Details`,
              COLOR: "red",
              POSITION: "top",
            }}
          />,
        ]}
      />
    </>
  );
};

const ListWrapper = ({ Fixtures, Team, teamID, setSelectedPlayer }) => {

  return (
    <>
      <TAB_WRAPPER defaultValue="Batting">
        <TAB_LIST
          ARR={[
            {
              VALUE: "Batting",
              LABEL: "Batting",
              ICON: <IconBrandAsana size={24} color="teal" />,
            },
            {
              VALUE: "Bowling",
              LABEL: "Bowling",
              ICON: <IconPokeball size={24} color="teal" />,
            },
          ]}
        />
        <Tabs.Panel value="Batting" pt="xs">
          <ListBatsmen
            Fixtures={Fixtures}
            Team={Team}
            teamID={teamID}
            setSelectedPlayer={setSelectedPlayer}
          />
        </Tabs.Panel>

        <Tabs.Panel value="Bowling" pt="xs">
          <ListBowlers
            Fixtures={Fixtures}
            Team={Team}
            teamID={teamID}
            setSelectedPlayer={setSelectedPlayer}
          />
        </Tabs.Panel>
      </TAB_WRAPPER>
    </>
  );
};
