import { gql } from "@apollo/client";

export const QUERY = (APISETTINGS) => {
  return gql`query GameDetails {
      fixture(id:${APISETTINGS.GameID}){
        data{
          id
          attributes{
            fixtureID
            HomeTeam
            HomeTeamResult
            AwayTeam
            hasPlayers
            hasPlayerSync
            AwayTeamResult
            Date
            ResultStatement
            Time
            player_battings(pagination: { start: 0, limit: -1 }){
              data{
                id
                attributes{
                  BATTING_Runs
                  BATTING_Balls 
                  BATTING_fours
                  BATTING_sixes
                  BATTING_SR
                  BATTING_HowOut
                  player{
                    data{
                      id
                      attributes{
                        Name
                        image {
                          data {
                            id
                            attributes {
                              
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                  team{
                    data{
                      id
                      attributes{
                        Name
                      }
                    }
                  }
                }
              }
            }
            player_bowlings (pagination: { start: 0, limit: -1 }){
              data{
                id
                attributes{
                  BOWLING_Overs
                  BOWLING_Runs
                  BOWLING_Wkts
                  BOWLING_Maidens
                  BOWLING_Econ
                  player{
                    data{
                      id
                      attributes{
                        Name
                        image {
                          data {
                            id
                            attributes {
                              
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                  team{
                    data{
                      id
                      attributes{
                        Name
                      }
                    }
                  }
                }
              }
            }
            player_catches  (pagination: { start: 0, limit: -1 }){
              data{
                id
                attributes{
                  PLAYERS_Catches
                  player{
                    data{
                      id
                      attributes{
                        Name
                        image {
                          data {
                            id
                            attributes {
                              
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                  team{
                    data{
                      id
                      attributes{
                        Name
                      }
                    }
                  }
                }
              }
            }
            player_stumpings(pagination: { start: 0, limit: -1 }){
              data{
                id
                attributes{
                  PLAYERS_Stumpings
                  player{
                    data{
                      id
                      attributes{
                        Name
                        image {
                          data {
                            id
                            attributes {
                              
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                  team{
                    data{
                      id
                      attributes{
                        Name
                      }
                    }
                  }
                }
              }
            }
            player_moms{
              data{
                id
                attributes{
                  player{
                    data{
                      id
                      attributes{
                        Name
                        image {
                          data {
                            id
                            attributes {
                              
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                  team{
                    data{
                      id
                      attributes{
                        Name
                      }
                    }
                  }
                }
              }
            }
            ground{
              data{
                id
                attributes{
                  Name
                }
              }
            }
            umpire{
              data{ 
                id
                attributes{
                  Name
                }
              }
            }
            teams{
              data{
                id
                attributes{
                  Name
                  Games
                  Won
                  Lost
                  Win_Ratio
                  Form
                  City_Rank
                  National_Rank
                  World_Rank
                  Logo {
                          data {
                            id
                            attributes {
                              formats
                              url
                            }
                          }
                        }
                  
                     
                }
              }
            }
          }
        }
      }
          }`;
};
