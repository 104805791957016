import { useEffect, useState } from "react";
import { useFindSingleTeam } from "../../../Hooks/Content/useFindTeam";
import { find } from "lodash";
import { BTN_CTA } from "../../../Theme/Structure/Button/BTNS";
import { useAICreate } from "../../../Hooks/Content/useAICreate";
import {
  BorderedContainer,
  VideoWizardContainer,
} from "../../../Theme/Structure/BodyContainer";
import { P, H3, H2 } from "../../../Theme/type";
import { Container, Loader } from "@mantine/core";
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
export const GPT3Prompt = ({ GAME }) => {
  console.log(GAME);
  //useAICreate
  const [AICreate, FetchAICreate] = useAICreate();
  const [loading, setLoading] = useState(false);

  const CreateWriteup = (GAME) => {
    console.log(GAME);
    FetchAICreate(GAME);
    setLoading(true);
  };

  useEffect(() => {
    if (AICreate?.response !== undefined) {
      setLoading(false);
    }
  }, [AICreate]);

  if (loading)
    return (
      <Container size={"xs"} py={20}>
        <Loader color="teal" variant="bars" />
      </Container>
    );
  return (
    <>
      <Container size={"xs"} py={20}>
        {AICreate?.response !== undefined ? (
          false
        ) : (
          <BTN_CTA
            CALLBACK={() => {
              CreateWriteup(GAME);
            }}
            LABEL={"Create Write up"}
            BG="7"
            Color="0"
          />
        )}
      </Container>

      {AICreate !== null ? <WriteUp Response={AICreate.response} /> : false}
    </>
  );
};

const WriteUp = ({ Response }) => {
  return (
    <>
      <BLOCK_Section_SubHeading LABEL="Generated Match Review" Color={5} />
      <Container size={"sm"} py={20}>
        <VideoWizardContainer>
          <P lineHeight={"1.4em"}>{Response}</P>
        </VideoWizardContainer>
        <BorderedContainer>
          <P colorID={4}  size={'sm'} lineHeight={"1.4em"}>
            Please note that while this AI will generate a match report based on
            its knowledge of T20 Cricket, the report may not accurately reflect
            the events of the LMS match. The scorecard provided will be used,
            but the report may not accurately reflect retired or returning
            batsmen. Please review the report before posting to ensure its
            accuracy.
          </P>
        </BorderedContainer>
      </Container>
    </>
  );
};
