import { Select } from '@mantine/core';
export const SelectRegionSeason = ({DATA, CALLBACK})=>{
  
    const handleChange=(e)=>{
        CALLBACK(e)
    }
  
    const CreateFranchiseSelect = ()=>{
        let ARR=[]
        DATA.map((Season,i)=>{
            if(!Season.attributes.isComplete)
              ARR.push({ value: Season, label: Season.attributes.Name, key:i })
        })
    return ARR
    }
    return (
        <Select
          label=""
          placeholder="Select Dataset"
          data={CreateFranchiseSelect()} 
          onChange={handleChange}
          styles={(theme) => ({
            input:{
              '&, &:focus':{
                borderColor: theme.colors.brand[5],
                color: theme.colors.brand[5]
              }
            },
            item: {
              // applies styles to selected item
              '&[data-selected], &:focus': {
                '&, &:hover, &:focus': {
                  backgroundColor:theme.colors.brand[3],
                  color: theme.colors.brand[4],
                },
              },
    
              // applies styles to hovered item (with mouse or keyboard)
              '&[data-hovered], :focus': {
                backgroundColor:theme.colors.brand[5],
                color: theme.colors.brand[0],
              },
            },
          })}
        />
      );
}