import { useEffect, useState } from "react";
import { useWindowScroll } from "@mantine/hooks";
import { useMediaQuery } from "@mantine/hooks";

import {
  AppShell,
  Header,
  MediaQuery,
  Burger,
  useMantineTheme,
  Affix,
  Transition,
  Box,
} from "@mantine/core";

import { DoubleNavbar } from "./NavbarTwoSections";
import { HeaderBarAccountStatus } from "./HeaderBarAccountStatus";

export default function Shell(props) {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [scroll, scrollTo] = useWindowScroll();
  const Width = useMediaQuery("(min-width: 769px)");
  useEffect(() => {}, [props.AsideNav]);
  //useEffect(() => {scrollTo({ y: 0 })}, []);

  
  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.brand[3]
              : theme.colors.brand[0],
          paddingTop: 50,
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      fixed
      navbar={<DoubleNavbar setOpened={setOpened} opened={opened} />}
      header={
        <Header
          height={40}
          style={{ padding: "0 7px" }}
          sx={(theme) => ({
            backgroundColor: theme.colors.brand[3],
          })}
        >
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <HeaderBarAccountStatus />
          </div>
        </Header>
      }
    >
      {props.children}
      <Affix position={{ bottom: 10, right: 10 }} style={{ display: Width ? 'none': 'block' }}>
        <Transition transition="slide-up" mounted={scroll.y > 0}>
          {(transitionStyles) => (
            <Box
              style={transitionStyles}
              sx={(theme) => ({
                backgroundColor: theme.colors.brand[3],
                textAlign: "center",
                padding: theme.spacing.xs,
                borderRadius: 100,
                cursor: "pointer",

                "&:hover": {
                  backgroundColor: theme.colors.brand[3],
                },
              })}
            >
              <Burger
                opened={opened}
                style={transitionStyles}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.brand[4]}
              />
            </Box>
          )}
        </Transition>
      </Affix>
    </AppShell>
  );
}
