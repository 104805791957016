
import { ThemeProvider } from "styled-components";

import { UPCOMINGFIXTURES_GLASS } from "../../theme/Templates/GLASS/index";
import { UPCOMINGFIXTURES_ARROWS } from "../../theme/Templates/ARROWS/index";
import { UPCOMINGFIXTURES_ROUNDED } from "../../theme/Templates/ROUNDED/index";

export const Build_MATCHDAYFIXTURES_4by5 = (props) => {
  const { RENDER } = props;

  const TEMPLATES = {
    Glass: <UPCOMINGFIXTURES_GLASS RENDER={RENDER} />,
    Arrows: <UPCOMINGFIXTURES_ARROWS RENDER={RENDER} />,
    Rounded: <UPCOMINGFIXTURES_ROUNDED RENDER={RENDER} />,
   
  };

  return (
    <ThemeProvider theme={RENDER.THEME.VideoTheme.Theme}>
      {TEMPLATES[RENDER.THEME.VideoTemplate]}
    </ThemeProvider>
  );
};
