import styled from 'styled-components';

export const PlaceHolder = styled.div`width: 150px;`
/* LISTS */
export const GLOBALUL = styled.ul`
    width:100%;
    height: 100%;
    position: relative;
    margin:0;
    padding:0;
`
 

export const POTDUL = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin: 150px 0 0 50px;
    padding: 25px;
    width: 100%; 
   
    
`


export const LI_Fixture = styled.li`
    width:  90%;
    height: 175px;
    margin: 8% 5%;
    position: relative;
    list-style:none;

`

export const MatchDayLI = styled(LI_Fixture)`
    margin: 7% 5%;
`
export const HONERABLELI = styled.li`
position: relative;
width: 48%;
max-height: 100px;
list-style: none;
margin: 0 1% 10px;
padding: 3px;
min-height:135px;
box-sizing: border-box;

display: flex;
flex-direction: column;
justify-content: space-evenly;
`
export const LeagueLadderLI = styled.li`
width: 97%;
    height: 130px;
    margin: 0.5% 1% 0.5% 2%;
    position: relative;
    list-style: none;

`