// Global
import { Audio, interpolate, useCurrentFrame, Sequence } from "remotion";
import {
  TournamentTopTail,
  DivisionWinnersTopAndTail,
} from "./Tournament_Top_Tail";
// Components
import { POTDBATTING_BODY } from "./Compositions/WEEKLY/POTD/BATTING/POTDBATTING_BODY";
import { POTDBOWLING_BODY } from "./Compositions/WEEKLY/POTD/BOWLING/POTDBOWLING_BODY";
import { TOP5_BODY } from "./Compositions/TOP5/Top5_BODY";
import { MATCHDAYRESULTS_BODY } from "./Compositions/WEEKLY/MATCHDAY/MATCHDAYRESULTS/MATCHDAYRESULTS_BODY";
import { MATCHDAYFIXTURES_BODY } from "./Compositions/WEEKLY/MATCHDAY/MATCHDATFIXTURES/MATCHDAYFIXTURES_BODY";
import { MOTD_BODY } from "./Compositions/WEEKLY/MATCHDAY/MOTD/GAMEOFTHEWEEK_BODY";
import { MOTD_LITE_BODY } from "./Compositions/WEEKLY/MATCHDAY/MOTDLITE/GAMEOFTHEWEEKLITE_BODY";
import { POSTMATCHINTERVIEW_BODY } from "./Compositions/WEEKLY/INTERVIEWS/POSTMATCH/POSTMATCHINTERVIEW_BODY";
import { TOURNAMENTWINNERS_BODY } from "./Compositions/WEEKLY/WINNERS/TOURNAMENTWINNERS";

// Add new Templates in here
// UPCOMING FIXTURES
export const UPCOMINGFIXTURES_TOURNAMENT_GLASS = ({ DATA, RenderType }) => {
//console.log(DATA);
  const OpeningMeta = {
    Label: "Fixtures",
  };
  return (
    <>
      <TournamentTopTail
        VideoLabel="Fixtures"
        OpeningMeta={OpeningMeta}
        DATA={DATA}
      >
        <MATCHDAYFIXTURES_BODY
          DATA={DATA}
          VideoSettings={DATA.VideoSettings}
          RenderType={RenderType}
        />
      </TournamentTopTail>
      <DefaultAudio VideoSettings={DATA.VideoSettings} />
    </>
  );
};

/*************************************  */
// Match Day Results Sequence
export const MATCHDAYRESULTS_TOURNAMENT_GLASS = ({ DATA, RenderType }) => {
//console.log(DATA);
  const OpeningMeta = {
    Label: "RESULTS",
  };
  return (
    <>
      <TournamentTopTail
        VideoLabel="Results"
        OpeningMeta={OpeningMeta}
        DATA={DATA}
      >
        <MATCHDAYRESULTS_BODY
          DATA={DATA}
          VideoSettings={DATA.VideoSettings}
          RenderType={RenderType}
        />
      </TournamentTopTail>
      <DefaultAudio VideoSettings={DATA.VideoSettings} />
    </>
  );
};

/*************************************  */
// BATTING POTD Results Sequence
export const POTDBATTING_TOURNAMENT_GLASS = ({ DATA, RenderType }) => {
  //console.log(DATA)
  const OpeningMeta = {
    Label: "Batting",
  };

  return (
    <>
      <TournamentTopTail
        VideoLabel="Batting POTD"
        OpeningMeta={OpeningMeta}
        DATA={DATA}
      >
        <POTDBATTING_BODY
          DATA={DATA}
          VideoSettings={DATA.VideoSettings}
          RenderType={RenderType}
        />
      </TournamentTopTail>
      <DefaultAudio VideoSettings={DATA.VideoSettings} />
    </>
  );
};

/*************************************  */
// BOWLING POTD Results Sequence
export const POTDBOWLING_TOURNAMENT_GLASS = ({ DATA, RenderType }) => {
//console.log(DATA);
  const OpeningMeta = {
    Label: "Bowling",
  };
  return (
    <>
      <TournamentTopTail
        VideoLabel="Bowling"
        OpeningMeta={OpeningMeta}
        DATA={DATA}
      >
        <POTDBOWLING_BODY
          DATA={DATA}
          VideoSettings={DATA.VideoSettings}
          RenderType={RenderType}
        />
      </TournamentTopTail>
      <DefaultAudio VideoSettings={DATA.VideoSettings} />
    </>
  );
};

/*************************************  */
// Game of the Week
export const MOTD_TOURNAMENT_GLASS = ({ DATA, RenderType }) => {
//console.log(DATA);
  const OpeningMeta = {
    Label: "Match of the Day",
  };
  return (
    <>
      <TournamentTopTail
        VideoLabel="Match of the Day"
        OpeningMeta={OpeningMeta}
        DATA={DATA}
      >
        <MOTD_BODY
          DATA={DATA}
          VideoSettings={DATA.VideoSettings}
          RenderType={RenderType}
        />
      </TournamentTopTail>
      <DefaultAudio VideoSettings={DATA.VideoSettings} />
    </>
  );
};

export const MOTD_LITE_TOURNAMENT_GLASS = ({ DATA, RenderType }) => {
//console.log(DATA);
  const OpeningMeta = {
    Label: "Match of the Day",
  };
  return (
    <>
      <TournamentTopTail
        VideoLabel="Match of the Day"
        OpeningMeta={OpeningMeta}
        DATA={DATA}
      >
        <MOTD_LITE_BODY 
          DATA={DATA}
          VideoSettings={DATA.VideoSettings}
          RenderType={RenderType}
        />
      </TournamentTopTail>
      <DefaultAudio VideoSettings={DATA.VideoSettings} />
    </>
  );
};


/* export const MOTD_LITE_TOURNAMENT_GLASS = ({ DATA, RenderType }) => {
//console.log(DATA);
  const OpeningMeta = {
    Label: "Match of the Day",
  };
  return (
    <>
      <TournamentTopTail
        VideoLabel="Match of the Day"
        OpeningMeta={OpeningMeta}
        DATA={DATA}
      >
        <MOTD_LITE_BODY
          DATA={DATA}
          VideoSettings={DATA.VideoSettings}
          RenderType={RenderType}
        />
      </TournamentTopTail>
      <DefaultAudio VideoSettings={DATA.VideoSettings} />
    </>
  );
}; */

/*************************************  */
// Post Match Interview
export const POSTMATCHINTERVIEW_TOURNAMENT_GLASS = ({ DATA }) => {
  const OpeningMeta = {
    Label: "Post Match Reaction",
  };
  return (
    <> 
      <TournamentTopTail
        VideoLabel={OpeningMeta.Label}
        OpeningMeta={OpeningMeta}
        DATA={DATA}
      >
        <POSTMATCHINTERVIEW_BODY DATA={DATA} />
      </TournamentTopTail>
    </>
  );
};

/*************************************  */
// LEADER BOARDs

// Top 5's
export const TOURNAMENT_TOP5_4by5 = ({
  DATA,
  RenderType,
  MainTitle = "Top 5",
  VARIABLE,
}) => {
//console.log(DATA);

  return (
    <>
      <TournamentTopTail VideoLabel={MainTitle} DATA={DATA}>
        <TOP5_BODY
          DATA={DATA}
          VideoSettings={DATA.VideoSettings}
          RenderType={RenderType}
          VARIABLE={VARIABLE}
        />
      </TournamentTopTail>
      <DefaultAudio VideoSettings={DATA.VideoSettings} />
    </>
  );
};

/*************************************  */
// WINNERS

// Top 5's
export const TOURNAMENT_WINNERS = ({
  DATA,
}) => {

  const MainTitle = ''
  const Subtitle = ''

  const OpeningMeta = {
    Label: "Tournament Winners",
  };
  return (
    <> 
      <DivisionWinnersTopAndTail
        VideoLabel={OpeningMeta.Label}
        OpeningMeta={OpeningMeta}
        DATA={DATA}
      >
        <TOURNAMENTWINNERS_BODY
          DATA={DATA}
          VideoSettings={DATA.VideoSettings}
          Subtitle={Subtitle}
          MainTitle={MainTitle}
        />
      </DivisionWinnersTopAndTail>
      <DefaultAudio VideoSettings={DATA.VideoSettings} />
    </>
  );
};

const DefaultAudio = ({ VideoSettings }) => {
  const frame = useCurrentFrame();
  return (
    <Sequence from={90}>
      <Audio
        src={`https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/TournamentTitleMusic_v2.mp3`}
        startFrom={0} // if composition is 30fps, then it will start at 2s
        endAt={VideoSettings.VideoDurationinFrames} // if composition is 30fps, then it will end at 4s
        volume={interpolate(
          frame,
          [
            VideoSettings.VideoDurationinFrames - 120,
            VideoSettings.VideoDurationinFrames,
          ],
          [0.7, 0],
          {
            extrapolateLeft: "clamp",
          }
        )}
      />
    </Sequence>
  );
};
