
import { ThemeProvider } from 'styled-components'




// Import Design Templates for MATCHDAYRESULT.
// Add new deisng patterns below
// GLASS
import {TEAMOFTHEWEEK_GLASS} from '../../theme/Templates/GLASS/index'
// END


export const Build_TEAMOFTHEWEEK_4by5 = (props)=>{
    const {RENDER} = props
	//console.log(DATA)
	const TEMPLATES={
		"Glass":<TEAMOFTHEWEEK_GLASS  RENDER={RENDER}/> 
	}  
    return ( 
		<ThemeProvider theme={RENDER.THEME.VideoTheme.Theme}  >  
			{ 
				TEMPLATES[RENDER.THEME.VideoTemplate]
			}
		</ThemeProvider> 
    );  
}