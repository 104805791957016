import { Sequence, Series } from "remotion";
// Components

import { DisplayPOTD } from "./POTD";
import { DisplayHonorable } from "./Honorable";
import { Counter } from "./POTD_Counter";

import {
  SeactionTitleContainer,
  HONORABLEMENTIONS,
} from '../../../utils/Containers';
import { POTDUL } from "../../../utils/html";
import { H1 } from "../../../utils/Text";

export const POTDBATTING_BODY = ({ DATA, VideoSettings }) => {
  return (
    <>
      <SHOWCASE DATA={DATA} />
    </>
  );
};
//<HONERABLE DATA={DATA}/>

const SHOWCASE = ({ DATA }) => {
  const Durations = {
    Begin: 0, // bring in overlay
    TransitionAfter: 60, // slide overlay down
    Close: 150, // remove overlay prep nect item
    Offset: 30,
  };
  return (
    <Series>
      {DATA.SHOWCASE.map((arr, i) => {
        return (
          <Series.Sequence
            durationInFrames={Durations.Close}
            layout="none"
            key={i}
          >
            <Counter _INT={i + 1} _TOTAL={DATA.SHOWCASE.length} />
            <Series>
              <Series.Sequence durationInFrames={Durations.Close} layout="none">
                <DisplayPOTD
                  _PLAY={arr}
                  _GallerySettings={DATA.VideoSettings}
                  _INT={i}
                  _Durations={Durations}
                />
              </Series.Sequence>
            </Series>
          </Series.Sequence>
        ); 
      })}
      <Series.Sequence durationInFrames={300}>
        <HONORABLEMENTIONS>
          <SeactionTitleContainer>
            <H1>honorable mentions</H1>
          </SeactionTitleContainer>

          <POTDUL>
            {DATA.HONERABLE.map((arr, i) => {
              return (
                <DisplayHonorable
                  key={i}
                  _PLAY={arr}
                  _GallerySettings={DATA.VideoSettings}
                  _INT={i}
                />
              );
            })}
          </POTDUL>
        </HONORABLEMENTIONS>
      </Series.Sequence>
    </Series>
  );
};

/*
 <Series.Sequence durationInFrames={Durations.Close} layout="none" key={i}>
                        <Series> 
                            <Series.Sequence durationInFrames={Durations.Close} layout="none">
                              <DisplayPOTD _PLAY={arr} _GallerySettings={DATA.VideoSettings} _INT={i}/>
                            </Series.Sequence>
                        </Series>
                    </Series.Sequence> 

<Sequence key={i}  from={((DATA.VideoSettings.StartOffset*i)+(DATA.VideoSettings.OpeningSceneDuration-DATA.VideoSettings.GalleryTimings[0]))} durationInFrames={DATA.VideoSettings.Duration}>
                  
                  <Sequence from={(0)} durationInFrames={DATA.VideoSettings.StartOffset}>
                    <Counter _INT={(i+1)} _TOTAL={DATA.SHOWCASE.length}/>
                  </Sequence> 
                  <DisplayPOTD _PLAY={arr} _GallerySettings={DATA.VideoSettings} _INT={i}/>
                </Sequence>
*/
const HONERABLE = ({ DATA }) => {
  return (
    <Sequence
      from={
        DATA.VideoSettings.POTDDuration +
        DATA.VideoSettings.OpeningSceneDuration
      }
      durationInFrames={DATA.VideoSettings.HONERABLEDuration}
    >
      <HONORABLEMENTIONS>
        <SeactionTitleContainer>
          <H1>honorable mentions</H1>
        </SeactionTitleContainer>

        <POTDUL>
          {DATA.HONERABLE.map((arr, i) => {
            return (
              <DisplayHonorable
                key={i}
                _PLAY={arr}
                _GallerySettings={DATA.VideoSettings}
                _INT={i}
              />
            );
          })}
        </POTDUL>
      </HONORABLEMENTIONS>
    </Sequence>
  );
};
