import { useEffect, useState } from "react";

import { useGetLeague } from "../../../Hooks/Content/useLeagues";

import { SelectTeamFromDivision } from "../../settings/SelectTeamFromDivision";
import { H3 } from "../../../Theme/type";
import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";
import { Group, Stack } from "@mantine/core";

import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
export const SelectATeamFromDivision = ({
  SelectedTeam,
  setSelectedTeam,
  Division,
}) => {
  const [value, setValue] = useState(SelectedTeam);
  const [DisplaySelected, setDisplaySelected] = useState(false);
  const [ItemState, setItemState] = useState(0);
  const [isTeamList, setisTeamList] = useState(false);
  const handleChange = (e) => {
  //console.log(e);
    setValue(e);
    setDisplaySelected(e);
    setSelectedTeam(e);
    setItemState(10);
  };

  useEffect(() => {
    //console.log(Division?.attributes?.Name)
    setisTeamList(false);
  }, [Division]);

  return (
    <>
      <BLOCK_Section_SubHeading
        LABEL="Select a Team from Selected Division"
        BGColor={0}
        Color={DisplaySelected ? 7 : 5}
      />

      <VideoWizardContainer DividerSize={ItemState} Padding="xs">
        <Group position="apart" spacing="xl" grow>
          {Division === false ? (
            "Awaiting Division"
          ) : isTeamList ? (
            <SelectTeamFromDivision
              DATA={isTeamList}
              CALLBACK={handleChange}
              DefaultValue={value}
            />
          ) : (
            <FetchTeams Division={Division} setisTeamList={setisTeamList} />
          )}
          {/* {DisplaySelected ? (
            <RenderSelected SELECTED={DisplaySelected} />
          ) : (
            false
          )} */}
        </Group>
      </VideoWizardContainer>
    </>
  );
};

const FetchTeams = ({ Division, setisTeamList }) => {
  //console.log(Division[0])
  const [getLeague, FetchgetLeague] = useGetLeague();

  useEffect(() => {
  //console.log('Division.id', Division[0].id)
    FetchgetLeague(Division[0].id);
  }, [Division[0]]);

  useEffect(() => {
    if (getLeague != null) {
      //console.log(getLeague.attributes.teams.data)
      setisTeamList(getLeague.attributes.teams.data);
    }
  }, [getLeague]);

  return <>FETCHING TEAMS</>;
};

/* const RenderSelected = ({ SELECTED }) => {
  //console.log(SELECTED.attributes.Name)
  return (
    <Stack align="center" justify="space-around">
      <H3 colorID={5}>{SELECTED.attributes.Name}</H3>
    </Stack>
  );
}; */
