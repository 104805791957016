/* eslint-disable react/jsx-pascal-case */
//import { useSelector } from "react-redux";

import { useEffect } from "react";

// Hooks
import { useGetFranchises } from "../../Hooks/Content/useFranchises";
// Compoennts

//import {SelectDefaultVideoSettings} from './SelectDefaultVideoSettings'
//import { ListDivisions } from "../Dashboard/DashboardWidgets/ActiveLeagues";
import { PageHeader } from "../../Components/interface/ProductHeader";
import { UISkeletonLines } from "../../Components/Global/Skeleton";
import { NoActiveFranchise } from "./NoActiveFranchise";

// Components
import { SelectAFranchise } from "./SyncData/SelectFranchise";
import { UpdateFranchiseHub } from "./SyncData/UpdateGameData";

export const FranchiseHub = () => {
  const [Franchises, FetchFranchises] = useGetFranchises();
 
  useEffect(() => {
    FetchFranchises();
  }, []);

  useEffect(() => {
    console.log(Franchises)
  }, [Franchises]);

  if (!Franchises) return <UISkeletonLines />;
  if (Franchises.data.length === 0) return <NoActiveFranchise />;

  return (
    <>
      <PageHeader NavPosition="Franchises" />
      <SelectAFranchise/>
      <UpdateFranchiseHub />
      
    </>
  );
};
