/* eslint-disable react/jsx-pascal-case */
import { useState } from "react";

import { Space, Group } from "@mantine/core";
import { BTN_CTA } from "../../Theme/Structure/Button/BTNS";

// Components
import { CreateNewTournament } from "./CreateNewTournament";
import { CurrentTournaments } from "./CurrentTournaments";
import { ViewTournament } from "./ViewTournament";
import { BLOCK_ToolBar } from "../../Components/interface/BLOCK_Toolbar";
const CreateTournament = () => {
  const [Create, setCreate] = useState(false);
  const [ID, setID] = useState(false);
  return (
    <>
      {!ID ? (
        <BLOCK_ToolBar
          TOOLS={[
            ID ? (
              false
            ) : (
              <BTN_CTA
                fullWidth={false}
                CALLBACK={() => {
                  setCreate(!Create);
                }}
                LABEL={Create ? "Back" : "Request Tournament"}
              />
            ),
          ]}
        />
      ) : (
        false
      )}

      <Space h="md" />
      {ID ? (
        <ViewTournament setID={setID} ID={ID} />
      ) : Create ? (
        <CreateNewTournament setCreate={setCreate} />
      ) : (
        <CurrentTournaments setID={setID} />
      )}
    </>
  );
};
export default CreateTournament;
