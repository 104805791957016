/* eslint-disable react/jsx-pascal-case */

// Actions
import { BattingList } from "../../../../../../actions/Stats";

import { VideoWizardContainer } from "../../../../../../Theme/Structure/BodyContainer";
import { Container } from "@mantine/core";

import { BLOCK_Section_SubHeading } from "../../../../../../Components/interface/BLOCK_Section_SubHeading";
import { P } from "../../../../../../Theme/type";

// utils
import { SORTABLETABLE } from "./utils/SortableTable";

export const ListBatsmen = ({ Fixtures, teamID, setSelectedPlayer }) => {
  const OBJ = {
    OrderBy: "BATTING_Runs",
    ITEMS: [
      { Name: "Name", PATH: "Name", ToFixed: false },
      { Name: "INN", PATH: "BATTING_INNINGS", ToFixed: false },
      { Name: "RUNS", PATH: "BATTING_Runs", ToFixed: false },
      { Name: "Balls Faced", PATH: "BATTING_Balls", ToFixed: false },
      { Name: "Fours", PATH: "BATTING_fours", ToFixed: false },
      { Name: "Sixes", PATH: "BATTING_sixes", ToFixed: false },
      { Name: "AVG", PATH: "BATTING_AVG", ToFixed: true },
      { Name: "SR", PATH: "BATTING_SR", ToFixed: true },
    ],
  };

  return (
    <Container size={"lg"}>
      <BLOCK_Section_SubHeading LABEL="BATTING" Color={5} />
      <P>
        Use the dots above a Label to sortby this Category, Click twice to
        change order
      </P>
      <VideoWizardContainer>
        <SORTABLETABLE
          DATA={BattingList(Fixtures, teamID)}
          CALLBACK={setSelectedPlayer}
          OBJ={OBJ}
        />
      </VideoWizardContainer>
    </Container>
  );
};
