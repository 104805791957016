import { useCurrentFrame } from "remotion";

import { useTheme } from "styled-components";
import { Building, BuildingSkyscraper, World } from "tabler-icons-react";
import styled from "styled-components";

// Animation
import { interpolateOpacityByFrame } from "../../../../../../../Animation/interpolate";
import { SpringToFrom } from "../../../../../../../Animation/RemotionSpring";

// Components

import { H1, H2, H3, H4 } from "../../../../../../utils/Text";

export const RankingWrapper = ({ D, STYLE }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();

  const OPACITY = (START, END) =>
    interpolateOpacityByFrame(frame, START, END, 0, 1);
  const TRANSLATEY = (START) => `translateY(${SpringToFrom(START, 100, 10)}px)`;
  const TRANSLATEX = (START) => `translateX(${SpringToFrom(START, -100, 0)}px)`;

  return (
    <RankContainer>
      <div>
        <Building
          size={100}
          style={{
            opacity: OPACITY(15, 30),
            transform: TRANSLATEY(15),
            stroke: theme.Copy[0],
            strokeWidth: "1px",
          }}
        />
        <H1
          style={{
            opacity: OPACITY(15, 30),
            transform: TRANSLATEX(15),
            color: theme.Copy[0],
          }}
        >
          {D.City_Rank}
        </H1>
        <H4
          style={{
            opacity: OPACITY(15, 30),
            transform: TRANSLATEX(15),
            color: theme.Copy[0],
            letterSpacing: "0px",
          }}
        >
          City
        </H4>
      </div>
      <div>
        <BuildingSkyscraper
          size={100}
          style={{
            opacity: OPACITY(25, 40),
            transform: TRANSLATEY(25),
            stroke: theme.Copy[0],
            strokeWidth: "1px",
          }}
        />
        <H1
          style={{
            opacity: OPACITY(25, 40),
            transform: TRANSLATEX(25),
            color: theme.Copy[0],
          }}
        >
          {D.National_Rank}
        </H1>
        <H4
          style={{
            opacity: OPACITY(25, 40),
            transform: TRANSLATEX(25),
            color: theme.Copy[0],
            letterSpacing: "0px",
          }}
        >
          National
        </H4>
      </div>
      <div>
        <World
          size={100}
          style={{
            opacity: OPACITY(35, 50),
            transform: TRANSLATEY(35),
            stroke: theme.Copy[0],
            strokeWidth: "1px",
          }}
        />
        <H1
          style={{
            opacity: OPACITY(35, 50),
            transform: TRANSLATEX(35),
            color: theme.Copy[0],
          }}
        >
          {D.World_Rank}
        </H1>
        <H4
          style={{
            opacity: OPACITY(35, 50),
            transform: TRANSLATEX(35),
            color: theme.Copy[0],
            letterSpacing: "0px",
          }}
        >
          World
        </H4>
      </div>
    </RankContainer>
  );
};

const RankContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  align-content: center;
  width: 100%;
  margin: 0px 0;
`;
