import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuthUser } from "../../../Hooks/Auth/useAuthUserMe";
import { useCreateSponsor } from "../../../Hooks/Content/useSponsors";

import { Container, Image, Input, Space, Textarea } from "@mantine/core";
import {
  VideoWizardContainer,
  ContentContainerx3,
} from "../../../Theme/Structure/BodyContainer";

import { BTN_CREATE } from "../../../Theme/Structure/Button/BTNS";
import { H3, H1 } from "../../../Theme/type";

import DropZone from "../../../Components/Global/DropZone";
import { IconTag } from "@tabler/icons";
import { IsLoading } from "../../../Components/Global/isLoadingDefault";
// UI ELEMENTS
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
export const CreateNewSponsor = ({ setCreate }) => {
  const [Sponsor, CreateSponsor] = useCreateSponsor();
  const [authUser, FetchauthUser] = useAuthUser();

  const AUTH = useSelector((state) => state.AUTH);
  const [Name, setName] = useState("");
  const [Label, setLabel] = useState("");
  const [Tagline, setTagline] = useState("");
  const [URL, setURL] = useState("");
  const [COPY, setCOPY] = useState("");
  const [disabled, setdisabled] = useState(true);
  const [DataSent, setDatasent] = useState(false);
  const [ImageFile, setImageFile] = useState(false);

  const handlePromotion = (e) => {
    setLabel(e.target.value);
  };
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleTagline = (e) => {
    setTagline(e.target.value);
  };
  const handleURL = (e) => {
    setURL(e.target.value);
  };
  const handleCopy = (e) => {
    setCOPY(e.target.value);
  };
  const handleSubmit = () => {
    setDatasent(true);
    CreateSponsor({
      Name: Name,
      Label: Label,
      Tagline: Tagline,
      URL: URL,
      SocialMediaCopy: COPY,
      users_permissions_user: AUTH.AUTHUSER.id,
      Logo: [ImageFile.id],
    });
  };

  useEffect(() => {
    if (Name.length != 0 && Tagline.length != 0) {
      setdisabled(false);
    }
  }, [Name, Tagline, URL]);

  useEffect(() => {
    if (Sponsor) {
      FetchauthUser();
    }
  }, [Sponsor]);

  useEffect(() => {
    if (authUser) {
      setDatasent(false);
      setCreate(false);
    }
  }, [authUser]);

  const FORMINPUTS = [
    {
      id: "Sponsor-Promotion",
      label: "Promotion Name",
      description: "Use this label to identify your promotion in Statto. i.e. Australian Nationals Sponsor 2022",
      error: "Promotion Name Required",
      placeholder: "Create a unique promotion name to identify it later.",
      handle: handlePromotion,
      icon:<IconTag />
    },
    {
      id: "Sponsor-Promotion",
      label: "Sponsors Name",
      description: "Add the display name for the Sponsor.",
      error: "Promotion Name Required",
      placeholder: "What will be the Display Name for this sponsor?",
      handle: handleName,
      icon:<IconTag />
    },
    {
      id: "Sponsor-Promotion",
      label: "Promotion Tagline",
      description: "Is there a Tagline for this sponsor? This tagline will feature in the videos.",
      error: "Promotion Name Required",
      placeholder: "Promotional Tagline!",
      handle: handleTagline,
      icon:<IconTag />
    },{
      id: "Sponsor-Promotion",
      label: "Promotion CALL TO ACTION",
      description: "Is there an action or event the sponsor would like to draw users to?",
      error: "Promotion Name Required",
      placeholder: "Promotion CTA/URL/EVENT",
      handle: handleURL,
      icon:<IconTag />
    },
  ];

  if (DataSent) return <IsLoading />;
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Create a Sponsor",
          ICON: "CirclePlus",
          DESCRIPTION: "Fill in the form below to create a new Asset sponsor. ",
          TOOLBAR: false,
        }}
      />
      <Container size="lg"  p={0}>
        <VideoWizardContainer>
        

          {FORMINPUTS.map((INPUT, i) => {
            return <InputWrapper key={i} OBJ={INPUT} />;
          })}
          
          <Space h="md" />
          <Textarea
            placeholder="#hashtag"
            onChange={handleCopy}
            description="Sponsors Social media Copy. Here you can add a small snippet of copy that will be available to copy and paste whenever this sponsor is used."
            size="lg"
          />

          <Space h="md" />
          {!ImageFile ? (
            <DropZone setImageFile={setImageFile} />
          ) : (
            <SponsorLogo ImageFile={ImageFile} />
          )}

          <Space h="md" />
          <BTN_CREATE
            CALLBACK={handleSubmit}
            LABEL={`Create New Sponsor`}
            TIPOBJ={{
              LABEL: "Submit New Sponsor",
              COLOR: "green",
              POSITION: "right",
            }}
            DISABLED={disabled}
          />
        </VideoWizardContainer>
      </Container>
    </>
  );
};

const SponsorLogo = ({ ImageFile }) => {
  return (
    <>
      <Image
        radius="md"
        width={120}
        src={ImageFile.url}
        alt="Player or Team image Missing"
      />
    </>
  );
};

const InputWrapper = ({ OBJ, handle }) => {
  /*
   {
      id: "Sponsor-Promotion",
      label: "Promotion Name",
      description: "Promotion Name",
      error: "Promotion Name",
      placeholder: "Sponsor Promotion",
      handle: handlePromotion,
    },
  */
  return (
    <Input.Wrapper
      id={OBJ.id}
      required
      withAsterisk
      label={OBJ.label}
      description={OBJ.description}
      mb={20}
    /*   error={OBJ.error} */
    >
      <Input
        icon={OBJ.icon}
        placeholder={OBJ.placeholder}
        id={OBJ.id}
        onChange={OBJ.handle}
      />
    </Input.Wrapper>
  );
};
