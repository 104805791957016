import {interpolate, useCurrentFrame, useVideoConfig,spring} from 'remotion';
import {FullWidthWrapper} from '../../utils/Containers'
import { H1,H2, H3,P,H4 } from '../../utils/Text';
import { Img } from "remotion";
import { ImageRatio } from '../../../../../actions/Images';
 


export const OpeningSponsorSequence = (props)=>{

    const {RENDER} = props
    
    const frame = useCurrentFrame();
    const { fps } = useVideoConfig();

    const SPONSOR = RENDER.SPONSORSHIP
    console.log(SPONSOR)
    const FadeIn=(START, END)=>{
        return  interpolate(frame, [START, END], [0, 1], {extrapolateRight: "clamp",});
    }

    const StaggerSpring = (int)=>{  
        return  spring({ 
            fps,
            from: 50,
            to: 0,
            frame:frame-int,
			config: {
				damping: 80,
				stiffness: 100,
				mass: 0.5,
			  },
          })
    }
    return(
        <FullWidthWrapper>
            <div style={{opacity:FadeIn(0,30),transform: `translateY(${StaggerSpring(0)}px)`}}>
                <H4>Brought to you by</H4>
            </div>
            
            <div style={{opacity:FadeIn(7,45),padding:'5%', transform: `translateY(${StaggerSpring(7)}px)`}}>
                <SponsorLogo Sponsor={SPONSOR}/> 
            </div>

            <div>
                <H1 style={{ margin:' 0', width:'100%'}}>{
                    SPONSOR.Name.split(' ').map((word,i)=>{
                        return(
                            <span key={i} style={{margin:'0 .1em', opacity:FadeIn((30+(5*i)),(60+(5*i))),transform: `translateY(${StaggerSpring((30)+(5*i))}px)`, width:'100%'}}>{word}</span>
                        )
                    })
                }</H1>
               
            </div>
            <div style={{opacity:FadeIn(60,75),transform: `translateY(${StaggerSpring(35)}px)`, width:'100%'}}> 
                <P style={{ margin:'0'}}>{SPONSOR.Tagline}</P>
            </div>
           
        </FullWidthWrapper>
    ) 
}


export const ClosingSponsorSequence = (props)=>{
    //console.log(props.DATA.OrderMetadata.Sponsor)
    const SPONSOR = props.DATA.OrderMetadata.Sponsor

    const frame = useCurrentFrame();
    const { fps } = useVideoConfig();

   
    
    const FadeIn=(START, END)=>{
        return  interpolate(frame, [START, END], [0, 1], {extrapolateRight: "clamp",});
    }

    const StaggerSpring = (int)=>{
        return  spring({
            fps,
            from: 50,
            to: 0,
            frame:frame-int,
			config: {
				damping: 80,
				stiffness: 100,
				mass: 0.5,
			  },
          })
    }
    return(
        <div style={{position:'absolute',top: '2em',left: 'auto', right:'auto', width:'100%'}}>
            <div style={{opacity:FadeIn(0,30),transform: `translateY(${StaggerSpring(0)}px)`}}>
                <H4>Brought to you by</H4>
            </div>
            <div style={{opacity:FadeIn(7,45),textAlign: 'center', transform: `translateY(${StaggerSpring(7)}px)`}}>
                <SponsorLogoEND Sponsor={SPONSOR}/> 
            </div>   
        </div>
    ) 
}


export const BodySponsorSequence = (props)=>{
    //console.log(props.DATA.OrderMetadata.Sponsor)
    const SPONSOR = props.DATA.OrderMetadata.Sponsor

    const frame = useCurrentFrame();
    const { fps } = useVideoConfig();

   
    
    const FadeIn=(START, END)=>{
        return  interpolate(frame, [START, END], [0, 1], {extrapolateRight: "clamp",});
    }

    const StaggerSpring = (int)=>{
        return  spring({
            fps,
            from: 50,
            to: 0,
            frame:frame-int,
			config: {
				damping: 80,
				stiffness: 100,
				mass: 0.5,
			  },
          })
    }
    return(
        <div style={{position:'absolute',bottom: '.5em',left: '27em'}}>
           <div style={{opacity:FadeIn(7,45),transform: `translateY(${StaggerSpring(7)}px)`}}>
                <Img style={{height:'40px'}} src={`${SPONSOR.Logo.data.attributes.url}`} />
            </div>  
             
        </div>
    ) 
}


const IMGSTYLESEND={
    width:'200px',
    height:'auto'
}

export const SponsorLogo = ({Sponsor}) => {
  console.log(Sponsor)
// import {ImageRatio} from '../../../../../actions/Images'
    const STYLES = ImageRatio(Sponsor.Logo.data.attributes).SponsorLogo
  //console.log(Sponsor.Logo.data?.attributes.url)
    return <Img style={STYLES} src={`${Sponsor.Logo.data?.attributes.url}`} />;
  };
  export const SponsorLogoEND = ({Sponsor}) => {
    return <Img style={IMGSTYLESEND} src={`${Sponsor.Logo.data.attributes.url}`} />;
  }; 