
import {  Group, Image, Space, Stack} from '@mantine/core';
import { useEffect, useState } from "react";

// UI ELEMENTS
//import {BLOCK_Section_Title} from '../../../Components/interface/BLOCK_Section_Title'
import {BLOCK_Section_SubHeading_INVERT} from '../../../Components/interface/BLOCK_Section_SubHeading'
import { H3 } from '../../../Theme/type';

export const DefaultProfileImageContainer = ({SelectedTeam})=>{
    const [ImageRatio, setRatio] = useState(150);
    useEffect(()=>{
        //console.log(SelectedTeam)
    },[SelectedTeam])
    return(
    <>
        <Group position='center'>
       
            <Image 
                src={`${SelectedTeam.attributes?.Logo?.data?.attributes?.url}`}
                placeholder
                width={ImageRatio}
                height={ImageRatio}
                radius={(ImageRatio/10)}
                fit={"cover"}
                caption="Team Logo"
                />
            <Stack>
                <H3 lineheight={'.8em'} colorID={3}>{SelectedTeam.attributes.Name}</H3>
                <H3 lineheight={'.8em'} colorID={3}>{`City Rank :  ${SelectedTeam.attributes.City_Rank}`}</H3>
                <H3 lineheight={'.8em'} colorID={3}>{`National Rank : ${SelectedTeam.attributes.National_Rank}`}</H3>
                <H3 lineheight={'.8em'} colorID={3}>{`World Rank :  ${SelectedTeam.attributes.World_Rank}`}</H3>
           </Stack>
           <Image 
                src={`${SelectedTeam.attributes?.TeamPhoto?.data?.attributes?.url}`}
                placeholder
                width={ImageRatio}
                height={ImageRatio}
                radius={(ImageRatio/10)}
                fit={"cover"}
                caption="Default Team Image"
                />
        </Group>
       
        </>                
    )
}