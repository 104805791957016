import {gql,} from "@apollo/client";


export const QUERY = (APISETTINGS)=>{
    return gql`# Write your query or mutation here
    query PLAYERMILESTONE {
      player(id:${APISETTINGS.PlayerID}){
      data{
            attributes{
              Name
              PlayerID
              MATCHES
              INNINGS
              RUNS
              BATTINGAVERAGE
              STRIKERATE
              HIGHSCORE
              FIFTIES
    HUNDREDS
              OVERS
              WICKETS
              BOWLINGAVERAGE
              ECONOMY
              BESTFIGURES
              W3
              W5
              NATIONALRANKINGBATTING
              NATIONALRANKINGBOWLING
              WORLDRANKINGBATTING
              WORLDRANKINGBOWLING
              TYPEBATTING
              TYPEBOWLING
              PLAYERPROFILELEVEL
              ImageGallery{
                 data {
                    attributes {
                    
                      url
                      height
                      width
                    }
                  }
              }
              image {
                  data {
                    attributes {
                      formats
                      url
                      height
                      width
                    }
                  }
                }
            }
          }
      }  
    }`
}
  
