import { useTheme } from "styled-components";
import styled from "styled-components";

// Animations
import { SpringToFrom } from "../../../../../../Animation/RemotionSpring";
import { EraseFromMiddle, FromBottomToTop, FromMiddle, FromTopToBottom } from "../../../../../../Animation/ClipWipe";
import {interpolateOpacityByFrame} from "../../../../../../Animation/interpolate";
import { P } from "../../../../../utils/Text";
import { Box } from "@mantine/core";
import { getContrastColor } from "../../../../../../../../actions/colors";
import { useCurrentFrame } from "remotion";

export const GameResult = ({ resultStatement }) => {
  const theme = useTheme();
  const frame = useCurrentFrame();

  return (
    <Box
        style={{
            backgroundColor:theme.Base[0],
            width:'90%',
            margin:' 0 5% -10px ',
            padding:'.8em 10px',
            opacity:interpolateOpacityByFrame(frame, 40,45, 0,1),
            position:'absolute',
            borderRadius: '100px 100px 0 0px',
            border:`1px solid ${theme.Base[1]}`,
            transform: `translateY(${SpringToFrom(
              60,
              60, 
              3,
              "Springy100"
            )}px) translateY(${SpringToFrom(250, 3, 60, "Springy100")}px)`,
            
            clipPath: EraseFromMiddle(250),  
        }}
    >
    <P
      style={{
        margin: "0",
        padding: "0",
        fontSize: "28px",
        lineHeight: "25px",
        fontWeight: "600",
        whiteSpace: "nowrap",
        letterSpacing:'-2px',
        color: getContrastColor(theme.Base[0]) ,
        clipPath: FromBottomToTop(35,'Springy100'),
      }}
    >
      {resultStatement}
    </P>
    </Box>
  );
};

