import { Select } from '@mantine/core';


export const SelectWhoWins = ({SelectedGame,setSelectWinner})=>{
    
    const handleChange=(e)=>{setSelectWinner(e)}
    const CreateFranchiseSelect = ()=>{
        let  Teams = SelectedGame.value.split('vs');
        let ARR=[Teams[0].trim(),Teams[1].substr(0, Teams[1].length-12).trim()]
        return ARR
    }

    if(SelectedGame.value === undefined)
    return false
    return (
        <Select
          label="Make a Prediction"
          placeholder="Who wins?"
          data={CreateFranchiseSelect()} 
          onChange={handleChange}
          styles={(theme) => ({
            input:{
              '&, &:focus':{
                borderColor: theme.colors.brand[5],
                color: theme.colors.brand[5]
              }
            },
            item: {
              // applies styles to selected item
              '&[data-selected], &:focus': {
                '&, &:hover, &:focus': {
                  backgroundColor:theme.colors.brand[3],
                  color: theme.colors.brand[4],
                },
              },
    
              // applies styles to hovered item (with mouse or keyboard)
              '&[data-hovered], :focus': {
                backgroundColor:theme.colors.brand[5],
                color: theme.colors.brand[0],
              },
            },
          })}
        />
      );
} 