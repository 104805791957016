import moment from "moment";
import { saveAs } from 'file-saver';

export const UNIXTIME = (STR_TIME) => {
  return moment.unix(STR_TIME).format("DD MMMM YYYY");
};
export const FORMATTTIME = (STR_TIME) => {
  return moment(STR_TIME).format("DD MMMM YYYY");
};

export const DAYSBETWEENDATES = (START, END) => {
  var b = moment(START);
  var a = moment(END);
  return a.diff(b, "days");
};

export const getTimeTaken = (UPDATEROW) => {
  //const INDEX = (UXSTATE?.SelectedFranchise?.attributes.franchise_update_requests.data.length - 1)
  //const UPDATEROW=UXSTATE?.SelectedFranchise?.attributes.franchise_update_requests.data[INDEX].attributes

  //console.log(UPDATEROW, UPDATEROW.STARTED,UPDATEROW.ENDED)
  //console.log((parseInt(UPDATEROW.ENDED)-parseInt(UPDATEROW.STARTED)))
  var diff = Math.floor(
      parseInt(UPDATEROW.ENDED) - parseInt(UPDATEROW.STARTED)
    ),
    units = [
      { d: 60, l: "seconds" },
      { d: 60, l: "minutes" },
    ];

  var s = "";
  for (var i = 0; i < units.length; ++i) {
    s = (diff % units[i].d) + " " + units[i].l + " " + s;
    diff = Math.floor(diff / units[i].d);
  }
  //console.log(s)
  return s;

  //return UXSTATE?.SelectedFranchise?.attributes.franchise_update_requests.data[INDEX].attributes.STAGE
};

export const TrimPlayerName = (NAME, INT = "15") => {
  //console.log('NAME', NAME.length)
  if (NAME.length > INT) {
    const NAMESPLIT = NAME.split(" ");
    const initials = NAMESPLIT[0].substring(0, 1).toUpperCase();
    //console.log(initials, NAMESPLIT[(NAMESPLIT.length-1)])
    return `${initials}. ${NAMESPLIT[NAMESPLIT.length - 1]}`;
  }
  return NAME;
};



export const MultipleImageDownloads=(FILES)=>{
  //console.log("MultipleImageDownloads",FILES)
  FILES.map((IMG,i)=>{
    //console.log(IMG.attributes.url)
    saveAs(IMG.attributes.url, `Image_${i}`);
  
  })


}

export const isFalseInArr = (arr)=>{
  return arr.every(element => element !== false);
} 
export const isFalseInObj = (arr)=>{
  return Object.values(arr).every(element => element !== false);
} 