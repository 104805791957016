import { useEffect, useState } from "react";
import {useDispatch, useSelector } from "react-redux";


import { Button, Image, Menu } from "@mantine/core";
import { IconTrash } from "@tabler/icons";
// Theme
import { H3 } from "../../../../Theme/type";
import { VideoWizardContainer } from "../../../../Theme/Structure/BodyContainer";
import {
  TABLE_WRAPPER,
  TABLE_THEAD_WRAPPER,
  TABLE_TD,
} from "../../../../Components/interface/Wrapper/Table";
export const DataCheck = () => {
  const RENDER = useSelector((state) => state.RENDER);
  const [update, setupdate] = useState(false);
  const [hasRemoved, setHasRemoved] = useState(false);

  // TODO FIX THIS SO THAT A PLAYER CAN BE RERMOVED FROM TEH OBJ STROED IN THE REDUCER
  const RemoveItemFromResults = (DATA, POSITION, RETIME = false) => {
    console.log(POSITION, RENDER.DATAOBJ)
    let newRenderDataObj = { ...RENDER.DATAOBJ };
    console.log(newRenderDataObj.SHOWCASE)
    //RENDER.DATAOBJ.SHOWCASE.splice(POSITION, 1);

    if (POSITION > -1 && POSITION < newRenderDataObj.SHOWCASE.length) {
      newRenderDataObj.SHOWCASE.splice(newRenderDataObj.SHOWCASE, 1);
      //dispatch(addRender(DATAOBJ));
    }

    console.log(newRenderDataObj.SHOWCASE.length)
    const TIMINGS = RENDER.DATAOBJ.VideoSettings;
   
   
    if (RETIME) {
      TIMINGS.VideoBodyTiming = TIMINGS.VideoBodyTiming - TIMINGS.Duration;
      TIMINGS.VideoDurationinFrames =
        TIMINGS.VideoDurationinFrames - TIMINGS.Duration;
    }
    setHasRemoved(true);
    setupdate(!update);
  };
 
  useEffect(() => {}, [update]);
  return (
    <>
      <VideoWizardContainer>
        <H3 colorID={5}>POTD Show case</H3>

        <TABLE_WRAPPER>
        <TABLE_THEAD_WRAPPER
          DISPLAY={["None", "table-header-group"]}
          HEADERS={[" ", "Player", "Wickets",""]}
        />
          <TABLE_THEAD_WRAPPER
          DISPLAY={[ "table-header-group","None"]}
          HEADERS={[" ", "Player", "Team", "Wickets", "RUNS", "Overs",""]}
          />

         

          <tbody>
            {RENDER.DATAOBJ.SHOWCASE.map((Performance, ii) => {
              //console.log(Performance)
              return (
                <tr key={ii}>
                   <TABLE_TD>
                    <Image
                      src={Performance.IMAGE}
                      height={50}
                      width={50}
                      radius={100}
                    />
                   </TABLE_TD>
                   <TABLE_TD>{Performance.Name} </TABLE_TD>
                   <TABLE_TD HIDE={true}>{Performance.Team}  </TABLE_TD>
                   <TABLE_TD>{Performance.BOWLING_Wkts} </TABLE_TD>
                   <TABLE_TD HIDE={true}>{Performance.BOWLING_Runs} </TABLE_TD>
                   <TABLE_TD HIDE={true}>{Performance.BOWLING_Overs} </TABLE_TD>
                  <TABLE_TD>
                 {/*  <RemovePlayerBtn
                      RemoveItemFromResults={RemoveItemFromResults}
                      DATA={RENDER.DATAOBJ.SHOWCASE}
                      INT={ii}
                      RETIME={true}
                      Name={Performance.Name}
                    /> */}
                    </TABLE_TD>
                </tr>
              );
            })}
          </tbody>
        </TABLE_WRAPPER>
      </VideoWizardContainer>

      <VideoWizardContainer>
        <H3 colorID={5}>POTD Honerable Mentions</H3>

        <TABLE_WRAPPER>
        <TABLE_THEAD_WRAPPER
          DISPLAY={["None", "table-header-group"]}
          HEADERS={[" ", "Player", "Wickets",""]}
        />
          <TABLE_THEAD_WRAPPER
          DISPLAY={[ "table-header-group","None"]}
          HEADERS={[" ", "Player", "Team", "Wickets", "RUNS", "Overs",""]}
          />
          <tbody>
            {RENDER.DATAOBJ.HONERABLE.map((Performance, ii) => {
              return (
                <tr key={ii}>
                   <TABLE_TD>
                    <Image
                      src={Performance.IMAGE}
                      height={50}
                      width={50}
                      radius={100}
                    />
                   </TABLE_TD>
                   <TABLE_TD>{Performance.Name} </TABLE_TD>
                   <TABLE_TD HIDE={true}>{Performance.Team}  </TABLE_TD>
                   <TABLE_TD>{Performance.BOWLING_Wkts} </TABLE_TD>
                   <TABLE_TD HIDE={true}>{Performance.BOWLING_Runs} </TABLE_TD>
                   <TABLE_TD HIDE={true}>{Performance.BOWLING_Overs} </TABLE_TD>
                  
                  <TABLE_TD>
                    {/* <RemovePlayerBtn
                      RemoveItemFromResults={RemoveItemFromResults}
                      DATA={RENDER.DATAOBJ.HONERABLE}
                      INT={ii}
                      RETIME={false}
                      Name={Performance.Name}
                    /> */}
                  </TABLE_TD>
                </tr>
              );
            })}
          </tbody>
        </TABLE_WRAPPER>
      </VideoWizardContainer>
    </>
  );
};

const RemovePlayerBtn = ({
  Name,
  RemoveItemFromResults,
  DATA,
  INT,
  RETIME,
}) => {
  return (
    <Menu shadow="md" width={300}>
      <Menu.Target>
        <Button
          uppercase
          sx={(theme) => ({
            backgroundColor: theme.colors.brand[0],
            color: theme.colors.brand[2],
            "&:hover": {
              backgroundColor: theme.colors.brand[6],
              color: theme.colors.brand[0],
            },
          })}
        >
          X
        </Button>
      </Menu.Target>

      <Menu.Dropdown
        sx={(theme) => ({
          backgroundColor: theme.colors.brand[1],
        })}
      >
        <Menu.Label
          sx={(theme) => ({
            color: theme.colors.brand[3],
            fontWeight: 900,
          })}
        >
          Confirm Removal
        </Menu.Label> 

        <Menu.Item
          icon={<IconTrash size={14} />}
          onClick={() => {
            RemoveItemFromResults(DATA, INT, RETIME);
          }}
          sx={(theme) => ({
            color: theme.colors.brand[6],
            fontWeight: 900,
          })}
        >
          Remove {Name} from this Video
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
