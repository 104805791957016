import { useCurrentFrame, useVideoConfig, AbsoluteFill } from "remotion";

import styled, { useTheme } from "styled-components";

import { SpringToFrom } from "../../../Animation/RemotionSpring";

const OBJ = [
  {
    pos: 0,
    Height: Math.floor(Math.random() * (60 - 40) + 40),
    Timer: Math.floor(Math.random() * 15),
  },
  {
    pos: 10,
    Height: Math.floor(Math.random() * (60 - 40) + 40),
    Timer: Math.floor(Math.random() * 15),
  },
  {
    pos: 20,
    Height: Math.floor(Math.random() * (60 - 40) + 40),
    Timer: Math.floor(Math.random() * 15),
  },
  {
    pos: 30,
    Height: Math.floor(Math.random() * (60 - 40) + 40),
    Timer: Math.floor(Math.random() * 15),
  },
  {
    pos: 40,
    Height: Math.floor(Math.random() * (60 - 40) + 40),
    Timer: Math.floor(Math.random() * 15),
  },
  {
    pos: 50,
    Height: Math.floor(Math.random() * (60 - 40) + 40),
    Timer: Math.floor(Math.random() * 15),
  },
  {
    pos: 60,
    Height: Math.floor(Math.random() * (60 - 40) + 40),
    Timer: Math.floor(Math.random() * 15),
  },
  {
    pos: 70,
    Height: Math.floor(Math.random() * (60 - 40) + 40),
    Timer: Math.floor(Math.random() * 15),
  },
  {
    pos: 80,
    Height: Math.floor(Math.random() * (60 - 40) + 40),
    Timer: Math.floor(Math.random() * 15),
  },
  {
    pos: 90,
    Height: Math.floor(Math.random() * (60 - 40) + 40),
    Timer: Math.floor(Math.random() * 15),
  },
];

export const Pillars = () => {

  const theme = useTheme();

  return (
    <AbsoluteFill>
      {OBJ.map((item, i) => {
        return (
          <VertPillar
            style={{
              left: `${item.pos}%`,
              backgroundColor: theme.Base[1],
              height: `${SpringToFrom(0.2 * i, 0, 100, "fast")}%`,
              bottom: 0,
            }}
          />
        );
      })}

      {OBJ.map((item, i) => {
        return (
          <VertPillar
            style={{
              left: `${item.pos}%`,
              backgroundColor: theme.Base[0],
              height: `${SpringToFrom(item.Timer, 0, item.Height)}%`,
              borderRadius: "0 0 500px 500px ",
            }}
          />
        );
      })}
    </AbsoluteFill>
  );
};

const VertPillar = styled.div`
  width: 10%;
  height: 0%;
  border-radius: 0;

  position: absolute;
  background-color: red;
`;
