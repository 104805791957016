/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";

// HOOKS
//import {useUploadPlayerImage} from '../../../customHooks/user/useUpdatePlayerImage'
import {
  UploadPlayerGalleryImage,
  useAssignNewDefaultImage,
} from "../../../../Hooks/Utils/useUploadPlayerImage";

import { Grid, Image, Space } from "@mantine/core";
import { BLOCK_ToolBar } from "../../../../Components/interface/BLOCK_Toolbar";
import { VideoWizardContainer } from "../../../../Theme/Structure/BodyContainer";
import { P, S } from "../../../../Theme/type";
import {
  BTN_CREATE,
  BTN_ICON_CALLBACK,
} from "../../../../Theme/Structure/Button/BTNS";
import DropZone from "../../../../Components/Global/DropZone";

/* ******************************************************************** */
// CHANGE PLAYER LOGO FUNC
/* ******************************************************************** */
// CHange Player Image container
// Takes in an ARRAY of the players detials
// requires Player.id and Player.IMAGE
// useState hook to change state back in UI
export const UploadNewPlayerImageContainer = ({
  Player,
  SetNewImage,
  UpdateDATAOBJ,
}) => {
  //const [PlayerImage,UpdatePlayerImage] = useUploadPlayerImage()
  const [GalleryImage, UploadGalleryImage] = UploadPlayerGalleryImage();
  const [PlayerImage, AssignPlayerImage] = useAssignNewDefaultImage();
  const [ImageFile, setImageFile] = useState(false);

  console.log("Player", Player);

  // Exit out of Upload Editor
  const BACK = (id) => {
    SetNewImage(false);
  };

  // Handle 'Store as new' Click, Upload new image to Profile
  const HandlePlayerImageChange = () => {
    UploadGalleryImage(ImageFile.id, { id: Player.ID });
  };

  // One profile has been updated, set this nerw image as the Playes Default Image
  useEffect(() => {
    if (GalleryImage !== null) AssignPlayerImage(ImageFile.id, Player.ID);
  }, [GalleryImage]);

  // return the new URL to the OG function to update the reducer
  useEffect(() => {
    //console.log(PlayerImage);

    if (ImageFile !== false && ImageFile !== null) {
      //console.log(ImageFile.url, Player)
      //Player.IMAGE = ImageFile.url;
      //console.log(Player, PlayerImage)
      // resync data object
      if (PlayerImage !== null) {
        UpdateDATAOBJ(ImageFile);
      }
    }
  }, [PlayerImage]);

  //console.log("Player Player Player", Player);

  return (
    <>
      <Space h={20} />
      <BLOCK_ToolBar
        TOOLS={[
          <BTN_ICON_CALLBACK
            BG={0}
            COLOR={6}
            CALLBACK={() => {
              BACK();
            }}
            ICON="ArrowBackUp"
            TIPOBJ={{
              LABEL: `Back to List`,
              COLOR: "Gray",
              POSITION: "top",
            }}
          />,
        ]}
      />
      <Space h="xl" />
      <VideoWizardContainer>
        <Grid columns={12} justify="flex-start" align="flex-start">
          <Grid.Col span={12} lg={4} justify="center" align="center">
            <Image
              width={200}
              height={200}
              radius="md"
              src={Player.IMAGE}
              alt="Player or Team image Missing"
              withPlaceholder
            />
            <P align="center">Active Image: {Player.Name} </P>
          </Grid.Col>
          <Grid.Col span={12} lg={8}justify="center" align="center">
            {!ImageFile ? (
              <DropZone setImageFile={setImageFile} />
            ) : (
              <NewImage ImageFile={ImageFile} />
            )}
            <Space h="sm" />
            {!ImageFile ? (
              ""
            ) : (
              <BTN_CREATE
                CALLBACK={() => {
                  HandlePlayerImageChange();
                }}
                LABEL={`Assign Image to ${Player.Name}`}
              />
            )}
          </Grid.Col>
        </Grid>
      </VideoWizardContainer>
    </>
  );
};

const NewImage = ({ ImageFile }) => {
  return (
    <Image
      radius="md"
      width={200}
      height={200}
      src={ImageFile.url}
      alt="New Image"
      withPlaceholder
    />
  );
};
