import { Modal } from '@mantine/core';
import { Player } from 'video-react';
import { BTN_DOWNLOAD} from '../../Theme/Structure/Button/BTNS';

export const VideoModal=({opened, setOpened,videoURL})=>{
  //console.log(videoURL)
    if(!videoURL)
    return false
    return( 
    <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            withCloseButton={false}
            centered 
            size="lg"
            overlayColor={'#000'}
            overlayOpacity={0.8}
            overlayBlur={3}
            transition="fade"
            title={videoURL.attributes.Name}
          >
            <> 
            
            <Player>
            <source src={videoURL.attributes.DownloadURI} />
          </Player>
   
              <BTN_DOWNLOAD 
                TO={`${videoURL.attributes.DownloadURI}`} 
                LABEL={`DOWNLOAD`}
                TIPOBJ={{
                  LABEL:'Download',
                  COLOR:'orange',
                  POSITION:'top'
              }}
              />
            </>
      
    </Modal>)
  }

  export const PreviewVideoModal=({opened, setOpened,videoURL})=>{
  //console.log(videoURL)
    if(!videoURL)
    return false
    return( 
    <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            withCloseButton={false}
            centered 
            size="lg"
            overlayColor={'#4A5060'}
            overlayOpacity={0.9}
            overlayBlur={3}
            transition="fade"
            padding='0'
            shadow='xl'
           
          >
            <> 
            
            <Player>
              <source src={videoURL} />
            </Player>
            </>
     
    </Modal>)
  }