import { useCurrentFrame, useVideoConfig, spring, Series } from "remotion";
import { useTheme } from "styled-components";
// Components
import { LargeLabel } from "../../../Components/LeagueLabel";
import moment from "moment";

/* import {FixtureResultsUL} from "./FixtureResultsUL" */
import { FullWidthWrapper } from "../../../utils/Containers";
import styled from "styled-components";
import { H1, H2, H3, H4 } from "../../../utils/Text";
import { TrimPlayerName } from "../../../../../../actions/UX";
import { Image } from "@mantine/core";
import {
  CalendarEvent,
  Map2,
  Clock,
  Building,
  BuildingSkyscraper,
  World,
} from "tabler-icons-react";
import { interpolateOpacityByFrame } from "../../../../Animation/interpolate";

const SpringToFrom = (int, FROM, TO, effect = "Default") => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  return spring({
    fps: fps / 2,
    from: FROM,
    to: TO,
    frame: frame - int,
    config: {
      damping: 10,
      stiffness: 55,
      mass: 0.9,
    },
  });
};

const SeriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  flex-wrap: wrap;
  list-style: none;
`;

export const GAMEOFTHEWEEK_BODY = ({ DATA, VideoSettings, RENDER }) => {
  return (
    <>
      <FullWidthWrapper>
        <LargeLabel LABEL={"Game of the Week"} />

        <Series> 
          <Series.Sequence
            durationInFrames={VideoSettings.SectionDuration[2]}
            layout="none"
          >
            <TheTeams DATA={DATA} Duration={VideoSettings.SectionDuration[2]} />
          </Series.Sequence>

          <Series.Sequence
            durationInFrames={VideoSettings.SectionDuration[3]}
            layout="none"
          >
            <TheStats DATA={DATA} Duration={VideoSettings.SectionDuration[3]} />
          </Series.Sequence>
          <Series.Sequence
            durationInFrames={VideoSettings.SectionDuration[4]}
            layout="none"
          >
            <ThePlayers
              DATA={DATA}
              Duration={VideoSettings.SectionDuration[4]}
            />
          </Series.Sequence>
          <Series.Sequence
            durationInFrames={VideoSettings.SectionDuration[5]}
            layout="none"
          >
            <ThePrediction
              DATA={DATA}
              Duration={VideoSettings.SectionDuration[5]}
              RENDER={RENDER}
            />
          </Series.Sequence>
        </Series>
      </FullWidthWrapper>
    </>
  );
};

/** Containers */
const LogoContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-top: 200px;
`;
const TeamNameContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
  margin-top: 0px;
`;
const GameVSContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  align-content: flex-end;
  margin-top: -40px;
  text-align: center;
`;
const GameMetaContainer = styled.div`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  backdrop-filter: blur(1px);
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  align-content: flex-end;
  margin-top: 250px;
`;
const MetaItem = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  align-content: flex-end;
  margin-top: 0px;
`;

const TheTeams = ({ DATA, Duration }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();
  //const Theme_Teams = theme.GOTW.Teams
  const Theme_Teams = theme.Asset.GOTW;
  const D = DATA.DATAOBJ;

  const ConvertData = (FromDate) => {
    const d = new Date(`${FromDate}`);
    return moment(d).format("DD MMMM");
  };

  const ConvertTime = (FromDate) => {
    const TIME = FromDate.split(":");
    return `${TIME[0]}:${TIME[1]}`;
  };

  const IMGSIZE = 500;
  return (
    <SeriesContainer>
      <LogoContainer>
        <Image
          radius={IMGSIZE}
          width={IMGSIZE}
          height={IMGSIZE}
          src={D.TeamA.LOGO}
          style={{
            transform: `translateX(${SpringToFrom(
              7,
              -1000,
              25
            )}px)   translateX(${SpringToFrom(Duration - 10, 25, -1000)}px)`,
          }}
        />

        <Image
          src={D.TeamB.LOGO}
          radius={IMGSIZE}
          width={IMGSIZE}
          height={IMGSIZE}
          style={{
            transform: `translateX(${SpringToFrom(
              7,
              1000,
              -25
            )}px) translateX(${SpringToFrom(Duration - 10, -25, 1000)}px)`,
          }}
        />
      </LogoContainer>
      <GameVSContainer>
        <H2
          style={{
            width: "100%",
            fontWeight: 900,
            opacity: interpolateOpacityByFrame(frame, 0, 45, 0, 1),
            transform: `translateY(${SpringToFrom(0, 100, 10)}px)`,
            color: Theme_Teams.Fill[0],
          }}
        >
          VS
        </H2>
      </GameVSContainer>

      <TeamNameContainer
        style={{
          opacity: interpolateOpacityByFrame(
            frame,
            Duration - 10,
            Duration,
            1,
            0
          ),
        }}
      >
        <H1
          style={{
            opacity: interpolateOpacityByFrame(frame, 15, 45, 0, 1),
            transform: `translateX(${SpringToFrom(7, -10, 50)}px)`,
            color: Theme_Teams.TeamA[0],
            width: "50%",
            margin: "0 5%",
          }}
        >
          {D.TeamA.Name}
        </H1>
        <H1
          style={{
            opacity: interpolateOpacityByFrame(frame, 15, 45, 0, 1),
            transform: `translateX(${SpringToFrom(7, 10, -50)}px)`,
            color: Theme_Teams.TeamB[0],
            width: "50%",
            margin: "0 5%",
          }}
        >
          {D.TeamB.Name}
        </H1>
      </TeamNameContainer>

      <GameMetaContainer
        style={{
          width: `${SpringToFrom(25, 0, 100)}%`,
          background: theme.radialGradient,
          opacity: interpolateOpacityByFrame(
            frame,
            Duration - 10,
            Duration,
            1,
            0
          ),
        }}
      >
        <MetaItem>
          <CalendarEvent
            style={{
              opacity: interpolateOpacityByFrame(frame, 30, 45, 0, 1),
              transform: `translateY(${SpringToFrom(30, 100, 10)}px)`,
              marginBottom: "2em",
              stroke: Theme_Teams.Fill[1],
              strokeWidth: "1px",
            }}
            size={120}
          />
          <H3
            style={{
              opacity: interpolateOpacityByFrame(frame, 30, 45, 0, 1),
              transform: `translateX(${SpringToFrom(30, -100, 0)}px)`,
              fontSize: "2em",
              lineHeight: "1.1em",
              fontWeight: 500,
              color: Theme_Teams.Fill[1],
            }}
          >
            {ConvertData(D.Date)}
          </H3>
        </MetaItem>
        <MetaItem>
          <Clock
            style={{
              opacity: interpolateOpacityByFrame(frame, 35, 60, 0, 1),
              transform: `translateY(${SpringToFrom(35, 100, 10)}px)`,
              marginBottom: "2em",
              stroke: Theme_Teams.Fill[1],
              strokeWidth: "1px",
            }}
            size={120}
          />
          <H3
            style={{
              opacity: interpolateOpacityByFrame(frame, 35, 60, 0, 1),
              transform: `translateX(${SpringToFrom(35, -100, 0)}px)`,
              fontSize: "2em",
              lineHeight: "1.1em",

              fontWeight: 500,
              color: Theme_Teams.Fill[1],
            }}
          >
            {ConvertTime(D.Time)}
          </H3>
        </MetaItem>
        <MetaItem>
          <Map2
            style={{
              opacity: interpolateOpacityByFrame(frame, 40, 75, 0, 1),
              transform: `translateY(${SpringToFrom(40, 100, 10)}px)`,
              marginBottom: "2em",
              stroke: Theme_Teams.Fill[1],
              strokeWidth: "1px",
            }}
            size={120}
          />
          <H3
            style={{
              opacity: interpolateOpacityByFrame(frame, 40, 75, 0, 1),
              transform: `translateX(${SpringToFrom(40, -100, 0)}px)`,
              fontSize: "2em",
              lineHeight: "1.1em",

              fontWeight: 500,
              color: Theme_Teams.Fill[1],
            }}
          >
            {D.ground}
          </H3>
        </MetaItem>
      </GameMetaContainer>
    </SeriesContainer>
  );
};

const StatsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-top: 100px;
`;
const StatsContainerInner = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  backdrop-filter: blur(1px);
  width: 48%;
  margin: 1%;
  height: 98%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  margin-top: 20px;
  border-radius: 20px;
`;
const RankContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  align-content: center;
  width: 100%;
  margin: 30px 0;
`;

const FormOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  align-content: center;
  border-radius: 20px;
  width: 96%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  backdrop-filter: blur(1px);
  padding: 1%;
  margin: 70px 0 0 0;
`;

const FormPositionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  border-radius: 20px;
  width: 100%;
  height: 100px;
  background-color: lightblue;
`;

const WinRatioOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  align-content: center;
  border-radius: 20px;
  margin: 0 2%;
  width: 96%;
  height: 300px;

  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  backdrop-filter: blur(1px);
  padding: 1%;
  margin: 30px 0 0 0;
`;
const WinRatioInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  align-content: center;
  width: 100%;
  border-radius: 20px;
  height: 300px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  backdrop-filter: blur(1px);
  padding: 1%;
`;
const WinRatioPositionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  border-radius: 20px;
  width: 100%;
  background-color: lightblue;
`;

const WinLostRatioIntContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 10px;
`;

const TheStats = ({ DATA, Duration }) => {
  //console.log(DATA.DATAOBJ)
  const frame = useCurrentFrame();
  const D = DATA.DATAOBJ;
  const theme = useTheme();
  return (
    <SeriesContainer>
      <StatsContainer>
        <StatsContainerInner
          style={{
            background: theme.radialGradient,
            opacity: interpolateOpacityByFrame(
              frame,
              Duration - 15,
              Duration,
              1,
              0
            ),
          }}
        >
          <StatsColumn D={D.TeamA} Duration={Duration} />
        </StatsContainerInner>
        <StatsContainerInner
          style={{
            background: theme.radialGradient,
            opacity: interpolateOpacityByFrame(
              frame,
              Duration - 10,
              Duration,
              1,
              0
            ),
          }}
        >
          <StatsColumn D={D.TeamB} Duration={Duration} />
        </StatsContainerInner>
      </StatsContainer>
    </SeriesContainer>
  );
};

const ThePlayers = ({ DATA, Duration }) => {
  //console.log(DATA.DATAOBJ)
  const D = DATA.DATAOBJ;
  const frame = useCurrentFrame();
  const theme = useTheme();
  return (
    <SeriesContainer>
      <H1
        style={{
          width: "100%",
          color: theme.Copy[2],
          opacity: interpolateOpacityByFrame(frame, 0, 15, 0, 1),
        }}
      >
        Ones to Watch
      </H1>
      <PlayersToWatch
        ARR={[D.TeamA.batsmanToWatch, D.TeamB.batsmanToWatch]}
        Duration={Duration}
        PATH="Runs"
        Label="RUNS"
        ITEMS={["Name", "RUNS", "BALLS", "SR"]}
        CATEGORY={["Runs", "SR"]}
        INT={0}
      />
      <PlayersToWatch
        ARR={[D.TeamA.batsmanToWatch, D.TeamB.batsmanToWatch]}
        Duration={Duration}
        PATH="SR"
        Label="Strike RAte"
        ITEMS={["Name", "RUNS", "BALLS", "SR"]}
        CATEGORY={["Runs", "SR"]}
        INT={1}
      />
      <PlayersToWatch
        ARR={[D.TeamA.bowlersToWatch, D.TeamB.bowlersToWatch]}
        Duration={Duration}
        PATH="WKTS"
        Label="Wickets"
        ITEMS={["Name", "WKTS", "OVERS", "ECO"]}
        CATEGORY={["WKT", "Eco"]}
        INT={2}
      />
      <PlayersToWatch
        ARR={[D.TeamA.bowlersToWatch, D.TeamB.bowlersToWatch]}
        Duration={Duration}
        PATH="ECO"
        Label="Economy"
        ITEMS={["Name", "WKTS", "OVERS", "ECO"]}
        CATEGORY={["WKT", "Eco"]}
        INT={3}
      />
    </SeriesContainer>
  );
};

const PredictionContainer = styled.div`
  position: relative;
  width: 92%;
  margin: 20% 4% 0;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  backdrop-filter: blur(1px);
  border-radius: 10px;
`;
const WinnersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ThePrediction = ({ DATA, RENDER }) => {
  const frame = useCurrentFrame();
  const WINNER =
    DATA.DATAOBJ.TeamA.Name === RENDER.INPUTDATA.SelectWinner
      ? DATA.DATAOBJ.TeamA
      : DATA.DATAOBJ.TeamB;
  const theme = useTheme();

  return (
    <SeriesContainer>
      <PredictionContainer
        style={{
          background: theme.radialGradient,
          height: `${SpringToFrom(0, 0, 80)}%`,
          maxHeight: `${SpringToFrom(165, 80, 0)}%`,
          opacity: interpolateOpacityByFrame(frame, 170, 178, 1, 0),
        }}
      >
        <H2
          style={{
            fontSize: "5em",
            opacity: interpolateOpacityByFrame(frame, 15, 30, 0, 1),
            transform: `scale(${SpringToFrom(160, 1, 0)})`,
          }}
        >
          Our Prediction
        </H2>

        <WinnersContainer>
          <Image
            src={WINNER.LOGO}
            radius={300}
            width={300}
            height={300}
            style={{
              textAlign: "center",
              transform: `scale(${SpringToFrom(30, 0, 1)}) scale(${SpringToFrom(
                160,
                1,
                0
              )})`,
              opacity: interpolateOpacityByFrame(frame, 168, 170, 1, 0),
            }}
          />
          <H1
            style={{
              fontSize: "5em",
              marginTop: ".4em",
              transform: `scale(${SpringToFrom(32, 0, 1)}) scale(${SpringToFrom(
                160,
                1,
                0
              )})`,
              opacity: interpolateOpacityByFrame(frame, 168, 170, 1, 0),
              color: theme.Copy[0],
            }}
          >
            {WINNER.Name}
          </H1>
        </WinnersContainer>

        <H2
          style={{
            transform: `scale(${SpringToFrom(45, 0, 1)}) scale(${SpringToFrom(
              160,
              1,
              0
            )})`,
            opacity: interpolateOpacityByFrame(frame, 168, 170, 1, 0),
            color: theme.Copy[0],
            fontSize: "4em",
          }}
        >
          GOOD LUCK TO BOTH TEAMS!
        </H2>
        <H3
          style={{
            transform: `scale(${SpringToFrom(65, 0, 1)}) scale(${SpringToFrom(
              160,
              1,
              0
            )})`,
            opacity: interpolateOpacityByFrame(frame, 168, 170, 1, 0),
            color: theme.Copy[2],
            fontSize: "2em",
            letterSpacing: "0px",
          }}
        >
          {RENDER.INPUTDATA.SelectIsLiveStreamed
            ? "this game will be streamed live on LMS tv"
            : false}{" "}
        </H3>
      </PredictionContainer>
    </SeriesContainer>
  );
};

/** INNER COMPOENENTS */
const CategoryDiv = styled.div`
  width: 92%;
  margin: 10px 4%;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;

  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  backdrop-filter: blur(1px);
  border-radius: 10px;
  max-height: 270px;
`;

const CategoryPosition = styled.div`
  position: absolute;
  left: -110px;
  top: 107px;
  transform: rotate(-90deg);
  height: 53px;
  width: 270px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 43%;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0.5em;
`;

const PlayersToWatch = ({
  ARR,
  PATH,
  ITEMS,
  CATEGORY,
  INT,
  Label,
  Duration,
}) => {
  const frame = useCurrentFrame();
  //const { fps } = useVideoConfig();
  const theme = useTheme();

  //console.log(ARR[0])
  if (ARR[0][PATH].length === 0) return false;
  return (
    <CategoryDiv
      style={{
        background: theme.radialGradient,
        width: `${SpringToFrom(3 * INT, 0, 92)}%`,
        borderColor: `rgba(255, 255, 255, ${interpolateOpacityByFrame(
          frame,
          15,
          30,
          0,
          1
        )})`,
        opacity: interpolateOpacityByFrame(
          frame,
          Duration - 25 + INT * 3,
          Duration,
          1,
          0
        ),
      }}
    >
      <CategoryPosition
        style={{
          opacity: interpolateOpacityByFrame(frame, 15, 45, 0, 1),
          backgroundColor: theme.Asset.GOTW.Fill[2],
        }}
      >
        <H3
          style={{
            color: theme.Copy[1],
            letterSpacing: "1px",
            width: "100%",
          }}
        >
          {Label}
        </H3>
      </CategoryPosition>
      {ARR.map((p, i) => {
        return (
          <PlayerContainer key={i}>
            <H1
              style={{
                fontSize: "2.4em",
                lineHeight: "1em",
                //opacity:interpolateOpacityByFrame(frame, 45,60, 0, 1),
                transform: `scale(${SpringToFrom(20 + 5 * i, 0, 1)})`,
                color: theme.Copy[0],
              }}
            >
              {TrimPlayerName(p[PATH][0][ITEMS[0]])}
            </H1>
            <Image
              radius={10}
              width={140}
              height={140}
              src={p[PATH][0]?.IMAGE}
              style={{
                transform: `scale(${SpringToFrom(15 + 5 * i, 0, 1)})`,
              }}
            />

            <H2
              style={{
                fontSize: "2.4em",
                lineHeight: "1.2em",
                opacity: interpolateOpacityByFrame(frame, 30, 45, 0, 1),
                transform: `translateY(${SpringToFrom(30, 10, 0)}px)`,
                color: theme.Copy[0],
                letterSpacing: "1px",
              }}
            >
              {CATEGORY[0]} - {p[PATH][0][ITEMS[1]]}{" "}
            </H2>
            <H3
              style={{
                fontSize: "2em",
                lineHeight: "1.2em",
                opacity: interpolateOpacityByFrame(frame, 35, 60, 0, 1),
                transform: `translateY(${SpringToFrom(35, 10, 0)}px)`,
                color: theme.Copy[0],
                letterSpacing: "1px",
              }}
            >
              {CATEGORY[1]} - {p[PATH][0][ITEMS[3]].toFixed(2)}
            </H3>
          </PlayerContainer>
        );
      })}
    </CategoryDiv>
  );
};

const StatsColumn = ({ D, Duration }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();
  //const Theme_Stats = theme.GOTW.Stats
  const Theme_Stats = theme.Asset.GOTW;
  return (
    <>
      <Image
        src={D.LOGO}
        radius={100}
        width={150}
        height={150}
        style={{
          marginTop: "-75px",
          marginBottom: "20px",
        }}
      />

      <H1
        style={{
          fontSize: "3em",
          lineHeight: "1em",
          minHeight: "100px",
          display: "flex",
          justifyContent: "center",
          color: theme.Copy[0],
        }}
      >
        {D.Name}
      </H1>

      <RankContainer>
        <div>
          <Building
            size={100}
            style={{
              opacity: interpolateOpacityByFrame(frame, 0, 30, 0, 1),
              transform: `translateY(${SpringToFrom(0, 100, 10)}px)`,
              stroke: theme.Copy[0],
              strokeWidth: "1px",
            }}
          />
          <H1
            style={{
              opacity: interpolateOpacityByFrame(frame, 0, 15, 0, 1),
              transform: `translateX(${SpringToFrom(0, -100, 0)}px)`,
              color: theme.Copy[0],
            }}
          >
            {D.City_Rank}
          </H1>
          <H4
            style={{
              opacity: interpolateOpacityByFrame(frame, 0, 15, 0, 1),
              transform: `translateX(${SpringToFrom(0, -100, 0)}px)`,
              color: theme.Copy[0],
              letterSpacing: "0px",
            }}
          >
            City
          </H4>
        </div>
        <div>
          <BuildingSkyscraper
            size={100}
            style={{
              opacity: interpolateOpacityByFrame(frame, 7, 37, 0, 1),
              transform: `translateY(${SpringToFrom(7, 100, 10)}px)`,
              stroke: theme.Copy[0],
              strokeWidth: "1px",
            }}
          />
          <H1
            style={{
              opacity: interpolateOpacityByFrame(frame, 7, 37, 0, 1),
              transform: `translateX(${SpringToFrom(7, -100, 0)}px)`,
              color: theme.Copy[0],
            }}
          >
            {D.National_Rank}
          </H1>
          <H4
            style={{
              opacity: interpolateOpacityByFrame(frame, 7, 37, 0, 1),
              transform: `translateX(${SpringToFrom(7, -100, 0)}px)`,
              color: theme.Copy[0],
              letterSpacing: "0px",
            }}
          >
            National
          </H4>
        </div>
        <div>
          <World
            size={100}
            style={{
              opacity: interpolateOpacityByFrame(frame, 15, 45, 0, 1),
              transform: `translateY(${SpringToFrom(15, 100, 10)}px)`,
              stroke: theme.Copy[0],
              strokeWidth: "1px",
            }}
          />
          <H1
            style={{
              opacity: interpolateOpacityByFrame(frame, 15, 45, 0, 1),
              transform: `translateX(${SpringToFrom(15, -100, 0)}px)`,
              color: theme.Copy[0],
            }}
          >
            {D.World_Rank}
          </H1>
          <H4
            style={{
              opacity: interpolateOpacityByFrame(frame, 15, 45, 0, 1),
              transform: `translateX(${SpringToFrom(15, -100, 0)}px)`,
              color: theme.Copy[0],
              letterSpacing: "0px",
            }}
          >
            World
          </H4>
        </div>
      </RankContainer>

      <WinRatioOuterContainer
        style={{
          opacity: interpolateOpacityByFrame(frame, 30, 45, 0, 1),
          height: `${SpringToFrom(30, 0, 300)}px`,
        }}
      >
        <H2
          style={{
            padding: "2%",
            color: theme.Copy[0],
          }}
        >
          Win Ratio
        </H2>
        <WinRatioInnerContainer>
          <WinRatioPositionContainer
            style={{
              opacity: interpolateOpacityByFrame(frame, 45, 60, 0, 1),
              height: `${SpringToFrom(45, 0, parseInt(D.Win_Ratio))}%`,
              backgroundColor: Theme_Stats.Fill[2],
            }}
          >
            <H3
              style={{
                opacity: interpolateOpacityByFrame(frame, 30, 45, 0, 1),
                fontSize: "3em",
                color: theme.Copy[1],
              }}
            >
              {D.Win_Ratio}
            </H3>
          </WinRatioPositionContainer>
        </WinRatioInnerContainer>
      </WinRatioOuterContainer>

      <WinLostRatioIntContainer
        style={{
          opacity: interpolateOpacityByFrame(frame, 30, 45, 0, 1),
        }}
      >
        <H3 style={{ color: theme.Copy[0] }}>Won : {D.Won}</H3>
        <H3 style={{ color: theme.Copy[0] }}>Lost : {D.Lost}</H3>
      </WinLostRatioIntContainer>

      <FormOuterContainer
        style={{
          background: theme.radialGradient,
          opacity: interpolateOpacityByFrame(frame, 60, 75, 0, 1),
          height: `${SpringToFrom(60, 0, 150)}px`,
        }}
      >
        <H2
          style={{
            color: theme.Copy[0],
            padding: "2%",
          }}
        >
          Current Form
        </H2>
        <FormPositionContainer
          style={{
            backgroundColor: Theme_Stats.Fill[3],
          }}
        >
          <H1
            style={{
              transform: `translateX(${SpringToFrom(70, 50, 0)}px)`,
              color: theme.Copy[1],
            }}
          >
            {D.Form.split(" ").map((INT, i) => {
              //console.log(D.Form.split(' ').length,INT,i++)
              return (
                <span
                  key={i}
                  style={{
                    margin: "0 5px",
                    opacity: interpolateOpacityByFrame(
                      frame,
                      65 + 5 * (i + 1),
                      65 + 7 * (i + 1),
                      0,
                      1
                    ),
                    transform: `translateY(${SpringToFrom(
                      65 + 7 * i,
                      -1000,
                      0
                    )}px)`,
                  }}
                >
                  {INT}
                </span>
              );
            })}
          </H1>
        </FormPositionContainer>
      </FormOuterContainer>
    </>
  );
};
