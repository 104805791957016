
import { useState } from "react" 
import { useSelector } from 'react-redux'
import {ENV} from '../../Config/RunTimeConfig'
import { parseCookies } from 'nookies'
import {useGetFranchises} from "./useFranchises"
const qs = require('qs');


// FUNC : CREATE
// usage // this function is used exclusivly for Creating a new tournament
export const useCreateWatchList=(ctx)=>{
    const [WatchList, setWatchList] = useState(false)
    const jwt = parseCookies(ctx).jwt
    const CreateWatchList = async(_DATA)=>{
  
    //console.log(_DATA)
      
      const res = await fetch(`${ENV.API_URL}/api/watch-lists`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({
                data: _DATA
            })
        })
        const JsonWatchList = await res.json();
        //console.log(JsonWatchList)
        setWatchList(JsonWatchList.data)
    }  
    return[WatchList,CreateWatchList] 
  } 




// FUNC : UPDATE 
// FUNC : DELETE 


// FUNC : GET 
export const useGetLeagues = (ctx)=>{

    const [getLeagues,setgetLeagues] = useState(null)
    const UXSTATE = useSelector((state) => state.UXSTATE);
    const jwt = parseCookies(ctx).jwt
     
    const FetchgetLeagues = async ()=>{

   
        if(UXSTATE?.SelectedFranchise?.id === undefined)
        return false

        const query = qs.stringify({
            filters: {
                $or: [
                    { 
                        hasCompleted:{ $ne: true,},
                    },
                    {
                        hasCompleted:{ $null: true,},
                    },
                  ],
                franchise: { id:{ $eq: UXSTATE.SelectedFranchise.id,}}, 
              },
              fields: ['Name','hasCompleted','leagueid','seasonid','STATS'],
              populate:['fixtures']
          }, {
            encodeValuesOnly: true,
          });

        const res = await fetch(`${ENV.API_URL}/api/watch-lists?${query}`, {
            headers: {
                Authorization: `Bearer ${jwt}`
            }
        })
        const loginResponse = await res.json(); 

        setgetLeagues(loginResponse.data)
    }
    return[getLeagues,FetchgetLeagues] 
}


// GET
// Group of Wishlist items based on there ID
export const useGetGroupOfLeagues = (ctx)=>{

    const [getLeagues,setgetLeagues] = useState(false)
    //const UXSTATE = useSelector((state) => state.UXSTATE);
    const jwt = parseCookies(ctx).jwt
     
    const FetchgetLeagues = async (_IDS)=>{
      //console.log(_IDS)
        const query = qs.stringify({
            filters: {
                $or: [
                    { 
                        hasCompleted:{ $ne: true,},
                    },
                    {
                        hasCompleted:{ $null: true,},
                    },
                  ],
                  id:{ $in: _IDS,}, 
              },
              fields: ['Name','hasCompleted','leagueid','seasonid','STATS'],
             
          }, {
            encodeValuesOnly: true,
          });

        const res = await fetch(`${ENV.API_URL}/api/watch-lists?${query}`, {
            headers: {
                Authorization: `Bearer ${jwt}`
            }
        })
        const loginResponse = await res.json(); 

        setgetLeagues(loginResponse.data)
    }
    return[getLeagues,FetchgetLeagues] 
}

// FUNC : GET SINGLE
export const useGetLeague = (ctx)=>{

    const [getLeague,setgetLeague] = useState(null)
    //const UXSTATE = useSelector((state) => state.UXSTATE);
    const jwt = parseCookies(ctx).jwt
     
    const FetchgetLeague = async (_ID)=>{
        const query = qs.stringify({
            filters: {
                $or: [
                    {
                        hasCompleted:{ $ne: true,},
                    },
                    {
                        hasCompleted:{ $null: true,},
                    },
                  ],
               
              },populate: '*',  
          }, {
            encodeValuesOnly: true,
          });

        const res = await fetch(`${ENV.API_URL}/api/watch-lists/${_ID}?${query}`, {
            headers: {
                Authorization: `Bearer ${jwt}`
            }
        })
        const loginResponse = await res.json(); 

      //console.log(loginResponse)
        setgetLeague(loginResponse.data)
    }
    return[getLeague,FetchgetLeague] 
}



export const useChangeLeagueStatus=(ctx)=>{

    const [LeagueStatus, setLeagueStatus] = useState(false)
    const [Franchises,FetchFranchises] =useGetFranchises()
    //const dispatch = useDispatch()
    const jwt = parseCookies(ctx).jwt

    const ChangeLeagueStatus = async(_DATA,_ID)=>{
        //console.log(_DATA)
        setLeagueStatus(false)
        const res = await fetch(`${ENV.API_URL}/api/watch-lists/${_ID}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({
                data: _DATA
            })
        })
        const GetLeagueStatus = await res.json();
        //console.log(GetLeagueStatus)
        setLeagueStatus(true)
        FetchFranchises()
    }  

    return[LeagueStatus,ChangeLeagueStatus]
}