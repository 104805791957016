import {gql,} from "@apollo/client";
export const QUERY = (APISETTINGS)=>{
  return gql`query TOP_5 { watchLists(
    pagination: { start: 0, limit: -1 }
    filters:{id:{in:  [${APISETTINGS.IDS}]}}
      ){
        data{ 
        id
          attributes{
            STATS
          }
        }
    }
    }` 
}

/* export const Query = (APISETTINGS)=>{
    return gql`query TOP_4 {
      playerBattings(
        pagination: { start: 0, limit: -1 }
        filters:{BATTING_fours:{  gte:1},fixture:{watch_list:{ id:{in: [${APISETTINGS.IDS}]}}}}
      ){
        data{ 
         id
          attributes{
            BATTING_fours
             team {
                  data {
                    id
                    attributes {
                      Name
                      TeamID
                      Logo {
                        data {
  
                          attributes {
                            url
                          }
                        }
                      }
                    }
                }
            }
            
            player{
              data{
                id
                attributes{
                  Name
                   image {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                }
              }
            }
          }
        }
    }
        }`
} */