import { Container, Loader, Space } from "@mantine/core";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { BLOCK_Section_SubHeading } from "../../../../Components/interface/BLOCK_Section_SubHeading";
import { BLOCK_ToolBar } from "../../../../Components/interface/BLOCK_Toolbar";
import {
  TABLE_TD,
  TABLE_THEAD_WRAPPER,
  TABLE_WRAPPER,
} from "../../../../Components/interface/Wrapper/Table";
import { useAIReports } from "../../../../Hooks/Content/useAICreate";
import {
  BorderedContainer,
  VideoWizardContainer,
} from "../../../../Theme/Structure/BodyContainer";
import { BTN_CREATE } from "../../../../Theme/Structure/Button/BTNS";
import { H3, P } from "../../../../Theme/type";

// RAW DATA
export const LEAGUESTANDINGS = ({ Order }) => {
  console.log(Order);
  const LoopOBJ = (OBJ) => {
    console.log(OBJ);
    const LIST = OBJ.map((Player, i) => {
      return (
        <tr key={i}>
          <TABLE_TD>{Player.homeTeam}</TABLE_TD>
          <TABLE_TD>{Player.homeTeamResult}</TABLE_TD>
          <TABLE_TD>vs</TABLE_TD>
          <TABLE_TD>{Player.awayTeam}</TABLE_TD>
          <TABLE_TD>{Player.awayTeamResult}</TABLE_TD>
          <TABLE_TD>{Player.resultStatement}</TABLE_TD>
        </tr>
      );
    });
    return LIST;
  };

  return (
    <>
      {Object.keys(Order.attributes.DATA.Fixtures).map((key, i) => {
        return (
          <>
            <H3>{key}</H3>
            <TABLE_WRAPPER>
              <tbody>{LoopOBJ(Order.attributes.DATA.Fixtures[key])}</tbody>
            </TABLE_WRAPPER>
          </>
        );
      })}
    </>
  );
};
//<TABLE_WRAPPER>{LoopOBJ(Order.attributes.DATA.Fixtures)}</TABLE_WRAPPER>

// AI WRITEUP
export const LEAGUESTANDINGSWRITEUP = ({ Order }) => {
  console.log(Order);
  const [AICreate, FetchAICreate] = useAIReports();
  const [loading, setLoading] = useState(false);

  //useAIMatchResults

  const CreateWriteup = () => {
    console.log("CreateWriteup", Order);
    FetchAICreate(Order.id, `/api/ai-writeup/LeagueStandings`);
    setLoading(true);
  };

  useEffect(() => {
    console.log(AICreate);
    if (AICreate?.response !== undefined) {
      setLoading(false);
    }
  }, [AICreate]);

  if (loading)
    return (
      <Container size={"xs"} py={20}>
        <Loader color="teal" variant="bars" />
      </Container>
    );
  return (
    <>
      <Container size={"lg"} py={0}>
        {AICreate?.response !== undefined ? (
          false
        ) : (
          <BLOCK_ToolBar
            TOOLS={[
              <BTN_CREATE
                LABEL={`Create AI Write up`}
                CALLBACK={CreateWriteup}
              />,
            ]}
          />
        )}
      </Container>

      {AICreate !== null ? <WriteUp Response={AICreate.response} /> : false}
    </>
  );
};

const WriteUp = ({ Response }) => {
  return (
    <Container size={"lg"} py={0}>
      <VideoWizardContainer>
        <ReactMarkdown>{Response}</ReactMarkdown>
      </VideoWizardContainer>
      <BorderedContainer>
        <P colorID={4} size={"sm"} lineHeight={"1.4em"}>
          Please note that while this AI will generate a match report based on
          its knowledge of T20 Cricket, the report may not accurately reflect
          the events of the LMS match. The scorecard provided will be used, but
          the report may not accurately reflect retired or returning batsmen.
          Please review the report before posting to ensure its accuracy.
        </P>
      </BorderedContainer>
    </Container>
  );
};
