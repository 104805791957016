import {
  Container,
  SimpleGrid,
  createStyles,
  Group,
  Card,
  Space,
  Divider,
} from "@mantine/core";
import { RecentlyCreatedAsset } from "./RecentlyCreatedAsset";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAuthNavigation } from "../../../Hooks/Auth/useAuthNavigation";
import { filter } from "lodash";
import { H3, P } from "../../../Theme/type";
import { BTN_ICON_CALLBACK } from "../../../Theme/Structure/Button/BTNS";
import { ICONDB } from "../../../Theme/icons";
import { useSelectVideoTheme } from "../../../Hooks/Content/useVideoTheme";
import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
export const IwouldLikeTo = () => {
  const { classes, theme } = useStyles();
  let navigate = useNavigate();
  const UXSTATE = useSelector((state) => state.UXSTATE);
  const [VideoTheme, FetchVideoTheme] = useSelectVideoTheme();

  const [Navigation, FetchNavigation] = useAuthNavigation();
  const [child, setchild] = useState([]);
  const [selected, setSelected] = useState(false);
  const [selectedAssets, setselectedAssets] = useState(false);

  useEffect(() => {
    FetchNavigation();
    FetchVideoTheme();
  }, []);
  useEffect(() => {
    setchild(
      filter(Navigation, function (o) {
        return o.attributes.Section === "Videos";
      })
    );
  }, [Navigation]);

  const handleClick = (NAV) => {
    setselectedAssets(NAV.attributes.assets.data);
    setSelected(NAV.attributes);
  };

  useEffect(() => {
    console.log(child);
  }, [child]);

  const links = child.map((link) => (
    <Card
      key={link.attributes.Name}
      //shadow="md"
      radius="md"
      sx={(theme) => ({
        position: "relative",
        backgroundColor: theme.colors.brand[0],
        border: `1px solid ${theme.colors.brand[1]}`,

        padding: theme.spacing.sm,
        borderRadius: theme.radius.sm,
        cursor: "pointer",

        "&:hover": {
          boxShadow: `${theme.shadows.md} !important`,
          transform: "scale(1.03)",
          backgroundColor: theme.colors.brand[1],
        },
      })}
      onClick={() => {
        handleClick(link);
      }}
      p="md"
    >
      <P
        transform="uppercase"
        margin={0}
        weight={400}
        colorID={3}
        align="right"
      >
        {ICONDB[link.attributes.Icon].Component}
      </P>
      <P transform="uppercase" weight={400} margin={0} size={18} colorID={3}>
        {link.attributes.Name}
      </P>
      <P size={"sm"} colorID={5} margin={0} weight={900}>
        {link.attributes.assets.data.length} video options
      </P>
    </Card>
  ));

  const CreateSelectedNav = () => {
    const Videos = selectedAssets.map((link) => (
      <Card
        key={link.attributes.Name}
        shadow="md"
        radius="md"
        sx={(theme) => ({
          position: "relative",
          backgroundColor: theme.colors.brand[0],
          padding: theme.spacing.sm,
          borderRadius: theme.radius.sm,
          cursor: "pointer",
          borderLeft: `5px solid ${theme.colors.brand[5]}`,

          "&:hover": {
            boxShadow: `${theme.shadows.md} !important`,
            transform: "scale(1.03)",
            backgroundColor: theme.colors.brand[1],
          },
        })}
        onClick={() => {
          navigate(`/${selected.URI}/${link.attributes.URI}`, {
            replace: true,
          });
          console.log();
        }}
        p="md"
      >
        <P
          colorID={5}
          transform="uppercase"
          margin={0}
          weight={400}
          align="right"
        >
          {ICONDB[selected.Icon].Component}
        </P>
        <P colorID={3} transform="uppercase" margin={0} weight={400} size={18}>
          {link.attributes.Name}
        </P>
        <P size={"sm"} colorID={5} margin={0} weight={900}>
          {" "}
          {link.attributes.orders.data.length} videos created
        </P>
      </Card>
    ));
    return Videos;
  };

  return (
    <>
      <Container size={"xl"}>
        <SimpleGrid cols={1} breakpoints={[{ minWidth: "md", cols: 2 }]}>
          <div>
            <BLOCK_Section_SubHeading LABEL="Recently Created" Color={5} />
            <RecentlyCreatedAsset />
          </div>

          <div>
            <BLOCK_Section_SubHeading LABEL="Quick Start" Color={5} />
            <H3 align="right">
              {" "}
              {selected
                ? `${selectedAssets.length} Video options for  ${selected.Name}`
                : ""}
            </H3>
            <VideoWizardContainer>
              <SimpleGrid
                cols={3}
                spacing="sm"
                mt={7}
                breakpoints={[
                  { maxWidth: "lg", cols: 2 },
                  { maxWidth: "md", cols: 1 },
                ]}
              >
                {selected ? CreateSelectedNav() : links}
              </SimpleGrid>

              <Group position="right" mt={10}>
                {selected ? (
                  <BTN_ICON_CALLBACK
                    CALLBACK={() => {
                      setSelected(false);
                    }}
                    ICON={"ArrowBackUp"}
                    TIPOBJ={{
                      LABEL: "Back to List",
                      COLOR: "Green",
                      POSITION: "top",
                    }}
                  />
                ) : (
                  ""
                )}
              </Group>
            </VideoWizardContainer>
          </div>
        </SimpleGrid>
      </Container>
      <Space h={15} />
      <Divider my="lg" color="white" />
      <Space h={15} />
    </>
  );
};

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    [theme.fn.smallerThan("sm")]: {
      fontSize: 24,
    },
  },

  description: {
    maxWidth: 600,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },
}));
