import { useCurrentFrame } from "remotion";
import { Group, Image, Table, Box, SimpleGrid } from "@mantine/core";
import { useTheme } from "styled-components";
import {
  CalendarEvent,
  Map2,
  Clock,
  Building,
  BuildingSkyscraper,
  World,
} from "tabler-icons-react";
import styled from "styled-components";

import moment from "moment";
// Animation
import { interpolateOpacityByFrame } from "../../../../../../Animation/interpolate";
import { SpringToFrom } from "../../../../../../Animation/RemotionSpring";
import {
  FromMiddle,
  FromBottomToTop,
} from "../../../../../../Animation/ClipWipe";
// Components
import { TopUnderlayForCopy } from "../../../../../GlobalComponents/BottomUnderlayForCopy";
import { H1, H2, H3, H4, P } from "../../../../../utils/Text";

export const StatsWrapper = ({ DATA, Duration, INN }) => {
  const frame = useCurrentFrame();
  const D = DATA.DATAOBJ;
  console.log(D);
  const Durations = {
    Begin: 0,
    TransitionAfter: 240,
  };
  return (
    <SeriesContainer>
      <StatsContainer>
        <H1
          style={{
            margin: 0,
            lineHeight: "1em",
          }}
        >
          Scorecard
        </H1>
        <H1
          style={{
            margin: 0,
            fontSize: "2em",
            lineHeight: ".8em",
            minHeight: "50px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {D.ResultStatement}
        </H1>
      
        <StatsContainerInner
          style={{
            opacity: interpolateOpacityByFrame(
              frame,
              Duration - 15,
              Duration,
              1,
              0
            ),
          }}
        >
          <StatsColumn
            D={D[INN.first]}
            Duration={Duration}
            CardData="BattingOrder"
            META={D}
          />
        </StatsContainerInner>
        <StatsContainerInner
          style={{
            opacity: interpolateOpacityByFrame(
              frame,
              Duration - 10,
              Duration,
              1,
              0
            ),
          }}
        >
          <StatsColumn
            D={D[INN.second]}
            Duration={Duration}
            META={D}
            CardData="BowlingOrder"
          />
        </StatsContainerInner>
        
        <TopUnderlayForCopy
          DATA={DATA}
          Durations={Durations}
          BOTTOM={`1100px`}
          POSITIONSTARTFROMTOP={-50}
          POSITIONFROMTOP={-15}
        />
      </StatsContainer>
    </SeriesContainer>
  );
};

const SeriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  flex-wrap: wrap;
  list-style: none;
`;

const StatsContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;
const StatsContainerInner = styled.div`
  /* background: radial-gradient(97.01% 88.57% at 50% 8.49%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.15) 100%);
    border: 1px solid rgba(255, 255, 255, 0.5); */
  box-sizing: border-box;
  backdrop-filter: blur(1px);
  width: 98%;
  margin: 1%;
  height: 98%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  margin-top: 0px;
  border-radius: 20px;
  z-index: 1000;
`;

const StatsColumn = ({ D, CardData, META }) => {
  const image = 80;

  const Score = () => {
    console.log(D.Name, META.HomeTeam);
    return D.Name === META.HomeTeam ? META.HomeTeamResult : META.AwayTeamResult;
  };

  return (
    <>
      <Image
        src={D.LOGO}
        radius={image}
        width={image}
        height={image}
        style={{
          top: 0,
          position: "absolute",
          left: 10,
          //bottom: "10px",

          transform: `translateX(${SpringToFrom(3, -250, 0, "Springy100")}px)`,
        }}
      />
      <H1
        style={{
          fontSize: "2em",
          lineHeight: "1em",
          minHeight: "50px",
          display: "flex",
          justifyContent: "end",
          width: "100%",
        }}
      >
        {D.Name}
      </H1>

      <Table verticalSpacing="xs">
        {CardData === "BattingOrder" ? <BattingTitle /> : <BowlingTitle />}
        {D[CardData].map((PLAYER, i) => {
          return CardData === "BattingOrder" ? (
            <>
              <BattingRow PLAYER={PLAYER} i={i} key={i} />
            </>
          ) : (
            <BowlingRow PLAYER={PLAYER} i={i} key={i} />
          );
        })}
      </Table>
      <H1
        style={{
          fontSize: "2em",
          lineHeight: "1em",
          minHeight: "50px",
          display: "flex",
          justifyContent: "end",
          width: "100%",
        }}
      >
        {CardData === "BattingOrder" ? Score() : false}
      </H1>
    </>
  );
};

const BattingTitle = () => {
  const frame = useCurrentFrame();
  const Styles = {
    P: {
      textAlign: "center",
      margin: "15px 0 15px 15px",
      fontSize: "1.6em",
      fontWeight: 600,
      backgroundColor: "#fff",
    },
  };
  return (
    <tr
      style={{
        marginBottom: "5px",
        transform: `translateX(${SpringToFrom(3, -1600, 0, "Springy100")}px)`,
        opacity: interpolateOpacityByFrame(frame, 3, 30, 0, 1),
      }}
    >
      <td></td>
      <td style={Styles.P}>Runs</td>
      <td style={Styles.P}>Balls</td>
      <td style={Styles.P}>How Out</td>
    </tr>
  );
};

const BowlingTitle = () => {
  const frame = useCurrentFrame();
  const Styles = {
    P: {
      textAlign: "center",
      margin: "15px 0 15px 15px",
      fontSize: "1.6em",
      fontWeight: 600,
      backgroundColor: "#fff",
    },
  };
  return (
    <tr
      style={{
        marginBottom: "5px",
        transform: `translateX(${SpringToFrom(3, -1600, 0, "Springy100")}px)`,
        opacity: interpolateOpacityByFrame(frame, 3, 30, 0, 1),
      }}
    >
      <td></td>
      <td style={Styles.P}>Wickets</td>
      <td style={Styles.P}>Runs</td>
      <td style={Styles.P}>Overs</td>
    </tr>
  );
};
const BattingRow = ({ PLAYER, i }) => {
  //const frame = useCurrentFrame();
  const theme = useTheme();

  const Styles = {
    P: {
      textAlign: "left",
      margin: "15px 0 15px 15px",
      fontSize: "1.8em",
      fontWeight: 600,
      color:theme.Copy[0]
    },
    INT: {
      textAlign: "center",
      margin: "15px 0",
      fontSize: "1.8em",
      color:theme.Copy[0],
      fontWeight: 400,
    },
  };
  const frame = useCurrentFrame();
  return (
    <tr
      style={{
        marginBottom: "5px",
        background: i % 2 === 0 ? theme.Base[5]: theme.radialGradient,  
        transform: `translateX(${SpringToFrom(
          3 * i,
          -1600,
          0,
          "Springy100"
        )}px)`,
        opacity: interpolateOpacityByFrame(frame, 3 * i, 30, 0, 1),
      }}
    >
      <td
        style={{
          borderRadius: "10px 0 0 10px",
        }}
      >
        <P style={Styles.P}>{PLAYER.attributes.player.data.attributes.Name}</P>{" "}
      </td>
      <td>
        <P style={Styles.INT}> {PLAYER.attributes.BATTING_Runs}</P>{" "}
      </td>
      <td>
        <P style={Styles.INT}>{PLAYER.attributes.BATTING_Balls}</P>
      </td>
      <td
        style={{
          borderRadius: "0 10px 10px 0 ",
        }}
      >
        <P style={Styles.INT}> {PLAYER.attributes.BATTING_HowOut}</P>
      </td>
    </tr>
  );
};

const BowlingRow = ({ PLAYER, i }) => {
  //const frame = useCurrentFrame();
  const theme = useTheme();

  const Styles = {
    P: {
      textAlign: "left",
      margin: "15px 0 15px 15px",
      fontSize: "1.8em",
      fontWeight: 600,
      color:theme.Copy[0],
    },
    INT: {
      textAlign: "center",
      margin: "15px 0",
      fontSize: "1.8em",
      fontWeight: 400,
      color:theme.Copy[0],
    },
  };
  const frame = useCurrentFrame();
  return (
    <tr
      key={i}
      style={{
        marginBottom: "5px",
        background: i % 2 === 0 ? theme.Base[5]: theme.radialGradient, 
        transform: `translateX(${SpringToFrom(
          3 * i,
          -1600,
          0,
          "Springy100"
        )}px)`,
        opacity: interpolateOpacityByFrame(frame, 3 * i, 30, 0, 1),
      }}
    >
      <td
        style={{
          borderRadius: "10px 0 0 10px",
        }}
      >
        <P style={Styles.P}>{PLAYER.attributes.player.data.attributes.Name}</P>{" "}
      </td>
      <td>
        <P style={Styles.INT}> {PLAYER.attributes.BOWLING_Wkts}</P>{" "}
      </td>
      <td>
        <P style={Styles.INT}>{PLAYER.attributes.BOWLING_Runs}</P>
      </td>
      <td
        style={{
          borderRadius: "0 10px 10px 0 ",
        }}
      >
        <P style={Styles.INT}> {PLAYER.attributes.BOWLING_Overs}</P>
      </td>
    </tr>
  );
};
