import { ThemeProvider } from "styled-components";

// Import Design Templates for MATCHDAYRESULT.
// Add new deisng patterns below
// GLASS
import { TOP5_4by5 } from "../../theme/Templates/GLASS/index";
import { ARROWS_TOP5_4by5 } from "../../theme/Templates/ARROWS/index";
// END

export const Build_TOP5BATTINGRANKINGS= (props) => {
  const { RENDER } = props;
  const TEMPLATES = {
    Glass: <TOP5_4by5 RENDER={RENDER} MainTitle="RANKING" Subtitle="TOP 5 BATSMAN" />,
    Arrows: <ARROWS_TOP5_4by5 RENDER={RENDER} VARIABLE={""} PREFIX={"World Ranking :"} />, 
   
  };
  return (
    <ThemeProvider theme={RENDER.THEME.VideoTheme.Theme}>
      {TEMPLATES[RENDER.THEME.VideoTemplate]}
    </ThemeProvider>
  );
};
