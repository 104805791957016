import styled from "styled-components";
import { useTheme } from "styled-components";
import { H1 } from "../../../../utils/Text";

import {FromLeftToRight,
} from "../../../../../Animation/ClipWipe";



export const StatsContainer = ({Label,DIS,SUP})=>{
  const theme = useTheme()
    return(
        <Container>
        <H1
          style={{
            textAlign: "left",
            fontWeight: 900,
            fontSize: "5em",
            color: theme.Copy[2],
            lineHeight: "1.4em",
            clipPath: FromLeftToRight(15, "Springy100")
          }}
        >
          {Label}
        </H1>
        <Main> 
          {DIS}
        </Main>

        <Sup>
          {SUP}
        </Sup>
      </Container>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  width: 800px;
`;

const Main = styled.div`
  width: 350px;
`;

const Sup = styled.div`
  width: 300px;
`;