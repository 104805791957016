import { Series } from 'remotion';
import {AnimateIn} from './Series/AnimateIn'


export const SHOWCASE = ({DATA,Dimensions})=>{ 

    const Durations={
        Begin:0, // bring in overlay
        TransitionAfter:120, // slide overlay down
        Close:240, // remove overlay prep nect item 
        Offset:30,
    }
   
    if(Dimensions.length ===0)
    return false
    return(
      <> 
      <Series>
        {
                  DATA.SHOWCASE.map((POTD,i)=>{
                   
                      return( 
                        <Series.Sequence durationInFrames={240} layout="none" key={i}>
                            <Series>
                                <Series.Sequence durationInFrames={240} layout="none">
                                    <AnimateIn POTD={POTD} Dimensions={Dimensions} Durations={Durations} DATA={DATA}/>
                                </Series.Sequence>
                            </Series>
                        </Series.Sequence> 
                      )    
                  })  
              }
               </Series> 
              </>
    )
  } 