import { useState } from "react" 
import axios from 'axios';
 
import {ENV} from '../../Config/RunTimeConfig'
import { parseCookies } from 'nookies'
import {uniq} from 'lodash'
const qs = require('qs');

// TODO : THIS HOOK IS IN THE WRONG FOLDER!!! MOVE


// UTILS 

const RemoveImageFromGallery_Return_ARR = async(_JWT,_IMG,_USER)=>{
     
    const query = qs.stringify({populate: '*'},{encodeValuesOnly: true,});

        const res = await fetch(`${ENV.API_URL}/api/players/${_USER.id}?${query}`, {
            headers: { Authorization: `Bearer ${_JWT}`}
        })

        const PlayerResponse = await res.json();
        const PlayerGallery = PlayerResponse.data.attributes.ImageGallery.data

        const ARR=[]
        PlayerGallery.map((IMG,i)=>{ ARR.push(IMG.id); return true;})
           
        const filteredItems = ARR.filter(item => item !== _IMG.id);
        return filteredItems
}

 

const STRAPI_UploadNewGallery = async(_URL,_JWT,_ARR)=>{
    const res = await fetch(`${_URL}`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${_JWT}`
        },
        body: JSON.stringify({
            data: {
                ImageGallery: _ARR,
              }
        })
    })
    const PlayerGallery = await res.json();
  //console.log(PlayerGallery)
    return PlayerGallery
}

// END UTILS
/* ****************************************************************************** */


// HOOKS
/* ******************************************************************* */
/* NOT SURE THIS IS USED, BUT DONT DELETE YET*/
export const useUploadPlayerImage = (ctx)=>{

    const [PlayerImage,setPlayerImage] = useState(null);
    const jwt = parseCookies(ctx).jwt
    
    const UpdatePlayer = async(_IMAGEID, _ID, _URL)=>{
        await fetch(`${ENV.API_URL}/api/players/${_ID}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({
                data: {
                    image: [_IMAGEID],
                  }
            })
        })
        //const GetLeagueStatus = await res.json();
        //console.log(GetLeagueStatus)
        setPlayerImage(_URL)
    }


    const UploadPlayerImage = async (_FILE, _PLAYER)=>{
        //console.log(_FILE)
        const formData = new FormData()
    
        formData.append('files', _FILE[0])
    
        axios.post(`${ENV.API_URL}/api/upload`, formData)
        .then((response)=>{
          
            //console.log(response.data[0].url)
            const imageId = response.data[0].id
            //console.log(imageId, _PLAYER.id)
            setPlayerImage(false)
            UpdatePlayer(imageId, _PLAYER.id, response.data[0].url)
      
        }).catch((error)=>{
          //console.log(error) 
        })
       
    }
    return[PlayerImage,UploadPlayerImage]
} 



/* ******************************************************************* */
/* ADD NEW IMAGE TO GALLERY */

export const UploadPlayerGalleryImage =(ctx)=>{  

    const [GalleryImage,setGalleryImage] = useState(null);
    const jwt = parseCookies(ctx).jwt;

    const ConstructGalleryARR=async(_FILE, _ID)=>{

        const query = qs.stringify({populate: '*'},{encodeValuesOnly: true,});

        const res = await fetch(`${ENV.API_URL}/api/players/${_ID}?${query}`, {
            headers: { Authorization: `Bearer ${jwt}`}
        })
 
        const PlayerResponse = await res.json();
        const PlayerGallery = PlayerResponse.data?.attributes.ImageGallery?.data
      console.log("PlayerGallery", PlayerGallery)
        
        if(PlayerGallery === undefined || PlayerGallery=== null)
            return [_FILE]
        
        const ARR=[_FILE]
        PlayerGallery.map((IMG,i)=>{ 
                ARR.push(IMG.id)
                return true
            })
            return uniq(ARR)

    }


    const UploadGalleryImage = async (_FILE, _PLAYER)=>{
        setGalleryImage(false)
        const GALLERYARR =  await ConstructGalleryARR(_FILE, _PLAYER.id)

        //_URL,_JWT,_ARR
        const UploadStatus = await STRAPI_UploadNewGallery(`${ENV.API_URL}/api/players/${_PLAYER.id}`,jwt,GALLERYARR)
        if(UploadStatus?.data?.id)
            setGalleryImage(true)
    }
    return [GalleryImage,UploadGalleryImage]
}


/* ******************************************************************* */
/* ASSIGN DEFAULT IMAGE */

export const useAssignNewDefaultImage = (ctx)=>{ 
    const [PlayerImage,setPlayerImage] = useState(null);
    const jwt = parseCookies(ctx).jwt
    
    const AssignPlayerImage = async(_IMAGEID, _ID)=>{
        const res = await fetch(`${ENV.API_URL}/api/players/${_ID}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({
                data: {
                    image: [_IMAGEID],
                  }
            })
        })
        const GetLeagueStatus = await res.json();
        setPlayerImage(GetLeagueStatus)
       
    }
    return [PlayerImage,AssignPlayerImage]
} 
 
/* ******************************************************************* */
/* Delete Player Image */


export const useDeletePlayerImage = (ctx)=>{
    
    const [DeleteImage,setDeleteImage] = useState(null);
    const jwt = parseCookies(ctx).jwt
    
    // Extract ID from GAllery, Remove Image from Server, Send UI Confirmation

    const DeleteFromServer = async (_IMG)=>{

        ///api/upload/files/:id
        const res = await fetch(`${ENV.API_URL}/api/upload/files/${_IMG.id}`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            }
        })
        const hasDeleteImage = await res.json();
      //console.log(hasDeleteImage)
    }


    const processDeleteImage= async(_IMG,_USER)=>{
            setDeleteImage(false)
        const GALLERYARR = await RemoveImageFromGallery_Return_ARR(jwt,_IMG,_USER)
        //_URL,_JWT,_ARR
        const UploadStatus = await STRAPI_UploadNewGallery(`${ENV.API_URL}/api/players/${_USER.id}`,jwt,GALLERYARR)

        if(UploadStatus.data.id){
            DeleteFromServer(_IMG);
            setDeleteImage(true);
        } 
    }

    return[DeleteImage,processDeleteImage]
}
