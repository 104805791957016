import {gql,} from "@apollo/client";

export const QUERY = (APISETTINGS)=>{
    return gql`query SelectedTeam {
      team(id:${APISETTINGS.TeamID}){
         data{
          attributes{
            Name
            TeamID
            Logo{
              data{
                attributes{
                  url
                }
              }
            }
            watch_lists(filters: { id: { in: [${APISETTINGS.DivisionID}] }}){
              data{
                id
                attributes{
                  Name
                }
              } 
            }
            fixtures(
               pagination: { start: 0, limit: -1 }
              filters: { watch_list: { id: { in: [${APISETTINGS.DivisionID}] } }}
            ){
              data{
                attributes{
                  fixtureID
                  HomeTeam
                  AwayTeam
                  HomeTeamResult
                  AwayTeamResult
                  ResultStatement
                  player_moms{
                    data{
                      id
                    }
                  }
                  watch_list{
                    data{
                      id
                      attributes{
                        Name
                      }
                    }
                  }
                }
              }
            }
            player_battings(
               pagination: { start: 0, limit: -1 }
              filters: { fixture:{watch_list: { id: { in: [${APISETTINGS.DivisionID}] } }} }
            ){
              data{
                attributes{
                  BATTING_Runs
                        BATTING_Balls
                        BATTING_fours
                        BATTING_sixes
                        BATTING_HowOut
                  player{
                    data{
                      id
                      attributes{
                        Name
                        PlayerID
                        image{
                          data{
                            attributes{
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            player_bowlings(
               pagination: { start: 0, limit: -1 }
              filters: { fixture:{watch_list: { id: { in: [${APISETTINGS.DivisionID}] } }} }
            ){
              data{
                attributes{
                  BOWLING_Overs
                  BOWLING_Runs
                  BOWLING_Wkts
                  BOWLING_Maidens
                  player{
                    
                    data{
                      id
                      attributes{
                        Name
                        PlayerID
                        image{
                          data{
                            attributes{
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            player_catches(
               pagination: { start: 0, limit: -1 }
              filters: { fixture:{watch_list: { id: { in: [${APISETTINGS.DivisionID}] } }} }
            ) {
              data{
                attributes{
                  PLAYERS_Catches
                  player{
                    
                    data{
                      id
                      attributes{
                        Name
                        PlayerID
                        image{
                          data{
                            attributes{
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            Logo{
              data{
                attributes{
                  url
                }
              }
            }
            ImageGallery{
              data{
                attributes{
                  url
                }
              }
            }
            TeamPhoto{
              data{
                attributes{
                  url
                }
              }
            }
          }
        }
      }
    }
    `
}
  
