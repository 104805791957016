import { useEffect, useState } from "react";
import { FindTeamLogoURI } from "../../../../../actions/video";

import { findIndex, filter, orderBy } from "lodash";

const VideoOBJ = {
  VideoDurationinFrames: null,
  SectionDuration: [90, 120, 180, 210, 210, 180 * 4, 180, 120],
  VideoBodyTiming: [180, 210, 210, 180 * 4, 180].reduce((a, b) => a + b),
  RenderStill: [70, 150, 330, 500, 700,890,1100,1200,1500,1600],
};
const DEFAULTIMG =
  "https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/playeroption4_de5eede10d.png";
export const useCreateFixtures = () => {
  const VideoSettings = { ...VideoOBJ };
  const [Fixtures, setFixtures] = useState({ VideoSettings });

  const VideoDuration = () => {
    return Fixtures.VideoSettings.SectionDuration.reduce((a, b) => a + b);
  };

  const CreateBattingOrder = (BATTING, TEAMID) => {
    const TEAMBATTING = filter(BATTING.data, (o) => {
      return o.attributes.team.data.attributes.Name === TEAMID;
    });

    return TEAMBATTING;
  };

  const CreateTeamOBJ = (TEAM, DATA) => {
    return {
      Name: TEAM.Name,
      City_Rank: TEAM.City_Rank,
      National_Rank: TEAM.National_Rank,
      World_Rank: TEAM.World_Rank,
      Form: TEAM.Form,
      Games: TEAM.Games,
      Won: TEAM.Won,
      Lost: TEAM.Lost,
      Win_Ratio: TEAM.Win_Ratio,
      BattingOrder: CreateBattingOrder(
        DATA.attributes.player_battings,
        TEAM.Name
      ),
      BowlingOrder: CreateBattingOrder(
        DATA.attributes.player_bowlings,
        TEAM.Name
      ),
      Catches: CreateBattingOrder(DATA.attributes.player_catches, TEAM.Name),
      Stumpings: CreateBattingOrder(
        DATA.attributes.player_stumpings,
        TEAM.Name
      ),
      MoM: CreateBattingOrder(DATA.attributes.player_moms, TEAM.Name),
      LOGO: FindTeamLogoURI({ attributes: TEAM }),
    };
  };


  const FindMOM =(DATA)=>{
    console.log(DATA.attributes.player_moms.data[0].attributes)
    const IMG = DATA?.attributes.player_moms.data[0]?.attributes.player.data?.attributes.image.data?.attributes.url
    return[{
      Name:DATA.attributes.player_moms.data[0].attributes.player.data.attributes.Name,
      Team:DATA.attributes.player_moms.data[0].attributes.team.data.attributes.Name,
      ID: DATA.attributes.player_moms.data[0].attributes.player.data.id,
      IMAGE:IMG === undefined ?DEFAULTIMG:IMG
    }]
  }
 
  /* Clean Performances */
  const CleanBatting = (PLAYER) => {
    return {
      Name: PLAYER.attributes.player.data.attributes.Name,
      Runs: PLAYER.attributes.BATTING_Runs,
      Balls: PLAYER.attributes.BATTING_Balls,
      StrikeRate: PLAYER.attributes.BATTING_SR,
      HowOut: PLAYER.attributes.BATTING_HowOut,
      ID:  PLAYER.attributes.player.data.id,
      IMAGE:
        PLAYER?.attributes.player.data?.attributes.image.data?.attributes.url ===
        undefined
          ? DEFAULTIMG
          : PLAYER.attributes.player.data.attributes.image.data.attributes.url,
    };
  };

  const CleanBowling = (PLAYER) => {
    return {
      Name: PLAYER.attributes.player.data.attributes.Name,
      Wickets: PLAYER.attributes.BOWLING_Wkts,
      Runs: PLAYER.attributes.BOWLING_Runs,
      Overs: PLAYER.attributes.BOWLING_Overs,
      ID: PLAYER.attributes.player.data.id,
      Economy: PLAYER.attributes.BOWLING_Econ,
      IMAGE:
        PLAYER?.attributes.player.data?.attributes.image.data?.attributes.url ===
        undefined
          ? DEFAULTIMG
          : PLAYER.attributes.player.data.attributes.image.data.attributes.url,
    };
  };

  const FindTopPerformance = (ORDER, PATH, TYPE, ORDERBY = "desc") => {
    const TopPerformance = orderBy(
      ORDER,
      (o) => {
        return parseInt(o.attributes[PATH]);
      },
      [ORDERBY]
    )[0];

    return TYPE === "BATTING"
      ? CleanBatting(TopPerformance)
      : CleanBowling(TopPerformance);
  };

  const CreatePerformances = (DATA) => {
    const Performances = {
      MOM:FindMOM(DATA),
      Runs: [
        FindTopPerformance(
          CreateTeamOBJ(DATA.attributes.teams.data[0].attributes, DATA)
            .BattingOrder,
          "BATTING_Runs",
          "BATTING"
        ),
        FindTopPerformance(
          CreateTeamOBJ(DATA.attributes.teams.data[1].attributes, DATA)
            .BattingOrder,
          "BATTING_Runs",
          "BATTING"
        ),
      ],
      StrikeRate: [
        FindTopPerformance(
          CreateTeamOBJ(DATA.attributes.teams.data[0].attributes, DATA)
            .BattingOrder,
          "BATTING_SR",
          "BATTING"
        ),
        FindTopPerformance(
          CreateTeamOBJ(DATA.attributes.teams.data[1].attributes, DATA)
            .BattingOrder,
          "BATTING_SR",
          "BATTING"
        ),
      ],
      Wickets: [
        FindTopPerformance(
          CreateTeamOBJ(DATA.attributes.teams.data[0].attributes, DATA)
            .BowlingOrder,
          "BOWLING_Wkts",
          "BOWLING"
        ),
        FindTopPerformance(
          CreateTeamOBJ(DATA.attributes.teams.data[1].attributes, DATA)
            .BowlingOrder,
          "BOWLING_Wkts",
          "BOWLING"
        ),
      ],
      Economy: [
        FindTopPerformance(
          CreateTeamOBJ(DATA.attributes.teams.data[0].attributes, DATA)
            .BowlingOrder,
          "BOWLING_Econ",
          "BOWLING", 'asc'
        ),
        FindTopPerformance(
          CreateTeamOBJ(DATA.attributes.teams.data[1].attributes, DATA)
            .BowlingOrder,
          "BOWLING_Econ",
          "BOWLING", 'asc'
        ),
      ],
    };
    return Performances;
  };

  const FindFixtures = async (DATA) => {
    let DATAOBJ = {};
    if (DATA.attributes.player_moms.data.length === 0) {
      DATAOBJ = { data: false };
    } else {
      DATAOBJ = {
        data: true,
        TeamA: CreateTeamOBJ(DATA.attributes.teams.data[0].attributes, DATA),
        TeamB: CreateTeamOBJ(DATA.attributes.teams.data[1].attributes, DATA),
        Performances: CreatePerformances(DATA),
        HomeTeam: DATA.attributes.HomeTeam,
        HomeTeamResult: DATA.attributes.HomeTeamResult,
        AwayTeam: DATA.attributes.AwayTeam,
        AwayTeamResult: DATA.attributes.AwayTeamResult,
        ResultStatement: DATA.attributes.ResultStatement,
        Date: DATA.attributes.Date,
        Time: DATA.attributes.Time,
        ground: DATA.attributes.ground.data.attributes.Name,
        umpire: DATA.attributes.umpire.data.attributes.Name,
      };

      Fixtures.VideoSettings.VideoDurationinFrames = VideoDuration();
    }

    setFixtures({ ...Fixtures, DATAOBJ: { ...DATAOBJ } });
  };

  useEffect(() => {}, [Fixtures]);

  return [Fixtures, FindFixtures];
};
