import { Container, Group } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {SectionLabel, P} from '../../Theme/type';
import {ColoredContainer } from "../../Theme/Structure/BodyContainer";
import {ICONDB} from '../../Theme/icons'

/** 
 * 
 * EXAMPLE COMPONENT
 * <BLOCK_Section_Title 
                OBJ={{
                    LABEL:'Most Popular',
                    ICON:'Download',
                    DESCRIPTION:'The more popular assets created around the LMS world.',
                    TOOLBAR:false
                }}
            />
 * 
 */
export const BLOCK_Section_Title = ({OBJ})=>{
    const matches = useMediaQuery('(min-width: 765px)');
    return(
        <Container size="lg" p={0} my='xs'
        sx={(theme) => ({
            borderTop: matches? false:`10px solid ${theme.colors.brand[1]}`,
            marginTop: matches? false:`20px !important`,
            paddingTop: matches? false:`10px !important`,
          })}
        
        >
            <DisplayTitle LABEL={OBJ.LABEL} ICON={ICONDB[OBJ.ICON].Component}/>
            <DisplayDescription DESCRIPTION={OBJ.DESCRIPTION}/>
            { OBJ.TOOLBAR ?<DisplayToolbar />: false }
        </Container>
    )
}


const DisplayTitle=({LABEL,ICON})=>{
    return(
        <Group spacing="xs">
                    <P colorID={4}>{ICON} </P>
                    <SectionLabel size={25} colorID={2} weight={400}>{LABEL}</SectionLabel>
                </Group>
    )
}

const DisplayDescription = ({DESCRIPTION})=>{
    return(
        <ColoredContainer BGColor={0} Padding={0}>
            <P colorID={3}>{DESCRIPTION}</P> 
        </ColoredContainer> 
    )
}


const DisplayToolbar = ()=>{
    return(
        <ColoredContainer BGColor={1} Padding={5}>
                <P colorID={3}>TOOLBAR : </P>
            </ColoredContainer> 
    )
}