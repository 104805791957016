import { Routes, Route,Outlet,useLocation } from "react-router-dom";
import { useEffect } from "react";
import ReactGA from "react-ga4";
// Route Components
import UpComingFixtures from '../assetoptions/WEEKLY/upComingFixtures'
import FixtureResults from "../assetoptions/WEEKLY/FixtureResults/index"
import POTDBATTING from "../assetoptions/WEEKLY/POTDBATTING/index"
import POTDBOWLING from "../assetoptions/WEEKLY/POTDBOWLING/index"
import TEAMOFTHEWEEK from "../assetoptions/WEEKLY/TeamoftheWeek/index"
import GAMEOFTHEWEEK from "../assetoptions/WEEKLY/GameoftheWeek/index"
import GAMEOFTHEWEEKLITE from "../assetoptions/WEEKLY/GameotheWeekLite/index"
import ThisWeekInLMSfrom from "../assetoptions/WEEKLY/ThisWeekInLMS/index"
import PLAYEROFTHEMATCH from "../assetoptions/WEEKLY/PLAYEROFTHEMATCH/index"
import MATCHREVIEW from "../assetoptions/WEEKLY/MatchReview/index"
import LEAGUESTANDINGS from "../assetoptions/WEEKLY/LeagueStandings/index"
//
// Top 5
import LEADERBOARDSIXES from "../assetoptions/TOP5/LeaderboardSixes/index"
import TOP5WICKETS from "../assetoptions/TOP5/TopFiveWickets"
import TOP5RUNS from "../assetoptions/TOP5/TopFiveRuns"
import TOP5CATCHES from "../assetoptions/TOP5/TopFiveCatches"
import TOP5FASTESTFIFTIES from "../assetoptions/TOP5/TopFastestFifties"
import TOP5FOURS from "../assetoptions/TOP5/TopFiveFours"
import TOP5BATTINGRANKINGS from '../assetoptions/TOP5/BattingRankings'
import TOP5BOWLINGRANKINGS from '../assetoptions/TOP5/BowlingRankngs'


// Player Milestones
import PLAYERMILESTONEGAMES from '../assetoptions/MILESTONE/PlayerMilestone/index'
// Tournaments
/* import TOURNAMENTPOTDBATTING from '../assetoptions/TOURNAMENTS/POTD/BATTING/'
import TOURNAMENTPOTDBOWLING from '../assetoptions/TOURNAMENTS/POTD/BOWLING/'
import TOURNAMENTTOP5RUNS from '../assetoptions/TOURNAMENTS/TOP5/TOP5RUNS/'
import TOURNAMENTTOP5WICKETS from '../assetoptions/TOURNAMENTS/TOP5/TOP5WICKETS/'
import TOURNAMENTTOP5SIXES from '../assetoptions/TOURNAMENTS/TOP5/TOP5SIXES/'
import TOURNAMENTRESULTS from '../assetoptions/TOURNAMENTS/MATCHDAY/RESULTS/'
import TOURNAMENTFIXTURES from '../assetoptions/TOURNAMENTS/MATCHDAY/FIXTURES/'
import TOURNAMENTMOTD from '../assetoptions/TOURNAMENTS/MATCHDAY/MOTD/'
import TOURNAMENTMOTDLITE from '../assetoptions/TOURNAMENTS/MATCHDAY/MOTDLITE/'
import TOURNAMENTPOSTMATCHINTERVIEW from '../assetoptions/TOURNAMENTS/MATCHDAY/INTERVIEWS/POSTMATCH/'
import TOURNAMENTWINNERS from '../assetoptions/TOURNAMENTS/MATCHDAY/WINNERS/' */

// Seasonal
import DivisionWinners from '../assetoptions/SEASONAL/DivisionWinners/'
import TEAMSEASONALREVIEW from '../assetoptions/SEASONAL/TeamSeasonalReview/'
// Index files
import {AssetGroupSeasonal} from '../assetoptions/AssetGroupPostSeason'
import {AssetGroupWeekly} from '../assetoptions/AssetGroupWeekly'
import {AssetGroupPerformances} from '../assetoptions/AssetGroupPerformances'
import {AssetGroupTop5List} from '../assetoptions/AssetGroupTop5List';
import {AssetPlayerMilestone} from '../assetoptions/AssetGroupPlayerMilestone'
//import {AssetGroupTournament} from '../assetoptions/OLD_AssetGroupTournament'


ReactGA.initialize("G-5MLHD6G08Z");

export const RoutesVideos = ()=>{
    let location = useLocation();
    useEffect(() => {
        // Google Analytics
        console.log(window.location.pathname)
        ReactGA.send({ hitType: "pageview", page: window.location.pathname }); 
      }, [location]);
    return(
        <>
            <RoutesWeekly  />
            <RoutesPlayerPerformances />
            <RoutesTopFive  />
            <RoutesMilestones  />
           {/*  <RoutesTournaments  /> */}
            <RoutesSeasonal   />
        </>
        
    )
}

export const RoutesWeekly = ()=>{
    return(
        <Routes> 
            <Route path="/Fixtures" element={<RouteLayout />} >
                <Route index element={<AssetGroupWeekly   />} />
                <Route path="upComingFixtures" element={<UpComingFixtures AssetID={1}   />} />
                <Route path="match-day-results" element={<FixtureResults AssetID={2}   />} />
                <Route path="team-of-the-week" element={<TEAMOFTHEWEEK  AssetID={6}    />} />
                <Route path="game-of-the-week" element={<GAMEOFTHEWEEK  AssetID={11}    />} />
                <Route path="game-of-the-week-lite" element={<GAMEOFTHEWEEKLITE  AssetID={35}    />} />
                <Route path="thisWeekInLMS" element={<ThisWeekInLMSfrom  AssetID={37}    />} />
                <Route path="match-review" element={<MATCHREVIEW  AssetID={41}    />} />
                <Route path="league-standings" element={<LEAGUESTANDINGS  AssetID={42}    />} />
                
            </Route>
        </Routes>
    )
}

export const RoutesPlayerPerformances = ()=>{
    return(
        <Routes> 
            <Route path="/performances" element={<RouteLayout />} >
                <Route index element={<AssetGroupPerformances   />} />
                <Route path="potd-batting" element={<POTDBATTING AssetID={3}   />} />
                <Route path="potd-bowling" element={<POTDBOWLING AssetID={5}    />} />
                <Route path="player-of-the-match" element={<PLAYEROFTHEMATCH  AssetID={38}    />} />    
            </Route>
        </Routes>
    )
}


export const RoutesTopFive = ()=>{
    return(
        <Routes> 
            <Route path="/top-5-lists" element={<RouteLayout />} >
                <Route index element={<AssetGroupTop5List   />} />
                <Route path="leaderboard-sixes" element={<LEADERBOARDSIXES AssetID={7}   />} />
                <Route path="top-5-wickets" element={<TOP5WICKETS AssetID={8}   />} />
                <Route path="top-5-runs" element={<TOP5RUNS AssetID={9}     />} />
                <Route path="top-5-catches" element={<TOP5CATCHES AssetID={10}   />} />
                <Route path="top-5-fastest-fifties" element={<TOP5FASTESTFIFTIES AssetID={21}   />} />
                <Route path="top-5-fours" element={<TOP5FOURS AssetID={22}   />} /> 
                <Route path="top-5-batting-rankings" element={<TOP5BATTINGRANKINGS AssetID={39}   />} />  
                <Route path="top-5-bowling-rankings" element={<TOP5BOWLINGRANKINGS AssetID={40}   />} />    
                
                
            </Route>
        </Routes>
    )
}

export const RoutesMilestones = ()=>{
    return(
        <Routes> 
            <Route path="/player-milestone" element={<RouteLayout />} >
                <Route index element={<AssetPlayerMilestone   />} />
                <Route path="player-milestone-games" element={<PLAYERMILESTONEGAMES AssetID={12} Type={{KEY:'MATCHES',LABEL:'GAME'}}   />} />
                <Route path="player-milestone-runs" element={<PLAYERMILESTONEGAMES AssetID={13} Type={{KEY:'RUNS',LABEL:'RUNS'}}   />} />
                <Route path="player-milestone-wickets" element={<PLAYERMILESTONEGAMES AssetID={14} Type={{KEY:'WICKETS',LABEL:'WICKETS'}}   />} />
                <Route path="player-milestone-fifties" element={<PLAYERMILESTONEGAMES AssetID={15} Type={{KEY:'FIFTIES',LABEL:'FIFTIES'}}   />} />
                <Route path="player-milestone-hundreds" element={<PLAYERMILESTONEGAMES AssetID={16} Type={{KEY:'HUNDREDS',LABEL:'HUNDREDS'}}   />} />
                <Route path="player-milestone-W3" element={<PLAYERMILESTONEGAMES AssetID={17} Type={{KEY:'W3',LABEL:' - 3 fas'}}   />} />
                <Route path="player-milestone-W5" element={<PLAYERMILESTONEGAMES AssetID={18} Type={{KEY:'W5',LABEL:' - 5 fas'}}   />} />
                <Route path="player-milestone-status" element={<PLAYERMILESTONEGAMES AssetID={19} Type={{KEY:'PLAYERPROFILELEVEL',LABEL:''}}   />} />
                    
            </Route>
        </Routes>
    )
}

/* export const RoutesTournaments = ()=>{
    return(
        <Routes> 
            <Route path="/tournament" element={<RouteLayout />} >
                <Route index element={<AssetGroupTournament   />} />
                <Route path="tournament-potd-batting" element={<TOURNAMENTPOTDBATTING AssetID={23}   />} />
                <Route path="tournament-potd-bowling" element={<TOURNAMENTPOTDBOWLING AssetID={24}   />} />
                <Route path="tournament-top5-runs" element={<TOURNAMENTTOP5RUNS AssetID={25}   />} />
                
                <Route path="tournament-results" element={<TOURNAMENTRESULTS AssetID={27}   />} />
                <Route path="tournament-fixtures" element={<TOURNAMENTFIXTURES AssetID={28}   />} />
                <Route path="tournament-motd" element={<TOURNAMENTMOTD AssetID={29}   />} />
                <Route path="tournament-post-match-interview" element={<TOURNAMENTPOSTMATCHINTERVIEW AssetID={30}   />} />
                <Route path="tournament-post-match-interview" element={<TOURNAMENTPOSTMATCHINTERVIEW AssetID={30}   />} />
                <Route path="tournament-winners" element={<TOURNAMENTWINNERS AssetID={31}   />} />
                <Route path="tournament-top5-wickets" element={<TOURNAMENTTOP5WICKETS AssetID={33}   />} />
                <Route path="tournament-top5-sixes" element={<TOURNAMENTTOP5SIXES AssetID={34}   />} />
                <Route path="tournament-motd-lite" element={<TOURNAMENTMOTDLITE AssetID={36}   />} />
                
            </Route>
        </Routes>
    )
} */

export const RoutesSeasonal= ()=>{
    return(
        <Routes> 
            <Route path="/seasonal" element={<RouteLayout />} >
                <Route index element={<AssetGroupSeasonal   />} />
                <Route path="division-winners" element={<DivisionWinners AssetID={26}   />} />
                <Route path="season-review" element={<TEAMSEASONALREVIEW AssetID={32}   />} />
            </Route>
        </Routes>
    )
}
const RouteLayout=()=><Outlet />
//<Route path="/post-season" element={<AssetPostSeason   />} />
/*    <Route path="/preseason" element={<AssetPreSeason   />} />
                        <Route path="/assetoptions/know-your-league" element={<KnowYourLeague  AssetID={4}    />} />
                     */ 