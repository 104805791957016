import styled from 'styled-components';

export const OpeningTitle = styled.h1`
margin:0;
font-family: 'arial';
font-style: normal;
font-weight: 900;
font-size: 150px;
text-align: center; 
letter-spacing: -0.01em;
text-transform: uppercase;
position: relative;
color:${props => props.theme.Copy[0]};
z-index:300;` 

export const OpeningSubTitle = styled.h1`
position: relative;
width: 100%;
font-family: 'Arial';
font-style: normal;
font-weight: 900;
font-size: 125px;

margin:0;
align-items: center;
text-align: center;
letter-spacing: -1px;
text-transform: uppercase;
color:${props =>props.theme.Copy[0]};
z-index:301;
`

export const OpeningDate = styled.h1`
margin:0;
font-family: 'arial';
font-style: normal;
font-weight: 500;
font-size: 60px;
text-align: center;
letter-spacing: -0.01em;
text-transform: uppercase;
position: relative;
color:${props => props.theme.Copy[0]};
z-index:300;`  

export const LASTMANSTANDS = styled.h1`

font-family: 'Arial';
font-style: normal;
font-weight: 900;
font-size: 85px;
line-height: 85px;
text-align: center;
letter-spacing: -0.02em;
text-transform: uppercase;
margin:0;
filter: drop-shadow(-1px 17px 16px rgba(0, 0, 0, 0.25));
color:${props => props.theme.Copy[1]}; 
`
export const StartLocalGoGlobal = styled.h1`
margin:0;
font-family: 'Arial';
font-weight: 700;
font-size: 60px;
line-height: 60px;
align-items: center;
text-align: center;
letter-spacing: -0.055em;
text-transform: uppercase;
filter: drop-shadow(-1px 17px 16px rgba(0, 0, 0, 0.25));
color:${props => props.theme.Copy[3]};
`
export const StartLocalGoGlobalSpan = styled.span`

color:${props => props.theme.Copy[4]};
`

/* BASE LINE TEXT */
export const H1 = styled.h1`
font-family: 'arial';
font-style: normal;
font-weight: 600;
font-size: 50px;
line-height: 60px;
margin:0;
align-items: center;
width:90%;
text-align: center;
letter-spacing: -3px;
text-transform: uppercase;
color:${props => props.theme.Base[4]};
z-index:10;`

export const H2 = styled.h1`
font-family: 'arial';
font-style: normal;
font-weight: 400;
font-size: 35px;
line-height: 30px;
text-align: right;
letter-spacing: -1px;
text-transform: uppercase;
z-index:10;
text-align: center;
margin: 0;
color:${props => props.theme.Base[4]};
`
export const H3 = styled.h1`
font-family: 'arial';
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 30px;
text-align: right;
letter-spacing: -3px;
text-transform: uppercase;
z-index:10;
text-align: center;
margin: 0;
color:${props => props.theme.Base[4]};
`
export const H4 = styled.h1`
font-family: 'arial';
font-style: normal;
font-weight: 600;
font-size: 25px;
line-height: 30px;
text-align: right;
letter-spacing: -3px;
text-transform: uppercase;
z-index:10;
text-align: center;
margin: 0;
color:${props => props.theme.Base[4]};
`

export const P = styled.p`
    z-index:10;
    font-family: 'arial';
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color:${props => props.theme.Base[4]};
`
export const S = styled.p`
    z-index:10;
    font-family: 'arial';
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color:${props => props.theme.Base[4]};
`
export const P_Bold = styled(P)` font-weight: 500;`


/** Bespoke */

export const POTD_SR= styled(H2)`
    position: absolute;
    right: 0px;
    top: -30px;
    margin:0;
    text-align: right;
    border-bottom:1px solid;`