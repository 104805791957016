/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { RadioVideoTheme } from "../../settings/RadioVideoTheme";
import { H3, P } from "../../../Theme/type";
import {
  ColoredContainer,
  VideoWizardContainer,
} from "../../../Theme/Structure/BodyContainer";
import { Group, ColorSwatch, Stack } from "@mantine/core";

import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
import { SelectVideoTheme } from "../../settings/SelectVideoTheme";
export const SelectTheme = ({ setTheme, Theme, Layout }) => {
  const [value, setValue] = useState(Theme);
  const [DisplaySelected, setDisplaySelected] = useState(false);
  const [ItemState, setItemState] = useState(0);

  const handleChange = (e) => {
    setValue(e);
    setTheme(e);
    setDisplaySelected(e);
    setItemState(10);
  };

  useEffect(() => {
    //console.log(value)
  }, []);


  return (
    <>
      <BLOCK_Section_SubHeading
        LABEL="Select a Color Theme"
        BGColor={0}
        Color={3} 
      />
        <Group position="apart" spacing="xl" mt={10} mb={20} grow>
          <ThemeOptions
            handleChange={handleChange}
            value={value}
            Layout={Layout}
          />
        </Group>
    
    </>
  );
};

const ThemeOptions = ({ handleChange, value, Layout }) => {
  
  return  !Layout ?<H3>Select a Template to view theme options</H3>:<SelectVideoTheme
        CALLBACK={handleChange}
        DefaultValue={value}
        SelectedTemplate={Layout}
      />
};

/* const RenderSelected = ({ SELECTED }) => {
  return (
    <Stack align="center" justify="space-around">
      <ColoredContainer BGColor={1}>
        <Group>
          {SELECTED.attributes.Theme.Base.map((c, i) => {
            return <ColorSwatch key={i} color={c} size={20} />;
          })}
        </Group>
      </ColoredContainer>

      <H3 colorID={5}>{SELECTED.attributes.Name}</H3>
    </Stack>
  );
}; */
