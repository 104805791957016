import {
  SimpleGrid,
  Skeleton,
  Image,
  Container,
  Stack,
  useMantineTheme,
} from "@mantine/core";
import { H1 } from "../../Theme/type";

const getChild = (height, IMG) => (
  <Image
    height={height}
    fit="cover"
    src={`/examples/${IMG}`}
  />
);
const BASE_HEIGHT = 560;
const getSubHeight = (children, spacing) =>
  BASE_HEIGHT / children - spacing * ((children - 1) / children);

export function Subgrid() {
  const theme = useMantineTheme();
  return (
    <Container my="xl" fluid={true}>
      <H1>Examples</H1>
      <SimpleGrid cols={4} breakpoints={[{ maxWidth: "xs", cols: 1 }]}>
        {getChild(BASE_HEIGHT,'IMG_70.jpeg')}
        <Stack>
          {getChild(getSubHeight(2, theme.spacing.md),'IMG_180.jpeg')}
          {getChild(getSubHeight(2, theme.spacing.md),'IMG_780_6acfd1f54f.jpeg')}
        </Stack>
        <Stack>
          {getChild(getSubHeight(3, theme.spacing.md),'IMG_320_f565c195cd.jpeg')}
          {getChild(getSubHeight(3, theme.spacing.md),'IMG_1170.jpeg')}
          {getChild(getSubHeight(3, theme.spacing.md),'IMG_70.jpeg')}
        </Stack>
        {getChild(BASE_HEIGHT,'IMG_300.jpeg')}
      </SimpleGrid>
    </Container>
  );
}
