import { useEffect } from "react";
import { useFindSingleTeam } from "../../../Hooks/Content/useFindTeam";
import { find } from "lodash";
export const GPT3Prompt = ({ GAME }) => {
  return (
    <>
      Write a social media match report about a T20 cricket match based on these notes<br />
      ###<br />
      <WhoBattedFirst TEAMS={GAME.teams} Result={GAME.ResultStatement} /> batted
      first <br />
      {GAME.HomeTeam} {GAME.HomeTeamResult} <br />
      {GAME.AwayTeam} {GAME.AwayTeamResult}
      <br />
      ###<br />
      Game Result {GAME.ResultStatement}
      <br />
      <br />
      ###<br />
      {GAME.HomeTeam} Score Card <br />
      First Innings
      {GAME.player_battings.data.map((Batting, i) => {
        return (
          <BattingSentence Batting={Batting} GAME={GAME} PATH="HomeTeam" />
        );
      })}
      {GAME.player_bowlings.data.map((bowling, i) => {
        return <Bowling bowling={bowling} GAME={GAME} PATH="AwayTeam " />;
      })}
      ###<br />
      {GAME.AwayTeam}  Second  Innings<br />
      {GAME.player_battings.data.map((Batting, i) => {
        return (
          <BattingSentence Batting={Batting} GAME={GAME} PATH="AwayTeam" />
        );
      })}
      {GAME.player_bowlings.data.map((bowling, i) => {
        return <Bowling bowling={bowling} GAME={GAME} PATH="HomeTeam" />;
      })}
      ###<br />
      {GAME.HomeTeam} Recent form :{" "}
      <Form Team={GAME.HomeTeam} TEAMS={GAME.teams} />
      <br />
      {GAME.AwayTeam} Recent form :{" "}
      <Form Team={GAME.AwayTeam} TEAMS={GAME.teams} />
      <br />
      ###<br />
    </>
  );
};

const WhoBattedFirst = ({ TEAMS, Result }) => {
  const Lookup = "runs";
  const WhoBattedFirst = Result.includes(Lookup);
  // if WhoBattedFirst, then winner batted first
  // who won?
  const Winner = Result.includes(TEAMS.data[0].attributes.Name);
  const TheWinnerIs = Winner ? 0 : 1;

  return WhoBattedFirst
    ? TEAMS.data[TheWinnerIs].attributes.Name
    : TEAMS.data[TheWinnerIs === 0 ? 1 : 0].attributes.Name;
};

const Form = ({ Team, TEAMS }) => {
  const [TeamName, FindTeamName] = useFindSingleTeam();
  console.log(TEAMS);
  const SELECTED = find(TEAMS.data, (o) => {
    return o.attributes.Name === Team;
  });

  useEffect(() => {
    FindTeamName(SELECTED.id);
  }, []);

  useEffect(() => {
    console.log(TeamName?.attributes?.Form);
  }, [TeamName]);
  if (TeamName === false) return false;
  return TeamName.attributes.Form.trim()
    .split(" ")
    .map((t, i) => {
      return t === "L" ? "Lose " : t === "D" ? "Draw " : "Win ";
    });
};

const Bowling = ({ bowling, GAME, PATH }) => {
  if (bowling.attributes.team.data === null) return false;
  if (bowling.attributes.team.data.attributes.Name === GAME[PATH])
   
      return (
        <>
          {bowling.attributes.player.data.attributes.Name}{" "} |
          {bowling.attributes.BOWLING_Wkts}/{bowling.attributes.BOWLING_Runs} (
          {bowling.attributes.BOWLING_Overs}) <br />
        </>
      );
};

const BattingSentence = ({ Batting, GAME, PATH }) => {
  if (Batting.attributes.team.data === null) return false;
  if (Batting.attributes.team.data.attributes.Name === GAME[PATH])
    
      return (
        <>
          {Batting.attributes.player.data.attributes.Name}{" "} | {" "}
          {Batting.attributes.BATTING_Runs} Runs,{" "} |{" "}
          {Batting.attributes.BATTING_Balls} Balls,{" "} |{" "}
          {Batting.attributes.BATTING_HowOut} <br />
        </>
      );
};
