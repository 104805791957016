import { BTN_ICON_CALLBACK } from "../../../../Theme/Structure/Button/BTNS";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Center, Container, Image, Modal, SimpleGrid } from "@mantine/core";
import { useGetPlayerSingle } from "../../../../Hooks/Content/usePlayer";
import { UISkeletonLines } from "../../../../Components/Global/Skeleton";
import { H3, P } from "../../../../Theme/type";


export const SelectNewPlayerImageFromGallery = (props) => {
  const { PLAYER, SetFromGallery } = props;

  const [GALLERY, GetPlayer] = useGetPlayerSingle();

  const [opened, setOpened] = useState(false);

  useEffect(() => {}, [GALLERY]);

  const showModal = () => {
    //DispatchFUNC
    setOpened(true);
    GetPlayer(PLAYER.ID);
    SetFromGallery(true);
  };

  return (
    <>
      <Modal opened={opened} onClose={() => setOpened(false)} size="xl">
        {!GALLERY ? (
          <FetchingGallery />
        ) : (
          <ShowGallery {...props} GALLERY={GALLERY} setOpened={setOpened} />
        )}
      </Modal>

      <BTN_ICON_CALLBACK
        BG={0}
        COLOR={6}
        CALLBACK={() => {
          showModal(true);
        }}
        ICON="Album"
        TIPOBJ={{
          LABEL: `Select image from Gallery`,
          COLOR: "blue",
          POSITION: "top",
        }}
      />
    </>
  );
};

const FetchingGallery = () => {
  return (
    <>
      <UISkeletonLines />
    </>
  );
};

const ShowGallery = (props) => {
  const { GALLERY, PLAYER, SetFromGallery, setOpened, DispatchFUNC } = props;
  //console.log(props);
  return (
    <Container size="xl">
      <H3>{GALLERY.attributes.Name}</H3>

      {GALLERY.attributes.ImageGallery.data === null ? (
        <P>
          Currently there are no gallery items registered to{" "}
          {GALLERY.attributes.Name}
        </P>
      ) : (
        <GalleryItems
          GALLERYITEMS={GALLERY.attributes.ImageGallery.data}
          PLAYER={PLAYER}
          SetFromGallery={SetFromGallery}
          setOpened={setOpened}
          DispatchFUNC={DispatchFUNC}
          {...props}
        />
      )}
    </Container>
  );
};

const GalleryItems = (props) => {
  const { GALLERYITEMS, SetFromGallery, setOpened, DispatchFUNC, INDEX } =
    props;
  const dispatch = useDispatch();
  const handleChange = (IMAGE, i) => {
    //console.log(INDEX, IMAGE);
    dispatch(DispatchFUNC({ ID: INDEX, url: IMAGE }));
    setOpened(false);

    SetFromGallery(false);
    //PLAYER.IMAGE = IMAGE;
  };

  return (
    <>
      <P>Select a image to use from the gallery below.</P>
      <SimpleGrid
        cols={2}
        breakpoints={[{ minWidth: "md", cols: 4 }]}
        my={"md"}
      >
        {GALLERYITEMS.map((image, i) => {
          return (
            <Container>
              <Image src={image.attributes.url} height={200} />
              <Center>
                <BTN_ICON_CALLBACK
                  BG={0}
                  COLOR={6}
                  CALLBACK={() => {
                    handleChange(image.attributes.url);
                  }}
                  ICON="Click"
                  TIPOBJ={{
                    LABEL: `Use this image`,
                    COLOR: "green",
                    POSITION: "top",
                  }}
                />
              </Center>
            </Container>
          );
        })}
      </SimpleGrid>
    </>
  );
};
