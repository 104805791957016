import { Group, Image } from "@mantine/core";
import { VideoWizardContainer } from "../../../../Theme/Structure/BodyContainer";
import { P } from "../../../../Theme/type";
import { BTN_ICON_CALLBACK } from "../../../../Theme/Structure/Button/BTNS";
import { TrimPlayerName } from "../../../../actions/UX";
import { SelectNewPlayerImageFromGallery } from "./SelectNewPlayerImageFromGallery";

export const SinglePlayerImagewithChangeOption = ({
  IMAGE,
  NAME,
  ChangeImage,
  PLAYER,
  SetFromGallery,
  INDEX,
  DispatchFUNC,
  hasGallery=true
}) => {
  return (
    <VideoWizardContainer BGColor={1}>
      <Image
        width={100}
        height={100}
        radius="sm"
        my={5}
        src={IMAGE} 
        alt="Player Image Missing"
        withPlaceholder
      />
      <P align="center" colorID={3}>
        {TrimPlayerName(NAME)}
      </P>
      <Group position="apart">
        <BTN_ICON_CALLBACK
          BG={0}
          COLOR={6}
          CALLBACK={() => {
            ChangeImage(PLAYER);
          }}
          ICON="Upload"
          TIPOBJ={{
            LABEL: `Upload a new image for ${NAME}`,
            COLOR: "green",
            POSITION: "top",
          }}
        />
        {
          hasGallery ?<SelectNewPlayerImageFromGallery PLAYER={PLAYER} INDEX={INDEX} SetFromGallery={SetFromGallery} DispatchFUNC={DispatchFUNC}/>: false
        }
        
      </Group>
    </VideoWizardContainer>
  );
};
