import { useState } from "react";
import { SelectAGame } from "../../settings/SelectaGame";
import { SelectWhoWins } from "../../settings/SelectWhoWins";
import { SwitchIsLiveStreamed } from "../../settings/SwitchIsLiveStreamed";
import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";
import { Space } from "@mantine/core";

import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";

export const SelectLIVEGame = ({
  setSelectGame,
  SelectedGame,
  SelectedDataset,
  SelectWinner,
  setSelectWinner,
  setSelectIsLiveStreamed,
}) => {
  //setSelectGame={DataCollection.setSelectGame} SelectedGame={DataCollection?.SelectGame} SelectedDataset={DataCollection?.Dataset?.attributes?.Name}
  const [value, setValue] = useState(SelectedGame);
  const [ItemState, setItemState] = useState(0);
  const [DisplaySelected, setDisplaySelected] = useState(false);
  const handleChange = (e) => {
    console.table(e);
    setValue(e.value);
    setSelectGame(e);
    setDisplaySelected(e); 
    setItemState(10);
  };

  if (SelectedDataset === false || SelectedDataset === undefined)
    return (
      <BLOCK_Section_SubHeading
        LABEL="Awaiting Data set"
        BGColor={0}
        Color={DisplaySelected ? 7 : 6}
      />
    );
  return (
    <>
      <BLOCK_Section_SubHeading
        LABEL="Select a Game"
        BGColor={0}
        Color={DisplaySelected ? 7 : 5}
      />

      <VideoWizardContainer DividerSize={ItemState} Padding="xs">
        <SelectAGame
          CALLBACK={handleChange}
          DefaultValue={value}
          SelectedDataset={SelectedDataset}
        />
        <Space h="xl" />
        <SelectWhoWins
          SelectedGame={SelectedGame}
          SelectWinner={SelectWinner}
          setSelectWinner={setSelectWinner}
        />
        <Space h="xl" />
        <SwitchIsLiveStreamed
          setSelectIsLiveStreamed={setSelectIsLiveStreamed}
        />
      </VideoWizardContainer>
    </>
  );
};
