import {useSelector } from "react-redux";
import { H3, P, H4_UPPERCASE, P_Bold } from "../../../../Theme/type";
import { Space, SimpleGrid } from "@mantine/core";
import { VideoWizardContainer } from "../../../../Theme/Structure/BodyContainer";
import { BLOCK_Section_SubHeading } from "../../../../Components/interface/BLOCK_Section_SubHeading";
export const DataCheck = ({ VIDEODATA }) => {
  const RENDER = useSelector((state) => state.RENDER);
  //console.log(RENDER.DATAOBJ.DATAOBJ)
  return (
    <>
      <PlayersLMSStats SelectedPlayer={RENDER.DATAOBJ.DATAOBJ} />
    </>
  );
};
//<PlayersLMSStats SelectedPlayer={VIDEODATA.DATAOBJ} />
const PlayersLMSStats = ({ SelectedPlayer }) => {
 
  const S = SelectedPlayer;
  return (
    <>
      <Space h="xl" />
      <BLOCK_Section_SubHeading LABEL="PLAYING STATS" Color={5} />
      <VideoWizardContainer>
        <SimpleGrid cols={2} spacing="xs">
          <StatRow ROW={["MATCHES", S.MATCHES]} />
          <StatRow ROW={["STATUS", S.PLAYERPROFILELEVEL]} />
        </SimpleGrid>
      </VideoWizardContainer>
      <BLOCK_Section_SubHeading LABEL="BATTING" Color={5} />
      <VideoWizardContainer>
        <SimpleGrid cols={2} spacing="xs">
          <StatRow ROW={["National", S.NATIONALRANKINGBATTING]} />
          <StatRow ROW={["World", S.WORLDRANKINGBATTING]} />
          <StatRow ROW={["INN", S.INNINGS]} />
          <StatRow ROW={["RUNS", S.RUNS]} />
          <StatRow ROW={["AVG", S.BATTINGAVERAGE]} />
          <StatRow ROW={["SR", S.STRIKERATE]} />
          <StatRow ROW={["HS", S.HIGHSCORE]} />
          <StatRow ROW={["50's", S.FIFTIES]} />
          <StatRow ROW={["100's", S.HUNDREDS]} />
        </SimpleGrid>
        </VideoWizardContainer>
      <BLOCK_Section_SubHeading LABEL="Bowling" Color={5} />
      <VideoWizardContainer>
     
        <SimpleGrid cols={2} spacing="xs">
          <StatRow ROW={["National", S.NATIONALRANKINGBOWLING]} />
          <StatRow ROW={["World", S.WORLDRANKINGBOWLING]} />
          <StatRow ROW={["OVERS", S.OVERS]} />
          <StatRow ROW={["WKTS", S.WICKETS]} />
          <StatRow ROW={["AVG", S.BOWLINGAVERAGE]} />
          <StatRow ROW={["ECO", S.ECONOMY]} />
          <StatRow ROW={["BF", S.BESTFIGURES]} />
          <StatRow ROW={["3W", S.W3]} />
          <StatRow ROW={["5W", S.W5]} />
        </SimpleGrid>
      </VideoWizardContainer>
    </>
  );
};

const StatRow = ({ ROW }) => {
  return (
    <>
      <P>{ROW[0]}</P>
      <P_Bold>{ROW[1]}</P_Bold>
    </>
  );
};
