import { createStyles, Text, Container, Title } from "@mantine/core";

import Login from "./Login";

import { VideoWizardContainer } from "../../Theme/Structure/BodyContainer";

const useStyles = createStyles((theme) => ({
  title: {
    color: theme.colors.Public[1],
    fontFamily: `"Roboto", ${theme.fontFamily}`,
  },
}));

export function AuthenticationForm() {
  const { classes } = useStyles();
  return (
    <>
      <Container size={"sm"} py={80}>
        <Title
          order={2}
          className={classes.title}
          align="center"
          mt="md"
          mb={50}
        >
          Sign in
        </Title>

        <VideoWizardContainer>
          <Login />
        </VideoWizardContainer>

        <Text
          size={15}
          weight={100}
          align="center"
          transform="capitalize"
          sx={(theme) => ({
            color: theme.colors.brand[3],
            fontFamily: "Roboto",
          })}
        >
          The social media assistant for LMS franchise owners
        </Text>
      </Container>
    </>
  );
}

{
  /* 
      <Paper className={classes.Copy} radius={0} p={0}>
        <Box
          style={{
            width: "150px",
            paddingBottom: "10px",
          }}
        >
          <LOGOMAINDARK />
        </Box>
        <Box
          style={{
            width: "80%",
            paddingBottom: "10px",
          }}
        >
          <Text
            size={23}
            weight={900}
            transform="uppercase"
            align="center"
            sx={(theme) => ({
              color: theme.colors.brand[3],
              fontFamily: "Roboto",
            })}
          >
            The easy way to create engaging social media content
          </Text>

          <Text
            size={18}
            weight={200}
            transform="uppercase"
            align="center"
            sx={(theme) => ({
              color: theme.colors.brand[3],
              fontFamily: "Roboto",
            })}
          >
           and  remove the weekly hassle of scorecards checking and player lookups.
          </Text>

          <Group position="center">
            <Text
              sx={(theme) => ({
                color: theme.colors.brand[4],
                textAlign: "center",
              })}
            >
              <IconCricket size={60} stroke={1} />
            </Text>

            <Text
              sx={(theme) => ({
                color: theme.colors.brand[4],
                textAlign: "center",
              })}
            >
              <IconNotebook size={60} stroke={1} />
            </Text>
            <Text
              sx={(theme) => ({
                color: theme.colors.brand[4],
                textAlign: "center",
              })}
            >
              <IconChartDots size={60} stroke={1} />
            </Text>
          </Group>
        </Box>
      </Paper>
       */
}
