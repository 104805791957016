import { useCurrentFrame} from 'remotion';
import { Image } from '@mantine/core';
import { useTheme } from 'styled-components'
import {CalendarEvent,Map2,Clock,Building,BuildingSkyscraper,World} from 'tabler-icons-react';
import styled from 'styled-components';

import moment from 'moment';
// Animation
import {interpolateOpacityByFrame} from '../../../../../../../Animation/interpolate'
import {SpringToFrom} from '../../../../../../../Animation/RemotionSpring'
import { FromMiddle,FromBottomToTop } from '../../../../../../../Animation/ClipWipe';
// Components
import {TopUnderlayForCopy} from '../../../../../../GlobalComponents/BottomUnderlayForCopy'
import { H1, H2,H3,H4 } from '../../../../../../utils/Text';

export const FormCalculator = ({D})=>{
    const frame = useCurrentFrame();
    const theme = useTheme();
    const Theme_Stats = theme.Asset.GOTW;

    return(
        <FormOuterContainer
        style={{
            opacity:interpolateOpacityByFrame(frame, 60,75, 0, 1),
            background: theme.radialGradient,
           }}>
                 <H2
                    style={{
                        color: theme.Copy[0],
                        padding:'2%'
                    }}
                    >Current Form</H2>
                <FormPositionContainer
                style={{
                    backgroundColor: Theme_Stats.Fill[1],
                    height: `${SpringToFrom(60,0,100)}px`,
                }}>
                    <H1
                    style={{
                        
                        //transform: `translateX(${SpringToFrom(70,50,0)}px)`,
                        color: theme.Copy[1],
                    }}
                    >{D.Form.split(' ').map((INT,i)=>{
                      //console.log(D.Form.split(' ').length,INT,i++)
                        return(
                           <span
                            key={i}
                            style={{
                                margin:'0 5px',
                                opacity:interpolateOpacityByFrame(frame, (65+(5*(i+1))),(65 +(7*(i+1))), 0, 1),
                               // transform: `translateY(${SpringToFrom(65+(7*i),-1000,0)}px)`
                            }}
                           >{INT}</span> 
                        )
                    })}</H1>
                </FormPositionContainer>
                
        </FormOuterContainer>
    )
}

const FormOuterContainer = styled.div`
display: flex;
flex-direction:column;
align-items: center;
justify-content: flex-end;
align-content: center;
border-radius: 10px;
width: 96%;
background: radial-gradient(97.01% 88.57% at 50% 8.49%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.15) 100%);
box-sizing: border-box;
backdrop-filter: blur(1px);
padding:1%;
margin:50px 0 0 0;
`

const FormPositionContainer = styled.div`
display: flex;
flex-direction:column;
align-items: center;
justify-content: center;
align-content: center;
border-radius: 5px;
width: 100%;
height:100px;
background-color: lightblue;`