import { useSelector } from "react-redux";
import { Text, Table, Image, Avatar } from "@mantine/core";
// Theme
import { H3, P } from "../../../../Theme/type";
import { VideoWizardContainer } from "../../../../Theme/Structure/BodyContainer";
import {
  TABLE_WRAPPER,
  TABLE_THEAD_WRAPPER,
  TABLE_TD,
} from "../../../../Components/interface/Wrapper/Table";
export const DataCheck = () => {
  const RENDER = useSelector((state) => state.RENDER);
  console.log(RENDER.DATAOBJ.DATAOBJ);
  const Labels = {
    Bat1: "Top Run Scorer",
    Bat2: "Top Run Scorer",
    Bat3: "Fastest 50",
    AR: "Best All rounder",
    Bowler1: "Most Wickets",
    Bowler2: "Most Wickets",
    Bowler3: "Best economy bowler",
    Keeper: "Best Keeper/Fielder",
  };

  return (
    <VideoWizardContainer>
      <H3 colorID={5}>Team of the Week</H3>
      <Text size="lg" weight={700}></Text>

      <TABLE_WRAPPER>
        <TABLE_THEAD_WRAPPER
          HEADERS={[" ", "Position", "Player", "Team", " ", " "]}
        />

        <tbody>
          {Object.keys(RENDER.DATAOBJ.DATAOBJ).map((item, i) => {
            //console.log(VIDEODATA.DATAOBJ[item].player?.data?.attributes?.image.data?.attributes?.url)
            return (
              <tr key={i}>
                <TABLE_TD>
                  <Image
                    src={
                      RENDER.DATAOBJ.DATAOBJ[item].player?.data?.attributes
                        ?.image.data?.attributes?.url
                    }
                    height={50}
                    width={50}
                    radius={100}
                  />
                </TABLE_TD>
                <TABLE_TD>
                  <P>{Labels[item]}</P>
                </TABLE_TD>
                <TABLE_TD>
                  <P>
                    {RENDER.DATAOBJ.DATAOBJ[item].player.data.attributes.Name}
                  </P>
                </TABLE_TD>
                <TABLE_TD>
                  <Avatar
                    src={
                      RENDER.DATAOBJ.DATAOBJ[item].team?.data.attributes?.Logo
                        ?.data?.attributes.url
                    }
                  />
                </TABLE_TD>
                <TABLE_TD>
                  <P>
                    {RENDER.DATAOBJ.DATAOBJ[item].team?.data.attributes.Name}
                  </P>
                </TABLE_TD>
                
              </tr>
            );
          })}
        </tbody>
      </TABLE_WRAPPER>
    </VideoWizardContainer>
  );
};
