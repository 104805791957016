import { useCurrentFrame} from 'remotion';
import { Image } from '@mantine/core';
import { useTheme } from 'styled-components'
import {CalendarEvent,Map2,Clock,Building,BuildingSkyscraper,World} from 'tabler-icons-react';
import styled from 'styled-components';

import moment from 'moment';
// Animation
import {interpolateOpacityByFrame} from '../../../../../../Animation/interpolate'
import {SpringToFrom} from '../../../../../../Animation/RemotionSpring'
import { FromMiddle,FromBottomToTop } from '../../../../../../Animation/ClipWipe';
// Components
import {TopUnderlayForCopy} from '../../../../../GlobalComponents/BottomUnderlayForCopy'
import { H1, H2,H3,H4 } from '../../../../../utils/Text';

import {RankingWrapper} from './Components/RankingWrapper'
import {WinLoseRatios} from './Components/WinLoseRatios'
import {FormCalculator} from './Components/FormCalculator'

export const StatsWrapper =({DATA,Duration})=>{ 
    //console.log(DATA.DATAOBJ)
      const frame = useCurrentFrame();
      const D = DATA.DATAOBJ 
      const Durations={
        Begin:0,
        TransitionAfter:240
       }
      return(
      <SeriesContainer>
          <StatsContainer>
              <StatsContainerInner style={{ opacity:interpolateOpacityByFrame(frame, (Duration-15),Duration, 1, 0) ,}}>
                  <StatsColumn D={D.TeamA} Duration={Duration} STYLE='TeamA'/>
              </StatsContainerInner>
              <StatsContainerInner style={{ opacity:interpolateOpacityByFrame(frame, (Duration-10),Duration, 1, 0) ,}}>
                  <StatsColumn D={D.TeamB} Duration={Duration} STYLE='TeamB'/>
              </StatsContainerInner>
              <TopUnderlayForCopy  DATA={DATA} Durations={Durations} BOTTOM={`50px`} POSITIONSTARTFROMTOP={-50} POSITIONFROMTOP={0}/>
          </StatsContainer>
      </SeriesContainer>)
   
  } 



  const SeriesContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin:0;
    padding:0;
    width:100%;
    height: 100%;
    position: relative;
    flex-wrap: wrap;
    list-style:none;
    
`

const StatsContainer=styled.div`
   
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top:150px;
    
`
const StatsContainerInner=styled.div`
    /* background: radial-gradient(97.01% 88.57% at 50% 8.49%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.15) 100%);
    border: 1px solid rgba(255, 255, 255, 0.5); */
    box-sizing: border-box;
    backdrop-filter: blur(1px);
    width:48%;
    margin:1%;
    height:98%;
    flex-direction:column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    margin-top:20px;
    border-radius: 20px;
    z-index:1000;
`



const StatsColumn=({D,STYLE})=>{
    const frame = useCurrentFrame();
    const theme = useTheme();
    const Theme_Stats = theme.GOTW.Stats

    return(
        <>
            <H1
                style={{
                    fontSize:'3em',
                    lineHeight:'1em',
                    minHeight:'100px',
                    display:'flex',
                    justifyContent:'center',
                    color:Theme_Stats[STYLE].TeamName
                }}>{D.Name}</H1>

    
            <RankingWrapper D={D} STYLE={STYLE}/>
            <WinLoseRatios D={D} STYLE={STYLE}/>
            <FormCalculator D={D} STYLE={STYLE} />
                <Image  
                    src={D.LOGO}
                    radius={220}
                    width={220}
                    height={220}
                    style={{
                        marginTop: '0px',
                        marginBottom: '0px',
                        position: 'absolute',
                        bottom: '10px',
                       
                        transform: `translateY(${SpringToFrom(3,250,0,'Springy100')}px)`
                    }}
                />
        </>
    )
}