/* eslint-disable react/jsx-pascal-case */
import { Container } from "@mantine/core";

import { useSelector } from "react-redux";

import { getTimeTaken } from "../../../actions/UX";
import moment from "moment";
// Hooks

// Compoennts
import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";
import { P } from "../../../Theme/type";

import { orderBy } from "lodash";

// UI ELEMENTS
import { BLOCK_Section_SubHeading_INVERT } from "../../../Components/interface/BLOCK_Section_SubHeading";
import {
  TABLE_WRAPPER,
  TABLE_THEAD_WRAPPER,
  TABLE_TD,
} from "../../../Components/interface/Wrapper/Table";
export const Last5Updates = () => {
  const UXSTATE = useSelector((state) => state.UXSTATE);

  const GetUpdates = () => {
    const UPDATES = orderBy(
      UXSTATE?.SelectedFranchise?.attributes.franchise_update_requests.data,
      ["attributes.createdAt"],
      ["desc"]
    );
    return UPDATES.slice(0, 10);
  };

  return (
    <>
      <BLOCK_Section_SubHeading_INVERT LABEL="Last 5 Updates" Color={5} />

      <Container size="lg" p={0}>
        <VideoWizardContainer>
          <TABLE_WRAPPER>
            <TABLE_THEAD_WRAPPER HEADERS={["Update", "Status", "Time Taken"]} />

            <tbody>
              {GetUpdates()
                .slice(0, 5)
                .map((u, i) => {
                  return (
                    <tr key={i}>
                      <TABLE_TD>
                        <P>
                          {moment(u.attributes.createdAt).format(
                            "DD MMMM YYYY"
                          )}
                        </P>
                      </TABLE_TD>
                      <TABLE_TD>
                        <P>{u.attributes.STAGE}</P>
                      </TABLE_TD>
                      <TABLE_TD>
                        <P>{getTimeTaken(u.attributes)}</P>
                      </TABLE_TD>
                    </tr>
                  );
                })}
            </tbody>
          </TABLE_WRAPPER>
        </VideoWizardContainer>
      </Container>
    </>
  );
};
