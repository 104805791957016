import { useEffect, useState } from "react";
import { orderBy } from "lodash";
import { useGetPlayersByLMSID } from "../../../../../Hooks/Content/usePlayer";

import {
  MergeTop5APICallBack,
  CleanTop5DATA,
} from "../../../../../actions/EvaluationUtils";
const OffsetTime = 210;
const VideoOBJ = {
  OpeningSceneDuration: 90,
  EndCreditsDuration: 120,
  SponsorTiming: 120,
  ItemOffset: OffsetTime,
  VideoBodyTiming: 0,
  VideoDurationinFrames: 90 + 120 + OffsetTime * 5 + 120,
  VideoDivisionTimgs: 0,
  RenderStill: [],
};

export const useBuildVideoData = () => {
  const [Player, GetPlayer] = useGetPlayersByLMSID();
  const VideoSettings = { ...VideoOBJ };
  const [VIDEODATA, setVIDEODATA] = useState({ VideoSettings });

  // HOOK TO FIND PLAYERS SELECTED

  const VideoTiming = (OBJ) => {
    const SECTIONS = [];
    OBJ.map((L, i) => {
      const ANIMATEIN = L.LeagueTable.length * 30;

      SECTIONS.push(ANIMATEIN * 2);
    });

    VideoOBJ.VideoDivisionTimings = SECTIONS;
    VideoOBJ.VideoBodyTiming = SECTIONS.reduce((a, b) => a + b, 0);
    VideoOBJ.VideoDurationinFrames =
      90 + 120 + SECTIONS.reduce((a, b) => a + b, 0) + 120;

    return VideoOBJ;
  };

  const addLogoToLeagueTable = (obj) => {
    obj.attributes.LeagueTable.map((team) => {
      const logo = obj.attributes.teams.data.find(
        (t) => t.attributes.Name === team.Team
      );
      if (logo) {
        team.LOGO = logo?.attributes.Logo.data?.attributes.url;
        team.Form = logo?.attributes.Form;
        team.Ranking = logo?.attributes.World_Rank;
      }
    });
    return obj;
  };


  function sortObjectByName(obj) {
    // Convert the object to an array
    const objArray = Object.values(obj);
    // Sort the array by the "Name" property
    objArray.sort((a, b) => {
      if (a.Name < b.Name) {
        return -1;
      }
      if (a.Name > b.Name) {
        return 1;
      }
      return 0;
    });
    return objArray;
  }
  
  


  // Main Function
  const FindVIDEODATA = async (DATA) => {
    // Send QUERY DATA to MergeTop5APICallBack for sorting
    // MergeTop5APICallBack(DATA,VARIABLE TO GROUP AROUND)

    const OBJ = [];
    DATA.map((div, i) => {
      const updatedObj = addLogoToLeagueTable(div);

      OBJ.push({
        Name: updatedObj.attributes.Name,
        LeagueTable: updatedObj.attributes.LeagueTable,
 
      });
    });

    console.log(OBJ)
    console.log(sortObjectByName(OBJ))

    const NewVideoOBJ = await VideoTiming(OBJ);

    setVIDEODATA({
      ...VIDEODATA,
      DATAOBJ: { ...sortObjectByName(OBJ) },
      VideoSettings: { ...NewVideoOBJ },
    });
  };

  useEffect(() => {}, [VIDEODATA]);

  return [VIDEODATA, FindVIDEODATA];
};
