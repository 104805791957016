import moment from "moment";
import { IdOff } from "tabler-icons-react";

const DisplayDates = (FromDate) => {
  const START = moment(FromDate[0]).format("DD MMMM YYYY");
  const END = moment(FromDate[1]).format("DD MMMM YYYY");
  return START === END ? `${START}` : `${START} - ${END}`;
};

export const NameVideo = (Filters) => {
  // This need to be more robust.
  // it may need to create a name based on the asset type
  //console.log('NameVideo')
  //console.log(Filters.FromDate)
  const ASSETSNAME = Filters.showAsset.Name.toUpperCase();
  const FromDate = Filters.FromDate;
  const DATASET = Filters.SeasonID.attributes.Name
    ? Filters.SeasonID.attributes.Name.toUpperCase()
    : "";
  const PrincipalLabel =
    Filters.VideoPrincipalLabel === undefined
      ? ""
      : Filters.VideoPrincipalLabel;

  const TITLEDATE =
    FromDate === undefined || FromDate === false
      ? moment(Date.now()).format("DD MMMM YYYY")
      : DisplayDates(FromDate);

  //console.log(ASSETSNAME, TITLEDATE.toUpperCase(),DATASET,TEMPLATE,THEME)
  return `${PrincipalLabel.toUpperCase()} ${TITLEDATE.toUpperCase()} ${ASSETSNAME} ${DATASET}`;
};

export const NameTournamentVideo = (Filters) => {
  // This need to be more robust.
  // it may need to create a name based on the asset type
  //console.log('NameVideo')
  // console.log(Filters.SeasonID.Name, Filters.showAsset.Name, moment(Filters.SeasonID.START).format('DD MMMM YYYY') )
  const ASSETSNAME = Filters.showAsset.Name.toUpperCase();
  const FromDate = Filters.FromDate;
  //const DATASET = Filters.SeasonID.attributes.Name ? Filters.SeasonID.attributes.Name.toUpperCase() :''
  const PrincipalLabel = Filters.SeasonID.Name;

  const TITLEDATE =
    FromDate === undefined || FromDate === false
      ? moment(Date.now()).format("DD MMMM YYYY")
      : DisplayDates(FromDate);

  //console.log(ASSETSNAME, TITLEDATE.toUpperCase(),DATASET,TEMPLATE,THEME)

  return `${PrincipalLabel.toUpperCase()} ${ASSETSNAME} ${TITLEDATE.toUpperCase()}`;
};

export const outputName = (AssetType, FromDate, Season) => {
  const Name = Season.attributes?.Name
    ? Season.attributes.Name.toUpperCase()
    : "";
  return `${AssetType}_${Name}`;
};

export const FindTeamLogoURI = (TEAM) => {
  //console.log(TEAM)
  const FORMATS = TEAM?.attributes.Logo.data?.attributes.formats;

  if (FORMATS === null) return false;
  if (FORMATS?.medium) return FORMATS?.medium.url;
  if (FORMATS?.small) return FORMATS?.small.url;
  if (FORMATS?.thumbnail) return FORMATS?.thumbnail.url;
};

export const FindIMGFORMAT = (FORMATS) => {
  //console.log(FORMATS)
  if (FORMATS === null || FORMATS === undefined)
    return {
      url: "https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/default_Large_d82705ff67.png",
    };
  if (FORMATS?.medium) return FORMATS?.medium;
  if (FORMATS?.small) return FORMATS?.small;
  if (FORMATS?.thumbnail) return FORMATS?.thumbnail;
};

/** GOTW check to see if the game has the corrent data */

export const hasData = (VIDEODATA) => {
  //console.log(VIDEODATA)
  const TOTAL = [];
  const TEAMS = ["TeamA", "TeamB"];

  const ARR1 = ["Runs", "SR"];
  const ARR2 = ["ECO", "WKTS"];

  TEAMS.map((t, i) => {
    const BATA = VIDEODATA.DATAOBJ[t].batsmanToWatch;
    const BALLA = VIDEODATA.DATAOBJ[t].bowlersToWatch;
    ARR1.map((v, i) => {
      TOTAL.push(BATA[v].length);
    });
    ARR2.map((v, i) => {
      TOTAL.push(BALLA[v].length);
    });
  });

  return TOTAL.reduce((a, b) => a + b, 0) === 8 ? true : false;
};



/** NAMING VIDEOS VERSION 2 */

const ReturnDates=(DATERANGE)=>{
  //
  return DATERANGE !== undefined ?`${DATERANGE[0]} ${DATERANGE[1]}`:' '
}

export const VideoTitleVersion2 = (RENDER,UXSTATE) => {
console.log(RENDER)
  const OBJ={
    POTDBATTING: `${RENDER.ASSET.Asset.ComponentName} ${UXSTATE.SelectedFranchise.attributes.Name} ${RENDER.INPUTDATA?.DATASET?.Name} ${ReturnDates(RENDER.INPUTDATA?.DATERANGE)}`,
    POTDBOWLING: `${RENDER.ASSET.Asset.ComponentName} ${UXSTATE.SelectedFranchise.attributes.Name} ${RENDER.INPUTDATA?.DATASET?.Name} ${ReturnDates(RENDER.INPUTDATA?.DATERANGE)}`,
    MATCHDAYRESULTS:`${RENDER.ASSET.Asset.ComponentName} ${UXSTATE.SelectedFranchise.attributes.Name} ${RENDER.INPUTDATA?.DATASET?.Name} ${ReturnDates(RENDER.INPUTDATA?.DATERANGE)}`,
    UPCOMINGFIXTURES:`${RENDER.ASSET.Asset.ComponentName} ${UXSTATE.SelectedFranchise.attributes.Name} ${RENDER.INPUTDATA?.DATASET?.Name} ${ReturnDates(RENDER.INPUTDATA?.DATERANGE)}`,
    TEAMOFTHEWEEK:`${RENDER.ASSET.Asset.ComponentName} ${UXSTATE.SelectedFranchise.attributes.Name} ${RENDER.INPUTDATA?.DATASET?.Name} ${ReturnDates(RENDER.INPUTDATA?.DATERANGE)}`,
    THISWEEKINLMS:`${RENDER.ASSET.Asset.ComponentName} ${UXSTATE.SelectedFranchise.attributes.Name} ${ReturnDates(RENDER.INPUTDATA?.DATERANGE)}`,
    TOP5RUNS:`${RENDER.ASSET.Asset.ComponentName} ${UXSTATE.SelectedFranchise.attributes.Name} ${RENDER.INPUTDATA?.DATASET?.Name} ${Math.floor(Math.random() * 1000000)}`,
    TOPFIVEFOURS:`${RENDER.ASSET.Asset.ComponentName} ${UXSTATE.SelectedFranchise.attributes.Name} ${RENDER.INPUTDATA?.DATASET?.Name} ${Math.floor(Math.random() * 1000000)}`,
    LEADERBOARDSIXES:`${RENDER.ASSET.Asset.ComponentName} ${UXSTATE.SelectedFranchise.attributes.Name} ${RENDER.INPUTDATA?.DATASET?.Name} ${Math.floor(Math.random() * 1000000)}`,
    TOP5CATCHES:`${RENDER.ASSET.Asset.ComponentName} ${UXSTATE.SelectedFranchise.attributes.Name} ${RENDER.INPUTDATA?.DATASET?.Name} ${Math.floor(Math.random() * 1000000)}`,
    TOP5WICKETS:`${RENDER.ASSET.Asset.ComponentName} ${UXSTATE.SelectedFranchise.attributes.Name} ${RENDER.INPUTDATA?.DATASET?.Name} ${Math.floor(Math.random() * 1000000)}`,
    TOP5FASTESTFIFTIES:`${RENDER.ASSET.Asset.ComponentName} ${UXSTATE.SelectedFranchise.attributes.Name} ${RENDER.INPUTDATA?.DATASET?.Name} ${Math.floor(Math.random() * 1000000)}`,
    PLAYERMILESTONE:`${RENDER.ASSET.Asset.ComponentName} ${Math.floor(Math.random() * 1000000)}`,
    PLAYEROFTHEMATCH:`${RENDER.ASSET.Asset.ComponentName} ${Math.floor(Math.random() * 1000000)}`,
    GAMEOFTHEWEEKLITE:`${RENDER.ASSET.Asset.ComponentName} ${RENDER.DATAOBJ.DATAOBJ?.TeamA?.Name} vs ${RENDER.DATAOBJ.DATAOBJ?.TeamB?.Name}`,
    GAMEOFTHEWEEK:`${RENDER.ASSET.Asset.ComponentName} ${RENDER.DATAOBJ.DATAOBJ?.TeamA?.Name} vs ${RENDER.DATAOBJ.DATAOBJ?.TeamB?.Name}`,
    DIVISIONWINNERS:`${RENDER.ASSET.Asset.ComponentName} ${RENDER.DATAOBJ?.General?.Team}`,
    TEAMSEASONREVIEW:`${RENDER.ASSET.Asset.ComponentName} ${RENDER.DATAOBJ?.General?.Team}`,
    TOP5BATTINGRANKINGS:`${RENDER.ASSET.Asset.ComponentName} ${UXSTATE.SelectedFranchise.attributes.Name} ${RENDER.INPUTDATA?.DATASET?.Name} ${Math.floor(Math.random() * 1000000)}`,
    TOP5BOWLINGRANKINGS:`${RENDER.ASSET.Asset.ComponentName} ${UXSTATE.SelectedFranchise.attributes.Name} ${RENDER.INPUTDATA?.DATASET?.Name} ${Math.floor(Math.random() * 1000000)}`,
    MATCHREVIEW:`${RENDER.ASSET.Asset.ComponentName} ${RENDER.DATAOBJ.DATAOBJ?.TeamA?.Name} vs ${RENDER.DATAOBJ.DATAOBJ?.TeamB?.Name}`,
    LEAGUESTANDINGS:`${RENDER.ASSET.Asset.ComponentName} ${UXSTATE.SelectedFranchise.attributes.Name} ${RENDER.INPUTDATA?.DATASET?.Name}`,
  }

  return OBJ[RENDER.ASSET.AssetType]

};

export const VideoOutPutNameVersion2 = (RENDER, UXSTATE) => {
  //console.log(RENDER)
  return `${RENDER.ASSET.Asset.ComponentName}_ID${Math.floor(Math.random() * 1000000)}`;
};