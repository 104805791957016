import { useEffect, useState } from "react";

import { SelectAudioOption } from "../../settings/SelectAudioOption";
import { P } from "../../../Theme/type";
import { VideoWizardContainer } from "../../../Theme/Structure/BodyContainer";
import { Group } from "@mantine/core";

import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";

export const SelectAudio = ({ setAudio, Audio }) => {
  const [value, setValue] = useState(Audio);
  const [DisplaySelected, setDisplaySelected] = useState(false);
  const [ItemState, setItemState] = useState(0);

  const handleChange = (e) => {
    console.log(e);
    setValue(e);
    setAudio(e);
    setDisplaySelected(e);
    setItemState(10);
  };

  useEffect(() => {
    console.log(Audio);
  }, [Audio]);

  return (
    <>
      <BLOCK_Section_SubHeading
        LABEL="Select Audio"
        BGColor={0}
        Color={3} 
      />

        <Group position="apart" spacing="xl" mt={10} mb={20}  grow>
          <SelectAudioOption CALLBACK={handleChange} DefaultValue={value} />
        </Group>
      
    </>
  );
};
