import { Sequence, useCurrentFrame, useVideoConfig, spring } from "remotion";

import { GameScoreCard } from "./Scorecard";
import { PlayerPerformanceDetails } from "./PlayerDetails";
import { GLOBALUL, MatchDayLI } from "../../../utils/html";
import { FrostedItem, FullWidthWrapper } from "../../../utils/Containers";
// fixtures

export const FixtureResultsUL = ({
  LISTFIXTURES,
  VideoSettings,
  LeagueDurations,
  RenderType,
}) => {
  //console.log(VideoSettings)
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const SpringConfig = {
    Springy900: {
      damping: 10,
      stiffness: 200,
      mass: 1,
    },
    Springy500: {
      damping: 10,
      stiffness: 200,
      mass: 1,
    },
    Springy100: {
      damping: 10,
      stiffness: 150,
      mass: 0.3,
    },
    Stiff: {
      damping: 80,
      stiffness: 100,
      mass: 0.1,
    },
  };

  const StaggerSpringIn = (int) => {
    return spring({
      fps,
      from: 0,
      to: 1,
      frame: frame - int,
      config: SpringConfig.Springy100,
    });
  };

  // Crewate DURATION func
  const CalcDuration = (FixtureDuration, Page, PageInt) => {
    let Deduction;
    const Int = Page.length;
    let TotalLeagueTime = FixtureDuration * Int;
    let InBuffer = VideoSettings.ScoreCardDuration;

    Deduction = InBuffer * (Int - 1);
    let TotalTime = TotalLeagueTime - Deduction;
    //console.log(Int,TotalLeagueTime, PageInt)
    return TotalTime;
  };

  /* const CalcItemDuration = (FixtureDuration,Page, PageInt)=>{
  let Deduction;
  const Int = Page.length
  let TotalLeagueTime = (FixtureDuration*3)+VideoSettings.ResultDuration
  let InBuffer = VideoSettings.ScoreCardDuration
 
  Deduction = (InBuffer*(Int-1))
  let TotalTime = (TotalLeagueTime-Deduction);
//console.log(Int,TotalLeagueTime, PageInt, TotalTime)
  return TotalLeagueTime
} */

  const CalcPageStartPoint = (FixtureDuration, i) => {
    if (i === 0) return 0;
    const Int = VideoSettings.FixturesPerPage * i;
    const GrossStartPoint = FixtureDuration * Int;
    const Deduction =
      (Int - 1) * VideoSettings.ScoreCardDuration +
      VideoSettings.ScoreCardDuration;
    //console.log( GrossStartPoint,Deduction,  (GrossStartPoint-Deduction),frame)
    return GrossStartPoint - Deduction;
  };

  const sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  };

  const FixturePages = sliceIntoChunks(
    LISTFIXTURES,
    VideoSettings.FixturesPerPage
  );

  const FixtureDuration =
    VideoSettings.FixtureAnimateIn +
    VideoSettings.FixtureAnimateOut +
    (VideoSettings.ResultDuration + VideoSettings.ScoreCardDuration);

  return (
    <FullWidthWrapper>
      {FixturePages.map((Page, i) => {
        //console.log('LeagueDurations',LeagueDurations,i,FixturePages[i].length,frame)
        return (
          <Sequence
            key={i}
            from={CalcPageStartPoint(FixtureDuration, i)}
            durationInFrames={CalcDuration(FixtureDuration, Page, i)}
          >
            <GLOBALUL>
              {Page.map((fixture, ii) => {
                return (
                  <MatchDayLI
                    key={ii}
                    style={{
                      transform: `scale(${StaggerSpringIn(
                        VideoSettings.ScoreCardDuration * ii + 0
                      )})`,
                    }}
                  >
                    <Sequence
                      from={VideoSettings.ScoreCardDuration * ii}
                      durationInFrames={FixtureDuration * 2.5}
                    >
                      <Sequence
                        from={0}
                        durationInFrames={VideoSettings.ResultDuration}
                      >
                        <GameScoreCard
                          GAME={fixture}
                          VideoSettings={VideoSettings}
                          _INT={ii}
                          RenderType={RenderType}
                        />
                      </Sequence>

                      <Sequence
                        from={VideoSettings.ResultDuration}
                        durationInFrames={FixtureDuration * 2.5}
                      >
                        <PlayerPerformanceDetails
                          RenderType={RenderType}
                          GAME={fixture}
                          VideoSettings={VideoSettings}
                          _INT={ii}
                        />
                      </Sequence>
                      <FrostedItem />
                    </Sequence>
                  </MatchDayLI>
                );
              })}
            </GLOBALUL>
          </Sequence>
        );
      })}
    </FullWidthWrapper>
  );
};
