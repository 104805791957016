import { useState } from "react";
import { ENV } from "../../Config/RunTimeConfig";
import { parseCookies } from "nookies";
const qs = require("qs");

export const useFetchAsset = (ctx) => {
  const [FindAsset, setFindAsset] = useState(false);
  const jwt = parseCookies(ctx).jwt;

  const ConfigAsset = (AssetResponse) => {
    //console.log(AssetResponse)
    let ARR = {
      ...{ id: AssetResponse.data.id },
      ...AssetResponse.data.attributes,
    };
    return ARR;
  };

  const FetchAsset = async (_ID) => {
    const query = qs.stringify(
      {
        populate: [
          "HeaderImage",
          "Examples",
          "orders",
          "navigation",
          "hashtags",
          "templates",
          "templates.Icon",
          "templates.themes",
          "video_components",
          "video_components.ratio",
          "video_components.social_media_platforms",
          "video_components.social_media_platforms.Logo",
          "suggested_assets",
          "media_editors"
        ],
      },
      {
        encodeValuesOnly: true,
      }
    );
    const res = await fetch(`${ENV.API_URL}/api/assets/${_ID}?${query}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    const AssetResponse = await res.json();
    //console.log(AssetResponse)

    setFindAsset(ConfigAsset(AssetResponse));
  };
  return [FindAsset, FetchAsset];
};

export const useFetchAssets = (ctx) => {
  const [FindAssets, setFindAssets] = useState(false);
  const jwt = parseCookies(ctx).jwt;
  const FetchAssets = async (PATH) => {
    //Weekly
    const query = qs.stringify(
      {
        filters: {
          navigation: {
            Name: { $eq: PATH },
          },
        },

        populate: {
         
          HeaderImage: {
            populate: ["*"],
          },
          Examples: {
            populate: ["*"],
          },
          Cover: {
            populate: ["*"],
          },
          navigation: {
            populate: ["*"],
          },
          templates: {
            populate: ["Icon", "themes"],
          },
          video_components: {
            populate: [
              "ratio",
              "social_media_platforms",
              "social_media_platforms",
              "social_media_platforms.Logo",
            ],
          },
          suggested_assets: { populate: ["*"] },
          orders: { fields: ["id"] },
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

  //console.log(PATH);
    const res = await fetch(`${ENV.API_URL}/api/assets?${query}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    const loginResponse = await res.json();

  //console.log(loginResponse);
    setFindAssets(loginResponse.data);
  };
  return [FindAssets, FetchAssets];
};
/*
  
            populate: [
                'HeaderImage',
                'Examples',
                'Cover',
                'orders',
                'navigation',
                'templates',
                'templates.Icon',
                'templates.themes',
                'video_components',
                'video_components.ratio',
                'video_components.social_media_platforms',
                'video_components.social_media_platforms.Logo',
                'suggested_assets'],  
                
*/

export const useFetchSuggestedAssets = (ctx) => {
  const [SuggestedAssets, setSuggestedAssets] = useState(false);
  const jwt = parseCookies(ctx).jwt;
  const FetchSuggestedAssets = async () => {
    const query = qs.stringify(
      {
        populate: ["assets", "assets.HeaderImage "],
      },
      {
        encodeValuesOnly: true,
      }
    );
    const res = await fetch(`${ENV.API_URL}/api/suggested-assets?${query}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    const Response = await res.json();

    //console.log(Response)
    setSuggestedAssets(Response.data);
  };
  return [SuggestedAssets, FetchSuggestedAssets];
};
