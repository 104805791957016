import { ThemeProvider } from "styled-components";

// Import Design Templates for MATCHDAYRESULT.
// Add new deisng patterns below
// GLASS
import { TEAMSEASONALREVIEW_4by5 } from "../../theme/Templates/GLASS/index";
import { TOURNAMENT_WINNERS } from "../../theme/Templates/TOURNAMENT_GLASS/index";
// END

export const Build_TEAMSEASONALREVIEW_4by5 = (props) => {
  const { RENDER } = props;
  const TEMPLATES = {
    Glass: <TEAMSEASONALREVIEW_4by5 RENDER={RENDER} />,
   
  };
  return (
    <ThemeProvider theme={RENDER.THEME.VideoTheme.Theme}>
      {TEMPLATES[RENDER.THEME.VideoTemplate]}
    </ThemeProvider>
  );
};
