/* eslint-disable react/jsx-pascal-case */

import { useNavigate } from "react-router-dom";
import { destroyCookie } from "nookies";
// Hooks

// Components

import { Container } from "@mantine/core";

import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";
import { BTN_CTA } from "../../Theme/Structure/Button/BTNS";

const Logout = (ctx) => {
  let navigate = useNavigate();

  //const AUTH = useSelector((state) => state.AUTH);

  const handle = () => {
    destroyCookie(ctx, "jwt");
    destroyCookie(ctx, "AUTHID");
    navigate("/", { replace: true });
    window.location.reload();
  };

  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Logout",
          ICON: "Login",
          DESCRIPTION: "Logout of this Account",
          TOOLBAR: false,
        }}
      />
      <Container size="lg"  p={0}>
        <BTN_CTA CALLBACK={handle} LABEL={"Logout"} fullWidth={false} />
      </Container>
    </>
  );
};
export default Logout;
