import { createStyles, Title, Text, Button, Container } from "@mantine/core";
/* import { Dots } from './Dots'; */

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: 120,
    paddingBottom: 80,

    "@media (max-width: 755px)": {
      paddingTop: 80,
      paddingBottom: 60,
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
  },

  dots: {
    position: "absolute",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[1],

    "@media (max-width: 755px)": {
      display: "none",
    },
  },

  dotsLeft: {
    left: 0,
    top: 0,
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: 40,
    letterSpacing: -1,
    color: theme.colors.Public[1],
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    "@media (max-width: 520px)": {
      fontSize: 28,
      textAlign: "left",
    },
  },

  highlight: {
    color: theme.colors.Public[2],
  },

  description: {
    textAlign: "center",

    "@media (max-width: 520px)": {
      textAlign: "left",
      fontSize: theme.fontSizes.md,
    },
  },

  controls: {
    marginTop: theme.spacing.lg,
    display: "flex",
    justifyContent: "center",

    "@media (max-width: 520px)": {
      flexDirection: "column",
    },
  },

  control: {
    "&:not(:first-of-type)": {
      marginLeft: theme.spacing.md,
    },

    "@media (max-width: 520px)": {
      height: 42,
      fontSize: theme.fontSizes.md,

      "&:not(:first-of-type)": {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },
  },
}));

export function SponsorsBanner() {
  const { classes, theme } = useStyles();

  return (
    <Container className={classes.wrapper} size={"lg"}>
      <SVGICON className={classes.dots} style={{ left: 0, top: 0 }} />

      <div className={classes.inner}>
        <Title className={classes.title}>
          Create{" "}
          <Text component="span" className={classes.highlight} inherit>
            revenue streams
            <br />
          </Text>{" "}
          through sponsored videos
        </Title>

        <Container p={0} size={600}>
          <Text size="lg" color="dimmed" className={classes.description}>
            Have a local sponsor you want to showcase? <br />
            No Problem, Turn any video into sponsored content with our{" "}
            <Text component="span" className={classes.highlight} inherit>
              add a sponsor feature
            </Text>
          </Text>
        </Container>

        {/* <div className={classes.controls}>
          <Button
            className={classes.control}
            size="lg"
            variant="default"
            color="gray"
          >
            Book a demo
          </Button>
          <Button className={classes.control} size="lg">
            Purchase a license
          </Button>
        </div> */}
      </div>
    </Container>
  );
}
const SVGICON = () => {
  const { classes, theme } = useStyles();
  return (
    <svg
      fill={theme.colors.Public[2]}
      viewBox="0 0 185 185"
      width="185"
      height="185"
      className="mantine-1ymp0dx"
      style={{ right: "0px; top: 60px" }}
    >
      <rect width="5" height="5" rx="2.5"></rect>
      <rect width="5" height="5" x="60" rx="2.5"></rect>
      <rect width="5" height="5" x="120" rx="2.5"></rect>
      <rect width="5" height="5" x="20" rx="2.5"></rect>
      <rect width="5" height="5" x="80" rx="2.5"></rect>
      <rect width="5" height="5" x="140" rx="2.5"></rect>
      <rect width="5" height="5" x="40" rx="2.5"></rect>
      <rect width="5" height="5" x="100" rx="2.5"></rect>
      <rect width="5" height="5" x="160" rx="2.5"></rect>
      <rect width="5" height="5" x="180" rx="2.5"></rect>
      <rect width="5" height="5" y="20" rx="2.5"></rect>
      <rect width="5" height="5" x="60" y="20" rx="2.5"></rect>
      <rect width="5" height="5" x="120" y="20" rx="2.5"></rect>
      <rect width="5" height="5" x="20" y="20" rx="2.5"></rect>
      <rect width="5" height="5" x="80" y="20" rx="2.5"></rect>
      <rect width="5" height="5" x="140" y="20" rx="2.5"></rect>
      <rect width="5" height="5" x="40" y="20" rx="2.5"></rect>
      <rect width="5" height="5" x="100" y="20" rx="2.5"></rect>
      <rect width="5" height="5" x="160" y="20" rx="2.5"></rect>
      <rect width="5" height="5" x="180" y="20" rx="2.5"></rect>
      <rect width="5" height="5" y="40" rx="2.5"></rect>
      <rect width="5" height="5" x="60" y="40" rx="2.5"></rect>
      <rect width="5" height="5" x="120" y="40" rx="2.5"></rect>
      <rect width="5" height="5" x="20" y="40" rx="2.5"></rect>
      <rect width="5" height="5" x="80" y="40" rx="2.5"></rect>
      <rect width="5" height="5" x="140" y="40" rx="2.5"></rect>
      <rect width="5" height="5" x="40" y="40" rx="2.5"></rect>
      <rect width="5" height="5" x="100" y="40" rx="2.5"></rect>
      <rect width="5" height="5" x="160" y="40" rx="2.5"></rect>
      <rect width="5" height="5" x="180" y="40" rx="2.5"></rect>
      <rect width="5" height="5" y="60" rx="2.5"></rect>
      <rect width="5" height="5" x="60" y="60" rx="2.5"></rect>
      <rect width="5" height="5" x="120" y="60" rx="2.5"></rect>
      <rect width="5" height="5" x="20" y="60" rx="2.5"></rect>
      <rect width="5" height="5" x="80" y="60" rx="2.5"></rect>
      <rect width="5" height="5" x="140" y="60" rx="2.5"></rect>
      <rect width="5" height="5" x="40" y="60" rx="2.5"></rect>
      <rect width="5" height="5" x="100" y="60" rx="2.5"></rect>
      <rect width="5" height="5" x="160" y="60" rx="2.5"></rect>
      <rect width="5" height="5" x="180" y="60" rx="2.5"></rect>
      <rect width="5" height="5" y="80" rx="2.5"></rect>
      <rect width="5" height="5" x="60" y="80" rx="2.5"></rect>
      <rect width="5" height="5" x="120" y="80" rx="2.5"></rect>
      <rect width="5" height="5" x="20" y="80" rx="2.5"></rect>
      <rect width="5" height="5" x="80" y="80" rx="2.5"></rect>
      <rect width="5" height="5" x="140" y="80" rx="2.5"></rect>
      <rect width="5" height="5" x="40" y="80" rx="2.5"></rect>
      <rect width="5" height="5" x="100" y="80" rx="2.5"></rect>
      <rect width="5" height="5" x="160" y="80" rx="2.5"></rect>
      <rect width="5" height="5" x="180" y="80" rx="2.5"></rect>
      <rect width="5" height="5" y="100" rx="2.5"></rect>
      <rect width="5" height="5" x="60" y="100" rx="2.5"></rect>
      <rect width="5" height="5" x="120" y="100" rx="2.5"></rect>
      <rect width="5" height="5" x="20" y="100" rx="2.5"></rect>
      <rect width="5" height="5" x="80" y="100" rx="2.5"></rect>
      <rect width="5" height="5" x="140" y="100" rx="2.5"></rect>
      <rect width="5" height="5" x="40" y="100" rx="2.5"></rect>
      <rect width="5" height="5" x="100" y="100" rx="2.5"></rect>
      <rect width="5" height="5" x="160" y="100" rx="2.5"></rect>
      <rect width="5" height="5" x="180" y="100" rx="2.5"></rect>
      <rect width="5" height="5" y="120" rx="2.5"></rect>
      <rect width="5" height="5" x="60" y="120" rx="2.5"></rect>
      <rect width="5" height="5" x="120" y="120" rx="2.5"></rect>
      <rect width="5" height="5" x="20" y="120" rx="2.5"></rect>
      <rect width="5" height="5" x="80" y="120" rx="2.5"></rect>
      <rect width="5" height="5" x="140" y="120" rx="2.5"></rect>
      <rect width="5" height="5" x="40" y="120" rx="2.5"></rect>
      <rect width="5" height="5" x="100" y="120" rx="2.5"></rect>
      <rect width="5" height="5" x="160" y="120" rx="2.5"></rect>
      <rect width="5" height="5" x="180" y="120" rx="2.5"></rect>
      <rect width="5" height="5" y="140" rx="2.5"></rect>
      <rect width="5" height="5" x="60" y="140" rx="2.5"></rect>
      <rect width="5" height="5" x="120" y="140" rx="2.5"></rect>
      <rect width="5" height="5" x="20" y="140" rx="2.5"></rect>
      <rect width="5" height="5" x="80" y="140" rx="2.5"></rect>
      <rect width="5" height="5" x="140" y="140" rx="2.5"></rect>
      <rect width="5" height="5" x="40" y="140" rx="2.5"></rect>
      <rect width="5" height="5" x="100" y="140" rx="2.5"></rect>
      <rect width="5" height="5" x="160" y="140" rx="2.5"></rect>
      <rect width="5" height="5" x="180" y="140" rx="2.5"></rect>
      <rect width="5" height="5" y="160" rx="2.5"></rect>
      <rect width="5" height="5" x="60" y="160" rx="2.5"></rect>
      <rect width="5" height="5" x="120" y="160" rx="2.5"></rect>
      <rect width="5" height="5" x="20" y="160" rx="2.5"></rect>
      <rect width="5" height="5" x="80" y="160" rx="2.5"></rect>
      <rect width="5" height="5" x="140" y="160" rx="2.5"></rect>
      <rect width="5" height="5" x="40" y="160" rx="2.5"></rect>
      <rect width="5" height="5" x="100" y="160" rx="2.5"></rect>
      <rect width="5" height="5" x="160" y="160" rx="2.5"></rect>
      <rect width="5" height="5" x="180" y="160" rx="2.5"></rect>
      <rect width="5" height="5" y="180" rx="2.5"></rect>
      <rect width="5" height="5" x="60" y="180" rx="2.5"></rect>
      <rect width="5" height="5" x="120" y="180" rx="2.5"></rect>
      <rect width="5" height="5" x="20" y="180" rx="2.5"></rect>
      <rect width="5" height="5" x="80" y="180" rx="2.5"></rect>
      <rect width="5" height="5" x="140" y="180" rx="2.5"></rect>
      <rect width="5" height="5" x="40" y="180" rx="2.5"></rect>
      <rect width="5" height="5" x="100" y="180" rx="2.5"></rect>
      <rect width="5" height="5" x="160" y="180" rx="2.5"></rect>
      <rect width="5" height="5" x="180" y="180" rx="2.5"></rect>
    </svg>
  );
};
//
