import { useState } from "react";
import { ENV } from "../../Config/RunTimeConfig";
import { parseCookies } from "nookies";


// FUNC : GET SINGLE
// FUNC : CREATE
// FUNC : DELETE

// FUNC : GET
export const useAICreate = (ctx) => {
  const [AICreate, setAICreate] = useState(null);
  const jwt = parseCookies(ctx).jwt;

  const FetchAICreate = async (GAME) => {
    const res = await fetch(`${ENV.API_URL}/api/ai-writeup/openai`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({ fixtureID: GAME.fixtureID }),
    });
    const GPT3RESPONSE = await res.json();
    console.log(GPT3RESPONSE);

    setAICreate(GPT3RESPONSE);
  };
  return [AICreate, FetchAICreate];
};



/* Create ENDpoints for all possible AI Writeup options */

export const useAIMatchResults = (ctx) => {
  const [AICreate, setAICreate] = useState(null);
  const jwt = parseCookies(ctx).jwt;

  const FetchAICreate = async (ORDERID) => {
    
    const res = await fetch(`${ENV.API_URL}/api/ai-writeup/matchdayResults`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({ OrderID: ORDERID }),
    });

    const GPT3RESPONSE = await res.json();
    console.log(GPT3RESPONSE);

    setAICreate(GPT3RESPONSE);
  };
  return [AICreate, FetchAICreate];
};


export const useAIReports = (ctx) => {
  const [AICreate, setAICreate] = useState(null);
  const jwt = parseCookies(ctx).jwt;

  const FetchAICreate = async (ORDERID, ENDPOINT, PATH='', WRITEUP='' ) => {
    
    const res = await fetch(`${ENV.API_URL}${ENDPOINT}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({ OrderID: ORDERID, PATH:PATH,WRITEUP:WRITEUP }),
    });

    const GPT3RESPONSE = await res.json();
    console.log(GPT3RESPONSE);

    setAICreate(GPT3RESPONSE);
  };
  return [AICreate, FetchAICreate];
};


// FUNC : UPDATE
