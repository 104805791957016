import { ThemeProvider } from 'styled-components'

// Import Design Templates for MATCHDAYRESULT.
// Add new deisng patterns below
// GLASS
import {POTDBOWLING_GLASS} from '../../theme/Templates/GLASS/index'
import {POTDBOWLING_ARROWS} from '../../theme/Templates/ARROWS/index'
import {POTDBOWLING_ROUNDED} from "../../theme/Templates/ROUNDED/index"
// END


export const Build_POTDBOWLING_4by5 = (props)=>{
    const {DATA,RENDER} = props
	
	const TEMPLATES={
		"Glass":<POTDBOWLING_GLASS  DATA={DATA}  RENDER={RENDER}/>,
		"Arrows":<POTDBOWLING_ARROWS  DATA={DATA}  RENDER={RENDER}/>,
		"Rounded":<POTDBOWLING_ROUNDED DATA={DATA} RENDER={RENDER}/>,
		//"TOURNAMENT_GLASS":<POTDBOWLING_TOURNAMENT_GLASS  DATA={DATA}  RENDER={RENDER}/>,  
	} 
    return (  
		<ThemeProvider theme={RENDER.THEME.VideoTheme.Theme} >  
			{
				TEMPLATES[RENDER.THEME.VideoTemplate] 
			}
		</ThemeProvider> 
    );  
}
