import { useCurrentFrame, useVideoConfig, spring } from "remotion";
import { TrimPlayerName } from "../../../../../../actions/UX";
import styled from "styled-components";
import { HONERABLELI } from "../../../utils/html";
import { H3, H2 } from "../../../utils/Text";
import { HonerableImageBox } from "../../../utils/Containers";
import { SpringToFrom } from "../../../../Animation/RemotionSpring";
import { interpolateOpacityByFrame } from "../../../../Animation/interpolate";
import {
  FromMiddle,
  FromBottomToTop,
  FromTopToBottom,
  FromRightToLeft,
  FromLeftToRight,
} from "../../../../Animation/ClipWipe";
import { useTheme } from "styled-components";
import { getContrastColor,getComplimentaryColor } from "../../../../../../actions/colors";
// Components

export const DisplayHonorable = ({ _PLAY, _INT }) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  
  const theme = useTheme();
  const SpringConfig = {
    Springy100: {
      damping: 10,
      stiffness: 150,
      mass: 0.3,
    },
    Stiff: {
      damping: 80,
      stiffness: 100,
      mass: 0.1,
    },
  };

  const StaggerSpringIn = (int) => {
    return spring({
      fps,
      from: 0,
      to: 1,
      frame: frame - int,
      config: SpringConfig.Springy100,
    });
  };

  const StaggerSpringOut = (int) => {
    return spring({
      fps,
      from: 1,
      to: 0,
      frame: frame - int,
      config: SpringConfig.Stiff,
    });
  };

  return (
    <HONERABLELI
      style={{
       /*  transform: `
            scale(${StaggerSpringIn(15 + _INT)}) 
            scale(${StaggerSpringOut(150 * 2)})`, */
      }}
    >
      <BottomBubble
        style={{
            borderColor:theme.Base[0],
            background: theme.Copy[1],
            
            opacity: interpolateOpacityByFrame(
                frame,
                (15 + _INT),
                (15 + _INT)+15,
                0,
                1
              ),
        }}
      
      >
        <H2
          style={{
            textAlign: "left",
            marginLeft: "35px",
            lineHeight: "80px",
            fontWeight: 400,
            fontSize:'1.7em',
            color: getComplimentaryColor(theme.Base[0]),
          }}
        >
          {_PLAY.BOWLING_Wkts}/{_PLAY.BOWLING_Runs} ({_PLAY.BOWLING_Overs}) 
        </H2>
      </BottomBubble>
      <TopBubble
        style={{
            backgroundColor: theme.Base[1],
            clipPath: FromBottomToTop((15 + _INT), "Springy100"),
        }}
      >
        <HonerableImageBox style={{ backgroundImage: `url(${_PLAY.Logo})` }} />
        <H3
          style={{
            textAlign: "left",
            marginLeft: "80px",
            lineHeight: "60px",
            fontWeight: 600,
            fontSize:'1.8em',
            letterSpacing:'-1px',
            color: getContrastColor(theme.Base[1]),
          }}
        >
          {TrimPlayerName(_PLAY.Name, 14)}
        </H3>
      </TopBubble>
    </HONERABLELI>
  );
};

const TopBubble = styled.div`
  position: absolute;
  width: 379px;
  height: 65px;
  left: 0px;
  top: 0px;
  border-radius: 100px;
`;

const BottomBubble = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 350px;
  height: 65px;
  left: 50px;
  top: 50px;
  border-radius: 100px;
  border:1px solid;
  box-shadow: 2px 8px 14px rgba(0, 0, 0, 0.25);
`;
