import {useCurrentFrame } from 'remotion';

import {SpringToFrom} from '../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../Animation/interpolate'
// Sponsorsed Components
import {
    SponsorLogoINTRO,
} from './Logo'

export const DisplaySponsorLogo = ({SPONSOR})=>{
    const frame = useCurrentFrame();
    
    return(
        <div style={{
                opacity:interpolateOpacityByFrame(frame, (45),(60), 0, 1),
                padding:'2em 0 ', 
                transform: `translateY(${SpringToFrom(45,10,0,'Springy100','slow')}px)`,
                textAlign:'center'
            }}>
                <SponsorLogoINTRO Sponsor={SPONSOR}/> 
            </div>

    )
}