import { Series } from "remotion";

import { FullWidthWrapper } from "../../../../utils/Containers";
// SERIES SEQUENCES
import { SECTION_BATTING } from "./Series/SECTION_BATTING";
import { SECTION_BOWLING } from "./Series/SECTION_BOWLING";
import { SECTION_AVERAGES } from "./Series/SECTION_AVERAGES";

export const THISWEEKINLMS = ({ DATA, VideoSettings,RENDER }) => {
  return (
    <>
      <FullWidthWrapper>
        <Series>
          <Series.Sequence
            durationInFrames={VideoSettings.SectionDuration[2]}
            layout="none"
          >
            <SECTION_BATTING 
              DATA={DATA}
              RENDER={RENDER}
              Duration={VideoSettings.SectionDuration[2]}
            />
          </Series.Sequence>
          <Series.Sequence
            durationInFrames={VideoSettings.SectionDuration[3]}
            layout="none"
          > 
            <SECTION_BOWLING
              DATA={DATA} 
              RENDER={RENDER}
              Duration={VideoSettings.SectionDuration[3]}
            />
          </Series.Sequence>
          <Series.Sequence
            durationInFrames={VideoSettings.SectionDuration[4]}
            layout="none"
          >
            <SECTION_AVERAGES
              DATA={DATA}
              RENDER={RENDER}
              Duration={VideoSettings.SectionDuration[4]}
            />
          </Series.Sequence>
        </Series>
      </FullWidthWrapper>
    </>
  );
};
