import {gql,} from "@apollo/client";


export const QUERY = (APISETTINGS)=>{
    return gql`query GetFixtures{
    watchLists(filters:{ id:{in: [${APISETTINGS.IDS}]} }){
              data{
                id
                attributes{
                  Name
                }
              }
            },
    fixtures(
      pagination: { start: 0, limit: -1 }
      filters: { 
        and: [
          {  
            Date:{
              gte:"${APISETTINGS.Date[0]}"
            }
          },
          { 
            Date:{
              lte:"${APISETTINGS.Date[1]}" 
            }
          }
        ]
        watch_list: { id: { in: [${APISETTINGS.IDS}] }  } 
      }
    ) {
      data {
        id
        attributes {
          fixtureID
          UnixTime
          Time
          Date
          HomeTeam
          AwayTeam
          watch_list{
            data {
              attributes{
                Name
              }
            }
          }
          teams {
            data {
              id
              attributes {
                Name
                Form
                World_Rank
                Logo {
                  data {
                    id
                    attributes {
                      formats
                      url
                    }
                  }
                }
              }
            }
          }
          ground {
            data {
              id
              attributes {
                Name
              }
            }
          }
           umpire {
            data {
              id
              attributes {
                Name
              }
            }
          }
        }
      }
    }
  } `
}
  