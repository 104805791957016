/* eslint-disable react/jsx-pascal-case */
import { useSelector } from "react-redux";
import { useUpdateMeVideoSettings } from "../../../Hooks/Content/useVideoSettings";
import { useAuthUser } from "../../../Hooks/Auth/useAuthUserMe";
import {
  ColoredContainer,
  VideoWizardContainer,
} from "../../../Theme/Structure/BodyContainer";
import { P } from "../../../Theme/type";

import { BattingPOTDSelectMinStrikeRate } from "../../../Components/settings/Select_VideoSetting_BattingPOTD_Min_StrikeRate";
import { BattingPOTDSelectMinRuns } from "../../../Components/settings/Select_VideoSetting_BattingPOTD_Min_Runs";
import { useEffect, useState } from "react";
import { UISkeletonLines } from "../../../Components/Global/Skeleton";
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
import { Container, Group, Space } from "@mantine/core";
import { PageHeader } from "../../../Components/interface/ProductHeader";
import { HIGHLIGHTER } from "../../../Components/interface/Wrapper/Highlighter";

export const BattingPOTDSettings = () => {
  const AUTH = useSelector((state) => state.AUTH);
  const SETTINGSPATH = AUTH.AUTHUSER.user_video_setting.Settings.POTD.BATTING;
  const [authUser, FetchauthUser] = useAuthUser();
  const [UpdateUser, PutUpdateUser] = useUpdateMeVideoSettings();

  console.log(AUTH.AUTHUSER.user_video_setting.id)

  const [updating, setupdating] = useState(false);
  const [MinStrikerate, setMinStrikerate] = useState(SETTINGSPATH.STRIKERATE);
  const [MinRuns, setMinRuns] = useState(SETTINGSPATH.MINRUNS);
  const [Settings, setSettings] = useState(
    AUTH.AUTHUSER.user_video_setting.Settings
  );

  const UpdateSETTINGS = (PATH, VALUE) => {
    const ARR = {
      ...Settings,
      POTD: {
        ...Settings.POTD,
        BATTING: { ...Settings.POTD.BATTING, [PATH]: VALUE },
      },
    };
    setSettings(ARR);
  };

  useEffect(() => {
    if (MinStrikerate !== SETTINGSPATH.STRIKERATE) {
      UpdateSETTINGS("STRIKERATE", MinStrikerate);
    }
  }, [MinStrikerate]);

  useEffect(() => {
    if (MinRuns !== SETTINGSPATH.MINRUNS) {
      UpdateSETTINGS("MINRUNS", MinRuns);
    }
  }, [MinRuns]);

  useEffect(() => {
    PutUpdateUser(Settings, AUTH.AUTHUSER.user_video_setting.id);
    setupdating(true);
  }, [Settings]);

  useEffect(() => {
    FetchauthUser();
  }, [UpdateUser]);
  useEffect(() => {
    setupdating(false);
  }, [authUser]);

  if (updating) return <UISkeletonLines />;
  return (
    <>
      <PageHeader NavPosition="Batting Settings" />

      <Space h="xl" />
      <BLOCK_Section_SubHeading LABEL="Play of the Day: Batting" Color={5} />

      <ColoredContainer Padding="xs" BGColor={0}>
        <P>
          Use these settings to fine tune the Batting POTD videos to your
          franchise. It is recommended that franchises with a high number of
          teams and leagues use a higher threshold to limit video length.{" "}
        </P>
        <Space h="xl" />
        <P>With the current settings, the follow statment is true.</P>
        <P weight={900} colorID={4} align="left">
          To be eligable for the Batting POTD a Players must score over{" "}
          {MinRuns} with a {MinStrikerate} strike rate or more
        </P>
      </ColoredContainer>

      <Container size="lg"  p={0}>
        <VideoWizardContainer>
          <Group position="apart">
            <P colorID={3}>
              <HIGHLIGHTER WORDS={["RUNS"]}>
                What is the Minimum RUNS required to be featured?
              </HIGHLIGHTER>
            </P>
            <BattingPOTDSelectMinRuns CALLBACK={setMinRuns} VALUE={MinRuns} />
          </Group>
        </VideoWizardContainer>
      </Container>

      <Container size="lg"  p={0}>
        <VideoWizardContainer>
          <Group position="apart">
            <P colorID={3}>
            <HIGHLIGHTER WORDS={["STRIKE RATE"]}>
              What is the minimum STRIKE RATE required to be featured?
              </HIGHLIGHTER>
            </P>
            <BattingPOTDSelectMinStrikeRate
              CALLBACK={setMinStrikerate}
              VALUE={MinStrikerate}
            />
          </Group>
        </VideoWizardContainer>
      </Container>
    </>
  );
};
