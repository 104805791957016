import { ThemeProvider } from 'styled-components'


// Import Design Templates for MATCHDAYRESULT.
// Add new deisng patterns below
// GLASS
import {PLAYEROFTHEMATCH_GLASS} from '../../theme/Templates/GLASS/index'
// END


export const Build_PLAYEROFTHEMATCH_4by5 = (props)=>{
	const {DATA,RENDER} = props
	const TEMPLATES={
		"Glass":<PLAYEROFTHEMATCH_GLASS  DATA={DATA}  RENDER={RENDER}/>,
	}  
    return (  
		<ThemeProvider theme={RENDER.THEME.VideoTheme.Theme} >  
			{
				TEMPLATES[RENDER.THEME.VideoTemplate] 
			}
		</ThemeProvider>  
    );  
} 

