import styled from 'styled-components';

export const OpeningTopBar = styled.div`
        position: absolute;
        width: 160px;
        height: 75px;
        left: 100px;
        top: 25px;
        border: 1px solid ${props => props.theme.Base[0]};
        z-index: 0;
        background: ${props => props.theme.Base[0]}; 
`

export const OpeningBottomBar = styled.div` 
        position: absolute; 
        width: 300px;
        height: 100px;
        left: 520px;
        top: 100px;
        background: ${props => props.theme.Base[0]};
        border: 1px solid ${props => props.theme.Base[0]};
        box-sizing: border-box;
        z-index:0;`

export const SectionTitleBar = styled.div`
        position: absolute;
        width: 300px;
        height: 75px;
        left: 10px;
        top: 30px;
        background: ${props => props.theme.Base[0]};
        box-sizing: border-box;
        z-index:0;
        ` 