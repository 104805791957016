import { useEffect, useState } from "react"
import { useSelector } from 'react-redux'
import {ENV} from '../../Config/RunTimeConfig'
import { parseCookies } from 'nookies'

// import items for Tournament create
import {useCreateFranchise} from './useFranchises'
import {useCreateWatchList} from './useLeagues'



const qs = require('qs');

// FUNC : GET SINGLE
 
// FUNC : CREATE
// FUNC : DELETE 

// FUNC : GET ALL
export const useGetTournaments = (ctx)=>{
    const [Tournaments, setTournaments] = useState(null)
    const AUTH = useSelector((state) => state.AUTH);
    const jwt = parseCookies(ctx).jwt;

        const GetTournaments = async()=>{
            setTournaments(null)
            const query = qs.stringify({
                filters: {  users_permissions_user: { id:AUTH.AUTHUSER.id} },
                populate: '*', 
              }, {
                encodeValuesOnly: true,
              });
              const res = await fetch(`${ENV.API_URL}/api/tournaments?${query}`, {  
                headers: { Authorization: `Bearer ${jwt}` }
            })
            const tournamentsResponse = await res.json();
        //console.log(tournamentsResponse)
        setTournaments(tournamentsResponse.data)
        }

        useEffect(()=>{},[Tournaments])

        return[Tournaments,GetTournaments]
}

// FUNC : GET SINGLE
export const useFetchSelectedTournament = (ctx)=>{

    const [TournamentData,setTournamentData] = useState(false)

    //const UXSTATE = useSelector((state) => state.UXSTATE);
    const jwt = parseCookies(ctx).jwt
     
    const FetchSelectedTournament  = async (_ID)=>{
      //console.log(_ID)
      setTournamentData(false)
        const query = qs.stringify({
            populate: ['LOGO', 
                'Gallery',
                'franchise',
                'sponsor',
                'sponsor.Logo',
                'TournamentVideos',
                'franchise.watch_lists',
                'franchise.watch_lists.fixtures',
                'TournamentVideos',
                'franchise.franchise_update_requests',
                '*']
          }, {
            encodeValuesOnly: true,
          }); 

        const res = await fetch(`${ENV.API_URL}/api/tournaments/${_ID}?${query}`, {
            headers: {
                Authorization: `Bearer ${jwt}`
            }
        })
        const FetchSelected = await res.json();
      //console.log(FetchSelected.data)
        setTournamentData(FetchSelected.data)
    }

    useEffect(()=>{},[TournamentData])


    return[TournamentData,FetchSelectedTournament] 
}


// FUNC : CREATE
// Create a new Tournament Sequence
/*
    -> Create Tournament Francise 
    -> Create Tournament Watchlist(s)
    -> Create TOurnament instance
*/
export const useCreateTournament=(ctx)=>{

    const [Franchise,CreateFranchise]  = useCreateFranchise()
    const [WatchList,CreateWatchList] =useCreateWatchList()
    const [Tournament, setTournament] = useState(false)

    const jwt = parseCookies(ctx).jwt
   
    const CreateTournament = async(_DATA)=>{
        // Create a new Franchise for the tournament
        CreateFranchise(_DATA)
      
        
    }  


    const CreateTournamentInstance = async (_DATA)=>{
           const res = await fetch(`${ENV.API_URL}/api/tournaments`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({
                data: _DATA
            })
        })
        const JsonTournament = await res.json();
      //console.log(JsonTournament)
        setTournament(JsonTournament)
    }


    // Check to see if franchise was created
    useEffect(()=>{
        if(Franchise){
            Franchise.franchise=[Franchise.FRANCHISEID]
            //console.log(Franchise)
            const URL = Franchise.URL.split('?')
            //console.log(URL, `/t20/?${URL[1]}`)

            const TournamentWatchlist={
                Name: Franchise.Name,
                PATH:`/t20/?${URL[1]}`,
                franchise:[Franchise.FRANCHISEID]
            }
            
          //console.log('New Franchise Created', Franchise, TournamentWatchlist)
            
            CreateWatchList(TournamentWatchlist)
            CreateTournamentInstance(Franchise)
        }
    },[Franchise])

    return[Tournament,CreateTournament] 
} 





// FUNC : CUSTOM ::: DATA FETCH
export const useFetchTournamentData = ()=>{
    const [TournamentData,setTournamentData] = useState(false)
 
    const FetchTournamentData = async(_ID)=>{
        const query = qs.stringify({ id:_ID});
        ///api/tournament/DataSync/:id
        const res = await fetch(`${ENV.API_URL}/api/tournament/DataSync/${_ID}?${query}`)
        const Players = await res.json();
      //console.log(Players)
      setTournamentData(Players)
    }
    return [TournamentData, FetchTournamentData]    
}


// FUNC : UPDATE
//PUT
// /api/tournaments/:id
export const usePUTTournaments = (ctx)=>{
    const [Tournaments, setTournaments] = useState(null)
    
    const jwt = parseCookies(ctx).jwt;

        const UpdateTournament = async(_ID,_DATA)=>{
            setTournaments(null)
          
              const res = await fetch(`${ENV.API_URL}/api/tournaments/${_ID}`, {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`
                },
                body: JSON.stringify({
                    data: _DATA
                })
            })
            const tournamentsResponse = await res.json();
        //console.log(tournamentsResponse)
        setTournaments(tournamentsResponse.data)
        }

        useEffect(()=>{},[Tournaments])

        return[Tournaments,UpdateTournament]
}

// FUNC DELETE 