/* eslint-disable react/jsx-pascal-case */
// custom hooks
import { useFetchSelectedTournament, usePUTTournaments } from "../../Hooks/Content/useTournaments";
import {
  BTN_ICON_CALLBACK,
  BTN_ICON_LINK,
} from "../../Theme/Structure/Button/BTNS";
import ReactMarkdown from "react-markdown";
import { useEffect, useState } from "react";
import {
  ColoredContainer,

  VideoWizardContainer,
} from "../../Theme/Structure/BodyContainer";
import { H3, Header, P, S } from "../../Theme/type";
import { Box, Center, Group, Image, Tabs } from "@mantine/core";
import {
  IconWriting,
  IconListNumbers,
  IconVideo,
  IconChartDots,
} from "@tabler/icons";
// Components
import { TournamentSyncData } from "./components/TournamentSyncDataBTN";
import { IsTournamentUpdating } from "./components/isTournamentUpdating";
import { FixturesandResults } from "./components/FixturesAndResults";
import { TournamentGeneralContainer } from "../Numbers/General/index";
import { TournamentBattingContainer } from "../Numbers/Batting/index";
import { TournamentBowlingContainer } from "../Numbers/Bowling/index";
import { TournamentTeamsContainer } from "../Numbers/Teams/index";
import { UploadTournamentVideo } from "./components/UploadVideo";
import moment from "moment";

import MDstyle from "../../markdown-styles.module.css";
// UI ELEMENTS
import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";
import { BLOCK_ToolBar } from "../../Components/interface/BLOCK_Toolbar";
import { TAB_LIST, TAB_WRAPPER } from "../../Components/interface/Wrapper/TABS";

export const ViewTournament = ({ ID, setID }) => {
  const [TournamentData, FetchSelectedTournament] =
    useFetchSelectedTournament();

  const [Selected, setSelected] = useState(false);
  const [SelectedID, setSelectedID] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    FetchSelectedTournament(ID);
  }, []);
  useEffect(() => {
  //console.log("REFRESH PAGE", refresh);
    if (refresh === true) {
      FetchSelectedTournament(ID);
    }
  }, [refresh]);

  useEffect(() => {
  //console.log("isUpdating", isUpdating, "refresh", refresh);

    if (TournamentData.id !== undefined && isUpdating === false) {
      setSelected(TournamentData.attributes);
      setSelectedID(TournamentData.id);
      setRefresh(false);
    }

    if (refresh === true) {
    //console.log("TournamentData refresh ", TournamentData);
      setSelected(TournamentData.attributes);
      setSelectedID(TournamentData.id);
      setRefresh(false);
    }
  }, [TournamentData]);

  if (!Selected) return <>Fetching data</>;
  return (
    <>
      <ToolBar
        setID={setID}
        Selected={Selected}
        isUpdating={isUpdating}
        setisUpdating={setisUpdating}
      />
      <IsTournamentUpdating
        SelectedID={SelectedID}
        isUpdating={isUpdating}
        setisUpdating={setisUpdating}
      />

      <TAB_WRAPPER defaultValue="About">
        <TAB_LIST
          ARR={[
            {
              VALUE: "About",
              LABEL: "About", 
              ICON: <IconWriting size={24} color="teal" />,
            },
            {
              VALUE: "Fixtures",
              LABEL: "Fixtures",
              ICON: <IconListNumbers size={24} color="teal" />,
            },
            {
              VALUE: "Videos",
              LABEL: "Videos",
              ICON: <IconVideo size={24} color="teal" />,
            },

            {
              VALUE: "Numbers",
              LABEL: "Numbers",
              ICON: <IconChartDots size={24} color="teal" />,
            },
          ]}
        />

        <Tabs.Panel value="About" pt="xs">
          <TournamentDetails Selected={Selected} />
          <TournamentAbout Selected={Selected} />
          <ArchiveTournament setID={setID} SelectedID={SelectedID} Selected={Selected}/>
        </Tabs.Panel>
        <Tabs.Panel value="Fixtures" pt="xs">
          <TournamentFixtures Selected={Selected} />
        </Tabs.Panel>

        <Tabs.Panel value="Videos" pt="xs">
          <UploadTournamentVideo
            Selected={Selected}
            SelectedID={SelectedID}
            setRefresh={setRefresh}
          />
        </Tabs.Panel>

        <Tabs.Panel value="Numbers" pt="xs">
          <TournamentNumbers Selected={Selected} />
        </Tabs.Panel>
      </TAB_WRAPPER>
    </>
  );
};

// <TournamentSponsor Selected={Selected} />

const ToolBar = ({ setID, Selected, isUpdating, setisUpdating }) => {
  //console.log('Selected', Selected)
  return (
    <>
      <BLOCK_ToolBar
        TOOLS={[
          <BTN_ICON_CALLBACK
            CALLBACK={() => {
              setID(false);
            }}
            ICON="Tournament"
            BG={1}
            COLOR={3}
            TIPOBJ={{
              LABEL: "Back to Tournament List",
              COLOR: "red",
              POSITION: "top",
            }}
          />,
          <TournamentSyncData
            _ID={Selected.franchise.data.id}
            isUpdating={isUpdating}
            setisUpdating={setisUpdating}
          />,
          <BTN_ICON_LINK
            TO={Selected.LMSPAGE}
            ICON="ExternalLink"
            TIPOBJ={{
              LABEL: "Visit League",
              COLOR: "gray",
              POSITION: "top",
            }}
          />,

          <BTN_ICON_LINK
            TO={Selected.URL}
            ICON="Cricket"
            TIPOBJ={{
              LABEL: "League Table",
              COLOR: "gray",
              POSITION: "top",
            }}
          />,
        ]}
      />
    </>
  );
};

const ArchiveTournament = ({ SelectedID,setID,Selected}) => { 
  const [Tournaments,UpdateTournament] = usePUTTournaments();
//console.log(Selected.isActive)

  useEffect(()=>{
    if(Tournaments !== null){
      setID(false);
    }
    
  },[Tournaments])

  return (
    <VideoWizardContainer>
      <Group position="apart">
        <H3 colorID={!Selected.isActive ?7:6}>{!Selected.isActive ?"Re-Activate Tournament":"Archive Tournament"}</H3>
        <Box>
          <BTN_ICON_CALLBACK
            CALLBACK={() => {
              UpdateTournament( SelectedID, {isActive:!Selected.isActive ?true:false})
             
            }}
            ICON={!Selected.isActive ?"CircleCheck":"Trash"}
            BG={6}
            COLOR={1}
            TIPOBJ={{
              LABEL: !Selected.isActive ?"Activate Tournament":"Archive Tournament",
              COLOR: !Selected.isActive ?'green':'red',
              POSITION: "top",
            }}
          />
        </Box>
      </Group>
    </VideoWizardContainer>
  );
};

const TournamentDetails = ({ Selected }) => {
  return (
    <ColoredContainer>
      <Group position="apart">
        <Box>
          <Image
            src={Selected.LOGO.data.attributes.url}
            height={100}
            fit="contain"
          />
          <Header>
            {Selected.Name} {Selected.Year}
          </Header>
          <H3 colorID={5}>
            {moment(Selected.START).format("dddd DD MMMM")} -{" "}
            {moment(Selected.END).format("dddd DD MMMM")}
          </H3>
          <H3 colorID={5}>{Selected.Location}</H3>
          <H3 colorID={5}>{Selected.Tagline}</H3>
        </Box>
        <Box>
          <Center>
            <Image
              src={Selected.sponsor.data.attributes.Logo.data.attributes.url}
              height={100}
              fit="contain"
            />
          </Center>
          <Header align="right">Sponsor</Header>
          <H3 colorID={5} align="right">
            {Selected.sponsor.data.attributes.Name}
          </H3>
          <H3 colorID={5} align="right">
            {Selected.sponsor.data.attributes.Tagline}
          </H3>
          <H3 colorID={5} align="right">
            {Selected.sponsor.data.attributes.URL}
          </H3>
        </Box>
      </Group>
    </ColoredContainer>
  );
};

const TournamentAbout = ({ Selected }) => {
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "About",
          ICON: "Tournament",
          DESCRIPTION: "A Brief Summary of the Tournament",
          TOOLBAR: false,
        }}
      />
      <VideoWizardContainer>
        <ReactMarkdown className={MDstyle.reactMarkDown}>
          {Selected.Infomation}
        </ReactMarkdown>
      </VideoWizardContainer>
    </>
  );
};

/* const TournamentSponsor = ({Selected})=>{

    return(
        <ContentContainerx3 BGColor={0} Padding={10}>
            <Group position='apart'>
                <Box>
                <Image 
                    src={Selected.sponsor.data.attributes.Logo.data.attributes.url}
                    height={100}
                    width={100}
                    fit="contain" 
                />
                    
                    <P>Title Sponsor  : {Selected.sponsor.data.attributes.Name}</P>
                    <P>Tagline  : {Selected.sponsor.data.attributes.Tagline}</P>
                    <P>CTA  : {Selected.sponsor.data.attributes.URL}</P>
                    <P>SocialMediaCopy  : {Selected.sponsor.data.attributes.SocialMediaCopy}</P>
                </Box>
                <Image 
                    src={Selected.sponsor.data.attributes.Logo.data.attributes.url}
                    height={100}
                    width={100}
                    fit="contain" 
                />
            </Group>
        </ContentContainerx3> 
    )
} */

const TournamentFixtures = ({ Selected }) => {
  //console.log(Selected)
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Tournament Fixtures and Results",
          ICON: "Tournament",
          DESCRIPTION: "Tournament Fixtures and Results as the system read it.",
          TOOLBAR: false,
        }}
      />

      <VideoWizardContainer>
        <FixturesandResults Selected={Selected} />
      </VideoWizardContainer>
    </>
  );
};

const TournamentNumbers = ({ Selected }) => {
  if (Selected.franchise.data.attributes?.FranchiseStats === null)
    return (
      <VideoWizardContainer>
        <H3 colorID={0}>Awaiting Tournament Statistics </H3>
      </VideoWizardContainer>
    );
  return (
    <>
      <TournamentGeneralContainer FranchiseStats={Selected} />
      <TournamentBattingContainer FranchiseStats={Selected} />
      <TournamentBowlingContainer FranchiseStats={Selected} />
      <TournamentTeamsContainer FranchiseStats={Selected} />
    </>
  );
};
