// Global
import { Audio, interpolate, useCurrentFrame, Sequence } from "remotion";
import {
  TopAndTail,
  DivisionWinnersTopAndTail,
  TeamSeasonReviewTopAndTail,
} from "./Components/TopandTail";
// Components
import { MATCHDAYRESULTS_BODY } from "./Compositions/WEEKLY/MATCHDAYRESULTS/MATCHDAYRESULTS_BODY";
import { MATCHDAYFIXTURES_BODY } from "./Compositions/WEEKLY/UPCOMINGFIXTURES/MATCHDAYFIXTURES_BODY";
import { POTDBATTING_BODY } from "./Compositions/WEEKLY/POTDBATTING/POTDBATTING_BODY";
import { POTDBOWLING_BODY } from "./Compositions/WEEKLY/POTDBOWLING/POTDBOWLING_BODY";
import { KNOWYOURLEAGUE_BODY } from "./Compositions/SEASONAL/KNOWYOURLEAGUE/KNOWYOURLEAGUE_BODY";
import { TEAMOFTHEWEEK_BODY } from "./Compositions/WEEKLY/TEAMOFTHEWEEK/TEAMOFTHEWEEK_BODY";
import { GAMEOFTHEWEEK_BODY } from "./Compositions/WEEKLY/GAMEOFTHEWEEK/GAMEOFTHEWEEK_BODY";
import { GAMEOFTHEWEEKLITE_BODY } from "./Compositions/WEEKLY/GAMEOFTHEWEEKLITE/GAMEOFTHEWEEKLITE_BODY";
import { TOP5_BODY_45 } from "./Compositions/TOP5/TOP5_BODY_45";
import { TOP5_EXTRAMETA_BODY_45 } from "./Compositions//TOP5/TOP5_EXTRAMETA_BODY_45";
import { TOP5_BODY_916 } from "./Compositions//TOP5/TOP5_BODY_916";
import { PLAYERMILESTONE_BODY } from "./Compositions/MILESTONE/PLAYERMILESTONE/PLAYERMILESTONE";
import { DIVISIONWINNERS_BODY } from "./Compositions/SEASONAL/DIVISIONWINNERS/DIVISIONWINNERS";
import { TEAMSEASONALREVIEW_BODY } from "./Compositions/SEASONAL/TEAMSEASONALREVIEW/TEAMSEASONALREVIEW";
import { PlayerOfTheMatch } from "./Compositions/WEEKLY/PLAYEROFTHEMATCH/PlayerOfTheMatch";
// Add new Templates in here

// UPCOMING FIXTURES
export const UPCOMINGFIXTURES_ROUNDED = ({ RENDER }) => {
  //console.log(DATA)
  return (
    <>
      <TopAndTail
        MainTitle="Fixtures"
        Subtitle="upcoming"
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      >
        <MATCHDAYFIXTURES_BODY
          DATA={RENDER.DATAOBJ}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
        />
      </TopAndTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
};

/*************************************  */
// Match Day Results Sequence
export const MATCHDAYRESULTS_ROUNDED = ({ RENDER }) => {
  console.log(RENDER);
  return (
    <>
      <TopAndTail
        MainTitle="Results"
        Subtitle="Matchday"
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      >
        <MATCHDAYRESULTS_BODY
          DATA={RENDER.DATAOBJ}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
        />
      </TopAndTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
};

/*************************************  */
// BATTING POTD Results Sequence
export const POTDBATTING_ROUNDED = ({ RENDER }) => {
  //THEME.VideoAudio
  return (
    <>
      <TopAndTail
        MainTitle="Honours"
        Subtitle="Batting"
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      >
        <POTDBATTING_BODY
          DATA={RENDER.DATAOBJ}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
        />
      </TopAndTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
};

/*************************************  */
// BOWLING POTD Results Sequence
export const POTDBOWLING_ROUNDED = ({ RENDER }) => {
  //console.log(DATA)
  return (
    <>
      <TopAndTail
        MainTitle="POTD"
        Subtitle="Bowling"
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      >
        <POTDBOWLING_BODY
          DATA={RENDER.DATAOBJ}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
        />
      </TopAndTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
};

/*************************************  */
// KNOW YOUR LEAGUE Results Sequence
/* export const KNOWYOURLEAGUE_GLASS = ({ DATA, RenderType }) => {
  //console.log(DATA)
  return (
    <TopAndTail MainTitle="League" Subtitle="Know Your" DATA={DATA}>
      <KNOWYOURLEAGUE_BODY
        DATA={DATA}
        VideoSettings={DATA.VideoSettings}
        RenderType={RenderType}
      />
    </TopAndTail>
  );
}; */

/*************************************  */
// BATTING POTD Results Sequence
/* export const TEAMOFTHEWEEK_GLASS = ({ RENDER }) => {
  //const frame = useCurrentFrame();
  return (
    <>
      <TopAndTail
        Subtitle="TEAM OF"
        MainTitle="THE WEEK"
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      >
        <TEAMOFTHEWEEK_BODY
          DATA={RENDER.DATAOBJ}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
        />
      </TopAndTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
}; */

/*************************************  */
// LEADER BOARDs

// Top 5's
/* export const TOP5_4by5 = ({ RENDER, MainTitle, Subtitle }) => {
  return (
    <>
      <TopAndTail
        Subtitle={Subtitle}
        MainTitle={MainTitle}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      >
        <TOP5_BODY_45
          DATA={RENDER.DATAOBJ}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
          Subtitle={Subtitle}
          MainTitle={MainTitle}
        />
      </TopAndTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
}; */
/* export const TOP5_9by16 = ({ DATA, RenderType, MainTitle, Subtitle }) => {
  return (
    <>
      <TopAndTail Subtitle={Subtitle} MainTitle={MainTitle} DATA={DATA}>
        <TOP5_BODY_916
          DATA={DATA}
          VideoSettings={DATA.VideoSettings}
          RenderType={RenderType}
          Subtitle={Subtitle}
          MainTitle={MainTitle}
        />
      </TopAndTail>
      <DefaultAudio VideoSettings={DATA.VideoSettings} DATA={DATA} />
    </>
  );
};
 */
/* export const TOP5_ExtraMeta_4by5 = ({
  RENDER,
  MainTitle,
  Subtitle,
  InfoTitle,
}) => {
  return (
    <>
      <TopAndTail
        Subtitle={Subtitle}
        MainTitle={MainTitle}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      >
        <TOP5_EXTRAMETA_BODY_45
          DATA={RENDER.DATAOBJ}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
          Subtitle={Subtitle}
          MainTitle={MainTitle}
          InfoTitle={InfoTitle}
        />
      </TopAndTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
}; */

/*************************************  */
// Game of the Week
/* export const GAMEOFTHEWEEK_GLASS = ({ RENDER }) => {
  return (
    <>
      <TopAndTail Subtitle="GAME OF" MainTitle="THE WEEK" RENDER={RENDER}>
        <GAMEOFTHEWEEK_BODY
          DATA={RENDER.DATAOBJ}
          RENDER={RENDER}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
        />
      </TopAndTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
};
 */
/* export const GAMEOFTHEWEEKLITE_GLASS = ({ RENDER }) => {
  return (
    <>
      <TopAndTail Subtitle="GAME OF" MainTitle="THE WEEK" RENDER={RENDER}>
        <GAMEOFTHEWEEKLITE_BODY
          DATA={RENDER.DATAOBJ}
          RENDER={RENDER}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
        />
      </TopAndTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
}; */

/*************************************  */
// LEADER BOARDs

// SIXES
/* export const PLAYERMILESTONE_4by5 = ({ RENDER, MainTitle, Subtitle }) => {
  return (
    <>
      <TopAndTail
        Subtitle={Subtitle}
        MainTitle={MainTitle}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      >
        <PLAYERMILESTONE_BODY
          DATA={RENDER.DATAOBJ}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
          Subtitle={Subtitle}
          MainTitle={MainTitle}
        />
      </TopAndTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
};
 */
// Player of the Match Award
/* export const PLAYEROFTHEMATCH_GLASS = ({ RENDER }) => {
  return (
    <>
      <TopAndTail
        Subtitle="Player of"
        MainTitle="the match Award"
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      >
        <PlayerOfTheMatch
          DATA={RENDER.DATAOBJ}
          VideoSettings={RENDER.DATAOBJ.VideoSettings}
        />
      </TopAndTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
}; */

/*************************************  */
// Seasonal Templates
// DIVISIONWINNERS
/* export const DIVISIONWINNERS_4by5 = ({ RENDER }) => {
  const MainTitle = "";
  const Subtitle = "";
  return (
    <>
      <DivisionWinnersTopAndTail
        Subtitle={Subtitle}
        MainTitle={MainTitle}
        DATA={RENDER}
      >
        <DIVISIONWINNERS_BODY
          DATA={RENDER}
          VideoSettings={RENDER.VideoSettings}
          Subtitle={Subtitle}
          MainTitle={MainTitle}
        />
      </DivisionWinnersTopAndTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
};
 */
// TEAM SEASONAL REVIEW
/* export const TEAMSEASONALREVIEW_4by5 = ({ RENDER }) => {
  const MainTitle = "";
  const Subtitle = "";
  return (
    <>
      <TeamSeasonReviewTopAndTail
        Subtitle={Subtitle} 
        MainTitle={MainTitle}
        DATA={RENDER}
      >
        <TEAMSEASONALREVIEW_BODY
           DATA={RENDER}
           VideoSettings={RENDER.VideoSettings}
           Subtitle={Subtitle}
           MainTitle={MainTitle}
        />
      </TeamSeasonReviewTopAndTail>
      <DefaultAudio
        VideoSettings={RENDER.DATAOBJ.VideoSettings}
        DATA={RENDER.DATAOBJ}
        RENDER={RENDER}
      />
    </>
  );
}; */

const DefaultAudio = ({ VideoSettings, DATA, RENDER }) => {
  //console.log(RENDER.THEME.VideoAudio);
  const frame = useCurrentFrame();
  //console.log(RENDER);
  const DefaultAudio = `https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/Audio_Template_Glass.mp3`;
  const TotalFrames = RENDER.DATAOBJ.VideoSettings.VideoDurationinFrames;
  return (
    <Sequence
      style={{
        zIndex: -1,
      }}
      from={90}
    >
      <Audio
        src={!RENDER.THEME.VideoAudio ? DefaultAudio : RENDER.THEME.VideoAudio}
        startFrom={0} // if composition is 30fps, then it will start at 2s
        endAt={TotalFrames} // if composition is 30fps, then it will end at 4s
        volume={interpolate(frame, [TotalFrames - 120, TotalFrames], [0.5, 0], {
          extrapolateLeft: "clamp",
        })}
      />
      <Audio
        src={`https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/audio/BackgroundCricketMatch.mp3`}
        startFrom={120} // if composition is 30fps, then it will start at 2s
        endAt={TotalFrames} // if composition is 30fps, then it will end at 4s
        volume={interpolate(frame, [TotalFrames - 120, TotalFrames], [0.8, 0], {
          extrapolateLeft: "clamp",
        })}
      /> 
    </Sequence>
  );
};
