import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useIsLoggedUser } from "../Hooks/Auth/isLoggedUser";

import Shell from "../Theme/Structure/Shell";
// ROUTES
import { MAINROUTES } from "./Routes/Main";
import { RoutesVideos } from "./Routes/videos";

const LMSSocialMediaManager = () => { 
  let navigate = useNavigate();
  let location = useLocation();
  const UXSTATE = useSelector((state) => state.UXSTATE);

  
  useEffect(()=>{
    console.log('SelectedFranchise ',UXSTATE.SelectedFranchise)
  },[UXSTATE.SelectedFranchise])
  if (!UXSTATE.SelectedFranchise) {
    if (location.pathname !== "/franchise") {
      // Find a better soultion to this issue!!!
      navigate("/franchise", { replace: true }); 
    } 
  } 

  return (
    <Shell>
      <RoutesVideos />
      <MAINROUTES /> 
    </Shell>
  ); 
};
export default LMSSocialMediaManager;
