import { useTheme } from "styled-components";
import styled from "styled-components";

// Animations

import {
  FromMiddle,
  EraseFromMiddle,
  FromTopToBottom,
} from "../../../../../../Animation/ClipWipe";

import { MoMImage } from "./MoM";
import { Performances } from "./Performances";
import { useCurrentFrame } from "remotion";
export const ResultData = ({ GAME, Durations }) => {
  const theme = useTheme();
  console.log(Durations);
  const frame = useCurrentFrame();
  /*  const tryClippy=()=>{
        console.log(Durations.RemoveScorcard)
        return  frame>Durations.RemoveScorcard ?EraseFromMiddle(Durations.RemoveScorcard ,'Springy100'):
    } */

  return (
    <BGOuter
      style={{
        clipPath: EraseFromMiddle(Durations.Length - 20, "Springy100"),
      }}
    >
      <BGSMALL
        style={{
          clipPath: FromTopToBottom(45, "Springy100"),
          border: `2px solid ${theme.Base[1]}`,
        }}
      >
        <Performances GAME={GAME} Durations={Durations} />

        <MoMImage
          IMG={GAME.MOM.Image}
          Name={GAME.MOM.Name}
          Durations={Durations}
        />
      </BGSMALL>
    </BGOuter>
  );
};

const BGOuter = styled.div`
  box-sizing: border-box;
  width: 100%;

  position: relative;
  border-radius: 10px;
  top: -60px;
`;
const BGSMALL = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  background: linear-gradient(
    90deg,
    rgba(217, 217, 217, 0.6) 50%,
    rgba(225, 225, 225, 0.8) 50%
  );
  position: relative;
  border-radius: 10px;
  top: 0px;
  padding-top: 60px;
`;
