import { Container, Tabs } from "@mantine/core";
import { P } from "../../../Theme/type";
export const TAB_WRAPPER = (props) => {
  const { defaultValue } = props;
  return (
    <Container size={"lg"} pt='md'>
      <Tabs 
        defaultValue={defaultValue}
        color="teal"
  
        radius="md" 
      
      >
        {props.children}
      </Tabs>
    </Container> 
  );
};

export const TAB_LIST = (props) => {
  const { ARR } = props;
  return (
    <Tabs.List grow position="center">
      {ARR.map((TABS, i) => {
        return (
          <Tabs.Tab key={i} value={TABS.VALUE} icon={TABS.ICON}>
            <P >{TABS.LABEL}</P>
          </Tabs.Tab>
        );
      })}
    </Tabs.List>
  );
};
