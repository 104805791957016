import { useCurrentFrame, Audio } from "remotion";
import { useTheme } from "styled-components";
import styled from "styled-components";
import { Series } from "remotion";
// Animations
import { SpringToFrom } from "../../../../../../Animation/RemotionSpring";
import { interpolateOpacityByFrame } from "../../../../../../Animation/interpolate";
import {
  FromMiddle,
  FromTopToBottom,
  FromBottomToTop,
} from "../../../../../../Animation/ClipWipe";
import { Box, Center, Group, Image, Stack } from "@mantine/core";
import { P } from "../../../../../utils/Text";
import {
  TrimPlayerName,
  TrimTeamName,
} from "../../../../../../../../actions/UX";
import { orderBy } from "lodash";
import moment from "moment";
export const ScoreCardWrapper = ({ DATA, LABEL, Durations }) => {
  const CreateTranslator = (Int) => {
    const Interval = Durations.Length;
    return `translateY(${SpringToFrom(
      Interval * Int,
      1360,
      0,
      "Springy100"
    )}px) translateY(${SpringToFrom(
      Interval * Int + Interval,
      0,
      -1360,
      "Springy100"
    )}px)`;
  };

  const CreatePairs = () => {
    let chunkSize = 4;
    //console.log(orderBy(DATA.Fixtures[LABEL], ["time"]));
    //ground
    const ARR = [];
    for (let i = 0; i < DATA.Fixtures[LABEL].length; i += chunkSize) {
      const chunk = orderBy(DATA.Fixtures[LABEL], ["time"]).slice(
        i,
        i + chunkSize
      );
      ARR.push(chunk);
    }
    
    return ARR;
  };

  return (
    <Series>
      {CreatePairs().map((Fixture, i) => {
       
        return (
          <Series.Sequence
            key={i}
            durationInFrames={Durations.Length}
            layout="none"
          >
            <div
              key={i}
              style={{
                transform: `${CreateTranslator(i)}`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                alignContent: "flex-start",
                position: "absolute",
                top: "80px",
                width: "90%",
              }}
            >
              {Fixture.map((GAME, i) => {
                
                return <ScoreCard GAME={GAME} key={i} Durations={Durations} LEN={Fixture.length}/>;
              })}
            </div>
            <Timer Durations={Durations} />
            <Audio
              src={`https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/TournamentsResultsItem_1.mp3`}
              playbackRate={1}
              startFrom={0} // if composition is 30fps, then it will start at 2s
              volume={1}
            />
          </Series.Sequence>
        );
      })}
    </Series>
  );
};

const TimerContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 10px;
`;

const Timer = ({ Durations }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();
  //console.log(((frame/Durations.Length)*100))
  return (
    <TimerContainer
      style={{
        width: `${(frame / Durations.Length) * 100}%`,
        backgroundColor: theme.Base[0],
      }}
    ></TimerContainer>
  );
};

const GLASSBG = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0.5em;
  height: auto;
  margin: 0;
  background: linear-gradient(
    145deg,
    rgba(0, 0, 0, 0.5) 10%,
    rgba(0, 0, 0, 0.2) 90%
  );
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const GLASSBGSMALL = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0.2em 0.5em;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  position: relative;
  border-radius: 0 0 10px 10px;
`;

const ScoreCard = ({ GAME, Durations,LEN }) => {
  //console.log(GAME)
  const theme = useTheme();
  const frame = useCurrentFrame();
  const DATE = moment(GAME.date).format("dddd DD MMMM");
  const TIME = GAME.time.split(":");
  return (
    <>
      <Group position="apart" style={{ width: "100%", margin: "40px 0 0 0" }}>
        <P
          style={{
            margin: "0",
            fontWeight: 400,
            fontSize: "33px",
            clipPath: FromTopToBottom(55),
            color: theme.Copy[0],
          }}
        >
          {DATE}
        </P>
        <P
          style={{
            margin: "0",
            fontWeight: 400,
            fontSize: "33px",
            clipPath: FromTopToBottom(55),
            color: theme.Copy[0],
          }}
        >{`Game Time : ${TIME[0]}:${TIME[1]}`}</P>
      </Group>
      <GLASSBG
        style={{
          clipPath: FromMiddle(15),
          opacity: interpolateOpacityByFrame(
            frame,
            Durations.Length - 7,
            Durations.Length,
            1,
            0
          ),
        }}
      >
        <TeamsAndResult GAME={GAME} Durations={Durations} LEN={LEN} />
      </GLASSBG>
      <GLASSBGSMALL style={{ clipPath: FromMiddle(30) }}>
        <Group position="apart" style={{ width: "100%", margin: "0px 0 0 0" }}>
          <P
            style={{
              margin: "0",
              fontSize: "30px",
              clipPath: FromBottomToTop(55),
              color: theme.Copy[1],
            }}
          >
            {TrimPlayerName(GAME.ground, 50)}
          </P>
          {/* <P style={{ margin:'0', fontSize:'30px',clipPath:FromBottomToTop(55), color:theme.Copy[1]}}>{`Umpire : ${TrimPlayerName(GAME.umpire, 10)}`}</P> */}
        </Group>
      </GLASSBGSMALL>
    </>
  );
};
//

const TeamsAndResult = ({ GAME,LEN }) => {
  //console.log(GAME);
  const theme = useTheme();
  return (
    <>
      <Group
        position="apart"
        spacing={0}
        style={{ width: "100%", padding: "0 ", position: "relative" }}
      >
        <Center>
          <Stack>
            <LOGOS FromTO={[390, 0]} LOGO={GAME.homeTeamLogo} LEN={LEN} />
            <FormBalls form={GAME?.homeTeamForm} INT={0} TOTAL={10} />
            <P
              style={{
                textAlign: "center",
                fontSize: "30px",
                lineHeight: "1.1em",
                margin: "0",
                padding: 0,
                fontWeight: "600",
                letterSpacing: "2px",
                clipPath: FromMiddle(35),
                color: theme.Copy[1],
              }}
            >
              WR: {TrimTeamName(GAME.homeTeamWR, 20)}
            </P>
          </Stack>
        </Center>

        <Box style={{ width: "50%" }} spacing={0}>
          <DisplayP VALUE={TrimTeamName(GAME.homeTeam, 20)} />
          <DisplayP VALUE={"vs"} />
          <DisplayP VALUE={TrimTeamName(GAME.awayTeam, 20)} />
        </Box>
        <Center>
          <Stack>
            <LOGOS FromTO={[-390, 0]} LOGO={GAME.awayTeamLogo}  LEN={LEN}/>

            <FormBalls form={GAME?.awayTeamForm} INT={0} TOTAL={10} />
            <P
              style={{
                textAlign: "center",
                fontSize: "30px",
                lineHeight: "1.1em",
                margin: "0",
                padding: 0,
                fontWeight: "600",
                letterSpacing: "2px",
                clipPath: FromMiddle(35),
                color: theme.Copy[1],
              }}
            >
              WR: {TrimTeamName(GAME.awayTeamWR, 20)}
            </P>
          </Stack>
        </Center>
      </Group>
    </>
  );
};

function FormBalls({ form, INT, TOTAL }) {
  const formBalls = form
    .trim()
    .split(" ")
    .map((ball, index) => {
      let bgColor;
      if (ball === "W") {
        bgColor = "green";
      } else if (ball === "L") {
        bgColor = "red";
      } else if (ball === "D") {
        bgColor = "gray";
      }

      return (
        <div
          key={index}
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "20px",
            backgroundColor: bgColor,
          }}
        />
      );
    });

  return <Group position="center">{formBalls}</Group>;
}

const LOGOS = ({ LOGO, FromTO, LEN }) => {
  const theme = useTheme();

  const RATIO = LEN===4 ?105:200


  return (
    <Image
      src={LOGO}
      width={RATIO}
      height={RATIO}
      radius={10}
      style={{
        backgroundColor: theme.Base[1],
        transform: ` translateX(${SpringToFrom(
          30,
          FromTO[0],
          FromTO[1],
          "Springy100"
        )}px) scale(${SpringToFrom(10, 0, 1, "Springy100")})`,
      }}
    />
  );
};

const DisplayP = ({ VALUE }) => {
  const theme = useTheme();
  return (
    <P
      style={{
        textAlign: "center",
        fontSize: "35px",
        lineHeight: "40px",
        margin: "0.2em 0 ",
        padding: 0,
        fontWeight: "400",
        letterSpacing: "-2px",
        clipPath: FromMiddle(35),
        color: theme.Copy[1],
      }}
    >
      {VALUE}
    </P>
  );
};
