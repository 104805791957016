import {useCurrentFrame,Img } from 'remotion';
import {LogoContainer,LogoInnerContainer} from "../../utils/Containers"
import {SpringToFrom} from '../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../Animation/interpolate'
import {ImageRatio} from '../../../../../actions/Images'
export const TournamentLogo = ({TournamentDetails})=>{

    const frame = useCurrentFrame();
    const styles=ImageRatio(TournamentDetails.LOGO.data.attributes).MainTitle
    

    return(
	<LogoContainer>
        <LogoInnerContainer style={{ 
                opacity:interpolateOpacityByFrame(frame, 10,30, 0,1),
                transform: ` scale(${SpringToFrom(7,0,1,'Springy100','fast')}) scale(${SpringToFrom(90,1,0,'Springy100','fast')})`}}>
         
         <div style={{ opacity:interpolateOpacityByFrame(frame, 90,95, 1,0), }}>
               <Img 
                style={styles}
                src={TournamentDetails.LOGO.data.attributes.url} 
            /> 
            </div>
        </LogoInnerContainer>
	</LogoContainer>
    )
}