/* eslint-disable react/jsx-pascal-case */
import { useSelector } from "react-redux";
import {
  useFindNullFixtures,
  useFindDuplicatePerformanceFixtures,
  useRemoveDuplicatePlayers,
} from "../../Hooks/Auth/useFindNullFixtures";
import { useGetSubscriptions } from "../../Hooks/Content/useSubscriptions";

import { useEffect } from "react";
import { Container, Space } from "@mantine/core";
import { PageHeader } from "../../Components/interface/ProductHeader";
import { VideoWizardContainer } from "../../Theme/Structure/BodyContainer";
import { BLOCK_Section_SubHeading } from "../../Components/interface/BLOCK_Section_SubHeading";
import { UISkeletonLines } from "../../Components/Global/Skeleton";
import { UNIXTIME, FORMATTTIME, DAYSBETWEENDATES } from "../../actions/UX";
import { ICONDB } from "../../Theme/icons";
import {
  TABLE_TD,
  TABLE_THEAD_WRAPPER,
  TABLE_WRAPPER,
} from "../../Components/interface/Wrapper/Table";
import { AccessDenied } from "../../Components/interface/AccessDenied";

export const ADMINHUB = () => {
  const [FindNullFixtures, DeleteFindNullFixtures] = useFindNullFixtures();
  const [FindDuplicatePerformance, DeleteFindDuplicatePerformance] =
    useFindDuplicatePerformanceFixtures();
  const [RemoveDuplicatePlayers, ProcessRemoveDuplicatePlayers] =
    useRemoveDuplicatePlayers();

  //const UXSTATE = useSelector((state) => state.UXSTATE);
  const AUTH = useSelector((state) => state.AUTH);

  console.log(AUTH);

  const FindNull = () => {
    //console.log('Find Null OBJS');
    DeleteFindNullFixtures();
  };
  useEffect(() => {
    //console.log(FindNullFixtures)
  }, [FindNullFixtures]);

  const FindDuplicates = () => {
    //console.log('Find DUPOLICATES OBJS');
    DeleteFindDuplicatePerformance();
  };

  useEffect(() => {
    //console.log(FindDuplicatePerformance)
  }, [FindDuplicatePerformance]);

  const FindDuplicatePlayers = () => {
    //console.log('Find DUPLICATE PLAYERS');
    ProcessRemoveDuplicatePlayers();
  };

  useEffect(() => {
    //console.log(RemoveDuplicatePlayers)
  }, [RemoveDuplicatePlayers]);

  if (!AUTH.AUTHUSER.hasFullAccess) return <AccessDenied />;
  return (
    <>
      <PageHeader NavPosition="Admin" />
      <Container size="lg" p={0}>
        <GeneralDetails GENERAL={AUTH.AUTHUSER} />
        <Space h="xl" />
       {/*  <AssignedFranchises franchises={AUTH.AUTHUSER.franchises} /> */}
        <Space h="xl" />
        <ActiveSubscriptions />
      </Container>
    </>
  );
};

const GeneralDetails = ({ GENERAL }) => {
  return (
    <>
      <BLOCK_Section_SubHeading LABEL="General" Color={4} />
      <VideoWizardContainer>
        <TABLE_WRAPPER>
          <TABLE_THEAD_WRAPPER
            DISPLAY={["None", "table-header-group"]}
            HEADERS={["Username", "Joined", "Status", "Active"]}
          />
          <TABLE_THEAD_WRAPPER
            DISPLAY={["table-header-group", "None"]}
            HEADERS={[
              "Username",
              "Contact Email",
              "Joined",
              "Status",
              "Active",
            ]}
          />

          <tbody>
            <tr>
              <TABLE_TD>{GENERAL.username}</TABLE_TD>
              <TABLE_TD HIDE={true}>{GENERAL.email}</TABLE_TD>
              <TABLE_TD>{FORMATTTIME(GENERAL.createdAt)}</TABLE_TD>
              <TABLE_TD>
                {GENERAL.confirmed
                  ? ICONDB.CircleCheck.Component
                  : ICONDB.CircleX.Component}
              </TABLE_TD>
              <TABLE_TD>
                {!GENERAL.blocked
                  ? ICONDB.CircleCheck.Component
                  : ICONDB.CircleX.Component}
              </TABLE_TD>
            </tr>
          </tbody>
        </TABLE_WRAPPER>
      </VideoWizardContainer>
    </>
  );
};

const ActiveSubscriptions = () => {
  const [Subscription, GetSubscription] = useGetSubscriptions();

  useEffect(() => {
    GetSubscription();
  }, []);
  useEffect(() => {}, [Subscription]);

  const CreateRows = () => {
    return Subscription.map((sub, i) => (
      <tr key={i}>
        <TABLE_TD>{sub.attributes.franchise.data.attributes.Name}</TABLE_TD>
        <TABLE_TD>${sub.attributes.Payment}</TABLE_TD>

        <TABLE_TD HIDE={true}>{FORMATTTIME(sub.attributes.DateStart)}</TABLE_TD>
        <TABLE_TD HIDE={true}>{FORMATTTIME(sub.attributes.DateEnd)}</TABLE_TD>
        <TABLE_TD>
          $
          {(
            sub.attributes.Payment *
            (DAYSBETWEENDATES(
              sub.attributes.DateStart,
              sub.attributes.DateEnd
            ) /
              7)
          ).toFixed(0)}
        </TABLE_TD>
        <TABLE_TD>
          {sub.attributes.hasPaid
            ? ICONDB.CircleCheck.Component
            : ICONDB.CircleX.Component}
        </TABLE_TD>

        <TABLE_TD>
          {sub.attributes.isActive
            ? ICONDB.CircleCheck.Component
            : ICONDB.CircleX.Component}
        </TABLE_TD>
        <TABLE_TD>
          {DAYSBETWEENDATES(sub.attributes.DateStart, sub.attributes.DateEnd)}
        </TABLE_TD>
      </tr>
    ));
  };

  if (!Subscription) return <UISkeletonLines />;
  return (
    <>
      <BLOCK_Section_SubHeading LABEL="Subscription Status" Color={5} />
      <VideoWizardContainer>
        <TABLE_WRAPPER>
          <TABLE_THEAD_WRAPPER
            DISPLAY={["None", "table-header-group"]}
            HEADERS={[
              "Franchise",
              "Tier",

              "Total",
              "Paid",
              "Active",
              "Days Left",
            ]}
          />
          <TABLE_THEAD_WRAPPER
            DISPLAY={["table-header-group", "None"]}
            HEADERS={[
              "Franchise",
              "Tier",
              "Subscription From",
              "Subscription To",
              "Total",
              "Paid",
              "Active",
              "Days Left",
            ]}
          />
          <tbody>{CreateRows()}</tbody>
        </TABLE_WRAPPER>
      </VideoWizardContainer>
    </>
  );
};

const AssignedFranchises = ({ franchises }) => {
  console.log(franchises)
  const rows = franchises.map((sub, i) => (
    <tr key={i}>
      <TABLE_TD>{sub.Name}</TABLE_TD>
      <TABLE_TD>
        {sub.FranchiseStats?.FIXTURES ? sub.FranchiseStats.FIXTURES : 0}
      </TABLE_TD>
      <TABLE_TD>{FORMATTTIME(sub.createdAt)}</TABLE_TD>
      <TABLE_TD>{UNIXTIME(sub.LastUpdate)}</TABLE_TD>
    </tr>
  ));
  return (
    <>
      <BLOCK_Section_SubHeading LABEL="Assigned Franchises" Color={5} />
      <VideoWizardContainer>
        <TABLE_WRAPPER>
          <TABLE_THEAD_WRAPPER
            HEADERS={["Franchise", "Fixtures", "Creates on", "Last Update"]}
          />
          <tbody>{rows}</tbody>
        </TABLE_WRAPPER>
      </VideoWizardContainer>
    </>
  );
};
