import {gql,} from "@apollo/client";


export const QUERY = (APISETTINGS)=>{
    return gql`query TOP_6 {
      playerBattings(
         pagination: { start: 0, limit: -1 }
        filters:{BATTING_sixes:{ gte:3},BATTING_SR:{ gt: 250 },fixture:{watch_list:{ id:{in: [${APISETTINGS.IDS}]}}}}
      ){
          data{ 
           id
            attributes{
              BATTING_Runs
              BATTING_SR
              BATTING_Balls
               team {
                    data {
                      id
                      attributes {
                        Name
                        TeamID
                        Logo {
                          data {
    
                            attributes {
                              url
                            }
                          }
                        }
                      }
                  }
              }
              
              player{
                data{
                  id
                  attributes{
                    Name
                     image {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                  }
                }
              }
            }
          }
      }
    }`
}
  
