import { ResponsiveLine } from '@nivo/line'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const LineChart = ({DATA}) => {

   
    return(
    <ResponsiveLine
        data={DATA}
        margin={{ top: 10, right: 20, bottom: 100, left: 40 }}
        xScale={{ type: 'point' }}
        colors={[ '#EC8E1F', '#4A819E', '#4FB575', '#82c5f0', '#1B6B96','#c4c5bc', '#4A5060']}
        yScale={{
            type: 'linear',  
            min: 'auto',
            max: 'auto',
            stacked: false,
            reverse: false 
        }}
        yFormat=" >-.2f"
        curve="monotoneX"
        enableArea={false}
        pointSize={2}
        pointBorderWidth={5}
        minValue={0}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 18,
            legend: '',
            legendOffset: 45,
            legendPosition: 'middle'
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendOffset: -40,
            legendPosition: 'middle',
            tickValues: 4
        }}
       
        pointColor={{ theme: 'background' }}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
            {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 10,
                translateY: 90,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 120,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'square',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />)
    }