import { Card,Group,SimpleGrid, Center } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';

import { CALLBACKIcon } from '../../../Theme/Structure/Button/ActionIcons';
import { H3, P } from '../../../Theme/type';
import {PreviewVideoModal} from '../../../Components/Global/PlayVideoModal'

export const DisplayVideos = ({Selected})=>{

    const [opened, setOpened] = useState(false);
    const [videoURL,setVideoURL] = useState(false)

    
    useEffect(()=>{},[Selected])
    
    if(Selected?.TournamentVideos?.data !== null)
    return(
       <>
       <H3 colorID={5}>Video Gallery</H3>
        <SimpleGrid  cols={4} spacing="xs">
        {
            Selected?.TournamentVideos?.data.map((video,i)=>{
                return <VideoCard VIDEO={video} key={i} setOpened={setOpened} setVideoURL={setVideoURL} opened={opened}/>
            })
        }
        </SimpleGrid> 
       <PreviewVideoModal setOpened={setOpened} opened={opened} setVideoURL={setVideoURL} videoURL={videoURL}/>
       </>
       
    )
}


 const VideoCard = ({VIDEO, setOpened,setVideoURL})=> {
    
    const inputRef = useRef(null);    
    const VideoModal = (Item)=>{
      //console.log(Item)
        setOpened(true)
        setVideoURL(Item)
      }

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Card.Section>
        <Group position='center' style={{
            padding:'1em 0 0 0'
        }}>
            <Center>
                <CALLBACKIcon ref={inputRef} ICON={`Video`} CALLBACK={() => {VideoModal(VIDEO.attributes.url)}}/>
            </Center>
            
        </Group>
        
      </Card.Section>

      <Group position="apart" mt="md" >
        <Center><P>{VIDEO.attributes.name}</P></Center>
      </Group>
        
    </Card>
  );
} 