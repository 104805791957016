import { Select } from '@mantine/core';

export const BowlingPOTDSelectMinWickets = ({CALLBACK, VALUE})=>{
  
    const ARR=[
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
      ]

    const handleChange=(e)=>{ CALLBACK(e)}
  
 
    return (
        <Select
          label=""
          placeholder={`Min Wickets :   ${VALUE}`}
          data={ARR} 
          onChange={handleChange}
          styles={(theme) => ({
            input:{
              '&, &:focus':{
                borderColor: theme.colors.brand[5],
                color: theme.colors.brand[5]
              }
            },
            item: {
              // applies styles to selected item
              '&[data-selected], &:focus': {
                '&, &:hover, &:focus': {
                  backgroundColor:theme.colors.brand[3],
                  color: theme.colors.brand[4],
                },
              },
    
              // applies styles to hovered item (with mouse or keyboard)
              '&[data-hovered], :focus': {
                backgroundColor:theme.colors.brand[5],
                color: theme.colors.brand[0],
              },
            },
          })}
        />
      );
}