import styled from "styled-components";
import { useTheme } from "styled-components";
const Value = styled.h1`
  position: absolute;
  right: 15px;
  bottom: 5px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  margin: 0;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0;
`;
export const Counter = ({ _INT, _TOTAL }) => {
	const theme = useTheme();
  return (
    <>
      <Value
        style={{
          zIndex: 999,
		  color: theme.Copy[2],
        }}
      >
        {_INT}-{_TOTAL}
      </Value>
    </>
  );
};
