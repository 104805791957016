import { useSelector } from "react-redux";
import { BLOCK_Section_SubHeading } from "../../Components/interface/BLOCK_Section_SubHeading";
import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";
import {
  TABLE_TD,
  TABLE_THEAD_WRAPPER,
  TABLE_WRAPPER,
} from "../../Components/interface/Wrapper/Table";
import { VideoWizardContainer } from "../../Theme/Structure/BodyContainer";
import { BTN_DOWNLOAD, BTN_ICON_LINK } from "../../Theme/Structure/Button/BTNS";
import { H3, P, P_WARNING } from "../../Theme/type";

import {
  Container,
  Card,
  Image,
  Text,
  Group,
  Badge,
  createStyles,
  SimpleGrid,
  
} from "@mantine/core";
import { useState } from "react";

export const NoActiveFranchise = () => {
  const UXSTATE = useSelector((state) => state.UXSTATE);
  const AUTH = useSelector((state) => state.AUTH);
  const [hasClicked, sethasClicked] = useState(false)
  console.log(AUTH);
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Subscription Required",
          ICON: "CircleX",
          DESCRIPTION: `There are currently no active subscriptions allocated to this account! however We currently have
           ${AUTH.AUTHUSER.subscriptions.length} franchises
           assigned to your account.`,
          TOOLBAR: false,
        }}
      />

      <Container size={"lg"} mt={20}>
        <H3>How to activate a Subscription</H3>
        <P>
          With a Statto subscription, you will have full access to all of
          Statto's features for a small weekly fee throughout the season. plus
          one month.
        </P>

        <P>
          Please select a payment plan from the options below that best suits
          your needs.
        </P>

        {AUTH.AUTHUSER.subscriptions.length > 1 ? (
          <MultipleInstructions />
        ) : (
          false
        )}
      </Container>

      {AUTH.AUTHUSER.subscriptions.map((stripe, i) => {
        return (
          <Container size={"lg"} mt={20}>
            <BLOCK_Section_SubHeading LABEL={stripe.franchise.Name} Color={5} />
            <VideoWizardContainer>
            <SimpleGrid cols={1} breakpoints={[{ minWidth: "md", cols: 2 }]}>
                {stripe.stripe_payment_products.map((option, ii) => {
                  return <SubscriptionCard option={option} key={ii} />;
                })}
              </SimpleGrid>
            </VideoWizardContainer>
          </Container>
        );
      })}

      <Container size={"lg"} mt={20}>
        <P colorID={2}>
          *Clicking on a Subscribe link will take you to the Payment platform
          powered by{" "}
          <a href="https://stripe.com/en-au" target={"_blank"}>
            Stripe.
          </a>
        </P>
      </Container>

      <Container size={"lg"} my={50}>
        <P size="lg" weight={900}>
          FAQS
        </P>
        <VideoWizardContainer>
          <H3 colorID={5} weight={900} transform={"uppercase"}>
            Why am i getting this notice?
          </H3>
          <ul>
            <li>
              <P>I have no Active Subscription assigned to my account.</P>
            </li>
            <li>
              <P>
                I have a Subscription pending, and awaiting confirmation from
                the admin.
              </P>
            </li>
          </ul>

          <H3 colorID={5} weight={900} transform={"uppercase"}>
            I have already Subscribed, what do i do now?
          </H3>
          <ul>
            <li>
              <P>
                Processing can take up to 24 hours, however, if you need to
                access your account earlier, send us a message and we can open
                up your account whilst the payment is pending.
              </P>
            </li>
          </ul>
          <H3 colorID={5} weight={900} transform={"uppercase"}>
            Cancellations mid Season
          </H3>
          <ul>
            <li>
              <P>
                If you wish to cancel your subscription mid-season, send us a
                message and we can take care of that immediately.
              </P>
            </li>
          </ul>
          <H3 colorID={5} weight={900} transform={"uppercase"}>
            End of Season
          </H3>
          <ul>
            <li>
              <P>
                As all franchises end at different points on the year, and as
                weather and ground allocations can sometimes play a part in when
                these dates may occur, it will be on the account holder to let
                us know when the final game of the season has taken place. Once
                this game has completed, your subscription will remain active
                for 1 additional month, as to ensure full use of Statto's end of
                season review options.
              </P>
            </li>
          </ul>
          <H3 colorID={5} weight={900} transform={"uppercase"}>
            Summer/Spring and Winter Comps
          </H3>
          <ul>
            <li>
              <P>
                If your franchise is active all year round, there is no need to
                renew your subscription. Your account will remain active until
                you no longer require it.
              </P>
            </li>
          </ul>
        </VideoWizardContainer>
      </Container>
    </>
  );
};

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    paddingBottom: theme.spacing.md,
  },

  like: {
    color: theme.colors.red[6],
  },

  label: {
    textTransform: "uppercase",
    fontSize: theme.fontSizes.xs,
    fontWeight: 700,
  },
}));

const SubscriptionCard = ({ option }) => {
  const { classes, theme } = useStyles();

  return (
    <Card withBorder radius="md" p="md" className={classes.card}>
      <Card.Section>
        <Image src={option.Image.url} alt={option.Label} height={180} />
      </Card.Section>

      <Card.Section className={classes.section} mt="md">
        <Group position="apart">
          <H3 colorID={5} weight={900} transform={"uppercase"}>
            {option.Label}
          </H3>
        </Group>

        <P>{option.description}</P>
      </Card.Section>

      <Card.Section className={classes.section}>
        <Group position="apart">
          <Text mt="md" className={classes.label} color="dimmed">
            Estimated Franchise Size: {option.FranchiseSize}
          </Text>

          <Text mt="md" className={classes.label} color="dimmed">
            <Badge size="xl" color={"blue"}>
              ${option.Price}/w
            </Badge>
          </Text>
        </Group>
      </Card.Section>

      <Group mt="xs">
        <BTN_DOWNLOAD
          TO={`${option.payment_link}`}
          LABEL={`Subscribe`}
          target="_blank"
          TIPOBJ={{
            LABEL: "Subscribe to Statto via Stripe",
            COLOR: "teal",
            POSITION: "top",
          }}
        />
      </Group>
    </Card>
  );
};

const MultipleInstructions = () => {
  return (
    <P colorID={5} weight={600}>
      As you have multiple franchises available, you have the option to select a
      single franchise, this will give you access to this franchise only, or get
      a discounted rate for a multiple franchise account. This will open up all
      of your franchises under the one payment option.
    </P>
  );
};

const Cancelation = () => {};
