import { useState } from "react" 
import axios from 'axios';
 
import {ENV} from '../../Config/RunTimeConfig'
import { parseCookies } from 'nookies'
import {uniq} from 'lodash'
const qs = require('qs');


// UTILS

const RemoveImageFromGallery_Return_ARR = async(_JWT,_IMG,_USER)=>{
    
    const query = qs.stringify({populate: '*'},{encodeValuesOnly: true,});

        const res = await fetch(`${ENV.API_URL}/api/teams/${_USER.id}?${query}`, {
            headers: { Authorization: `Bearer ${_JWT}`}
        })

        const PlayerResponse = await res.json();
        const PlayerGallery = PlayerResponse.data.attributes.ImageGallery.data

        const ARR=[]
        PlayerGallery.map((IMG,i)=>{ ARR.push(IMG.id); return true;})
           
        const filteredItems = ARR.filter(item => item !== _IMG.id);
        return filteredItems
}

const STRAPI_UploadNewGallery = async(_URL,_JWT,_ARR)=>{
    const res = await fetch(`${_URL}`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${_JWT}`
        },
        body: JSON.stringify({
            data: {
                ImageGallery: _ARR,
              }
        })
    })
    const PlayerGallery = await res.json();
  //console.log(PlayerGallery)
    return PlayerGallery
}

/* ******************************************************************* */
/* ADD NEW IMAGE TO GALLERY */

export const UploadTeamGalleryImage =(ctx)=>{ 

    const [GalleryImage,setGalleryImage] = useState(null);
    const jwt = parseCookies(ctx).jwt;


    const ConstructGalleryARR=async(_FILE, _ID)=>{

        const query = qs.stringify({populate: '*'},{encodeValuesOnly: true,});

        const res = await fetch(`${ENV.API_URL}/api/teams/${_ID}?${query}`, {
            headers: { Authorization: `Bearer ${jwt}`}
        })

        const PlayerResponse = await res.json();
        const PlayerGallery = PlayerResponse.data.attributes.ImageGallery.data


        if(PlayerGallery === null)
            return [_FILE]
        
        const ARR=[_FILE]
        PlayerGallery.map((IMG,i)=>{
                ARR.push(IMG.id)
                return true
            })
            return uniq(ARR)

    }


    const UploadGalleryImage = async (_FILE, _TEAM)=>{
        setGalleryImage(false)
        const GALLERYARR =  await  ConstructGalleryARR(_FILE, _TEAM.id)

        //_URL,_JWT,_ARR
        const UploadStatus = await STRAPI_UploadNewGallery(`${ENV.API_URL}/api/teams/${_TEAM.id}`,jwt,GALLERYARR)
        if(UploadStatus.data.id)
            setGalleryImage(true)
    }
    return [GalleryImage,UploadGalleryImage]
}

/* ******************************************************************* */
/* ASSIGN DEFAULT IMAGE */

export const useAssignNewDefaultLOGO = (ctx)=>{ 
    const [TeamLogo,setTeamLogo] = useState(null);
    const jwt = parseCookies(ctx).jwt
    
    const AssignTeamLogo = async(_IMAGEID, _ID)=>{
        //console.log(_IMAGEID, _ID)
        const res = await fetch(`${ENV.API_URL}/api/teams/${_ID}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({
                data: {
                    Logo: [_IMAGEID],
                  }
            })
        })
        const GetLeagueStatus = await res.json();
        //console.log(GetLeagueStatus)
        setTeamLogo(GetLeagueStatus)
       
    }
    return [TeamLogo,AssignTeamLogo]
}

export const useAssignNewTeamPhoto = (ctx)=>{ 
    const [TeamPhoto,setTeamPhoto] = useState(null);
    const jwt = parseCookies(ctx).jwt
    
    const AssignTeamPhoto = async(_IMAGEID, _ID)=>{
        //console.log(_IMAGEID, _ID)
        const res = await fetch(`${ENV.API_URL}/api/teams/${_ID}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({
                data: {
                    TeamPhoto: [_IMAGEID],
                  }
            })
        })
        const GetLeagueStatus = await res.json();
        //console.log(GetLeagueStatus)
        setTeamPhoto(GetLeagueStatus)
       
    }
    return [TeamPhoto,AssignTeamPhoto]
}

/* ******************************************************************* */
/* Delete Team Image */


export const useDeleteTeamImage = (ctx)=>{
    
    const [DeleteImage,setDeleteImage] = useState(null);
    const jwt = parseCookies(ctx).jwt
    
    // Extract ID from GAllery, Remove Image from Server, Send UI Confirmation

    const DeleteFromServer = async (_IMG)=>{

        ///api/upload/files/:id
        const res = await fetch(`${ENV.API_URL}/api/upload/files/${_IMG.id}`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
            }
        })
        const hasDeleteImage = await res.json();
      //console.log(hasDeleteImage)
    }


    const processDeleteImage= async(_IMG,_USER)=>{
            setDeleteImage(false)
        const GALLERYARR = await RemoveImageFromGallery_Return_ARR(jwt,_IMG,_USER)
        //_URL,_JWT,_ARR
        const UploadStatus = await STRAPI_UploadNewGallery(`${ENV.API_URL}/api/teams/${_USER.id}`,jwt,GALLERYARR)

        if(UploadStatus.data.id){
            DeleteFromServer(_IMG);
            setDeleteImage(true);
        } 
    }

    return[DeleteImage,processDeleteImage]
}


