/* eslint-disable react/jsx-pascal-case */

import { Center, Container, Stack, Image, SimpleGrid } from "@mantine/core";
import { VideoWizardContainer } from "../../../../Theme/Structure/BodyContainer";

import { BTN_ICON_LINK } from "../../../../Theme/Structure/Button/BTNS";
//

import { BLOCK_Section_Title } from "../../../../Components/interface/BLOCK_Section_Title";

export const TAB_IMAGES = ({ GALLERY }) => {
  if (GALLERY === undefined || GALLERY === null)
    return <>There are no Images associated with this Asset</>;
  return (
    <Container size="lg" my="xl">
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Images",
          ICON: "Photo",
          DESCRIPTION: "",
          TOOLBAR: false,
        }}
      />
      <Container size="md" my="xl">
        <VideoWizardContainer>
          <SimpleGrid cols={4} spacing="sm">
            {GALLERY.map((img, i) => {
              return (
                <Stack justify="flex-start" key={i}>
                  <Image
                    src={img.attributes.url}
                    style={{
                      boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.25)",
                    }}
                  />
                  <Center>
                    <BTN_ICON_LINK
                      ICON="Download"
                      TO={img.attributes.url}
                      TIPOBJ={{
                        LABEL: "Download",
                        COLOR: "blue",
                        POSITION: "top",
                      }}
                    />
                  </Center>
                </Stack>
              );
            })}
          </SimpleGrid>
        </VideoWizardContainer>
      </Container>
    </Container>
  );
};
