import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, SimpleGrid, Stack, Space, Group, Divider, Container } from "@mantine/core";
import {
  ColoredContainer,
  VideoWizardContainer,
} from "../../../Theme/Structure/BodyContainer";
import { H3 } from "../../../Theme/type";

import { inRange } from "lodash";
import { useGetLeagues } from "../../../Hooks/Content/useLeagues";
import { UISkeletonLines } from "../../../Components/Global/Skeleton";

import { PieChart } from "../../../Components/Charts/Pie";
import { BLOCK_Section_SubHeading } from "../../../Components/interface/BLOCK_Section_SubHeading";
import { COPY_INFO } from "../../../Components/interface/BLOCK_Info";
import { BLOCK_Section_Title } from "../../../Components/interface/BLOCK_Section_Title";
import { BASESTATS } from "../../Numbers/General";
//<Leagues />

//import { UpdateGameData } from "./SyncData/UpdateGameData";
//import moment from 'moment';
export const LeagueNumbers = () => {
  const UXSTATE = useSelector((state) => state.UXSTATE);
  if (!UXSTATE.SelectedFranchise) return <>No Franchise Selected</>;
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Franchise at a Glance",
          ICON: "Cricket",
          DESCRIPTION:
            "An overview of how your franchise is tracking with statto",
          TOOLBAR: false,
        }}
      />
      <ColoredContainer BGColor={0} size={'xl'}>
        <Fixtures />
      </ColoredContainer>
      <BASESTATS STATS={UXSTATE.SelectedFranchise.attributes.FranchiseStats} />

      <Space h={15} />
      <Divider my="lg" color="white" />
      <Space h={15} />
    </>
  );
};

/* const Leagues = () => {
  const UXSTATE = useSelector((state) => state.UXSTATE);
  return (
    <>
      <BLOCK_Section_SubHeading LABEL="League Status" Color={4} />
      <VideoWizardContainer>
        <Stack cols={3}>
          <GridItem
            Label={"Stored"}
            Value={UXSTATE.SelectedFranchise.attributes.watch_lists.data.length}
          />
          <GridItem
            Label={"Tracked"}
            Value={
              filter(
                UXSTATE.SelectedFranchise.attributes.watch_lists.data,
                (o) => {
                  return (
                    o.attributes.hasCompleted === null ||
                    o.attributes.hasCompleted === false
                  );
                }
              ).length
            }
          />
          <GridItem
            Label={"De-Active"}
            Value={
              filter(
                UXSTATE.SelectedFranchise.attributes.watch_lists.data,
                (o) => {
                  return o.attributes.hasCompleted === true;
                }
              ).length
            }
          />
        </Stack>
        <div style={{ height: "160px" }}>
          <PieChart
            Legend={false}
            DATA={[
              {
                id: "Stored",
                label: "Stored",
                value:
                  UXSTATE.SelectedFranchise.attributes.watch_lists.data.length,
              },
              {
                id: "Active",
                label: "Active",
                value: filter(
                  UXSTATE.SelectedFranchise.attributes.watch_lists.data,
                  (o) => {
                    return (
                      o.attributes.hasCompleted === null ||
                      o.attributes.hasCompleted === false
                    );
                  }
                ).length,
              },

              {
                id: "Deactivated",
                label: "Deactivated",
                value: filter(
                  UXSTATE.SelectedFranchise.attributes.watch_lists.data,
                  (o) => {
                    return o.attributes.hasCompleted === true;
                  }
                ).length,
              },
            ]}
          />
        </div>
      </VideoWizardContainer>
    </>
  );
}; */

const Fixtures = () => {
  //const UXSTATE = useSelector((state) => state.UXSTATE);
  //console.log(UXSTATE.SelectedFranchise)
  const [getLeagues, FetchgetLeagues] = useGetLeagues();

  useEffect(() => {
    FetchgetLeagues();
  }, []);
  useEffect(() => {}, [getLeagues]);

  const FindTotalFixtures = () => {
    const ARR = [];
    getLeagues.map((LEG, i) => {
      if (LEG.attributes.hasCompleted !== true)
        ARR.push(LEG.attributes.fixtures.data.length);
    });
    return ARR.reduce((a, b) => a + b);
  };

  const FindCompleted = () => {
    const ARR = [];
    getLeagues.map((LEG, i) => {
      if (LEG.attributes.hasCompleted !== true) {
        LEG.attributes.fixtures.data.map((fix, i) => {
          if (fix.attributes.hasResult) ARR.push(1);
        });
      }
    });
    return ARR.length === 0 ? 0 : ARR.reduce((a, b) => a + b);
  };

  const FindUpcoming = () => {
    const ARR = [0];
    let SevenDaysFromNow = Math.floor(
      (Date.now() + 7 * 24 * 60 * 60 * 1000) / 1000
    );
    let Today = Math.floor(Date.now() / 1000);
    getLeagues.map((LEG, i) => {
      if (LEG.attributes.hasCompleted !== true) {
        LEG.attributes.fixtures.data.map((fix, i) => {
          if (inRange(fix.attributes.UnixTime, Today, SevenDaysFromNow))
            ARR.push(1);
        });
      }
    });
    //console.log("ARR", ARR)
    return ARR.reduce((a, b) => a + b);
  };

  if (!getLeagues) return <UISkeletonLines />;
  return (
    <>
      <BLOCK_Section_SubHeading LABEL="Season progression in Games" Color={5} />

      <VideoWizardContainer>
        <SimpleGrid cols={3}>
          <GridItem Label={"This Season"} Value={FindTotalFixtures()} />
          <GridItem Label={"This Week"} Value={FindUpcoming()} />
          <GridItem Label={"Completed"} Value={FindCompleted()} />
        </SimpleGrid>

        <div style={{ height: "250px" }}>
          <PieChart
            DATA={[
              {
                id: "Games Completed",
                label: "Games Completed",
                value: FindCompleted(),
              },
              {
                id: "Games this Week",
                label: "Games this Week",
                value: FindUpcoming(),
              },

              {
                id: "Games Left",
                label: "Games this Season",
                value: FindTotalFixtures() - FindCompleted(),
              },
            ]}
          />
        </div>
        <COPY_INFO
          COPY={
            "These season games numbers may include forfeits and abandoned game data."
          }
        />
      </VideoWizardContainer>
    </>
  );
};

const GridItem = (props) => {
  return (
    <Group position="center">
      <H3 align="center" colorID={5} weight={400} transform="uppercase">
        {props.Label}
      </H3>
      <H3 align="center" colorID={4} weight={900} transform="uppercase">
        {props.Value}
      </H3>
    </Group>
  );
};
