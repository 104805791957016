import {useCurrentFrame } from 'remotion';
import {IntroContainer} from "../../utils/Containers"
import {TournamentLocationP, } from "../../utils/Text"

import {SpringToFrom} from '../../../Animation/RemotionSpring'
import {interpolateOpacityByFrame} from '../../../Animation/interpolate'


export const TournamentLocation = (props)=>{
    const {TournamentLocation} = props
    const frame = useCurrentFrame();
    return(
	<IntroContainer
        style={{ 
                padding:0,
                width:'90%',
                position:'absolute',
                bottom:'0px'
            }}
    >
		<TournamentLocationP 
            style={{ 
                opacity:interpolateOpacityByFrame(frame, 25,30, 0,1),
                transform: ` translateY(${SpringToFrom(25,100,0,'Default')}px) translateY(${SpringToFrom(90,0,100,'Default')}px)`
                 }} {... props} >{TournamentLocation}</TournamentLocationP>	
	</IntroContainer>
    )
}
//<OpeningBottomBar style={{width:`${widthWhite}px`}} {... props}/>