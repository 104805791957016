import { Center, Grid, Group, Container } from '@mantine/core'

import styled from 'styled-components';
import {SectionWrapper} from '../Components/Containers';
import {H1} from '../Components/type'



export const IndexSubscriptions=()=>{
    return(
        <SectionWrapper BGCOLOR={2}>
             <Container
            sx={(theme) => ({
                '@media (min-width: 1200px)': {
                    padding:' 5em 0',
                   
                },
                '@media (max-width: 1200px) and (min-width: 800px)': {
                    padding:' 5em 0',
                  
                },
                '@media (max-width: 800px) and (min-width: 755px)': {
                    padding:' 5em 0',
                  
                },'@media (max-width: 755px) and (min-width: 576px)': {
                    padding:' 5em 0',
                  
                },'@media (max-width: 576px)': {
                    padding:' 5em 0',
                }
              })}>
            <H1 weight={100} align='center' colorID={0}>subscription packages</H1>
            <Group position='center'>
                <SubscriptionContainer>item</SubscriptionContainer>
                <SubscriptionContainer>item</SubscriptionContainer>
                <SubscriptionContainer>item</SubscriptionContainer>
            </Group>
            </Container>
        </SectionWrapper>
    )
}

const SubscriptionOuterContainer = styled.div`

box-sizing: border-box;
position:relative;
height:400px;
width:300px;
background: #FFFFFF;

box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
border-radius: 10px;
padding:0;
`

const SubscriptionTop = styled.div`
position: absolute;
left: 0%;
right: 0%;
top: 0%;
bottom: 91.89%;

background: #4A5060;
box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
border-radius: 10px 10px 0px 0px;`


const SubscriptionBottom = styled.div`position: absolute;
left: 0%;
right: 0%;
top: 86%;
bottom: 0%;

background: #EC8E1F;
box-shadow: inset 0px 5px 10px rgba(0, 0, 0, 0.25);
border-radius: 0px 0px 10px 10px;`

const SubscriptionContainer = (props)=>{
    return(
        <SubscriptionOuterContainer>
            <SubscriptionTop>header</SubscriptionTop>
            {props.children}
            <SubscriptionBottom ></SubscriptionBottom>
        </SubscriptionOuterContainer>
    )
}

