import {Tooltip } from "@mantine/core"
export const ToolTipWrapper=(props)=>{
    const {TIPOBJ} = props
  
    if(TIPOBJ === false)
    return props.children
    return(
      <Tooltip 
            label={TIPOBJ.LABEL}
            color={TIPOBJ.COLOR}
            position={TIPOBJ.POSITION}
            withArrow
          >
            {props.children}
          </Tooltip>
    )
  }