import { useCurrentFrame, Audio } from "remotion";
import { useTheme } from "styled-components";
import styled from "styled-components";
import { Series } from "remotion";
// Animations
import { interpolateOpacityByFrame } from "../../../../../Animation/interpolate";

import { TeamsAndScores } from "./UI/TeamScores";
import { GameResult } from "./UI/GameResult";
import { ResultData } from "./UI/ResultData";

const ScorecardOuter = styled.div`
  display: flex;
  flex-direction: column;
  place-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 0px;
  width: 90%;
`;

export const ScoreCardWrapper = ({ DATA, LABEL, Durations }) => {
  const CreatePairs = () => {
    let chunkSize = 2;
    const ARR = [];
    for (let i = 0; i < DATA.Fixtures[LABEL].length; i += chunkSize) {
      const chunk = DATA.Fixtures[LABEL].slice(i, i + chunkSize);
      ARR.push(chunk);
    }
    return ARR;
  };

  return (
    <Series>
      {CreatePairs().map((Fixture, i) => {
        return (
          <Series.Sequence
            key={i}
            durationInFrames={Durations.Length}
            layout="none"
          >
            <ScorecardOuter key={i}>
              {Fixture.map((GAME, i) => {
                return <ScoreCard GAME={GAME} key={i} Durations={Durations} />;
              })}
            </ScorecardOuter>
            <Timer Durations={Durations} />
            <Audio
              src={`https://lmssocialmediamanager.s3.ap-southeast-2.amazonaws.com/assets/TournamentsResultsItem_1.mp3`}
              playbackRate={1}
              startFrom={0} // if composition is 30fps, then it will start at 2s
              volume={1}
            />
          </Series.Sequence>
        );
      })}
    </Series>
  );
};

const TimerContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 10px;
`;

const Timer = ({ Durations }) => {
  const frame = useCurrentFrame();
  const theme = useTheme();
  //console.log(((frame/Durations.Length)*100))
  return (
    <TimerContainer
      style={{
        width: `${(frame / Durations.Length) * 100}%`,
        backgroundColor: theme.Base[1],
      }}
    ></TimerContainer>
  );
};

//opacity:interpolateOpacityByFrame(frame, 45,60, 1,0),
//transform: `scale(${SpringToFrom(0,0,1,'Springy100')}) scale(${SpringToFrom(45,1,0)})`,
//box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
const ScorecardFrame = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 580px;
  margin: 80px 0 0 0;
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ScoreCard = ({ GAME, Durations }) => {
  const frame = useCurrentFrame();

  return (
    <ScorecardFrame
      style={{
        opacity: interpolateOpacityByFrame(
          frame,
          Durations.Length - 5,
          Durations.Length,
          1,
          0
        ),
      }}
    >
      <GameResult resultStatement={GAME.resultStatement} />
      <TeamsAndScores GAME={GAME} Durations={Durations} />
      <ResultData GAME={GAME} Durations={Durations} />
    </ScorecardFrame>
  );
};
