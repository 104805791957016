import { useEffect, useState } from "react";
import {
  Group,
  Text,
  useMantineTheme,
  MantineTheme,
  List,
  ThemeIcon,
} from "@mantine/core";
import { Upload, Photo, X, Icon as TablerIcon } from "tabler-icons-react";
import {
  Dropzone,
  DropzoneStatus,
  IMAGE_MIME_TYPE,
  MIME_TYPES,
} from "@mantine/dropzone";
import { useUploadImageViaDropzone } from "../../Hooks/user/useUploadImageViaDropzone";
import { ICONDB } from "../../Theme/icons";
import {
  IconUpload,
  IconPhoto,
  IconX,
  IconCircleCheck,
  IconCircleDashed,
} from "@tabler/icons";
import { VideoWizardContainer } from "../../Theme/Structure/BodyContainer";
import { BTN_CREATE } from "../../Theme/Structure/Button/BTNS";
import { H3, P } from "../../Theme/type";
function getIconColor(status: DropzoneStatus, theme: MantineTheme) {
  return status.accepted
    ? theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6]
    : status.rejected
    ? theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]
    : theme.colorScheme === "dark"
    ? theme.colors.dark[0]
    : theme.colors.gray[7];
}

function ImageUploadIcon({
  status,
  ...props
}: React.ComponentProps<TablerIcon> & { status: DropzoneStatus }) {
  if (status.accepted) {
    return <Upload {...props} />;
  }

  if (status.rejected) {
    return <X {...props} />;
  }

  return <Photo {...props} />;
}

export const dropzoneChildren = (
  status: DropzoneStatus,
  theme: MantineTheme
) => (
  <Group
    position="center"
    spacing="xl"
    style={{ minHeight: 220, pointerEvents: "none" }}
  >
    <ImageUploadIcon
      status={status}
      style={{ color: getIconColor(status, theme) }}
      size={80}
    />

    <div>
      <Text size="xl" inline>
        Drag image here or click to select files
      </Text>
      <Text size="sm" color="dimmed" inline mt={7}>
        Attach as many files as you like, each file should not exceed 5mb
      </Text>
    </div>
  </Group>
);

export default function DropZone({ setImageFile, setCloseDisabled }) {
  //[PlayerImage,UploadPlayerImage]
  const [DropZoneImage, UploadDropZoneImage] = useUploadImageViaDropzone();
  const [ProcessingImage, setProcessingImage] = useState(false);
  const [rejected, setRejected] = useState(false);
  const theme = useMantineTheme();

  const handleFileUpload = (_FILE) => {
    //console.log(_FILE)
    UploadDropZoneImage(_FILE);
    setProcessingImage(true);
    setCloseDisabled(true);
  };

  useEffect(() => {
    //console.log('DropZoneImage', DropZoneImage)
    setProcessingImage(false);
    setImageFile(DropZoneImage);
  }, [DropZoneImage]);

  if (rejected)
    return <RejectedFiles setRejected={setRejected} rejected={rejected} />;
  return (
    <Dropzone
      onDrop={(files) => handleFileUpload(files)}
      onReject={(files) => {
        setRejected(files);
        console.log("rejected files", files);
      }}
      maxSize={10 * 1024 ** 2}
      accept={[MIME_TYPES.jpeg, MIME_TYPES.png, MIME_TYPES.gif]}
      multiple={false}
      loading={ProcessingImage}
    >
      <Group
        position="center"
        spacing="xl"
        style={{ minHeight: 220, pointerEvents: "none" }}
      >
        <Dropzone.Idle>{ICONDB["Photo"].Component}</Dropzone.Idle>

        <div>
          <Text size="xl" inline>
            Drag images here or click to select files
          </Text>
        </div>
      </Group>
    </Dropzone>
  );
}

const RejectedFiles = ({ rejected, setRejected }) => {
  console.log(rejected[0].errors);
  return (
    <VideoWizardContainer>
      <H3>ERROR!</H3>
      <P>
        The following errors occurred whilst attempting to upload your image.
      </P>
      <List
        size="lg"
        withPadding
        center
        icon={
          <ThemeIcon color="red" size={24} radius="xl">
            <IconCircleCheck size={16} />
          </ThemeIcon>
        }
        style={{
          margin: "2em 0",
        }}
      >
        {rejected[0].errors.map((err, i) => {
          return <List.Item>{err.message}</List.Item>;
        })}
      </List>

      <BTN_CREATE
        CALLBACK={() => {
          setRejected(false);
        }}
        LABEL="Retry"
      />
    </VideoWizardContainer>
  );
};
