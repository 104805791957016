/* eslint-disable react/jsx-pascal-case */
import { useState } from "react";
import { useSelector } from "react-redux";

import { Space } from "@mantine/core";

import { BTN_CTA } from "../../Theme/Structure/Button/BTNS";

// Components
import { CreateNewSponsor } from "./Sponsorship/CreateNewSponsor";
import { CurrentSponsors } from "./Sponsorship/CurrentSponsors";
import { BLOCK_Section_Title } from "../../Components/interface/BLOCK_Section_Title";
import { BLOCK_ToolBar } from "../../Components/interface/BLOCK_Toolbar";
import { AccessDenied } from "../../Components/interface/AccessDenied";
export const SettingsCreateNewSponsor = () => {
  const [Create, setCreate] = useState(false);
  const AUTH = useSelector((state) => state.AUTH);
  console.log(AUTH.AUTHUSER.hasFullAccess);
  if (!AUTH.AUTHUSER.hasFullAccess) return <AccessDenied />;
  return (
    <>
      <BLOCK_Section_Title
        OBJ={{
          LABEL: "Sponsors",
          ICON: "ReportMoney",
          DESCRIPTION: `Create a title sponsor for your franchise. A title sponsor can be used in any of the video assets where the option is available in its creation`,
          TOOLBAR: false,
        }}
      />
      <Space h="md" />
      <BLOCK_ToolBar
        TOOLS={[
          <BTN_CTA
            fullWidth={false} 
            CALLBACK={() => {
              setCreate(!Create);
            }}
            LABEL={Create ? "Back" : "Create a Sponsor"}
          />,
        ]}
      />

      {Create ? (
        <CreateNewSponsor setCreate={setCreate} />
      ) : (
        <CurrentSponsors />
      )}
    </>
  );
};
