import { Select } from '@mantine/core';

export const SelectTeamFromDivision = ({DATA, CALLBACK, Division})=>{

    const handleChange=(e)=>{ CALLBACK(e)}
  
    const CreateFranchiseSelect = ()=>{
        let ARR=[{ value: false, label:'Clear'}]
        DATA.map((Team,i)=>{ ARR.push({ value: Team, label: Team.attributes.Name, key:i })})
        return ARR
    }
    
    return (
        <Select
          label="Teams in Selected Division" 
          placeholder="Select a Team"
          data={CreateFranchiseSelect()} 
          onChange={handleChange}
          styles={(theme) => ({
            input:{
              '&, &:focus':{
                borderColor: theme.colors.brand[5],
                color: theme.colors.brand[5]
              }
            },
            item: {
              // applies styles to selected item
              '&[data-selected], &:focus': {
                '&, &:hover, &:focus': {
                  backgroundColor:theme.colors.brand[3],
                  color: theme.colors.brand[4],
                },
              },
    
              // applies styles to hovered item (with mouse or keyboard)
              '&[data-hovered], :focus': {
                backgroundColor:theme.colors.brand[5],
                color: theme.colors.brand[0],
              },
            },
          })}
        />
      );
} 