import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// hooks
import { useAuthNavigation } from "../../Hooks/Auth/useAuthNavigation";
import { LOGOMAIN } from "./svg/LOGO";
import {
  createStyles,
  Navbar,
  UnstyledButton,
  Tooltip,
  Title,
} from "@mantine/core";
import {
  IconHome2,
  IconPhoto,
  IconReportMoney,
  IconVideo,
  IconUser,
  IconSettings,
  IconBallpen,
  IconChartDots,
} from "@tabler/icons";

import { filter } from "lodash";
import { useSelector } from "react-redux";

//import { MantineLogo } from '@mantine/ds';

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },

  aside: {
    flex: "0 0 60px",
    backgroundColor: theme.colors.brand[3],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRight: `1px solid ${theme.colors.brand[3]}`,
  },

  main: {
    flex: 1,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },

  mainLink: {
    width: 44,
    height: 44,
    borderRadius: theme.radius.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.colors.brand[1],

    "&:hover": {
      backgroundColor: theme.colors.brand[1],
      color: theme.colors.brand[3],
    },
  },

  mainLinkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.colors.brand[7],
      }).background,
      color: theme.colors.brand[5],
    },
  },

  title: {
    boxSizing: "border-box",
    color: theme.colors.brand[3],
    fontFamily: `${theme.fontFamily}`,
    marginBottom: theme.spacing.xl,
    textTransform: "uppercase",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    padding: theme.spacing.md,
    paddingTop: 18,
    height: 60,
    borderBottom: `1px solid ${theme.colors.brand[0]}`,
  },

  logo: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    height: 60,
    padding: theme.spacing.xs,
    borderBottom: `1px solid ${theme.colors.brand[3]}`,
    marginBottom: theme.spacing.xl,
  },

  link: {
    boxSizing: "border-box",
    display: "block",
    textDecoration: "none",
    borderTopRightRadius: theme.radius.md,
    borderBottomRightRadius: theme.radius.md,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.brand[0]
        : theme.colors.brand[3],
    padding: `0 ${theme.spacing.md}px`,
    fontSize: theme.fontSizes.sm,
    marginRight: theme.spacing.md,
    fontWeight: 500,
    height: 44,
    lineHeight: "44px",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.brand[0]
          : theme.colors.brand[3],
      color: theme.colors.brand[0],
    },
  },

  linkActive: {
    "&, &:hover": {
      borderLeftColor: theme.fn.variant({
        variant: "filled",
        color: theme.colors.brand[3],
      }).background,
      backgroundColor: theme.colors.brand[3],
      color: theme.white,
    },
  },
}));

const mainLinksMockdata = [
  { icon: IconHome2, label: "Home" },
  { icon: IconReportMoney, label: "Franchise" },
  { icon: IconChartDots, label: "Statistics" },
  { icon: IconVideo, label: "Videos" },
  { icon: IconBallpen, label: "Writeup" },
  { icon: IconPhoto, label: "Repository" },
  { icon: IconSettings, label: "Settings" },
  { icon: IconUser, label: "Admin" },
];

export function DoubleNavbar({ setOpened, opened }) {
  const AUTH = useSelector((state) => state.AUTH);

  const [Navigation, FetchNavigation] = useAuthNavigation();
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("Home");
  const [activeLink, setActiveLink] = useState("Data");
  //const [Parent, setParent] = useState([]);
  const [child, setchild] = useState([]);

  useEffect(() => {
    FetchNavigation();
  }, []);
  useEffect(() => {
    setchild(
      filter(Navigation, function (o) {
        return o.attributes.Section === active;
      })
    );
  }, [Navigation]);

  const handleParentClick = (LINK) => {
    setActive(LINK);
    setchild(
      filter(Navigation, function (o) {
        return o.attributes.Section === LINK;
      })
    );
  };

  const mainLinks = mainLinksMockdata.map((link) => (
    <Tooltip
      label={link.label}
      position="right"
      withArrow
      transitionDuration={0}
      key={link.label}
    >
      <UnstyledButton
        onClick={() => handleParentClick(link.label)}
        className={cx(classes.mainLink, {
          [classes.mainLinkActive]: link.label === active,
        })}
      >
        <link.icon stroke={1.5} />
      </UnstyledButton>
    </Tooltip>
  ));

  //console.log(child, AUTH.AUTHUSER.id);

  const links = child.map((link) => (
    <Link
      to={`/${link.attributes.URI}`}
      className={cx(classes.link, {
        [classes.linkActive]: activeLink === link.attributes.Name,
      })}
      onClick={(event) => {
        setActiveLink(link.attributes.Name);
        setOpened(false);
      }}
      key={link.attributes.Name}
    >
      {link.attributes.Name}
    </Link>
  ));

  return (
    <Navbar width={{ sm: 300 }} hiddenBreakpoint="sm" hidden={!opened}>
      <Navbar.Section grow className={classes.wrapper}>
        <div className={classes.aside}>
          <div className={classes.logo}>
            <LOGOMAIN />
          </div>
          {mainLinks}
        </div>
        <div className={classes.main}>
          <Title order={4} className={classes.title}>
            {active}
          </Title>

          {links}
        </div>
      </Navbar.Section>
    </Navbar>
  );
}

/*
onClick={() => { setOpened(false); }}

 <Navbar
      styles={{
        root: { 
          //background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2],
          background: `${theme.colors.brand[3]}`,
          top:0,
          height:'100%'
        },
      }}
       p="md" hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 200, lg: 250 }}
      >
*/
