import { useEffect, useState } from "react";
import { UISkeletonLines } from "../../../Components/Global/Skeleton";
import { PageHeader } from "../../../Components/interface/ProductHeader";
import { useSelectVideoTheme } from "../../../Hooks/Content/useVideoTheme";
import {
  Card,
  Avatar,
  Text,
  Group,
  SimpleGrid,
  Container,
  ColorSwatch,
  Space,
} from "@mantine/core";
//import { ModalGallery } from "../../../Components/Global/ModalCarouselGallery";

export const THEMES = () => {
  const [VideoTheme, FetchVideoTheme] = useSelectVideoTheme();
  const [opened, setOpened] = useState(false);
  const [Gallery, setGallery] = useState([]);
  useEffect(() => {
    FetchVideoTheme();
  }, []);
  useEffect(() => {
  //console.log(VideoTheme);
  }, [VideoTheme]);
  if (VideoTheme === null) return <UISkeletonLines />;
  return (
    <>
      <PageHeader NavPosition="Themes" />
      <Container size={"lg"}>
      <SimpleGrid
          cols={1}
          breakpoints={[
            { minWidth: "xs", cols: 2 },
            { minWidth: "md", cols: 2 },
            { minWidth: "lg", cols: 3 },
          ]}
        >
          {VideoTheme.map((DATA, i) => {
            return (
              <DisplaySingleTemplate
                key={i}
                DATA={DATA}
                setOpened={setOpened}
                setGallery={setGallery}
              />
            );
          })}
        </SimpleGrid>
      </Container>
    </>
  );
};

const DisplaySingleTemplate = ({ DATA, setOpened, setGallery }) => {
  
  console.log(DATA.attributes.Theme.BackgroundGradient);

  const Displayswatches = (COLORS) => {
    return COLORS.map((color) => {
      return <ColorSwatch key={color} color={color} withShadow={true} />;
    });
  }; 

  return (
    <Card withBorder p="xl" radius="md"
    style={{
      background: DATA.attributes.Theme.BackgroundGradient,}}>
      <Card.Section
        sx={{
           
          height: 140,
          backgroundPosition: "center",
        }}
      />
      <Avatar
        
        color="teal"
        size={80}
        radius={80}
        mx="auto"
        mt={-30}
      
      />
      <Text align="center" size="lg" weight={500} mt="sm" transform="uppercase">
        {DATA.attributes.Name}
      </Text>

      <Text align="center" size="sm" color="dimmed">
        Templates : {DATA.attributes.templates.data.length}
      </Text>
     
     <Space h='md'/>
      <Group position="center" spacing="xs">
        {Displayswatches(DATA.attributes.Theme.Base)}
      </Group>
      <Text align="center" size="sm" color="dimmed">
        Base Color Palette
      </Text>
      
    </Card>
  );
};

/*
 <Text align="left" size="sm">
        {DATA.attributes?.description}
      </Text>
<Text align="center" size="sm" color="dimmed">
        Video Assets : {DATA.attributes.assets.data.length}
      </Text>
 {DATA.attributes.Examples?.data !== null ? (
        <ModalGallery Gallery={DATA.attributes.Examples.data} />
      ) : (
        false
      )}
*/
